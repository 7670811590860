import React, {useState, useEffect} from 'react'
import '../../assets/styles/CreateBundleProductList.css'

const CreateBundleProductList = ({systemProductList, setProductList, productList}) => {

    const [systemProducts, setSystemProducts] = useState([])
    const [systemProductsOriginal, setSystemProductsOriginal] = useState([])

    const [termSearch, setTermSearch] = useState('')
    const [reloadSearch, setReloadSearch] = useState(false)

    useEffect(() => {   
        setSystemProductsOriginal(systemProductList)
        setSystemProducts(systemProductList)
        setReloadSearch(true)
    }, [systemProductList])





    //ADD PRODUCT
    const handleClick = (product) => {
        // console.log(product)
        const newProduct = {...product, Quantity: 1}

        let value = false
        
        for(let i = 0; i < productList.length; i++) {
            if (productList[i].DealerProposalProductId === newProduct.DealerProposalProductId) {
                    value = true  
            }
        }
       
        if(!value){
            setProductList(prev => [...prev, newProduct])
        }
        
    }



    //BUSCADOR
    useEffect(()=>{
      
        // if(systemProductList.lenght > 0){
        if(reloadSearch){
            // console.log('1')
            if(termSearch === ''){
                setSystemProducts(systemProductsOriginal)
            }else{
                const results = systemProductsOriginal.filter(product => product.Name.toLowerCase().includes(termSearch.toLowerCase()))
                setSystemProducts(results)
            }
        }
            
        // }
        
    }, [termSearch, reloadSearch])







    return (
        <div className="createBundleSystemProduct_listContainer">
            <input 
                type="text" 
                className="bundleItemList_input" 
                placeholder="Search product"
                value={termSearch}
                onChange={e => setTermSearch(e.target.value)}
            />

            <div className="bundleItemListContainer">

                {systemProducts && systemProducts.map(product => {
                        return (
                            <div className="bundleItemCard" 
                                 key={product.DealerProposalProductId}
                                 onClick={()=> handleClick(product)} 
                            >
                                <p className="bundleItemCard_name">{product.Name}</p>
                                <p className="bundleItemCard_info">
                                    <span >{product.PartNumber}</span> · <span >{`$${product.ProductPrice}`}</span>
                                </p>
                                
                               
                             </div>
                        )
                    })
                }
                
            </div>
           
        </div>
       
    )
}

export default CreateBundleProductList;
