import React, { useState, useEffect, useContext } from "react";
import "../../assets/styles/ApplicationForm.css";
import "../../assets/styles/StepCounter.css";
import Notification from '../Notification';
import { AuthContext } from '../../AuthContext';
import iconCheck from '../../assets/images/iconCheck.png';
import iconCheckOrange from '../../assets/images/iconCheckOrange.png';
import SpinnerPopUp from '../SpinnerPopUp';
import Spinner from 'react-spinner-material';
import iconSadFace from '../../assets/images/iconSadFace.png';
import moment from 'moment-timezone';
import PinnacleContractStatusTab from "../applicationsPage/PinnacleContractStatusTab";
import useIsSafari from '../../hooks/useIsSafari'
import NumberFormat from "react-number-format";
import Button from '../general/Button'


require('dotenv').config();

const PinnacleContract = (props) => {



    //ESTADOS
    const { uri, proposalInfo, setProposalInfo, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);
    const isSafari = useIsSafari()


    //ESTADOS DE CONTRACT
    const [contract, setContract] = useState({})
    const [loan, setLoan] = useState({})
    const [contractErrors, setContractErrors] = useState({})
    const [isSubmittingContract, setIsSubmittingContract] = useState(false);
    const [editContract, setEditContract] = useState(false);
    const [contractSalesAmount, setContractSalesAmount] = useState();
    const [loadingPostContract, setLoadingPostContract] = useState(false);
    const [reloadGetContract, setReloadGetContract] = useState(false);
    const [showNotificationContractSendedOk, setShowNotificationContractSendedOk] = useState(false);
    const [contractCompletionStatus, setContractCompletionStatus] = useState(false)
    const [showNotificationPostContractError, setShowNotificationPostContractError] = useState(false);
    const [tierContract, setTierContract] = useState('')
    const [newAmountFinanced, setNewAmountFinanced] = useState(null)
    const [paymentFactor, setPaymentFactor] = useState(null)
    const [monthlyPayment, setMonthlyPayment] = useState(null)
    const [loadingCalculatedPaymentFactor, setLoadingCalculatedPaymentFactor] = useState(false)
    const [createCompleteManualApplications, setCreateCompleteManualApplications] = useState(false)
    const [loadingResendContract, setLoadingResendContract] = useState(false)
    const [resendContractSuccess, setResendContractSuccess] = useState(false)
    const [resendContractError, setResendContractError] = useState(false)
    const [incluideBankNumbers, setIncluideBankNumbers] = useState(true)
    const [bidPercentContract, setBidPercentContract] = useState('')
    const [contractStatusDate, setContractStatusDate] = useState({})
    const [contractApprovalType, setContractApprovalType] = useState(false)
    const [APRValues, setAPRValues] = useState([])
    const [loanTermsValues, setLoanTermsValues] = useState([])
    const [loanProgramsValues, setloanProgramsValues] = useState([])
    const [promotionsValues, setPromotionsValues] = useState([])
    const [flagToUpdateAmountFinanced, setFlagToUpdateAmountFinanced] = useState(false)
    const [time, setTime] = useState(null)
    const [contractSuccessfullySended, setContractSuccessfullySended] = useState(false)
    const [reloadStatusTab, setReloadStatusTab] = useState(0)
    const [contractCompletionValue, setContractCompletionValue] = useState('')
    const [loadingCopyProposal, setLoadingCopyProposal] = useState(false)





    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const dealerIdCode = window.localStorage.getItem('dealerId');







    //LLENAR EL CONTRACT
    useEffect(() => {

        const data = { ...props.applicationData }



        if (!data || Object.keys(data).length === 0) {
            return
        }

        // console.log(data)
        setLoan(data.LoanParameters)


        if ((data.ContractCompletionStatus !== 'Success' && data.ContractCompletionStatus !== 'Resent' && data.ContractCompletionStatus !== 'SignerComplete') && (data.LoanApplicationStatus === 'Approved' || data.LoanApplicationStatus === 'SignReady')) {
            setEditContract(true)
        } else if ((data.ContractCompletionStatus === 'Success' || data.ContractCompletionStatus === 'Resent' || data.ContractCompletionStatus === 'SignerComplete') && 
          (data.LoanApplicationStatus === 'Approved' || 
          data.LoanApplicationStatus === 'SignReady' || 
          data.LoanApplicationStatus === 'Received' || 
          data.LoanApplicationStatus === 'PendingDocs' || 
          data.LoanApplicationStatus === 'ReadyToFund' || 
          data.LoanApplicationStatus === 'FundingComplete')) {
            setEditContract(false)
            setContractCompletionStatus(true)
        }

        setContractCompletionValue(data.ContractCompletionStatus)


        setBidPercentContract(data.BidPercent)
        setTierContract(data.Tier)

        setContractApprovalType(data.ContractApprovalType)

        if (data.ContractApprovalType && data.ContractApprovalType === 'Manual') {
            setCreateCompleteManualApplications(true)
        }




        //CONSTRACT STATUS DATE TABLITA
        setContractStatusDate({
            ContractCompletionSentDate: data.ContractCompletionSentDate,
            ContractCompletionSignedDate: data.ContractCompletionSignedDate,
        })

        let f = new Date();
        let d = new Date();
        d.setDate(d.getDate() + 30);


        const contractData = {
            ApplicantFirstName: data.Applicant.FirstName || '',
            ApplicantLastName: data.Applicant.LastName || '',
            ApplicantEmail: data.Applicant.EmailAddress || '',
            ApplicantAddress: data.Applicant.Address1 || '',
            ApplicantCity: data.Applicant.City || '',
            ApplicantState: data.Applicant.State || '',
            ApplicantZipCode: data.Applicant.ZipCode || data.Applicant.PostalCode || '',
            ApplicantPhone: data.Applicant.CellPhone || '',

            ManufacturerGoodsServiceSold: data.LoanParameters.ManufacturerGoodsServiceSold || '',
            ProductOrService: data.LoanParameters.ProductOrService || 'Security System',
            SerialNumberGoodsServiceSold: data.LoanParameters.SerialNumberGoodsServiceSold || '',


            LoanProgram: data.LoanParameters.LoanProgram,



            LoanDate: data.LoanParameters.RemittanceUpdateFlag ? (data.LoanParameters.LoanDate || null) : (data.LoanParameters.LoanDate || `${f.getFullYear()}-${(f.getMonth() + 1).toString().padStart(2, '0')}-${f.getDate().toString().padStart(2, '0')}`),
            FirstPaymentDate: data.LoanParameters.RemittanceUpdateFlag ? null : (data.LoanParameters.FirstPaymentDate || `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`),
            RepaymentTerms: data.LoanParameters.RepaymentTerms || 96,

            APR: data.LoanParameters.APR ? data.LoanParameters.APR
                : data.Tier === 'PLATINUM' ? '9.99'
                    : data.Tier === 'GOLD' ? '11.99'
                        : data.Tier === 'SILVER' ? '13.99'
                            : null,



            CompletionDate: data.LoanParameters.RemittanceUpdateFlag ? null : (data.LoanParameters.CompletionDate || null),

            BankAccountType: data.LoanParameters.BankAccountType || null,
            BankRoutingNumber: data.LoanParameters.BankRoutingNumber,
            BankAccountNumber: data.LoanParameters.BankAccountNumber,



        }

        if (data.LoanParameters.SaleAmount) {
            contractData.SaleAmount = data.LoanParameters.RemittanceUpdateFlag ? null : parseFloat(data.LoanParameters.SaleAmount).toFixed(2);
            contractData.DownPayment = data.LoanParameters.RemittanceUpdateFlag ? null : parseFloat(data.LoanParameters.DownPayment).toFixed(2);
        }

        if (data.hasOwnProperty('CoApplicant')) {
            contractData.CoApplicantFirstName = data.CoApplicant.FirstName || ''
            contractData.CoApplicantLastName = data.CoApplicant.LastName || ''
            contractData.CoApplicantEmail = data.CoApplicant.EmailAddress || ''
            contractData.CoApplicantPhone = data.CoApplicant.CellPhone || ''
        }






        setContractSalesAmount(parseInt(data.LoanParameters.SaleAmount))

        setContract({ ...contract, ...contractData })

       
        getContractInfo(contractData, true, false)



    }, [props.applicationData])




    //SUBMIT CONTRACT
    const postContract = (event) => {

        setLoadingPostContract(true);


        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_CONTRACT_COMPLETION}${props.application_id}`
        //console.log(URI)  


        const contractSubmit = {
            LoanProgram: contract.LoanProgram || '365 Days SAC',
            CompletionDate: contract.CompletionDate,
            // BankAccountType : contract.BankAccountType,
            // BankRoutingNumber : contract.BankRoutingNumber,
            // BankAccountNumber : contract.BankAccountNumber,
            ManufacturerGoodsServiceSold: contract.ManufacturerGoodsServiceSold,
            ProductOrService: contract.ProductOrService,
            SerialNumberGoodsServiceSold: contract.SerialNumberGoodsServiceSold,
            SaleAmount: contract.SaleAmount,
            DownPayment: contract.DownPayment,
            LoanApplicationDate: contract.LoanDate,
            APR: contract.APR,
            RepaymentTerms: contract.RepaymentTerms,
            ApplicantEmail: contract.ApplicantEmail,
            CoApplicantEmail: contract.CoApplicantEmail,
            ApplicantPhone: contract.ApplicantPhone,
            CoApplicantPhone: contract.CoApplicantPhone,
            ApplicantFirstName: contract.ApplicantFirstName,
            ApplicantLastName: contract.ApplicantLastName,
            CoApplicantFirstName: contract.CoApplicantFirstName,
            CoApplicantLastName: contract.CoApplicantLastName,

            ApplicantAddress: contract.ApplicantAddress,
            ApplicantCity: contract.ApplicantCity,
            ApplicantState: contract.ApplicantState,
            ApplicantZipCode: contract.ApplicantZipCode
        }

        if (incluideBankNumbers) {
            contractSubmit.BankAccountType = contract.BankAccountType
            contractSubmit.BankRoutingNumber = contract.BankRoutingNumber
            contractSubmit.BankAccountNumber = contract.BankAccountNumber
        }

        if (createCompleteManualApplications) {
            contractSubmit.ContractApprovalType = 'Manual'
          
        } else {
            contractSubmit.ContractApprovalType = 'Blanket'
        }


        console.log(contractSubmit)
        // console.log(JSON.stringify(contractSubmit))


        window.fetch(URI, {
        method: 'POST',
        headers: {
          //'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token,
          'x-api-key' : URI_X_API_KEY_I,
        },
        body: JSON.stringify(contractSubmit),

        }).then(response => {

          if (response.status === 200) {
              return response.json();
            }
          return Promise.reject(response);

        })
        .then(datos => {
          setLoadingPostContract(false)
          //console.log('OBJETO QUE RESPONDE EL SERVIDOR')
        //   console.log(datos);
          setReloadStatusTab(reloadStatusTab + 1)

          if(datos.status === 'SUCCESS'){
            setContractSuccessfullySended(true)
            setShowNotificationContractSendedOk({show: true, text: 'Contract completion form submitted, this could take a few minutes.', type: 'ok'}) 
          }else{
            //console.log('ERROR')
            setContractSuccessfullySended(false)
            setShowNotificationPostContractError({error: true, text: 'An error has ocurred, please contact the admin'})
          }


        }).catch((error)=>{
          console.log(error)
          setContractSuccessfullySended(false)
          if(error.status === 504){
            setLoadingPostContract(false)
            setShowNotificationContractSendedOk({show: true, text: 'Contract Completion is taking longer than expected, it will complete in a few minutes.', type: 'timeOut'}) 
          }else{
            setLoadingPostContract(false)
            setShowNotificationPostContractError({error: true, text: 'An error has ocurred, please contact the admin'})
          }

        })      






    }; //end



    const defaultAPRValue = (isManual, tier, APRSelected, APRList) => {
        if(isManual){
            const newList = APRList.map(num => parseFloat(num))
            let apr = Math.max(...newList);
            return apr
        }else{
            const APR = APRSelected ? APRSelected
            : tier === 'PLATINUM' ? '9.99'
                : tier === 'GOLD' ? '11.99'
                    : tier === 'SILVER' ? '13.99'
                        : null
            return APR
        }
    }


    //GET CONTRACT INFO
    const getContractInfo = (contractData, firstCall, amountFinanced) => {

       

        if(!amountFinanced){
            return
        }

      

        setLoadingCalculatedPaymentFactor(true)

        const abortController = new AbortController();
        const signal = abortController.signal;

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.applicationData.ApplicationId}/get-contract-details/${amountFinanced ? (amountFinanced < 1000 ? '1000' : amountFinanced) : ''}`
       


        window.fetch(URI, {
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',
                'Content-Type': 'application/json',
                'username': user,
                'Authorization': token,
                'x-api-key': uri.URI_X_API_KEY,

            }
        })
            .then(response => {

                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then(data => {
                //setLoadingGetFinancials(false)

                //  console.log(data)
                // console.log(contractData)
               

                //ESTE IF SE EJECUTA SI EL CONTRATO NO SE HA ENVIADO
                const loanTerm = contractCompletionValue === 'Empty' ? (data.Defaults.DealerDefaultLoanTerm || data.Defaults.GlobalDefaultLoanTerm) : contractData.RepaymentTerms
                let defaultApr = ''
                if(contractCompletionValue === 'Empty'){
                    if (data.ApprovalType === 'Manual' || newAmountFinanced > 5000) {
                        // console.log('isManual')
                        const Apr = defaultAPRValue(true, undefined, undefined, data.APRs)
                        defaultApr = Apr
                                      
                        setCreateCompleteManualApplications(true)
                        setContract({...contractData, 
                                    ...contract, 
                                    APR: Apr, 
                                    RepaymentTerms: loanTerm,
                                    LoanProgram: data.Defaults.DealerDefaultLoanProgram  || data.Defaults.GlobalDefaultLoanProgram,
                                })
                    } else {
                       
                        if (!contractCompletionStatus) {
                            defaultApr = data.Defaults.DealerDefaultAPR || data.Defaults.GlobalDefaultAPR
                            setContract({...contractData, 
                                         ...contract,
                                         APR: data.Defaults.DealerDefaultAPR || data.Defaults.GlobalDefaultAPR,
                                         LoanProgram: '365 Days SAC',
                                         RepaymentTerms: loanTerm,
                                        })
                        }
                    }
                }


                //CALCULAR MONTHLYPAYMENT Y PAYMENTFACTOR       
                const APRCopy = contractCompletionValue === 'Empty' ? defaultApr : contractData.APR
                
                
      
               
          

                const values = data.Promotions.find(promotion => (promotion.APR == APRCopy) && (promotion.LoanTerm === loanTerm ) )

                if(values){
                    setMonthlyPayment(values.EstimatedMonthlyPayment)
                    setPaymentFactor(values.PaymentFactor)
                }
                setLoadingCalculatedPaymentFactor(false)

                //LLENAR APR
                setAPRValues(data.APRs)

                //LLENAR LOAN TERMS
                setLoanTermsValues(data.LoanTerms)

                //LLENAR LOAN POGRAMS
                setloanProgramsValues(data.LoanPrograms)

                //LLENAR PROMOTIONS
                setPromotionsValues(data.Promotions)

                setFlagToUpdateAmountFinanced(true)

    

            }).catch((error, response) => {
                console.log('error')
                setLoadingCalculatedPaymentFactor(false)
                if (errorFlagFromContext === false && error.status === 401) {
                    changeToken().then(response => console.log(response)).catch(error => console.log(error))
                }
            });
    }





    //ACTUALIZAR MONTHLY Y PAYMENTFACTOR
    useEffect(() => {
        if(promotionsValues.length > 0){
            const values = promotionsValues.find(promotion => (promotion.APR == contract.APR) && (promotion.LoanTerm === contract.RepaymentTerms ) )

            if(values){
                setMonthlyPayment(values.EstimatedMonthlyPayment)
                setPaymentFactor(values.PaymentFactor)
            }
        }   
    }, [contract.APR, contract.RepaymentTerms])




    
    




    //HANDLE INPUT CONTRACT
    const handleInputContract = (event, inputName) => {
        let value = event.target && event.target.value;


        if (event.target && event.target.name === 'LoanDate') {
            if (contract.LoanProgram !== '90 Days NP' && contract.LoanProgram !== '90 Days NPNI') {
                let d = new Date(value)
                d.setDate(d.getDate() + 30);
                //console.log(d)

                setContract({
                    ...contract,
                    [event.target.name]: value,
                    FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
                });
            } else {
                let d = new Date(value)
                d.setDate(d.getDate() + 90);
                //console.log(d)


                setContract({
                    ...contract,
                    [event.target.name]: value,
                    FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
                });
            }

        } else if (event.target && event.target.name === 'LoanProgram') {
            if (value === '90 Days NP' || value === '90 Days NPNI') {

                let d = new Date(contract.LoanDate)
                d.setDate(d.getDate() + 90);


                setContract({
                    ...contract,
                    [event.target.name]: value,
                    FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
                });
            } else {

                let d = new Date(contract.LoanDate)
                d.setDate(d.getDate() + 30);


                setContract({
                    ...contract,
                    [event.target.name]: value,
                    FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
                });

            }

        }else if (inputName === "ApplicantPhone" || inputName === "CoApplicantPhone") {
            setContract({
                ...contract,
                [inputName]: event.value,
            })
        }else {
            setContract({
                ...contract,
                [event.target.name]: value,
            });
        }





    }; //end




    //HANDLE SUBMIT CONSTRACT
    const handleSubmitContract = event => {
        event.preventDefault();
        //handle errors
        //console.log(contract)
        setContractErrors(validateFormContract(contract));
        setIsSubmittingContract(true);


    }; //end



    //POST CONTRACT
    useEffect(() => {
        if (Object.keys(contractErrors).length === 0 && isSubmittingContract) {
            postContract()

        }
    }, [contractErrors, isSubmittingContract]);




    //validate contract
    const validateFormContract = (applicant) => {
        let errorList = {};

         //FirstName - text
         if (!applicant.ApplicantFirstName) {
            errorList.ApplicantFirstName = "Please enter a name";
          } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.ApplicantFirstName)) {
            errorList.ApplicantFirstName = "The name can only contain letters";
          }




          //LastName - text
          if (!applicant.ApplicantLastName) {
            errorList.ApplicantLastName = "Please enter a lastname";
          } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.ApplicantLastName)) {
            errorList.ApplicantLastName = "The lastname can only contain letters";
          }


          if (contract.hasOwnProperty('CoApplicantFirstName')) {
                 //CoApplicantFirstName - text
                if (!applicant.CoApplicantFirstName) {
                    errorList.CoApplicantFirstName = "Please enter a name";
                } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.CoApplicantFirstName)) {
                    errorList.CoApplicantFirstName = "The name can only contain letters";
                }




                //CoApplicantLastName - text
                if (!applicant.CoApplicantLastName) {
                    errorList.CoApplicantLastName = "Please enter a lastname";
                } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.CoApplicantLastName)) {
                    errorList.CoApplicantLastName = "The lastname can only contain letters";
                }
          }


         



        //ManufacturerGoodsServiceSold
        if (!applicant.ManufacturerGoodsServiceSold) {
            errorList.ManufacturerGoodsServiceSold = "Please enter a Manufacturer Goods Service Sold";
        } else if (!/^[a-zA-Z0-9_\-\s\.]{0,200}$/.test(applicant.ManufacturerGoodsServiceSold)) {
            errorList.ManufacturerGoodsServiceSold = "The Manufacturer Goods Service Sold can only contain 200 characters";
        }


        //ProductOrService
        if (!applicant.ProductOrService) {
            errorList.ProductOrService = "Please enter a Description Goods Service Sold";
        } else if (!/^[a-zA-Z0-9_\-\s\.]{0,500}$/.test(applicant.ProductOrService)) {
            errorList.ProductOrService = "The Product Or Service can only contain 500 characters";
        }


        //SerialNumberGoodsServiceSold
        if (!applicant.SerialNumberGoodsServiceSold) {
            errorList.SerialNumberGoodsServiceSold = "Please enter a Serial Number Goods Service Sold";
        } else if (!/^[a-zA-Z0-9_\-\.]{0,50}$/.test(applicant.SerialNumberGoodsServiceSold)) {
            errorList.SerialNumberGoodsServiceSold = "The Serial Number Goods Service Sold can only contain 50 characters";
        }



        //SaleAmount *
        if (!applicant.SaleAmount) {
            errorList.SaleAmount = "Please enter a value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.SaleAmount)) {
            errorList.SaleAmount = "The value can only contain numbers";
        }



        //DownPayment *
        if (applicant.DownPayment === null || applicant.DownPayment === '') {
            errorList.DownPayment = "Please enter a value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.DownPayment)) {
            errorList.DownPayment = "The value can only contain numbers";
        } else if (parseInt(applicant.DownPayment) > parseInt(applicant.SaleAmount)) {
            errorList.DownPayment = "Should be less than Sale Amount";
        }


        // RepaymentTerms - LoanTerm
        if (!applicant.RepaymentTerms) {
            errorList.RepaymentTerms = "Please select a Loan Term";
        }


        //APR
        if (!applicant.APR) {
            errorList.APR = "Please select a APR";
        }



        //LoanDate
        if (!applicant.LoanDate) {
            errorList.LoanDate = "Please enter a Loan Date";
        } else if (!applicant.LoanDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
            if (isSafari) {
                errorList.LoanDate = "The format must be yyyy-mm-dd";
            } else {
                errorList.LoanDate = "The format must be dd/mm/yyyy";
            }
        }



        //FirstPaymentDate
        if (!applicant.FirstPaymentDate) {
            errorList.FirstPaymentDate = "Please enter a First Payment Date";
        }


        //CompletionDate
        if (!applicant.CompletionDate) {
            errorList.CompletionDate = "Please enter a date";
        } else if (!applicant.CompletionDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
            if (isSafari) {
                errorList.CompletionDate = "The format must be yyyy-mm-dd";
            } else {
                errorList.CompletionDate = "The format must be dd/mm/yyyy";
            }
        } else if (applicant.CompletionDate) {
            let dob2 = new Date(applicant.CompletionDate);
            const loanDate = new Date(applicant.LoanDate);
            if (dob2 < loanDate) {
                errorList.CompletionDate = "The date must be after the Loan Date";
            }


            const localTime = new Date().getTime()
            const completionDate = moment.tz(applicant.CompletionDate + " 00:00", "America/Chicago")
            const today00 = moment(localTime).tz('America/Chicago').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })


            if (completionDate.isBefore(today00)) {
                errorList.CompletionDate = "The date must be today or in the future";
            }
        }


        //LoanProgram
        if (createCompleteManualApplications) {
            if (!applicant.LoanProgram) {
                errorList.LoanProgram = "Please select an option";
            }
        }




        //BankAccountType
        if (incluideBankNumbers || !createCompleteManualApplications) {
            if (!applicant.BankAccountType) {
                errorList.BankAccountType = "Please select an option";
            }
        }



        //BankRoutingNumber *
        if (incluideBankNumbers || !createCompleteManualApplications) {
            if (!applicant.BankRoutingNumber && applicant.BankRoutingNumber !== '' ) {
                errorList.BankRoutingNumber = "Please enter a value";
            } else if (!/^[0-9]{9}$/.test(applicant.BankRoutingNumber)) {
                errorList.BankRoutingNumber = "The Bank Routing Number must have 9 numbers";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankRoutingNumber)) {
                errorList.BankRoutingNumber = "The value can only contain numbers";
            }
        }





        //BankAccountNumber *
        if (incluideBankNumbers || !createCompleteManualApplications) {
            if (!applicant.BankAccountNumber && applicant.BankAccountNumber !== '') {
                errorList.BankAccountNumber = "Please enter a value";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankAccountNumber)) {
                errorList.BankAccountNumber = "The value can only contain numbers";
            }
        }

        // else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankAccountNumber)) {
        //   errorList.BankAccountNumber = "The value can only contain numbers";
        // }


        //EmailAddress *
        if (!applicant.ApplicantEmail) {
            errorList.ApplicantEmail = "Please enter a email";
        } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.ApplicantEmail)) {
            errorList.ApplicantEmail = "Please enter a valid email";
        }


        //PHONE
        if (!applicant.ApplicantPhone) {
            errorList.ApplicantPhone = "Please enter a phone";
        } else if (!/^[0-9]{10}$/.test(applicant.ApplicantPhone)) {
            errorList.ApplicantPhone = "The phone must have 10 numbers";
        } else if (applicant.ApplicantPhone === '0000000000') {
            errorList.ApplicantPhone = "Please enter a valid phone number";
        }


        //COAPPLICANT PHONE
        if (contract.hasOwnProperty('CoApplicantFirstName')) {
            if (!applicant.CoApplicantPhone) {
                errorList.CoApplicantPhone = "Please enter a phone";
            } else if (!/^[0-9]{10}$/.test(applicant.CoApplicantPhone)) {
                errorList.CoApplicantPhone = "The phone must have 10 numbers";
            } else if (applicant.CoApplicantPhone === '0000000000') {
                errorList.CoApplicantPhone = "Please enter a valid phone number";
            }
        }






        //CoApplicantEmail *
        if (contract.hasOwnProperty('CoApplicantFirstName')) {
            if (!applicant.CoApplicantEmail) {
                errorList.CoApplicantEmail = "Please enter a email";
            } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.CoApplicantEmail)) {
                errorList.CoApplicantEmail = "Please enter a valid email";
            } else if (applicant.CoApplicantEmail === applicant.ApplicantEmail) {
                errorList.CoApplicantEmail = "The email must be different from the applicant's";
            }
        }




        //Address1 - text
        if (!contract.ApplicantAddress) {
            errorList.ApplicantAddress = "Please enter a address";
        }

        //City - text
        if (!contract.ApplicantCity) {
            errorList.ApplicantCity = "Please enter a city";
        } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ-\s\.]+$/.test(contract.ApplicantCity)) {
            errorList.ApplicantCity = "The city can only contain letters";
        }         

        //State - select
        if (!contract.ApplicantState) {
            errorList.ApplicantState = "Please select an option";
        } else if (contract.ApplicantState === "State") {
            errorList.ApplicantState = "Please select an option";
        }

        //PostalCode - number
        if (!contract.ApplicantZipCode) {
            errorList.ApplicantZipCode = "Please enter a value";
        } else if (!/^[0-9]{5}$/.test(contract.ApplicantZipCode)) {
            errorList.ApplicantZipCode = "The value must have 5 numbers";
        }












        return errorList;
    };









    //CAMBIAR EL VALOR DE MONTHLY PAYMENTFACTOR
    useEffect(() => {


        if (contract.SaleAmount && paymentFactor) {
            let value = (parseInt(contract.SaleAmount)) - (parseInt(contract.DownPayment));
            calculateMonthlyPayment(paymentFactor, value)

        } else {
            setMonthlyPayment(0)
        }

    }, [contract.SaleAmount, contract.DownPayment])









    //CALCULAR AMOUNT FINANCED
    useEffect(() => {

        if (contract.SaleAmount && contract.DownPayment) {

            let value = ((parseFloat(contract.SaleAmount)) - (parseFloat(contract.DownPayment))).toFixed(2)

            if (value >= 0) {
                setNewAmountFinanced(value)        
            } else {
                setNewAmountFinanced(0)
            }

            setLoadingCalculatedPaymentFactor(true)
            const timer = () => setTimeout(() => {
                if(value > 0){
                    getContractInfo(contract, false, value)
                }else{
                    getContractInfo(false, false, false)
                }
              }, 3000);
          
              clearTimeout(time)
              setTime(timer) 
           

        } else {
            setNewAmountFinanced(parseFloat(loan.AmountFinanced).toFixed(2))
        }

    }, [contract.SaleAmount, contract.DownPayment, loan])








    //CALCULAR MONTHLY PAYMENT
    const calculateMonthlyPayment = (paymentFactorNew, amountF) => {
        let monthlyPaymentNew = parseFloat(amountF).toFixed(2) * parseFloat(paymentFactorNew).toFixed(2)
        setMonthlyPayment(monthlyPaymentNew);
    }









    //RESEND CONTRACT
    const resendContract = () => {

        setLoadingResendContract(true)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_CONTRACT_COMPLETION}${props.application_id}/resend`

        window.fetch(URI, {
            method: 'GET',
            headers: {
                //'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json',
                'username': user,
                'Authorization': token,
                'x-api-key': URI_X_API_KEY_I,
            },


        }).then(response => response.json())
            .then(datos => {
                setLoadingResendContract(false)

                // console.log(datos);

                if (datos.status === 'SUCCESS') {
                    setResendContractError(false)
                    setResendContractSuccess(true)
                } else {
                    setResendContractSuccess(false)
                    setResendContractError(true)
                }




            }).catch((error) => {
                console.log(error)
                setLoadingResendContract(false)
            })

    }




    const copyProposalInfo = () => {

        setLoadingCopyProposal(true)
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-customer-proposal-by-applicant-id/${props.applicantId}`
        //console.log(URI)


        fetch(URI,{
            method: 'GET',
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : URI_X_API_KEY_I,   
              }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
           
            setLoadingCopyProposal(false)
            // console.log(datos)    

            setContract({
                ...contract,
                SaleAmount: datos[0].TotalEquipment + (datos[0].SalesTax || 0),
                DownPayment: datos[0].DownPayment,
                
            })
            setNewAmountFinanced(datos[0].TotalEquipment + datos[0].SalesTax - (datos[0].DownPayment || 0))


            setErrorFlagFromContext(false)

        })
        .catch((error, response) => {
            setLoadingCopyProposal(false)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }    
        });
    }





    return (

        <>
            <div className="tabsMainContainer">


                <div className="applicationFormContainer">


                    
                            <div className="contract-headerContainer">
                                {
                                   ( props.applicationData.LoanApplicationStatus === 'Received' || 
                                    props.applicationData.LoanApplicationStatus === 'PendingDocs' ||
                                    props.applicationData.LoanApplicationStatus === 'InVerification' ||
                                    props.applicationData.LoanApplicationStatus === 'Verified' ||
                                    props.applicationData.LoanApplicationStatus === 'ReadytoFund' ||
                                    props.applicationData.LoanApplicationStatus === 'FundingComplete') &&
                                    <h3 className="applicationFormContainer-tittle_green">Documents Signed</h3>
                                }

                                {
                                    ((contractCompletionStatus && 
                                        props.applicationData.LoanApplicationStatus !== 'PendingDocs' && 
                                        (props.applicationData.LoanApplicationStatus === 'SignReady' || 
                                        props.applicationData.LoanApplicationStatus === 'Received' || 
                                        props.applicationData.LoanApplicationStatus === 'Approved' )) ||
                                        contractSuccessfullySended) &&
                                    <>

                                        <h3 className="applicationFormContainer-tittle_green">Documents Generated</h3>


                                        <div className="contract_header-editButtonContainer">
                                            <button className="contract_header-editButton marginPinnacleContractButton" onClick={() => { setEditContract(!editContract) }}>Redo Complete Contract</button>
                                            {
                                                props.applicationData.LoanApplicationStatus !== 'Approved' ? 

                                                    loadingResendContract ?
                                                        <button className="contract_header-editButton marginPinnacleContractButton pinnacleResendContratLoadingButton" >
                                                            <Spinner
                                                                radius={18}
                                                                color={"white"}
                                                                stroke={3}
                                                                visible={true}
                                                            />
                                                        </button>
                                                        :
                                                        <button className="contract_header-editButton marginPinnacleContractButton" onClick={resendContract}>Resend Contract</button>
                                                
                                                : null
                                            }
                                            
                                        </div>

                                        {editContract && contractCompletionStatus && (props.applicationData.LoanApplicationStatus === 'SignReady' || props.applicationData.LoanApplicationStatus === 'Received' || props.applicationData.LoanApplicationStatus === 'Approved') && <p className="pinnacleContract_resendContractMessage">Contract may now be redone.</p>}


                                        {resendContractSuccess && <p className="pinnacleContract_resendContractSuccess">Contract has been resent successfully</p>}
                                        {resendContractError && <p className="pinnacleContract_resendContractError">Error resending the contract</p>}

                                    </>

                                }
                                
                                





                            </div>

                        
                    

                    {
                        loan.RemittanceUpdateFlag &&
                        <div className="applicationFormContainer-tittle">
                            <h3 className="">Remittance Data</h3>
                        </div>

                    }


                    {
                        (props.isLoadingDetails || loadingCalculatedPaymentFactor) &&
                            <div className="updatingDataMessage">
                            <Spinner
                                radius={18}
                                color={"grey"}
                                stroke={3}
                                visible={true}
                            />
                            <p>Updating data</p>
                        </div>
                    }
                    



                    <h3 className="applicationFormContainer-tittle">Applicant</h3>



                    <div action="" id="form" className="form">



                        {/* ApplicantFirsName */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >First Name</label>
                            <input
                                type="text"
                                name="ApplicantFirstName"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantFirstName ? "imputError" : "campo"}`}
                                value={contract.ApplicantFirstName}
                                placeholder="First Name"
                                disabled={editContract ? null : "disabled"}
                                // disabled="disabled"
                                required
                            />
                            {contractErrors.ApplicantFirstName && <p className="errorMessage">{contractErrors.ApplicantFirstName}</p>}
                        </div>


                        {/* ApplicantLastName */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Last Name</label>
                            <input
                                type="text"
                                name="ApplicantLastName"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantLastName ? "imputError" : "campo"}`}
                                placeholder="Last Name"
                                value={contract.ApplicantLastName}
                                required
                                disabled={editContract ? null : "disabled"}
                                // disabled="disabled"
                            />
                            {contractErrors.ApplicantLastName && <p className="errorMessage">{contractErrors.ApplicantLastName}</p>}
                        </div>


                        {/* ApplicantEmail */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Email</label>
                            <input
                                type="email"
                                name="ApplicantEmail"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantEmail ? "imputError" : "campo"}`}
                                placeholder="Email"
                                value={contract.ApplicantEmail}
                                required
                                disabled={editContract ? null : "disabled"}
                            />
                            {contractErrors.ApplicantEmail && <p className="errorMessage">{contractErrors.ApplicantEmail}</p>}
                        </div>


                        {/* ApplicantAddress */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Address</label>
                            <input
                                type="text"
                                value={contract.ApplicantAddress}
                                name="ApplicantAddress"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantAddress ? "imputError" : "campo"}`}
                                placeholder="Address"
                                required
                                disabled={editContract ? null : "disabled"}
                                // disabled="disabled"
                            />
                            {contractErrors.ApplicantAddress && <p className="errorMessage">{contractErrors.ApplicantAddress}</p>}
                        </div>


                        {/* ApplicantCity */}
                        <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >City</label>
                            <input
                                type="text"
                                value={contract.ApplicantCity}
                                name="ApplicantCity"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantCity ? "imputError" : "campo"}`}
                                placeholder="City"
                                required
                                disabled={editContract ? null : "disabled"}
                                // disabled="disabled"
                            />
                            {contractErrors.ApplicantCity && <p className="errorMessage">{contractErrors.ApplicantCity}</p>}
                        </div>


                        {/* ApplicantState */}
                        <div className="mediowidth-right campoContainer">
                            <label className="labelApplicationForm" >State</label>
                            <select
                                value={contract.ApplicantState}
                                name="ApplicantState"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantState ? "imputError" : "campo"}`}
                                placeholder="State"
                                required
                                disabled={editContract ? null : "disabled"}
                                // disabled="disabled"
                            >
                                <option value="1" disabled selected>
                                    Applicant State
                          </option>
                                <option value="AK">AK</option>
                                <option value="AL">AL</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DC">DC</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="GU">GU</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MS">MS</option>
                                <option value="MO">MO</option>
                                <option value="MT">MT</option>
                                <option value="NE">NE</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="PR">PR</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VA">VA</option>
                                <option value="VI">VI</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>
                            {contractErrors.ApplicantState && <p className="errorMessage">{contractErrors.ApplicantState}</p>}
                        </div>


                        {/* ApplicantZipCode */}
                        <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Zip Code</label>
                            <input
                                type="number"
                                value={contract.ApplicantZipCode}
                                name="ApplicantZipCode"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantZipCode ? "imputError" : "campo"}`}
                                placeholder="Zip Code"
                                required
                                disabled={editContract ? null : "disabled"}
                                // disabled="disabled"
                            />
                            {contractErrors.ApplicantZipCode && <p className="errorMessage">{contractErrors.ApplicantZipCode}</p>}
                        </div>



                        {/*  ApplicantPhone */}
                        <div className="mediowidth-right campoContainer">
                            <label className="labelApplicationForm" >Phone</label>
                            <NumberFormat
                                value={contract.ApplicantPhone}
                                onValueChange={e => handleInputContract(e, 'ApplicantPhone')}
                                displayType="input"
                                type="text"
                                name="ApplicantPhone"
                                placeholder="Do not use dashes"
                                format="(###) ### - ####"
                                className={`${contractErrors.ApplicantPhone ? "imputError" : "campo"}`}
                                // className={stylesForInputMask(errors.CellPhone)}
                                disabled={editContract ? null : "disabled"}
                            />
                            {/* <input
                                type="number"
                                value={contract.ApplicantPhone}
                                name="ApplicantPhone"
                                onChange={handleInputContract}
                                className={`${contractErrors.ApplicantPhone ? "imputError" : "campo"}`}
                                placeholder="Do not use dashes"
                                required
                                disabled={editContract ? null : "disabled"}
                            /> */}
                            {contractErrors.ApplicantPhone && <p className="errorMessage">{contractErrors.ApplicantPhone}</p>}
                        </div>

                    </div>


                    {
                        contract.hasOwnProperty('CoApplicantFirstName') ?
                            <>

                                <h3 className="applicationFormContainer-tittle">Co-Applicant</h3>

                                <div action="" id="form" className="form">



                                    {/* CoApplicantFirsName */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Firs Name</label>
                                        <input
                                            type="text"
                                            name="CoApplicantFirstName"
                                            onChange={handleInputContract}
                                            className={`${contractErrors.CoApplicantFirstName ? "imputError" : "campo"}`}
                                            value={contract.CoApplicantFirstName}
                                            placeholder="Firs Name"
                                            disabled={editContract ? null : "disabled"}
                                            // disabled="disabled"
                                            required
                                        />
                                        {contractErrors.CoApplicantFirstName && <p className="errorMessage">{contractErrors.CoApplicantFirstName}</p>}
                                    </div>



                                    {/* CoApplicantLastName */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Last Name</label>
                                        <input
                                            type="text"
                                            name="CoApplicantLastName"
                                            onChange={handleInputContract}
                                            className={`${contractErrors.CoApplicantLastName ? "imputError" : "campo"}`}
                                            value={contract.CoApplicantLastName}
                                            placeholder="Last Name"
                                            disabled={editContract ? null : "disabled"}
                                            // disabled="disabled"
                                            required
                                        />
                                        {contractErrors.CoApplicantLastName && <p className="errorMessage">{contractErrors.CoApplicantLastName}</p>}
                                    </div>



                                    {/* CoApplicantEmail */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Email</label>
                                        <input
                                            type="text"
                                            value={contract.CoApplicantEmail}
                                            name="CoApplicantEmail"
                                            onChange={handleInputContract}
                                            className={`${contractErrors.CoApplicantEmail ? "imputError" : "campo"}`}
                                            placeholder="Email"
                                            required
                                            disabled={editContract ? null : "disabled"}
                                        />
                                        {contractErrors.CoApplicantEmail && <p className="errorMessage">{contractErrors.CoApplicantEmail}</p>}
                                    </div>


                                    {/* CoApplicantPhone */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Phone</label>
                                        <NumberFormat
                                            value={contract.CoApplicantPhone}
                                            onValueChange={e => handleInputContract(e, 'CoApplicantPhone')}
                                            displayType="input"
                                            type="text"
                                            name="CoApplicantPhone"
                                            placeholder="Do not use dashes"
                                            format="(###) ### - ####"
                                            className={`${contractErrors.CoApplicantPhone ? "imputError" : "campo"}`}
                                            // className={stylesForInputMask(errors.CellPhone)}
                                            disabled={editContract ? null : "disabled"}
                                        />
                                        {/* <input
                                            type="number"
                                            value={contract.CoApplicantPhone}
                                            name="CoApplicantPhone"
                                            onChange={handleInputContract}
                                            className={`${contractErrors.CoApplicantPhone ? "imputError" : "campo"}`}
                                            placeholder="Phone"
                                            required
                                            disabled={editContract ? null : "disabled"}
                                        /> */}
                                        {contractErrors.CoApplicantPhone && <p className="errorMessage">{contractErrors.CoApplicantPhone}</p>}
                                    </div>

                                </div>

                            </>

                            :

                            <>
                                <h3 className="applicationFormContainer-tittle">Co-Applicant</h3>
                                <div action="" id="form" className="form">
                                    <p className="fullwidth campoContainer">This Application does not have a CoApplicant</p>
                                </div>

                            </>
                    }

                    <h3 className="applicationFormContainer-tittle">Goods or Services</h3>

                    <div action="" id="form" className="form">

                        {/* ProductOrService */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Description</label>
                            <input
                                type="text"
                                name="ProductOrService"
                                onChange={handleInputContract}
                                className={`${contractErrors.ProductOrService ? "imputError" : "campo"}`}
                                placeholder="Description"
                                value={contract.ProductOrService}
                                required
                                disabled={editContract ? null : "disabled"}
                            />
                            {contractErrors.ProductOrService && <p className="errorMessage">{contractErrors.ProductOrService}</p>}
                        </div>


                        {/* ManufacturerGoodsServiceSold */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Manufacturer</label>
                            <input
                                type="text"
                                name="ManufacturerGoodsServiceSold"
                                onChange={handleInputContract}
                                className={`${contractErrors.ManufacturerGoodsServiceSold ? "imputError" : "campo"}`}
                                value={contract.ManufacturerGoodsServiceSold}
                                placeholder="Manufacturer"
                                disabled={editContract ? null : "disabled"}
                                required
                            />
                            {contractErrors.ManufacturerGoodsServiceSold && <p className="errorMessage">{contractErrors.ManufacturerGoodsServiceSold}</p>}
                        </div>


                        {/* SerialNumberGoodsServiceSold */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Serial Number (If not known enter 0)</label>
                            <input
                                type="text"
                                value={contract.SerialNumberGoodsServiceSold}
                                name="SerialNumberGoodsServiceSold"
                                onChange={handleInputContract}
                                className={`${contractErrors.SerialNumberGoodsServiceSold ? "imputError" : "campo"}`}
                                placeholder="If not known enter 0"
                                required
                                disabled={editContract ? null : "disabled"}


                            />
                            {contractErrors.SerialNumberGoodsServiceSold && <p className="errorMessage">{contractErrors.SerialNumberGoodsServiceSold}</p>}
                        </div>












                    </div>




                    <h3 className="applicationFormContainer-tittle">Other Information</h3>

                    <div action="" id="form" className="form">

                        <div className="fullwidth" style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                            <Button
                                onClick={copyProposalInfo}
                                value="Copy Proposal"
                                isLoading={loadingCopyProposal}
                                showButton={(props.hasProposal ? true : false) && editContract}
                                size="small"
                            />
                        </div>


                        {/* SaleAmount */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Sale Amount</label>
                            <input
                                type="number"
                                step="0.01"
                                value={contract.SaleAmount}
                                name="SaleAmount"
                                onChange={handleInputContract}
                                onBlur={() => { setContract({ ...contract, 'SaleAmount': parseFloat(contract.SaleAmount).toFixed(2) }) }}
                                className={`${contractErrors.SaleAmount ? "imputError" : "campo"}`}
                                placeholder="Sale Amount"
                                required
                                disabled={editContract ? null : "disabled"}
                            />
                            {contractErrors.SaleAmount && <p className="errorMessage">{contractErrors.SaleAmount}</p>}
                        </div>

                        
                        
                            
                           
                        
                        



                        {/* DownPayment */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Down Payment</label>
                            <input
                                type="number"
                                step="0.01"
                                value={contract.DownPayment}
                                name="DownPayment"
                                onChange={handleInputContract}
                                onBlur={() => { setContract({ ...contract, 'DownPayment': parseFloat(contract.DownPayment).toFixed(2) }) }}
                                className={`${contractErrors.DownPayment ? "imputError" : "campo"}`}
                                placeholder="Down Payment"
                                required
                                disabled={editContract ? null : "disabled"}
                            />
                            {contractErrors.DownPayment && <p className="errorMessage">{contractErrors.DownPayment}</p>}
                        </div>


                        {/* AmountFinanced */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Amount Financed</label>
                            <input
                                type="number"
                                step="0.01"
                                value={newAmountFinanced}
                                name="AmountFinanced"
                                onChange={handleInputContract}
                                className={`${contractErrors.AmountFinanced ? "imputError" : "campo"}`}
                                placeholder="Down Payment"
                                required
                                disabled
                            />
                            {contractErrors.AmountFinanced && <p className="errorMessage">{contractErrors.AmountFinanced}</p>}
                        </div>





                        {/* LoanProgram */} 
                        <div className="fullwidth campoContainer">
                            {/* <label className="labelApplicationForm" >{createCompleteManualApplications ? 'MANUAL' : '-BLANKET'} </label> */}
                            <label className="labelApplicationForm" >Loan Program </label>
                            <select
                                value={contract.LoanProgram}
                                name="LoanProgram"
                                onChange={handleInputContract}
                                className={`${contractErrors.LoanProgram ? "imputError" : "campo"}`}
                                placeholder="Applicant State"
                                required
                                disabled={editContract ? null : "disabled"}
                            >
                                <option value="" selected disabled>Loan Program</option>
                                {
                                    loanProgramsValues.map(value => <option value={value.Name} key={value.Name}>{value.Description}</option>) 
                                }
                                {/* <option value="StandardFinancing" >Standard Financing</option>
                                <option value="90 Days SAC" >90 Days Same-as-Cash</option>
                                <option value="180 Days SAC" >180 Days Same-as-Cash</option>
                                <option value="365 Days SAC" >365 Days Same-as-Cash</option>
                                <option value="18 Months SAC" >18 Months Same-as-Cash</option>
                                <option value="24 Months SAC" >24 Months Same-as-Cash</option>
                                <option value="36 Months SAC" >36 Months Same-as-Cash</option>
                                <option value="90 Days NP" >90 Days No Payments</option>
                                <option value="90 Days NPNI" >90 Days No Payment & No Interest</option> */}
                            </select>
                            {contractErrors.LoanProgram && <p className="errorMessage">{contractErrors.LoanProgram}</p>}
                        </div>

                        {
                            loadingCalculatedPaymentFactor ?
                                <div className="fullwidth spineerIntoInput">
                                    <Spinner
                                        radius={25}
                                        color={"grey"}
                                        stroke={4}
                                        visible={true}
                                    />
                                </div>
                                : null
                        }
                                
                        







                        {/* LoanTerm - RepaymentTerms */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Loan Term</label>
                            <select
                                value={contract.RepaymentTerms}
                                name="RepaymentTerms"
                                onChange={handleInputContract}
                                className={`${contractErrors.RepaymentTerms ? "imputError" : "campo"}`}
                                placeholder="Loan Term"
                                required
                                disabled={editContract ? null : "disabled"}
                            >
                                <option value="1" selected disabled >Loan Term</option>
                                {
                                   loanTermsValues.map(terms => <option value={terms} key={terms}>{terms}</option>) 
                                }
                                {/* <option value="36" >36 Months</option>
                                <option value="60" >60 Months</option>
                                <option value="96" >96 Months</option>
                                {newAmountFinanced > 5000 && createCompleteManualApplications && <option value="120" >120 Months</option>} */}
                            </select>
                            {contractErrors.RepaymentTerms && <p className="errorMessage">{contractErrors.RepaymentTerms}</p>}
                        </div>

                        {
                            loadingCalculatedPaymentFactor ?
                                <div className="fullwidth spineerIntoInput">
                                    <Spinner
                                        radius={25}
                                        color={"grey"}
                                        stroke={4}
                                        visible={true}
                                    />
                                </div>
                                : null
                        }




                        {/* APR */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >APR</label>
                            <select
                                value={contract.APR}
                                name="APR"
                                onChange={handleInputContract}
                                className={`${contractErrors.APR ? "imputError" : "campo"}`}
                                placeholder="APR"
                                required
                                disabled={editContract ? null : "disabled"}
                            >
                                <option value={false} selected disabled >APR</option>
                                {
                                    APRValues.map(apr => <option value={apr} key={apr}>{apr}</option>)
                                }
                                {/* {createCompleteManualApplications && <option value="7.99" >7.99</option>}
                                {(!createCompleteManualApplications || (tierContract === 'SILVER' || tierContract === 'GOLD' || tierContract === 'PLATINUM')) && <option value="9.99" >9.99</option>}
                                {(!createCompleteManualApplications || (tierContract === 'SILVER' || tierContract === 'GOLD')) && <option value="11.99" >11.99</option>}
                                {(!createCompleteManualApplications || tierContract === 'SILVER') && <option value="13.99" >13.99</option>} */}
                            </select>
                            {contractErrors.APR && <p className="errorMessage">{contractErrors.APR}</p>}
                            <p className="labelApplicationForm">*Picking a lower APR rate increases costs to the dealer.</p>
                            <br/>
                        </div>

                        {
                            loadingCalculatedPaymentFactor ?
                                <div className="fullwidth spineerIntoInput">
                                    <Spinner
                                        radius={25}
                                        color={"grey"}
                                        stroke={4}
                                        visible={true}
                                    />
                                </div>
                                : null
                        }







                        {/* MonthlyPayment */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Monthly Payment</label>
                            <input
                                type="text"
                                step="0.01"
                                name="MonthlyPayment"
                                onChange={handleInputContract}
                                className={`${contractErrors.MonthlyPayment ? "imputError" : "campo"}`}
                                value={monthlyPayment || 0}
                                placeholder="Buy Rate Percent"
                                disabled="disabled"
                                required
                            />
                            {contractErrors.MonthlyPayment && <p className="errorMessage">{contractErrors.MonthlyPayment}</p>}
                        </div>

                        {
                            loadingCalculatedPaymentFactor ?
                                <div className="fullwidth spineerIntoInput">
                                    <Spinner
                                        radius={25}
                                        color={"grey"}
                                        stroke={4}
                                        visible={true}
                                    />
                                </div>
                                : null
                        }



                        {/* PaymentFactor */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Payment Factor</label>
                            <input
                                type="text"
                                name="PaymentFactor"
                                onChange={handleInputContract}
                                className={`${contractErrors.PaymentFactor ? "imputError" : "campo"}`}
                                value={paymentFactor || 0}
                                placeholder="Payment Factor"
                                disabled="disabled"
                                required
                            />
                            {contractErrors.PaymentFactor && <p className="errorMessage">{contractErrors.PaymentFactor}</p>}
                        </div>



                        {
                            loadingCalculatedPaymentFactor ?
                                <div className="fullwidth spineerIntoInput">
                                    <Spinner
                                        radius={25}
                                        color={"grey"}
                                        stroke={4}
                                        visible={true}
                                    />
                                </div>
                                : null
                        }








                        {/* LoanDate */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Loan Date</label>
                            <input
                                type="date"
                                name="LoanDate"
                                onChange={handleInputContract}
                                className={`${contractErrors.LoanDate ? "imputError" : "campo"}`}
                                value={contract.LoanDate}
                                placeholder="yyyy-mm-dd"
                                disabled={editContract ? null : "disabled"}
                                required
                            />
                            {contractErrors.LoanDate && <p className="errorMessage">{contractErrors.LoanDate}</p>}
                        </div>


                        {/* FirstPaymentDate */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >First Payment Date</label>
                            <input
                                type="date"
                                name="FirstPaymentDate"
                                onChange={handleInputContract}
                                className={`${contractErrors.FirstPaymentDate ? "imputError" : "campo"}`}
                                value={contract.FirstPaymentDate}
                                placeholder="yyyy-mm-dd"
                                disabled="disabled"
                                required
                            />
                            {contractErrors.FirstPaymentDate && <p className="errorMessage">{contractErrors.FirstPaymentDate}</p>}
                        </div>





                        {/* CompletionDate */}
                        <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Estimated Installation Date</label>
                            <input
                                type="date"
                                name="CompletionDate"
                                onChange={handleInputContract}
                                className={`${contractErrors.CompletionDate ? "imputError" : "campo"}`}
                                value={contract.CompletionDate}
                                placeholder="yyyy-mm-dd"
                                disabled={editContract ? null : "disabled"}
                                required
                            />
                            {contractErrors.CompletionDate && <p className="errorMessage">{contractErrors.CompletionDate}</p>}
                        </div>


                    </div>





                    <h3 className="applicationFormContainer-tittle">ACH Bank Information</h3>

                    <div action="" id="form" className="form">

                        {createCompleteManualApplications && editContract &&
                            <div className="fullwidth campoContainer checkCopyAddressInfo">
                                <input type="checkbox"
                                    name="isActive"
                                    {...incluideBankNumbers && { checked: "checked" }}
                                    className="inputCopyAddressInfo"
                                    onClick={() => setIncluideBankNumbers(!incluideBankNumbers)} /> <label className="labelApplicationForm" > Include ACH Bank Information</label>
                            </div>
                        }


                        {/* BankAccountType */}
                        {
                            (incluideBankNumbers || !createCompleteManualApplications) &&
                            <div className="fullwidth campoContainer">
                                <label className="labelApplicationForm" >Bank Account Type</label>
                                <select
                                    value={contract.BankAccountType}
                                    name="BankAccountType"
                                    onChange={handleInputContract}
                                    className={`${contractErrors.BankAccountType ? "imputError" : "campo"}`}
                                    placeholder="Applicant State"
                                    required
                                    disabled={editContract ? null : "disabled"}
                                >
                                    <option value="1" selected disabled >Bank Account Type</option>
                                    <option value="C" >Checking</option>
                                    <option value="S" >Savings</option>
                                </select>
                                {contractErrors.BankAccountType && <p className="errorMessage">{contractErrors.BankAccountType}</p>}
                            </div>
                        }





                        {/* BankRoutingNumber */}
                        {
                            (incluideBankNumbers || !createCompleteManualApplications) &&
                            <div className="fullwidth campoContainer">
                                <label className="labelApplicationForm" >Bank Routing Number</label>
                                <input
                                    type="number"
                                    name="BankRoutingNumber"
                                    onChange={handleInputContract}
                                    className={`${contractErrors.BankRoutingNumber ? "imputError" : "campo"}`}
                                    value={contract.BankRoutingNumber}
                                    placeholder="Bank Routing Number"
                                    disabled={editContract ? null : "disabled"}
                                    required
                                />
                                {contractErrors.BankRoutingNumber && <p className="errorMessage">{contractErrors.BankRoutingNumber}</p>}
                            </div>
                        }




                        {/* BankAccountNumber */}
                        {
                            (incluideBankNumbers || !createCompleteManualApplications) &&
                            <div className="fullwidth campoContainer">
                                <label className="labelApplicationForm" >Bank Account Number</label>
                                <input
                                    type="number"
                                    name="BankAccountNumber"
                                    onChange={handleInputContract}
                                    className={`${contractErrors.BankAccountNumber ? "imputError" : "campo"}`}
                                    value={contract.BankAccountNumber}
                                    placeholder="Bank Account Number"
                                    disabled={editContract ? null : "disabled"}
                                    required
                                />
                                {contractErrors.BankAccountNumber && <p className="errorMessage">{contractErrors.BankAccountNumber}</p>}
                            </div>
                        }








                        {
                            editContract ?

                                <input
                                    type="submit"
                                    id="submit"
                                    onClick={handleSubmitContract}
                                    className="fullwidth submit campo"
                                    placeholder="Complete contract"
                                    value="Complete contract"
                                    required

                                />

                                : null
                        }



                    </div>


                    {
                        showNotificationContractSendedOk.show ?
                            <Notification  {...isSafari && { flexRight: true }} 
                                type='popup' title={showNotificationContractSendedOk.text} 
                                f={() => { setShowNotificationContractSendedOk({ error: false }); props.setReloadGetContract(state => !state) }}>
                                {
                                    showNotificationContractSendedOk.type === 'ok' ?
                                        <img src={iconCheck} alt="check" />
                                        :
                                        <img src={iconCheckOrange} alt="check" style={{ height: '150px' }} />
                                }

                            </Notification>
                            : null
                    }

                    {
                        showNotificationPostContractError.error ?
                            <Notification  {...isSafari && { flexRight: true }} type='popup' title={showNotificationPostContractError.text} f={() => { setShowNotificationPostContractError({ error: false }) }}>
                                <img src={iconSadFace} alt="check" />
                            </Notification>
                            : null
                    }



                    {loadingPostContract ? <SpinnerPopUp /> : null}

                

                    {
                        (contractCompletionStatus) && 
                        <PinnacleContractStatusTab date={contractStatusDate} applicationId={props.application_id} reloadStatusTab={reloadStatusTab} />
                    }



                </div>

                {/* :

        <div className="form">
          <p className="fullwidth">Contracts can be completed once the application is Approved</p>
        </div>

        } */}
            </div>
        </>




    )
}

export default PinnacleContract;
