import React, {useState, useEffect} from 'react'
import ExternalForm from './ExternalForm'
import ConvertProspect from './ConvertProspect'

const CommonApplicationInternalProcess = ({urlParamApplicantId, setStep2}) => {
    
    const [step, setStep] = useState(setStep2 ? 2 : 1)
    const [applicationData, setApplicationData] = useState({})
    const [financialInstitutions, setFinancialInstitutions] = useState([])
    const [applicantIdFromPostCommon, setApplicantIdFromPostCommon] = useState()
    const [urlApplicantId, setUrlApplicantId] = useState(urlParamApplicantId)

    useEffect(()=>{
        setUrlApplicantId(urlParamApplicantId)
    }, [urlParamApplicantId])

    return (
        <>  
            {
                step === 1 ?

                <ExternalForm 
                    internal 
                    setStep={setStep} 
                    setApplicationData={setApplicationData} 
                    type="create" 
                    setFinancialInstitutions={setFinancialInstitutions} 
                    setApplicantIdFromPostCommon={setApplicantIdFromPostCommon}
                    urlParamApplicantId={urlApplicantId}

                />

                :

                <ConvertProspect 
                    appData={applicationData} 
                    internal 
                    financialInstitutions={financialInstitutions} 
                    applicantIdFromPostCommon={applicantIdFromPostCommon}
                    goToListView
                    urlParamApplicantId={urlApplicantId}
                    setStep={setStep} 
                />
            }
            
        </>
    )
}

export default CommonApplicationInternalProcess;
