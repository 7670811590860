import React, { useState, useEffect, useContext } from 'react'
import "../../assets/styles/ApplicationForm.css";
import "../../assets/styles/StepCounter.css";
import "../../assets/styles/ExternalForm.css";
import Notification from '../Notification';
import { AuthContext } from '../../AuthContext';
import iconCheck from '../../assets/images/iconCheck.png';
import SpinnerPopUp from '../SpinnerPopUp';
import Spinner from 'react-spinner-material';
import iconArrowBack from '../../assets/images/iconArrowBack.png';
import iconSadFace from '../../assets/images/iconSadFace.png';
import iconDelete from '../../assets/images/iconDelete.png';
import useIsSafari from '../../hooks/useIsSafari'
import AquaFinanceContractStatusTab from './AquaFinanceContractStatusTab';
import IconLinkOutSide from '../../assets/images/iconLinkOutSide.png'
import AquaResendContractButton from './AquaResendContractButton';

const AquaFinanceContractACH = (props) => {
    //ESTADOS

    const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);

    const isSafari = useIsSafari()




    const [includeTax, setIncludeTax] = useState(false);
    const [changeInputTax, setChangeInputTax] = useState(false);


    const [applicationDetails, setApplicationDetails] = useState({})




    //ESTADOS DE CONTRACT
    const [contract, setContract] = useState({})
    const [contractErrors, setContractErrors] = useState({})
    const [isSubmittingContract, setIsSubmittingContract] = useState(false);
    const [editContract, setEditContract] = useState(false);
    const [loadingPostContract, setLoadingPostContract] = useState(false);
    const [contractCompletionStatus, setContractCompletionStatus] = useState(false)
    const [refreshStatusContract, setRefreshStatusContract] = useState(false)
    const [contractType, setContractType] = useState('Email')




    const [showNotificationOk, SetShowNotificationOk] = useState(false);
    const [showNotificationError, SetShowNotificationError] = useState(false);
    const [showNotificationApplicantAlreadyExists, setShowNotificationApplicantAlreadyExists] = useState(false)



    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');








    useEffect(() => {
        // console.log(props.applicationData)
        if(Object.keys(props.applicationData).length > 0){
            fillContractData(props.applicationData)
        }
    }, [props.applicationData])





    //RELLENAR FORMULARIO DE CONTRACT
    const fillContractData = (data) => {
        //console.log(data)

        setApplicationDetails(data)


        // if (!data.ACHStatus && (data.LoanApplicationStatus === 'Approved' ||  data.LoanApplicationStatus === 'Received' ||  data.LoanApplicationStatus === 'PendingDocs' ||  data.LoanApplicationStatus === 'ConditionallyApproved' ||  data.LoanApplicationStatus === 'Problem' || data.LoanApplicationStatus === 'Pending')) {
        if (!data.ACHStatus && data.LoanApplicationStatus !== 'FundingComplete' && data.LoanApplicationStatus !== 'FundingComplete') {  
            setEditContract(true)
        } else if ((data.ACHStatus === 'Pending' || data.ACHStatus === 'Signed') && data.LoanApplicationStatus !== 'FundingComplete' && data.LoanApplicationStatus !== 'FundingComplete') {
            setEditContract(false)
            setContractCompletionStatus(true)
        }



        const contractData = {
            BankAccountType: data.LoanParameters.BankAccountType || '',
            BankRoutingNumber: data.LoanParameters.BankRoutingNumber || '',
            BankAccountNumber: data.LoanParameters.BankAccountNumber || '',
            ApplicantEmail: data.Applicant.EmailAddress || '',
            ContractLanguage: data.LoanParameters.ContractLanguage,
        }

        


        // setContractSalesAmount(parseInt(data.LoanParameters.SaleAmount))

        setContract({ ...contract, ...contractData })



    } //end





    //SUBMIT CONTRACT
    const postContract = (event) => {

        setLoadingPostContract(true);


        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}submit-ach/${applicationDetails.ApplicationId}`
        // console.log(URI)  



        // console.log(contract)

        const obj = {
            ...contract,
            Email: contract.ApplicantEmail,
            SignatureType: contractType,
            ContractLanguage: contract.ContractLanguage
        }

        delete obj.ApplicantEmail

        //console.log(obj)
       //console.log(JSON.stringify(obj))


        window.fetch(URI, {
        method: 'POST',
        headers: {
          //'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token,
          'x-api-key' : uri.URI_X_API_KEY,
        },
        body: JSON.stringify(obj),

        }).then(response => response.json())
        .then(datos => {
          setLoadingPostContract(false)
          //console.log('OBJETO QUE RESPONDE EL SERVIDOR')
        //   console.log(datos);


          if(datos.status === 'AQUAFINANCE_API_ERROR'){
            SetShowNotificationError(true)
          }else if (datos.status === 'SUCCESS'){
            SetShowNotificationError(false)
            const container = document.getElementById('shetContainer')
            // container.scrollTo(0, 0)
            setRefreshStatusContract(!refreshStatusContract)
            props.SetReloadGetApplicationDetail(reloadGetApplicationDetail => !reloadGetApplicationDetail)
          }else{
            SetShowNotificationError(true)
          }

         


        }).catch((error)=>{
          console.log(error)
          setLoadingPostContract(false)
          SetShowNotificationError(true)
        })          

    }; //end




    //HANDLE INPUT CONTRACT
    const handleInputContract = event => {
        let value = event.target.value;

        setContract({
            ...contract,
            [event.target.name]: value,
        });

    }; //end




    //HANDLE SUBMIT CONSTRACT
    const handleSubmitContract = event => {
        event.preventDefault();
        //handle errors
        
        setContractErrors(validateFormContract(contract))
        setIsSubmittingContract(true);
     
          
    }; //end




    useEffect(() => {
        if (Object.keys(contractErrors).length === 0 && isSubmittingContract) {
            postContract()

        }
    }, [contractErrors, isSubmittingContract]);



   

    //validate contract
    const validateFormContract = (applicant) => {
        let errorList = {};

        //BankAccountType
        if (!applicant.BankAccountType) {
            errorList.BankAccountType = "Please select an option";
        }


        //BankRoutingNumber *
        if (!applicant.BankRoutingNumber) {
            errorList.BankRoutingNumber = "Please enter a value";
        } else if (!/^[0-9]{9}$/.test(applicant.BankRoutingNumber)) {
            errorList.BankRoutingNumber = "The Bank Routing Number must have 9 numbers";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankRoutingNumber)) {
            errorList.BankRoutingNumber = "The value can only contain numbers";
        }



        //BankAccountNumber *
        if (!applicant.BankAccountNumber) {
            errorList.BankAccountNumber = "Please enter a value";
        }else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankAccountNumber)) {
            errorList.BankAccountNumber = "The value can only contain numbers";
        }

        //EmailAddress *
        if (!applicant.ApplicantEmail) {
            errorList.ApplicantEmail = "Please enter a email";
        } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.ApplicantEmail)) {
            errorList.ApplicantEmail = "Please enter a valid email";
        }


        //ContractLanguage
        if (!applicant.ContractLanguage) {
             errorList.ContractLanguage = "Please select an option";
        }

        return errorList;
    };






    return (
        <>

            <div className="documentSectionContentMain">


                {
                    contractCompletionStatus  &&
                    <div className="contract-headerContainer">
                       <h3 className="applicationFormContainer-tittle_green">Contract generated</h3>
                       <div className="contract-headerContainer_buttonsContainer">
                            <button className="contract_header-editButton" onClick={() => { setEditContract(!editContract) }}>Redo ACH Bank Information</button>
                            <AquaResendContractButton applicationId={applicationDetails.ApplicationId} contract='ACH'/>
                       </div>
                       
                    </div>
                              
                }

                {editContract && contractCompletionStatus && <p className="pinnacleContract_resendContractMessage">Contract may now be redone.</p>}


                {
                  editContract &&
                  <div className="fullwidth campoContainer">
                    <div className="createApplicationPinnacle_switchButton">
                        <p className="createApplicationPinnacle_switchText margin-right_10">Sign via email  </p>
                        <label className="switch">
                        <input type="checkbox" id="checkStubApplication"  {...contractType === 'Electronic' && { checked: "checked" } } name="isActive" id="isActive" onClick={()=> setContractType(contractType === 'Email' ? 'Electronic' : 'Email')} />      
                        <span className="slider round"></span>
                        </label>
                        
                        <p className="createApplicationPinnacle_switchText">Sign in-person</p>
                    </div>
                  </div>
                }


                <h3 className="applicationFormContainer-tittle">Contract Language</h3>

                <div action="" id="form" className="form">
                    <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Contract Language</label>

                        <select
                        value={contract.ContractLanguage}
                        name="ContractLanguage"
                        onChange={handleInputContract}
                        className={`${contractErrors.ContractLanguage ? "imputError" : "campo"}`}
                        placeholder="ContractLanguage"
                        required
                        disabled={editContract ? null : "disabled"}
                        >
                        <option disabled selected>Contract Language</option>
                        <option value="English">English</option> 
                        <option value="Spanish">Spanish</option> 
                        </select>
                        {contractErrors.ContractLanguage && <p className="errorMessage">{contractErrors.ContractLanguage}</p>}
                    </div>

                </div>



                <h3 className="applicationFormContainer-tittle">Bank Information</h3> 



                <div action="" id="form" className="form">

                    {/* ApplicantEmail */}
                    <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Email</label>
                        <input
                            type="email"
                            name="ApplicantEmail"
                            onChange={handleInputContract}
                            className={`${contractErrors.ApplicantEmail ? "imputError" : "campo"}`}
                            placeholder="Email"
                            value={contract.ApplicantEmail}
                            required
                            disabled={editContract ? null : "disabled"}
                        />
                        {contractErrors.ApplicantEmail && <p className="errorMessage">{contractErrors.ApplicantEmail}</p>}
                    </div>



                    {/* BankAccountType */}
                    <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Bank Account Type</label>
                        <select
                            value={contract.BankAccountType}
                            name="BankAccountType"
                            onChange={handleInputContract}
                            className={`${contractErrors.BankAccountType ? "imputError" : "campo"}`}
                            placeholder="Applicant State"
                            required
                            disabled={editContract ? null : "disabled"}
                        >
                            <option value="" selected disabled >Bank Account Type</option>
                            <option value="C" >Checking</option>
                            <option value="S" >Savings</option>
                        </select>
                        {contractErrors.BankAccountType && <p className="errorMessage">{contractErrors.BankAccountType}</p>}
                    </div>


                    {/* BankRoutingNumber */}
                    <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Bank Routing Number</label>
                        <input
                            type="number"
                            name="BankRoutingNumber"
                            onChange={handleInputContract}
                            className={`${contractErrors.BankRoutingNumber ? "imputError" : "campo"}`}
                            value={contract.BankRoutingNumber}
                            placeholder="Bank Routing Number"
                            disabled={editContract ? null : "disabled"}
                            required
                        />
                        {contractErrors.BankRoutingNumber && <p className="errorMessage">{contractErrors.BankRoutingNumber}</p>}
                    </div>



                    {/* BankAccountNumber */}
                    <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Bank Account Number</label>
                        <input
                            type="number"
                            name="BankAccountNumber"
                            onChange={handleInputContract}
                            className={`${contractErrors.BankAccountNumber ? "imputError" : "campo"}`}
                            value={contract.BankAccountNumber}
                            placeholder="Bank Account Number"
                            disabled={editContract ? null : "disabled"}
                            required
                        />
                        {contractErrors.BankAccountNumber && <p className="errorMessage">{contractErrors.BankAccountNumber}</p>}
                    </div>



                   


                    {
                        !props.sendACHToo &&
                        
                        (editContract &&
                         (
                            loadingPostContract ?
          
                            <div  className="mediowidth-right submit campo middle">
                            <Spinner
                              radius={25}
                              color={"white"}
                              stroke={3}
                              visible={true}
                            />
                           </div>
            
                          :
                            <input
                              type="submit"
                              id="submit"
                              onClick={handleSubmitContract}
                              className="mediowidth-right submit campo"
                              placeholder="Save and Send"
                              value="Submit Contract"
                              required
                            />
            
                          ))

                    }


                    {
                    showNotificationError &&
                    <div className="mediowidth-right">
                    <p className="text_Red">An unexpected error has occurred, please try again later</p>
                    </div>
                    }


                    

                </div>

                {
                    (applicationDetails.ACHESignUrl && !editContract) &&
                    <a href={applicationDetails.ACHESignUrl} target="_blank" className="contract_header-editButton-white" >Open to Sign ACH Bank Information <img className="iconLinkOutSide" src={IconLinkOutSide}/></a>
                }





            </div>



            {
                (contractCompletionStatus || props.applicationData.ACHStatus === 'Pending' || props.applicationData.ACHStatus === 'Signed') &&
                <AquaFinanceContractStatusTab 
                    applicationId={props.applicationData.ApplicationId} 
                    type="ACH" 
                    refreshStatusContract={refreshStatusContract} 
                    showReloadButton={props.applicationData.LoanApplicationStatus === 'FundingComplete' ? false : true} 
                    userName={props.applicationData.username}
                    reloadGetSignContractStatuses={props.reloadGetSignContractStatuses}
                />
            }




        </>
    )






}

export default AquaFinanceContractACH;
