import React, {useState, useEffect, useContext} from 'react'
import '../assets/styles/NotificationManagementBanner.css'
import Spinner from 'react-spinner-material';
import { AuthContext } from '../AuthContext';
import SkeletonLoader from "tiny-skeleton-loader-react";

const NotificationManagementBanner = () => {

    const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);

    const [alertList, setAlertList] = useState([])

    const [alertForm, setAlertForm] = useState({type: '', text: ''})
    const [loadingCreateAlert, setLoadingCreateAlert] = useState(false)
    const [loadingGetAlert, setLoadingGetAlert] = useState(false)
    const [alertSuccesfullyCreated, setAlertSuccesfullyCreated] = useState(false)
    const [useSwitch, setUseSwitch] = useState(false)
    const [reloadGetAlerts, setReloadGetAlerts] = useState(false)
    const [showNoAlertCreated, setNoAlertCreated] = useState(false)
    const [showErrorGetingAlerts, setShowErrorGetingAlerts] = useState(false)
    const [limitCharacters, setLimitCharacters] = useState(false)



    const token = window.localStorage.getItem('token');



    //GET ALERTS
    useEffect(()=>{
        setLoadingGetAlert(true)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}app/get-banner-alerts`
        console.log(URI)
       

       const abortController = new AbortController();
       const signal = abortController.signal;


        const header = {
        'Content-Type': 'application/json', 
        'Authorization' : token,   
        'x-api-key' :  uri.URI_X_API_KEY, 
        }



       window.fetch(URI,{
           method: 'GET',
           signal: signal,
           headers: header,
       })
       .then(response => response.json())
       .then(datos => {
            setLoadingGetAlert(false)
            // console.log(datos);

            if(datos.status === 'INTERNAL_SERVER_ERROR' ){
                console.log('INTERNAL_SERVER_ERROR')
                setShowErrorGetingAlerts(true)
                setNoAlertCreated(false)
            }else if(datos.status === 'NO_BANNER_ALERTS_FOUND'){
                setShowErrorGetingAlerts(false)
                setNoAlertCreated(true)
            }
            else{
                setShowErrorGetingAlerts(false)
                setNoAlertCreated(false)
                setAlertList(datos)
            }

            
           

       }).catch(error => {
           console.log(error)   
        });

          return function cleanup() {
            abortController.abort();
          }

    }, [reloadGetAlerts])




    //SUBMIT
    const createAlert = (isFromSwitch, newObject) => {
       
        if(!isFromSwitch){
            if(alertForm.Text.length > 1024){
                setLimitCharacters(true)
                return
            }
        }
       

        setLoadingCreateAlert(true)


        const newAlert = [{
            Text : alertForm.Text,
            Type : alertForm.Type,
            IsActive : true,
        }]

        // console.log(newAlert)

    
      

        let body = []
        if(isFromSwitch){
            body = [newObject]
        }else{
            body = newAlert
        }
    
        // console.log(body)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}app/create-banner-alerts`;
        // console.log(URI)


       
    
    
        window.fetch(URI, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': uri.URI_X_API_KEY,
            'Authorization': token,
          },
          body: JSON.stringify(body),
   
        })
          .then(response => response.json())
          .then(datos => {
            setLoadingCreateAlert(false);
    
            // console.log(datos);
            if(!isFromSwitch){
                setReloadGetAlerts(!reloadGetAlerts) 
            }



            setAlertForm({Type: '', Text: ''})
             
    
          }).catch(error => {
              console.log(error)
          })

  }






    //DELETE
    const deleteAlert=(id, index)=>{
        setLoadingGetAlert(true)

        // const newList = alertList.filter(alert => alert.Id !== id) 
        // console.log(newList)
        // setAlertList(newList);

        // createAlert(true)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}app/delete-banner-alert/${id}`;
        // console.log(URI)

    
        window.fetch(URI, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': uri.URI_X_API_KEY,
            'Authorization': token,
          }
   
        })
          .then(response => response.json())
          .then(datos => {
            // setLoadingGetAlert(false)
    
            // console.log(datos);

            if(datos.status === 'SUCCESS'){
                setReloadGetAlerts(!reloadGetAlerts)
            }
   
             
    
          }).catch(error => {
              console.log(error)
          })



    }







    //SWITCH
    const handleAlertSwitch =(event, value, id)=>{

        const newList = alertList.filter(alert => alert.Id === id) 
        
        const newObject = {
            Text: newList[0].Text,
            Type: newList[0].Type,
            IsActive: value,
            Id: newList[0].Id
        }

        const newList2 = alertList.map(alert =>{  
            if(alert.Id === id){     
                alert.IsActive = value 
                return alert
            }else{
                return alert
            }
        })
            
        setAlertList(newList2)
        createAlert(true, newObject)
        
    }




    //LOADING ALERTS
    const loadingAlerts = () => {
        const list = [1,2,3]
        return (list.map((item, index) =>{
            return (<div className="loadingCards_container">
                        <SkeletonLoader width="100%" height="40px"/>
                        <SkeletonLoader width="100%" height="40px"/>
                        <SkeletonLoader width="100%" height="40px"/>
                    </div>
                    )
        }))
        
    }







    return (
        <div className="alertManagement_mainContainer">

            {
                loadingGetAlert ? 
                    loadingAlerts()
                :
                alertList.map((alert, index) => {
                    return (
                        <div className="alertItemCard ">

                            <p className={alert.Type === 'Primary' ? "alertItemCard-primary" : 
                                          alert.Type === 'Secundary' ? "alertItemCard-secundary" : 
                                          alert.Type === 'Success' ? "alertItemCard-success" : 
                                          alert.Type === 'Error' ? "alertItemCard-error" : 
                                          alert.Type === 'Warning' ? "alertItemCard-warning" : 
                                          "alertItemCard-secundary"   
                                            
                                            
                                            }>
                                {alert.Text}
                            </p>
            
                            <label className="switch">
                            <input
                                type="checkbox"
                                {...alert.IsActive ? { checked: "checked" } : null}
                                {...loadingCreateAlert && { disabled: "disabled" }}
                                onChange={(e)=>{handleAlertSwitch(e, !alert.IsActive, alert.Id)}}
                            />
                            <span className="slider round"></span>
                            </label>
            
                            <button className="alertDeleteButton" onClick={()=>deleteAlert(alert.Id)}>
                                Delete
                            </button>
            
        
                        </div>

                    )
                })
            }   

            {showNoAlertCreated &&  <p>No alerts created</p> }

            {showErrorGetingAlerts && <p>Error</p>}


            <h3 className="alert_subtitle">Create Alert</h3>


            <div className="settings-changePassword">
            <select
              name="Type"
              className="settings-changePassword_input"
              onChange={(e) => { setAlertForm({ ...alertForm, Type: e.target.value }) }}
              value={alertForm.Type}
            >
                <option value="1" selected disabled >Type</option>
                <option value="Primary" >Primary</option>
                <option value="Secondary" >Secondary</option>
                <option value="Success" >Success</option> 
                <option value="Error" >Error</option>
                <option value="Warning" >Warning</option>
            </select>

            <textarea 
                className="settings-changePassword_input alertTextArea"  
                placeholder="Message"
                name="Text"
                value={alertForm.Text}
                onChange={(e) => { setAlertForm({ ...alertForm, Text: e.target.value }) }}
            />

   
          

            {loadingCreateAlert ?
              <div className="settings-changePassword_button">
                <Spinner
                  radius={20}
                  color={"white"}
                  stroke={3}
                  visible={true}
                />
              </div>
              :
              <button className="settings-changePassword_button" onClick={()=>createAlert(false)}>Create alert</button>

            }

            {limitCharacters && <p className="alerts_createErrorMessage">The maximum limit of characters allowed is 1024.</p>}



            {/* {alertSuccesfullyCreated && <p className="settings-message_ok">Alert created successfully.</p> } */}



          </div>



     
        </div>
    )
}

export default NotificationManagementBanner;
