import React from 'react';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import '../assets/styles/globalStyles.css'
import Login from '../containers/Login';
import Register from '../containers/Register';
import ChangePassword from '../containers/ChangePassword';
import NotFound from '../containers/NotFound';
import Block from '../containers/Block';
import Layout from '../components/Layout';
//import Context from '../Context';
import ProtectedRoute from './ProtectedRoute'
import { AuthProvider } from '../AuthContext'
import Users from '../containers/Users';
import Library from '../containers/Library';
import Settings from '../containers/Settings';
import CreateApplication from '../containers/CreateApplication';
import Dealers from '../containers/Dealers';
import ResetPassword from '../containers/ResetPassword';
import Applicants from '../containers/Applicants';
import ExternalCreateApplication from '../containers/ExternalCreateApplication'
import CompleteApplication from '../containers/CompleteApplication'
import IframeContainer from '../containers/iframeContainer';
import ApplicationDetailContainer from '../containers/ApplicationDetailContainer';
import CreateCommonApplication from '../containers/CreateCommonApplication';
import Proposals from '../containers/Proposals';
import CreateProposal from '../containers/CreateProposal';
import Reports  from '../containers/Reports';
import CreateSystemPackage from '../containers/CreateSystemPackage';
import EditSystemPackage from '../containers/EditSystemPackage';
import Logs from '../containers/Logs';
import CreateCommercialLoan from '../containers/CreateCommercialLoan';
import CommercialLoan from '../containers/CommercialLoan';
import CreateCommercialProposal from '../containers/CreateCommercialProposal';
import ExternalFillOwnerInformation from '../containers/ExternalFillOwnerInformation';




const App = ()=>(
  <div>
  <BrowserRouter>
    <AuthProvider>
    <Layout>
      <Switch>
        

        <ProtectedRoute path="/createapplication/:applicantId" component={CreateCommonApplication} />
        <ProtectedRoute exact path="/prospectapplication/:id" component={CompleteApplication} />
        <ProtectedRoute exact path="/prospectapplication/" component={Applicants} />

        <ProtectedRoute exact path="/application/:id" component={ApplicationDetailContainer} />
        <ProtectedRoute exact path="/application/" component={Applicants} />

        <ProtectedRoute path="/editsystempackage/:id" component={EditSystemPackage} />
        <ProtectedRoute path="/createsystempackage" component={CreateSystemPackage} />

        <Route path="/resetpassword/:source" component={ResetPassword} />   
        <Route exact path="/customer/:id" component={ExternalCreateApplication} />
        <Route exact path="/commercialowner/:id" component={ExternalFillOwnerInformation} />
        <Route exact path="/iframe/:id" component={IframeContainer} />
        <ProtectedRoute exact path="/commercial/:id" component={CreateCommercialLoan} />
        <ProtectedRoute exact path="/customer" component={Applicants} />
        <ProtectedRoute exact path="/iframe" component={Applicants} />
        <ProtectedRoute exact path="/commercial" component={CreateCommercialLoan} />
        <ProtectedRoute exact path="/commercialapp" component={CommercialLoan} />
      
        <Route exact path="/login" component={Login} />
        <Route exact path="/changepassword" component={ChangePassword} />
        <Route exact path="/block" component={Block} />
 
       

 
        
     
        <ProtectedRoute path="/logs" component={Logs} />
        <ProtectedRoute path="/register" component={Register} />
        <ProtectedRoute path="/applicants" component={Applicants} />
        <ProtectedRoute path="/proposals" component={Proposals} />
        <ProtectedRoute path="/reports" component={Reports} />
        <ProtectedRoute path="/users" component={Users} />
        <ProtectedRoute path="/library" component={Library} />
        {/* <ProtectedRoute path="/createapplication" component={CreateApplication} /> */}
        <ProtectedRoute path="/createapplication" component={CreateCommonApplication} />
        <ProtectedRoute path="/createproposal" component={CreateProposal} />
        <ProtectedRoute path="/createcommercialproposal" component={CreateCommercialProposal} />
        
        <ProtectedRoute path="/settings" component={Settings} />
        <ProtectedRoute path="/dealers" component={Dealers} />
        <ProtectedRoute path="/" component={Applicants} />

        
        
        
        <Route component={NotFound} />  
         
      
        
       
             

      </Switch>
    </Layout> 
    </AuthProvider>
      
  </BrowserRouter>
  </div>
  
  
)

export default App;

