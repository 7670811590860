import React, { useState, useContext, useEffect } from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/Login.css'
import { AuthConsumer} from '../AuthContext';
import {AuthContext} from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Notification from '../components/Notification';
import iconSadFace from '../assets/images/iconSadFace.png';
import Spinner from 'react-spinner-material';
import iconAdmiration from '../assets/images/iconAdmiration.png';

const Login = (props)=>{

    const{uri, activateAuth, isAuth} = useContext(AuthContext);
    
    const [form, setValues] = useState({
        password: '',
        username: '',
        new_password: '',
        repeatNewPassword : '',  
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

   

    const [showNotification, SetShowNotification] = useState(false);
    const [showErrorPassword, SetShowErrorPassword] = useState(false);
    const [showNotificationPendingForActivation, setShowNotificationPendingForActivation] = useState(false);
    const [showNotificationGeneralError, setShowNotificationGeneralError] = useState(false);









    const handleInput = event => {
        setValues({
            ...form,
            [event.target.name]: event.target.value,            
        })
    }


    const postData = async (e) => {
            setIsLoading(true)

          const body = {
            password: form.password,
            username: form.username,
            new_password: form.new_password,
          }
                              
          const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_AUTHENTICATE}`;
        

          try{
            const rawResponse = await window.fetch(URI, {
                method: 'POST',
                headers: {           
                'Content-Type': 'application/json', 
                'x-api-key' : uri.URI_X_API_KEY,           
                },
                body: JSON.stringify(body),
            
            });
            setIsLoading(false);
            const content = await rawResponse.json();

            // console.log(content);

            

            const loginSucces =()=>{
              const token = content.id_token;
                //console.log('token: '+token)
                const user = form.username
                const refreshToken = content.refresh_token;
                const dealerId = content.dealer_id;
                const accessToken = content.access_token;
                const userEmail = content.email;
                //console.log('REFRESH TOKEN:')
                //console.log(refreshToken)

                const permissions = content.permissions;
                permissions.map(item => {
                    if (item === "can_read_all_applications") {
                        localStorage.setItem("getApplicationsEndpoint", item);
                      } else if (item === "can_read_dealer_applications") {
                        localStorage.setItem("getApplicationsEndpoint", item);
                      } else if (item === "can_read_own_applications") {
                        localStorage.setItem("getApplicationsEndpoint", item);
                      } 

                    else if (item === "can_create_applications") {
                      localStorage.setItem("can_create_applications", 'true');
                    } else if (item === "can_create_users") {
                      localStorage.setItem("can_create_users", 'true');
                    }
                    
                    else if (item === "can_read_all_users") {
                      localStorage.setItem("getUsersEndpoint", item);
                    } else if (item === "can_read_dealer_users") {
                      localStorage.setItem("getUsersEndpoint", item);
                    }
                    
                    else if(item === 'is_dealerally_admin'){
                      localStorage.setItem("is_dealerally_admin", 'true');
                    }

                    else if(item === 'can_edit_users'){
                      localStorage.setItem("can_edit_users", 'true');
                    }
                    
                    
                    else if (item === "can_delete_users") {
                      localStorage.setItem("can_delete_users", 'true');
                    } else if (item === "can_edit_usersroles") {
                      localStorage.setItem("can_edit_usersroles", 'true');
                    } 
                    
                    else if (item === "can_read_all_roles") {
                      localStorage.setItem("can_read_all_roles", 'true');
                    } else if (item === "can_read_dealer_roles") {
                      localStorage.setItem("can_read_dealer_roles", 'true');
                    } else if (item === "can_create_roles") {
                      localStorage.setItem("can_create_roles", 'true');
                    } else if (item === "can_edit_roles") {
                      localStorage.setItem("can_edit_roles", 'true');
                    }
                  });

                  const dealerPermissions = content.dealer_permissions;
                  if(dealerPermissions != undefined && dealerPermissions != null){
                    dealerPermissions.map(item => {
                      if (item === "can_create_applications") {
                        localStorage.setItem("dealer_can_create_applications", 'true');
                      }
                    })
                  }

                  // if(content.status === 'WAITING_FOR_ACTIVATION'){
                  //   localStorage.setItem("waitingForActivation", 'true');
                  // }


                  if(content.has_been_validated_in_aqua){
                    localStorage.setItem("has_been_validated_in_aqua", 'true');
                  }else{
                    localStorage.setItem("has_been_validated_in_aqua", 'false');
                  }
            
                activateAuth(token, user, refreshToken, dealerId, accessToken, userEmail);
                props.history.replace(`/applicants`);
                
                
              

            }




            if(content.status === 'NEW_PASSWORD_REQUIRED'){
                props.history.push (`/changepassword`);
            }else if(content.status === 'SUCCESS'){ 
              loginSucces();

            }else if(content.status === 'UNAUTHORIZED'){
                console.log('INAUTORIZADO')
                SetShowNotification(true);
                
            }else if(content.status === 'ERROR'){
                console.log('ERROR');
                SetShowErrorPassword(true)
            }else if(content.status === 'WAITING_FOR_ACTIVATION'){
              console.log('WAITING_FOR_ACTIVATION');
              //setShowNotificationPendingForActivation(true);
              loginSucces();
            }else{
              console.log('ERROR');           
              SetShowNotification(true);
            }

          }catch{
            console.log('Catch error')
            setIsLoading(false);
            setShowNotificationGeneralError(true)
          }  
      
    }



    const handleSubmit = event => {
      event.preventDefault() 
      setErrors(validateForm(form));
      setIsSubmitting(true);
      
    };


    //VALIDAR ERRORES Y ENVIAR FORMULARIO DE CAMBIAR CONTRASEÑA
    useEffect(() => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
        postData();
      } 
    }, [errors]);



    const validateForm = (data) => {
      let errorList = {};

      //Password
      if (!data.password) {
        errorList.password = "Please enter the current password";
      }

      //username
      if (!data.username) {
        errorList.username = "Please enter the UserName";
      }

      //New Password
      if (!data.new_password) {
          errorList.new_password = "Please enter a new password";
      }else if(data.new_password !== data.repeatNewPassword){
          errorList.new_password = "New password does not match";
      }

        //New Password2
      if (!data.repeatNewPassword) {
        errorList.repeatNewPassword = "Please repeat the new password";
      }else if(data.repeatNewPassword !== data.new_password){
        errorList.repeatNewPassword = "New password does not match";
      } 

      return errorList;
    };




    const loadingButtom = ()=>{
      return(
        <div className="loadingButtom">
            <Spinner
              size={25}
              color={"white"}
              stroke={4}
              visible={true}
            />
        </div>
      )
    }
    
    return(
        <>
       
        <div>
        {isAuth ? null : <Header />}
        <div className="login-page">
            <section className="login-container">
            <h2 className="login-title">Change Password</h2>
            <p className="login-p">To continue you need to change your password. </p>
            <form  onSubmit={handleSubmit} className="login-form">

                <label className="login_labelForm">Username</label> 
                <input 
                    name="username"
                    type="text" 
                    placeholder="Username"
                    onChange={handleInput} 
                    className="register-input"
                    autoComplete="new-password" 
                />
                {errors.username && <p className="errorMessage">{errors.username}</p>}



                <label className="login_labelForm">Current Password</label> 
                <input 
                    name="password"
                    type="password" 
                    placeholder="Current Password"
                    onChange={handleInput} 
                    className="register-input"
                    autoComplete="new-password"
                />
                {errors.password && <p className="errorMessage">{errors.password}</p>}


                <label className="login_labelForm">New Password</label>       
                <input 
                    name="new_password"
                    type="password" 
                    placeholder="New Password" 
                    onChange={handleInput}
                    className="login-input"
                    autoComplete="new-password"
                />
                {errors.new_password && <p className="errorMessage">{errors.new_password}</p>}


                <label className="login_labelForm">Repeat New Password</label>       
                <input 
                    name="repeatNewPassword"
                    type="password" 
                    placeholder="Repeat New Password" 
                    onChange={handleInput}
                    className="login-input"
                    autoComplete="new-password" 
                />
                {errors.repeatNewPassword && <p className="errorMessage">{errors.repeatNewPassword}</p>}
              


              {isLoading ? loadingButtom() 
                : <button type="submit" className="login-submit-button">Change Password</button>}
            </form>
            
            <Link to="" className="login-forgot"></Link>
        </section>
        </div>
        {isAuth ? null : <Footer />}
        </div>
        

        {showNotification ? 
        <Notification type='popup' title='Something went wrong' text='Check your username and password, if it does not work contact your support team.' f={()=>{SetShowNotification(false)}}>
            <img src={iconSadFace} alt="close" />
        </Notification> : null}

        {showErrorPassword ?
         <Notification type='popup' title='The new password is not secure' text='Password requires numbers, special character, uppercase and lowercase letters, and minimum 8 characters length.' f={()=>{SetShowErrorPassword(false)}}>
         <img src={iconSadFace} alt="close" />
        </Notification> : null} 

        {showNotificationGeneralError ? 
        <Notification type='popup' title='Something went wrong' text='Contact your administrator.' f={()=>{setShowNotificationGeneralError(false)}}>
            <img src={iconSadFace} alt="close" />
        </Notification> : null}

        {showNotificationPendingForActivation ? 
        <Notification type='popup' title='Info: The user is pending for activation in AquaFinance systems.' text='Try again later.' f={()=>{setShowNotificationPendingForActivation(false); props.history.push (`/login`);}}>
            <img src={iconAdmiration} alt="close" className="login_icon-admiration" />
        </Notification> : null}   
        }
        </>
    );
}



export default Login;