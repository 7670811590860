import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/Register.css'
import '../assets/styles/ApplicationForm.css'
import { AuthConsumer } from '../AuthContext';
import { AuthContext } from '../AuthContext';
import Notification from '../components/Notification';
import iconSadFace from '../assets/images/iconSadFace.png';
import iconCheck from '../assets/images/iconCheck.png';
import SpinnerPopUp from '../components/SpinnerPopUp';
import Spinner from 'react-spinner-material';
import useIsSafari from '../hooks/useIsSafari'
import NotificationSettings from '../components/settings/NotificationSettings';
import AlertBox from "../components/AlertBox";
require('dotenv').config();


const Register = (props) => {



  const { uri } = useContext(AuthContext);

  const isSafari = useIsSafari()

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGetRoles, setIsLoadingGetRoles] = useState(false);

  const [data, setData] = useState({});
  const [dealerId, setDealerId] = useState(false);

  const [dealersList, setDealersList] = useState({});
  const [showDealersInput, setShowDealersInput] = useState(false);
  const [dealerSelected, setDealerSelected] = useState(false)

  const [roles, setRoles] = useState([]);
  const [hasAvailableRoles, setHasAvailableRoles] = useState(false)

  const [changeDealer, serChangeDealer] = useState(false);

  const [showNotificationOk, SetShowNotificationOk] = useState(false);
  const [showNotificationErrorPass, SetShowNotificationErrorPass] = useState(false);
  const [showNotificationErrorExist, SetShowNotificationErrorExist] = useState(false);

  //NOTIFICATIONS
  const [notificationInfo, setnotificationInfo] = useState({})
  const [getNotificationInfo, setGetNotificationInfo] = useState(1)
  const [notificationError, setNotificationError] = useState(false)
  const [alertBoxInfo, setAlertBoxInfo] = useState({show: false})



  //RECUPERAR VALORES DE SESSION STORAGE Y CONTEXT API
  const URI_X_API_KEY_I = uri.URI_X_API_KEY;
  const user = window.localStorage.getItem('user');
  const token = window.localStorage.getItem('token');
  const refreshToken = window.localStorage.getItem('refreshToken');
  const dealerIdCode = window.localStorage.getItem('dealerId');
  const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');


  //CAMBIAR EL TOKEN AL ENTRAR EN ESTE COMPONENTE
  useEffect(() => {
    const data = {
      "username": user,
      "refresh_token": refreshToken,
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
    window.fetch(URI_REFRESHTOKEN, {
      method: 'POST',
      signal: signal,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': URI_X_API_KEY_I,
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(datos => {
        //console.log(datos)
        window.localStorage.removeItem('token');
        const newToken = datos.id_token;
        window.localStorage.setItem('token', newToken);
      })

    return function cleanup() {
      abortController.abort();
    }
  }, []);



  useEffect(() => {
    if(props.converBasicUser){
      setData({   
        ...data,
        email: props.email,
        repeatEmail: props.email,
        first_name: props.first_name,
        last_name: props.last_name,
        phone_number: props.phone_number,
      })
    }  
  }, [props.email])



  //SUBMIT REGISTER FORM
  const submitRegister = async (event) => {
    //event.preventDefault();

     setIsLoading(true);
     setAlertBoxInfo({show:false})

    const dealerIdv = dealerId.dealerIdValue;
 

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_CREATE_USER}`
    // console.log(URI)


    const body = {
      username: data.username,
      password: data.password,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      title: data.title,
      role: data.role,
    }

    body.user_notifications = notificationInfo

    if(props.converBasicUser){
      body.basic_user_id = props.user_id
    }

    // console.log(notificationInfo)
    // console.log(body)
   

    const rawResponse = await window.fetch(URI, {
      method: 'POST',
      headers: {
        //'Access-Control-Request-Headers': 'application/json',           
        'Content-Type': 'application/json',
        'DealerId': dealerId,
        'Authorization': token,
        'x-api-key': URI_X_API_KEY_I,
      },
      body: JSON.stringify(body),

    });
    setIsLoading(false);
    const content = await rawResponse.json();
    console.log(content);

    // setnotificationInfo({...notificationInfo, error: true})

    if (content.user_status === 'FORCE_CHANGE_PASSWORD') {
      SetShowNotificationOk(true)
    }

    if (content.status) {
      if (content.status === 'USER_ALREADY_EXISTS') {
        SetShowNotificationErrorExist(true)
      }else if (content.status === 'ERROR') {
        setAlertBoxInfo({ 
          title: content.Message,
          text: content.Errors[0],
          type: 'error',
          show: true
        });
      }
    }

    if (content.Errors) {
      setAlertBoxInfo({ 
        title: content.Message,
        text: content.Errors[0],
        type: 'error',
        show: true
      });
    }

  };




  //GET ALL DEALERS
  useEffect(() => {

    if (isDeallerAllyAdmin === 'true' && !props.hasOwnProperty('dealerIdProp')) {

      setIsLoadingGetRoles(true);

      const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_GET_ALL_DEALERS}`
      console.log(URI)

      fetch(URI, {
        method: 'GET',
        headers: {
          'Access-Control-Request-Headers': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token,
          'x-api-key': URI_X_API_KEY_I,
        }
      })
        .then(response => {
          return response.json()
        })
        .then(datos => {
          setIsLoadingGetRoles(false);
          console.log('INFORMACION DE TODOS LOS DEALERS DESDE CREATE USERS');
          console.log(datos);

          setDealersList(datos);
          setShowDealersInput(true)

        })



    } else {
      setDealerId(props.hasOwnProperty('dealerIdProp') ? props.dealerIdProp : window.localStorage.getItem('dealerId'));
      //getRole()
    }

  }, [])




  useEffect(() => {

    if (dealerSelected != false) {
      setDealerId(dealerSelected)
      //console.log(dealerSelected) 

    }

  }, [dealerSelected])




  useEffect(() => {

    if (dealerId != false) {
      getRole()
    }

  }, [dealerId])








  //GET ROLES
  const getRole = () => {



    setIsLoadingGetRoles(true);



    const abortController = new AbortController();
    const signal = abortController.signal;

    //const dealerIdv = dealerId.dealerIdValue;


    const URI_GET_DEALER_ROLES = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_ROLES}${uri.URI_GET_DEALER_ROLES}`;
    // console.log(URI_GET_DEALER_ROLES )

    // console.log(dealerId)

    window.fetch(URI_GET_DEALER_ROLES, {
      method: 'GET',
      signal: signal,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': URI_X_API_KEY_I,
        'DealerId': dealerId,
        'Authorization': token,
        'username': user,
      },

    })
      .then(response => response.json())
      .then(datos => {
        setIsLoadingGetRoles(false)

        // console.log(datos)

        if (datos.hasOwnProperty('STATUS') || datos.hasOwnProperty('status')) {

          setHasAvailableRoles(false)
          setRoles([]);
        } else {
          setRoles(datos);
          setHasAvailableRoles(true)
        }

      })

    return function cleanup() {
      abortController.abort();
    }




  };



  //OBTENER DATOS DE NOTIFICATIONS
  const getNotifications = (notificationsOptions, enableNotifications) => {

    const { All, Pending, ConditionallyApproved, Approved, SignReady, Received, PendingDocs, InVerification, InConfirmation, ReadyToFund, Denied, Problem, Cancelled, StatusMessages } = notificationsOptions;


    if (!All && !Pending && !ConditionallyApproved && !Approved && !SignReady && !Received && !PendingDocs && !InVerification && !InConfirmation && !ReadyToFund && !Denied && !Problem && !Cancelled && !StatusMessages) {
      // setChoseOneOptionError(true)


    } else {
      // setChoseOneOptionError(false)

      //ARMAR LA LISTA
      let notificationsListToSend = [];

      for (const notification in notificationsOptions) {
        if (notificationsOptions[notification]) {
          notificationsListToSend.push(notification)
        }
      }

      //ARMAR EL OBJETO
      const data = {
        // NotificationsActive : enableNotifications,
        NotificationsStatuses: notificationsListToSend,
        NotificationsDeliveryMethod: ['Email'],
        NotificationsActive: enableNotifications,
      }

      // if (fromOptions) {
      //     data.NotificationsActive = enableNotifications
      // } else {
      //     data.NotificationsActive = !enableNotifications
      // }


      console.log(data)

    }

  }





  const handleInput = event => {
    let value = event.target.value;

    if (event.target.name === "dealerIdValue") {
      setDealerId({
        ...dealerId,
        [event.target.name]: value
      });

    } else {
      setData({
        ...data,
        [event.target.name]: value
      });
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    //handle errors

    await setGetNotificationInfo(getNotificationInfo + 1)
    setErrors(validateForm(data, dealerId));
    setIsSubmitting(true);
    
    //console.log(data);
  };



  //VALIDAR ERRORES Y ENVIAR FORMULARIO
  useEffect(() => {

    if (Object.keys(errors).length === 0 && (notificationInfo.NotificationsStatuses !== undefined || ( notificationInfo.NotificationsStatuses && notificationInfo.NotificationsStatuses && notificationInfo.NotificationsStatuses.length > 0))  ) {
      submitRegister();
    }
  }, [errors]);






  
  const validateForm = (data, id) => {
    let errorList = {};

    // if(notificationInfo.error){
    //   errorList.notificationInfo = "Please select a option";
    // }

    //dealerid - check
    if (isDeallerAllyAdmin === 'true' && !props.hasOwnProperty('dealerIdProp')) {
      if (dealerSelected === false) {
        errorList.dealerIdValue = "Please select a option";
      }
    }


    //Username - text
    if (!data.username) {
      errorList.username = "Please enter a username";
    // } else if (!/[\p{L}\p{M}\p{S}\p{N}\p{P}]+/.test(data.username)) {
    } else if (data.username.includes(' ')) {
      errorList.username = "Invalid username";
    } else if (data.username !== data.repeatUsername) {
      errorList.username = "Username does not match";
    }

    //Username - text
    if (!data.repeatUsername) {
      errorList.repeatUsername = "Please enter a username";
    // } else if (!/[\p{L}\p{M}\p{S}\p{N}\p{P}]+/.test(data.repeatUsername)) {
    } else if (data.repeatUsername.includes(' ')) {
      errorList.repeatUsername = "Invalid username";
    }else if (data.repeatUsername !== data.username) {
      errorList.repeatUsername = "Username does not match";
    }

    //Password - text
    if (!data.password) {
      errorList.password = "Please enter a password";
    }

    //email - text
    if (!data.email) {
      errorList.email = "Please enter a Email";
    } else if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$/.test(data.email)) {
      errorList.email = "Enter a valid email";
    } else if (data.email !== data.repeatEmail) {
      errorList.email = "Email does not match";
    }

    //repeatEmail - text
    if (!data.repeatEmail) {
      errorList.repeatEmail = "Please enter a Email";
    } else if (!/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$/.test(data.repeatEmail)) {
      errorList.repeatEmail = "Enter a valid email";
    } else if (data.repeatEmail !== data.email) {
      errorList.repeatEmail = "Email does not match";
    }



    //name - text
    if (!data.first_name) {
      errorList.first_name = "Please enter a name";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/.test(data.first_name)) {
      errorList.first_name = "The name can only contain letters";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]{1,32}$/.test(data.first_name)) {
      errorList.first_name = "The first name can contain maximum 32 characters";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ]{1,32}$/.test(data.first_name)) {
      errorList.first_name = "The first name cannot contain spaces";
    }

    //lastname - text
    if (!data.last_name) {
      errorList.last_name = "Please enter a lastname";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/.test(data.last_name)) {
      errorList.last_name = "The lastname can only contain letters";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]{1,64}$/.test(data.last_name)) {
      errorList.last_name = "The last name can contain maximum 32 characters";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ]{1,64}$/.test(data.last_name)) {
      errorList.last_name = "The last name cannot contain spaces";
    }

    //title- text
    if (!data.title) {
      errorList.title = "Please enter a title";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/.test(data.title)) {
      errorList.title = "The lastname can only contain letters";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]{1,32}$/.test(data.title)) {
      errorList.title = "The title can contain maximum 32 characters";
    }

    //phone_number - number
    if (!data.phone_number) {
      errorList.phone_number = "Please enter a primary phone";
    } else if (!/^[0-9]{10}$/.test(data.phone_number)) {
      errorList.phone_number = "The phone number must have 10 numbers";
    }

    //dealercompany - text
    /*if (!data.dealercompany) {
      errorList.dealercompany = "Please enter a dealer company";
    }*/

    //role - check
    if (!data.role) {
      errorList.role = "Please enter a Role";
    }

    return errorList;
  };













  return (
    <>
      <AuthConsumer>
        {({ isAuth, activateAuth, logout, postAuth }) => (
          <div className="register-page">

            <section className="register-container">
              {props.hasOwnProperty('dealerIdProp')  ? null  : <h2 className="register-title">Create New User</h2>}
              {props.showTitle && <h2 className="register-title">Register User</h2>}


              <form action="" className="register-form">
                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">Username (Must be all lower case)</label>
                  <br/>
                  <label className="labelApplicationForm">Recommend using full email address</label>
                  <input
                    name="username"
                    type="text"
                    placeholder="Username"
                    onChange={handleInput}
                    required
                    className={errors.username ? "imputError" : "campo"}
                    autoComplete="new-password"
                  />
                  {errors.username && <p className="errorMessage">{errors.username}</p>}
                </div>


                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">Repeat Username (Must be all lower case)</label>
                  <input
                    name="repeatUsername"
                    type="text"
                    placeholder="Repeat Username"
                    onChange={handleInput}
                    required
                    className={errors.repeatUsername ? "imputError" : "campo"}
                    autoComplete="new-password"
                  />
                  {errors.repeatUsername && <p className="errorMessage">{errors.repeatUsername}</p>}
                </div>



                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">Password</label>
                  <input
                    name="password"
                    type="text"
                    placeholder="Password"
                    onChange={handleInput}
                    required
                    className={errors.password ? "imputError" : "campo"}
                    autoComplete="new-password"
                  />
                  {errors.password && <p className="errorMessage">{errors.password}</p>}
                </div>

                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">Email (Must be all lower case)</label>
                  <input
                    name="email"
                    type="email"
                    value={data.email}
                    placeholder="Email"
                    onChange={handleInput}
                    required
                    className={errors.email ? "imputError" : "campo"}
                    disabled={props.type === 'edit' ? "disabled" : null}
                    autoComplete="new-password"
                  />
                  {errors.email && <p className="errorMessage">{errors.email}</p>}
                </div>


                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">Repeat Email (Must be all lower case)</label>
                  <input
                    name="repeatEmail"
                    type="email"
                    value={data.repeatEmail}
                    placeholder="Repeat Email"
                    onChange={handleInput}
                    required
                    className={errors.repeatEmail ? "imputError" : "campo"}
                    disabled={props.type === 'edit' ? "disabled" : null}
                    autoComplete="new-password"
                  />
                  {errors.repeatEmail && <p className="errorMessage">{errors.repeatEmail}</p>}
                </div>


                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">First Name</label>
                  <input
                    name="first_name"
                    type="text"
                    value={data.first_name}
                    placeholder="First Name"
                    onChange={handleInput}
                    required
                    className={errors.first_name ? "imputError" : "campo"}
                    autoComplete="new-password"
                    
                  />
                  {errors.first_name && (
                    <p className="errorMessage">{errors.first_name}</p>
                  )}
                </div>


                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">Last Name</label>
                  <input
                    name="last_name"
                    type="text"
                    value={data.last_name}
                    placeholder="Last Name"
                    onChange={handleInput}
                    required
                    className={errors.last_name ? "imputError" : "campo"}
                    autoComplete="new-password"
                  />
                  {errors.last_name && (
                    <p className="errorMessage">{errors.last_name}</p>
                  )}
                </div>

                <div className="mediowidth-right campoContainer">
                  <label className="labelApplicationForm" >Phone Number</label>
                  <input
                    type="number"
                    value={data.phone_number}
                    name="phone_number"
                    onChange={handleInput}
                    className={`${errors.phone_number ? "imputError" : "campo"}`}
                    placeholder="Phone Number"
                    required
                    autoComplete="new-password"
                  />
                  {errors.phone_number && <p className="errorMessage">{errors.phone_number}</p>}
                </div>


                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm">Title</label>
                  <input
                    name="title"
                    type="text"
                    placeholder="Title"
                    onChange={handleInput}
                    required
                    className={errors.title ? "imputError" : "campo"}
                    autoComplete="new-password"
                  />
                  {errors.title && (
                    <p className="errorMessage">{errors.title}</p>
                  )}
                </div>




                {showDealersInput ?
                  <div className="mediowidth-left campoContainer">
                    <label className="labelApplicationForm">Dealer</label>
                    <select
                      name="dealerIdValue"
                      onChange={(e) => { setDealerSelected(e.target.value) }}
                      className={errors.dealerIdValue ? "imputError" : "campo"}
                      placeholder="Dealer"
                      required
                    >
                      <option value="disable" disabled selected>
                        Dealer
                    </option>
                      {
                        dealersList.map((item, index) => {
                          return (<option value={item.Id} key={index}>{item.Name}</option>)
                        })
                      }

                    </select>
                    {errors.dealerIdValue && (
                      <p className="errorMessage">{errors.dealerIdValue}</p>
                    )}
                  </div>
                  : null}





                {isLoadingGetRoles ?
                  <div className="loadingRoles">
                    <Spinner
                      size={25}
                      color={"grey"}
                      spinnerWidth={3}
                      visible={true}
                    />
                  </div>
                  : null
                }


                {hasAvailableRoles ?
                  <div className="mediowidth-left campoContainer">
                    <label className="labelApplicationForm">Role</label>
                    <select
                      name="role"
                      onChange={handleInput}
                      className={errors.role ? "imputError" : "campo"}
                      placeholder="Choose a Role"
                      required
                    >
                      <option value="disable" disabled selected>
                        Role
            </option>

                      {roles.map((item, index) => {
                        return (<option value={item.code} key={index}>{item.name}</option>)
                      })}


                    </select>
                    {errors.role && <p className="errorMessage">{errors.role}</p>}
                  </div>
                  : null}


                <NotificationSettings noPadding creatingUser getNotificationInfo={getNotificationInfo} setnotificationInfo={setnotificationInfo} setNotificationError={setNotificationError}/>


                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="register-submit-button"
                >
                  Register
                </button>

                <div className="mediowidth-left campoContainer">
                  <AlertBox 
                      showAlert={alertBoxInfo.show}
                      type={alertBoxInfo.type}
                      title={alertBoxInfo.title}
                      text={alertBoxInfo.text}
                />
                </div>
                
              </form>









            </section>
          </div>
        )}
      </AuthConsumer>

      {showNotificationOk ?
        <Notification {...isSafari && props.safari && { flexRight: true }} {...props.converBasicUser && {reload:'true'}} type='popup' link="/users" title='The User was created successfully.' f={() => { SetShowNotificationOk(false) }}>
          <img src={iconCheck} alt="check" />
        </Notification> : null}

      {showNotificationErrorPass ?
        <Notification {...isSafari && props.safari && { flexRight: true }} type='popup' title='Password requires numbers, special character, uppercase and lowercase letters, and minimum 8 characters length.' f={() => { SetShowNotificationErrorPass(false) }}>
          <img src={iconSadFace} alt="check" />
        </Notification> : null}

      {showNotificationErrorExist ?
        <Notification {...isSafari && props.safari && { flexRight: true }} type='popup' title='User already exists' f={() => { SetShowNotificationErrorExist(false) }}>
          <img src={iconSadFace} alt="check" />
        </Notification> : null}

      {isLoading ? <SpinnerPopUp /> : null}
    </>
  );
}



export default Register;