import React from 'react'
import '../../assets/styles/BasicLayout.css'
import { Link } from 'react-router-dom';
import Button from './Button';



const Page = ({ children, title, mainButton, reloadButton, secondaryButton }) => {

 
  return (
    <div className='pageGeneralContainer'>
      <div className='page_header'>
        <div className='page_titleContainer'>
          {title && <p className='mainHeaderTittle'>{title}</p>}
          {reloadButton && reloadButton}
        </div>

        <div className='page_buttonsContainer'>
          {
            secondaryButton &&
            <Button link={secondaryButton.link} size='big' type='secondary' variant='rounded'>
              {secondaryButton.name}
            </Button>
          }
          
          {
            mainButton && 
            <Button link={mainButton.link} size='big' variant='rounded'>
              {mainButton.name}
            </Button>
          } 
        </div>
         
      </div>
      {children}
    </div>
  )
}

export default Page