import React, {useContext, useState} from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from '../AuthContext'
import Applicants from '../containers/Applicants'
import { AuthContext } from '../AuthContext'

// const ProtectedRoute = ({ component: Component}) => (
//   <AuthConsumer>
//     {({ isAuth }) => (
//       <Route
//         render={props =>
//           isAuth ? <Component  {...props} /> : <Redirect to="/Login" />
//         }
        
//       />
//     )}
//   </AuthConsumer>
// )

const ProtectedRoute = (props) => {

  
  const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
  const cantReadAllUsers = localStorage.getItem("getUsersEndpoint");
  const dealerCanCreateApplication = window.localStorage.getItem("dealer_can_create_applications");
  const canCreateProposal = window.localStorage.getItem("allow_customer_proposals");
  const userRole = localStorage.getItem('userRole');
  const canCreateReports = window.localStorage.getItem("allow_customer_reports");
  const [path, setPath] = useState(undefined)

  return(
  <AuthConsumer>
    {({ isAuth }) => (
      
         
        
          isAuth ? 
            
                  props.path === '/dealers' ? 
                  

                          isDeallerAllyAdmin === "true" ? 
                                <Route exact path={props.path} component={props.component} /> 
                          : <Redirect to="/applicants" />
                    
                   
                   
                  : props.path === '/users' ? 

                          cantReadAllUsers === 'can_read_all_users' || cantReadAllUsers === 'can_read_dealer_users' ?
                                <Route exact path={props.path} component={props.component} /> 
                          : <Redirect to="/applicants" />
                    
                    
                  : props.path === '/createapplication' ? 
                        
                        dealerCanCreateApplication === 'true' ?
                              <Route exact path={props.path} component={props.component} /> 
                        : <Redirect to="/applicants" />


                  :props.path === '/createproposal' ? 
                  
                        isDeallerAllyAdmin !=='true' && canCreateProposal === 'true' ?
                              <Route exact path={props.path} component={props.component} /> 
                        : <Redirect to="/applicants" />
                  

                  :props.path === '/logs' ? 
                  
                        isDeallerAllyAdmin ==='true' ?
                              <Route exact path={props.path} component={props.component} /> 
                        : <Redirect to="/applicants" />
                
                  : <Route exact path={props.path} component={props.component} /> 
                    
                    
                      
          : <Redirect to={"/Login"} />
        
        
      
    )}
  </AuthConsumer>
)}

export default ProtectedRoute