import React, {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import '../assets/styles/CreateApplication.css'
import ExternalForm from '../components/ExternalForm'
import CreateLinkToExternalForm from '../components/CreateLinkToExternalForm'
import CommonApplicationInternalProcess from '../components/CommonApplicationInternalProcess'
import CreateOnlyDocumentsApp from '../components/CreateOnlyDocumentsApp';


const CreateCommonApplication = () => {

    const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);

    const urlParamApplicantId = useParams().applicantId

    const [showForm, setShowForm] = useState(2)

    const [financialInstitutionList, setFinancialInstitutionList] = useState([])
    const [canCreateOnlyDocuments, setCanCreateOnlyDocuments] = useState(false)

    const dealerIdCode = window.localStorage.getItem('dealerId');
    const token = window.localStorage.getItem('token');

    const switchLogic = (form) => {
        if(form === showForm){
            setShowForm(2)
        }else{
            setShowForm(form) 
        }
    }



        //GET FINANCIALS INSTITUTIONS CUADROS BLANCOS
        useEffect(()=>{

            if(!urlParamApplicantId){
    
                //setLoadingGetFinancials(true)
    
            const abortController = new AbortController();
            const signal = abortController.signal;
        
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_GET_FINANCIAL_INSTITUTIONS}`
            // console.log(URI)
            
    
            window.fetch(URI,{
                method: 'GET',
                signal: signal,
                headers: {
                    'Access-Control-Request-Headers': 'application/json',           
                    'Content-Type': 'application/json', 
                    'DealerId' : dealerIdCode,
                    'Authorization' : token,  
                    'x-api-key' : uri.URI_X_API_KEY,
                            
                    }
            })
            .then(response => {

                if (response.ok) {
                    return response.json();
                  }
                return Promise.reject(response);
            })
            .then(datos => {
                //setLoadingGetFinancials(false)
        
                // console.log(datos)
        
                setFinancialInstitutionList(datos)

                if(datos.length > 0){
                    let canCreate = false
                    datos.forEach(element => {
                        if(element.CanDealerCreateOnlyDocumentsApplications){
                            canCreate = true
                        }
                    });
                    setCanCreateOnlyDocuments(canCreate)
                }
    
                setErrorFlagFromContext(false)
                
            }).catch((error, response) => {
                // console.log(error)
                if(errorFlagFromContext === false && error.status === 401){
                    changeToken().then(response => console.log(response)).catch(error => console.log(error))
                }    
            });
        
            return function cleanup(){
                abortController.abort();
            }
            }      
            
        }, [reloadFetchFromContext]);



        
    return (
        <>
            <div className="createApplication">
                <div className="createApplication-header">               
                    <div className="createApplication-header-tittle">
                        <h2>New Application</h2>
                    </div>  

                    
                     
                     <div className="createApplication-header_swtchesContainer">
                    
                     {
                         !urlParamApplicantId && canCreateOnlyDocuments &&
                         <div className="createApplicationPinnacle_switchButton">
                            <label className="switch">
                            <input type="checkbox" id="checkStubApplication" {...showForm === 3 && { checked: "checked" } } name="isActive"  onClick={()=> switchLogic(3)} />      
                            <span className="slider round"></span>
                            </label>
                            
                            <p className="createApplicationPinnacle_switchText">Documents-only Application</p>
                        </div>
                     }
                    
                    {
                         !urlParamApplicantId && 
                         <div className="createApplicationPinnacle_switchButton">
                            <label className="switch">
                            <input type="checkbox" id="checkStubApplication" {...showForm === 1 && { checked: "checked" } } name="isActive"  onClick={()=> switchLogic(1)} />      
                            <span className="slider round"></span>
                            </label>
                            
                            <p className="createApplicationPinnacle_switchText">Send Customer a Link to Fill Out an Application</p>
                        </div>
                     }

                     </div>
                    
                     
                                  
                </div>


                {
                    showForm === 1 ? 
                    <CreateLinkToExternalForm />
                    : showForm === 2 ? 
                    <CommonApplicationInternalProcess urlParamApplicantId={urlParamApplicantId} setStep2={urlParamApplicantId ? false : false}/>
                    : showForm === 3 ?
                    <CreateOnlyDocumentsApp financialInstitutionList={financialInstitutionList}/>
                    : null
                }
                

                
            </div>
        
        </>
    )
}

export default CreateCommonApplication;
