import React, {useState, useContext, useEffect} from 'react'
import '../../assets/styles/Settings.css'
import SkeletonLoader from "tiny-skeleton-loader-react";
import { AuthContext } from '../../AuthContext';
import Spinner from 'react-spinner-material';

const UserPersonalInformation = ({isLoadingUser, data, children, showExtraFields, reloadGetUserData}) => {

    const { uri} = useContext(AuthContext);


    const [editMode, setEditMode] = useState(false)
    const [editData, setEditData] = useState({})
    const [isLoadingEditUser, setIsLoadingEditUser] = useState(false)

    const [userData, setUserData] = useState({})


    const token = window.localStorage.getItem('token');
    const dealerId = window.localStorage.getItem('dealerId');
    const user = window.localStorage.getItem('user');


    useEffect(() => {
        
        if(data){
            setUserData(data)

            const user = {}
            user.first_name = data.first_name;
            user.last_name = data.last_name;
            user.phone_number = data.phone_number;
            user.title = data.title;
            user.username = data.username;
            setEditData(user)
        }

        

    }, [data])



    const handleEditInput = (event) => {
        setEditData({
          ...editData,
          [event.target.name]: event.target.value,
        })
      }
    
    
      //FETCH POST EDIT USER
      const handleInputEditUser = () => {
    
        setIsLoadingEditUser(true);
    
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_UPDATE_USER}`
        //console.log(URI)
    
        // console.log(editData)
    
        window.fetch(URI, {
          method: 'POST',
          headers: {
            'Access-Control-Request-Headers': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token,
            // 'DealerId': dealerId,
            "username" : user,
            'x-api-key': uri.URI_X_API_KEY,
          },
          body: JSON.stringify(editData),
        })
          .then(response => {
            //console.log(response.status)
            return response.json()
          })
          .then(datos => {
            setIsLoadingEditUser(false);
    
            // console.log(datos);
    
            if (datos.status === 'SUCCESS') {
            //   window.location.reload();
              reloadGetUserData(state => !state)
              setEditMode(false)
            }
    
    
    
    
          }).catch(error => {
            console.log(error)
            // changeToken();
            // setReloadGetUserDetail(!reloadGetUserDetail);
    
    
          });
    
    
      }
    





    return (
        <div>

          <div className="settings_header">
            {/* <h2 className="settings-title_section">{title}</h2> */}
            {children}
            <button className="settings_editButton" onClick={() => { setEditMode(!editMode) }}>Edit</button>
          </div>

            {
                showExtraFields && 
                <div className="settings-row">
                    <span className="settings-span">Dealer name</span>
                    {isLoadingUser ? <SkeletonLoader width="50%" /> : <span className="settings-span">{userData.dealer_name}</span>}
                </div>
            }
          


            <div className="settings-row">
            <span className="settings-span">First name</span>
            {isLoadingUser ? <SkeletonLoader width="50%" /> :
              editMode ? <input type="text"
                className="settings-input"
                placeholder="Name"
                value={editData.first_name}
                onChange={handleEditInput}
                name="first_name" />
                : <span className="settings-span">{userData.first_name}</span>
            }
          </div>

          <div className="settings-row">
            <span className="settings-span">Last name</span>
            {isLoadingUser ? <SkeletonLoader width="50%" /> :
              editMode ? <input type="text"
                className="settings-input"
                placeholder="Lastname"
                value={editData.last_name}
                onChange={handleEditInput}
                name="last_name" />
                : <span className="settings-span">{userData.last_name}</span>
            }
          </div>

          <div className="settings-row">
            <span className="settings-span">Phone</span>
            {isLoadingUser ? <SkeletonLoader width="50%" /> :
              editMode ? <input type="number"
                className="settings-input"
                placeholder="Phone"
                value={editData.phone_number}
                onChange={handleEditInput}
                name="phone_number" />
                : <span className="settings-span">{userData.phone_number}</span>
            }
          </div>

          <div className="settings-row">
            <span className="settings-span">Title</span>
            {isLoadingUser ? <SkeletonLoader width="50%" /> :
              editMode ? 
                <input type="text"
                className="settings-input"
                placeholder="Title"
                value={editData.title}
                onChange={handleEditInput}
                name="title" />
                : 
                <span className="settings-span">{userData.title}</span>
            }
          </div>

          {
            editMode ?
              <div className="settings-row">
                <span></span>
                <div className="settings_saveButton-container">
                  <button className="settings_saveButton" onClick={handleInputEditUser}>Save</button>
                  
                      <Spinner
                        radius={26}
                        color={"grey"}
                        spinnerWidth={2}
                        visible={isLoadingEditUser}
                      />
                    

                </div>
              </div>
              : null
          }




          <div className="settings-row">
            <span className="settings-span">Role</span>
            {isLoadingUser ? <SkeletonLoader width="50%" /> : <span className="settings-span">{userData.role}</span>}

          </div>

          <div className="settings-row">
            <span className="settings-span">Email</span>
            {isLoadingUser ? <SkeletonLoader width="50%" /> : <span className="settings-span">{userData.email}</span>}

          </div>

          <div className="settings-row">
            <span className="settings-span">Username</span>
            {isLoadingUser ? <SkeletonLoader width="50%" /> : <span className="settings-span">{userData.username}</span>}

          </div>

          {
                showExtraFields && 
                <div className="settings-row">
                    <span className="settings-span">AquaFinance status</span>
                    {isLoadingUser ? <SkeletonLoader width="50%" /> : <span className="settings-span">{userData.is_active_in_aqua ? 'Active' : 'Inactive'}</span>}
                </div>
            }

          <div className="settings-row">
            <span className="settings-span">Permissions</span>
            <ul className="settings-span">



              {userData.hasOwnProperty('permissions') ?

                userData.permissions.map((item, index) => {
                  return (
                    <li key={index}>{item}</li>
                  )
                })
                : <SkeletonLoader width="50%" />

              }





            </ul>
          </div>
            
        </div>
    )
}

export default UserPersonalInformation
