import React from 'react'
import '../../assets/styles/ReportRow.css'
import Hint from "react-portal-hint";



const RemittanceReportRow = (props) => {

  
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');

    return (
        <>


        
        <div className="report_listContainer" >

            <div className={ isDeallerAllyAdmin === 'true' ? "tableGridForAdmin reportRow" : "tableGrid reportRow"} >
                <p className="report_listItem">{props.LoanNumber}</p>
                <p className="report_listItem">{props.LenderNbr}</p>
                <p className="report_listItem">{props.CustomerName}</p>
             
                {
                   isDeallerAllyAdmin === 'true' &&
                   <p className="report_listItem">{props.DealerName }</p>
                }
                <p className="report_listItem">{props.AddressState}</p>
                <p className="report_listItem">{props.FinancePartner === 'Aqua Finance' ? 'Aqua' : 'Pinnacle' }</p>
                <p className="report_listItem">{props.DealerAllyFundingDate}</p>
                <p className="report_listItem">{props.PurchaseAmt && parseFloat(props.PurchaseAmt).toFixed(2)}</p>        
                <p className="report_listItem">{props.BuyRate && parseFloat(props.BuyRate).toFixed(2)}</p>
                <p className="report_listItem">{props.ProgramDiscountPct && parseFloat(props.ProgramDiscountPct).toFixed(2)}</p>
                <p className="report_listItem">{props.FinalBid && parseFloat(props.FinalBid).toFixed(2)}</p>
                <p className="report_listItem">{props.ACHBonus && parseFloat(props.ACHBonus).toFixed(2)}</p>
                <p className="report_listItem">{props.DocFee && parseFloat(props.DocFee).toFixed(2)}</p>
                <p className="report_listItem">{props.NetProceeds && parseFloat(props.NetProceeds).toFixed(2)}</p>
                {
                  props.Notes ?  
                  <Hint content={props.Notes } place="left">
                    <p className="report_listItem">{props.Notes }</p>
                 </Hint> 
                 :
                 <p className="report_listItem">{props.Notes }</p>
                }
                <p className="report_listItem">{props.Promotion }</p>
                <p className="report_listItem">{props.APR }</p>
                <p className="report_listItem">{props.Term }</p>
                <p className="report_listItem">{props.Program}</p>
                
              
            </div>
  
        </div>

        


          
            
        </>
    )
}

export default RemittanceReportRow