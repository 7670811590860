import React, {useState} from 'react'
import '../../assets/styles/CommercialApplications.css'
import CommercialLoanStepper from './CommercialLoanStepper'
import CommercialLoanForm from './CommercialLoanForm'



const CommercialLoan = ({applicationId, inspectMode, setCommercialId}) => {



    const [step, setStep] = useState({
        CompanyInformation : false,
        ContactInformation : false,
        EquipmentInformation : false,
        AdminCommercialInfo: false,
        AdditionalNotes: false
        // BrokerWriteUp : false,
        // BestRateOption : false,
    })

    return (
        <>
            <div className={inspectMode ? 'commercialApp_mainContainer_detailsView' : 'commercialApp_mainContainer'}>
                {
                    !inspectMode &&
                    <div>
                        <CommercialLoanStepper
                            step={step}
                        />
                    </div>
                }

                <div>
             
                    <CommercialLoanForm
                        setStep={setStep}
                        step={step}
                        applicationId={applicationId}
                        inspectMode={inspectMode}
                        setCommercialId={setCommercialId}
                    />
                </div>

            </div>

        </>
    )
}

export default CommercialLoan