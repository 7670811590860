import React from 'react'
import '../../assets/styles/CommercialLoanStepper.css'
import { Icon } from '@iconify/react';
import { Link } from "react-scroll";

const CommercialLoanStepper = ({step}) => {

    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');

    const { CompanyInformation,
            ContactInformation,
            EquipmentInformation,
            AdditionalNotes,
            AdminCommercialInfo
            // BrokerWriteUp,
            // BestRateOption
    } = step
    

    return (
        <div className='commercialLoanStepper'>
            <Link activeClass="activeStep" smooth spy to="company">
                    <Icon width="20px" color={CompanyInformation ? "31D902" : "C2C2C2"} icon="akar-icons:circle-check" />
                    <p>Company Information</p>  
            </Link>
            <Link activeClass="activeStep" smooth spy to="contact">
                <Icon width="20px" color={ContactInformation ? "31D902" : "C2C2C2"} icon="akar-icons:circle-check" />
                <p>Ownership/Contact Information</p>
            </Link>
            <Link activeClass="activeStep" smooth spy to="equipment">
                <Icon width="20px" color={EquipmentInformation ? "31D902" : "C2C2C2"} icon="akar-icons:circle-check" />
                <p>Equipment Information</p>
            </Link> 
            <Link activeClass="activeStep" smooth spy to="aditionalNotes">
                <Icon width="20px" color={AdditionalNotes ? "31D902" : "C2C2C2"} icon="akar-icons:circle-check" />
                <p>Additional Notes</p>
            </Link> 
            {
                 isDeallerAllyAdmin === "true" &&
                 <Link activeClass="activeStep" smooth spy to="admin-info">
                <Icon width="20px" color={'#F2F2F2'} icon="akar-icons:circle-check" />
                <p>DA Administrator</p>
                </Link>
            }
            
            {/* <Link activeClass="activeStep" smooth spy to="write-up">
                <Icon width="20px" color={BrokerWriteUp ? "31D902" : "C2C2C2"} icon="akar-icons:circle-check" />
                <p>Broker Write-Up</p>
            </Link>
            <Link activeClass="activeStep" smooth spy to="bestRateOption">
                <Icon width="20px" color={BestRateOption ? "31D902" : "C2C2C2"} icon="akar-icons:circle-check" />
                <p>Best Rate Option</p>
            </Link> */}
            <Link activeClass="activeStep" smooth spy to="files">
                <Icon width="20px" color={'#F2F2F2'} icon="akar-icons:circle-check" />
                <p>File Upload</p>
            </Link>
            
            
        </div>
    )
}

export default CommercialLoanStepper