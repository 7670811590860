import React,{useState} from 'react'
import Page from '../components/general/Page'
import CommercialLoan from '../components/CommercialLoan/CommercialLoan'


const CreateCommercialLoan = () => {

    const [commercialId, setCommercialId] = useState(null)
    return (
        <Page title={`Commercial Loan Applications ${commercialId ? commercialId : ''}`}>
            <CommercialLoan setCommercialId={setCommercialId}/>
        </Page>
    
    )
}

export default CreateCommercialLoan