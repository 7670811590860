import React, { useState, useEffect, useContext} from "react";
import "../assets/styles/ApplicationForm.css";
import "../assets/styles/StepCounter.css";
import "../assets/styles/general/Buttons.css"
import Notification from './Notification';
import axios from 'axios'
import {AuthContext} from '../AuthContext';
import iconCheck from '../assets/images/iconCheck.png';
import SpinnerPopUp from './SpinnerPopUp';
import Spinner from 'react-spinner-material';
import iconArrowBack from '../assets/images/iconArrowBack.png';
import iconDelete from '../assets/images/iconDelete.png';
import iconSadFace from '../assets/images/iconSadFace.png';
import useIsSafari from '../hooks/useIsSafari'
import AquaFinanceContract from "./applicationsPage/AquaFinanceContract";
import ExternalForm from "./ExternalForm";
import ProposalForm from './proposals/ProposalForm'
import AquaPromotionCard from "./applicationsPage/AquaPromotionCard";
import moment from 'moment-timezone';
import AquaLoanHistory from "./applicationsPage/AquaLoanHistory";
import SkeletonLoader from "tiny-skeleton-loader-react";
import {Link} from 'react-router-dom';
import AlertBox from "./AlertBox";
import useAlertBox from "../hooks/useAlertBox";




require('dotenv').config();

const ApplicationFormAquaFinance=(props) =>{

  //ESTADOS

  const{uri, proposalInfo, setProposalInfo, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);


  const isSafari = useIsSafari()

  const [errorFetch, setErrorFetch] = useState(false)
  const [reloadGetProducts, setReloadGetProducts] = useState(false)

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [errorIndex, setErrorIndex] = useState(false);

  const [step, setStep] = useState(props.setStep3 ? 3 : props.urlParamApplicantId ? 1 : props.errorStatus ? 1 : 4);

 
 

  //ESTADOS DE CREAR APPLICATION
  const [applicant, setApplicant] = useState(props.prospectData ? props.prospectData.Applicant : {});
  const [coApplicant, setCoApplicant] = useState(props.prospectDataCoApplicant ? props.prospectData.CoApplicant : {});
  const [loan, setLoan] = useState({});
  const [statusMessages, SetStatusMessages] = useState([])
  const [applicationStatus, SetApplicationStatus] = useState('')
  const [applicationRTCDate, setApplicationRTCDate] = useState('')
  const [hasMessages, SetHasMessages] = useState(false);
  const [hasCoApplicant, setHasCoapplicant] = useState(props.prospectData ? props.prospectData.CoApplicant ? true :false : false);
  const [hasCoApplicantValue, setHasCoapplicantValue] = useState({});
  const [applicationProduct, SetApplicationProduct] = useState([]);
  const [showNotificationUserAlredyExists, setShowNotificationUserAlredyExists] = useState(false)
  const [showNotificationUserNotFound, setShowNotificationUserNotFound] = useState(false)
  const [statusBuyRate, setStatusBuyRate] = useState('')
  const [copyIdentificationTypeValue, setCopyIdentificationTypeValue] = useState(undefined)
  const [copyResidenceDataFromApplicant, setCopyResidenceDataFromApplicant] = useState(false)
  const [termsCheck, setTermsCheck] = useState(false)
  const [termsCheckBorderRed, setTermsCheckBorderRed] = useState(false)
  const [allowEditSSN, setAllowEditSSN] = useState(props.prospectData && props.prospectData.Applicant && props.prospectData.Applicant.SocialSecurityNumber ? false : true)
  const [isLoadingGetStatus, setIsLoadingGetStatus] = useState(false)
  const [applicationStatusInfo, setApplicationStatusInfo] = useState({})
  const [contractEsignStatus, setContractEsignStatus] = useState('')
  const [errorUnavailableId, setErrorUnavailableId] = useState(false)

  //handler includetax
  const [includeTax, setIncludeTax] = useState(false);
  const [changeStateIncludeTax, setChangeStateIncludeTax] = useState(true);

  //handler creditType
  const [creditTypeClosedEnd, setCreditTypeClosedEnd] = useState(false);
  const [changeInputTax, setChangeInputTax] = useState(false);


  //ESTADOS DE PROMOTIONS
  const [hasPromotion, SetHasPromotion] = useState(false)
  const [openPromotionFirstAccordion, setOpenPromotionFirstAccordion] = useState(false)
  const [availablePromotions, setAvailablePromotions] = useState([])
  const [promotions, SetPromotions] = useState({});
  const [isSeetingPromotion, SetIsSeetingPromotion] = useState(false);
  const [promotionSaved, SetPromotionSaved] = useState(false);
  const [noPromotion, setNoPromotion] = useState(false)
  const [reloadGetPromotions, SetReloadGetPromotions] = useState(false);
  const [showNotificationPromotionPostOk, setShowNotificationPromotionPostOk] = useState(false);
  const [showNotificationErrorPostPromotion, SetShowNotificationErrorPostPromotion] = useState(false)

  const [errors, setErrors] = useState({});
  const [errorsCoApplicant, setErrorsCoApplicant] = useState({});
  const [errorsLoan, setErrorsLoan] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingCoApplicant, setIsSubmittingCoApplicant] = useState(false);
  const [isSubmittingLoan, setIsSubmittingLoan] = useState(false);

  const [showNotificationOk, SetShowNotificationOk] = useState(false);
  const [showNotificationError, SetShowNotificationError] = useState(false)
  const [errorResponse, setErrorResponse] = useState({})

  //INCOMES
  const [incomeList, setIncomeList] = useState([]);
  const [errorIncomes, setErrorIncomes] = useState([{}])
  const [incomeListCoApplicant, setIncomeListCoApplicant] = useState([]);
  const [errorIncomesCoApplicant, setErrorIncomesCoApplicant] = useState([{}])
  const [isSubmitiongIncomeApplicant, setIsSubmitingIncomeApplicant] = useState(false);
  const [isSubmitiongIncomeCoApplicant, setIsSubmitingIncomeCoApplicant] = useState(false)


  //ESTADOS DE DOCUMENTS
  const [documentsList, SetDocumentsList] = useState([]);
  const [errorsdocument, setErrorsDocument] = useState({});
  const [isSubmittingDocument, setIsSubmittingDocument] = useState(false);
  const [documentForm, SetDocumentForm] = useState({});
  const [hasdocuments, SetHasDocuments] = useState(false);
  const [reloadGetDocuments, SetReloadGetDocuments] = useState(false);
  const [showPostNotificationSucces, SetShowPostNotificationSucces] = useState(false);
  const [showNotificationDocumentAlreadyExist, SetShowNotificationDocumentAlreadyExist] = useState(false);
  const [showNotificationTypeDocumentError, SetShowNotificationTypeDocumentError] = useState(false);
  const [showNotificationDocumentUploadError, setShowNotificationDocumentUploadError] = useState(false)
  const [showNotificationTypeDocumentGeneralError, SetShowNotificationTypeDocumentGeneralError] = useState(false);
  const [showNotificationDocumentDeletedSuccessfully, SetShowNotificationDocumentDeletedSuccessfully] = useState(false);
  const [messageDocumentTypeError, setMessageDocumentTypeError] = useState()
  const [documentsAlertBox, setDocumentsAlertBox] = useState({
    title: '',
    text: '',
    type: ''
  } )

  const [documentsShowAlert, setDocumentsShowAlert] = useAlertBox({
    autoClose : true, 
    autoCloseTimeout : 5000, 
    initialShow: false
  })

  const [reloadGetApplicationDetail, SetReloadGetApplicationDetail] = useState(false);

  const [copyAddresDataFromApplicant, setCopyAddressDataFromApplicant] = useState(false)

  const [allApplicationData, setAllApplicationData] = useState({})

  const [reDoSigningContract, setReDoSigningContract] = useState(false)

  const [editProposal, setEditProposal] = useState(false);
  const [createProposalAfterApplication, setCreateProposalAfterApplication] = useState(false)



  const token = window.localStorage.getItem('token');
  const user = window.localStorage.getItem('user');
  const URI_X_API_KEY_I = uri.URI_X_API_KEY;
  const refreshToken = window.localStorage.getItem('refreshToken');
  const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
  const canCreateProposal = window.localStorage.getItem("allow_customer_proposals");
  const hideBuyRate = localStorage.getItem('hideBuyRate');
  const userRole = localStorage.getItem('userRole');











  //POST APPLICATION
  const handleSubmitAllData = async (event) => {
  
    setIsLoading(true);
    setErrorUnavailableId(false)
    
  
    delete coApplicant.ApplicantRelationship;







    //INDETIFICATION PermanentResidencyCard applicant
    if(applicant.IdentificationType === 'PermanentResidencyCard'){
      applicant.IsIssueDatePresent = 'true'
      delete applicant.ExpirationDateUnavailable; 
      delete applicant.IdentificationIssueState
      delete applicant.IsIssueStatePresent
    }else if(applicant.IdentificationType === 'Unavailable'){
      delete applicant.IdentificationNumber;
      delete applicant.IsIssueDatePresent;
      delete applicant.IsIssueStatePresent;
      delete applicant.IdentificationIssueState;
      delete applicant.IdentificationIssuedDate;
      delete applicant.IdentificationExpirationDate; 
      delete applicant.ExpirationDateUnavailable; 
    }else if(applicant.IdentificationType === 'UnitedStatesMilitaryId'){
      delete applicant.IsIssueStatePresent
      delete applicant.IdentificationIssueState
      if(applicant.ExpirationDateUnavailable === 'true'){
        delete applicant.IdentificationExpirationDate
      }
    }else if(applicant.IdentificationType === 'UnitedStatesPassport'){
      delete applicant.IdentificationIssueState
      delete applicant.IsIssueStatePresent
      delete applicant.ExpirationDateUnavailable;
    }else if(applicant.IdentificationType === 'DriversLicense'){
      delete applicant.ExpirationDateUnavailable; 
    }


    //EMPLOYED TYPE APPLICANT
    if(applicant.EmploymentType === 'Unemployed' || applicant.EmploymentType === 'Retired'){
      delete applicant.CurrentEmployer; 
      delete applicant.Occupation;
      delete applicant.GrossMonthlyIncome;
    }else if(applicant.EmploymentType === 'SelfEmployed'){
      delete applicant.CurrentEmployer; 
    }


     //RESIDENCE APPLICANT
     if(applicant.ResidenceType === 'Rent'){
        delete applicant.HomeValue;
     }else if(applicant.ResidenceType === 'OwnedFreeAndClear'){
        delete applicant.ResidenceMonthlyPayment;
     }


     //COAPPLICANT-------------------------------
     

    if(coApplicant.IdentificationType === 'PermanentResidencyCard'){
      coApplicant.IsIssueDatePresent = 'true'
      delete coApplicant.ExpirationDateUnavailable; 
      delete coApplicant.IdentificationIssueState
      delete coApplicant.IsIssueStatePresent
    }else if(coApplicant.IdentificationType === 'Unavailable'){
      delete coApplicant.IdentificationNumber;
      delete coApplicant.IsIssueDatePresent;
      delete coApplicant.IsIssueStatePresent;
      delete coApplicant.IdentificationIssueState;
      delete coApplicant.IdentificationIssuedDate;
      delete coApplicant.IdentificationExpirationDate; 
      delete coApplicant.ExpirationDateUnavailable; 
    }else if(coApplicant.IdentificationType === 'UnitedStatesMilitaryId'){
      delete coApplicant.IsIssueStatePresent
      delete coApplicant.IdentificationIssueState
      if(coApplicant.ExpirationDateUnavailable === 'true'){
        delete coApplicant.IdentificationExpirationDate
      }
    }else if(coApplicant.IdentificationType === 'UnitedStatesPassport'){
      delete coApplicant.IdentificationIssueState
      delete coApplicant.IsIssueStatePresent
      delete coApplicant.ExpirationDateUnavailable;
    }else if(coApplicant.IdentificationType === 'DriversLicense'){
      delete coApplicant.ExpirationDateUnavailable; 
    }


    //EMPLOYED TYPE COAPPLICANT
    if(coApplicant.EmploymentType === 'Unemployed' || coApplicant.EmploymentType === 'Retired'){
      delete coApplicant.CurrentEmployer; 
      delete coApplicant.Occupation;
      delete coApplicant.GrossMonthlyIncome;
    }else if(coApplicant.EmploymentType === 'SelfEmployed'){
      delete coApplicant.CurrentEmployer; 
    }


    //RESIDENCE COAPPLICANT
    if(coApplicant.ResidenceType === 'Rent'){
      delete coApplicant.HomeValue;
    }else if(coApplicant.ResidenceType === 'OwnedFreeAndClear'){
        delete coApplicant.ResidenceMonthlyPayment;
    }



    

    //Armar el objeto que se envia
    const formData = {};
    let email = ''

    if(process.env.REACT_APP_BUILD_ENV === 'dev' || process.env.REACT_APP_BUILD_ENV === 'stg'){
      email = 'support@aquafinance.com';  
    }else{
      email = window.localStorage.getItem('userEmail');
    }


      formData.Applicant = applicant;
      formData.LoanParameters = loan;
      formData.AssignedUserEmailAddress = email;
      formData.FinantialInstitutionId = 'FI-0001';

    if(hasCoApplicant === true){
      formData.CoApplicant = coApplicant;
    }

    if(props.hasOwnProperty('appData')){
      formData.ProspectLoanApplicationId = props.appData.ApplicationId 
    }


    //Borrar DownPayment si es igual a cero
    if( formData.LoanParameters.DownPayment === 0 ||  formData.LoanParameters.DownPayment === '0' ||  formData.LoanParameters.DownPayment === false){
      delete formData.LoanParameters.DownPayment
    }else{
      formData.LoanParameters.DownPayment = parseFloat(formData.LoanParameters.DownPayment);
    }

    //BORRAR MIDLEINITIAL
    if(formData.Applicant.MiddleInitial === false || formData.Applicant.MiddleInitial === ''){
      delete formData.Applicant.MiddleInitial
    }
    if(hasCoApplicant === true){
      if(formData.CoApplicant.MiddleInitial === false || formData.CoApplicant.MiddleInitial === ''){
        delete formData.CoApplicant.MiddleInitial
      }
    }
    

    
    
    //Borrar incomes que vienen de details
    delete applicant.AdditionalIncomeSources;
    delete coApplicant.AdditionalIncomeSources;


    //CONVERT TO FLOAT LOANPARAMETERS
    formData.LoanParameters.SellingPrice = parseFloat(formData.LoanParameters.SellingPrice);  
    formData.LoanParameters.AmountFinanced = parseFloat(formData.LoanParameters.AmountFinanced);
    // formData.LoanParameters.SalesTax = parseFloat(formData.LoanParameters.SalesTax);
    if( formData.Applicant.ResidenceMonthlyPayment){
      formData.Applicant.ResidenceMonthlyPayment = parseFloat(formData.Applicant.ResidenceMonthlyPayment);
    } 
    if( formData.Applicant.HomeValue){
      formData.Applicant.HomeValue = parseFloat(formData.Applicant.HomeValue);
    } 
    if( formData.Applicant.GrossMonthlyIncome){
      formData.Applicant.GrossMonthlyIncome = parseFloat(formData.Applicant.GrossMonthlyIncome);
    } 
    if(hasCoApplicant === true){
      if(formData.CoApplicant.ResidenceMonthlyPayment){
        formData.CoApplicant.ResidenceMonthlyPayment = parseFloat(formData.CoApplicant.ResidenceMonthlyPayment);
      }  
      if( formData.CoApplicant.HomeValue){
        formData.CoApplicant.HomeValue = parseFloat(formData.CoApplicant.HomeValue);
      } 
      if( formData.CoApplicant.GrossMonthlyIncome){
        formData.CoApplicant.GrossMonthlyIncome = parseFloat(formData.CoApplicant.GrossMonthlyIncome);
      } 
    }


    //Añadir otherincomes a Applicant
    if(incomeList.length > 0){
      const newList = incomeList.map(item => { if(item.Id && item.Id.includes('Income') ){delete item.Id}; item.Amount = parseFloat(item.Amount); return item})
      formData.Applicant.AdditionalIncomeSources = newList;
      //setApplicant(newIncomeList)
    }

    //añadir otherincomes a Coapplicant
    if(incomeListCoApplicant.length > 0){
      const newList = incomeListCoApplicant.map(item=> { if(item.Id && item.Id.includes('Income') ){delete item.Id}; item.Amount = parseFloat(item.Amount); return item}) 
      formData.CoApplicant.AdditionalIncomeSources = newList;
      //setCoApplicant(newIncomeListCoApplicant)
    }

    //AÑADIR APPLICANTID
    if(props.hasOwnProperty('applicant_id')){
      formData.Applicant.ApplicantId = props.applicant_id
    }

    if(props.applicantIdFromPostCommon){
      formData.ProspectLoanApplicationId = props.applicantIdFromPostCommon
    }

    if(props.urlParamApplicantId){
      formData.Applicant.ApplicantId = props.urlParamApplicantId
    }

    
    if(proposalInfo.hasInfo){
      formData.CustomerProposalId = proposalInfo.info.customerProposalId
      formData.Applicant.ApplicantId = proposalInfo.info.ApplicantId
    }
    




    
    //BORRAR BUSINESSPHONE SI ES VACIO
    if(formData.Applicant.BusinessPhone === ''){
      delete formData.Applicant.BusinessPhone
    }
    if(hasCoApplicant === true){
      if(formData.CoApplicant.BusinessPhone === ''){
        delete formData.CoApplicant.BusinessPhone
      }
    }

   
    
    

  

    // console.log(formData)
    //console.log(JSON.stringify(formData))


    let URI = '';
    if(props.updateId){
      URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}update/${props.updateId}`;
    }else{
      URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}`;
    }
   
      
    //console.log(URI)

    

    try{
      const rawResponse = await window.fetch(URI, {
        method: 'POST',
        headers: {
          // 'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        },
        body: JSON.stringify(formData),
      
      });
      //console.log(JSON.stringify(formData))
      //console.log(rawResponse)
      setIsLoading(false);

      const content = await rawResponse.json();
      console.log(content);    
      setProposalInfo({info:{}, hasInfo: false})

      if(content.Status === 'FilledOut' || content.Status === 'Approved' || content.Status === 'Pending' || content.Status === 'SUCCESS'){   
        SetShowNotificationOk(true);
      }else if(content.status === 'APPLICANT_ALREADY_EXISTS'){
        setShowNotificationUserAlredyExists(true)
        setErrorResponse({Message : "Applicant already exists."})
      }else if(content.status === 'USER_NOT_FOUND' || 
              content.status === 'DEALER_NOT_FOUND' ||
              content.status === 'APPLICANT_NOT_FOUND' ||
              content.status === 'COAPPLICANT_NOT_FOUNDD' ||
              content.status === 'DEALER_EXTERNAL_ID_NOT_SET' ||
              content.status === 'FINANCIAL_INSTITUTION_NOT_FOUND' ||
              content.status === 'PINNACLE_DEALER_PARAMETERS_NOT_SET' ||
              content.status === 'FINANCIAL_INSTITUTION_NOT_FOUND' ||
              content.status === 'APPLICANT_IS_NULL' 
      ){
        SetShowNotificationError(true)
        setErrorResponse({Message : content.status, Errors: content.messages})
      }else if(content.status === 'UNAVAILABLE_ID_ERROR'){
        setErrorUnavailableId(true)
      }   
      else{
        SetShowNotificationError(true);
        setErrorResponse(content)
      }


    }
    catch(error){
      console.log(error)
      setIsLoading(false);
      SetShowNotificationError(true);            
    }

        
      
  
};






  //GET PRODUCTS FOR PRODUCTS OR SERVICES FIELD IN LOAN FORM
  useEffect(()=>{
    if(props.hasOwnProperty('updateId') || props.hasOwnProperty('prospectData')){
      const abortController = new AbortController();
      const signal = abortController.signal;

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_GET_PRODUCTS}`
    // console.log(URI)
   
    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'FinancialInstitution' : 'FI-0001',
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,           
          }
    })
    .then(response => response.json())
    .then(datos => {
        
        //console.log(datos)
        SetApplicationProduct(datos);

        setErrorFetch(false)
        
    }).catch(error => {
      console.log(error)

      if(errorFetch === false){
          //console.log('SE VENCIO EL PRIMER TOKEN')
          
          const data = {
              "username" : user,
              "refresh_token" : refreshToken,
              
          }
          const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;

          window.fetch(URI_REFRESHTOKEN,{
          method: 'POST',
          headers: {           
          'Content-Type': 'application/json',  
          'x-api-key' : URI_X_API_KEY_I,         
          },
          body: JSON.stringify(data),
          })
          .then(response => response.json())
          .then(datos => { 
              //console.log(datos)
              window.localStorage.removeItem('token');
              const newToken = datos.id_token;
              window.localStorage.setItem('token', newToken);
              //console.log('SE REEMPLAZO EL TOKEN VIEJO POR EL NUEVO');
              setReloadGetProducts(!reloadGetProducts);

              setErrorFetch(true) 
          })
      }
    });

      return function cleanup(){
        abortController.abort();
      }

    }
  },[reloadGetProducts]);





  // GET APPLICATIONDETAIL DETAIL
  useEffect(()=>{

    if(props.application_id){
      setIsLoadingDetails(true);
      

    const abortController = new AbortController();
    const signal = abortController.signal;

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/get-common-details`
   
    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,
                  
          }
    })
    .then(response => response.json())
    .then(datos => {
      setIsLoadingDetails(false);      
        console.log(datos)

        if(datos.Message){
          return
        }
       

        setAllApplicationData(datos)

       
        if(datos.Applicant){
          setApplicant({...datos.Applicant, 
                          BusinessPhone: (datos.Applicant.BusinessPhone !== datos.Applicant.CellPhone) ? datos.Applicant.BusinessPhone : undefined, 
                        });
        } 

        if(datos.CoApplicant){
          setHasCoapplicant(true);
          setCoApplicant(datos.CoApplicant);        
        } 

        
        // if(datos.hasOwnProperty('LoanParameters')){
        //   if(datos.LoanParameters.SalesTaxIncluded != null && (datos.LoanParameters.SalesTaxIncluded === 'true' || datos.LoanParameters.SalesTaxIncluded === true) ){
        //     setIncludeTax(true);
        //   }
        // }
        

        //PROMOTIONS
        if(datos.hasOwnProperty('Promotion')){
          SetHasPromotion(true)
          SetPromotions(datos.Promotion)
        }else{
          SetHasPromotion(false)
        }

        //INCOMES
        if(datos.Applicant && datos.Applicant.AdditionalIncomeSources){
          //setIncomeList([])
          const errors = datos.Applicant.AdditionalIncomeSources
          const numErrors = errors.map(item => { return {}})
          setErrorIncomes(numErrors)
          setIncomeList(datos.Applicant.AdditionalIncomeSources)
          
        }

        //INCOMES COAPPLICANT
        if(datos.CoApplicant){
          if(datos.CoApplicant.AdditionalIncomeSources){

            const errors = datos.CoApplicant.AdditionalIncomeSources
            const numErrors = errors.map(item => { return {}})
            setErrorIncomesCoApplicant(numErrors)
            setIncomeListCoApplicant(datos.CoApplicant.AdditionalIncomeSources)
          } 
        }
        

        //LOAN
        if(datos.LoanParameters){
          setLoan({...datos.LoanParameters,
                      SellingPrice: parseFloat(datos.LoanParameters.SellingPrice).toFixed(2),
                      DownPayment: parseFloat(datos.LoanParameters.DownPayment).toFixed(2),
                      // SalesTax: parseFloat(datos.LoanParameters.SalesTax).toFixed(2),
                      AmountFinanced: parseFloat(datos.LoanParameters.AmountFinanced).toFixed(2),
          });
        } 
       
        

        setErrorFlagFromContext(false)
        
    }).catch((error, response) => {
      console.log(error)
      if(errorFlagFromContext === false && error.status === 401){
          changeToken().then(response => console.log(response)).catch(error => console.log(error))
      }    
      });



    return function cleanup(){
      abortController.abort();
  }
    }   
},[reloadGetPromotions, reloadGetApplicationDetail, reloadFetchFromContext]);









//LLENAR LOS DATOS DE INCOMES DEL EXTERNAL FORM
useEffect(()=>{

        if(props.hasOwnProperty('appData')){

            //INCOMES
            if (props.appData.Applicant.AdditionalIncomeSources) {
                //setIncomeList([])
                const errors = props.appData.Applicant.AdditionalIncomeSources
                const numErrors = errors.map(item => { return {} })
                setErrorIncomes(numErrors)
                setIncomeList(props.appData.Applicant.AdditionalIncomeSources)

            }

            //INCOMES COAPPLICANT
            if (props.appData.CoApplicant) {
                if (props.appData.CoApplicant.AdditionalIncomeSources) {

                    const errors = props.appData.CoApplicant.AdditionalIncomeSources
                    const numErrors = errors.map(item => { return {} })
                    setErrorIncomesCoApplicant(numErrors)
                    setIncomeListCoApplicant(props.appData.CoApplicant.AdditionalIncomeSources)
                }
            }

        }
    }, [])




 // RELLENAR APPLICANT EXISTENTE
 useEffect(()=>{

  if(props.urlParamApplicant_Id){
    setIsLoadingDetails(true);
    

  const abortController = new AbortController();
  const signal = abortController.signal;

  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GET_APPLICANT_DETAILS}${props.urlParamApplicant_Id}`
 
  

  window.fetch(URI,{
      method: 'GET',
      signal: signal,
      headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token, 
          'FinancialInstitution' : 'FI-0001', 
          'x-api-key' : URI_X_API_KEY_I,
                
        }
  })
  .then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);

      //console.log('INFORMACION DEL APPLICANT');       
      console.log(datos)

      //CAMBIAR EL VALOR DE HASCOAPPLICANT
      datos.HasCoApplicant = false;


      if(datos){
        setCopyIdentificationTypeValue(datos.IdentificationType)
        setApplicant(datos);
        
      } 
     

      // if(datos.HasCoApplicant === true){
      //   setHasCoapplicant(true);
      // }

       //INCOMES
       if(datos.AdditionalIncomeSources){
        //setIncomeList([])
        const errors = datos.AdditionalIncomeSources
        const numErrors = errors.map(item => { return {}})
        setErrorIncomes(numErrors)
        setIncomeList(datos.AdditionalIncomeSources)
        
      }
     
     
      

      setErrorIndex(false)
      
  }).catch(error => {
    console.log(error)

            if(errorIndex === false){
            //console.log('SE VENCIO EL PRIMER TOKEN')
          
            const data = {
                "username" : user,
                "refresh_token" : refreshToken,
                'x-api-key' : URI_X_API_KEY_I, 
            }

            const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
            window.fetch(URI_REFRESHTOKEN ,{
            method: 'POST',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : URI_X_API_KEY_I,          
            },
            body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(datos => { 
                //console.log(datos)

                window.localStorage.removeItem('token');
                const newToken = datos.id_token;
                window.localStorage.setItem('token', newToken);
                console.log('REPLACE TOKEN');
                SetReloadGetApplicationDetail(!reloadGetApplicationDetail)
                setErrorIndex(true)
            })
          }
});

  return function cleanup(){
    abortController.abort();
}
  }   
},[reloadGetPromotions, reloadGetApplicationDetail]);





const getStatus = () =>{
  if(props.application_id){
    setIsLoadingGetStatus(true)
    
  
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GET_APPLICATION_STATUS}${props.application_id}`
    // console.log(URI)
  
    const abortController = new AbortController();
    const signal = abortController.signal;
    
  
    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,       
          }
    })
    .then(response => {

      if (response.ok) {
          return response.json();
        }
      return Promise.reject(response);
    })
    .then(datos => {
        setIsLoadingGetStatus(false)
        //console.log(datos)

        setApplicationStatusInfo(datos)
  
        if(datos.StatusMessages){
          SetHasMessages(true)
        }
        SetStatusMessages(datos.StatusMessages)
        SetApplicationStatus(datos.Status)  
        setApplicationRTCDate(datos.RTCDate)

        setStatusBuyRate(datos.BuyRate)
    }).catch((error, response) => {
      setIsLoadingGetStatus(false)
      console.log(error)
      if(errorFlagFromContext === false && error.status === 401){
          changeToken().then(response => console.log(response)).catch(error => console.log(error))
      }    
  });
  
    return function cleanup(){
      abortController.abort();
  }
    }
}


const refreshContractStatuses = () => {
 

  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/paperwork-signed-status-refresh`


  fetch(URI,{
      method: 'GET',
      headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token,   
          'x-api-key' : uri.URI_X_API_KEY,  
        }
  })
  .then(response => {

      if (response.ok) {
          return response.json();
        }
      return Promise.reject(response);
  })
  .then(data => {
    
     
      //console.log(data);
      setContractEsignStatus(data.SigningContractStatus)
    
 

  })
  .catch((error, response) => {
      console.log(error)
   
  });

}



 //GET STATUS APPLICANT
 useEffect(()=>{
  // setTimeout(function(){ getStatus()  }, 2000);
  getStatus()
  refreshContractStatuses()

},[reloadGetApplicationDetail]);





// usePolling(() => { 
//       if( applicationStatus !== 'Approved'){
//           console.log('xx')
//           getStatus()  
         
//       }
// }, 5000);










// GET DOCUMENTS
useEffect(()=>{

  if(props.application_id){
    setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}`;


  const abortController = new AbortController();
  const signal = abortController.signal;
  

  window.fetch(URI,{
      method: 'GET',
      signal: signal,
      headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  })
  .then(response => response.json())
  .then(datos => {
      setIsLoadingDetails(false);
      //console.log('DATOS DE DOCUMENTS')
      //console.log(datos)

      if(datos.length === 0){
        //console.log('ESTA APLICACION NO TIENE NINGUN DOCUMENTO GUARDADO');
        SetHasDocuments(false);
      }else{
        SetHasDocuments(true);
        SetDocumentsList(datos);
      }   
 
  })

  return function cleanup(){
    abortController.abort();
}
  }   
},[reloadGetDocuments]);




//FUNCION PARA DESCARGAR ARCHIVOS
function showFile(blob, documentName){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  //console.log(blob)
  let newBlob = new Blob([blob], {type: 'application/pdf'})
  

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  let link = document.createElement('a');
  link.href = data;
  link.download= documentName;
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}


//DOWLOAD DOCUMENT
const dowloadDocument = (fileId, documentName) =>{

  
  //const uriEncode = encodeURIComponent(filename);
  //console.log(uriEncode)

  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}${fileId}`;
  //console.log(URI)
  window.fetch(URI,{
      method: 'GET',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  }).then(r =>  r.blob())
  //.then(showFile)
  .then(blob=>{
    //console.log(documentName)
    showFile(blob, documentName)
  })
  
  
  /*.then(response => response.json())
  .then(datos => {
      console.log('DATOS DOWLOAD DOCUMENT')
      console.log(datos)


})*/
}

//DELETE DOCUMENT
const deleteDocument = (fileId) =>{
  setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}${fileId}`;
  
  window.fetch(URI,{
      method: 'DELETE',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  }).then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);
      //console.log('DATOS DOWLOAD DOCUMENT')
      //console.log(datos)
      if(datos.status === 'SUCCESS'){
        SetReloadGetDocuments(!reloadGetDocuments)
        SetShowNotificationDocumentDeletedSuccessfully(true);
      }

})
}



//POST DOCUMENT
const sendDocument = () =>{
  setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}`;

  let data = new FormData()
  data.append('document-type', documentForm.DocumentFileType);
  data.append('file', documentForm.File);

  //console.log(data)
 
  window.fetch(URI,{
      method: 'PUT',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        },
        body: data
  }).then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);

      //console.log(datos)
      if(datos.status === 'SUCCESS'){
        SetReloadGetDocuments(true)
        SetDocumentForm({});
        // SetShowPostNotificationSucces(true);
        
        delete documentForm.DocumentFileType;
        delete documentForm.File;

        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'The document was successfully uploaded',
          type: 'success'
        })
      }else if(datos.status === 'DOCUMENT_TYPE_NOT_ACCEPTED'){
        // setMessageDocumentTypeError({message: datos.messages[0], title: "Type of document not accepted"})
        // setShowNotificationDocumentUploadError(true)

        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'Type of document not accepted',
          text: datos.messages[0],
          type: 'error'
        })
        
      }else if(datos.status === 'FILESIZE_TOO_BIG'){
        // setMessageDocumentTypeError({message: datos.messages[0], title: "File size is too big"})
        // setShowNotificationDocumentUploadError(true)

        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'File size is too big',
          text: datos.messages[0],
          type: 'error'
        })
        
      }else if(datos.status === 'FAILED_UPLOADING_DOCUMENT_TO_LENDER'){
        // setMessageDocumentTypeError({message: 'Please email file to info@dealerallyprogram.com with the subject “File failed to upload to Aqua” and the loan number.', title: "Failed uploading document to Aqua"})
        // setShowNotificationDocumentUploadError(true)

        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'Failed uploading document to Aqua',
          text: 'Please email file to info@dealerallyprogram.com with the subject “File failed to upload to Aqua” and the loan number.',
          type: 'error'
        })
        
      }

  }).catch(error => {
    console.log(error)
    setIsLoadingDetails(false);
    SetShowNotificationTypeDocumentGeneralError(true)
    setMessageDocumentTypeError('An unexpected error has occurred, please try again')
    SetDocumentForm({});
    delete documentForm.DocumentFileType;
    delete documentForm.File;
  })
}

useEffect(() => {
  if (Object.keys(errorsdocument).length === 0 && isSubmittingDocument) {
    sendDocument();
  } 
}, [errorsdocument]);









































  /*----------------------------------------- HANDLE INPUTS APPLICANT COAPPLICANT LOAN------------------------------------- */

  //HANDLE INPUTS
    //inputs applicant
    const handleInputApplicant = event => {
      let value = event.target.value;

     

        if (event.target.name === "EmploymentType") {
          if(value === "Unemployed" || value === "Retired"){
            delete applicant.CurrentEmployer;
            delete applicant.CurrentEmployerPhoneNumber;
            delete applicant.EmploymentMonth;
            delete applicant.EmploymentYear;
            delete applicant.Occupation;
            delete applicant.HireDate;
            delete applicant.GrossMonthlyIncome
          }else if(value === 'SelfEmployed'){
            delete applicant.CurrentEmployer;
          }
          value = value
        }










        // if (event.target.name === "IdentificationType") {
        //     if(value === "Unavailable" || value === 'StateId'){           
        //       delete applicant.IdentificationNumber;
        //       delete applicant.IsIssueDatePresent;
        //       delete applicant.IsIssueStatePresent;
        //       delete applicant.IdentificationIssueState;
        //       delete applicant.IdentificationIssuedDate;
        //       delete applicant.IdentificationExpirationDate; 
        //       delete applicant.ExpirationDateUnavailable;         
        //     }
            
        // }

    



        


        // if (event.target.name === "ResidenceMonthlyPayment") {

        //   const formatter = new Intl.NumberFormat();
        
       

        //   value = formatter.format(value);
          
        //   // value = parseFloat(value).toFixed(2)
        // }



        if (event.target.name === "IsIssueDatePresent") {
          if(value === "false"){
            delete applicant.IdentificationIssuedDate;          
          }
          value = value
        }


        if (event.target.name === "IsIssueStatePresent") {
          if(value === "false"){
            delete applicant.IdentificationIssueState;    
          }
          value = value
        }


        if (event.target.name === "ExpirationDateUnavailable") {
          if(value === "false"){
            delete applicant.IdentificationExpirationDate;   
          }
          value = value
        }

        


        setApplicant({
          ...applicant,
          [event.target.name]: value,                
        })

       
    }



    //CAMBIO INDENTIFICATION TYPE applicant
    // useEffect(() => {

    //   if(copyIdentificationTypeValue !== applicant.IdentificationType){

    //       if(props.type === 'create'){
    //         setApplicant({
    //           ...applicant,
    //             'IdentificationNumber' : '',
    //             'IsIssueDatePresent' : 'disable',
    //             'IsIssueStatePresent' : 'disable',
    //             'IdentificationIssueState' : '',
    //             'IdentificationIssuedDate' : '',
    //             'IdentificationExpirationDate' : '',
    //             'ExpirationDateUnavailable' : 'disable',              
    //         })
    //       }

    //   }

    // }, [applicant.IdentificationType])

   

    
  

    //inputs coapplicant
    const handleInputCoApplicant = event => {
    let value = event.target.value;

        if (event.target.name === "EmploymentType") {
          if(value === "Unemployed" || value === "Retired"){
            
            delete coApplicant.CurrentEmployer;
            delete coApplicant.CurrentEmployerPhoneNumber;
            delete coApplicant.EmploymentMonth;
            delete coApplicant.EmploymentYear;
            delete coApplicant.Occupation;
            delete coApplicant.HireDate;
            delete coApplicant.GrossMonthlyIncome;
          }else if(value === 'SelfEmployed'){
            delete coApplicant.CurrentEmployer;
          }
          value = value

        }

        // if (event.target.name === "IdentificationType") {
        //   if(value === "Unavailable"){
            
        //     delete coApplicant.IdentificationNumber;
        //     delete coApplicant.IsIssueDatePresent;
        //     delete coApplicant.IsIssueStatePresent;
        //     delete coApplicant.IdentificationIssueState;
        //     delete coApplicant.IdentificationIssuedDate;
        //     delete coApplicant.IdentificationExpirationDate;
        //     delete coApplicant.ExpirationDateUnavailable; 
        //   }

        // }






        if (event.target.name === "IsIssueDatePresent") {
          if(value === "false"){
            delete coApplicant.IdentificationIssuedDate;          
          }
          value = value
        }


        if (event.target.name === "IsIssueStatePresent") {
          if(value === "false"){
            delete coApplicant.IdentificationIssueState;    
          }
          value = value
        }

        if (event.target.name === "ExpirationDateUnavailable") {
          if(value === "false"){
            delete coApplicant.IdentificationExpirationDate;   
          }
          value = value
        }

        // if(event.target.name === 'HireDate'){
          
        //   const now = moment(new Date());
        //   const end = moment(value);
        //   const duration = moment.duration(now.diff(end));
        //   console.log(duration.years())
        //   console.log(duration.months())

        //   coApplicant.EmploymentMonth = duration.months();
        //   coApplicant.EmploymentYear = duration.years();

          
        // }


      setCoApplicant({
          ...coApplicant,
          [event.target.name]: value,            
      })
    }


     //CAMBIO INDENTIFICATION TYPE coapplicant
    //  useEffect(() => {

    //   if(copyIdentificationTypeValue !== coApplicant.IdentificationType){
    //     if(props.type === 'create'){
    //       setCoApplicant({
    //         ...coApplicant,
    //           'IdentificationNumber' : '',
    //           'IsIssueDatePresent' : 'disable',
    //           'IsIssueStatePresent' : 'disable',
    //           'IdentificationIssueState' : '',
    //           'IdentificationIssuedDate' : '',
    //           'IdentificationExpirationDate' : '',
    //           'ExpirationDateUnavailable' : 'disable',              
    //       })
    //     }
    //   }
      
      
  
  
    //   }, [coApplicant.IdentificationType])





    //inputs loan
    const handleInputLoan = event => {
      let value = event.target.value;

      // if (event.target.name === "SalesTaxIncluded") {
      //   if(value === "true"){
      //     setIncludeTax(true);
         
          
          
      //     //setChangeStateIncludeTax(!changeStateIncludeTax);
      //     setChangeInputTax(!changeInputTax);
      //     value = value
      //   }else if(value === "false"){
          
      //     setIncludeTax(false);
      //     //setChangeStateIncludeTax(!changeStateIncludeTax);
      //     setChangeInputTax(!changeInputTax)
      //     value = value

      //   }
      // }else 
      if(event.target.name === "SellingPrice") {
        setChangeInputTax(!changeInputTax)
        value = value
      }else if(event.target.name === "DownPayment") {
        setChangeInputTax(!changeInputTax)
        value = value
      }
      // else if(event.target.name === "SalesTax") {
      //   setChangeInputTax(!changeInputTax)
      //   value = value
      // }

    setLoan({
        ...loan,
        [event.target.name]: value,            
    })

 
    }

    //input select coapplicant
    const handleInputHasCoApplicant = event => {

      if(event.target.value === "true"){
        setHasCoapplicant(true)
      }else if(event.target.value === "false"){
        setHasCoapplicant(false)
      }

      setHasCoapplicantValue({
        ...hasCoApplicantValue,
        [event.target.name]: event.target.value         
    })

    }

  


    //calcular el tax
   useEffect(() => {       

    if(includeTax === false){
      setLoan({
        ...loan,
        // SalesTax: parseFloat(0.00),            
    }) 

    }

  if (loan.hasOwnProperty('SellingPrice') && loan.hasOwnProperty('DownPayment') ) {  
    
    let salesTax;
    if(includeTax === false){
      salesTax =parseFloat(0.00);
    }else{
      salesTax = parseFloat(loan.SalesTax);
    }
 

    const sellingPrice = parseFloat(loan.SellingPrice)
    const downPayment = parseFloat(loan.DownPayment)
    const amountFinanced = ((sellingPrice - downPayment) + salesTax)


    
    setLoan({
      ...loan,
      // SalesTax: salesTax,  
      AmountFinanced: amountFinanced.toFixed(2),  
    })
    
  }

    }, [changeInputTax, changeStateIncludeTax, loan.SellingPrice, loan.DownPayment]);



    
    //LLENAR LOAN INFO QUE VIENE DESPUES DE CREAR PROPOSAL
    useEffect(()=>{
        if(proposalInfo.hasInfo){
          //console.log(proposalInfo)
            setLoan({
                ...loan,
                SellingPrice: parseFloat(proposalInfo.info.TotalInstalledProduct).toFixed(2),
                DownPayment: parseFloat(proposalInfo.info.DownPayment).toFixed(2),
                // SalesTaxIncluded : parseFloat(proposalInfo.info.SalesTax) > 0 ? "true" : loan.SalesTaxIncluded,
                // SalesTax: parseFloat(proposalInfo.info.SalesTax) > 0 ? parseFloat(proposalInfo.info.SalesTax).toFixed(2) : loan.SalesTax,
            })

            // if(parseFloat(proposalInfo.info.SalesTax) > 0){
            //   setIncludeTax(true)
            // }

        }
    },[proposalInfo])




    //hascoapplicant value
    useEffect(() => {
      if(hasCoApplicant === true){
        setApplicant({
          ...applicant,
          HasCoApplicant: true,            
      })

      }else if(hasCoApplicant === false){
        setApplicant({
          ...applicant,
          HasCoApplicant: false,            
      })
      }
      }, [hasCoApplicant]);


      
    //set CoApplicantType
    const setCoApplicantType =()=>{
      setCoApplicant({
        ...coApplicant,
        CoApplicantType: coApplicant.ApplicantRelationship,            
      })

      
    }

  
     /*--------------------------------------------------------------------------------------------------------------------- */ 

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

     /*----------------------------------------- FORMULARIOS Y VALIDACION DE DOCUMENTS ------------------------------------- */
    //MANEJADORES DE FORM DE DOCUMENTS
    const handleDocumentForm = event => {
      let value;
      let fileType;

      if(event.target.name === 'File'){

        value = event.target.files[0];
        fileType = value.type;

        if(fileType.includes('image/') || fileType.includes('application/pdf')  || fileType.includes('audio/')){
          if(fileType.includes('application/pdf')){
            if(value.size > 8388608){
              SetShowNotificationTypeDocumentError(true)
              setMessageDocumentTypeError('Info: You can only upload files up to 8MB')
              value = null
            }        
          }else if(fileType.includes('image/') ){
            if(value.size > 8388608 ){
              SetShowNotificationTypeDocumentError(true)
              setMessageDocumentTypeError('Info: You can only upload files up to 8MB')
              value = null
            }  
            
          }
        }else{
            SetShowNotificationTypeDocumentError(true)
            setMessageDocumentTypeError('Info: You can only upload PDF, Audio and Image files')
            value = null
        }
       


        
        
      }else if(event.target.name === 'DocumentFileType'){
        value = event.target.value;
      }
    
      SetDocumentForm({
        ...documentForm,
        [event.target.name]: value,       
    })
    }

    //submit documents
    const handleSubmitDocument = event => {
      event.preventDefault();
      //handle errors
      setErrorsDocument(validateFormDocument(documentForm));
      setIsSubmittingDocument(true);
      //console.log(documentForm)
      
    };

    //validar el formulario de documentos
    const validateFormDocument = (data) => {
      let errorListDocument = {};
  
      //dealerid - check
      if (!data.DocumentFileType) {
        errorListDocument.DocumentFileType = "Please select a option";
      }

      if (!data.File) {
        errorListDocument.File = "Please select a file";
      }
      return errorListDocument;
      };
    /*--------------------------------------------------------------------------------------------------------------------- */  

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    /*----------------------------------------- VALIDAR FORMULARIOS ------------------------------------- */

    //validate applicand and coapplicant
    const validateForm = (applicant, isCoApplicant) => {
      let errorList = {};
  
      
      //IdentificationType - select
      if (!applicant.IdentificationType) {
        errorList.IdentificationType = "Please select an option";
      } else if (applicant.IdentificationType === "Identification Type") {
        errorList.IdentificationType = "Please select an option";
      }
  
      //IdentificationNumber - text
      if(applicant.IdentificationType === "Unavailable" ){
        //console.log('ok')
      }else{
        if (!applicant.IdentificationNumber) {
          errorList.IdentificationNumber = "Please enter an identification number";
        }else if(applicant.IdentificationNumber && applicant.IdentificationNumber.length > 20){
          errorList.IdentificationNumber = "The identification number must be less than 20 characters";
        }else if (!/^[a-zA-Z0-9,-\s]*$/.test(applicant.IdentificationNumber)) {
          errorList.IdentificationNumber = "The value can only contain numbers, letters, spaces, or dashes";
      }

      }
        
      
      
      if(applicant.IdentificationType === "Unavailable" || applicant.IsIssueDatePresent === 'false' || applicant.IsIssueDatePresent === false){
        //console.log('ok')
      }else{
        //IdentificationIssuedDate - date
      if (!applicant.IdentificationIssuedDate) {
        errorList.IdentificationIssuedDate = "Please enter a date";
      }else if(!applicant.IdentificationIssuedDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
        if(isSafari){
          errorList.IdentificationIssuedDate = "The format must be yyyy-mm-dd";
        }else{
          errorList.IdentificationIssuedDate = "The format must be dd/mm/yyyy";
        }     
      } else if(applicant.IdentificationIssuedDate){
        let dob2 = new Date (applicant.IdentificationIssuedDate);
        const nowDate = new Date();
        if(nowDate < dob2){
          errorList.IdentificationIssuedDate = "The date must be in the past";
        }
      }
      }


      
      
      //IdentificationExpirationDate - date
      if(applicant.IdentificationType === "Unavailable" || applicant.ExpirationDateUnavailable === 'true'){
        
      }else{   
          if (!applicant.IdentificationExpirationDate) {
            errorList.IdentificationExpirationDate = "Please enter a date";
          }else if(!applicant.IdentificationExpirationDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
            if(isSafari){
              errorList.IdentificationExpirationDate = "The format must be yyyy-mm-dd";
            }else{
              errorList.IdentificationExpirationDate = "The format must be dd/mm/yyyy";
            }     
          } else if(applicant.IdentificationExpirationDate){
            let dob3 = new Date (applicant.IdentificationExpirationDate);
            const nowDate = new Date();
            if(nowDate > dob3){
              errorList.IdentificationExpirationDate = "The date must be in the future";
            }
          }
      }





       //IsIssueStatePresent
       if(applicant.IdentificationType === "Unavailable" || applicant.IdentificationType === "UnitedStatesPassport" || applicant.IdentificationType === "PermanentResidencyCard" || applicant.IdentificationType === "UnitedStatesMilitaryId"){
        //console.log('ok')
      }else{
        if(props.application_id){
          if ((!applicant.IsIssueStatePresent && !applicant.IsIssueStatePresent === false) || applicant.IsIssueStatePresent === 'disable') {
            errorList.IsIssueStatePresent = "Please select an option";
          }
        }else if(applicant.IsIssueStatePresent === null || applicant.IsIssueStatePresent === undefined || applicant.IsIssueStatePresent === 'disable'){
            errorList.IsIssueStatePresent = "Please select an option";
        }
        

      }
    

      
      
      

      if(applicant.IdentificationType === "Unavailable" ||  applicant.IdentificationType === "UnitedStatesPassport" ||  applicant.IdentificationType === "PermanentResidencyCard" ||  applicant.IdentificationType === "UnitedStatesMilitaryId" || applicant.IsIssueStatePresent === 'false' || applicant.IsIssueStatePresent === false){
        //console.log('ok')
      }else{
         //IdentificationIssueState - select
      
         if (!applicant.IdentificationIssueState) {
          errorList.IdentificationIssueState = "Please select an option";
        } else if (
          applicant.IdentificationIssueState === "Identification Issue State"
        ) {
          errorList.IdentificationIssueState = "Please select an option";
        }


      }
     
      
  
      //EmploymentType - select
      if (!applicant.EmploymentType) {
        errorList.EmploymentType = "Please select an option";
      } else if (applicant.EmploymentType === "Employment Type") {
        errorList.EmploymentType = "Please select an option";
      }
  

      //CurrentEmployer - text
      if(applicant.EmploymentType === "Unemployed" || applicant.EmploymentType === "Retired" || applicant.EmploymentType === "SelfEmployed"){
        //console.log('ok')
      }else{
          if (!applicant.CurrentEmployer) {
            errorList.CurrentEmployer = "Please add your current job";
          }else if(applicant.CurrentEmployer.length < 3){
            errorList.CurrentEmployer = "Employer name must be at least 3 characters long";
          }
      }
  


      //EmploymentYear - date
      if(applicant.EmploymentType === "Unemployed" || applicant.EmploymentType === "Retired"){
        //console.log('ok')
      }else{
          if (!applicant.HireDate) {
            errorList.HireDate = "Please enter a date";
          }else if(!applicant.HireDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
            if(isSafari){
              errorList.HireDate = "The format must be yyyy-mm-dd";
            }else{
              errorList.HireDate = "The format must be dd/mm/yyyy";
            }     
          } else if(applicant.HireDate){
            let dob2 = new Date (applicant.HireDate);
            const nowDate = new Date();
            if(nowDate < dob2){
              errorList.HireDate = "The date must be in the past";
            }
          }
      }
  
      //Occupation - text
      if(applicant.EmploymentType === "Unemployed" || applicant.EmploymentType === "Retired"){
       // console.log('ok')
      }else{
        if (!applicant.Occupation) {
          errorList.Occupation = "Please add your occupation";
        }
      }
  
      //GrossMonthlyIncome - number
      if(applicant.EmploymentType === "Unemployed" || applicant.EmploymentType === "Retired"){
       // console.log('ok')
      }else{
        if (!applicant.GrossMonthlyIncome) {
          errorList.GrossMonthlyIncome = "Please enter the value of your income";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.GrossMonthlyIncome)) {
          errorList.GrossMonthlyIncome = "The value can only contain numbers";
        }else if (isCoApplicant && applicant.GrossMonthlyIncome == 0) {
          errorList.GrossMonthlyIncome = "The value must be greater than 0";
        }
      }
      
  
      //ResidenceType - select
      if (!applicant.ResidenceType) {
        errorList.ResidenceType = "Please select an option";
      } else if (applicant.ResidenceType === "Residence Type") {
        errorList.EmploymentType = "Please select an option";
      }

      //CoApplicantType - select
      if(hasCoApplicant && isCoApplicant){
        if (!applicant.CoApplicantType) {
          errorList.CoApplicantType = "Please select an option";
        } 
      }
      
  
      //ResidenceMonthlyPayment - number
      if(applicant.ResidenceType !== 'OwnedFreeAndClear'){
        if (!applicant.ResidenceMonthlyPayment && !applicant.ResidenceMonthlyPayment === 0) {
          errorList.ResidenceMonthlyPayment = "Please enter a value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.ResidenceMonthlyPayment)) {
          errorList.ResidenceMonthlyPayment = "The value can only contain numbers";
        }
      }
      /*
      //HasCoApplicant - check
      if (!applicant.HasCoApplicant) {
        errorList.HasCoApplicant = "Please select an option";
      }
      */
  
      //FirstName - text
      if (!applicant.FirstName) {
        errorList.FirstName = "Please enter a name";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.FirstName)) {
        errorList.FirstName = "The name can only contain letters";
      }


      //MiddleInitial
      if (applicant.MiddleInitial) {
        if(!/^[a-zA-Z]{1}$/.test(applicant.MiddleInitial)) {
          errorList.MiddleInitial = "Can only contain one letter";
        }
      }
  


      //LastName - text
      if (!applicant.LastName) {
        errorList.LastName = "Please enter a lastname";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.LastName)) {
        errorList.LastName = "The lastname can only contain letters";
      }
  
      //Address1 - text
      if (!applicant.Address1) {
        errorList.Address1 = "Please enter a address";
      }
  
      //City - text
      if (!applicant.City) {
        errorList.City = "Please enter a city";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s\.]+$/.test(applicant.City)) {
        errorList.City = "The city can only contain letters";
      }
  
      //State - select
      if (!applicant.State) {
        errorList.State = "Please select an option";
      } else if (applicant.State === "State") {
        errorList.State = "Please select an option";
      }
  
      //PostalCode - number
      if (!applicant.PostalCode) {
        errorList.PostalCode = "Please enter a postalCode";
      } else if (!/^[0-9]{5}$/.test(applicant.PostalCode)) {
        errorList.PostalCode = "The postal code must have 5 numbers";
      } 
  
      //PrimaryPhone - number
      // if (!applicant.PrimaryPhone) {
      //   errorList.PrimaryPhone = "Please enter a primary phone";
      // } else if (!/^[0-9]{10}$/.test(applicant.PrimaryPhone)) {
      //   errorList.PrimaryPhone = "The primary phone must have 10 numbers";
      // } else if(applicant.PrimaryPhone === '0000000000' ){
      //   errorList.PrimaryPhone = "Please enter a valid phone number";
      // }
  
      // //PrimaryPhoneType - select
      // if (!applicant.PrimaryPhoneType) {
      //   errorList.PrimaryPhoneType = "Please select an option";
      // } else if (applicant.PrimaryPhoneType === "Primary Phone Type") {
      //   errorList.PrimaryPhoneType = "Please select an option";
      // }
  
      //SocialSecurityNumber - number
      if (!applicant.SocialSecurityNumber) {
        errorList.SocialSecurityNumber = "Please enter a Social Security Number";
      }else if (!/^[0-9]{9}$/.test(applicant.SocialSecurityNumber)) {
        errorList.SocialSecurityNumber =
          "The social security number must have 9 numbers";
      }

      //HomeValue - number
      if(applicant.ResidenceType !== 'Rent'){
        if (!applicant.HomeValue) {
          errorList.HomeValue = "Please enter a value";
        }else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.HomeValue)) {
          errorList.HomeValue =
            "The Home Value number must have 9 numbers";
        }else if(applicant.HomeValue <= 0){
          errorList.HomeValue ="Value must be greater than 0";
      }
      }
     



      //DateOfBirth - date
      if (!applicant.DateOfBirth) {
        errorList.DateOfBirth = "Please enter a date";
      }else if(!applicant.DateOfBirth.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
        if(isSafari){
          errorList.DateOfBirth = "The format must be yyyy-mm-dd";
        }else{
          errorList.DateOfBirth = "The format must be dd/mm/yyyy";
        }     
      } else if(applicant.DateOfBirth){
        let dob = new Date (applicant.DateOfBirth);
        const nowDate = new Date();
        nowDate.setFullYear(nowDate.getFullYear() - 18);
          if(dob > nowDate){
            errorList.DateOfBirth = "Must be over 18 years old";
          }
      } 


      if(!applicant.EmailAddress){
        errorList.EmailAddress = "Please enter a email";
      }else if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.EmailAddress)){
          errorList.EmailAddress = "Please enter a valid email";
        }

      
      //IsIssueDatePresent
      if(applicant.IdentificationType === "Unavailable" || applicant.IdentificationType === "PermanentResidencyCard" ){
        //console.log('ok')
      }else{
        if(props.application_id){
          if ((!applicant.IsIssueDatePresent && !applicant.IsIssueDatePresent === false) || applicant.IsIssueDatePresent === 'disable') {
            errorList.IsIssueDatePresent = "Please select an option";
          }
        }else if(applicant.IsIssueDatePresent === null || applicant.IsIssueDatePresent === undefined || applicant.IsIssueDatePresent === 'disable'){
          errorList.IsIssueDatePresent = "Please select an option";
        }

      }
      
     


      //ExpirationDateUnavailable
      if(applicant.IdentificationType === "Unavailable" || applicant.IdentificationType === "DriversLicense"  || applicant.IdentificationType === "UnitedStatesPassport" || applicant.IdentificationType === "PermanentResidencyCard"){
        //console.log('ok')
      }else{
        if(props.application_id){
          if ((!applicant.ExpirationDateUnavailable && !applicant.ExpirationDateUnavailable === false) || applicant.ExpirationDateUnavailable === 'disable') {
            errorList.ExpirationDateUnavailable = "Please select an option";
          }
        }else if(applicant.ExpirationDateUnavailable === null || applicant.ExpirationDateUnavailable === undefined || applicant.ExpirationDateUnavailable === 'disable'){
            errorList.ExpirationDateUnavailable = "Please select an option";
        }
      }



    
      


      // if (!applicant.MiddleInitial) {
      //   errorList.MiddleInitial = "Please enter a initial";
      // } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]{1}$/.test(applicant.MiddleInitial)) {
      //   errorList.MiddleInitial = "Please enter only one letter";
      // }



      // if (applicant.SecondaryPhone) {
      //   if (!applicant.SecondaryPhoneType) {
      //     errorList.SecondaryPhoneType = "Please select an option";
      //   }else if(applicant.SecondaryPhoneType === applicant.PrimaryPhoneType){
      //     errorList.SecondaryPhoneType = "Please enter a secondary phone type which is different from primary phone type.";
      //   }

      // }

      // if(applicant.SecondaryPhoneType === applicant.PrimaryPhoneType){
      //   errorList.SecondaryPhoneType = "Please enter a secondary phone type which is different from primary phone type.";
      // }

      // if (applicant.SecondaryPhoneType) {
      //   if (!applicant.SecondaryPhone) {
      //     errorList.SecondaryPhone = "Please enter a number";
      //   }else if (!/^[0-9]{10}$/.test(applicant.SecondaryPhone)) {
      //     errorList.SecondaryPhone = "The secondary phone must have 10 numbers";
      //   }else if(applicant.SecondaryPhone === '0000000000' ){
      //     errorList.SecondaryPhone = "Please enter a valid phone number";
      //   }

      // }


      //CellPhone - number
      if (!applicant.CellPhone) {
        errorList.CellPhone = "Please enter a primary phone";
      } else if (!/^[0-9]{10}$/.test(applicant.CellPhone)) {
          errorList.CellPhone = "The CellPhone must have 10 numbers";
      }else if(applicant.CellPhone === '0000000000' ){
          errorList.CellPhone = "Please enter a valid phone number";
      }

      if(applicant.BusinessPhone){
        if (applicant.BusinessPhone === applicant.CellPhone) {
            errorList.BusinessPhone = "Phone numbers must be different";
        }else if (!/^[0-9]{10}$/.test(applicant.BusinessPhone)) {
            errorList.BusinessPhone = "The Business Phone must have 10 numbers";
        } else if (applicant.BusinessPhone === '0000000000') {
            errorList.BusinessPhone = "Please enter a valid phone number";
        }
      }

    

     
  
      return errorList;
    };


    //validate loan
    const validateFormLoan = loan => {
      let errorList = {};
  

     

      //RePaymentTerms  select
      if(loan.CreditType === "Resolving"){
        console.log('ok')
      }else if(loan.CreditType === "ClosedEnd"){
        if (!loan.RepaymentTerms) {
          errorList.RepaymentTerms = "Please select an option";
        } else if (loan.RepaymentTerms === "Repayment Terms") {
          errorList.RepaymentTerms = "Please select an option";
        }
      }


      //salesTaxincluded - select
      // if (!loan.SalesTaxIncluded && !loan.SalesTaxIncluded === false) {
      //   errorList.SalesTaxIncluded = "Please select an option";
      // } else if (loan.PaymentTerm === "Sales Tax Included") {
      //   errorList.SalesTaxIncluded = "Please select an option";
      // }

      //salesTax - select

      // if(loan.SalesTaxIncluded === 'true'){
      //   if (!loan.SalesTax) {
      //     errorList.SalesTax = "Please enter a value";
      //   } else if (!/^[0-9]+\.?[0-9]*$/.test(loan.SalesTax)) {
      //     errorList.SalesTax = "The value can only contain numbers";
      //   } else if(loan.SalesTax <= 0){
      //     console.log('debe ser mayor a 0')
      //     errorList.SalesTax = "The value must be greater than 0";
      //   }
      // }
      
      
  
      //ContractState - select
      if (!loan.ContractState) {
        errorList.ContractState = "Please select an option";
      } else if (loan.ContractState === "Contract State") {
        errorList.ContractState = "Please select an option";
      }
  
      //ContractLanguage - select
      if (!loan.ContractLanguage) {
        errorList.ContractLanguage = "Please select an option";
      } else if (loan.ContractLanguage === "Contract Language") {
        errorList.ContractLanguage = "Please select an option";
      }
  
      //ProductOrService - select
      if (!loan.ProductOrService) {
        errorList.ProductOrService = "Please select an option";
      } else if (loan.ProductOrService === "Product Or Service") {
        errorList.ProductOrService = "Please select an option";
      }
  
      //SellingPrice - number
      if (!loan.SellingPrice) {
        errorList.SellingPrice = "Please enter a value";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(loan.SellingPrice)) {
        errorList.SellingPrice = "The value can only contain numbers";
      }
  
      //DownPayment - number / si es igual a 0 no se envia
      if (!loan.DownPayment && loan.DownPayment !== 0 && loan.DownPayment !== '0') {
        errorList.DownPayment = "Please enter a value";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(loan.DownPayment)) {
        errorList.DownPayment = "The value can only contain numbers";
      } 
  
      //AmountFinanced - number
      if (loan.AmountFinanced < 1000) {
        errorList.AmountFinanced = "Minimum Amount Finances is $1000";
      }else if(applicant.ResidenceType === 'Rent'){
        if (loan.AmountFinanced > 5000) {
          errorList.AmountFinanced = "AmountFinanced must be less than or equal to $5000";
        }
      }
      
      

      


      
  
 

      if (!loan.CompletionDate) {
        errorList.CompletionDate = "Please select a date";
      }else if(!loan.CompletionDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
        if(isSafari){
          errorList.CompletionDate = "The format must be yyyy-mm-dd";
        }else{
          errorList.CompletionDate = "The format must be dd/mm/yyyy";
        }     
      }else if(loan.CompletionDate){
        // let dob3 = new Date (loan.CompletionDate);
        // const nowDate = new Date();
        // if(nowDate > dob3){
        //   errorList.CompletionDate = "The date must be in the future";
        // }

        const localTime = new Date().getTime()
        const completionDate = moment.tz(loan.CompletionDate + " 00:00", "America/Chicago") 
        const today00 = moment(localTime).tz('America/Chicago').set({hour:0,minute:0,second:0,millisecond:0})


        if(completionDate.isBefore(today00)){
          errorList.CompletionDate = "The date must be today or in the future";
        }
      }

    
    
  
      return errorList;
    };
    /*------------------------------------------------------------------------------------------------- */
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

   /*----------------------------------------- SUBMIT FORMULARIOS------------------------------------- */
    //submit applicant
    const handleSubmitApplicant = event => {
      event.preventDefault();
      //handle errors
      setErrors(validateForm(applicant, false));
      setErrorIncomes(validateIncome(incomeList))
      setIsSubmitting(true);
      setIsSubmitingIncomeApplicant(!isSubmitiongIncomeApplicant)
    };

    //submit coapplicant
    const handleSubmitCoApplicant = event => {
      event.preventDefault();
      //handle errors
      setErrorsCoApplicant(validateForm(coApplicant, true));
      setErrorIncomesCoApplicant(validateIncome(incomeListCoApplicant));
      setIsSubmittingCoApplicant(true);
      setIsSubmitingIncomeCoApplicant(!isSubmitiongIncomeCoApplicant)
    };

    //submit loan
    const handleSubmitLoan = event => {
      event.preventDefault();
   
      //añadir valores quemados al formulario de loan

      setLoan({

        ...loan,
        PaymentFactor: 'TwoPointFive', 
        CreditType: 'Revolving',           
      })

      if(termsCheck === false) {
        setTermsCheckBorderRed(true)
      }


      setErrorsLoan(validateFormLoan(loan));
      setIsSubmittingLoan(true);
    };
  /*------------------------------------------------------------------------------------------------- */

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 


    /*----------------------------------------- USE EFFECTS POS FORMULARIOS------------------------------------- */
    //post applicant
    useEffect(() => {

      let errInc = []
      
      errorIncomes.forEach(error => {
        if( Object.keys(error).length != 0){
          errInc = [ ...errInc, false]
        }
        
      })
      //console.log(errInc)

      if (Object.keys(errors).length === 0 && isSubmitting && errInc.length === 0) {
     
        //changeEmploymentDatesApplicant();
        //console.log(applicant);
        //setCoApplicantType()
        nextStep();
      } else{
        // console.log(errors)
       
      }

      

    }, [errors, isSubmitiongIncomeApplicant]);


    //post coapplicant
    useEffect(() => {

      let errInc = []
      
      errorIncomesCoApplicant.forEach(error => {
        if( Object.keys(error).length != 0){
          errInc = [ ...errInc, false]
        }
      })
     // console.log(errInc)


      if (Object.keys(errorsCoApplicant).length === 0 && isSubmittingCoApplicant && errInc.length === 0) {
        
        //changeEmploymentDatesCoApplicant();
        //console.log(coApplicant);
        nextStep();
      }else{
        // console.log(errorsCoApplicant)
      }
    }, [errorsCoApplicant, isSubmitiongIncomeCoApplicant]);


    //post loan
    useEffect(() => {
      if (Object.keys(errorsLoan).length === 0 && isSubmittingLoan && termsCheck) {
        
        //changeEmploymentDates(applicant);
        //console.log(loan);

        handleSubmitAllData();
        
      }
    }, [errorsLoan]);
   /*------------------------------------------------------------------------------------------------- */
    
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 


   /*----------------------------------------- STEPS Y TABS------------------------------------- */   
  //set step
  
const setStep3=()=>{
  setStep(3);
};
  const setStep4=()=>{
  setStep(4);
};
    const setStep5=()=>{
  setStep(5);
};
    const setStep6=()=>{
    setStep(6);
  };
    const nextStep = () => {
        setStep(step + 1);      
    };
    const prevStep = () => {
        setStep(step - 1);  
    };
    const restar = () => {
        setStep(1);
    };


    //steps for create new application
    const stepsCounter = () => {
      const styletitlemobile1 = ["stepmobiletittle"];
      const styletitlemobile2 = ["stepmobiletittle"];
      const styletitlemobile3 = ["stepmobiletittle"];
      
      const stylenumber1 = ["stepnumber"];
      const styletitle1 = ["steptittle"];
      const stylenumber2 = ["stepnumber"];
      const styletitle2 = ["steptittle"];
      const stylenumber3 = ["stepnumber"];
      const styletitle3 = ["steptittle"];
      
      if (step === 1) {
        stylenumber1.push("stepnumberactive");
        styletitle1.push("steptittleactive");
        styletitlemobile1.push("showstepmobiletittle");
      }
      if (step === 2) {
        stylenumber1.push("stepnumbercomplete");
        stylenumber2.push("stepnumberactive");
        styletitle2.push("steptittleactive");
        styletitlemobile2.push("showstepmobiletittle");
      }
      if (step === 3) {
        stylenumber1.push("stepnumbercomplete");
        stylenumber2.push("stepnumbercomplete");
        stylenumber3.push("stepnumberactive");
        styletitle3.push("steptittleactive");
        styletitlemobile3.push("showstepmobiletittle");
      }
      
  
      return (
        <div className="stepscontainer">
          <div className="stepsmobiletittlecontainer">
          
            
            <h3 className={styletitlemobile1.join(" ")}>Applicant Information</h3>
            <h3 className={styletitlemobile2.join(" ")}>CoApplicant Information</h3>
            <h3 className={styletitlemobile3.join(" ")}>Loan Parameters</h3>
            
          </div>


          <div className="stepsdesktopcontainer">
            {step === 1 ? null : <img src={iconArrowBack } onClick={prevStep} className="backButton"/>}
            
            <div className="step">
              <span className={stylenumber1.join(" ")}>1</span>
              <h3 className={styletitle1.join(" ")}>Applicant Information</h3>
            </div>
            <div className="step">
              <span className={stylenumber2.join(" ")}>2</span>
              <h3 className={styletitle2.join(" ")}>
                CoApplicant Information
              </h3>
            </div>
            <div className="step">
              <span className={stylenumber3.join(" ")}>3</span>
              <h3 className={styletitle3.join(" ")}>Loan Parameters</h3>
            </div>
           
          </div>
        </div>
      );
    };


    //tabs for detail application
    const tabs = () => {
        
      return (
        
        <div className="applicationTabsContainer">
          <ul className="applicationTabs">
            <li className={step===4 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep4}>Status</li>
            {
              props.errorStatus ? 
              <>
               <li className={step===1 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(1) }>Applicant</li>
                <li className={step===2 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(2) }>CoApplicant</li>
              </>
              :
              <>
              <li className={step===7 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(7) }>Applicant</li>
              <li className={step===8 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(8) }>CoApplicant</li>
              </>
            }
           
            <li className={step===3 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep3}>Parameters</li>
            {
              canCreateProposal && 
              <li className={step===9 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(9)}>Proposal</li>
            }
            
            
            <li className={step===5 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep5}>Contract</li>
            <li className={step===6 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep6}>Documents</li>
            
          </ul>
        </div>
        
      );
    
    
    }
    /*------------------------------------------------------------------------------------------------- */

    // 
    // 
    // 
    // 

    const copyAddressData = () =>{
  

      if(copyAddresDataFromApplicant === false){
        setCoApplicant({
          ...coApplicant,
          Address1: applicant.Address1,
          City: applicant.City,
          State: applicant.State,
          PostalCode: applicant.PostalCode,   
           
      })
      }else{
        setCoApplicant({
          ...coApplicant,
          Address1: null,
          City: null,
          State: null,
          PostalCode: null,   
          
      })
      }
  
      setCopyAddressDataFromApplicant(!copyAddresDataFromApplicant)
      }



      const copyResidenceData = () =>{
  

        if(copyResidenceDataFromApplicant === false){

            // const obj = { ResidenceType: applicant.ResidenceType}

            // if(applicant.ResidenceType !== 'Rent'){
            //   obj.HomeValue = applicant.HomeValue
            // }
            // if(applicant.ResidenceType !== 'OwnedFreeAndClear'){
            //   obj.ResidenceMonthlyPayment = applicant.ResidenceMonthlyPayment
            // }

            setCoApplicant({
            ...coApplicant,
            HomeValue: applicant.HomeValue,
            ResidenceType: applicant.ResidenceType,
            ResidenceMonthlyPayment: applicant.ResidenceMonthlyPayment,   
                
        })
        }else{
            setCoApplicant({
            ...coApplicant,
            HomeValue: null,
            ResidenceType: null,
            ResidenceMonthlyPayment: null,   
            
        })
        }
    
        setCopyResidenceDataFromApplicant(!copyResidenceDataFromApplicant)
        }


    // 
    // 
    // 
    // 
    // 
    // 
    // 

    // if(application.Status == "Approved" || (application.SigningContractStatus != null && (application.Status == "Received" || application.Status == "PendingDocs"))){
      
    // }

    /*----------------------------------------- INCOMES------------------------------------- */ 
      const addIncome=()=>{

        setErrorIncomes([...errorIncomes, {}])
          
          setIncomeList([
            ...incomeList,
            {
              Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`,
              
            }
          ]) 
          //console.log(incomeList)
          
        } 

      const addIncomeCoApplicant=()=>{
        setErrorIncomesCoApplicant([...errorIncomesCoApplicant, {}])
        
        setIncomeListCoApplicant([
          ...incomeListCoApplicant,
          {
            Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`,
            //errors : [{}]
          }
        ]) 
        //console.log(incomeListCoApplicant)
        
      }

      
      const handleIncomeInput=(event, id)=>{

            const newList = incomeList.map(income =>{
              
              if(income.Id === id){
                if(event.target.name === 'AdditionalIncomeSource'){
                    income.AdditionalIncomeSource = event.target.value
                  }else if(event.target.name === 'Description'){
                    income.Description = event.target.value
                  }else if(event.target.name === 'Amount'){
                    income.Amount = event.target.value
                  }else if(event.target.name === 'Source'){
                    income.Source = event.target.value
                  }
                

                return income

              }else{
                return income
              }


          })
            
          setIncomeList(newList)
              
          //console.log(incomeList)
            
          
      }


      const maskIncomeInput = (id, applicant) => {
        if(applicant){
            const newList = incomeList.map(income => {
                if (income.Id === id) {
                    income.Amount = parseFloat(income.Amount).toFixed(2) 
                    return income
                } else {
                    return income
                }
            })
            setIncomeList(newList)
        }else{
            const newList = incomeListCoApplicant.map(income => {
                if (income.Id === id) {
                    income.Amount = parseFloat(income.Amount).toFixed(2) 
                    return income
                } else {
                    return income
                }
            })
            setIncomeListCoApplicant(newList)
        }
       
    }


      const handleIncomeInputCoApplicant=(event, id)=>{

        const newList = incomeListCoApplicant.map(income =>{
              
          if(income.Id === id){
            if(event.target.name === 'AdditionalIncomeSource'){
                income.AdditionalIncomeSource = event.target.value
              }else if(event.target.name === 'Description'){
                income.Description = event.target.value
              }else if(event.target.name === 'Amount'){
                income.Amount = event.target.value
              }else if(event.target.name === 'Source'){
                income.Source = event.target.value
              }
            

            return income

          }else{
            return income
          }


      })
        
      setIncomeListCoApplicant(newList)
          
      //console.log(incomeListCoApplicant)
    }


      const deleteIncome=(id, index)=>{

          const newList = incomeList.filter(income => income.Id !== id) 
          setIncomeList(newList);

          const newErrorList = errorIncomes.filter((error, i)=> errorIncomes.indexOf(error) !== index)
          setErrorIncomes(newErrorList)

      } 

      
      const deleteIncomeCoApplicant=(id)=>{
        
          const newList = incomeListCoApplicant.filter(income => income.Id !== id) 
          setIncomeListCoApplicant(newList)
          
      } 
    

      const validateIncome=(incomeList)=>{


        const errorIncomeList = incomeList.map((item)=>{


          let errorList = {};

          //AdditionalIncomeSource - select
          if (!item.AdditionalIncomeSource) {
            errorList.AdditionalIncomeSource = "Please select an option";
          }
    
          //Description - text
          if (!item.Description) {
            errorList.Description = "Please enter a description";
          }

    
          //Amount - number
          if (!item.Amount) {
            errorList.Amount = "Please enter a value";
          } else if (!/^[0-9]+\.?[0-9]*$/.test(item.Amount)) {
            errorList.Amount = "The value can only contain numbers";
          }else if (item.Amount == 0) {
            errorList.Amount = "The value should be greater than 0";
        }
    
          
          if(errorList.lenght !== 0){
            return errorList;
          }
          
          
        })

        //incomeList.errors = errorIncomeList;

        //console.log(errorIncomeList)
        

        
        //return errorIncomeList
        if(errorIncomeList.length === 0){
          //console.log(errorIncomeList)
          const vacio = []
          return vacio
        }else{
          console.log(errorIncomeList)
          return errorIncomeList
        }
        
        
      }

     /*------------------------------------------------------------------------------------------------- */

   
   













  switch (step) {
    default:
      return (
        <div>
          <h1>breack</h1>
          <button onClick={restar} value="Next">
            Next
          </button>
        </div>
      );
    case 1:
      
      return (
        <>
        <div className="tabsMainContainer">

        {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
          <div className="applicationForm-titleFinancialEntityContainer">
            <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p>
            <p className="applicationForm_formtitle">AquaFinance Form</p>
          </div>
          : null
        }

        {props.type ==='create' ? stepsCounter()
        : tabs()
        }

        {isLoadingDetails ? 
        <div className="spinnerDetails"><Spinner
              size={25}
              color={"grey"}
              spinnerWidth={3}
              visible={true}
            /></div> : null}

         


          {
            props.showErrorMessages &&
            <div className="sheetContainer">      
            {
                  hasMessages ?  
                                                      
                        statusMessages.map((item, index) => {
                          return (
                            <div className="cardMessage_error" key={index}>
                              <p className="statusMessage_error">{item.Text}</p>
                            </div>
                          );
                        })
                        
                
                  : 
                    <p className="applicationFormContainer-statusNotMessage">This app has no status messages available.</p>
              }
            </div>
          }





          <div className="applicationFormContainer">
           

            <h3 className="applicationFormContainer-tittle">
              Main Information
            </h3>
            <form action="" id="form" className="form">

              {/* <button onClick={()=>{nextStep()}}>Siguiente</button> */}


              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" > First Name </label>
              <input
                type="text"         
                name="FirstName"
                onChange={handleInputApplicant}
                className={`${errors.FirstName ? "imputError" : "campo"}`}
                value={applicant.FirstName}
                placeholder="First name"
                disabled={(props.type === 'edit' || props.urlParamApplicant_Id  || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                required
              />
              {errors.FirstName && <p className="errorMessage">{errors.FirstName}</p>}
              </div>


              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Middle Initial</label>
              <input
                type="text"   
                name="MiddleInitial"
                onChange={handleInputApplicant}
                className={`${errors.MiddleInitial ? "imputError" : "campo"}`}
                value={applicant.MiddleInitial}
                placeholder="Only one letter"
                disabled={(props.type === 'edit' || props.urlParamApplicant_Id  || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                required
              />
              {errors.MiddleInitial && <p className="errorMessage">{errors.MiddleInitial}</p>}
              </div>



              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Last Name</label>
                <input
                  type="text"
                  
                  name="LastName"
                  onChange={handleInputApplicant}
                  className={`${errors.LastName ? "imputError" : "campo"}`}
                  placeholder="Last Name"
                  value={applicant.LastName}
                  required
                  disabled={(props.type === 'edit' || props.urlParamApplicant_Id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                />
                {errors.LastName && <p className="errorMessage">{errors.LastName}</p>}
              </div>


              <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Social Security Number</label>
                <input
                  value={props.type === 'edit' ? applicant.hasOwnProperty('SocialSecurityNumber') ? ('*****' + applicant.SocialSecurityNumber.toString().substr(5,9)) : '' : applicant.SocialSecurityNumber}
                  type={props.type === 'edit' ? "text" : "number"}
                  name="SocialSecurityNumber"
                  onChange={handleInputApplicant}
                  className={`${errors.SocialSecurityNumber? "imputError" : "campo"}`}
                  placeholder="Do not use dashes"
                  required
                  disabled={((props.type === 'edit' || props.urlParamApplicant_Id || props.appData || proposalInfo.info.HasCreatedApplications) && !allowEditSSN) ? "disabled" : null}
                />
                {errors.SocialSecurityNumber && <p className="errorMessage">{errors.SocialSecurityNumber}</p>}
            </div>



              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Email</label>
                <input
                  type="email"
                  
                  name="EmailAddress"
                  onChange={handleInputApplicant}
                  className={`${errors.EmailAddress ? "imputError" : "campo"}`}
                  placeholder="Email"
                  value={applicant.EmailAddress}
                  required
                  disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                />
                {errors.EmailAddress && <p className="errorMessage">{errors.EmailAddress}</p>}
              </div>



              
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Date of Birth</label>
                  <input
                    type="date"
                    
                    name="DateOfBirth"
                    onChange={handleInputApplicant}
                    className={`${errors.DateOfBirth ? "imputError" : "campo"}`}
                    placeholder="yyyy-mm-dd"
                    required
                    value={applicant.DateOfBirth}
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications)? "disabled" : null}
                  />
                  {errors.DateOfBirth && <p className="errorMessage">{errors.DateOfBirth}</p>}
              </div>

           



              
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Address</label>
                  <input
                    type="text"
                    value={applicant.Address1}

                    name="Address1"
                    onChange={handleInputApplicant}
                    className={`${errors.Address1 ? "imputError" : "campo"}`}
                    placeholder="Address"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.Address1 && <p className="errorMessage">{errors.Address1}</p>}
              </div>
             
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >City</label>
                  <input
                    type="text"
                    value={applicant.City}

                    name="City"
                    onChange={handleInputApplicant}
                    className={`${errors.City ? "imputError" : "campo"}`}
                    placeholder="City"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.City && <p className="errorMessage">{errors.City}</p>}
              </div>

              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >State</label>
              <select
                value={applicant.State}
                name="State"
                onChange={handleInputApplicant}
                className={`${errors.State ? "imputError" : "campo"}`}
                placeholder="State"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              >
                <option value="selected" disabled selected>
                  State
                </option>
                <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
              </select>
              {errors.State && <p className="errorMessage">{errors.State}</p>}
              </div>
              
              
              
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Postal Code</label>
                  <input
                    type="number"
                    value={applicant.PostalCode}

                    name="PostalCode"
                    onChange={handleInputApplicant}
                    className={`${errors.PostalCode ? "imputError" : "campo"}`}
                    placeholder="Postal Code"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.PostalCode && <p className="errorMessage">{errors.PostalCode}</p>}
              </div>

               {/* CellPhone */}
               <div className="mediowidth-left campoContainer">
                    <label className="labelApplicationForm" >Home/Cell Phone</label>
                    <input
                        type="number"
                        value={applicant.CellPhone}
                        name="CellPhone"
                        onChange={handleInputApplicant}
                        className={`${errors.CellPhone ? "imputError" : "campo"}`}
                        placeholder="Do not use dashes"
                        required
                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                    />
                    {errors.CellPhone && <p className="errorMessage">{errors.CellPhone}</p>}
                </div>



                 {/* BusinessPhone */}
                 <div className="mediowidth-right campoContainer">
                      <label className="labelApplicationForm" >Business Phone</label>
                      <input
                          type="number"
                          value={applicant.BusinessPhone}
                          name="BusinessPhone"
                          onChange={handleInputApplicant}
                          className={`${errors.BusinessPhone ? "imputError" : "campo"}`}
                          placeholder="Do not use dashes"
                          required
                          disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications)? "disabled" : null}
                      />
                      {errors.BusinessPhone && <p className="errorMessage">{errors.BusinessPhone}</p>}
                  </div>

              
            
          
              </form>

              <h3 className="applicationFormContainer-tittle">
            Identification Information
            </h3>
            <form action="" id="form" className="form">















            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Identification Type</label>
                <select
                  value={applicant.IdentificationType}

                  name="IdentificationType"
                  onChange={handleInputApplicant}
                  className={`${errors.IdentificationType ? "imputError" : "campo"}`}
                  placeholder="Identification Type"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="default" disabled selected>
                    Identification Type
                  </option>
                  <option value="Unavailable">Unavailable</option>
                  <option value="DriversLicense">Drivers License</option>
                  <option value="StateId">State Id</option>
                  <option value="UnitedStatesMilitaryId">United States Military Id</option>
                  <option value="UnitedStatesPassport">United States Passport</option>
                  <option value="PermanentResidencyCard">Permanent Residency Card</option>
                </select>
                {errors.IdentificationType && <p className="errorMessage">{errors.IdentificationType}</p>}
            </div>



            {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId' || applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'UnitedStatesPassport' || applicant.IdentificationType === 'PermanentResidencyCard'  ? 
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Identification Number</label>
                <input
                  type="text"
                  value={applicant.IdentificationNumber}
                  name="IdentificationNumber"
                  onChange={handleInputApplicant}
                  className={`${errors.IdentificationNumber ? "imputError" : "campo"}`}
                  placeholder="Identification Number"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errors.IdentificationNumber && <p className="errorMessage">{errors.IdentificationNumber}</p>}
            </div>
            : null
          
          
             }
            



            

             {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId' || applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'UnitedStatesPassport' ? 
             <div className="mediowidth-left campoContainer">
             <label className="labelApplicationForm" >Is Issue Date Present</label>
                 <select
                 value={applicant.IsIssueDatePresent}
                 name="IsIssueDatePresent"
                 onChange={handleInputApplicant}
                 className={`${errors.IsIssueDatePresent ? "imputError" : "campo"}`}
                 placeholder="Is Issue Date Present"
                 required
                 disabled={props.type === 'edit' ? "disabled" : null}
                 >
                 <option value="disable" disabled selected>
                 Is Issue Date Present
                 </option>
                 <option value="true">Yes</option>
                 <option value="false">No</option>
               </select>
               {errors.IsIssueDatePresent && <p className="errorMessage">{errors.IsIssueDatePresent}</p>}
             </div>
             : null
            
              }




              {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId' || applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'UnitedStatesPassport' || applicant.IdentificationType === 'PermanentResidencyCard' ?    
                  (applicant.IsIssueDatePresent === true || applicant.IsIssueDatePresent === 'true') || applicant.IdentificationType === 'PermanentResidencyCard' ?
                        <div className={applicant.IdentificationType === 'PermanentResidencyCard' ? "fullwidth campoContainer" : "mediowidth-right campoContainer"}>
                        <label className="labelApplicationForm" >Identification Issue Date</label>
                            <input
                              type="date"
                              value={applicant.IdentificationIssuedDate}
                              name="IdentificationIssuedDate"
                              onChange={handleInputApplicant}
                              className={`${errors.IdentificationIssuedDate ? "imputError" : "campo"}`}
                              placeholder="yyyy-mm-dd"
                              required
                              disabled={props.type === 'edit' ? "disabled" : null}
                            />
                            {errors.IdentificationIssuedDate && <p className="errorMessage">{errors.IdentificationIssuedDate}</p>}
                        </div>
                        :null
                : null   
                }
            


              {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId'   ?
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Is Issue State Present</label>
                  <select
                  value={applicant.IsIssueStatePresent}
                  name="IsIssueStatePresent"
                  onChange={handleInputApplicant}
                  className={`${errors.IsIssueStatePresent ? "imputError" : "campo"}`}
                  placeholder="Is Issue State Present"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                  Is Issue State Present
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                  

                </select>
                {errors.IsIssueStatePresent && <p className="errorMessage">{errors.IsIssueStatePresent}</p>}
              </div>
              : null
              
            
              }


          



          {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId'  || applicant.IdentificationType === 'PermanentResidencyCard'  ?

              applicant.IsIssueStatePresent === true || applicant.IsIssueStatePresent === 'true' ?
                  <div className="mediowidth-right campoContainer">
                  <label className="labelApplicationForm" >Identification Issue State</label>
                  <select
                    value={applicant.IdentificationIssueState}
                    name="IdentificationIssueState"
                    onChange={handleInputApplicant}
                    className={`${errors.IdentificationIssueState ? "imputError" : "campo"}`}
                    placeholder="Identification Issue State"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  >
                    <option value="disable" disabled selected>
                      Identification Issue State
                    </option>
                    <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
                  </select>
                  {errors.IdentificationIssueState && <p className="errorMessage">{errors.IdentificationIssueState}</p>}
                  </div>
                  :
                  null
            : null
          }


          {applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'StateId'  ?
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Is Expiration Date Present</label>
                  <select
                  value={applicant.ExpirationDateUnavailable}
                  name="ExpirationDateUnavailable"
                  onChange={handleInputApplicant}
                  className={`${errors.ExpirationDateUnavailable ? "imputError" : "campo"}`}
                  placeholder="Is Expiration Date Present"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                  Is Expiration Date Present
                  </option>
                  <option value="false">Yes</option>
                  <option value="true">No</option>
                  

                </select>
                {errors.ExpirationDateUnavailable && <p className="errorMessage">{errors.ExpirationDateUnavailable}</p>}
              </div>
              : null
          
              }


              {applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'StateId' ?
                 applicant.ExpirationDateUnavailable === false || applicant.ExpirationDateUnavailable === 'false' ?
                  <div className="mediowidth-right campoContainer">
                  <label className="labelApplicationForm" >Identification Expiration Date</label>
                      <input
                        type="date"
                        value={applicant.IdentificationExpirationDate}
                        name="IdentificationExpirationDate"
                        onChange={handleInputApplicant}
                        className={`${errors.IdentificationExpirationDate ? "imputError" : "campo"}`}
                        placeholder="yyyy-mm-dd"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errors.IdentificationExpirationDate && <p className="errorMessage">{errors.IdentificationExpirationDate}</p>}
                  </div>
                  : null
              : null
              
            
              }
            
            
            
            
              
            





            {applicant.IdentificationType === 'DriversLicense'  || applicant.IdentificationType === 'UnitedStatesPassport' || applicant.IdentificationType === 'PermanentResidencyCard'?
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Identification Expiration Date</label>
                <input
                  type="date"
                  value={applicant.IdentificationExpirationDate}
                  name="IdentificationExpirationDate"
                  onChange={handleInputApplicant}
                  className={`${errors.IdentificationExpirationDate ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errors.IdentificationExpirationDate && <p className="errorMessage">{errors.IdentificationExpirationDate}</p>}
            </div>
            : null    
            }
            
            


            
            
            
            
            </form>


            <h3 className="applicationFormContainer-tittle">
            Employment Information
              </h3>

              <form action="" id="form" className="form">


              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Employment Type</label>
                  <select
                  value={applicant.EmploymentType}
                  name="EmploymentType"
                  onChange={handleInputApplicant}
                  className={`${errors.EmploymentType ? "imputError" : "campo"}`}
                  placeholder="Employment Type"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                    Employment Type
                  </option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Employed">Employed</option>
                  <option value="Retired">Retired</option>
                  <option value="SelfEmployed">Self Employed</option>

                </select>
                {errors.EmploymentType && <p className="errorMessage">{errors.EmploymentType}</p>}
              </div>

             {applicant.EmploymentType === 'Employed'  ?
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Current Employer</label>
                  <input
                  type="text"
                  value={applicant.CurrentEmployer}
                  name="CurrentEmployer"
                  onChange={handleInputApplicant}
                  className={`${errors.CurrentEmployer ? "imputError" : "campo"}`}
                  placeholder="Current Employer"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.CurrentEmployer && <p className="errorMessage">{errors.CurrentEmployer}</p>}
              </div>
              : null}
            
           

                {applicant.EmploymentType === 'Employed' || applicant.EmploymentType === 'SelfEmployed'?
                <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Occupation</label>
                <input
                  type="text"
                  value={applicant.Occupation}
                  name="Occupation"
                  onChange={handleInputApplicant}
                  className={`${errors.Occupation ? "imputError" : "campo"}`}
                  placeholder="Occupation"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
               {errors.Occupation && <p className="errorMessage">{errors.Occupation}</p>}
                </div>
                : null}

            {/* {applicant.EmploymentType === 'Employed' || applicant.EmploymentType === 'SelfEmployed'? 
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Employment Years</label>
                  <input
                  type="number"
                  value={applicant.EmploymentYear}
                  name="EmploymentYear"
                  onChange={handleInputApplicant}
                  className={`${errors.EmploymentYear ? "imputError" : "campo"}`}
                  placeholder="Employment Years"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.EmploymentYear && <p className="errorMessage">{errors.EmploymentYear}</p>}
              </div>
              : null}

            {applicant.EmploymentType === 'Employed' || applicant.EmploymentType === 'SelfEmployed' ?
              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Employment Months</label>
                  <input
                  type="number"
                  value={applicant.EmploymentMonth}
                  name="EmploymentMonth"
                  onChange={handleInputApplicant}
                  className={`${errors.EmploymentMonth ? "imputError" : "campo"}`}
                  placeholder="Employment Months"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.EmploymentMonth && <p className="errorMessage">{errors.EmploymentMonth}</p>}
              </div>
              : null} */}

              {applicant.EmploymentType === 'Employed' || applicant.EmploymentType === 'SelfEmployed' ?
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Employment Start Date</label>
                  <input
                  type="date"
                  value={applicant.HireDate}
                  name="HireDate"
                  onChange={handleInputApplicant}
                  className={`${errors.HireDate ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.HireDate && <p className="errorMessage">{errors.HireDate}</p>}
              </div>
              : null}


              {applicant.EmploymentType === 'Employed' || applicant.EmploymentType === 'SelfEmployed' ?
               <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Gross Monthly Income</label>
                  <input
                    type="number"
                    value={applicant.GrossMonthlyIncome}
                    name="GrossMonthlyIncome"
                    onChange={handleInputApplicant}
                    onBlur={()=> { setApplicant({...applicant, 'GrossMonthlyIncome': parseFloat(applicant.GrossMonthlyIncome).toFixed(2) })   }}
                    className={`${errors.GrossMonthlyIncome ? "imputError" : "campo"}`}
                    placeholder="Gross Monthly Income"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.GrossMonthlyIncome && <p className="errorMessage">{errors.GrossMonthlyIncome}</p>}
              </div>
              : null}

              
      
            </form>

            {
              applicant.EmploymentType === 'Unemployed' || applicant.EmploymentType === 'Retired' ?
              <p className="externalForm_addIncomesText">Please Add Income below.</p>
              : null
            }


             {/*   AQUI COMIENZA OTHER INCOMES---------------------------------------------------------------------------------------- */}
             
              
             <h3 className="applicationFormContainer-tittle">
              Other Monthly Income
              </h3>
              

             {/* INCOME LISTS */}
                  {
                incomeList.map((item, index)=>{
                return(
                <div className="incomeCard" key={index}>

                  

                  {props.type === 'edit' ? 
                  null
                  :
                  <div className="mediowidth-right deleteIncome-icon_container">
                   <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteIncome(item.Id, index)}}/>
                  </div>  
                  }

                        <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >Additional Income Source</label>
                        <select
                        value={item.AdditionalIncomeSource} 
                        name="AdditionalIncomeSource"
                        onChange={(event)=>{handleIncomeInput(event, item.Id)}} 
                        className={`${errorIncomes[index].AdditionalIncomeSource ? "imputError" : "campo"}`} 
                        // className={`${errorIncomes.includes(`${errorIncomes[index].AdditionalIncomeSource}`, index ) ? "imputError" : "campo"}`} 
                        placeholder="Additional Income Source"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                        >
                          <option value="disable" disabled selected>
                            Additional Income Source
                            </option>
                            <option value="Other">Other</option>
                            <option value="RetirementSSI">Retirement SSI</option>
                            <option value="Investments">Investments</option>
                            <option value="Rental">Rental</option>
                            </select>
                            
                             {errorIncomes[index].AdditionalIncomeSource && <p className="errorMessage">{errorIncomes[index].AdditionalIncomeSource}</p>} 
                            {/* {errorIncomes.includes('AdditionalIncomeSource', index ) && <p className="errorMessage">{errorIncomes[index].AdditionalIncomeSource}</p>} */}
                        </div>




                    <div className="mediowidth-right campoContainer">
                       <label className="labelApplicationForm" >Description</label>
                      <input
                      type="text"
                      value={item.Description}
                      name="Description"
                      onChange={(event)=>{handleIncomeInput(event, item.Id)}} 
                      className={`${errorIncomes[index].Description ? "imputError" : "campo"}`}
                      // className={`${errorIncomes.includes('Description', index ) ? "imputError" : "campo"}`}
                      placeholder="Description"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorIncomes[index].Description && <p className="errorMessage">{errorIncomes[index].Description}</p>} 
                      {/* {errorIncomes.includes('Description', index ) && <p className="errorMessage">{errorIncomes[index].Description}</p>} */}
                      </div>



                      {/* <div className="mediowidth-left campoContainer">
                      <label className="labelApplicationForm" >Source</label>
                      <input
                      type="text"
                      value={item.Source}
                      name="Source"
                      onChange={(event)=>{handleIncomeInput(event, item.Id)}} 
                      className={`${errorIncomes[index].Source ? "imputError" : "campo"}`}
                      //className={`${errorIncomes.includes('Source', index ) ? "imputError" : "campo"}`}
                      placeholder="Source"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorIncomes[index].Source && <p className="errorMessage">{errorIncomes[index].Source}</p>} 
                      </div> */}


                      <div className="mediowidth-left campoContainer">
                      <label className="labelApplicationForm" >Amount</label>
                      <input
                      type="number"
                      value={item.Amount}
                      name="Amount"
                      onChange={(event)=>{handleIncomeInput(event, item.Id)}}
                      onBlur={()=> {  maskIncomeInput(item.Id, true)  }}  
                      className={`${errorIncomes[index].Amount ? "imputError" : "campo"}`}
                      //className={`${errorIncomes.includes('Amount', index )? "imputError" : "campo"}`}
                      placeholder="Amount"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorIncomes[index].Amount && <p className="errorMessage">{errorIncomes[index].Amount}</p>}
                      {/* {errorIncomes.includes('Amount', index ) && <p className="errorMessage">{errorIncomes[index].Amount}</p>} */}
                      </div>


                      {
                        item.AdditionalIncomeSource === 'Other' &&
                        <div className="mediowidth-right campoContainer">
                        <p className="income_typeList_title">Examples of “Other”</p>
                        <ul className="income_typeList">
                            <li>· Alimony</li>
                            <li>· Child Support</li>
                            <li>· Disability</li>
                            <li>· Social Security</li>
                            <li>· Unemployment</li>
                        </ul>
                        </div>
                    }


                      </div>
                      )
                      })
                      }





                      {props.type === 'edit' ? 
                      null
                      :
                      <button className="addIncome_button" onClick={addIncome}>Add Income</button>
                      }
                      

                      {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}




            
            {/*   AQUI TERMINA OTHER INCOMES---------------------------------------------------------------------------------------- */}



            <h3 className="applicationFormContainer-tittle">
            Residence
            </h3>

            <form action="" id="form" className="form">

           
           
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Residence Type</label>
                <select
                  value={applicant.ResidenceType}
                  name="ResidenceType"
                  onChange={handleInputApplicant}
                  className={`${errors.ResidenceType? "imputError" : "campo"}`}
                  placeholder=""
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="disable" disabled selected>
                  Residence Type
                  </option>
                  <option value="Rent">Rent</option>
                  <option value="OwnWithMortgage">Own with Mortgage</option>
                  <option value="OwnedFreeAndClear">Owned Free & Clear</option>
                 
                  
                </select>
                {errors.ResidenceType && <p className="errorMessage">{errors.ResidenceType}</p>}
            </div>
           

            {applicant.ResidenceType !== 'OwnedFreeAndClear' && 
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Residence Monthly Payment</label>
                  <input
                    type="number"
                    value={applicant.ResidenceMonthlyPayment}
                    name="ResidenceMonthlyPayment"
                    onChange={handleInputApplicant}
                    onBlur={()=> { setApplicant({...applicant, 'ResidenceMonthlyPayment': parseFloat(applicant.ResidenceMonthlyPayment).toFixed(2) })   }}
                    className={`${errors.ResidenceMonthlyPayment ? "imputError" : "campo"}`}
                    placeholder="Residence Monthly Payment"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.ResidenceMonthlyPayment && <p className="errorMessage">{errors.ResidenceMonthlyPayment}</p>}
              </div>
            }
            


            {applicant.ResidenceType !== 'Rent' &&          
            <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Home Value</label>
                <input
                  type="number"
                  value={applicant.HomeValue}
                  name="HomeValue"
                  onChange={handleInputApplicant}
                  onBlur={()=> { setApplicant({...applicant, 'HomeValue': parseFloat(applicant.HomeValue).toFixed(2) })   }}
                  className={`${errors.HomeValue ? "imputError" : "campo"}`}
                  placeholder="Home Value"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errors.HomeValue && <p className="errorMessage">{errors.HomeValue}</p>}
            </div>
            }
            

            

             </form> 












          

              
           
            


            <form action="" id="form" className="form">






























              {props.type === 'create' ? 
              <input
              onClick={handleSubmitApplicant}
              type="submit"
              id="submit"
              className="fullwidth submit campo"
              placeholder="Save Changes"
              value="Save Changes"
              required
            />        
              : null
              }
            </form>
          </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
        <div className="tabsMainContainer">
        
        {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
          <div className="applicationForm-titleFinancialEntityContainer">
            <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p>
            <p className="applicationForm_formtitle">AquaFinance Form</p>
          </div>
          : null
        }

        {props.type === 'create' ? stepsCounter()
        : tabs()
        }

        {isLoadingDetails ? 
        <div className="spinnerDetails"><Spinner
              size={25}
              color={"grey"}
              spinnerWidth={3}
              visible={true}
            /></div> 
            : 
        null}


          
          {props.type === 'create' ?
          <div className="applicationFormContainer">

          {props.type === 'create' ?
          <>
          <h3 className="applicationFormContainer-tittle ">
          Do you want to add a CoApplicant?
           </h3>
           <p className="coapplicantNote">If the primary applicant is married then the co-applicant must be the spouse, otherwise the co-applicant must reside in the house with the primary applicant. Adding a co-applicant may result in a better promotion offering.</p>
           </>
          :null
          }

          {props.type === 'create' ?
          <div className="form">
            <div className="fullwidth campoContainer ">
            <label className="labelApplicationForm" >Add a Co-Applicant?</label>
                <select
                  id=""
                  name="HasCoApplicant"
                  onChange={handleInputHasCoApplicant}
                  className="campo"
                  placeholder="Has CoApplicant"
                  value={applicant.HasCoApplicant === true ? 'true' : null}
                  required
                >
                  <option value="default" disabled selected>
                    Select
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                 
                </select>
                
            </div>
            </div>
            :null
            }
            

              {hasCoApplicantValue.HasCoApplicant === "false" ?
                <div className="form">
                  <button 
                  className="mediowidth-left campo back"
                  onClick={prevStep}
                  >Back</button>

                  <input
                    onClick={nextStep}
                    type="submit"
                    id="submit"
                    className="mediowidth-right submit campo"
                    placeholder="Save and continue"
                    value="Continue"
                    required
                  />
                </div>
                
                  : null
                }
           
          </div>
          : null}







          {hasCoApplicant ? 
          <div className="applicationFormContainer">
            <h3 className="applicationFormContainer-tittle">
              Main Information
            </h3>
            <div className="form">

            {/* <button onClick={()=>{nextStep()}}>Siguiente</button> */}

            <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Applicant Relationship</label>
                  <select
                  value={coApplicant.CoApplicantType ? coApplicant.CoApplicantType : coApplicant.ApplicantRelationship}
                  name="CoApplicantType"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.CoApplicantType ? "imputError" : "campo"}`}
                  placeholder="Applicant Relationship"
                  required
                  autoFocus
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                  Applicant Relationship
                  </option>
                  <option value="Spouse">Spouse</option>
                  <option value="NonSpouse">Non Spouse</option>
                  
                </select>
                {errorsCoApplicant.CoApplicantType && <p className="errorMessage">{errorsCoApplicant.CoApplicantType}</p>}
              </div>


              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" > First Name</label>
              <input
                type="text"
                value={coApplicant.FirstName}
                name="FirstName"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.FirstName ? "imputError" : "campo"}`}
                placeholder="First name"               
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              />
              {errorsCoApplicant.FirstName && <p className="errorMessage">{errorsCoApplicant.FirstName}</p>}
              </div>


              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Middle Initial</label>
              <input
                type="text"   
                name="MiddleInitial"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.MiddleInitial ? "imputError" : "campo"}`}
                value={coApplicant.MiddleInitial}
                placeholder="Only one letter"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              />
              {errorsCoApplicant.MiddleInitial && <p className="errorMessage">{errorsCoApplicant.MiddleInitial}</p>}
              </div>



              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Last Name</label>
                <input
                  type="text"
                  value={coApplicant.LastName}
                  name="LastName"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.LastName ? "imputError" : "campo"}`}
                  placeholder="Last Name"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.LastName && <p className="errorMessage">{errorsCoApplicant.LastName}</p>}
              </div>



              <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Social Security Number</label>
                <input
                  value={props.type === 'edit' ? coApplicant.hasOwnProperty('SocialSecurityNumber') ? ('*****' + coApplicant.SocialSecurityNumber.toString().substr(5,9)) : '' : coApplicant.SocialSecurityNumber}
                  type={props.type === 'edit' ? "text" : "number"}
                  name="SocialSecurityNumber"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.SocialSecurityNumber? "imputError" : "campo"}`}
                  placeholder="Do not use dashes"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.SocialSecurityNumber && <p className="errorMessage">{errorsCoApplicant.SocialSecurityNumber}</p>}
            </div>



              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Email</label>
                <input
                  type="email"
                  
                  name="EmailAddress"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.EmailAddress ? "imputError" : "campo"}`}
                  placeholder="Email"
                  value={coApplicant.EmailAddress}
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.EmailAddress && <p className="errorMessage">{errorsCoApplicant.EmailAddress}</p>}
              </div>







              
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Date of Birth</label>
                  <input
                    type="date"
                    value={coApplicant.DateOfBirth}
                    name="DateOfBirth"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.DateOfBirth ? "imputError" : "campo"}`}
                    placeholder="yyyy-mm-dd"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.DateOfBirth && <p className="errorMessage">{errorsCoApplicant.DateOfBirth}</p>}
              </div>


              {props.type === 'create' ? 
              <div className="fullwidth campoContainer checkCopyAddressInfo">
                <input type="checkbox" 
                      name="isActive" 
                      className="inputCopyAddressInfo" 
                      onClick={copyAddressData} /> <label className="labelApplicationForm" > Copy address info from Applicant</label>
              </div>
              : null
              }
              

              {copyAddresDataFromApplicant ? null :
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Address</label>
                  <input
                    type="text"
                    value={coApplicant.Address1}
                    name="Address1"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.Address1 ? "imputError" : "campo"}`}
                    placeholder="Address"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.Address1 && <p className="errorMessage">{errorsCoApplicant.Address1}</p>}
              </div>
              }
             
             {copyAddresDataFromApplicant ? null :
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >City</label>
                  <input
                    type="text"
                    value={coApplicant.City}
                    name="City"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.City ? "imputError" : "campo"}`}
                    placeholder="City"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.City && <p className="errorMessage">{errorsCoApplicant.City}</p>}
              </div>
             }


              {copyAddresDataFromApplicant ? null :
              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >State</label>
              <select
                value={coApplicant.State}
                name="State"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.State ? "imputError" : "campo"}`}
                placeholder="State"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              >
                <option value="1" disabled selected>
                  State
                </option>
                <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
              </select>
              {errorsCoApplicant.State && <p className="errorMessage">{errorsCoApplicant.State}</p>}
              </div>
              }
              
              
              {copyAddresDataFromApplicant ? null :
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Postal Code</label>
                  <input
                    type="number"
                    value={coApplicant.PostalCode}
                    name="PostalCode"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.PostalCode ? "imputError" : "campo"}`}
                    placeholder="Postal Code"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.PostalCode && <p className="errorMessage">{errorsCoApplicant.PostalCode}</p>}
              </div>
              }


              {/* CellPhone */}
              <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm" >Home/Cell Phone</label>
                  <input
                      type="number"
                      value={coApplicant.CellPhone}
                      name="CellPhone"
                      onChange={handleInputCoApplicant}
                      className={`${errorsCoApplicant.CellPhone ? "imputError" : "campo"}`}
                      placeholder="Do not use dashes"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.CellPhone && <p className="errorMessage">{errorsCoApplicant.CellPhone}</p>}
              </div>



              {/* BusinessPhone */}
              <div className="mediowidth-right campoContainer">
                  <label className="labelApplicationForm" >BusinessPhone</label>
                  <input
                      type="number"
                      value={coApplicant.BusinessPhone}
                      name="BusinessPhone"
                      onChange={handleInputCoApplicant}
                      className={`${errorsCoApplicant.BusinessPhone ? "imputError" : "campo"}`}
                      placeholder="Do not use dashes"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.BusinessPhone && <p className="errorMessage">{errorsCoApplicant.BusinessPhone}</p>}
              </div>

              
              {/* <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Primary Phone Type</label>
                  <select
                  value={coApplicant.PrimaryPhoneType}
                  name="PrimaryPhoneType"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.PrimaryPhoneType ? "imputError" : "campo"}`}
                  placeholder="Primary Phone Type"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                  Primary Phone Type
                  </option>
                  <option value="Home">Home</option>
                  <option value="Cell">Cell</option>
                  <option value="Work">Work</option>
                </select>
                {errorsCoApplicant.PrimaryPhoneType && <p className="errorMessage">{errorsCoApplicant.PrimaryPhoneType}</p>}
              </div>

              
              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Primary Phone</label>
                  <input
                    type="number"
                    value={coApplicant.PrimaryPhone}
                    name="PrimaryPhone"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.PrimaryPhone ? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.PrimaryPhone && <p className="errorMessage">{errorsCoApplicant.PrimaryPhone}</p>}
              </div>



              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Secondary Phone Type</label>
                  <select
                  value={coApplicant.SecondaryPhoneType}
                  
                  name="SecondaryPhoneType"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.SecondaryPhoneType ? "imputError" : "campo"}`}
                  placeholder="Secondary Phone Type"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                  Secondary Phone Type
                  </option>
                  <option value="Home">Home</option>
                  <option value="Cell">Cell</option>
                  <option value="Work">Work</option>
                </select>
                {errorsCoApplicant.SecondaryPhoneType && <p className="errorMessage">{errorsCoApplicant.SecondaryPhoneType}</p>}
              </div>



              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Secondary Phone</label>
                  <input
                    type="number"
                    value={coApplicant.SecondaryPhone}

                    name="SecondaryPhone"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.SecondaryPhone ? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.SecondaryPhone && <p className="errorMessage">{errorsCoApplicant.SecondaryPhone}</p>}
              </div>
              */}

            

              
             
              

          
              </div>

              <h3 className="applicationFormContainer-tittle">
            Identification Information
            </h3>
            <div className="form">

            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Identification Type</label>
                <select
                  value={coApplicant.IdentificationType}
                  name="IdentificationType"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.IdentificationType ? "imputError" : "campo"}`}
                  placeholder="Identification Type"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="default" disabled selected>
                    Identification Type
                  </option>
                  <option value="Unavailable">Unavailable</option>
                  <option value="DriversLicense">Drivers License</option>
                  <option value="StateId">State Id</option>
                  <option value="UnitedStatesMilitaryId">United States Military Id</option>
                  <option value="UnitedStatesPassport">United States Passport</option>
                  <option value="PermanentResidencyCard">Permanent Residency Card</option>
                </select>
                {errorsCoApplicant.IdentificationType && <p className="errorMessage">{errorsCoApplicant.IdentificationType}</p>}
            </div>
            
           {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'UnitedStatesPassport' || coApplicant.IdentificationType === 'PermanentResidencyCard'? 
           
           <div className="fullwidth campoContainer">
           <label className="labelApplicationForm" >Identification Number</label>
               <input
                 type="text"
                 value={coApplicant.IdentificationNumber}
                 name="IdentificationNumber"
                 onChange={handleInputCoApplicant}
                 className={`${errorsCoApplicant.IdentificationNumber ? "imputError" : "campo"}`}
                 placeholder="Identification Number"
                 required
                 disabled={props.type === 'edit' ? "disabled" : null}
               />
               {errorsCoApplicant.IdentificationNumber && <p className="errorMessage">{errorsCoApplicant.IdentificationNumber}</p>}
           </div>
           : null
          }
            


            {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'UnitedStatesPassport'?    
            <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Is Issue Date Present</label>
                <select
                value={coApplicant.IsIssueDatePresent}
                name="IsIssueDatePresent"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.IsIssueDatePresent ? "imputError" : "campo"}`}
                placeholder="Is Issue Date Present"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
                >
                <option value="disable" disabled selected>
                Is Issue Date Present
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                

              </select>
              {errorsCoApplicant.IsIssueDatePresent && <p className="errorMessage">{errorsCoApplicant.IsIssueDatePresent}</p>}
            </div>
            : null     
            }





              {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId'  || coApplicant.IdentificationType === 'UnitedStatesPassport' || coApplicant.IdentificationType === 'PermanentResidencyCard'?

                  (coApplicant.IsIssueDatePresent === 'true' || coApplicant.IsIssueDatePresent === true)  || coApplicant.IdentificationType === 'PermanentResidencyCard' ?

                  <div className={coApplicant.IdentificationType === 'PermanentResidencyCard' ? "fullwidth campoContainer" : "mediowidth-right campoContainer"}>
                  <label className="labelApplicationForm" >Identification Issue Date</label>
                      <input
                        type="date"
                        value={coApplicant.IdentificationIssuedDate}
                        name="IdentificationIssuedDate"
                        onChange={handleInputCoApplicant}
                        className={`${errorsCoApplicant.IdentificationIssuedDate ? "imputError" : "campo"}`}
                        placeholder="yyyy-mm-dd"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorsCoApplicant.IdentificationIssuedDate && <p className="errorMessage">{errorsCoApplicant.IdentificationIssuedDate}</p>}
                  </div>
                  :null
                  : null

              }

             
            


            {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId'  ?
            <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Is Issue State Present</label>
                <select
                value={coApplicant.IsIssueStatePresent}
                name="IsIssueStatePresent"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.IsIssueStatePresent ? "imputError" : "campo"}`}
                placeholder="Is Issue State Present"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
                >
                <option value="disable" disabled selected>
                Is Issue State Present
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
                

              </select>
              {errorsCoApplicant.IsIssueStatePresent && <p className="errorMessage">{errorsCoApplicant.IsIssueStatePresent}</p>}
            </div>
            :null
            }


         
              
            


            

            {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'PermanentResidencyCard' ?

                coApplicant.IsIssueStatePresent === 'true' || coApplicant.IsIssueDatePresent === true ?

                    <div className="mediowidth-right campoContainer">
                    <label className="labelApplicationForm" >Identification Issue State</label>
                    <select
                      value={coApplicant.IdentificationIssueState}
                      name="IdentificationIssueState"
                      onChange={handleInputCoApplicant}
                      className={`${errorsCoApplicant.IdentificationIssueState ? "imputError" : "campo"}`}
                      placeholder="Identification Issue State"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                    >
                      <option value="disable" disabled selected>
                        Identification Issue State
                      </option>
                      <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
                    </select>
                    {errorsCoApplicant.IdentificationIssueState && <p className="errorMessage">{errorsCoApplicant.IdentificationIssueState}</p>}
                    </div>
                    :
                    null
            : null
          }
            



          {coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'StateId'   ?
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Is Expiration Date Present</label>
                  <select
                  value={coApplicant.ExpirationDateUnavailable}
                  name="ExpirationDateUnavailable"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.ExpirationDateUnavailable ? "imputError" : "campo"}`}
                  placeholder="Is Expiration Date Present"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                  Is Expiration Date Present
                  </option>
                  <option value="false">Yes</option>
                  <option value="true">No</option>
                  

                </select>
                {errorsCoApplicant.ExpirationDateUnavailable && <p className="errorMessage">{errorsCoApplicant.ExpirationDateUnavailable}</p>}
              </div>
              : null
          
              }


              {coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'StateId'   ?
                 coApplicant.ExpirationDateUnavailable === false || coApplicant.ExpirationDateUnavailable === 'false' ?
                  <div className="mediowidth-right campoContainer">
                  <label className="labelApplicationForm" >Identification Expiration Date</label>
                      <input
                        type="date"
                        value={coApplicant.IdentificationExpirationDate}
                        name="IdentificationExpirationDate"
                        onChange={handleInputCoApplicant}
                        className={`${errorsCoApplicant.IdentificationExpirationDate ? "imputError" : "campo"}`}
                        placeholder="yyyy-mm-dd"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorsCoApplicant.IdentificationExpirationDate && <p className="errorMessage">{errorsCoApplicant.IdentificationExpirationDate}</p>}
                  </div>
                  : null
              : null
              
            
              }
            
            
            
            
            
            


            {coApplicant.IdentificationType === 'DriversLicense'  || coApplicant.IdentificationType === 'UnitedStatesPassport' || coApplicant.IdentificationType === 'PermanentResidencyCard' ?
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Identification Expiration Date</label>
                <input
                  type="date"
                  value={coApplicant.IdentificationExpirationDate}
                  name="IdentificationExpirationDate"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.IdentificationExpirationDate ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.IdentificationExpirationDate && <p className="errorMessage">{errorsCoApplicant.IdentificationExpirationDate}</p>}
            </div>
            :
            null
          }
            
           
            
            </div>







            <h3 className="applicationFormContainer-tittle">
            Employment Information
              </h3>

              <div className="form">


              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Employment Type</label>
                  <select
                  value={coApplicant.EmploymentType}
                  name="EmploymentType"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.EmploymentType ? "imputError" : "campo"}`}
                  placeholder="Employment Type"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                    Employment Type
                  </option>
                  <option value="Unemployed">Unemployed</option>
                  <option value="Employed">Employed</option>
                  <option value="Retired">Retired</option>
                  <option value="SelfEmployed">Self Employed</option>

                </select>
                {errorsCoApplicant.EmploymentType && <p className="errorMessage">{errorsCoApplicant.EmploymentType}</p>}
              </div>

             {coApplicant.EmploymentType === 'Employed' ?
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Current Employer</label>
                  <input
                  type="text"
                  value={coApplicant.CurrentEmployer}
                  name="CurrentEmployer"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.CurrentEmployer ? "imputError" : "campo"}`}
                  placeholder="Current Employer"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.CurrentEmployer && <p className="errorMessage">{errorsCoApplicant.CurrentEmployer}</p>}
              </div>
              : null}
            
            

            

              

                {coApplicant.EmploymentType === 'Employed' || coApplicant.EmploymentType === 'SelfEmployed' ?
                <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Occupation</label>
                <input
                  type="text"
                  value={coApplicant.Occupation}
                  name="Occupation"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.Occupation ? "imputError" : "campo"}`}
                  placeholder="Occupation"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
               {errorsCoApplicant.Occupation && <p className="errorMessage">{errorsCoApplicant.Occupation}</p>}
                </div>
                : null}

              {/* {coApplicant.EmploymentType === 'Employed' || coApplicant.EmploymentType === 'SelfEmployed' ? 
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Employment Year</label>
                  <input
                  type="number"
                  value={coApplicant.EmploymentYear}
                  name="EmploymentYear"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.EmploymentYear ? "imputError" : "campo"}`}
                  placeholder="Employment Year"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.EmploymentYear && <p className="errorMessage">{errorsCoApplicant.EmploymentYear}</p>}
              </div>
              : null}


            {coApplicant.EmploymentType === 'Employed' || coApplicant.EmploymentType === 'SelfEmployed' ?
              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Employment Months</label>
                  <input
                  type="number"
                  value={coApplicant.EmploymentMonth}
                  name="EmploymentMonth"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.EmploymentMonth ? "imputError" : "campo"}`}
                  placeholder="Employment Months"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.EmploymentMonth && <p className="errorMessage">{errorsCoApplicant.EmploymentMonth}</p>}
              </div>
              : null} */}


            {coApplicant.EmploymentType === 'Employed' || coApplicant.EmploymentType === 'SelfEmployed' ?
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Employment Start Date</label>
                  <input
                  type="date"
                  value={coApplicant.HireDate}
                  name="HireDate"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.HireDate ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.HireDate && <p className="errorMessage">{errorsCoApplicant.HireDate}</p>}
              </div>
              : null}


            {coApplicant.EmploymentType === 'Employed' || coApplicant.EmploymentType === 'SelfEmployed' ?
            <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Gross Monthly Income</label>
                  <input
                    type="number"
                    value={coApplicant.GrossMonthlyIncome}
                    name="GrossMonthlyIncome"
                    onChange={handleInputCoApplicant}
                    onBlur={()=> { setCoApplicant({...coApplicant, 'GrossMonthlyIncome': parseFloat(coApplicant.GrossMonthlyIncome).toFixed(2) })   }}
                    className={`${errorsCoApplicant.GrossMonthlyIncome ? "imputError" : "campo"}`}
                    placeholder="Gross Monthly Income"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.GrossMonthlyIncome && <p className="errorMessage">{errorsCoApplicant.GrossMonthlyIncome}</p>}
              </div>
               : null}

              
            
            </div>


            {
              coApplicant.EmploymentType === 'Unemployed' || coApplicant.EmploymentType === 'Retired' ?
              <p className="externalForm_addIncomesText">Please Add Income below.</p>
              : null
            }  


            {/*   AQUI COMIENZA OTHER INCOMES---------------------------------------------------------------------------------------- */}

           <h3 className="applicationFormContainer-tittle">
              Other Monthly Income
              </h3>


             {/* INCOME LISTS */}
                  {
                incomeListCoApplicant.map((item, index)=>{
                return(
                <div className="incomeCard" key={index}>

                  {props.type === 'edit' ? 
                  null
                  :
                  <div className="mediowidth-right deleteIncome-icon_container">
                   <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteIncomeCoApplicant(item.Id)}}/>
                  </div>  
                  }

                        <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >Additional Income Source</label>
                        <select
                        value={item.AdditionalIncomeSource} 
                        name="AdditionalIncomeSource"
                        onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}} 
                        className={`${errorIncomesCoApplicant[index].AdditionalIncomeSource ? "imputError" : "campo"}`} 
                        placeholder="Additional Income Source"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                        >
                          <option value="disable" disabled selected>
                            Additional Income Source
                            </option>
                            <option value="Other">Other</option>
                            <option value="RetirementSSI">Retirement SSI</option>
                            <option value="Investments">Investments</option>
                            <option value="Rental">Rental</option>
                            </select>
                            
                            {errorIncomesCoApplicant[index].AdditionalIncomeSource && <p className="errorMessage">{errorIncomesCoApplicant[index].AdditionalIncomeSource}</p>}
                        </div>




          <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Description</label>
          <input
          type="text"
          value={item.Description}
          name="Description"
          onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}} 
          className={`${errorIncomesCoApplicant[index].Description ? "imputError" : "campo"}`}
          placeholder="Description"
          required
          disabled={props.type === 'edit' ? "disabled" : null}
          />
          {errorIncomesCoApplicant[index].Description && <p className="errorMessage">{errorIncomesCoApplicant[index].Description}</p>}
          </div>



          {/* <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Source</label>
          <input
          type="text"
          value={item.Source}
          name="Source"
          onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}} 
          className={`${errorIncomesCoApplicant[index].Source ? "imputError" : "campo"}`}
          placeholder="Source"
          required
          disabled={props.type === 'edit' ? "disabled" : null}
          />
          {errorIncomesCoApplicant[index].Source && <p className="errorMessage">{errorIncomesCoApplicant[index].Source}</p>}
          </div> */}


          <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Amount</label>
          <input
          type="number"
          value={item.Amount}
          name="Amount"
          onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}}
          onBlur={()=> {  maskIncomeInput(item.Id, false)  }} 
          className={`${errorIncomesCoApplicant[index].Amount ? "imputError" : "campo"}`}
          placeholder="Amount"
          required
          disabled={props.type === 'edit' ? "disabled" : null}
          />
          {errorIncomesCoApplicant[index].Amount && <p className="errorMessage">{errorIncomesCoApplicant[index].Amount}</p>}
          </div>


          {
              item.AdditionalIncomeSource === 'Other' &&
              <div className="mediowidth-right campoContainer">
              <p className="income_typeList_title">Examples of “Other”</p>
              <ul className="income_typeList">
                  <li>· Alimony</li>
                  <li>· Child Support</li>
                  <li>· Disability</li>
                  <li>· Social Security</li>
                  <li>· Unemployment</li>
              </ul>
              </div>
          }


          </div>
          )
          })
          }




          {props.type === 'edit' ? 
          null
          :
          <button className="addIncome_button" onClick={addIncomeCoApplicant}>Add Income</button>
        }

          {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}




            
            {/*   AQUI TERMINA OTHER INCOMES---------------------------------------------------------------------------------------- */}



            <h3 className="applicationFormContainer-tittle">
            Residence
            </h3>

            <div className="form">

            {props.type === 'create' &&
            <div className="fullwidth campoContainer checkCopyAddressInfoExternalForm">
                <input type="checkbox"
                    name="isActive"
                    className="inputCopyAddressInfo"
                    onClick={copyResidenceData} /> <label className="labelApplicationForm" > Copy residence info from Applicant</label>
            </div>
            }


            {copyResidenceDataFromApplicant ? null :
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Residence Type</label>
                <select
                  value={coApplicant.ResidenceType}
                  name="ResidenceType"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.ResidenceType? "imputError" : "campo"}`}
                  placeholder=""
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="disable" disabled selected>
                  Residence Type
                  </option>
                  <option value="Rent">Rent</option>
                  <option value="OwnWithMortgage">Own with Mortgage</option>
                  <option value="OwnedFreeAndClear">Owned Free & Clear</option>
             
                  
                </select>
                {errorsCoApplicant.ResidenceType && <p className="errorMessage">{errorsCoApplicant.ResidenceType}</p>}
            </div>
            }


            {copyResidenceDataFromApplicant || coApplicant.ResidenceType === 'OwnedFreeAndClear' ? null :
            <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Residence Monthly Payment</label>
                <input
                  type="number"
                  value={coApplicant.ResidenceMonthlyPayment}
                  name="ResidenceMonthlyPayment"
                  onChange={handleInputCoApplicant}
                  onBlur={()=> { setCoApplicant({...coApplicant, 'ResidenceMonthlyPayment': parseFloat(coApplicant.ResidenceMonthlyPayment).toFixed(2) })   }}
                  className={`${errorsCoApplicant.ResidenceMonthlyPayment ? "imputError" : "campo"}`}
                  placeholder="Residence Monthly Payment"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.ResidenceMonthlyPayment && <p className="errorMessage">{errorsCoApplicant.ResidenceMonthlyPayment}</p>}
            </div>
            }
            

            {copyResidenceDataFromApplicant || coApplicant.ResidenceType === 'Rent' ? null :
            <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Home Value</label>
                <input
                  type="number"
                  value={coApplicant.HomeValue}
                  name="HomeValue"
                  onChange={handleInputCoApplicant}
                  onBlur={()=> { setCoApplicant({...coApplicant, 'HomeValue': parseFloat(coApplicant.HomeValue).toFixed(2) })   }}
                  className={`${errorsCoApplicant.HomeValue ? "imputError" : "campo"}`}
                  placeholder="Home Value"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.HomeValue && <p className="errorMessage">{errorsCoApplicant.HomeValue}</p>}
            </div>
            }


            
            
            
           



            </div> 






            
            
           























 






              




            <div action="" id="form" className="form">
















            

            
           
              {props.type === 'edit' ? null
              :
              <button 
              className="mediowidth-left campo back"
              onClick={prevStep}
              >Back</button>
              }
              
              {props.type === 'edit' ? null
              :
              <input
                onClick={handleSubmitCoApplicant}
                type="submit"
                id="submit"
                className="mediowidth-right submit campo"
                placeholder="Save and continue"
                value="Continue"
                required
                
              />
              }

               
            </div>
          </div>
          : 

              props.type === 'edit' ?

              <div className="applicationFormContainer">
                
                <p className="applicationHasNotCoApplicant-message">This Application has no Co Applicant</p>

              </div>

              : null
          
          
          
          }

         
        </div>
        </>
      );
    case 3:
      return (
        <>
        <div className="tabsMainContainer">

        {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
          <div className="applicationForm-titleFinancialEntityContainer">
            <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p>
            <p className="applicationForm_formtitle">AquaFinance Form</p>
          </div>
          : null
        }

        
        {props.type === 'create' ? stepsCounter()
        : tabs()
        }

{isLoadingDetails ? 
  <div className="spinnerDetails"><Spinner
        size={25}
        color={"grey"}
        spinnerWidth={3}
        visible={true}
      /></div> : null}


        <div className="applicationFormContainer">

          {
            errorUnavailableId && 
            <div className="cardMessage_error">
              <p>{`For Aqua, an identification is required for all applicants.  Click on the “<“ to return the the applicant and/or co-applicant screens to enter an ID`}</p>
            </div>
          }
          

          <h3 className="applicationFormContainer-tittle">Loan Parameters</h3>
          <form action="" id="form" className="form">




          
            
            <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Selling Price</label>
                <input
                  type="number"
                  value={loan.SellingPrice}
                  name="SellingPrice"
                  onChange={handleInputLoan}
                  onBlur={()=> { setLoan({...loan, 'SellingPrice': parseFloat(loan.SellingPrice).toFixed(2) })   }}
                  className={`${errorsLoan.SellingPrice ? "imputError" : "campo"}`}
                  placeholder="Selling Price"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsLoan.SellingPrice && <p className="errorMessage">{errorsLoan.SellingPrice}</p>}
            </div>



            <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Down Payment</label>
                <input
                  type="number"
                  value={loan.DownPayment}
                  name="DownPayment"
                  onChange={handleInputLoan}
                  onBlur={()=> { setLoan({...loan, 'DownPayment': parseFloat(loan.DownPayment).toFixed(2) })   }}
                  className={`${errorsLoan.DownPayment ? "imputError" : "campo"}`}
                  placeholder="Down Payment"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsLoan.DownPayment && <p className="errorMessage">{errorsLoan.DownPayment}</p>}
            </div>
            
            {/* <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Add Sales Tax Line Item</label>
                
                <select
                  value={loan.SalesTaxIncluded}
                  name="SalesTaxIncluded"
                  onChange={handleInputLoan}
                  className={`${errorsLoan.SalesTaxIncluded ? "imputError" : "campo"}`}
                  placeholder="Add Sales Tax Line Item"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="Sales Tax Included" disabled selected>
                  Add Sales Tax Line Item
                  </option> 
                  <option value="true">Yes</option> 
                  <option value="false">No</option> 
                  </select> 
                  {errorsLoan.SalesTaxIncluded && <p className="errorMessage">{errorsLoan.SalesTaxIncluded}</p>}
            </div>
            
            {includeTax || loan.SalesTaxIncluded === 'true' ? 
            <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Sales Tax</label>
                <input
                  type="number"
                  value={loan.SalesTax}
                  name="SalesTax"
                  onChange={handleInputLoan}
                  onBlur={()=> { setLoan({...loan, 'SalesTax': parseFloat(loan.SalesTax).toFixed(2) })   }}
                  className={`${errorsLoan.SalesTax ? "imputError" : "campo"}`}
                  placeholder="Sales Tax"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  
                />
                {errorsLoan.SalesTax && <p className="errorMessage">{errorsLoan.SalesTax}</p>}
            </div>
            : null} */}
            
            <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Amount Financed</label>
            <input
                  type="number"
                  value={loan.AmountFinanced}
                  name="AmountFinanced"
                  onChange={handleInputLoan}
                  className={`${errorsLoan.AmountFinanced ? "imputError" : "campo"}`}
                  placeholder="Amount Financed"
                  required
                  disabled
                  value={loan.AmountFinanced ? loan.AmountFinanced : 0}
                  
                />
                {errorsLoan.AmountFinanced && <p className="errorMessage">{errorsLoan.AmountFinanced}</p>}
            </div>
            
            <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Contract Language</label>

                <select
                  value={loan.ContractLanguage}
                  name="ContractLanguage"
                  onChange={handleInputLoan}
                  className={`${errorsLoan.ContractLanguage ? "imputError" : "campo"}`}
                  placeholder="Contract State"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="Contract Language" disabled selected>
                  Contract Language
                  </option> 
                  <option value="English">English</option> 
                  <option value="Spanish">Spanish</option> 
                  </select>  
                  {errorsLoan.ContractLanguage && <p className="errorMessage">{errorsLoan.ContractLanguage}</p>}    
            </div>
            
            <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Contract State</label>
                <select
                  value={loan.ContractState}
                  name="ContractState"
                  onChange={handleInputLoan}
                  className={`${errorsLoan.ContractState ? "imputError" : "campo"}`}
                  placeholder="Contract State"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="Contract State" disabled selected>
                    Contract State
                  </option>
                  <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
                </select>
                {errorsLoan.ContractState && <p className="errorMessage">{errorsLoan.ContractState}</p>}
            </div>
            




            <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Product or Service</label>
                
                <select
                  value={loan.ProductOrService}
                  name="ProductOrService"
                  onChange={handleInputLoan}
                  className={`${errorsLoan.ProductOrService ? "imputError" : "campo"}`}
                  placeholder="Product Or Service"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="Product Or Service" disabled selected>
                  Product or Service
                  </option> 
                  {props.type === 'edit' || props.application_id ? <option value={loan.ProductOrService}>{loan.ProductOrService}</option> : null}

                  {applicationProduct.map((item, index)=>{
                    return(
                      <option value={item.ProductOrService} key={index}>{item.ProductOrService}</option> 
                    )
                  })}
                   
                  </select> 
                  {errorsLoan.ProductOrService && <p className="errorMessage">{errorsLoan.ProductOrService}</p>}
            </div>





            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Estimated Installation Date</label>
                <input
                  type="date"
                  value={loan.CompletionDate}
                  name="CompletionDate"
                  onChange={handleInputLoan}
                  className={`${errorsLoan.CompletionDate ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsLoan.CompletionDate && <p className="errorMessage">{errorsLoan.CompletionDate}</p>}
            </div>

            {
              props.type === 'create' &&
              <div className={termsCheckBorderRed ?  "fullwidth termsContainerError" : "fullwidth termsContainer" }>
              <div className="checkCopyAddressInfo">
                <input type="checkbox" 
                      name="isActive" 
                      className="inputCopyAddressInfo" 
                      {...termsCheck ? { checked: "checked" } : null}
                      onClick={()=> setTermsCheck(!termsCheck)} /> 
                <label className="labelApplicationForm" > Applicant Authorization</label>
              </div> 
              <p>
              By checking the following box, the dealer warrants that all information provided is true and complete. The dealer guarantees that the applicant authorizes a full investigation and release of any records from any source, including credit bureau reports, to check the accuracy of information given and to determine whether the applicant's credit application is approved by the Dealer. The applicant understands no agreement exists until and unless the application is approved by the Dealer.              </p>

              </div>
            }
            





           
            {props.type === 'create' ? 
            <button 
            className="mediowidth-left campo back"
            onClick={prevStep}
            >Back</button>
            :null
            }
            
            {props.type === 'create' && termsCheck ? 
            <input
              type="submit"
              id="submit"
              onClick={handleSubmitLoan}
              className="mediowidth-right submit campo"
              placeholder="Save and Send"
              value="Submit Application"
              required
            />
            :props.type === 'create' && !termsCheck ? 
            <input
              type="submit"
              id="submit"
              className="mediowidth-right submitDisabled campo"
              placeholder="Save and Send"
              value="Submit Application"
              required
              disabled
            />
            : null
            }
          </form>

          {showNotificationOk  ? 
              (props.updateId || props.hasOwnProperty('appData') ) && !props.goToListView ?
                <Notification {...isSafari && {flexRight : true}} type='popup' reload='true' link="/applicants" title='The application was created successfully.' f={()=>{SetShowNotificationOk(false)}}>
                    <img src={iconCheck} alt="check" />
                </Notification> 
                : 
                <Notification {...isSafari && {flexRight : true}} type='popup' link="/applicants" title='The application was created successfully.' f={()=>{SetShowNotificationOk(false)}}>
                    <img src={iconCheck} alt="check" />
                </Notification> 
            :
            null}

          {showNotificationError  ? 
          <Notification {...isSafari && {flexRight : true}} errorResponse={errorResponse} error={true} type='popup' title='An error has occurred creating the application. Please verify the information and try again.' f={()=>{SetShowNotificationError(false)}}>
              <img src={iconSadFace} alt="check" />
          </Notification> : null}

          {showNotificationUserAlredyExists  ? 
          <Notification {...isSafari && {flexRight : true}} errorResponse={errorResponse} error={true} type='popup' title='Another applicant already exists with the same Social Security Number.' f={()=>{setShowNotificationUserAlredyExists(false)}}>
              <img src={iconSadFace} alt="check" />
          </Notification> : null}

        

         
        {isLoading ? <SpinnerPopUp /> : null}


        </div>
        </div>
        </>
      );
      //PROPOSAL
      case 9:
        return(<>
          <div className="tabsMainContainer">{props.type === 'create' ? stepsCounter():tabs()}
            {
             ( allApplicationData.CustomerProposalId || createProposalAfterApplication) ?
              // HAS PROPOSAL
              <div>
                {allApplicationData.CustomerProposalId && <button className="proposalCard_editButton" onClick={()=> setEditProposal(!editProposal)}>Edit proposal</button>}
                {editProposal && <p className="proposalCard_editButton_message">The form is now editable</p>} 
                <ProposalForm 
                    allApplicationData={allApplicationData}
                    proposalId={allApplicationData.CustomerProposalId} 
                    inDetails={allApplicationData.CustomerProposalId ? true : false} 
                    type={(editProposal || createProposalAfterApplication) ? 'create' : "edit"}  
                    showPersonalInformation
                    editProposal={editProposal ? true : false}
                    createProposal={createProposalAfterApplication ? true : false}
                    addCustomerPropsalId
                    dontShowCreateAppButton
                    showCreatePdfButton={true}
                    showCustomerInformacion
                    createProposalAfterApplication={createProposalAfterApplication}
                    applicationId = {props.application_id}
                    SetReloadGetApplicationDetail={SetReloadGetApplicationDetail}
                />
              </div>
              :
              // DONT HAS PROPOSAL
              <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>      
                <p className="noProposalText">This application has no associated proposal</p>
                  <button className="button_secondary-green" onClick={()=> setCreateProposalAfterApplication(true)}>Create a new proposal</button>    
              </div>
              
            }
           

          </div>
        </>)
      //STATUS
      case 4:
      return(<>
            <div className="tabsMainContainer">{props.type === 'create' ? stepsCounter():tabs()}

                <div className="applicationFormContainer">

                  <p className="applicationFormContainer-title-value">{`${props.application_id} - ${props.applicantName}`}</p>

                  <div className="aquaStatusTab_MainValuesGrid">
                    
                     
                      <div>
                        <h4 className="applicationFormContainer-title">Status:</h4>
                        <p className="applicationFormContainer-title-value">{applicationStatus || '-'}</p>
                      </div>            
                      
                      {
                          (hideBuyRate === 'true' && userRole === 'Sales Representative') ? null :
                         <div>
                         <h4 className="applicationFormContainer-title">BuyRate: </h4>
                         <p className="applicationFormContainer-title-value">{statusBuyRate || '-'}</p>
                       </div>
                      }
                     
                                       
                      <div>
                        <h4 className="applicationFormContainer-title">RTC Date: </h4>
                        <p className="applicationFormContainer-title-value">{applicationRTCDate || '-'}</p>
                      </div> 

                      <div>
                        <h4 className="applicationFormContainer-title">Contract E-sign status:</h4>
                        <p className="applicationFormContainer-title-value">{contractEsignStatus || '-'}</p>
                      </div>
                                                   
                  </div>

                  {
                    applicationStatusInfo.PromotionName && 
                    <>
                         <h4 className="applicationFormContainer-title">Promotion</h4>
                        <AquaPromotionCard 
                            disabled 
                            Name={applicationStatusInfo.PromotionName} 
                            PromoCode={applicationStatusInfo.PromoCode} 
                            EstimatedMonthlyPayment={applicationStatusInfo.EstimatedMonthlyPayment}
                            promotionId={false}
                            Description={applicationStatusInfo.PromotionDescription}
                        />
                    </>     
                  }
                  

                  <div className="spaceTopBotton-30"></div>
                  <h4 className="applicationFormContainer-title">Messages</h4>

                  

                  
                  
                  {
                    isLoadingGetStatus ? 

                      <div className="statusLoaderContainer">
                        <SkeletonLoader width="100%" height="50px"/>
                        <SkeletonLoader width="100%" height="35px"/>
                        <SkeletonLoader width="100%" height="20px"/>
                        <SkeletonLoader width="100%" height="45px"/>
                      </div>
                    :

                    hasMessages ? 
                         
                      statusMessages.map((item, index) => {
                        return (
                          <div className="cardMessage" key={index}>
                            <div className="statusHeaderContainer">
                              <p className="statusTitle">{`${item.MessageType} - ${item.Label}`}</p>
                              {
                                item.MessageType === 'Condition' &&
                                <input type="checkbox" 
                                    disabled
                                    {...item.IsMet === true && { checked: "checked" }}
                                    // {...item.IsMet && { disabled: "disabled" }}
                                    // className="inputCopyAddressInfo" 
                              />
                              }
                              
                            </div>         
                            <p className="statusMessage">{item.Text}</p>
                            <p className="statusMessage_date">{item.LastModifiedDate}</p>
                          </div>
                        );
                      })
                    
                    : 
                    <p>There are no status notifications yet</p>

                  }

                <div className="spaceTopBotton-30"></div>
                <AquaLoanHistory applicationId={props.application_id}/>

                {
                  applicationStatusInfo.BasicUser &&
                  <>
                    <h4 className="margin-top-40 applicationFormContainer-title">Creation info</h4>
                    <p className="aquaLoanHistory_card-message">{`Created by: ${applicationStatusInfo.BasicUser.FirstName} ${applicationStatusInfo.BasicUser.LastName}`}</p>
                    <p className="aquaLoanHistory_card-message">{`Created Date: ${applicationStatusInfo.CreatedDate}`}</p>
                    <p className="aquaLoanHistory_card-message">{`Updated Date: ${applicationStatusInfo.UpdatedDate}`}</p>
                  </>
                }
                

              <div className="margin-top-40 textSecondary">
                <p>Aqua Finance - Credit Department</p>
                <p>Approvals, Stipulations & Verifications</p>
                <p>800-234-3663, option 4, ext. 6098</p>
                <p>Fax: 715-848-6220</p>
                <p>creditapps@aquafinance.com</p>
                <p>Hours (CT)</p>
                <p>Monday-Friday 7:00am – 10:00pm</p>
                <p>Saturday 8:00am – 4:00pm</p>
              </div>

              
              </div>


              
              
              
              </div></>);
      case 5:
        return(
          <><div className="tabsMainContainer">
          {props.type === 'create' ? stepsCounter()
        : tabs()
        }
        

        {isLoadingDetails ? 
          <div className="spinnerDetails"><Spinner
        size={25}
        color={"grey"}
        spinnerWidth={3}
        visible={true}
        /></div> : null}



        <div className="applicationFormContainer">
      

        {loan.RemittanceUpdateFlag && <h3 className="">Remittance Data</h3>}

        {/* {noPromotion && applicationStatus !== 'FundingComplete' ? 


          <p>In order to add promotions the Application must be approved.</p>

          :
          availablePromotions.map((item, key) => {
                return (
                  <AquaPromotionCard {...item} key={item.Id} promotionSaved={promotionSaved} isSeetingPromotion={isSeetingPromotion}/>
            
                );
                          
            })
                   

                      

        } */}

        {/* <h4 className="marginTop-10">Step 2: Create Contract Paperwork</h4> */}

        {
          // (applicationStatus === 'Approved' || applicationStatus === 'Received' || applicationStatus === 'PendingDocs' || applicationStatus === 'ConditionallyApproved' || applicationStatus === 'Pending') ?
          true ?
          <AquaFinanceContract 
              applicationData={allApplicationData} 
              SetReloadGetApplicationDetail={SetReloadGetApplicationDetail} 
              setReDoSigningContract={setReDoSigningContract} 
              reDoSigningContract={reDoSigningContract}
              openPromotionFirstAccordion={openPromotionFirstAccordion}
          />
            :
            <p>In order to set contracts and promotions the Application must be Approved</p>
        }
        

        </div>
        </div>
          </>
        );
        //DOCUMENTS
        case 6:
          return(
            <><div className="tabsMainContainer">
                {props.type === 'create' ? stepsCounter()
                : tabs()
                }

          {isLoadingDetails ? 
            <div className="spinnerDetails"><Spinner
                  size={25}
                  color={"grey"}
                  spinnerWidth={3}
                  visible={true}
                /></div> : null}

                <div className="applicationFormContainer">
                {/* <h2 className="tabTittle">Documents</h2> */}

                

                <div className="documentSection">

                <AlertBox 
                    showAlert={documentsShowAlert}
                    type={documentsAlertBox.type}
                    title={documentsAlertBox.title}
                    text={documentsAlertBox.text}
                />

                  <span className="documentSection-title">Upload Document</span><span className="labelApplicationForm">      * Only .pdf and images accepted</span>
                      <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Document Type</label>
                            <select
                            name="DocumentFileType"
                            onChange={handleDocumentForm}
                            className={`${errors.PrimaryPhoneType ? "imputError" : "campo"}`}
                            placeholder="File Type"
                            required
                            >
                            <option value="disable" disabled selected>
                            Document type
                            </option>
                            <option value="AC">Applicant Contract (AC)	</option>
                            <option value="CC">Completion Certificate (CC)</option>
                            <option value="ID">Identification (ID)</option>
                            <option value="MA">Monitoring/Customer Agreement (MA)	</option>               
                            <option value="PH">Proof Of Homeownership (PH)</option>
                            <option value="PI">Proof Of Income (PI)</option>
                            <option value="SE">Schedule Of Equipment (SE)</option>
                            <option value="SV">Signal Verification (SV)</option>
                            <option value="O">Other (O)</option>
                           
                               
                            </select>
                            {errorsdocument.DocumentFileType && <p className="errorMessage">{errorsdocument.DocumentFileType}</p>}
                      </div>

                      <div className="choseDocumentSection">
                      <div className="selectFile">
                        <span className="labelSelectFile" >Choose</span>
                        <input 
                        type="file" 
                        className="documentChoseButtom" 
                        max-size="2000"
                        name="File"
                        onChange={handleDocumentForm} />
                        
                      </div>
                      {documentForm.File ? <span className="choseDocumentSection-span_filename">{documentForm.File.name}</span> : null}
                      </div>
                      {errorsdocument.File && <p className="errorMessage">{errorsdocument.File}</p>}


                      {isLoadingDetails ? <button disabled className="document-sendFile_button submit campo submitDisabled">Upload Document</button>                     
                      :
                      <button className="document-sendFile_button submit campo" onClick={handleSubmitDocument} >Upload Document</button>
                      }

                      {/* {showPostNotificationSucces &&
                      <Notification title="The document was successfully uploaded" f={()=>{SetShowPostNotificationSucces(false)}}/>
                      }
                      {showNotificationDocumentUploadError &&
                      <Notification title={messageDocumentTypeError.title} text={messageDocumentTypeError.message} color="red" f={()=>{setShowNotificationDocumentUploadError(false)}}/>
                      }
                      {showNotificationTypeDocumentGeneralError &&
                      <Notification title="Error" text={messageDocumentTypeError} color="red" f={()=>{SetShowNotificationTypeDocumentGeneralError(false)}}/>
                      }
                      {showNotificationDocumentDeletedSuccessfully &&
                      <Notification title="Document deleted successfully" f={()=>{SetShowNotificationDocumentDeletedSuccessfully(false)}}/>
                      } */}

                     

                      </div>

                      <div className="documentSection">
                        <p className="documentSection-title">Uploaded Documents</p>



                        {hasdocuments ? 
                        
                        documentsList.map((item, index) => {
                          return(
                          <div className="document-card" key={index}>
                            <div className="document-card_info">
                              <span className="document-card_info-type document-card_info-span">{item.Type}</span>
                              <span className="document-card_info-fileName document-card_info-span">{item.Filename}</span>
                              <div className="document-card_info-idAndDdate">
                                <span className="document-card_info-id document-card_info-span">{item.Id}</span>
                                <span className="document-card_info-date document-card_info-span">{item.CreationDate}</span>
                              </div>
                              
                            </div>
                            <div className="document-card_button">
                              {isLoadingDetails ? 
                              <>
                                <button className="document-card_button-dowload">Download</button>
                                {
                                  isDeallerAllyAdmin === "true" ?
                                    <button className="document-card_button-delete">Delete</button>
                                  : null
                                }   
                              </>
                                :
                                <>
                                <button className="document-card_button-dowload" onClick={()=>{dowloadDocument(item.Id, item.Filename)}}>Download</button>
                                {
                                  isDeallerAllyAdmin === "true" ?
                                  <button className="document-card_button-delete" onClick={()=>{deleteDocument(item.Id)}}>Delete</button>
                                  : null
                                } 
                                </>
                            }
                             
                            </div>
                          </div>
                          )
                          


                    })
                    
                      :
                      <p className="documentSection-title">This application has no documents</p>
                      
                      }
                    
                      </div>
                </div>
                </div>
            </>

          ); 
        case 7:
        case 8:
          return(  
            <>
            <div className="tabsMainContainer">
             {tabs()}
            
             {isLoadingDetails &&
              <div className="spinnerDetails"><Spinner
                    size={25}
                    color={"grey"}
                    spinnerWidth={3}
                    visible={true}
                  /></div>}

              <ExternalForm detailsId={'123'} appData={allApplicationData} type="edit" externalStep={step}/>
            </div>
              
            </>
          ); 

          
  }
}

export default ApplicationFormAquaFinance;
