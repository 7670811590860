import React, {useState} from 'react'
import '../../assets/styles/ExternalURL.css'

const ExternalURL = ({url}) => {

  const [seeCompleteURL, setSeeCompletURL] = useState(false)
  return (
    <div className='externalURL_container'>
        <p className='bsb_title'>BSB URL</p>
        {
          seeCompleteURL ?
          <p className='bsb_url'>{url}</p>
          :
          <p className='bsb_url'>{url.substring(0,150)}... <span className='bsb_url_see' onClick={()=> setSeeCompletURL(true)}>see complete</span></p>
        }
        
        <a  href={url} target="_blank">
            <button className='bsb_link'>
                Go to BSB form
            </button>         
        </a>    
    </div>
  )
}

export default ExternalURL