import React, { useState, useEffect, useContext } from "react";
import "../assets/styles/ApplicationForm.css";
import "../assets/styles/StepCounter.css";
import Notification from './Notification';
import {AuthContext} from '../AuthContext';
import iconCheck from '../assets/images/iconCheck.png';
import SpinnerPopUp from './SpinnerPopUp';
import Spinner from 'react-spinner-material';
import iconArrowBack from '../assets/images/iconArrowBack.png';
import iconSadFace from '../assets/images/iconSadFace.png';
require('dotenv').config();

const PinnacleStubForm =(props) =>{

  //ESTADOS

  const{uri} = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [errorIndex, setErrorIndex] = useState(false);

  const [step, setStep] = useState(1);

  //ESTADOS DE CREAR APPLICATION
  const [applicant, setApplicant] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
 

  const [showNotificationOk, SetShowNotificationOk] = useState(false);
  const [showNotificationError, SetShowNotificationError] = useState(false);
  const [showNotificationApplicantAlreadyExists, setShowNotificationApplicantAlreadyExists] = useState(false)




  //ESTADOS DE DOCUMENTS
  const [documentsList, SetDocumentsList] = useState([]);
  const [errorsdocument, setErrorsDocument] = useState({});
  const [isSubmittingDocument, setIsSubmittingDocument] = useState(false);
  const [documentForm, SetDocumentForm] = useState({});
  const [hasdocuments, SetHasDocuments] = useState(false);
  const [reloadGetDocuments, SetReloadGetDocuments] = useState(false);
  const [showPostNotificationSucces, SetShowPostNotificationSucces] = useState(false);
  const [showNotificationDocumentAlreadyExist, SetShowNotificationDocumentAlreadyExist] = useState(false);
  const [showNotificationTypeDocumentError, SetShowNotificationTypeDocumentError] = useState(false);
  const [showNotificationDocumentDeletedSuccessfully, SetShowNotificationDocumentDeletedSuccessfully] = useState(false);
  const [messageDocumentTypeError, setMessageDocumentTypeError] = useState();
  const [documentApplicationId, setDocumentApplicationId] = useState(false)

  const [reloadGetApplicationDetail, SetReloadGetApplicationDetail] = useState(false);



  const token = window.localStorage.getItem('token');
  const user = window.localStorage.getItem('user');
  const URI_X_API_KEY_I = uri.URI_X_API_KEY;
  const refreshToken = window.localStorage.getItem('refreshToken');
  const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');











  //POST APPLICATION
  const handleSubmitAllData = async (event) => {
  
    setIsLoading(true);



 
    delete applicant.LastPayDate;
    delete applicant.HireDate;
    delete applicant.DateOfBirth;
    delete applicant.LivingSinceCurrentResidence;
    delete applicant.ResidenceMonthlyPayment;
    delete applicant.DriversLicense;
    delete applicant.DriversLicenseState;
    delete applicant.EmploymentTermType;
    delete applicant.HomePhone;
    delete applicant.NetIncome;
    delete applicant.EmployerState;
    delete applicant.PayFrequency;
    delete applicant.SocialSecurityNumber;
    delete applicant.EmploymentType;
    delete applicant.CurrentEmployer;
    delete applicant.Occupation;
    delete applicant.GrossMonthlyIncome;
    delete applicant.ResidenceType;
    delete applicant.IsIssueDatePresent;
    delete applicant.IsIssueStatePresent;
    delete applicant.MiddleInitial;
    delete applicant.HasCoApplicant;
    delete applicant.HomeValue;


    //Armar el objeto que se envia
    const formData = {};
    formData.Applicant = applicant;

    if(props.entity === 'aquaFinance'){
      formData.FinantialInstitutionId = 'FI-0001';
    }else{
      formData.FinantialInstitutionId = 'FI-0002';
    }
   


    //PostalCodePlus4
    if(!applicant.PostalCodePlus4){
      formData.Applicant.PostalCodePlus4 = '0000';
    }else if(applicant.PostalCodePlus4 === ''){
      formData.Applicant.PostalCodePlus4 = '0000';
    }

   


    //AÑADIR APPLICANTID
    if(props.hasOwnProperty('urlParamApplicant_Id')){
      formData.Applicant.ApplicantId = props.urlParamApplicant_Id
    }

    
    

    console.log(formData)
    console.log(JSON.stringify(formData))

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_CREATE_STUB_APPLICATION}`;
      
    console.log(URI)
    
     
    try{
      const rawResponse = await window.fetch(URI, {
        method: 'POST',
        headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        },
        body: JSON.stringify(formData),
      
      });
     
      setIsLoading(false);
      const content = await rawResponse.json();
      console.log(content);    

      if(content.status === 'SUCCESS'){
        SetShowNotificationOk(true);
        setDocumentApplicationId(content.appplicationId)
      }else if(content.status === 'APPLICANT_ALREADY_EXISTS'){
        setShowNotificationApplicantAlreadyExists(true)
      }
      else{
        SetShowNotificationError(true);
      }
    }
    catch(error){
      console.log('OCURRIO UN ERROR ENVIANDO EL FORMULARIO DE CREAR NUEVA APPLICATION');
      SetShowNotificationError(true);            
    }

        
      
  
};

  


  // GET APPLICATIONDETAIL DETAIL
  useEffect(()=>{

    if(props.application_id){
      setIsLoadingDetails(true);
      

    const abortController = new AbortController();
    const signal = abortController.signal;

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_GET_APPLICATION_DETAIL}`
   
    

    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,
                  
          }
    })
    .then(response => response.json())
    .then(datos => {
      setIsLoadingDetails(false);

      
        console.log(datos)

       
        if(datos.Applicant){
          setApplicant(datos.Applicant);
        } 

       

        setErrorIndex(false)
        
    }).catch(error => {
      console.log(error)

              if(errorIndex === false){
              console.log('SE VENCIO EL PRIMER TOKEN')
            
              const data = {
                  "username" : user,
                  "refresh_token" : refreshToken,
                  'x-api-key' : URI_X_API_KEY_I, 
              }

              const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
              window.fetch(URI_REFRESHTOKEN ,{
              method: 'POST',
              headers: {           
              'Content-Type': 'application/json',  
              'x-api-key' : URI_X_API_KEY_I,          
              },
              body: JSON.stringify(data),
              })
              .then(response => response.json())
              .then(datos => { 
                  console.log(datos)

                  window.localStorage.removeItem('token');
                  const newToken = datos.id_token;
                  window.localStorage.setItem('token', newToken);
                  console.log('SE REEMPLAZO EL TOKEN VIEJO POR EL NUEVO');
                  SetReloadGetApplicationDetail(!reloadGetApplicationDetail)
                  setErrorIndex(true)
              })
            }
});

    return function cleanup(){
      abortController.abort();
  }
    }   
},[reloadGetApplicationDetail]);






 // RELLENAR APPLICANT EXISTENTE
 useEffect(()=>{

  if(props.urlParamApplicant_Id){
    setIsLoadingDetails(true);
    

  const abortController = new AbortController();
  const signal = abortController.signal;

  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GET_APPLICANT_DETAILS}${props.urlParamApplicant_Id}`
 
  

  window.fetch(URI,{
      method: 'GET',
      signal: signal,
      headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token, 
          'FinancialInstitution' : 'FI-0002', 
          'x-api-key' : URI_X_API_KEY_I,
                
        }
  })
  .then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);

       
     // console.log(datos)

     
      if(datos){
        setApplicant(datos);
      } 

        

      setErrorIndex(false)
      
  }).catch(error => {
    console.log(error)

            if(errorIndex === false){
            console.log('SE VENCIO EL PRIMER TOKEN')
          
            const data = {
                "username" : user,
                "refresh_token" : refreshToken,
                'x-api-key' : URI_X_API_KEY_I, 
            }

            const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
            window.fetch(URI_REFRESHTOKEN ,{
            method: 'POST',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : URI_X_API_KEY_I,          
            },
            body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(datos => { 
                console.log(datos)

                window.localStorage.removeItem('token');
                const newToken = datos.id_token;
                window.localStorage.setItem('token', newToken);
                console.log('SE REEMPLAZO EL TOKEN VIEJO POR EL NUEVO');
                SetReloadGetApplicationDetail(!reloadGetApplicationDetail)
                setErrorIndex(true)
            })
          }
});

  return function cleanup(){
    abortController.abort();
}
  }   
},[reloadGetApplicationDetail]);









// GET DOCUMENTS
useEffect(()=>{

  if(props.application_id || documentApplicationId){
    setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id || documentApplicationId}/${uri.URI_DOCUMENTS}`;


  const abortController = new AbortController();
  const signal = abortController.signal;
  

  window.fetch(URI,{
      method: 'GET',
      signal: signal,
      headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  })
  .then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);
      
      // console.log(datos)

      if(datos.length === 0){
        
        SetHasDocuments(false);
      }else{
        SetHasDocuments(true);
        SetDocumentsList(datos);
      }   
 
  })

  return function cleanup(){
    abortController.abort();
}
  }   
},[reloadGetDocuments, reloadGetApplicationDetail]);




//FUNCION PARA DESCARGAR ARCHIVOS
function showFile(blob, documentName){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  console.log(blob)
  var newBlob = new Blob([blob], {type: 'application/pdf'})
  

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download= documentName;
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}


//DOWLOAD DOCUMENT
const dowloadDocument = (fileId, documentName) =>{

  
  //const uriEncode = encodeURIComponent(filename);
  //console.log(uriEncode)

  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id || documentApplicationId}/${uri.URI_DOCUMENTS}${fileId}`;
  console.log(URI)
  window.fetch(URI,{
      method: 'GET',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  }).then(r =>  r.blob())
  .then(blob=>{
    //console.log(documentName)
    showFile(blob, documentName)
  })
  
  
}

//DELETE DOCUMENT
const deleteDocument = (fileId) =>{
  setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id || documentApplicationId}/${uri.URI_DOCUMENTS}${fileId}`;
  
  window.fetch(URI,{
      method: 'DELETE',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  }).then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);
      console.log('DATOS DOWLOAD DOCUMENT')
      console.log(datos)
      if(datos.status === 'SUCCESS'){
        SetReloadGetDocuments(!reloadGetDocuments)
        SetShowNotificationDocumentDeletedSuccessfully(true);
      }

})
}


//POST DOCUMENT
const sendDocument = () =>{
  setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id || documentApplicationId}/${uri.URI_DOCUMENTS}`;

  let data = new FormData()
  data.append('document-type', documentForm.DocumentFileType);
  data.append('file', documentForm.File);

  console.log(data)
 
  window.fetch(URI,{
      method: 'PUT',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        },
        body: data
  }).then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);
      console.log('DATOS POST DOCUMENT')
      console.log(datos)
      if(datos.status === 'SUCCESS'){
        SetReloadGetDocuments(!reloadGetDocuments)
        SetDocumentForm({});
        SetShowPostNotificationSucces(true);
        delete documentForm.DocumentFileType;
        delete documentForm.File;

      }else if(datos.status === 'DOCUMENT_TYPE_NOT_ACCEPTED'){
        SetShowNotificationTypeDocumentError(true)
        setMessageDocumentTypeError(datos.messages[0])
      }else if(datos.status === 'FILESIZE_TOO_BIG'){
        SetShowNotificationTypeDocumentError(true)
        setMessageDocumentTypeError(datos.messages[0])
      }

})
}

useEffect(() => {
  if (Object.keys(errorsdocument).length === 0 && isSubmittingDocument) {
    sendDocument();
  }
}, [errorsdocument]);












  /*----------------------------------------- HANDLE INPUTS APPLICANT COAPPLICANT LOAN------------------------------------- */

  //HANDLE INPUTS
    //inputs applicant
    const handleInputApplicant = event => {
      let value = event.target.value;



        if (event.target.name === "EmploymentType") {
          if(value === "Unemployed" || value === "Retired"){
            delete applicant.CurrentEmployer;
            delete applicant.CurrentEmployerPhoneNumber;
            delete applicant.EmploymentMonth;
            delete applicant.EmploymentYear;
            delete applicant.Occupation;
            delete applicant.EmploymentStartDate;
          }else if(value === 'SelfEmployed'){
            delete applicant.CurrentEmployer;
          }
          value = value

        }

        if (event.target.name === "IdentificationType") {
          if(value === "Unavailable"){
            
            delete applicant.IdentificationNumber;
            delete applicant.IsIssueDatePresent;
            delete applicant.IsIssueStatePresent;
            delete applicant.IdentificationIssueState;
            delete applicant.IdentificationIssuedDate;
            delete applicant.IdentificationExpirationDate;
          }else if(value === 'DriversLicense' || value === 'StateId' || value === 'UnitedStatesMilitaryId'){
            delete applicant.IdentificationIssueState;
            delete applicant.IsIssueStatePresent;
          }
          value = value

        }


        if (event.target.name === "IsIssueDatePresent") {
          if(value === "false"){
            delete applicant.IdentificationIssuedDate;          
          }
          value = value
        }


        if (event.target.name === "IsIssueStatePresent") {
          if(value === "false"){
            delete applicant.IdentificationIssueState;    
          }
          value = value
        }

        



        setApplicant({
            ...applicant,
            [event.target.name]: value,          
        })
    }



 

    

  
     /*--------------------------------------------------------------------------------------------------------------------- */ 

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

     /*----------------------------------------- FORMULARIOS Y VALIDACION DE DOCUMENTS ------------------------------------- */
    //MANEJADORES DE FORM DE DOCUMENTS
    const handleDocumentForm = event => {
      let value;
      if(event.target.name === 'File'){
        value = event.target.files[0];
        if(value.size > 8388608){
          SetShowNotificationTypeDocumentError(true)
          setMessageDocumentTypeError('Info: You can only upload files up to 8MB')
          value = null
        }
        
      }else if(event.target.name === 'DocumentFileType'){
        value = event.target.value;
      }
    
      SetDocumentForm({
        ...documentForm,
        [event.target.name]: value,       
    })
    }

    //submit documents
    const handleSubmitDocument = event => {
      event.preventDefault();
      //handle errors
      setErrorsDocument(validateFormDocument(documentForm));
      setIsSubmittingDocument(true);
      console.log(documentForm)
      
    };

    //validar el formulario de documentos
    const validateFormDocument = (data) => {
      let errorListDocument = {};
  
      //dealerid - check
      if (!data.DocumentFileType) {
        errorListDocument.DocumentFileType = "Please select a option";
      }

      if (!data.File) {
        errorListDocument.File = "Please select a file";
      }
      return errorListDocument;
      };
    /*--------------------------------------------------------------------------------------------------------------------- */  

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    /*----------------------------------------- VALIDAR FORMULARIOS ------------------------------------- */

    //validate applicand and coapplicant
    const validateForm = (applicant, isCoApplicant) => {
      let errorList = {};
  
      
  
  
      //FirstName - text
      if (!applicant.FirstName) {
        errorList.FirstName = "Please enter a name";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.FirstName)) {
        errorList.FirstName = "The name can only contain letters";
      }
  
      //LastName - text
      if (!applicant.LastName) {
        errorList.LastName = "Please enter a lastname";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.LastName)) {
        errorList.LastName = "The lastname can only contain letters";
      }
  
      //Address1 - text
      if (!applicant.Address1) {
        errorList.Address1 = "Please enter a address";
      }
  
      //City - text
      if (!applicant.City) {
        errorList.City = "Please enter a city";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s\.]+$/.test(applicant.City)) {
        errorList.City = "The city can only contain letters";
      }
  
      //State - select
      if (!applicant.State) {
        errorList.State = "Please select an option";
      } else if (applicant.State === "State") {
        errorList.State = "Please select an option";
      }
  
      //PostalCode - number
      if (!applicant.PostalCode) {
        errorList.PostalCode = "Please enter a value";
      } else if (!/^[0-9]{5}$/.test(applicant.PostalCode)) {
        errorList.PostalCode = "The value must have 5 numbers";
      } 


      //PostalCodePlus4 - number *
      if (applicant.PostalCodePlus4) {
        if (!/^[0-9]{4}$/.test(applicant.PostalCodePlus4)) {
          errorList.PostalCodePlus4 = "The value must have 4 numbers";
        }
      }
  
      //BusinessPhone - number
      if(applicant.BusinessPhone){
        if (applicant.BusinessPhone === applicant.CellPhone) {
            errorList.BusinessPhone = "Phone numbers must be different";
        }else if (!/^[0-9]{10}$/.test(applicant.BusinessPhone)) {
            errorList.BusinessPhone = "The Business Phone must have 10 numbers";
        } else if (applicant.BusinessPhone === '0000000000') {
            errorList.BusinessPhone = "Please enter a valid phone number";
        }
      }


      //BusinessPhone - number
      if (!applicant.CellPhone) {
        errorList.CellPhone = "Please enter a primary phone";
      } else if (!/^[0-9]{10}$/.test(applicant.CellPhone)) {
        errorList.CellPhone = "The CellPhone must have 10 numbers";
      }else if(applicant.CellPhone === '0000000000' ){
        errorList.CellPhone = "Please enter a valid phone number";
      }
  
    
  
  

      if(!applicant.EmailAddress){
        errorList.EmailAddress = "Please enter a email";
      }else if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.EmailAddress)){
          errorList.EmailAddress = "Please enter a valid email";
        }

  
      

      












  
      
      
  
     
  
      return errorList;
    };

    
    /*------------------------------------------------------------------------------------------------- */
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

   /*----------------------------------------- SUBMIT FORMULARIOS------------------------------------- */
    //submit applicant
    const handleSubmitApplicant = event => {
      event.preventDefault();
      //handle errors
      setErrors(validateForm(applicant, false));    
      setIsSubmitting(true);
   
    };


  /*------------------------------------------------------------------------------------------------- */

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 


    /*----------------------------------------- USE EFFECTS POS FORMULARIOS------------------------------------- */
     //post applicant MIENTRAS MIENTRAS
     useEffect(() => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
        handleSubmitAllData();
      } 
    }, [errors]);




 
   /*------------------------------------------------------------------------------------------------- */
    
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 


   /*----------------------------------------- STEPS Y TABS------------------------------------- */   
  //set step
   const setStep1 = () => {
          setStep(1);    
    };
    const setStep6 = () => {
          if(props.type === 'edit' || documentApplicationId){
            setStep(6);
          }
              
        };
    const prevStep = () => {
        setStep(step - 1);  
    };
    const restar = () => {
        setStep(1);
    };


    //steps for create new application
    const stepsCounter = () => {
      const styletitlemobile1 = ["stepmobiletittle"];
      const styletitlemobile2 = ["stepmobiletittle"];
      const styletitlemobile3 = ["stepmobiletittle"];
      
      const stylenumber1 = ["stepnumber"];
      const styletitle1 = ["steptittle"];
      const stylenumber2 = ["stepnumber"];
      const styletitle2 = ["steptittle"];
      const stylenumber3 = ["stepnumber"];
      const styletitle3 = ["steptittle"];
      
      if (step === 1) {
        stylenumber1.push("stepnumberactive");
        styletitle1.push("steptittleactive");
        styletitlemobile1.push("showstepmobiletittle");
      }
      if (step === 2) {
        stylenumber1.push("stepnumbercomplete");
        stylenumber2.push("stepnumberactive");
        styletitle2.push("steptittleactive");
        styletitlemobile2.push("showstepmobiletittle");
      }
      if (step === 3) {
        stylenumber1.push("stepnumbercomplete");
        stylenumber2.push("stepnumbercomplete");
        stylenumber3.push("stepnumberactive");
        styletitle3.push("steptittleactive");
        styletitlemobile3.push("showstepmobiletittle");
      }
      
  
      return (
        <div className="stepscontainer">
          <div className="stepsmobiletittlecontainer">
          
            
            <h3 className={styletitlemobile1.join(" ")}>Applicant Information</h3>
            <h3 className={styletitlemobile2.join(" ")}>CoApplicant Information</h3>
            <h3 className={styletitlemobile3.join(" ")}>Loan Parameters</h3>
            
          </div>


          <div className="stepsdesktopcontainer">
            {step === 1 ? null : <img src={iconArrowBack } onClick={prevStep} className="backButton"/>}
            
            <div className="step">
              <span className={stylenumber1.join(" ")}>1</span>
              <h3 className={styletitle1.join(" ")}>Applicant Information</h3>
            </div>
            <div className="step">
              <span className={stylenumber2.join(" ")}>2</span>
              <h3 className={styletitle2.join(" ")}>
                CoApplicant Information
              </h3>
            </div>
            <div className="step">
              <span className={stylenumber3.join(" ")}>3</span>
              <h3 className={styletitle3.join(" ")}>Loan Parameters</h3>
            </div>
           
          </div>
        </div>
      );
    };


    //tabs for detail application
    const tabs = () => {
        
      return (
        
        <div className="applicationTabsContainer">
          <ul className="applicationTabsPinnacle">
            <li className={step===1 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep1}>Applicant</li>
            {/* <li className={step===2 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep2}>CoApplicant</li>
            <li className={step===3 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep3}>Parameters</li>
            <li className={step===4 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep4}>Status</li>
            <li className={step===5 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep5}>Promotions</li> */}
            <li className={step===6 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep6}>Documents</li>
            
          </ul>
        </div>
        
      );
    
    
    }
    /*------------------------------------------------------------------------------------------------- */

    

   

   
   














  switch (step) {
    default:
      return (
        <div>
          <h1>breack</h1>
          <button onClick={restar} value="Next">
            Next
          </button>
        </div>
      );
    case 1:
      
      return (
        <>

        <div className="documentSectionContentMain">

        {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
          <div className="applicationForm-titleFinancialEntityContainer">
            {/* <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p> */}
            <p className="applicationForm_formtitle">{props.entity === 'aquaFinance' ? 'AquaFinance Form' : 'Pinnacle Form'}</p>
          </div>
          : null
        }

        {/* {props.type === 'create' ? null : tabs()} */}
        
       

        

        {isLoadingDetails ? 
        <div className="spinnerDetails"><Spinner
              size={25}
              color={"grey"}
              spinnerWidth={3}
              visible={true}
            /></div> : null}




          <div className="applicationFormContainer">

          {tabs()}

            <h3 className="applicationFormContainer-tittle">
              Main Information
            </h3>
            <form action="" id="form" className="form">




              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" > First Name</label>
              <input
                type="text"
                
                name="FirstName"
                onChange={handleInputApplicant}
                className={`${errors.FirstName ? "imputError" : "campo"}`}
                value={applicant.FirstName}
                placeholder="First name"
                disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id) ? "disabled" : null}
                required
              />
              {errors.FirstName && <p className="errorMessage">{errors.FirstName}</p>}
              </div>



              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Last Name</label>
                <input
                  type="text"
                  
                  name="LastName"
                  onChange={handleInputApplicant}
                  className={`${errors.LastName ? "imputError" : "campo"}`}
                  placeholder="Last Name"
                  value={applicant.LastName}
                  required
                  disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id) ? "disabled" : null}
                />
                {errors.LastName && <p className="errorMessage">{errors.LastName}</p>}
              </div>




              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Email</label>
                <input
                  type="email"
                  
                  name="EmailAddress"
                  onChange={handleInputApplicant}
                  className={`${errors.EmailAddress ? "imputError" : "campo"}`}
                  placeholder="Email"
                  value={applicant.EmailAddress}
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errors.EmailAddress && <p className="errorMessage">{errors.EmailAddress}</p>}
              </div>




              
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Address</label>
                  <input
                    type="text"
                    value={applicant.Address1}

                    name="Address1"
                    onChange={handleInputApplicant}
                    className={`${errors.Address1 ? "imputError" : "campo"}`}
                    placeholder="Address"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.Address1 && <p className="errorMessage">{errors.Address1}</p>}
              </div>
             


              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >City</label>
                  <input
                    type="text"
                    value={applicant.City}

                    name="City"
                    onChange={handleInputApplicant}
                    className={`${errors.City ? "imputError" : "campo"}`}
                    placeholder="City"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.City && <p className="errorMessage">{errors.City}</p>}
              </div>



              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >State</label>
              <select
                value={applicant.State || 'selected'}
                name="State"
                onChange={handleInputApplicant}
                className={`${errors.State ? "imputError" : "campo"}`}
                placeholder="State"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              >
                <option value="selected" disabled>
                  State
                </option>
                <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
              </select>
              {errors.State && <p className="errorMessage">{errors.State}</p>}
              </div>
              
              
              
               <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >{props.entity === 'aquaFinance' ? 'Postal Code' : 'Zip Code'}</label>
                  <input
                    type="number"
                    value={applicant.PostalCode}

                    name="PostalCode"
                    onChange={handleInputApplicant}
                    className={`${errors.PostalCode ? "imputError" : "campo"}`}
                    placeholder={props.entity === 'aquaFinance' ? 'Postal Code' : 'Zip Code'}
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.PostalCode && <p className="errorMessage">{errors.PostalCode}</p>}
              </div> 



              {
                props.entity === 'aquaFinance' ? null 
                :
                <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm" >Plus 4</label>
                    <input
                      type="number"
                      value={applicant.PostalCodePlus4}
                      name="PostalCodePlus4"
                      onChange={handleInputApplicant}
                      className={`${errors.PostalCodePlus4 ? "imputError" : "campo"}`}
                      placeholder="Plus 4"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                    />
                    {errors.PostalCodePlus4 && <p className="errorMessage">{errors.PostalCodePlus4}</p>}
                </div> 
              }
              

              

              
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Cell Phone</label>
                  <input
                    type="number"
                    value={applicant.CellPhone || applicant.PrimaryPhone}
                    name="CellPhone"
                    onChange={handleInputApplicant}
                    className={`${errors.CellPhone ? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.CellPhone && <p className="errorMessage">{errors.CellPhone}</p>}
              </div>


              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Business Phone</label>
                  <input
                    type="number"
                    value={applicant.BusinessPhone}
                    name="BusinessPhone"
                    onChange={handleInputApplicant}
                    className={`${errors.BusinessPhone ? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.BusinessPhone && <p className="errorMessage">{errors.BusinessPhone}</p>}
              </div>
          
              </form>

             


              {/* BORRAR ESTE BOTON EN LA VERSION OFICIAL Y USAR SOLO EL DE ARRIBA */}
              {props.type === 'create' ? 
              <input
              onClick={handleSubmitApplicant}
              type="submit"
              id="submit"
              className="fullwidth submit campo"
              placeholder="Send Application"
              value="Save Changes"
              required
            />        
              : null
              }
          </div>
          </div>

          {showNotificationOk  ? 
              props.updateId ?
                <Notification type='popup' reload='true' link ="/applicants" title='The application was created successfully.' f={()=>{SetShowNotificationOk(false)}}>
                    <img src={iconCheck} alt="check" />
                </Notification> 
                : 
                <Notification type='popup' title='The application was created successfully.' f={()=>{SetShowNotificationOk(false); setStep(6)}}>
                    <img src={iconCheck} alt="check" />
                </Notification> 
            :
            null}

        {showNotificationError  ? 
          <Notification type='popup' title='An error has occurred creating the application. Please verify the information and try again.' f={()=>{SetShowNotificationError(false)}}>
              <img src={iconSadFace} alt="check" />
          </Notification> : null}


          {showNotificationApplicantAlreadyExists  ? 
          <Notification type='popup' title='This applicant already exists' f={()=>{setShowNotificationApplicantAlreadyExists(false)}}>
              <img src={iconSadFace} alt="check" />
          </Notification> : null}

        {isLoading ? <SpinnerPopUp /> : null}

        </>
      );
    
        case 6:
          return(
            <>
            <div className="documentSectionContentMain">
            {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
              <div className="applicationForm-titleFinancialEntityContainer">
                {/* <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p> */}
                <p className="applicationForm_formtitle">{props.entity === 'aquaFinance' ? 'AquaFinance Form' : 'Pinnacle Form'}</p>
              </div>
              : null
              }

            {/* {props.type === 'create' ? null : tabs()} */}
            

            <div className="documentSection">
                {isLoadingDetails ? 
                  <div className="spinnerDetails"><Spinner
                    size={25}
                    color={"grey"}
                    spinnerWidth={3}
                    visible={true}
                  /></div> : null}


                <div className="applicationFormContainer">

                {tabs()}
                
                <div className="documentSectionContent">

                <h2 className="tabTittle">Documents</h2>

                <div className="">
                  <span className="documentSection-title">Upload Document</span><span className="labelApplicationForm">      * Only .pdf and images accepted</span>
                      <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Document Type</label>
                            <select
                            name="DocumentFileType"
                            onChange={handleDocumentForm}
                            className={`${errors.DocumentFileType ? "imputError" : "campo"}`}
                            placeholder="File Type"
                            required
                            
                            >
                            <option value="disable" disabled selected>
                            Document type
                            </option>
                            <option value="AC">Applicant Contract (AC)	</option>
                            <option value="CC">Completion Certificate (CC)</option>
                            <option value="ID">Identification (ID)</option>
                            <option value="MA">Monitoring/Customer Agreement (MA)	</option>               
                            <option value="PH">Proof Of Homeownership (PH)</option>
                            <option value="PI">Proof Of Income (PI)</option>
                            <option value="SE">Schedule Of Equipment (SE)</option>
                            <option value="SV">Signal Verification (SV)</option>
                            <option value="O">Other (O)</option>
                            
                          
                            </select>
                            {errorsdocument.DocumentFileType && <p className="errorMessage">{errorsdocument.DocumentFileType}</p>}
                      </div>

                      <div className="choseDocumentSection">
                      <div className="selectFile">
                        <span className="labelSelectFile" >Choose</span>
                        <input 
                        type="file" 
                        className="documentChoseButtom" 
                        max-size="2000"
                        name="File"
                        onChange={handleDocumentForm} />
                        
                      </div>
                      {documentForm.File ? <span className="choseDocumentSection-span_filename">{documentForm.File.name}</span> : null}
                      </div>
                      {errorsdocument.File && <p className="errorMessage">{errorsdocument.File}</p>}


                      {isLoadingDetails ? <button disabled className="document-sendFile_button submit campo submitDisabled">Upload Document</button>                     
                      :
                      <button className="document-sendFile_button submit campo" onClick={handleSubmitDocument} >Upload Document</button>
                      }

                      </div>

                      {showPostNotificationSucces ? 
                      <Notification title="The document was successfully uploaded" f={()=>{SetShowPostNotificationSucces(false)}}/>
                      :
                      null
                      }
                      {showNotificationTypeDocumentError ?
                      <Notification title="Type of document not accepted" text={messageDocumentTypeError} color="red" f={()=>{SetShowNotificationTypeDocumentError(false)}}/>
                      :
                      null
                      }
                      {showNotificationDocumentDeletedSuccessfully ?
                      <Notification title="Document deleted successfully" f={()=>{SetShowNotificationDocumentDeletedSuccessfully(false)}}/>
                      :
                      null
                      }

                     

                      </div>

                      <div className="documentSection">
                        <span className="documentSection-title">Uploaded Documents</span>




                        {hasdocuments ? 
                        
                        documentsList.map((item, index) => {
                          return(
                          <div className="document-card" key={index}>
                            <div className="document-card_info">
                              <span className="document-card_info-type document-card_info-span">{item.Type}</span>
                              <span className="document-card_info-fileName document-card_info-span">{item.Filename}</span>
                              <div className="document-card_info-idAndDdate">
                                <span className="document-card_info-id document-card_info-span">{item.Id}</span>
                                <span className="document-card_info-date document-card_info-span">{item.CreationDate}</span>
                              </div>
                              
                            </div>
                            <div className="document-card_button">
                              {isLoadingDetails ? 
                              <>
                                <button className="document-card_button-dowload">Download</button>
                                {
                                  isDeallerAllyAdmin === "true" ?
                                    <button className="document-card_button-delete">Delete</button>
                                  : null
                                }              
                              </>
                                :
                                <>
                                <button className="document-card_button-dowload" onClick={()=>{dowloadDocument(item.Id, item.Filename)}}>Download</button>
                                {
                                  isDeallerAllyAdmin === "true" ?
                                  <button className="document-card_button-delete" onClick={()=>{deleteDocument(item.Id)}}>Delete</button>
                                  : null
                                }        
                                </>
                            }
                             
                            </div>
                          </div>
                          )
                          


                    })
                    
                      :
                      <p className="documentSection-title">This application has no documents</p>
                      
                      }
                    
                      </div>
                </div>
                </div>
                </div>
            </>

          );   
  }
}

export default PinnacleStubForm;
