import React, { useState, useEffect, useContext } from 'react'
import "../assets/styles/ApplicationForm.css";
import "../assets/styles/StepCounter.css";
import "../assets/styles/ExternalForm.css";
import Notification from './Notification';
import { AuthContext } from '../AuthContext';
import iconCheck from '../assets/images/iconCheck.png';
import SpinnerPopUp from './SpinnerPopUp';
import Spinner from 'react-spinner-material';
import iconArrowBack from '../assets/images/iconArrowBack.png';
import iconSadFace from '../assets/images/iconSadFace.png';
import iconDelete from '../assets/images/iconDelete.png';
import useIsSafari from '../hooks/useIsSafari'
import PlacesAutocomplete from "react-places-autocomplete";
import useHandlePlacesAutocomplete from '../hooks/useHandlePlacesAutocomplete';
import moment from 'moment-timezone';
import NumberFormat from "react-number-format";



const ExternalForm = (props) => {

    //ESTADOS

    const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken, proposalInfo } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);

    const isSafari = useIsSafari()

    const [errorIndex, setErrorIndex] = useState(false);

    const [step, setStep] = useState(1);

    //ESTADOS DE CREAR APPLICATION
    const [applicant, setApplicant] = useState(props.appData ? ((Object.keys(props.appData).length === 0) ? {} : props.appData.Applicant ) : {});
    //const [applicant, setApplicant] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [coApplicant, setCoApplicant] = useState(props.appData ? ( (Object.keys(props.appData).length === 0) ? {} : props.appData.CoApplicant ? props.appData.CoApplicant : {} ) : {});
    const [hasCoApplicant, setHasCoapplicant] = useState(props.appData ? props.appData.CoApplicant ? true :false : false);
    const [hasCoApplicantValue, setHasCoapplicantValue] = useState({});
    const [errorsCoApplicant, setErrorsCoApplicant] = useState({});
    const [isSubmittingCoApplicant, setIsSubmittingCoApplicant] = useState(false);
    const [copyAddresDataFromApplicant, setCopyAddressDataFromApplicant] = useState(false);
    const [copyResidenceDataFromApplicant, setCopyResidenceDataFromApplicant] = useState(false)


    const [showNotificationOk, SetShowNotificationOk] = useState(false);
    const [showNotificationError, SetShowNotificationError] = useState({Show: false});
    const [showNotificationApplicantAlreadyExists, setShowNotificationApplicantAlreadyExists] = useState(false)


    //INCOMES
    const [incomeList, setIncomeList] = useState([]);
    const [errorIncomes, setErrorIncomes] = useState([{}])
    const [incomeListCoApplicant, setIncomeListCoApplicant] = useState([]);
    const [errorIncomesCoApplicant, setErrorIncomesCoApplicant] = useState([{}])
    const [isSubmitiongIncomeApplicant, setIsSubmitingIncomeApplicant] = useState(false);
    const [isSubmitiongIncomeCoApplicant, setIsSubmitingIncomeCoApplicant] = useState(false)


    const [sendACHToo, setSendACHToo] = useState(false)


    const [documentApplicationId, setDocumentApplicationId] = useState(false)


    const [handleChangePlacesAutocomplete, handleSelectPlacesAutocomplete ] = useHandlePlacesAutocomplete(setApplicant, 'Address1');
    const [handleChangePlacesAutocompleteCoApplicant, handleSelectPlacesAutocompleteCoApplicant ] = useHandlePlacesAutocomplete(setCoApplicant, 'Address1');


    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const dealerId = window.localStorage.getItem('dealerId');
 












    //POST APPLICATION
    const handleSubmitAllData = async (event) => {

        setIsLoading(true);
        SetShowNotificationError({Show: false})


        //EMPLOYED TYPE APPLICANT
        if (applicant.EmploymentType === 'Unemployed' || applicant.EmploymentType === 'Retired') {
            delete applicant.CurrentEmployer;
            delete applicant.Occupation;
            delete applicant.GrossMonthlyIncome;
        } else if (applicant.EmploymentType === 'SelfEmployed') {
            delete applicant.CurrentEmployer;
        }


        //EMPLOYED TYPE COAPPLICANT
        if (coApplicant.EmploymentType === 'Unemployed' || coApplicant.EmploymentType === 'Retired') {
            delete coApplicant.CurrentEmployer;
            delete coApplicant.Occupation;
            delete coApplicant.GrossMonthlyIncome;
        } else if (coApplicant.EmploymentType === 'SelfEmployed') {
            delete coApplicant.CurrentEmployer;
        }


        //RESIDENCE APPLICANT
        if(applicant.ResidenceType === 'Rent'){
            delete applicant.HomeValue;
        }else if(applicant.ResidenceType === 'OwnedFreeAndClear'){
            delete applicant.ResidenceMonthlyPayment;
        }


        //RESIDENCE COAPPLICANT
        if(coApplicant.ResidenceType === 'Rent'){
        delete coApplicant.HomeValue;
        }else if(coApplicant.ResidenceType === 'OwnedFreeAndClear'){
            delete coApplicant.ResidenceMonthlyPayment;
        }


        




        //Armar el objeto que se envia
        const formData = {};
        formData.Applicant = applicant;

        if (hasCoApplicant === true) {
            formData.CoApplicant = coApplicant;
        }


        //IFRAME
        if(props.iframe === true){
            formData.DealerId = props.dealerId
        }else{
            formData.ProspectLoanApplicationId = props.applicationId
        }

        


        //ADD INDENTIFICATION TYPE
        if(!applicant.IdentificationType){
            formData.Applicant.IdentificationType = 'Unavailable'; 
        }
        if(hasCoApplicant === true && !coApplicant.IdentificationType){
            formData.CoApplicant.IdentificationType = 'Unavailable'; 
        }
        



        if(formData.Applicant.IdentificationType === 'Unavailable'){
            delete formData.Applicant.IdentificationNumber;
            delete formData.Applicant.IsIssueDatePresent;
            delete formData.Applicant.IsIssueStatePresent;
            delete formData.Applicant.IdentificationIssueState;
            delete formData.Applicant.IdentificationIssuedDate;
            delete formData.Applicant.IdentificationExpirationDate; 
            delete formData.Applicant.ExpirationDateUnavailable; 
        }else if(formData.Applicant.IdentificationType === 'PermanentResidencyCard' ){
            formData.Applicant.IsIssueDatePresent = 'true'
            delete formData.Applicant.ExpirationDateUnavailable; 
            delete formData.Applicant.IdentificationIssueState
            delete formData.Applicant.IsIssueStatePresent
        }
        else if(formData.Applicant.IdentificationType === 'UnitedStatesMilitaryId'){
            delete formData.Applicant.IsIssueStatePresent
            delete formData.Applicant.IdentificationIssueState
            if(formData.Applicant.ExpirationDateUnavailable === 'true'){
                delete formData.Applicant.IdentificationExpirationDate
            }
        }else if(formData.Applicant.IdentificationType === 'UnitedStatesPassport'){
            delete formData.Applicant.IdentificationIssueState
            delete formData.Applicant.IsIssueStatePresent
            delete formData.Applicant.ExpirationDateUnavailable;
        }else{
            //delete formData.Applicant.ExpirationDateUnavailable; 
        }


        if(formData.Applicant.IsIssueDatePresent === 'false'){
            delete formData.Applicant.IdentificationIssuedDate
        }

        if(formData.Applicant.IsIssueStatePresent === 'false'){
            delete formData.Applicant.IdentificationIssueState
        }

        if(formData.Applicant.ExpirationDateUnavailable === 'true'){
            delete formData.Applicant.IdentificationExpirationDate
        }
    
        



        if(hasCoApplicant === true ){
            if(formData.CoApplicant.IdentificationType === 'Unavailable'){
                delete formData.CoApplicant.IdentificationNumber;
                delete formData.CoApplicant.IsIssueDatePresent;
                delete formData.CoApplicant.IsIssueStatePresent;
                delete formData.CoApplicant.IdentificationIssueState;
                delete formData.CoApplicant.IdentificationIssuedDate;
                delete formData.CoApplicant.IdentificationExpirationDate; 
                delete formData.CoApplicant.ExpirationDateUnavailable; 
            }else if(formData.CoApplicant.IdentificationType === 'PermanentResidencyCard' ){
                formData.CoApplicant.IsIssueDatePresent = 'true'
                delete formData.CoApplicant.ExpirationDateUnavailable; 
                delete formData.CoApplicant.IdentificationIssueState
                delete formData.CoApplicant.IsIssueStatePresent
            }
            else if(formData.CoApplicant.IdentificationType === 'UnitedStatesMilitaryId'){
                delete formData.CoApplicant.IsIssueStatePresent
                delete formData.CoApplicant.IdentificationIssueState
                if(formData.CoApplicant.ExpirationDateUnavailable === 'true'){
                    delete formData.CoApplicant.IdentificationExpirationDate
                }
            }else if(formData.CoApplicant.IdentificationType === 'UnitedStatesPassport'){
                delete formData.CoApplicant.IdentificationIssueState
                delete formData.CoApplicant.IsIssueStatePresent
                delete formData.CoApplicant.ExpirationDateUnavailable;
            }else{
                //delete formData.CoApplicant.ExpirationDateUnavailable; 
            }


            if(formData.CoApplicant.IsIssueDatePresent === 'false'){
                delete formData.CoApplicant.IdentificationIssuedDate
            }
    
            if(formData.CoApplicant.IsIssueStatePresent === 'false'){
                delete formData.CoApplicant.IdentificationIssueState
            }
    
            if(formData.CoApplicant.ExpirationDateUnavailable === 'true'){
                delete formData.CoApplicant.IdentificationExpirationDate
            }
          
        }
        

         //AÑADIR HOMEPHONE
         formData.Applicant.HomePhone = applicant.CellPhone;

         if (hasCoApplicant === true) {
            formData.CoApplicant.HomePhone = coApplicant.CellPhone;
         }


        //CONVERT TO FLOAT LOANPARAMETERS
        // formData.Applicant.HomeValue = parseFloat(formData.Applicant.HomeValue).toFixed(2);
        // formData.Applicant.GrossMonthlyIncome = parseFloat(formData.Applicant.GrossMonthlyIncome).toFixed(2);
        // formData.Applicant.ResidenceMonthlyPayment = parseFloat(formData.Applicant.ResidenceMonthlyPayment).toFixed(2);
        if( formData.Applicant.ResidenceMonthlyPayment){
            formData.Applicant.ResidenceMonthlyPayment = parseFloat(formData.Applicant.ResidenceMonthlyPayment);
          } 
          if( formData.Applicant.HomeValue){
            formData.Applicant.HomeValue = parseFloat(formData.Applicant.HomeValue);
          } 
          if( formData.Applicant.GrossMonthlyIncome){
            formData.Applicant.GrossMonthlyIncome = parseFloat(formData.Applicant.GrossMonthlyIncome);
          } 
        if (hasCoApplicant === true) {
            // formData.CoApplicant.HomeValue = parseFloat(formData.CoApplicant.HomeValue).toFixed(2);
            // formData.CoApplicant.GrossMonthlyIncome = parseFloat(formData.CoApplicant.GrossMonthlyIncome).toFixed(2);
            // formData.CoApplicant.ResidenceMonthlyPayment = parseFloat(formData.CoApplicant.ResidenceMonthlyPayment).toFixed(2);
            if(formData.CoApplicant.ResidenceMonthlyPayment){
                formData.CoApplicant.ResidenceMonthlyPayment = parseFloat(formData.CoApplicant.ResidenceMonthlyPayment);
            }  
            if( formData.CoApplicant.HomeValue){
            formData.CoApplicant.HomeValue = parseFloat(formData.CoApplicant.HomeValue);
            } 
            if( formData.CoApplicant.GrossMonthlyIncome){
            formData.CoApplicant.GrossMonthlyIncome = parseFloat(formData.CoApplicant.GrossMonthlyIncome);
            } 
        }



        //Añadir otherincomes a Applicant
        if (incomeList.length > 0) {
            const newList = incomeList.map(item => { if (item.Id.includes('Income')) { delete item.Id }; item.Amount = parseFloat(item.Amount).toFixed(2); return item })
            formData.Applicant.AdditionalIncomeSources = newList;
        }

        //añadir otherincomes a Coapplicant
        if (incomeListCoApplicant.length > 0 && hasCoApplicant === true) {
            const newList = incomeListCoApplicant.map(item => { if (item.Id.includes('Income')) { delete item.Id }; item.Amount = parseFloat(item.Amount).toFixed(2); return item })
            formData.CoApplicant.AdditionalIncomeSources = newList;
        }






        // console.log(formData)
        // console.log(JSON.stringify(formData))



        let URI = ''
        if(props.internal){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}create-common-application`;
        }else{
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}fill-in-prospect-application`; 
        }

        let headers = ''
        if(props.internal){
            headers = {
                        'Access-Control-Request-Headers': 'application/json',
                        'Content-Type': 'application/json',
                        'username': user,
                        'x-api-key': URI_X_API_KEY_I,
                        'Authorization' : token, 
                    }
        }else{
            headers = {
                'Access-Control-Request-Headers': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': URI_X_API_KEY_I,
            }
        }

        //console.log(URI)

        if(props.internal){
            setIsLoading(false);
            props.setStep(2)
            props.setApplicationData(formData)
            window.scroll(0, 0)
        }else{

            try {
                const rawResponse = await window.fetch(URI, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(formData),
    
                });
    
                setIsLoading(false);
                const content = await rawResponse.json();
                console.log(content);



                if (content.Status === 'FilledOut') {
                    SetShowNotificationOk(true);
                } else if (content.Status === 'APPLICANT_ALREADY_EXISTS') {
                    setShowNotificationApplicantAlreadyExists(true)
                }
                else {
                    SetShowNotificationError({Message : content.Message, Errors: content.Errors, Show: true});
                }
    
    
            
                
    
            }
            catch (error) {
                console.log('Catch Error');
                SetShowNotificationError({Message : 'An error has occurred creating the application. Please verify the information and try again.', Show: true});
            }
    
       }

    };





    //CAMBIAR STEP
    useEffect(()=>{
        if(props.externalStep){
            if(props.externalStep === 7){
                setStep(1)  
            }else if(props.externalStep === 8){
                setStep(2) 
            }
            
        }
    }, [props.externalStep])








    //LLENAR LA INFORMACION POR PROPS PARA MOSTRAR DETAILS
    useEffect(()=>{
        if(props.hasOwnProperty('appData') && Object.keys(props.appData).length > 0){
            // console.log(props.appData)
            //APPLICANT
            // setApplicant(props.appData ? props.appData.Applicant : {})
            const data = props.appData.Applicant
            setApplicant(props.appData.Applicant ?{...data, ResidenceMonthlyPayment: parseFloat(data.ResidenceMonthlyPayment).toFixed(2) || undefined,
                                                            HomeValue: parseFloat(data.HomeValue).toFixed(2) || undefined,
                                                            GrossMonthlyIncome: parseFloat(data.GrossMonthlyIncome).toFixed(2) || undefined,
                                        } 
                                        : {})

            //COAPPLICANT
            const coData = props.appData.CoApplicant
            setCoApplicant(props.appData.CoApplicant ? {...coData,  ResidenceMonthlyPayment: parseFloat(coData.ResidenceMonthlyPayment).toFixed(2) || undefined,
                                                                    HomeValue: parseFloat(coData.HomeValue).toFixed(2) || undefined,
                                                                    GrossMonthlyIncome: parseFloat(coData.GrossMonthlyIncome).toFixed(2) || undefined,
            
                                            } 
                                            : {})
            //HASCOAPPLICANT
            setHasCoapplicant(props.appData ? props.appData.CoApplicant ? true :false : false)

            
         
        }
    },[props.appData])




      //LLENAR INCOMES APPLICANT Y COAPPLICANT PARA MOSTRAR EN DETAILS
      useEffect(()=>{

        if(props.hasOwnProperty('appData') && Object.keys(props.appData).length > 0){

         
            //INCOMES
            if (props.appData.Applicant.AdditionalIncomeSources) {
                //setIncomeList([])
                const errors = props.appData.Applicant.AdditionalIncomeSources
                const numErrors = errors.map(item => { return {} })
                setErrorIncomes(numErrors)
                const newIncomeList = props.appData.Applicant.AdditionalIncomeSources.map(item => {return {...item, Amount : parseFloat(item.Amount).toFixed(2)}})
                setIncomeList(newIncomeList)

            }

            //INCOMES COAPPLICANT
            if (props.appData.CoApplicant) {
                if (props.appData.CoApplicant.AdditionalIncomeSources) {

                    const errors = props.appData.CoApplicant.AdditionalIncomeSources
                    const numErrors = errors.map(item => { return {} })
                    setErrorIncomesCoApplicant(numErrors)
                    const newIncomeList = props.appData.CoApplicant.AdditionalIncomeSources.map(item => {return {...item, Amount : parseFloat(item.Amount).toFixed(2)}})
                    setIncomeListCoApplicant(newIncomeList)
                }
            }

        }
    }, [props.appData])




     //hascoapplicant value
     useEffect(() => {
        if (hasCoApplicant === true) {
            setApplicant({
                ...applicant,
                HasCoApplicant: true,
            })

        } else if (hasCoApplicant === false) {
            setApplicant({
                ...applicant,
                HasCoApplicant: false,
            })
        }
    }, [hasCoApplicant]);



    //VALIDAR Y LLENAR INFO DE PROPOSAL
    useEffect(()=>{
        if(proposalInfo.hasInfo && !props.dontRevalidateProposalInfo){
            // console.log(proposalInfo.info)

            const userInfo = {
                FirstName: proposalInfo.info.FirstName,
                LastName: proposalInfo.info.LastName,
                EmailAddress: proposalInfo.info.EmailAddress,
                Address1: proposalInfo.info.Address1,
                City: proposalInfo.info.City,
                State: proposalInfo.info.State,
                PostalCode: proposalInfo.info.PostalCode,
                CellPhone: proposalInfo.info.CellPhone,
                BusinessPhone: proposalInfo.info.BusinessPhone,
                SocialSecurityNumber: proposalInfo.info.SocialSecurityNumber || applicant.SocialSecurityNumber,
                DateOfBirth: proposalInfo.info.DateOfBirth
            }

            setApplicant({
                ...applicant,
                ...userInfo,
                IdentificationType : proposalInfo.info.FinancialLender === 'FI-0002' ? 'Unavailable' : undefined
            })

            setErrors(validateForm(userInfo, false));

        }
    
    },[proposalInfo])




    // RELLENAR APPLICANT EXISTENTE - APLICACION CREADA A PARTIR DE OTRA APLICACION EN EL BOTON + 
    useEffect(()=>{

    if(props.urlParamApplicantId){
        
        setIsLoadingDetails(true);
        

    const abortController = new AbortController();
    const signal = abortController.signal;

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GET_APPLICANT_DETAILS}${props.urlParamApplicantId}`
    
    

    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token, 
            'x-api-key' : URI_X_API_KEY_I,
                    
            }
    })
    .then(response => response.json())
    .then(datos => {
        setIsLoadingDetails(false);


        // console.log(datos)

        setApplicant({...datos, 
                    BusinessPhone: (datos.BusinessPhone !== datos.CellPhone) ? datos.BusinessPhone : undefined, 
                    HasCoApplicant : false
                })

        setHasCoapplicant(false)
        
         //INCOMES
         if (datos.AdditionalIncomeSources) {
            //setIncomeList([])
            const errors = datos.AdditionalIncomeSources
            const numErrors = errors.map(item => { return {} })
            setErrorIncomes(numErrors)
            setIncomeList(datos.AdditionalIncomeSources)
        }
    
        
        

        setErrorFlagFromContext(false)
        
    }).catch(error => {
        console.log(error)

        if (errorFlagFromContext === false) {
            changeToken().then(response => console.log(response)).catch(error => console.log(error))
        }
            
    });

    return function cleanup(){
        abortController.abort();
    }
    }  

    },[reloadFetchFromContext]);



















    /*----------------------------------------- HANDLE INPUTS APPLICANT COAPPLICANT LOAN------------------------------------- */

    //inputs applicant
    const handleInputApplicant = (event, inputName) => {
        let value = event.target && event.target.value;



        if (event.target && event.target.name === "EmploymentType") {
            if (value === "Unemployed" || value === "Retired") {
                delete applicant.CurrentEmployer;
                delete applicant.CurrentEmployerPhoneNumber;
                delete applicant.EmploymentMonth;
                delete applicant.EmploymentYear;
                delete applicant.Occupation;
                delete applicant.EmploymentStartDate;
                delete applicant.GrossMonthlyIncome
            } else if (value === 'SelfEmployed') {
                delete applicant.CurrentEmployer;
            }
        }

        if (inputName === "CellPhone" || inputName === "BusinessPhone" || inputName === "SocialSecurityNumber") {
            setApplicant({
                ...applicant,
                [inputName]: event.value,
            })
        }else{
            setApplicant({
                ...applicant,
                [event.target.name]: value,
            })
        }
        
     
     


        
    }


    //inputs coapplicant
    const handleInputCoApplicant = (event, inputName) => {
        let value = event.target && event.target.value;

        if (event.target && event.target.name === "EmploymentType") {
            if (value === "Unemployed" || value === "Retired") {

                delete coApplicant.CurrentEmployer;
                delete coApplicant.CurrentEmployerPhoneNumber;
                delete coApplicant.EmploymentMonth;
                delete coApplicant.EmploymentYear;
                delete coApplicant.Occupation;
                delete coApplicant.HireDate;
                delete coApplicant.GrossMonthlyIncome;
            } else if (value === 'SelfEmployed') {
                delete coApplicant.CurrentEmployer;
            }
            value = value

        }


        if (inputName === "CellPhone" || inputName === "BusinessPhone" || inputName === "SocialSecurityNumber") {
            setCoApplicant({
                ...coApplicant,
                [inputName]: event.value,
            })
        }else{
            setCoApplicant({
                ...coApplicant,
                [event.target.name]: value,
            })
        }
    }

    //input select coapplicant
    const handleInputHasCoApplicant = event => {

        if (event.target.value === "true") {
            setHasCoapplicant(true)
        } else if (event.target.value === "false") {
            setHasCoapplicant(false)
        }

        setHasCoapplicantValue({
            ...hasCoApplicantValue,
            [event.target.name]: event.target.value
        })

    }



   



    //set CoApplicantType
    const setCoApplicantType = () => {
        setCoApplicant({
            ...coApplicant,
            CoApplicantType: coApplicant.ApplicantRelationship,
        })


    }



    //BANDERAS TRUE ISPRESENT IDENTIFICATION APPLICANT
    useEffect(()=>{
        if(props.type === 'create'){

            if( applicant.IdentificationType === 'StateId' || applicant.IdentificationType === 'UnitedStatesMilitaryId' ){
                setApplicant({
                    ...applicant,
                    'IsIssueDatePresent': true,
                    'IsIssueStatePresent' : true,
                    'ExpirationDateUnavailable' : false
                })
            }else if(applicant.IdentificationType === 'DriversLicense'){
                setApplicant({
                    ...applicant,
                    'IsIssueDatePresent': true,
                    'IsIssueStatePresent' : true,
                })
            }
            else if(applicant.IdentificationType === 'UnitedStatesPassport'){
                setApplicant({
                    ...applicant,
                    'IsIssueDatePresent': true,
                    'IsIssueStatePresent' : null,  
                })
            }else if(applicant.IdentificationType === 'PermanentResidencyCard' || applicant.IdentificationType === 'Unavailable'  ){
                setApplicant({
                    ...applicant,
                    'IsIssueDatePresent': null,
                    'IsIssueStatePresent' : null, 
                })
            }

  

           
        }
    }, [applicant.IdentificationType])




    //BANDERAS TRUE ISPRESENT IDENTIFICATION COAPPLICANT
    useEffect(()=>{
        if(props.type === 'create'){
    
            if( coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'UnitedStatesMilitaryId' ){
                setCoApplicant({
                    ...coApplicant,
                    'IsIssueDatePresent': true,
                    'IsIssueStatePresent' : true,
                    'ExpirationDateUnavailable' : false
                })
            }else if(coApplicant.IdentificationType === 'DriversLicense'){
                setCoApplicant({
                    ...coApplicant,
                    'IsIssueDatePresent': true,
                    'IsIssueStatePresent' : true,
                })
            }else if(coApplicant.IdentificationType === 'UnitedStatesPassport'){
                setCoApplicant({
                    ...coApplicant,
                    'IsIssueDatePresent': true,
                    'IsIssueStatePresent' : null,
                })
            }else if(coApplicant.IdentificationType === 'PermanentResidencyCard' || coApplicant.IdentificationType === 'Unavailable'  ){
                setCoApplicant({
                    ...coApplicant,
                    'IsIssueDatePresent': null,
                    'IsIssueStatePresent' : null,
                })
            }

           
        }
    }, [props.type === 'create' ? coApplicant.IdentificationType : null])

    /*--------------------------------------------------------------------------------------------------------------------- */

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 



    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    /*----------------------------------------- VALIDAR FORMULARIOS ------------------------------------- */

    //validate applicand and coapplicant
    const validateForm = (data, isCoApplicant) => {
        let errorList = {};




        //FirstName - text
        if (!data.FirstName) {
            errorList.FirstName = "Please enter a name";
        // } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(data.FirstName)) {
        } else if (!/^[a-zA-Z\s\-]*$/.test(data.FirstName)) {
            errorList.FirstName = "The name can only contain letters";
        }

        //MiddleInitial
        if (data.MiddleInitial) {
            if (!/^[a-zA-Z]{1}$/.test(data.MiddleInitial)) {
                errorList.MiddleInitial = "Can only contain one letter";
            }
        }

        //LastName - text
        if (!data.LastName) {
            errorList.LastName = "Please enter a lastname";
        // } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(data.LastName)) {
        } else if (!/^[a-zA-Z\s\-]*$/.test(data.LastName)) {
            errorList.LastName = "The lastname can only contain letters";
        }



        //SocialSecurityNumber - number
        if (!data.SocialSecurityNumber) {
            errorList.SocialSecurityNumber = "Please enter a Social Security Number";
        } else if (!/^[0-9]{9}$/.test(data.SocialSecurityNumber)) {
            errorList.SocialSecurityNumber ="The social security number must have 9 numbers";
        } else if(isCoApplicant && (data.SocialSecurityNumber === applicant.SocialSecurityNumber)){
            errorList.SocialSecurityNumber ="The social security number must be different than the applicant";
        } 

        //Address1 - text
        if (!data.Address1) {
            errorList.Address1 = "Please enter a address";
        }

        //City - text
        if (!data.City) {
            errorList.City = "Please enter a city";
        } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ-\s\.]+$/.test(data.City)) {
            errorList.City = "The city can only contain letters";
        }


        //DateOfBirth - date
        if (!data.DateOfBirth) {
            errorList.DateOfBirth = "Please enter a date";
        } else if (!data.DateOfBirth.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
            if (isSafari) {
                errorList.DateOfBirth = "The format must be yyyy-mm-dd";
            } else {
                errorList.DateOfBirth = "The format must be dd/mm/yyyy";
            }
        } else if (data.DateOfBirth) {
            let dob = new Date(data.DateOfBirth);
            const nowDate = new Date();
            nowDate.setFullYear(nowDate.getFullYear() - 18);
            if (dob > nowDate) {
                errorList.DateOfBirth = "Must be over 18 years old";
            }else if(dob.getFullYear() < 1900){
                errorList.DateOfBirth = "Please enter a valid date";
            }

           
        }
       


        //State - select
        if (!data.State) {
            errorList.State = "Please select an option";
        } else if (data.State === "State") {
            errorList.State = "Please select an option";
        }

        //PostalCode - number
        if (!data.PostalCode) {
            errorList.PostalCode = "Please enter a value";
        } else if (!/^[0-9]{5}$/.test(data.PostalCode)) {
            errorList.PostalCode = "The value must have 5 numbers";
        }


        //PostalCodePlus4 - number *
        if (data.PostalCodePlus4) {
            if (!/^[0-9]{4}$/.test(data.PostalCodePlus4)) {
                errorList.PostalCodePlus4 = "The value must have 4 numbers";
            }
        }


        //CoApplicantType - select
        if (hasCoApplicant && isCoApplicant) {
            if (!data.CoApplicantType) {
                errorList.CoApplicantType = "Please select an option";
            }
        }

        //HomePhone - number
        // if (!data.HomePhone) {
        //     errorList.HomePhone = "Please enter a HomePhone";
        // } else if (!/^[0-9]{10}$/.test(data.HomePhone)) {
        //     errorList.HomePhone = "The HomePhone must have 10 numbers";
        // }


        //CellPhone - number
        if (!data.CellPhone) {
            errorList.CellPhone = "Please enter a primary phone";
        } else if (!/^[0-9]{10}$/.test(data.CellPhone)) {
            errorList.CellPhone = "The CellPhone must have 10 numbers";
        } else if (data.CellPhone === '0000000000') {
            errorList.CellPhone = "Please enter a valid phone number";
        }
        // else if (data.CellPhone[0] == 1 || data.CellPhone[0] == 0) {
        //     errorList.CellPhone = "Please enter a valid phone number";
        // }

        //BusinessPhone *
        if(data.BusinessPhone){
            if (data.BusinessPhone === data.CellPhone) {
                errorList.BusinessPhone = "Phone numbers must be different";
            }else if (!/^[0-9]{10}$/.test(data.BusinessPhone)) {
                errorList.BusinessPhone = "The Business Phone must have 10 numbers";
            } else if (data.BusinessPhone === '0000000000') {
                errorList.BusinessPhone = "Please enter a valid phone number";
            }
        }
        // if (!data.BusinessPhone) {
        //     errorList.BusinessPhone = "Please enter a Business Phone";
        // } else if (!/^[0-9]{10}$/.test(data.BusinessPhone)) {
        //     errorList.BusinessPhone = "The Business Phone must have 10 numbers";
        // } else if (data.BusinessPhone === '0000000000') {
        //     errorList.BusinessPhone = "Please enter a valid phone number";
        // } else if (data.BusinessPhone === data.CellPhone) {
        //     errorList.BusinessPhone = "Phone numbers must be different";
        // }






        if (!data.EmailAddress) {
            errorList.EmailAddress = "Please enter a email";
        } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(data.EmailAddress)) {
            errorList.EmailAddress = "Please enter a valid email";
        }


        //EMPLOY

        //EmploymentType - select
        if (!data.EmploymentType) {
            errorList.EmploymentType = "Please select an option";
        } else if (data.EmploymentType === "Employment Type") {
            errorList.EmploymentType = "Please select an option";
        }

        //CurrentEmployer - text
        if (data.EmploymentType === "Unemployed" || data.EmploymentType === "Retired" || data.EmploymentType === "SelfEmployed") {
            //console.log('ok')
        } else {
            if (!data.CurrentEmployer) {
                errorList.CurrentEmployer = "Please add your current job";
            } else if (data.CurrentEmployer.length < 3) {
                errorList.CurrentEmployer = "Employer name must be at least 3 characters long";
            }
        }



        //EmploymentYear - date
        if (!data.HireDate) {
            errorList.HireDate = "Please enter a date";
        } else if (!data.HireDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
            if (isSafari) {
                errorList.HireDate = "The format must be yyyy-mm-dd";
            } else {
                errorList.HireDate = "The format must be dd/mm/yyyy";
            }
        } else if (data.HireDate) {
            let dob2 = new Date(data.HireDate);
            const nowDate = new Date();

            const a = moment(data.HireDate, "YYYY-MM-DD"); 
            const b = moment(data.DateOfBirth, "YYYY-MM-DD"); 
            const difference = a.diff(b, 'years') // 1

            if (nowDate < dob2) {
                errorList.HireDate = "The date must be in the past";
            }else if(difference < 16){
                errorList.HireDate = "Start date must be at least 16 years after the birthday date";
            }  
            
        }

        //Occupation - text
        if (data.EmploymentType === "Unemployed" || data.EmploymentType === "Retired") {
            // console.log('ok')
        } else {
            if (!data.Occupation) {
                errorList.Occupation = "Please add your occupation";
            }
        }


        //EmploymentState - text  
        if (!data.EmployerState) {
            errorList.EmployerState = "Please select an option";
        }




        //GrossMonthlyIncome - number
        if (data.EmploymentType === "Unemployed" || data.EmploymentType === "Retired") {
            
        } else {
            if (!data.GrossMonthlyIncome) {
                errorList.GrossMonthlyIncome = "Please enter the value of your income";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(data.GrossMonthlyIncome)) {
                errorList.GrossMonthlyIncome = "The value can only contain numbers";
            }else if (data.GrossMonthlyIncome == 0) {
                errorList.GrossMonthlyIncome = "The value must be greater than 0";
            }else if(data.GrossMonthlyIncome > 1000000){
                errorList.GrossMonthlyIncome = "Monthly Income must be <= 1,000,000";
            }
        }

        
        if(!isCoApplicant){
            if(incomeList.length === 0 && !data.GrossMonthlyIncome){
                errorList.Incomes = "Please enter the value of your income"; 
            }
            
            if(calculateTotalIncome() > 1000000){
                errorList.TotalIncomes = "Monthly Income must be <= 1,000,000";
            }
        }else{
            if(incomeListCoApplicant.length === 0 && !data.GrossMonthlyIncome){
                errorList.Incomes = "Please enter the value of your income"; 
            }
            
            if(calculateTotalIncome(true) > 1000000){
                errorList.TotalIncomes = "Monthly Income must be <= 1,000,000";
            }
        }
       





        //HomeValue - number
        if (data.ResidenceType !== 'Rent') {
            if (!data.HomeValue || data.HomeValue === '' ||  data.HomeValue === "NaN") {
                errorList.HomeValue = "Please enter a value";
            } else if(data.HomeValue <= 0){
                errorList.HomeValue ="Value must be greater than 0";
            }
        }


        //ResidenceType - select
        if (!data.ResidenceType) {
            errorList.ResidenceType = "Please select an option";
        } else if (data.ResidenceType === "Residence Type") {
            errorList.EmploymentType = "Please select an option";
        }


        //ResidenceMonthlyPayment - number
        if (data.ResidenceType !== 'OwnedFreeAndClear') {
            if (!data.ResidenceMonthlyPayment || data.ResidenceMonthlyPayment === "NaN") {
                errorList.ResidenceMonthlyPayment = "Please enter a value";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(data.ResidenceMonthlyPayment)) {
                errorList.ResidenceMonthlyPayment = "The value can only contain numbers";
            }
        }


        //LivingSinceCurrentResidence   
        if (!data.LivingSinceCurrentResidence) {
            errorList.LivingSinceCurrentResidence = "Please enter a date";
        } else if (!data.LivingSinceCurrentResidence.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
            if (isSafari) {
                errorList.LivingSinceCurrentResidence = "The format must be yyyy-mm-dd";
            } else {
                errorList.LivingSinceCurrentResidence = "The format must be dd/mm/yyyy";
            }
        } else if (data.LivingSinceCurrentResidence) {
            let dob = new Date(data.LivingSinceCurrentResidence);
            const nowDate = new Date();
            if (nowDate < dob) {
                errorList.LivingSinceCurrentResidence = "The date must be in the past";
            }else if(dob.getFullYear() < 1900){
                errorList.DateOfBirth = "Please enter a valid date";
            }
        }


        //LivingSinceCurrentResidence   
        if (!data.IdentificationType) {
            errorList.IdentificationType = "Please select an option";
        }



        //IdentificationType - select
        if (data.IdentificationType) {

            //IdentificationNumber - text
            if (data.IdentificationType === "Unavailable") {
                //console.log('ok')
            } else {
                if (!data.IdentificationNumber) {
                    errorList.IdentificationNumber = "Please enter an identification number";
                } else if(data.IdentificationNumber && data.IdentificationNumber.length > 20){
                    errorList.IdentificationNumber = "The identification number must be less than 20 characters";
                }
                else if (!/^[a-zA-Z0-9,-\s]*$/.test(data.IdentificationNumber)) {
                    errorList.IdentificationNumber = "The value can only contain numbers, letters, spaces, or dashes";
                }


            }



            if (data.IdentificationType === "Unavailable" || data.IsIssueDatePresent === 'false' || data.IsIssueDatePresent === false) {
                //console.log('ok')
            } else {
                //IdentificationIssuedDate - date
                if (!data.IdentificationIssuedDate) {
                    errorList.IdentificationIssuedDate = "Please enter a date";
                } else if (!data.IdentificationIssuedDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
                    if (isSafari) {
                        errorList.IdentificationIssuedDate = "The format must be yyyy-mm-dd";
                    } else {
                        errorList.IdentificationIssuedDate = "The format must be dd/mm/yyyy";
                    }
                } else if (data.IdentificationIssuedDate) {
                    let dob2 = new Date(data.IdentificationIssuedDate);
                    const nowDate = new Date();
                    if (nowDate < dob2) {
                        errorList.IdentificationIssuedDate = "The date must be in the past";
                    }
                }
            }




            //IdentificationExpirationDate - date
            if ((applicant.IdentificationType === "Unavailable" || applicant.ExpirationDateUnavailable === 'true') && (applicant.IdentificationType !== "DriversLicense" && applicant.IdentificationType !== "UnitedStatesPassport" && applicant.IdentificationType !== "PermanentResidencyCard" )) {
            } else {
                if (!data.IdentificationExpirationDate) {
                    errorList.IdentificationExpirationDate = "Please enter a date";
                } else if (!data.IdentificationExpirationDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
                    if (isSafari) {
                        errorList.IdentificationExpirationDate = "The format must be yyyy-mm-dd";
                    } else {
                        errorList.IdentificationExpirationDate = "The format must be dd/mm/yyyy";
                    }
                } else if (data.IdentificationExpirationDate) {
                    let dob3 = new Date(data.IdentificationExpirationDate);
                    const nowDate = new Date();
                    if (nowDate > dob3) {
                        errorList.IdentificationExpirationDate = "The date must be in the future";
                    }
                }
            }






           


            //IsIssueDatePresent
            if (data.IdentificationType === "Unavailable" || data.IdentificationType === "PermanentResidencyCard") {
                //console.log('ok')
            } else {
                if (props.application_id) {
                    if ((!data.IsIssueDatePresent && !data.IsIssueDatePresent === false) || data.IsIssueDatePresent === 'disable') {
                        errorList.IsIssueDatePresent = "Please select an option";
                    }
                } else if (data.IsIssueDatePresent === null || data.IsIssueDatePresent === undefined || data.IsIssueDatePresent === 'disable') {
                    errorList.IsIssueDatePresent = "Please select an option";
                }

            }

            //IsIssueStatePresent
            if (data.IdentificationType === "Unavailable" || data.IdentificationType === "UnitedStatesPassport" || data.IdentificationType === "PermanentResidencyCard" || data.IdentificationType === "UnitedStatesMilitaryId") {
                //console.log('ok')
            } else {
                if (props.application_id) {
                    if ((!data.IsIssueStatePresent && !data.IsIssueStatePresent === false) || data.IsIssueStatePresent === 'disable') {
                        errorList.IsIssueStatePresent = "Please select an option";
                    }
                } else if (data.IsIssueStatePresent === null || data.IsIssueStatePresent === undefined || data.IsIssueStatePresent === 'disable') {
                    errorList.IsIssueStatePresent = "Please select an option";
                }
            }




            if (data.IdentificationType === "Unavailable" || data.IdentificationType === "UnitedStatesPassport" || data.IdentificationType === "PermanentResidencyCard"  || data.IdentificationType === "UnitedStatesMilitaryId" || data.IsIssueStatePresent === 'false' || data.IsIssueStatePresent === false) {
                //console.log('ok')
            } else {
                //IdentificationIssueState - select

                if (!data.IdentificationIssueState) {
                    errorList.IdentificationIssueState = "Please select an option";
                } else if (
                    data.IdentificationIssueState === "Identification Issue State"
                ) {
                    errorList.IdentificationIssueState = "Please select an option";
                }


            }


            //ExpirationDateUnavailable
            if (data.IdentificationType === "Unavailable" || data.IdentificationType === "DriversLicense" ||  data.IdentificationType === "UnitedStatesPassport" || data.IdentificationType === "PermanentResidencyCard") {
                //console.log('ok')
            } else {
                if (props.application_id) {
                    if ((!data.ExpirationDateUnavailable && !data.ExpirationDateUnavailable === false) || data.ExpirationDateUnavailable === 'disable') {
                        errorList.ExpirationDateUnavailable = "Please select an option";
                    }
                } else if (data.ExpirationDateUnavailable === null || data.ExpirationDateUnavailable === undefined || data.ExpirationDateUnavailable === 'disable') {
                    errorList.ExpirationDateUnavailable = "Please select an option";
                }
            }


        }


        if (sendACHToo && !isCoApplicant) {

            //BankAccountType
            if (!data.BankAccountType) {
              errorList.BankAccountType = "Please select an option";
            }
      
      
            //BankRoutingNumber *
            if (!data.BankRoutingNumber && data.BankRoutingNumber !== '') {
              errorList.BankRoutingNumber = "Please enter a value";
            } else if (!/^[0-9]{9}$/.test(data.BankRoutingNumber)) {
              errorList.BankRoutingNumber = "The Bank Routing Number must have 9 numbers";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(data.BankRoutingNumber)) {
              errorList.BankRoutingNumber = "The value can only contain numbers";
            }
      
            //BankAccountNumber *
            if (!data.BankAccountNumber && data.BankAccountNumber !== '') {
              errorList.BankAccountNumber = "Please enter a value";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(data.BankAccountNumber)) {
              errorList.BankAccountNumber = "The value can only contain numbers";
            }
      
      
          }



























        return errorList;
    };


    const calculateTotalIncome = (isCoapplicant) => {
        let total = 0
        if(!isCoapplicant){
            if(applicant.GrossMonthlyIncome){
                total = total + parseFloat(applicant.GrossMonthlyIncome)
            }
    
            if(incomeList.length){
                incomeList.forEach(income => {
                    total = total + parseFloat(income.Amount)
                })
            }
            return parseInt(total)
        }else{
            if(coApplicant.GrossMonthlyIncome){
                total = total + parseFloat(coApplicant.GrossMonthlyIncome)
            }
    
            if(incomeListCoApplicant.length){
                incomeListCoApplicant.forEach(income => {
                    total = total + parseFloat(income.Amount)
                })
            }
            return parseInt(total)
        }
        
       
    }


    /*------------------------------------------------------------------------------------------------- */
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

    /*----------------------------------------- SUBMIT FORMULARIOS------------------------------------- */
    //submit applicant
    const handleSubmitApplicant = event => {
        event.preventDefault();
        //handle errors
        setErrors(validateForm(applicant, false));
        setIsSubmitting(true);
        setErrorIncomes(validateIncome(incomeList))
        setIsSubmitingIncomeApplicant(!isSubmitiongIncomeApplicant)

    };


    //submit coapplicant
    const handleSubmitCoApplicant = event => {
        event.preventDefault();
        //handle errors
        setErrorsCoApplicant(validateForm(coApplicant, true));
        setErrorIncomesCoApplicant(validateIncome(incomeListCoApplicant));
        setIsSubmittingCoApplicant(true);
        setIsSubmitingIncomeCoApplicant(!isSubmitiongIncomeCoApplicant)
    };


    /*------------------------------------------------------------------------------------------------- */

    // 
    // 
    // 
    // 



    /*----------------------------------------- USE EFFECTS POST FORMULARIOS------------------------------------- */
    //post applicant
    useEffect(() => {

        // console.log(errors)

        let errInc = []

        errorIncomes.forEach(error => {
            if (Object.keys(error).length != 0) {
                errInc = [...errInc, false]
            }

        })


        if (Object.keys(errors).length === 0 && isSubmitting && errInc.length === 0) {
            setCoApplicantType()
            setStep(2)
            // console.log(applicant)

        } else{
            // console.log(errors)
            // console.log(applicant)

            //handleSubmitAllData();
        }


    }, [errors, isSubmitiongIncomeApplicant]);




    //post coapplicant
    useEffect(() => {

        // console.log(errorsCoApplicant)

        let errInc = []

        errorIncomesCoApplicant.forEach(error => {
            if (Object.keys(error).length != 0) {
                errInc = [...errInc, false]
            }
        })



        if (Object.keys(errorsCoApplicant).length === 0 && isSubmittingCoApplicant && errInc.length === 0) {
           
            handleSubmitAllData();
     
        }else{
            // console.log(errorsCoApplicant)
            // console.log(errorIncomesCoApplicant)
        } 
    }, [errorsCoApplicant, isSubmitiongIncomeCoApplicant]);





    /*------------------------------------------------------------------------------------------------- */

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

    const copyAddressData = () =>{
  

        if(copyAddresDataFromApplicant === false){
          setCoApplicant({
            ...coApplicant,
            Address1: applicant.Address1,
            City: applicant.City,
            State: applicant.State,
            PostalCode: applicant.PostalCode,   
             
        })
        }else{
          setCoApplicant({
            ...coApplicant,
            Address1: null,
            City: null,
            State: null,
            PostalCode: null,   
            
        })
        }
    
        setCopyAddressDataFromApplicant(!copyAddresDataFromApplicant)
        }

        


    const copyResidenceData = () =>{


    if(copyResidenceDataFromApplicant === false){
        setCoApplicant({
        ...coApplicant,
        HomeValue: applicant.HomeValue,
        LivingSinceCurrentResidence: applicant.LivingSinceCurrentResidence,
        ResidenceType: applicant.ResidenceType,
        ResidenceMonthlyPayment: applicant.ResidenceMonthlyPayment,   
            
    })
    }else{
        setCoApplicant({
        ...coApplicant,
        HomeValue: null,
        LivingSinceCurrentResidence: null,
        ResidenceType: null,
        ResidenceMonthlyPayment: null,   
        
    })
    }

    setCopyResidenceDataFromApplicant(!copyResidenceDataFromApplicant)
    }


    // const currencyMask = (value) => {
    //     let convert = value
    //     return parseFloat(convert).toFixed(2)
    // } 

    const stylesForInputMask = (error) => error ? "imputError" : "campo"


    /*----------------------------------------- STEPS Y TABS------------------------------------- */
    //set step
    const setStep1 = () => {
        setStep(1);
    };
    const setStep6 = () => {
        if (props.type === 'edit' || documentApplicationId) {
            setStep(6);
        }

    };
    const prevStep = () => {
        setStep(step - 1);
    };
    const restar = () => {
        setStep(1);
    };


    //steps for create new application
    const stepsCounter = () => {
        const styletitlemobile1 = ["stepmobiletittle"];
        const styletitlemobile2 = ["stepmobiletittle"];
        const styletitlemobile3 = ["stepmobiletittle"];

        const stylenumber1 = ["stepnumber"];
        const styletitle1 = ["steptittle"];
        const stylenumber2 = ["stepnumber"];
        const styletitle2 = ["steptittle"];
        const stylenumber3 = ["stepnumber"];
        const styletitle3 = ["steptittle"];

        if (step === 1) {
            stylenumber1.push("stepnumberactive");
            styletitle1.push("steptittleactive");
            styletitlemobile1.push("showstepmobiletittle");
        }
        if (step === 2) {
            stylenumber1.push("stepnumbercomplete");
            stylenumber2.push("stepnumberactive");
            styletitle2.push("steptittleactive");
            styletitlemobile2.push("showstepmobiletittle");
        }
        if (step === 6) {
            stylenumber1.push("stepnumbercomplete");
            stylenumber2.push("stepnumbercomplete");
            stylenumber3.push("stepnumberactive");
            styletitle3.push("steptittleactive");
            styletitlemobile3.push("showstepmobiletittle");
        }


        return (
            <div className="stepscontainer">
                <div className="stepsmobiletittlecontainer">


                    <h3 className={styletitlemobile1.join(" ")}>Applicant Information</h3>
                    <h3 className={styletitlemobile2.join(" ")}>CoApplicant Information</h3>
                    {/* <h3 className={styletitlemobile3.join(" ")}>Loan Parameters</h3> */}

                </div>


                <div className="stepsdesktopcontainer">
                    {step === 1 ? null : <img src={iconArrowBack} onClick={() => { setStep(1) }} className="backButton" />}

                    <div className="step">
                        <span className={stylenumber1.join(" ")}>1</span>
                        <h3 className={styletitle1.join(" ")}>Applicant Information</h3>
                    </div>
                    <div className="step">
                        <span className={stylenumber2.join(" ")}>2</span>
                        <h3 className={styletitle2.join(" ")}>
                            CoApplicant Information
              </h3>
                    </div>

                    {/* <div className="step">
              <span className={stylenumber3.join(" ")}>3</span>
              <h3 className={styletitle3.join(" ")}>Loan Parameters</h3>
            </div> */}

                </div>
            </div>
        );
    };


    //tabs for detail application
    const tabs = () => {

        return (

            <div className="applicationTabsContainer">
                <ul className="applicationTabsPinnacle">
                    <li className={step === 1 ? 'applicationTabActive' : 'applicationTab'} onClick={()=>{setStep(1)}}>Applicant</li>
                    {/* <li className={step===2 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep2}>CoApplicant</li>
            <li className={step===3 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep3}>Parameters</li>
            <li className={step===4 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep4}>Status</li>
            <li className={step===5 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep5}>Promotions</li> */}
                    <li className={step === 2 ? 'applicationTabActive' : 'applicationTab'} onClick={()=>{setStep(2)}}>CoApplicant</li>

                </ul>
            </div>

        );


    }
    /*------------------------------------------------------------------------------------------------- */


    /*----------------------------------------- INCOMES------------------------------------- */
    const addIncome = () => {

        setErrorIncomes([...errorIncomes, {}])

        setIncomeList([
            ...incomeList,
            {
                Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`,
                IsNonTaxableIncome: 'false'

            }
        ])
        //console.log(incomeList)

    }

    const addIncomeCoApplicant = () => {
        setErrorIncomesCoApplicant([...errorIncomesCoApplicant, {}])

        setIncomeListCoApplicant([
            ...incomeListCoApplicant,
            {
                Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`,
                IsNonTaxableIncome: 'false'
                //errors : [{}]
            }
        ])
        //console.log(incomeListCoApplicant)

    }


    const handleIncomeInput = (event, id) => {

        const newList = incomeList.map(income => {

            if (income.Id === id) {
                if (event.target.name === 'AdditionalIncomeSource') {
                    income.AdditionalIncomeSource = event.target.value
                } else if (event.target.name === 'Description') {
                    income.Description = event.target.value
                } else if (event.target.name === 'Amount') {
                    income.Amount = event.target.value
                } else if (event.target.name === 'Source') {
                    income.Source = event.target.value
                } else if (event.target.name === 'IsNonTaxableIncome') {
                    income.IsNonTaxableIncome = event.target.value
                }
                return income
            } else {
                return income
            }
        })
        setIncomeList(newList)
        //console.log(incomeList)
    }


    const maskIncomeInput = (id, applicant) => {
        if(applicant){
            const newList = incomeList.map(income => {
                if (income.Id === id) {
                    income.Amount = parseFloat(income.Amount).toFixed(2) 
                    return income
                } else {
                    return income
                }
            })
            setIncomeList(newList)
        }else{
            const newList = incomeListCoApplicant.map(income => {
                if (income.Id === id) {
                    income.Amount = parseFloat(income.Amount).toFixed(2) 
                    return income
                } else {
                    return income
                }
            })
            setIncomeListCoApplicant(newList)
        }
       
    }


    const handleIncomeInputCoApplicant = (event, id) => {

        const newList = incomeListCoApplicant.map(income => {

            if (income.Id === id) {
                if (event.target.name === 'AdditionalIncomeSource') {
                    income.AdditionalIncomeSource = event.target.value
                } else if (event.target.name === 'Description') {
                    income.Description = event.target.value
                } else if (event.target.name === 'Amount') {
                    income.Amount = event.target.value
                } else if (event.target.name === 'Source') {
                    income.Source = event.target.value
                } else if (event.target.name === 'IsNonTaxableIncome') {
                    income.IsNonTaxableIncome = event.target.value
                }


                return income

            } else {
                return income
            }


        })

        setIncomeListCoApplicant(newList)

        //console.log(incomeListCoApplicant)
    }


    const deleteIncome = (id, index) => {

        const newList = incomeList.filter(income => income.Id !== id)
        setIncomeList(newList);

        const newErrorList = errorIncomes.filter((error, i) => errorIncomes.indexOf(error) !== index)
        setErrorIncomes(newErrorList)

    }


    const deleteIncomeCoApplicant = (id) => {

        const newList = incomeListCoApplicant.filter(income => income.Id !== id)
        setIncomeListCoApplicant(newList)

    }


    const validateIncome = (incomeList) => {


        const errorIncomeList = incomeList.map((item) => {


            let errorList = {};

            //AdditionalIncomeSource - select
            if (!item.AdditionalIncomeSource) {
                errorList.AdditionalIncomeSource = "Please select an option";
            }

            //Description - text
            if (!item.Description) {
                errorList.Description = "Please enter a description";
            }else if(item.Description.length > 20){
                errorList.Description = "Description can be maximum 20 characters long";
            }


            //Amount - number
            if (!item.Amount) {
                errorList.Amount = "Please enter a value";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(item.Amount)) {
                errorList.Amount = "The value can only contain numbers";
            }else if (item.Amount == 0) {
                errorList.Amount = "The value should be greater than 0";
            }


            if (errorList.lenght !== 0) {
                return errorList;
            }


        })

        //incomeList.errors = errorIncomeList;

        //console.log(errorIncomeList)



        //return errorIncomeList
        if (errorIncomeList.length === 0) {
            //console.log(errorIncomeList)
            const vacio = []
            return vacio
        } else {
            //console.log(errorIncomeList)
            return errorIncomeList
        }


    }

    /*------------------------------------------------------------------------------------------------- */

















    switch (step) {
        default:
            return (
                <div>
                    <h1>breack</h1>
                    <button onClick={restar} value="Next">
                        Next
              </button>
                </div>
            );
        case 1:

            return (
                <>

                    <div className="documentSectionContentMain">

                       

                     
                        {!props.hasOwnProperty('detailsId') && stepsCounter()}
                        {/* {stepsCounter()} */}
                       

                        {isLoadingDetails ?
                        <div className="spinnerDetails"><Spinner
                            radius={35}
                            color={"grey"}
                            stroke={5}
                            visible={true}
                        /></div> : null}



                        <div className="applicationFormContainer">

                            {/* {
                                props.hasOwnProperty('detailsId') &&
                                <div className="externalForm_toggleLenderContainer">
                                    <div className="externalForm_toggleLender-option">
                                        <p className="externalForm_toggleLender-text">Send to:</p>
                                        <p className="externalForm_toggleLender-lenderTitle">AquaFinance</p>
                                    </div>
                                    <div className="externalForm_toggleLender-option">
                                        <p className="externalForm_toggleLender-text">Send to:</p>
                                        <p className="externalForm_toggleLender-lenderTitle">Pinnacle</p>
                                    </div>
                                </div>
                            } */}
                           
                            
                            {props.internalTabs && tabs()}
                            {props.hasOwnProperty('externalTabs') && props.externalTabs()}

                            {props.type === 'edit' && props.appData && props.appData.ApplicationId && <p className="applicationNumberTitle">{props.appData.ApplicationId}</p>}
                           

                            <h3 className="applicationFormContainer-tittle">
                                Main Information
                            </h3>
                            
                            <form action="" id="form" className="form">

                            

                                {/* <button onClick={() => { setStep(2) }}>NEXT</button> */}


                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >First Name</label>
                                    <input
                                        type="text"

                                        name="FirstName"
                                        onChange={handleInputApplicant}
                                        className={`${errors.FirstName ? "imputError" : "campo"}`}
                                        value={applicant.FirstName}
                                        placeholder="First name"
                                        disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                        required
                                    />
                                    {errors.FirstName && <p className="errorMessage">{errors.FirstName}</p>}
                                </div>


                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Middle Initial (*Optional)</label>
                                    <input
                                        type="text"
                                        name="MiddleInitial"
                                        onChange={handleInputApplicant}
                                        className={`${errors.MiddleInitial ? "imputError" : "campo"}`}
                                        value={applicant.MiddleInitial}
                                        placeholder="Only one letter"
                                        disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                        required
                                    />
                                    {errors.MiddleInitial && <p className="errorMessage">{errors.MiddleInitial}</p>}
                                </div>



                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Last Name</label>
                                    <input
                                        type="text"

                                        name="LastName"
                                        onChange={handleInputApplicant}
                                        className={`${errors.LastName ? "imputError" : "campo"}`}
                                        placeholder="Last Name"
                                        value={applicant.LastName}
                                        required
                                        disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    {errors.LastName && <p className="errorMessage">{errors.LastName}</p>}
                                </div>



                                {/* SocialSecurityNumber */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Social Security Number</label>
                                    {
                                        props.type === 'edit' ?
                                         <input
                                            //value={applicant.SocialSecurityNumber}
                                            value={props.type === 'edit' ? (applicant.SocialSecurityNumber ? ('*****' + applicant.SocialSecurityNumber.toString().substr(5,9)) : '') : applicant.SocialSecurityNumber}
                                            type="text"
                                            name="SocialSecurityNumber"
                                            onChange={handleInputApplicant}
                                            className={`${errors.SocialSecurityNumber ? "imputError" : "campo"}`}
                                            placeholder="Do not use dashes"
                                            required
                                            disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                        /> 
                                        :
                                        <NumberFormat
                                        value={props.type === 'edit' ? (applicant.SocialSecurityNumber ? ('*****' + applicant.SocialSecurityNumber.toString().substr(5,9)) : '') : applicant.SocialSecurityNumber}
                                        // value={applicant.SocialSecurityNumber}
                                        onValueChange={e => handleInputApplicant(e, 'SocialSecurityNumber')}
                                        displayType="input"
                                        type="text"
                                        // type={props.type === 'edit' ? "text" : "number"}
                                        name="SocialSecurityNumber"
                                        placeholder="Do not use dashes"
                                        format="###-##-####"
                                        // className={`${errors.CellPhone ? "imputError" : "campo"}`}
                                        className={stylesForInputMask(errors.SocialSecurityNumber)}
                                        disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    }
                                   
                                   
                                    {errors.SocialSecurityNumber && <p className="errorMessage">{errors.SocialSecurityNumber}</p>}
                                </div>




                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Email</label>
                                    <input
                                        type="email"

                                        name="EmailAddress"
                                        onChange={handleInputApplicant}
                                        className={`${errors.EmailAddress ? "imputError" : "campo"}`}
                                        placeholder="Email"
                                        value={applicant.EmailAddress}
                                        required
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    {errors.EmailAddress && <p className="errorMessage">{errors.EmailAddress}</p>}
                                </div>


                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Date of Birth</label>
                                    <input
                                        type="date"
                                        name="DateOfBirth"
                                        onChange={handleInputApplicant}
                                        className={`${errors.DateOfBirth ? "imputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        autocomplete="off"
                                        autocorrect="off"
                                        value={applicant.DateOfBirth}           
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    {errors.DateOfBirth && <p className="errorMessage">{errors.DateOfBirth}</p>}
                                </div>





                                {/* <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Address</label>
                                    <input
                                        type="text"
                                        value={applicant.Address1}
                                       
                                        name="Address1"
                                        onChange={handleInputApplicant}
                                        className={`${errors.Address1 ? "imputError" : "campo"}`}
                                        placeholder="Address"
                                        required
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    {errors.Address1 && <p className="errorMessage">{errors.Address1}</p>}
                                </div> */}


                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Address</label>
                                    <PlacesAutocomplete
                                        value={applicant.Address1}
                                        onChange={handleChangePlacesAutocomplete}
                                        onSelect={handleSelectPlacesAutocomplete}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({ placeholder: "Type address" })}
                                            placeholder="Address"
                                            className={`${errors.Address1 ? "imputError" : "campo"}`}
                                            autocomplete="off"
                                            disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                            />

                                            <div className={suggestions.length > 0 && "inputPlacesAutocomplete_optionsContainer"}>
                                            {loading ? <div>...loading</div> : null}

                                            {suggestions.map((suggestion) => {
                                                const style = {
                                                backgroundColor: suggestion.active ? "#e5e5e5" : "#fff",
                                                cursor: "pointer",
                                                marginBotton: '10ppx'
                                                };

                                                return (
                                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                                    {suggestion.description}
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {errors.Address1 && <p className="errorMessage">{errors.Address1}</p>}
                                </div>



                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >City</label>
                                    <input
                                        type="text"
                                        value={applicant.City}

                                        name="City"
                                        onChange={handleInputApplicant}
                                        className={`${errors.City ? "imputError" : "campo"}`}
                                        placeholder="City"
                                        required
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    {errors.City && <p className="errorMessage">{errors.City}</p>}
                                </div>



                                <div className="mediowidth-right campoContainer">
                                    <label className="labelApplicationForm" >State</label>
                                    <select
                                        value={applicant.State || 'selected'}
                                        name="State"
                                        onChange={handleInputApplicant}
                                        className={`${errors.State ? "imputError" : "campo"}`}
                                        placeholder="State"
                                        required
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    >
                                        <option value="selected" disabled>
                                            State
                                        </option>
                                        <option value="AK">AK</option>
                                        <option value="AL">AL</option>
                                        <option value="AR">AR</option>
                                        <option value="AZ">AZ</option>           
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>                 
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="IA">IA</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>          
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="MA">MA</option>
                                        <option value="MD">MD</option>
                                        <option value="ME">ME</option>      
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NV">NV</option>
                                        <option value="NY">NY</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VA">VA</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="WA">WA</option>
                                        <option value="WI">WI</option>
                                        <option value="WV">WV</option>
                                        <option value="WY">WY</option>   
                                    </select>
                                    {errors.State && <p className="errorMessage">{errors.State}</p>}
                                </div>



                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >{props.entity === 'aquaFinance' ? 'Postal Code' : 'Zip Code'}</label>
                                    <input
                                        type="number"
                                        value={applicant.PostalCode}

                                        name="PostalCode"
                                        onChange={handleInputApplicant}
                                        className={`${errors.PostalCode ? "imputError" : "campo"}`}
                                        placeholder={props.entity === 'aquaFinance' ? 'Postal Code' : 'Zip Code'}
                                        required
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    {errors.PostalCode && <p className="errorMessage">{errors.PostalCode}</p>}
                                </div>


                                {/* CellPhone */}
                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >Home/Cell Phone</label>
                                    <NumberFormat
                                        value={applicant.CellPhone}
                                        onValueChange={e => handleInputApplicant(e, 'CellPhone')}
                                        displayType="input"
                                        type="text"
                                        name="CellPhone"
                                        placeholder="Do not use dashes"
                                        format="(###) ### - ####"
                                        // className={`${errors.CellPhone ? "imputError" : "campo"}`}
                                        className={stylesForInputMask(errors.CellPhone)}
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                  
                                    {/* <input
                                        type="text"
                                        value={applicant.CellPhone}
                                        name="CellPhone"
                                        onChange={handleInputApplicant}
                                        className={`${errors.CellPhone ? "imputError" : "campo"}`}
                                        placeholder="Do not use dashes"
                                        required
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    /> */}
                                    {errors.CellPhone && <p className="errorMessage">{errors.CellPhone}</p>}
                                </div>







                                {/* BusinessPhone */}
                                <div className="mediowidth-right campoContainer">
                                    <label className="labelApplicationForm" >Business Phone (*Optional)</label>
                                    <NumberFormat
                                        value={applicant.BusinessPhone}
                                        onValueChange={e => handleInputApplicant(e, 'BusinessPhone')}
                                        displayType="input"
                                        type="text"
                                        name="BusinessPhone"
                                        placeholder="Do not use dashes"
                                        format="(###) ### - ####"
                                        // className={`${errors.CellPhone ? "imputError" : "campo"}`}
                                        className={stylesForInputMask(errors.BusinessPhone)}
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    />
                                    {/* <input
                                        type="number"
                                        value={applicant.BusinessPhone}
                                        name="BusinessPhone"
                                        onChange={handleInputApplicant}
                                        className={`${errors.BusinessPhone ? "imputError" : "campo"}`}
                                        placeholder="Do not use dashes"
                                        required
                                        disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                    /> */}
                                    {errors.BusinessPhone && <p className="errorMessage">{errors.BusinessPhone}</p>}
                                </div>

                            </form>









                            <h3 className="applicationFormContainer-tittle">
                            Identification Information
                            </h3>

                            
                            <form action="" id="form" className="form">
                            {props.type === 'create' && !props.fillOutApp &&
                                (props.iframe ?  
                                    
                                      <p className="fullwidth campoContainer">*Optional, Choose Unavailable.</p> 
                                    
                                    
                                    :  
                                    <p className="fullwidth campoContainer">*ID is required for Aqua.</p>)
                               
                            }




                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Type</label>
                                <select
                                value={applicant.IdentificationType}

                                name="IdentificationType"
                                onChange={handleInputApplicant}
                                className={`${errors.IdentificationType ? "imputError" : "campo"}`}
                                placeholder="Identification Type"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                <option value="default" disabled selected>
                                    Identification Type
                                </option>
                                <option value="Unavailable">Unavailable</option>
                                <option value="DriversLicense">Drivers License</option>
                                <option value="StateId">State Id</option>
                                <option value="UnitedStatesMilitaryId">United States Military Id</option>
                                <option value="UnitedStatesPassport">United States Passport</option>
                                <option value="PermanentResidencyCard">Permanent Residency Card</option>
                                </select>
                                {errors.IdentificationType && <p className="errorMessage">{errors.IdentificationType}</p>}
                            </div>



                            {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId' || applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'UnitedStatesPassport' || applicant.IdentificationType === 'PermanentResidencyCard'  ? 
                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Number</label>
                                <input
                                type="text"
                                value={applicant.IdentificationNumber}
                                name="IdentificationNumber"
                                onChange={handleInputApplicant}
                                className={`${errors.IdentificationNumber ? "imputError" : "campo"}`}
                                placeholder="Identification Number"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                />
                                {errors.IdentificationNumber && <p className="errorMessage">{errors.IdentificationNumber}</p>}
                            </div>
                            : null
                        
                        
                            }
                            

                            {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId' || applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'UnitedStatesPassport' ? 
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue Date Present</label>
                                <select
                                value={applicant.IsIssueDatePresent}
                                name="IsIssueDatePresent"
                                onChange={handleInputApplicant}
                                className={`${errors.IsIssueDatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue Date Present"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                <option value="disable" disabled selected>
                                Is Issue Date Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {errors.IsIssueDatePresent && <p className="errorMessage">{errors.IsIssueDatePresent}</p>}
                            </div>
                            : null
                            
                            
                            }




                            {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId' || applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'UnitedStatesPassport' || applicant.IdentificationType === 'PermanentResidencyCard' ?    
                                (applicant.IsIssueDatePresent === true || applicant.IsIssueDatePresent === 'true') || applicant.IdentificationType === 'PermanentResidencyCard' ?
                                        <div className={applicant.IdentificationType === 'PermanentResidencyCard' ? "fullwidth campoContainer" : "mediowidth-right campoContainer"}>
                                        <label className="labelApplicationForm" >Identification Issue Date</label>
                                            <input
                                            type="date"
                                            value={applicant.IdentificationIssuedDate}
                                            name="IdentificationIssuedDate"
                                            onChange={handleInputApplicant}
                                            className={`${errors.IdentificationIssuedDate ? "imputError" : "campo"}`}
                                            placeholder="yyyy-mm-dd"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                            />
                                            {errors.IdentificationIssuedDate && <p className="errorMessage">{errors.IdentificationIssuedDate}</p>}
                                        </div>
                                        :null
                                : null   
                                }
                            


                            {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId'  ?
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue State Present</label>
                                <select
                                value={applicant.IsIssueStatePresent}
                                name="IsIssueStatePresent"
                                onChange={handleInputApplicant}
                                className={`${errors.IsIssueStatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue State Present"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                <option value="disable" disabled selected>
                                Is Issue State Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                                

                                </select>
                                {errors.IsIssueStatePresent && <p className="errorMessage">{errors.IsIssueStatePresent}</p>}
                            </div>
                            : null
                            
                            
                            }


                        



                        {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'StateId'  || applicant.IdentificationType === 'PermanentResidencyCard'  ?

                            applicant.IsIssueStatePresent === true || applicant.IsIssueStatePresent === 'true' ?
                                <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Identification Issue State</label>
                                <select
                                    value={applicant.IdentificationIssueState}
                                    name="IdentificationIssueState"
                                    onChange={handleInputApplicant}
                                    className={`${errors.IdentificationIssueState ? "imputError" : "campo"}`}
                                    placeholder="Identification Issue State"
                                    required
                                    disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                    <option value="disable" disabled selected>
                                    Identification Issue State
                                    </option>
                                    <option value="AK">AK</option>
                                    <option value="AL">AL</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>           
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>                 
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="GU">GU</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>          
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>      
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MS">MS</option>
                                    <option value="MO">MO</option>
                                    <option value="MT">MT</option>
                                    <option value="NE">NE</option>
                                    <option value="NC">NC</option>
                                    <option value="ND">ND</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="PR">PR</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VA">VA</option>
                                    <option value="VI">VI</option>
                                    <option value="VT">VT</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>   
                                </select>
                                {errors.IdentificationIssueState && <p className="errorMessage">{errors.IdentificationIssueState}</p>}
                                </div>
                                :
                                null
                            : null
                        }


                        {applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'StateId'   ?
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Expiration Date Present</label>
                                <select
                                value={applicant.ExpirationDateUnavailable}
                                name="ExpirationDateUnavailable"
                                onChange={handleInputApplicant}
                                className={`${errors.ExpirationDateUnavailable ? "imputError" : "campo"}`}
                                placeholder="Is Expiration Date Present"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                <option value="disable" disabled selected>
                                Is Expiration Date Present
                                </option>
                                <option value="false">Yes</option>
                                <option value="true">No</option>
                                

                                </select>
                                {errors.ExpirationDateUnavailable && <p className="errorMessage">{errors.ExpirationDateUnavailable}</p>}
                            </div>
                            : null
                        
                            }


                            {applicant.IdentificationType === 'UnitedStatesMilitaryId' || applicant.IdentificationType === 'StateId'  ?
                                applicant.ExpirationDateUnavailable === false || applicant.ExpirationDateUnavailable === 'false' ?
                                <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Identification Expiration Date</label>
                                    <input
                                        type="date"
                                        value={applicant.IdentificationExpirationDate}
                                        name="IdentificationExpirationDate"
                                        onChange={handleInputApplicant}
                                        className={`${errors.IdentificationExpirationDate ? "imputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    />
                                    {errors.IdentificationExpirationDate && <p className="errorMessage">{errors.IdentificationExpirationDate}</p>}
                                </div>
                                : null
                            : null
                            
                            
                            }
                            


                            {applicant.IdentificationType === 'DriversLicense' || applicant.IdentificationType === 'UnitedStatesPassport' || applicant.IdentificationType === 'PermanentResidencyCard'?
                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Expiration Date</label>
                                <input
                                type="date"
                                value={applicant.IdentificationExpirationDate}
                                name="IdentificationExpirationDate"
                                onChange={handleInputApplicant}
                                className={`${errors.IdentificationExpirationDate ? "imputError" : "campo"}`}
                                placeholder="yyyy-mm-dd"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                />
                                {errors.IdentificationExpirationDate && <p className="errorMessage">{errors.IdentificationExpirationDate}</p>}
                            </div>
                            : null    
                            }
                            
                            
                            </form>















                            <h3 className="applicationFormContainer-tittle">
                                Employment Information
                            </h3>

                            <form action="" id="form" className="form">


                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Employment Type</label>
                                    <select
                                        value={applicant.EmploymentType}
                                        name="EmploymentType"
                                        onChange={handleInputApplicant}
                                        className={`${errors.EmploymentType ? "imputError" : "campo"}`}
                                        placeholder="Employment Type"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    >
                                        <option value="disable" disabled selected>
                                            Employment Type
                                        </option>
                                        <option value="Unemployed">Unemployed</option>
                                        <option value="Employed">Employed</option>
                                        <option value="Retired">Retired</option>
                                        <option value="SelfEmployed">Self Employed</option>

                                    </select>
                                    {errors.EmploymentType && <p className="errorMessage">{errors.EmploymentType}</p>}
                                </div>

                                {applicant.EmploymentType === 'Employed' ?
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Current Employer</label>
                                        <input
                                            type="text"
                                            value={applicant.CurrentEmployer}
                                            name="CurrentEmployer"
                                            onChange={handleInputApplicant}
                                            className={`${errors.CurrentEmployer ? "imputError" : "campo"}`}
                                            placeholder="Current Employer"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errors.CurrentEmployer && <p className="errorMessage">{errors.CurrentEmployer}</p>}
                                    </div>
                                    : null}



                                {applicant.EmploymentType === 'Employed' || applicant.EmploymentType === 'SelfEmployed' ?
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Occupation</label>
                                        <input
                                            type="text"
                                            value={applicant.Occupation}
                                            name="Occupation"
                                            onChange={handleInputApplicant}
                                            className={`${errors.Occupation ? "imputError" : "campo"}`}
                                            placeholder="Occupation"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errors.Occupation && <p className="errorMessage">{errors.Occupation}</p>}
                                    </div>
                                    : null}




                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >State</label>
                                    <select
                                        value={applicant.EmployerState || 'selected'}
                                        name="EmployerState"
                                        onChange={handleInputApplicant}
                                        className={`${errors.EmployerState ? "imputError" : "campo"}`}
                                        placeholder="Employment State"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    >
                                        <option value="selected" disabled>
                                            State
                                            </option>
                                            <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
                                    </select>
                                    {errors.EmployerState && <p className="errorMessage">{errors.EmployerState}</p>}
                                </div>





                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Start Date</label>
                                    <input
                                        type="date"
                                        value={applicant.HireDate}
                                        name="HireDate"
                                        onChange={handleInputApplicant}
                                        className={`${errors.HireDate ? "imputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    />
                                    {errors.HireDate && <p className="errorMessage">{errors.HireDate}</p>}
                                </div>



                                {applicant.EmploymentType === 'Employed' || applicant.EmploymentType === 'SelfEmployed' ?
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Gross Monthly Income</label>
                                        <input
                                            type="number"
                                            value={applicant.GrossMonthlyIncome}
                                            name="GrossMonthlyIncome"
                                            onChange={handleInputApplicant}
                                            onBlur={()=> { setApplicant({...applicant, 'GrossMonthlyIncome': parseFloat(applicant.GrossMonthlyIncome).toFixed(2) })   }}
                                            className={`${errors.GrossMonthlyIncome ? "imputError" : "campo"}`}
                                            placeholder="Gross Monthly Income"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errors.GrossMonthlyIncome && <p className="errorMessage">{errors.GrossMonthlyIncome}</p>}
                                    </div>
                                    : null}

                                    



                            </form>

                            {
                                ((applicant.EmploymentType === 'Unemployed' || applicant.EmploymentType === 'Retired') && props.type !== 'edit')  ?
                                <p className="applicationFormContainer-tittle">Must show income. Please add other monthly Income.</p>
                                : null
                            }


                            {errors.Incomes &&  <p className="applicationFormContainer-tittle textColor_red">Income must be greater than 0.</p>}

                            {errors.TotalIncomes &&  <p className="applicationFormContainer-tittle textColor_red">Total income must be equal to or less than 1,000,000</p>}
                            
                            
                              
                            
                            
                           



                           

                            {/*   AQUI COMIENZA OTHER INCOMES---------------------------------------------------------------------------------------- */}


                            <h3 className="applicationFormContainer-tittle">
                                Other Monthly Income
                            </h3>


                            {/* INCOME LISTS */}
                            {
                                incomeList.map((item, index) => {
                                    return (
                                        <div className="incomeCard" key={index}>



                                            {props.type === 'edit' ?
                                                null
                                                :
                                                <div className="mediowidth-right deleteIncome-icon_container">
                                                    <img src={iconDelete} className="deleteIncome-icon" onClick={() => { deleteIncome(item.Id, index) }} />
                                                </div>
                                            }

                                            <div className="mediowidth-left campoContainer">
                                                <label className="labelApplicationForm" >Additional Income Source</label>
                                                <select
                                                    value={item.AdditionalIncomeSource}
                                                    name="AdditionalIncomeSource"
                                                    onChange={(event) => { handleIncomeInput(event, item.Id) }}
                                                    className={`${errorIncomes[index].AdditionalIncomeSource ? "imputError" : "campo"}`}
                                                    // className={`${errorIncomes.includes(`${errorIncomes[index].AdditionalIncomeSource}`, index ) ? "imputError" : "campo"}`} 
                                                    placeholder="Additional Income Source"
                                                    required
                                                    disabled={props.type === 'edit' ? "disabled" : null}
                                                >
                                                    <option value="disable" disabled selected>
                                                        Additional Income Source
                                                    </option>
                                                    <option value="Other">Other</option>
                                                    <option value="RetirementSSI">Retirement SSI</option>
                                                    <option value="Investments">Investments</option>
                                                    <option value="Rental">Rental</option>
                                                </select>

                                                {errorIncomes[index].AdditionalIncomeSource && <p className="errorMessage">{errorIncomes[index].AdditionalIncomeSource}</p>}
                                                {/* {errorIncomes.includes('AdditionalIncomeSource', index ) && <p className="errorMessage">{errorIncomes[index].AdditionalIncomeSource}</p>} */}
                                            </div>




                                            <div className="mediowidth-right campoContainer">
                                                <label className="labelApplicationForm" >Description</label>
                                                <input
                                                    type="text"
                                                    value={item.Description}
                                                    name="Description"
                                                    onChange={(event) => { handleIncomeInput(event, item.Id) }}
                                                    className={`${errorIncomes[index].Description ? "imputError" : "campo"}`}
                                                    // className={`${errorIncomes.includes('Description', index ) ? "imputError" : "campo"}`}
                                                    placeholder="Description"
                                                    required
                                                    disabled={props.type === 'edit' ? "disabled" : null}
                                                />
                                                {errorIncomes[index].Description && <p className="errorMessage">{errorIncomes[index].Description}</p>}
                                                {/* {errorIncomes.includes('Description', index ) && <p className="errorMessage">{errorIncomes[index].Description}</p>} */}
                                            </div>



                                            <div className="mediowidth-left campoContainer">
                                                <label className="labelApplicationForm" >Amount</label>
                                                <input
                                                    type="number"
                                                    value={item.Amount}
                                                    name="Amount"
                                                    onChange={(event) => { handleIncomeInput(event, item.Id) }}
                                                    onBlur={()=> {  maskIncomeInput(item.Id, true)  }}                   
                                                    className={`${errorIncomes[index].Amount ? "imputError" : "campo"}`}
                                                    //className={`${errorIncomes.includes('Amount', index )? "imputError" : "campo"}`}
                                                    placeholder="Amount"
                                                    required
                                                    disabled={props.type === 'edit' ? "disabled" : null}
                                                />
                                                {errorIncomes[index].Amount && <p className="errorMessage">{errorIncomes[index].Amount}</p>}
                                                {/* {errorIncomes.includes('Amount', index ) && <p className="errorMessage">{errorIncomes[index].Amount}</p>} */}
                                            </div>

                                            {
                                                item.AdditionalIncomeSource === 'Other' &&
                                                <div className="mediowidth-right campoContainer">
                                                <p className="income_typeList_title">Examples of “Other”</p>
                                                <ul className="income_typeList">
                                                    <li>· Alimony</li>
                                                    <li>· Child Support</li>
                                                    <li>· Disability</li>
                                                    <li>· Social Security</li>
                                                    <li>· Unemployment</li>
                                                </ul>
                                                </div>
                                            }



                                            <div className="mediowidth-left campoContainer">
                                                <label className="labelApplicationForm" >Is Non Taxable Income</label>
                                                <select
                                                    value={item.IsNonTaxableIncome}
                                                    name="IsNonTaxableIncome"
                                                    onChange={(event) => { handleIncomeInput(event, item.Id) }}
                                                    className={`${errorIncomes[index].IsNonTaxableIncome ? "imputError" : "campo"}`}
                                                    placeholder="Is Non Taxable Income"
                                                    required
                                                    disabled={props.type === 'edit' ? "disabled" : null}
                                                >
                                                    <option value="disable" disabled selected>
                                                        Is Non Taxable Income
                                                    </option>
                                                    <option value="true">YES</option>
                                                    <option value="false">NO</option>
                                                </select>

                                                {errorIncomes[index].IsNonTaxableIncome && <p className="errorMessage">{errorIncomes[index].IsNonTaxableIncome}</p>}
                                            </div>
                                            
                                        </div>
                                    )
                                })
                            }





                            {props.type === 'edit' ?
                                null
                                :
                                <button className="addIncome_button" onClick={addIncome}>Add Income</button>
                            }


                            {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}





                            {/*   AQUI TERMINA OTHER INCOMES---------------------------------------------------------------------------------------- */}


                            <h3 className="applicationFormContainer-tittle">
                                Residence
                             </h3>

                            <form className="form">

                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >Residence Type</label>
                                    <select
                                        value={applicant.ResidenceType}
                                        name="ResidenceType"
                                        onChange={handleInputApplicant}
                                        className={`${errors.ResidenceType ? "imputError" : "campo"}`}
                                        placeholder=""
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    >
                                        <option value="disable" disabled selected>
                                            Residence Type
                                        </option>
                                        <option value="Rent">Rent</option>
                                        <option value="OwnWithMortgage">Own with Mortgage</option>
                                        <option value="OwnedFreeAndClear">Owned Free & Clear</option>
                                        

                                    </select>
                                    {errors.ResidenceType && <p className="errorMessage">{errors.ResidenceType}</p>}
                                </div>



                                <div className="mediowidth-right campoContainer">
                                    <label className="labelApplicationForm" >Date Moved into Home</label>
                                    <input
                                        type="date"
                                        value={applicant.LivingSinceCurrentResidence}
                                        name="LivingSinceCurrentResidence"
                                        onChange={handleInputApplicant}
                                        className={`${errors.LivingSinceCurrentResidence ? "imputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    />
                                    {errors.LivingSinceCurrentResidence && <p className="errorMessage">{errors.LivingSinceCurrentResidence}</p>}
                                </div>

                     
                                {applicant.ResidenceType !== 'OwnedFreeAndClear' &&
                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >Residence Monthly Payment</label>
                                    <input
                                        type="number"
                                        value={applicant.ResidenceMonthlyPayment} 
                                        name="ResidenceMonthlyPayment"
                                        onChange={handleInputApplicant}
                                        onBlur={()=> { setApplicant({...applicant, 'ResidenceMonthlyPayment': parseFloat(applicant.ResidenceMonthlyPayment).toFixed(2) })   }}
                                        className={`${errors.ResidenceMonthlyPayment ? "imputError" : "campo"}`}
                                        placeholder="Residence Monthly Payment"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    />
                                    {errors.ResidenceMonthlyPayment && <p className="errorMessage">{errors.ResidenceMonthlyPayment}</p>}
                                </div>
                                }





                                {applicant.ResidenceType !== 'Rent' &&  
                                <div className="mediowidth-right  campoContainer">
                                    <label className="labelApplicationForm" >Home Value</label>
                                    <input
                                        type="number"
                                        value={applicant.HomeValue}
                                        name="HomeValue"
                                        onChange={handleInputApplicant}
                                        onBlur={()=> { setApplicant({...applicant, 'HomeValue': parseFloat(applicant.HomeValue).toFixed(2) })   }}
                                        className={`${errors.HomeValue ? "imputError" : "campo"}`}
                                        placeholder="Home Value"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    />
                                    {errors.HomeValue && <p className="errorMessage">{errors.HomeValue}</p>}
                                </div>
                                }
                                












                                {
                                  ( props.type === 'create') && 
                                   <div className="fullwidth campoContainer checkCopyAddressInfo">
                                        <input type="checkbox"
                                            name="isActive"
                                            className="inputSentACHToo"
                                            onClick={() => setSendACHToo(!sendACHToo)}
                                            {...sendACHToo && { checked: "checked" } }
                                        />
                                        <label className="applicationFormContainer-tittle-noPadding" > Enter applicant ACH information.</label>
                                    </div> 
                                }


                                {
                                    (sendACHToo) &&
                                    <>

                                    
                                        <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Bank Account Type</label>
                                        <select
                                            value={applicant.BankAccountType}
                                            name="BankAccountType"
                                            onChange={handleInputApplicant}
                                            className={`${errors.BankAccountType ? "imputError" : "campo"}`}
                                            placeholder="Applicant State"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="" selected disabled >Bank Account Type</option>
                                            <option value="C" >Checking</option>
                                            <option value="S" >Savings</option>
                                        </select>
                                        {errors.BankAccountType && <p className="errorMessage">{errors.BankAccountType}</p>}
                                        </div>

                                        {false &&
                                            <>
                                            <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Bank Account Type</label>
                                        <select
                                            value={applicant.BankAccountType}
                                            name="BankAccountType"
                                            onChange={handleInputApplicant}
                                            className={`${errors.BankAccountType ? "imputError" : "campo"}`}
                                            placeholder="Applicant State"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="" selected disabled >Bank Account Type</option>
                                            <option value="C" >Checking</option>
                                            <option value="S" >Savings</option>
                                        </select>
                                        {errors.BankAccountType && <p className="errorMessage">{errors.BankAccountType}</p>}
                                        </div>

                                        <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Bank Account Type</label>
                                        <select
                                            value={applicant.BankAccountType}
                                            name="BankAccountType"
                                            onChange={handleInputApplicant}
                                            className={`${errors.BankAccountType ? "imputError" : "campo"}`}
                                            placeholder="Applicant State"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="" selected disabled >Bank Account Type</option>
                                            <option value="C" >Checking</option>
                                            <option value="S" >Savings</option>
                                        </select>
                                        {errors.BankAccountType && <p className="errorMessage">{errors.BankAccountType}</p>}
                                        </div>
                                            </>
                                        }
                                        


                                      

                                        <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Bank Routing Number</label>
                                        <input
                                            type="number"
                                            name="BankRoutingNumber"
                                            onChange={handleInputApplicant}
                                            className={`${errors.BankRoutingNumber ? "imputError" : "campo"}`}
                                            value={applicant.BankRoutingNumber}
                                            placeholder="Bank Routing Number"
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                            required
                                        />
                                        {errors.BankRoutingNumber && <p className="errorMessage">{errors.BankRoutingNumber}</p>}
                                        </div>


                                        <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Bank Account Number</label>
                                        <input
                                            type="number"
                                            name="BankAccountNumber"
                                            onChange={handleInputApplicant}
                                            className={`${errors.BankAccountNumber ? "imputError" : "campo"}`}
                                            value={applicant.BankAccountNumber}
                                            placeholder="Bank Account Number"
                                            disabled={props.type === 'edit' ? "disabled" : null}                
                                            required
                                        />
                                        {errors.BankAccountNumber && <p className="errorMessage">{errors.BankAccountNumber}</p>}
                                        </div>


                                    </>
                                   
                                } 
                                
                        

                               

                                <div></div>
                                {
                                    !props.hasOwnProperty('detailsId') &&
                                    <input
                                        onClick={handleSubmitApplicant}
                                        type="submit"
                                        id="submit"
                                        className="mediowidth-right submit campo"
                                        placeholder="Send Application"
                                        value="Continue"
                                        required
                                    />
                                }
                              
                                


                            </form>












                        </div>
                    </div>



                </>
            );

        case 2:
            return (
                <>
                    <div className="documentSectionContentMain">



                        {!props.hasOwnProperty('detailsId') && stepsCounter()}

                        {/* {stepsCounter()} */}

                        {isLoadingDetails ?
                            <div className="spinnerDetails"><Spinner
                                size={25}
                                color={"grey"}
                                spinnerWidth={3}
                                visible={true}
                            /></div>
                            :
                            null}



                       {props.type === 'create' ? 
                            <div className="applicationFormContainer">

                               
                                {/* {props.internalTabs && tabs()} */}

                           

                                {/* {props.type === 'create' ? */}
                                {!props.hasOwnProperty('detailsId') && 
                                    <>
                                        <h3 className="applicationFormContainer-tittle ">
                                            Do you want to add a CoApplicant?
                                        </h3>
                                        <p className="coapplicantNote">If the primary applicant is married then the co-applicant must be the spouse, otherwise the co-applicant must reside in the house with the primary applicant. Adding a co-applicant may result in a better promotion offering.</p>
                                    </>
                                   
                                }

                                {/* {props.type === 'create' ? */}
                                {!props.hasOwnProperty('detailsId')  ?
                                    <div className="form ">
                                        <div className={props.iframe ? "fullwidth campoContainer " : "fullwidth campoContainer"}>
                                            <label className="labelApplicationForm" >Add a Co-Applicant? </label>
                                            <select
                                                id=""
                                                name="HasCoApplicant"
                                                onChange={handleInputHasCoApplicant}
                                                className="campo"
                                                placeholder="Has CoApplicant"
                                                value={applicant.HasCoApplicant === true ? 'true' : null}
                                                required
                                                disabled={props.type === 'edit' ? "disabled" : null}
                                            >
                                                <option value="default" disabled selected>
                                                    Select
                                                </option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>

                                            </select>

                                        </div>
                                        {/* {
                                           props.iframe && <div>.</div>
                                        } */}
                                       
                                    </div>
                                    : null
                                }


                                {hasCoApplicantValue.HasCoApplicant === "false" && !props.hasOwnProperty('detailsId') ?
                                    <div className="form">
                                        <button
                                            className="mediowidth-left campo back"
                                            onClick={() => { setStep(1) }}
                                        >Back</button>

                                        <input
                                            onClick={handleSubmitAllData}
                                            type="submit"
                                            id="submit"
                                            className="mediowidth-right submit campo"
                                            placeholder="Save and continue"
                                            value="Submit"
                                            required
                                        />
                                    </div>

                                    : null
                                    }

                                </div>
                                :
                                null
                                }
                         


                            {
                                (props.hasOwnProperty('detailsId') && applicant.HasCoApplicant === false) &&
                                <div className="applicationFormContainer">
                                     {props.internalTabs && tabs()}
                                    <p className="applicationHasNotCoApplicant-message">This Application has no Co Applicant</p>
                                </div>
                            }





                        {hasCoApplicant &&
                            <div className="applicationFormContainer">

                                {/* {props.internalTabs && tabs()} */}

                                <h3 className="applicationFormContainer-tittle">
                                    Main Information
                            </h3>
                                <form action="" id="form" className="form">

                                  

                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Applicant Relationship</label>
                                        <select
                                            value={coApplicant.CoApplicantType ? coApplicant.CoApplicantType : coApplicant.ApplicantRelationship}
                                            name="CoApplicantType"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.CoApplicantType ? "imputError" : "campo"}`}
                                            placeholder="Applicant Relationship"
                                            required
                                            autoFocus
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="disable" disabled selected>
                                                Applicant Relationship
                                        </option>
                                            <option value="Spouse">Spouse</option>
                                            <option value="NonSpouse">Non Spouse</option>

                                        </select>
                                        {errorsCoApplicant.CoApplicantType && <p className="errorMessage">{errorsCoApplicant.CoApplicantType}</p>}
                                    </div>


                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" > First Name</label>
                                        <input
                                            type="text"
                                            name="FirstName"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.FirstName ? "imputError" : "campo"}`}
                                            value={coApplicant.FirstName}
                                            placeholder="First name"
                                            disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id) ? "disabled" : null}
                                            required
                                        />
                                        {errorsCoApplicant.FirstName && <p className="errorMessage">{errorsCoApplicant.FirstName}</p>}
                                    </div>



                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Middle Initial (*Optional)</label>
                                        <input
                                            type="text"
                                            name="MiddleInitial"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.MiddleInitial ? "imputError" : "campo"}`}
                                            value={coApplicant.MiddleInitial}
                                            placeholder="Only one letter"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.MiddleInitial && <p className="errorMessage">{errorsCoApplicant.MiddleInitial}</p>}
                                    </div>



                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Last Name</label>
                                        <input
                                            type="text"

                                            name="LastName"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.LastName ? "imputError" : "campo"}`}
                                            placeholder="Last Name"
                                            value={coApplicant.LastName}
                                            required
                                            disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id) ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.LastName && <p className="errorMessage">{errorsCoApplicant.LastName}</p>}
                                    </div>



                                    {/* SocialSecurityNumber */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Social Security Number</label>
                                        {
                                            props.type === 'edit' ?
                                            <input
                                                //value={applicant.SocialSecurityNumber}
                                                value={props.type === 'edit' ? (coApplicant.SocialSecurityNumber ? ('*****' + coApplicant.SocialSecurityNumber.toString().substr(5,9)) : '') : coApplicant.SocialSecurityNumber}
                                                type="text"
                                                name="SocialSecurityNumber"
                                                onChange={handleInputApplicant}
                                                className={`${errors.SocialSecurityNumber ? "imputError" : "campo"}`}
                                                placeholder="Do not use dashes"
                                                required
                                                disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                            /> 
                                            :
                                            <NumberFormat
                                            value={props.type === 'edit' ? (coApplicant.SocialSecurityNumber ? ('*****' + coApplicant.SocialSecurityNumber.toString().substr(5,9)) : '') : coApplicant.SocialSecurityNumber}
                                            // value={applicant.SocialSecurityNumber}
                                            onValueChange={e => handleInputCoApplicant(e, 'SocialSecurityNumber')}
                                            displayType="input"
                                            type="text"
                                            // type={props.type === 'edit' ? "text" : "number"}
                                            name="SocialSecurityNumber"
                                            placeholder="Do not use dashes"
                                            format="###-##-####"
                                            className={`${errorsCoApplicant.CellPhone ? "imputError" : "campo"}`}
                                            // className={stylesForInputMask(errors.SocialSecurityNumber)}
                                            disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                        />
                                        }
                                        {/* <input
                                            value={props.type === 'edit' ? coApplicant.hasOwnProperty('SocialSecurityNumber') ? ('*****' + coApplicant.SocialSecurityNumber.toString().substr(5,9)) : '' : coApplicant.SocialSecurityNumber}
                                            type={props.type === 'edit' ? "text" : "number"}
                                            name="SocialSecurityNumber"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.SocialSecurityNumber ? "imputError" : "campo"}`}
                                            placeholder="Do not use dashes"
                                            required
                                            disabled={(props.type === 'edit' || props.urlParamApplicant_Id || props.application_id) ? "disabled" : null}
                                        /> */}
                                        {errorsCoApplicant.SocialSecurityNumber && <p className="errorMessage">{errorsCoApplicant.SocialSecurityNumber}</p>}
                                    </div>




                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Email</label>
                                        <input
                                            type="email"

                                            name="EmailAddress"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.EmailAddress ? "imputError" : "campo"}`}
                                            placeholder="Email"
                                            value={coApplicant.EmailAddress}
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.EmailAddress && <p className="errorMessage">{errorsCoApplicant.EmailAddress}</p>}
                                    </div>



                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Date of Birth</label>
                                        <input
                                            type="date"
                                            value={coApplicant.DateOfBirth}
                                            name="DateOfBirth"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.DateOfBirth ? "imputError" : "campo"}`}
                                            placeholder="yyyy-mm-dd"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.DateOfBirth && <p className="errorMessage">{errorsCoApplicant.DateOfBirth}</p>}
                                    </div>


                                    {
                                       props.type === 'create' &&
                                       <div className="fullwidth campoContainer checkCopyAddressInfo">
                                        <input type="checkbox"
                                            name="isActive"
                                            className="inputCopyAddressInfo"
                                            onClick={copyAddressData} /> <label className="labelApplicationForm" > Copy address info from Applicant</label>
                                        </div>
                                    }
                                    
                                    

                                    {/* {copyAddresDataFromApplicant ? null :
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Address</label>
                                        <input
                                            type="text"
                                            value={coApplicant.Address1}

                                            name="Address1"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.Address1 ? "imputError" : "campo"}`}
                                            placeholder="Address"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.Address1 && <p className="errorMessage">{errorsCoApplicant.Address1}</p>}
                                    </div>
                                    } */}


                                    {copyAddresDataFromApplicant ? null :
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Address</label>
                                        <PlacesAutocomplete
                                            value={coApplicant.Address1}
                                            onChange={handleChangePlacesAutocompleteCoApplicant}
                                            onSelect={handleSelectPlacesAutocompleteCoApplicant}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input
                                                {...getInputProps({ placeholder: "Type address" })}
                                                placeholder="Address"
                                                className={`${errorsCoApplicant.Address1 ? "imputError" : "campo"}`}
                                                autocomplete="off"
                                                disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                                />

                                                <div className={suggestions.length > 0 && "inputPlacesAutocomplete_optionsContainer"}>
                                                {loading ? <div>...loading</div> : null}

                                                {suggestions.map((suggestion) => {
                                                    const style = {
                                                    backgroundColor: suggestion.active ? "#e5e5e5" : "#fff",
                                                    cursor: "pointer",
                                                    marginBotton: '10ppx'
                                                    };

                                                    return (
                                                    <div {...getSuggestionItemProps(suggestion, { style })}>
                                                        {suggestion.description}
                                                    </div>
                                                    );
                                                })}
                                                </div>
                                            </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {errorsCoApplicant.Address1 && <p className="errorMessage">{errorsCoApplicant.Address1}</p>}
                                    </div>
                                     }


                                    {copyAddresDataFromApplicant ? null :
                                    <div className="mediowidth-left campoContainer">
                                        <label className="labelApplicationForm" >City</label>
                                        <input
                                            type="text"
                                            value={coApplicant.City}

                                            name="City"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.City ? "imputError" : "campo"}`}
                                            placeholder="City"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.City && <p className="errorMessage">{errorsCoApplicant.City}</p>}
                                    </div>
                                    }


                                    {copyAddresDataFromApplicant ? null :
                                    <div className="mediowidth-right campoContainer">
                                        <label className="labelApplicationForm" >State</label>
                                        <select
                                            value={coApplicant.State || 'selected'}
                                            name="State"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.State ? "imputError" : "campo"}`}
                                            placeholder="State"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="selected" disabled>
                                                State
                                        </option>
                                        <option value="AK">AK</option>
                                        <option value="AL">AL</option>
                                        <option value="AR">AR</option>
                                        <option value="AZ">AZ</option>           
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>                 
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="IA">IA</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>          
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="MA">MA</option>
                                        <option value="MD">MD</option>
                                        <option value="ME">ME</option>      
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NV">NV</option>
                                        <option value="NY">NY</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VA">VA</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="WA">WA</option>
                                        <option value="WI">WI</option>
                                        <option value="WV">WV</option>
                                        <option value="WY">WY</option>   
                                        </select>
                                        {errorsCoApplicant.State && <p className="errorMessage">{errorsCoApplicant.State}</p>}
                                    </div>
                                    }

                                    
                                    {copyAddresDataFromApplicant ? null :
                                    <div className="mediowidth-left campoContainer">
                                        <label className="labelApplicationForm" >{props.entity === 'aquaFinance' ? 'Postal Code' : 'Zip Code'}</label>
                                        <input
                                            type="number"
                                            value={coApplicant.PostalCode}

                                            name="PostalCode"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.PostalCode ? "imputError" : "campo"}`}
                                            placeholder={props.entity === 'aquaFinance' ? 'Postal Code' : 'Zip Code'}
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.PostalCode && <p className="errorMessage">{errorsCoApplicant.PostalCode}</p>}
                                    </div>
                                    }


                                    {/* CellPhone */}
                                    <div className="mediowidth-left campoContainer">
                                        <label className="labelApplicationForm" >Home/Cell Phone</label>
                                        <NumberFormat
                                            value={coApplicant.CellPhone}
                                            onValueChange={e => handleInputCoApplicant(e, 'CellPhone')}
                                            displayType="input"
                                            type="text"
                                            name="CellPhone"
                                            placeholder="Do not use dashes"
                                            format="(###) ### - ####"
                                            className={`${errorsCoApplicant.CellPhone ? "imputError" : "campo"}`}
                                            // className={stylesForInputMask(errors.CellPhone)}
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {/* <input
                                            type="number"
                                            value={coApplicant.CellPhone}
                                            name="CellPhone"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.CellPhone ? "imputError" : "campo"}`}
                                            placeholder="Do not use dashes"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        /> */}
                                        {errorsCoApplicant.CellPhone && <p className="errorMessage">{errorsCoApplicant.CellPhone}</p>}
                                    </div>


                                    {/* HomePhone */}
                                    {/* <div className="mediowidth-right campoContainer">
                                        <label className="labelApplicationForm" >Home Phone</label>
                                        <input
                                            type="number"
                                            value={coApplicant.HomePhone}
                                            name="HomePhone"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.HomePhone ? "imputError" : "campo"}`}
                                            placeholder="Do not use dashes"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.HomePhone && <p className="errorMessage">{errorsCoApplicant.HomePhone}</p>}
                                    </div> */}





                                    {/* BusinessPhone */}
                                    <div className="mediowidth-right campoContainer">
                                        <label className="labelApplicationForm" >BusinessPhone (*Optional)</label>
                                        <NumberFormat
                                            value={coApplicant.BusinessPhone}
                                            onValueChange={e => handleInputCoApplicant(e, 'BusinessPhone')}
                                            displayType="input"
                                            type="text"
                                            name="BusinessPhone"
                                            placeholder="Do not use dashes"
                                            format="(###) ### - ####"
                                            className={`${errorsCoApplicant.BusinessPhone ? "imputError" : "campo"}`}
                                            // className={stylesForInputMask(errors.CellPhone)}
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {/* <input
                                            type="number"
                                            value={coApplicant.BusinessPhone}
                                            name="BusinessPhone"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.BusinessPhone ? "imputError" : "campo"}`}
                                            placeholder="Do not use dashes"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        /> */}
                                        {errorsCoApplicant.BusinessPhone && <p className="errorMessage">{errorsCoApplicant.BusinessPhone}</p>}
                                    </div>

                                </form>








                            <h3 className="applicationFormContainer-tittle">
                            Identification Information
                            </h3>
                           

                            <div className="form">

                            {/* {props.type === 'create' ?
                               (props.iframe ?  <p className="fullwidth campoContainer">*Optional, Choose Unavailable.</p> :  <p className="fullwidth campoContainer">*Optional for Pinnacle, choose Unavailable.</p>)
                                : null
                            } */}

                            {props.type === 'create' && !props.fillOutApp &&
                                (props.iframe ?  
                                    
                                      <p className="fullwidth campoContainer">*Optional, Choose Unavailable.</p> 
                                    
                                    
                                    :  
                                    <p className="fullwidth campoContainer">*ID is required for Aqua.</p>)
                               
                            }

                            {/* {props.type === 'create' &&
                                (props.iframe && <p className="fullwidth campoContainer">*Optional, Choose Unavailable</p> )
                               
                            }

                            {props.type === 'create' &&
                                (!props.iframe && <p className="fullwidth campoContainer">*Optional for Pinnacle, choose Unavailable</p>)
                               
                            }
                             */}

                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Type</label>
                                <select
                                value={coApplicant.IdentificationType}
                                name="IdentificationType"
                                onChange={handleInputCoApplicant}
                                className={`${errorsCoApplicant.IdentificationType ? "imputError" : "campo"}`}
                                placeholder="Identification Type"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                <option value="default" disabled selected>
                                    Identification Type
                                </option>
                                <option value="Unavailable">Unavailable</option>
                                <option value="DriversLicense">Drivers License</option>
                                <option value="StateId">State Id</option>
                                <option value="UnitedStatesMilitaryId">United States Military Id</option>
                                <option value="UnitedStatesPassport">United States Passport</option>
                                <option value="PermanentResidencyCard">Permanent Residency Card</option>
                                </select>
                                {errorsCoApplicant.IdentificationType && <p className="errorMessage">{errorsCoApplicant.IdentificationType}</p>}
                            </div>
                            
                        {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'UnitedStatesPassport' || coApplicant.IdentificationType === 'PermanentResidencyCard'? 
                        
                        <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Identification Number</label>
                            <input
                                type="text"
                                value={coApplicant.IdentificationNumber}
                                name="IdentificationNumber"
                                onChange={handleInputCoApplicant}
                                className={`${errorsCoApplicant.IdentificationNumber ? "imputError" : "campo"}`}
                                placeholder="Identification Number"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                            />
                            {errorsCoApplicant.IdentificationNumber && <p className="errorMessage">{errorsCoApplicant.IdentificationNumber}</p>}
                        </div>
                        : null
                        }
                            


                            {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'UnitedStatesPassport'?    
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue Date Present</label>
                                <select
                                value={coApplicant.IsIssueDatePresent}
                                name="IsIssueDatePresent"
                                onChange={handleInputCoApplicant}
                                className={`${errorsCoApplicant.IsIssueDatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue Date Present"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                <option value="disable" disabled selected>
                                Is Issue Date Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                                

                            </select>
                            {errorsCoApplicant.IsIssueDatePresent && <p className="errorMessage">{errorsCoApplicant.IsIssueDatePresent}</p>}
                            </div>
                            : null     
                            }





                            {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'UnitedStatesPassport' || coApplicant.IdentificationType === 'PermanentResidencyCard'?

                                (coApplicant.IsIssueDatePresent === 'true' || coApplicant.IsIssueDatePresent === true)  || coApplicant.IdentificationType === 'PermanentResidencyCard' ?

                                <div className={coApplicant.IdentificationType === 'PermanentResidencyCard' ? "fullwidth campoContainer" : "mediowidth-right campoContainer"}>
                                <label className="labelApplicationForm" >Identification Issue Date</label>
                                    <input
                                        type="date"
                                        value={coApplicant.IdentificationIssuedDate}
                                        name="IdentificationIssuedDate"
                                        onChange={handleInputCoApplicant}
                                        className={`${errorsCoApplicant.IdentificationIssuedDate ? "imputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        disabled={props.type === 'edit' ? "disabled" : null}
                                    />
                                    {errorsCoApplicant.IdentificationIssuedDate && <p className="errorMessage">{errorsCoApplicant.IdentificationIssuedDate}</p>}
                                </div>
                                :null
                                : null

                            }

                            
                            


                            {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId'   ?
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue State Present</label>
                                <select
                                value={coApplicant.IsIssueStatePresent}
                                name="IsIssueStatePresent"
                                onChange={handleInputCoApplicant}
                                className={`${errorsCoApplicant.IsIssueStatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue State Present"
                                required
                                disabled={props.type === 'edit' ? "disabled" : null}
                                >
                                <option value="disable" disabled selected>
                                Is Issue State Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                                

                            </select>
                            {errorsCoApplicant.IsIssueStatePresent && <p className="errorMessage">{errorsCoApplicant.IsIssueStatePresent}</p>}
                            </div>
                            :null
                            }


         
              
            


            

            {coApplicant.IdentificationType === 'DriversLicense' || coApplicant.IdentificationType === 'StateId' || coApplicant.IdentificationType === 'PermanentResidencyCard' ?

                coApplicant.IsIssueStatePresent === 'true' || coApplicant.IsIssueStatePresent === true ?

                    <div className="mediowidth-right campoContainer">
                    <label className="labelApplicationForm" >Identification Issue State</label>
                    <select
                      value={coApplicant.IdentificationIssueState}
                      name="IdentificationIssueState"
                      onChange={handleInputCoApplicant}
                      className={`${errorsCoApplicant.IdentificationIssueState ? "imputError" : "campo"}`}
                      placeholder="Identification Issue State"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                    >
                      <option value="disable" disabled selected>
                        Identification Issue State
                      </option>
                      <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
                    </select>
                    {errorsCoApplicant.IdentificationIssueState && <p className="errorMessage">{errorsCoApplicant.IdentificationIssueState}</p>}
                    </div>
                    :
                    null
            : null
          }
            



          {coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'StateId'  ?
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Is Expiration Date Present</label>
                  <select
                  value={coApplicant.ExpirationDateUnavailable}
                  name="ExpirationDateUnavailable"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.ExpirationDateUnavailable ? "imputError" : "campo"}`}
                  placeholder="Is Expiration Date Present"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  >
                  <option value="disable" disabled selected>
                  Is Expiration Date Present
                  </option>
                  <option value="false">Yes</option>
                  <option value="true">No</option>
                  

                </select>
                {errorsCoApplicant.ExpirationDateUnavailable && <p className="errorMessage">{errorsCoApplicant.ExpirationDateUnavailable}</p>}
              </div>
              : null
          
              }


              {coApplicant.IdentificationType === 'UnitedStatesMilitaryId' || coApplicant.IdentificationType === 'StateId'  ?
                 coApplicant.ExpirationDateUnavailable === false || coApplicant.ExpirationDateUnavailable === 'false' ?
                  <div className="mediowidth-right campoContainer">
                  <label className="labelApplicationForm" >Identification Expiration Date</label>
                      <input
                        type="date"
                        value={coApplicant.IdentificationExpirationDate}
                        name="IdentificationExpirationDate"
                        onChange={handleInputCoApplicant}
                        className={`${errorsCoApplicant.IdentificationExpirationDate ? "imputError" : "campo"}`}
                        placeholder="yyyy-mm-dd"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorsCoApplicant.IdentificationExpirationDate && <p className="errorMessage">{errorsCoApplicant.IdentificationExpirationDate}</p>}
                  </div>
                  : null
              : null
              
            
              }
            
            
            
            
            
            


            {coApplicant.IdentificationType === 'DriversLicense'   || coApplicant.IdentificationType === 'UnitedStatesPassport' || coApplicant.IdentificationType === 'PermanentResidencyCard' ?
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Identification Expiration Date</label>
                <input
                  type="date"
                  value={coApplicant.IdentificationExpirationDate}
                  name="IdentificationExpirationDate"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.IdentificationExpirationDate ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.IdentificationExpirationDate && <p className="errorMessage">{errorsCoApplicant.IdentificationExpirationDate}</p>}
            </div>
            :
            null
          }
            
           
            
            </div>












                                <h3 className="applicationFormContainer-tittle">
                                    Employment Information
                            </h3>

                                <form action="" id="form" className="form">


                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Employment Type</label>
                                        <select
                                            value={coApplicant.EmploymentType}
                                            name="EmploymentType"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.EmploymentType ? "imputError" : "campo"}`}
                                            placeholder="Employment Type"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="disable" disabled selected>
                                                Employment Type
                                        </option>
                                            <option value="Unemployed">Unemployed</option>
                                            <option value="Employed">Employed</option>
                                            <option value="Retired">Retired</option>
                                            <option value="SelfEmployed">Self Employed</option>

                                        </select>
                                        {errorsCoApplicant.EmploymentType && <p className="errorMessage">{errorsCoApplicant.EmploymentType}</p>}
                                    </div>

                                    {coApplicant.EmploymentType === 'Employed' ?
                                        <div className="fullwidth campoContainer">
                                            <label className="labelApplicationForm" >Current Employer</label>
                                            <input
                                                type="text"
                                                value={coApplicant.CurrentEmployer}
                                                name="CurrentEmployer"
                                                onChange={handleInputCoApplicant}
                                                className={`${errorsCoApplicant.CurrentEmployer ? "imputError" : "campo"}`}
                                                placeholder="Current Employer"
                                                required
                                                disabled={props.type === 'edit' ? "disabled" : null}
                                            />
                                            {errorsCoApplicant.CurrentEmployer && <p className="errorMessage">{errorsCoApplicant.CurrentEmployer}</p>}
                                        </div>
                                        : null}



                                    {coApplicant.EmploymentType === 'Employed' || coApplicant.EmploymentType === 'SelfEmployed' ?
                                        <div className="fullwidth campoContainer">
                                            <label className="labelApplicationForm" >Occupation</label>
                                            <input
                                                type="text"
                                                value={coApplicant.Occupation}
                                                name="Occupation"
                                                onChange={handleInputCoApplicant}
                                                className={`${errorsCoApplicant.Occupation ? "imputError" : "campo"}`}
                                                placeholder="Occupation"
                                                required
                                                disabled={props.type === 'edit' ? "disabled" : null}
                                            />
                                            {errorsCoApplicant.Occupation && <p className="errorMessage">{errorsCoApplicant.Occupation}</p>}
                                        </div>
                                        : null}




                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >State</label>
                                        <select
                                            value={coApplicant.EmployerState || 'selected'}
                                            name="EmployerState"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.EmployerState ? "imputError" : "campo"}`}
                                            placeholder="Employment State"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="selected" disabled>
                                                State
                                        </option>
                                        <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
                                        </select>
                                        {errorsCoApplicant.EmployerState && <p className="errorMessage">{errorsCoApplicant.EmployerState}</p>}
                                    </div>





                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Start Date</label>
                                        <input
                                            type="date"
                                            value={coApplicant.HireDate}
                                            name="HireDate"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.HireDate ? "imputError" : "campo"}`}
                                            placeholder="yyyy-mm-dd"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.HireDate && <p className="errorMessage">{errorsCoApplicant.HireDate}</p>}
                                    </div>



                                    {coApplicant.EmploymentType === 'Employed' || coApplicant.EmploymentType === 'SelfEmployed' ?
                                        <div className="fullwidth campoContainer">
                                            <label className="labelApplicationForm" >Gross Monthly Income</label>
                                            <input
                                                type="number"
                                                value={coApplicant.GrossMonthlyIncome}
                                                name="GrossMonthlyIncome"
                                                onChange={handleInputCoApplicant}
                                                onBlur={()=> { setCoApplicant({...coApplicant, 'GrossMonthlyIncome': parseFloat(coApplicant.GrossMonthlyIncome).toFixed(2) })   }}
                                                className={`${errorsCoApplicant.GrossMonthlyIncome ? "imputError" : "campo"}`}
                                                placeholder="Gross Monthly Income"
                                                required
                                                disabled={props.type === 'edit' ? "disabled" : null}
                                            />
                                            {errorsCoApplicant.GrossMonthlyIncome && <p className="errorMessage">{errorsCoApplicant.GrossMonthlyIncome}</p>}
                                        </div>
                                        : null}



                                </form>




                                {/*   AQUI COMIENZA OTHER INCOMES---------------------------------------------------------------------------------------- */}

                                {
                                    (coApplicant.EmploymentType === 'Unemployed' || coApplicant.EmploymentType === 'Retired') && props.type === 'create' ?
                                    <h3 className="applicationFormContainer-tittle">Must show income. Please add other monthly Income.</h3>
                                    : null
                                }

                                {errorsCoApplicant.TotalIncomes &&  <p className="applicationFormContainer-tittle textColor_red">Total income must be equal to or less than 1,000,000</p>}


                                <h3 className="applicationFormContainer-tittle">
                                    Other Monthly Income
                                </h3>


                                {/* INCOME LISTS */}
                                {
                                    incomeListCoApplicant.map((item, index) => {
                                        return (
                                            <div className="incomeCard" key={index}>



                                                {props.type === 'edit' ?
                                                    null
                                                    :
                                                    <div className="mediowidth-right deleteIncome-icon_container">
                                                        <img src={iconDelete} className="deleteIncome-icon" onClick={() => { deleteIncomeCoApplicant(item.Id, index) }} />
                                                    </div>
                                                }

                                                <div className="mediowidth-left campoContainer">
                                                    <label className="labelApplicationForm" >Additional Income Source</label>
                                                    <select
                                                        value={item.AdditionalIncomeSource}
                                                        name="AdditionalIncomeSource"
                                                        onChange={(event) => { handleIncomeInputCoApplicant(event, item.Id) }}
                                                        className={`${errorIncomesCoApplicant[index].AdditionalIncomeSource ? "imputError" : "campo"}`}
                                                        placeholder="Additional Income Source"
                                                        required
                                                        disabled={props.type === 'edit' ? "disabled" : null}
                                                    >
                                                        <option value="disable" disabled selected>
                                                            Additional Income Source
                                                    </option>
                                                        <option value="Other">Other</option>
                                                        <option value="RetirementSSI">Retirement SSI</option>
                                                        <option value="Investments">Investments</option>
                                                        <option value="Rental">Rental</option>
                                                    </select>

                                                    {errorIncomesCoApplicant[index].AdditionalIncomeSource && <p className="errorMessage">{errorIncomesCoApplicant[index].AdditionalIncomeSource}</p>}
                                                </div>




                                                <div className="mediowidth-right campoContainer">
                                                    <label className="labelApplicationForm" >Description</label>
                                                    <input
                                                        type="text"
                                                        value={item.Description}
                                                        name="Description"
                                                        onChange={(event) => { handleIncomeInputCoApplicant(event, item.Id) }}
                                                        className={`${errorIncomesCoApplicant[index].Description ? "imputError" : "campo"}`}
                                                        placeholder="Description"
                                                        required
                                                        disabled={props.type === 'edit' ? "disabled" : null}
                                                    />
                                                    {errorIncomesCoApplicant[index].Description && <p className="errorMessage">{errorIncomesCoApplicant[index].Description}</p>}
                                                </div>



                                                <div className="mediowidth-left campoContainer">
                                                    <label className="labelApplicationForm" >Amount</label>
                                                    <input
                                                        type="number"
                                                        value={item.Amount}
                                                        name="Amount"
                                                        onChange={(event) => { handleIncomeInputCoApplicant(event, item.Id) }}
                                                        onBlur={()=> {  maskIncomeInput(item.Id, false)  }} 
                                                        className={`${errorIncomesCoApplicant[index].Amount ? "imputError" : "campo"}`}
                                                        placeholder="Amount"
                                                        required
                                                        disabled={props.type === 'edit' ? "disabled" : null}
                                                    />
                                                    {errorIncomesCoApplicant[index].Amount && <p className="errorMessage">{errorIncomesCoApplicant[index].Amount}</p>}
                                                </div>


                                                {
                                                    item.AdditionalIncomeSource === 'Other' &&
                                                    <div className="mediowidth-right campoContainer">
                                                    <p className="income_typeList_title">Examples of “Other”</p>
                                                    <ul className="income_typeList">
                                                        <li>· Alimony</li>
                                                        <li>· Child Support</li>
                                                        <li>· Disability</li>
                                                        <li>· Social Security</li>
                                                        <li>· Unemployment</li>
                                                    </ul>
                                                    </div>
                                                }


                                                <div className="mediowidth-left campoContainer">
                                                    <label className="labelApplicationForm" >Is Non Taxable Income</label>
                                                    <select
                                                        value={item.IsNonTaxableIncome}
                                                        name="IsNonTaxableIncome"
                                                        onChange={(event) => { handleIncomeInputCoApplicant(event, item.Id) }}
                                                        className={`${errorIncomesCoApplicant[index].IsNonTaxableIncome ? "imputError" : "campo"}`}
                                                        placeholder="Is Non Taxable Income"
                                                        required
                                                        disabled={props.type === 'edit' ? "disabled" : null}
                                                    >
                                                        <option value="disable" disabled selected>
                                                            Is Non Taxable Income
                                                        </option>
                                                        <option value="true">YES</option>
                                                        <option value="false">NO</option>
                                                    </select>

                                                    {errorIncomesCoApplicant[index].IsNonTaxableIncome && <p className="errorMessage">{errorIncomesCoApplicant[index].IsNonTaxableIncome}</p>}
                                                </div>


                                            </div>
                                        )
                                    })
                                }





                                {props.type === 'edit' ?
                                    null
                                    :
                                    <button className="addIncome_button" onClick={addIncomeCoApplicant}>Add Income</button>
                                }


                                {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}





                                {/*   AQUI TERMINA OTHER INCOMES---------------------------------------------------------------------------------------- */}


                                <h3 className="applicationFormContainer-tittle">
                                    Residence
                                </h3>

                                <form className="form">

                                    {
                                        props.type === 'create' &&
                                        <div className="fullwidth campoContainer checkCopyAddressInfoExternalForm">
                                        <input type="checkbox"
                                            name="isActive"
                                            className="inputCopyAddressInfo"
                                            onClick={copyResidenceData} /> <label className="labelApplicationForm" > Copy residence info from Applicant</label>
                                        </div>
                                    }
                                    



                                    {copyResidenceDataFromApplicant ? null :
                                    <div className="mediowidth-left campoContainer">
                                        <label className="labelApplicationForm" >Residence Type</label>
                                        <select
                                            value={coApplicant.ResidenceType}
                                            name="ResidenceType"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.ResidenceType ? "imputError" : "campo"}`}
                                            placeholder=""
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        >
                                            <option value="disable" disabled selected>
                                                Residence Type
                                        </option>
                                            <option value="Rent">Rent</option>
                                            <option value="OwnWithMortgage">Own with Mortgage</option>
                                            <option value="OwnedFreeAndClear">Owned Free & Clear</option>
                                           
                                        </select>
                                        {errorsCoApplicant.ResidenceType && <p className="errorMessage">{errorsCoApplicant.ResidenceType}</p>}
                                    </div>
                                    }


                                    {copyResidenceDataFromApplicant ? null :
                                    <div className="mediowidth-right campoContainer">
                                        <label className="labelApplicationForm" >Date Moved into Home</label>
                                        <input
                                            type="date"
                                            value={coApplicant.LivingSinceCurrentResidence}
                                            name="LivingSinceCurrentResidence"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.LivingSinceCurrentResidence ? "imputError" : "campo"}`}
                                            placeholder="yyyy-mm-dd"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.LivingSinceCurrentResidence && <p className="errorMessage">{errorsCoApplicant.LivingSinceCurrentResidence}</p>}
                                    </div>
                                    }



                                    {copyResidenceDataFromApplicant || coApplicant.ResidenceType === 'OwnedFreeAndClear' ? null :
                                    <div className="mediowidth-left campoContainer">
                                        <label className="labelApplicationForm" >Residence Monthly Payment</label>
                                        <input
                                            type="number"
                                            value={coApplicant.ResidenceMonthlyPayment}
                                            name="ResidenceMonthlyPayment"
                                            onChange={handleInputCoApplicant}
                                            onBlur={()=> { setCoApplicant({...coApplicant, 'ResidenceMonthlyPayment': parseFloat(coApplicant.ResidenceMonthlyPayment).toFixed(2) })   }}
                                            className={`${errorsCoApplicant.ResidenceMonthlyPayment ? "imputError" : "campo"}`}
                                            placeholder="Residence Monthly Payment"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.ResidenceMonthlyPayment && <p className="errorMessage">{errorsCoApplicant.ResidenceMonthlyPayment}</p>}
                                    </div>
                                    }




                                    {copyResidenceDataFromApplicant || coApplicant.ResidenceType === 'Rent' ? null :
                                    <div className="mediowidth-right campoContainer">
                                        <label className="labelApplicationForm" >Home Value</label>
                                        <input
                                            type="number"
                                            value={coApplicant.HomeValue}
                                            name="HomeValue"
                                            onChange={handleInputCoApplicant}
                                            onBlur={()=> { setCoApplicant({...coApplicant, 'HomeValue': parseFloat(coApplicant.HomeValue).toFixed(2) })   }}
                                            className={`${errorsCoApplicant.HomeValue ? "imputError" : "campo"}`}
                                            placeholder="Home Value"
                                            required
                                            disabled={props.type === 'edit' ? "disabled" : null}
                                        />
                                        {errorsCoApplicant.HomeValue && <p className="errorMessage">{errorsCoApplicant.HomeValue}</p>}
                                    </div>
                                    }


                                   


                                   


                                   


                                    {
                                         !props.hasOwnProperty('detailsId') &&
                                         <input
                                            onClick={handleSubmitCoApplicant}
                                            type="submit"
                                            id="submit"
                                            className="fullwidth submit campo"
                                            placeholder="Send Application"
                                            value="Save Changes"
                                            required
                                        />
                                    }
                                    



                                </form>












                            </div>

                        }



                        {showNotificationOk ?
                          
                                <Notification type='popup' reload='true' link="/applicants" title='The application was created successfully.' f={() => { window.location.reload(); }}>
                                    <img src={iconCheck} alt="check" />
                                </Notification>
                               
                            :
                            null}

                        {showNotificationError.Show ?
                            <Notification type='popup' errorResponse={showNotificationError} error={true} title='An error has occurred creating the application. Please verify the information and try again.' f={() => { SetShowNotificationError({Show:false}) }}>
                                <img src={iconSadFace} alt="check" />
                            </Notification> : null}


                        {showNotificationApplicantAlreadyExists ?
                            <Notification type='popup' title='This applicant already exists' f={() => { setShowNotificationApplicantAlreadyExists(false) }}>
                                <img src={iconSadFace} alt="check" />
                            </Notification> : null}

                        {isLoading ? <SpinnerPopUp /> : null}


                    </div>
                </>

            );
    }
}

export default ExternalForm;
