import React, {useState, useEffect, useContext} from 'react'
import '../assets/styles/Proposals.css'
import "../assets/styles/ApplicationForm.css";
import SkeletonLoader from "tiny-skeleton-loader-react";
import {AuthContext} from '../AuthContext';
import {Link} from 'react-router-dom';
import ProductRow from '../components/proposals/ProductRow';
import PopUp from '../components/PopUp'
import AddAndEditProposalProduct from '../components/proposals/AddAndEditProposalProduct';
import iconArrowDownGreen from '../assets/images/iconArrowDownGreen.png';
import iconArrowDownGrey2 from '../assets/images/iconArrowDownGrey2.png';
import iconArrowUpGreen from '../assets/images/iconArrowUpGreen.png';
import iconArrowUpGrey2 from '../assets/images/iconArrowUpGrey2.png';
import ProductBundleRow from '../components/proposals/ProductBundleRow';
import CreateBundleSystemProduct from '../components/proposals/CreateBundleSystemProduct';



const Proposals = () => {

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    let params = new URLSearchParams(document.location.search.substring(1));
    let tab = params.get('tab') || '';


    const [step, setStep] = useState(tab === '4' ? 4 : 1)

    const [showAddSystemProduct, setShowAddSystemProduct] = useState(false)
    const [showAddMonitoringProduct, setShowAddMonitoringProduct] = useState(false)
    const [showAddBundleSystemProduct, setShowAddBundleSystemProduct] = useState(false)


    const [isLoadingSystemProducts, setIsLoadingSystemProducts] = useState(false);
    const [isLoadingMonitoringProducts, setIsLoadingMonitoringProducts] = useState(false);
    const [isLoadingBundleSystemProducts, setIsLoadingBundleSystemProducts] = useState(false);

    const [systemProductList, setSystemProductList] = useState([])
    const [systemProductListOriginal, setSystemProductListOriginal] = useState([])

    const [monitoringProductList, setMonitoringProductList] = useState([])
    const [monitoringProductListOriginal, setMonitoringProductListOriginal] = useState([])

    const [bundleSystemProductList, setBundleSystemProductList] = useState([])

    const [systemPackageList, setSystemPackageList] = useState([])

    const [reloadGetSystemProducts, setReloadGetSystemProducts] = useState(false)
    const [reloadGetMonitoringProducts, setReloadGetMonitoringProducts] = useState(false)
    const [reloadGetBundleProducts, setReloadGetBundleProducts] = useState(false)
    const [reloadGetPackage, setReloadGetPackage] = useState(false)
    


    const [filterIndex, SetFilterIndex] = useState(2);
    const [reorder, setReorder] = useState(false);

    const [propertyFilterSystem, setPropertyFilterSystem] = useState('')
    const [propertyFilterMonitoring, setPropertyFilterMonitoring] = useState('')
    const [propertyFilterPackage, setPropertyFilterPackage] = useState('')
    const [propertyFilterBundle, setPropertyFilterBundle] = useState('')

    const [termSearchSystem, setTermSearchSystem] = useState('')
    const [searchBySystem, setSearchBySystem] = useState('All')

    const [termSearchMonitoring, setTermSearchMonitoring] = useState('')
    const [searchByMonitoring, setSearchByMonitoring] = useState('All')
    



    const token = window.localStorage.getItem('token');
    const dealerId = window.localStorage.getItem('dealerId');
    const user = window.localStorage.getItem('user');

   



    //GET SYSTEM PRODUCTS
    useEffect(()=>{
        setIsLoadingSystemProducts(true)


        
         const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-system-products`
        //  console.log(URI) 


        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization' : token,  
                'DealerId' : dealerId,  
                'x-api-key' : uri.URI_X_API_KEY,
             }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoadingSystemProducts(false)
            //console.log(datos);

            if(datos && datos.length > 0){
                setSystemProductList(datos)
                setSystemProductListOriginal(datos)
            }
                
            

        }).catch((error, response) => {
            // console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken()
            }    
        });

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, reloadGetSystemProducts]);





    //GET MONITORING PRODUCTS
    useEffect(()=>{
        setIsLoadingMonitoringProducts(true)


        
         const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-monitoring-products`
        //  console.log(URI) 


        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization' : token,   
                'DealerId' : dealerId,  
                'x-api-key' : uri.URI_X_API_KEY,
             }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoadingMonitoringProducts(false)
            //console.log(datos);

            if(datos && datos.length > 0){
                setMonitoringProductList(datos)
                setMonitoringProductListOriginal(datos)
            } 
            

        }).catch((error, response) => {
            // console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken()
            }    
        });

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, reloadGetMonitoringProducts]);




    //GET BUNDLE SYSTEM PRODUCTS
    useEffect(()=>{
        setIsLoadingBundleSystemProducts(true)
    
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-system-product-bundles`
            //console.log(URI) 


        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization' : token,   
                'username': user,  
                'x-api-key' : uri.URI_X_API_KEY,
                }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
                }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoadingBundleSystemProducts(false)
            //console.log(datos);

            if(datos && datos.length > 0){
                setBundleSystemProductList(datos)
            } 
            

        }).catch((error, response) => {
            console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken()
            }    
        });

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, reloadGetBundleProducts]);






    //GET SYSTEM PACKAGE
    useEffect(()=>{
        setIsLoadingBundleSystemProducts(true)
    
         const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-package-proposals`
         //console.log(URI) 


        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization' : token,   
                'username': user,  
                'x-api-key' : uri.URI_X_API_KEY,
             }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoadingBundleSystemProducts(false)
            // console.log(datos);

            if(datos && datos.length > 0){
                setSystemPackageList(datos)
            } 
            

        }).catch((error, response) => {
            console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken()
            }    
        });

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, reloadGetPackage]);









    //BUSCADOR SYSTEM PACKAGE
    useEffect(()=>{

        if(termSearchSystem === ''){
            setSystemProductList(systemProductListOriginal)
        }else if(searchBySystem === 'All'){
            const results = systemProductListOriginal.filter(product=> (product.Name|| '').toLowerCase().includes(termSearchSystem.toLowerCase())
                                                                || (product.PartNumber || '').toLowerCase().includes(termSearchSystem.toLowerCase()) 
                                                                || (product.Manufacturer || '').toLowerCase().includes(termSearchSystem.toLowerCase())       
                                                
            )
            setSystemProductList(results)
        } else {      
            const results = systemProductListOriginal.filter(product => (product[searchBySystem] || '').toLowerCase().includes(termSearchSystem.toLowerCase()))
            setSystemProductList(results)
        }

    }, [termSearchSystem, searchBySystem])




    //BUSCADOR MONITORING
    useEffect(()=>{

        if(termSearchMonitoring === ''){
            setMonitoringProductList(monitoringProductListOriginal)
        }else if(searchByMonitoring === 'All'){
            const results = monitoringProductListOriginal.filter(product=> (product.Name|| '').toLowerCase().includes(termSearchMonitoring.toLowerCase())
                                                                || (product.PartNumber || '').toLowerCase().includes(termSearchMonitoring.toLowerCase()) 
                                                                || (product.ServiceCompany || '').toLowerCase().includes(termSearchMonitoring.toLowerCase())       
                                                
            )
            setMonitoringProductList(results)
        } else {      
            const results = monitoringProductListOriginal.filter(product => (product[searchByMonitoring] || '').toLowerCase().includes(termSearchMonitoring.toLowerCase()))
            setMonitoringProductList(results)
        }

    }, [termSearchMonitoring, searchByMonitoring])




    //SORTER SYSTEM AND MONITORING
    const dynamicSort =(property, table) => {
        setReorder(!reorder)
        table === 'systemProduct' ?  setPropertyFilterSystem(property) 
        : 
        table === 'package' ?  setPropertyFilterPackage(property) 
        :
        table === 'bundle' ?  setPropertyFilterBundle(property) 
        :
        setPropertyFilterMonitoring(property)
       
      
        return function(a, b) { 
            // console.log(a[property].toString()) 

            if (filterIndex === 1) {
            
                if(b[property] === undefined ){
                    b[property] = ''
                }
                if(a[property] === undefined ){
                    a[property] = ''
                }
                SetFilterIndex(2)
                if(property === 'ProductPrice'){
                    return b.ProductPrice > a.ProductPrice ? 1 : b.ProductPrice === a.ProductPrice ? 0 : -1;
                }else{
                    return b[property].toString().localeCompare(a[property].toString());
                }
                
            } else {
                if(b[property] === undefined ){
                    b[property] = ''
                }
                if(a[property] === undefined ){
                    a[property] = ''
                }
                SetFilterIndex(1)
                if(property === 'ProductPrice'){
                    return a.ProductPrice > b.ProductPrice ? 1 : a.ProductPrice === b.ProductPrice ? 0 : -1;
                }else{
                    return a[property].toString().localeCompare(b[property].toString());
                }
               
            }
          
        };
     
    }






    const arrowDirection = (property, table) => {

        let propertyFilter;
        table === 'systemProduct' ? propertyFilter = propertyFilterSystem 
        : 
        table === 'package' ? propertyFilter = propertyFilterPackage  
        :
        table === 'bundle' ? propertyFilter = propertyFilterBundle
        :
        propertyFilter = propertyFilterMonitoring

        if(property === propertyFilter && filterIndex === 1){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            )
        }else if(property === propertyFilter && filterIndex === 2 ){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }else{
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }

       
    }




    const tabs = () => {     
       
        return (    
          <div className="applicationTabsContainer">
            <ul className="applicationTabsProposals">            
              <li className={step===1 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> {setStep(1)}}>System Products</li>
              <li className={step===2 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> {setStep(2)}}>System Product Bundles</li>   
              <li className={step===3 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> {setStep(3)}}>Services</li>  
              <li className={step===4 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> {setStep(4)}}>System Packages</li>         
            </ul>
          </div>   
    );}




    const loadingRow = () => {
        const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
        return (list.map((item, index) =>{
            return(
                <div className="proposals_productRow-loading">
                    <p className="proposals_productValue_loading"><SkeletonLoader width="90%" /></p>
                    <p className="proposals_productValue_loading"><SkeletonLoader width="90%" /></p>
                    <p className="proposals_productValue_loading"><SkeletonLoader width="90%" /></p>
                    <p className="proposals_productValue_loading"><SkeletonLoader width="90%" /></p>
                    <p className="proposals_productValue_loading"><SkeletonLoader width="90%" /></p>
                    <p className="proposals_productValue_loading"></p>
                    <p className="proposals_productValue_loading"></p>
                </div>
            )
        }))
        
    }






    return (
        <div className="proposals" >
            {/* HEADER */}
            <div className="applications-header">
                <div className="applications-header-tittle"> 
                        <h2>Proposal Setup</h2>                
                </div>

                {/* <div className="aplications-header-buttoms">
                    <Link to="/createproposal">
                        <div className="aplications-header-buttoms_new">

                            <img src={iconAdd} className="aplications-header-buttoms_new_icon" />
                            <p className="aplications-header-buttoms_new_text">
                                <label className="aplications-header-buttoms_new_text_1">New </label>
                                <label className="aplications-header-buttoms_new_text_2">Proposal</label>
                            </p>
                        </div>
                    </Link>
                </div> */}

            </div>


            {/* MAIN CONTAINER  */}
            <div className="proposals_mainContainer">
                {tabs()}
                
                <div className="proposals_tabsContainer">
                {
                    step === 1 ?
                    <>
                        {showAddSystemProduct && <PopUp setShowSheet={setShowAddSystemProduct} title="Add System Product">
                            <AddAndEditProposalProduct type="system"  setReloadGetSystemProducts={setReloadGetSystemProducts}/>
                        </PopUp>}

                        <button className="proposals_addButton" onClick={()=> setShowAddSystemProduct(true)}>Add System Product</button>


                        <div className="proposal_searchContainer">                      
                            {/* SEARCHER */}
                            <div className="applications_filter-searchContainerList">
                                <input type="text" placeholder="Search" value={termSearchSystem} className="applications_filter-searchInput" onChange={(e)=>{setTermSearchSystem(e.target.value)}}/>
                                <span className="applications_filter-searchCloseIcon" onClick={()=>{setTermSearchSystem('')}}>x</span>
                            </div>

                            {/* SEARCHER FILTERS */}
                            <div className="proposal_contaner-filterSearch">
                                <select onChange={(e)=>{setSearchBySystem(e.target.value)}} className="proposal-header-buttoms_filter square-proposal">
                                    <option value="All" selected>By Any</option>
                                    <option value="Name">By Name</option>
                                    <option value="PartNumber">By Part Number</option>
                                    <option value="Manufacturer">By Manufacturer</option>                  
                                </select>
                            </div>
                        </div>


                        <div className="proposals_productRow-header">
                            <div className="proposal_list-tittle-item" onClick={()=>{systemProductList.sort(dynamicSort("Name", 'systemProduct'))}}>
                                <div>
                                    <p>Name</p>
                                </div> 
                                {arrowDirection('Name', 'systemProduct')}
                            </div> 
                            <div className="proposal_list-tittle-item" onClick={()=>{systemProductList.sort(dynamicSort("ProductPrice", 'systemProduct'))}}>
                                <div>
                                    <p>Price</p>
                                </div> 
                                {arrowDirection('ProductPrice','systemProduct')}
                            </div>   
                            
                           
                            <div className="proposal_list-tittle-item" onClick={()=>{systemProductList.sort(dynamicSort("PartNumber", 'systemProduct'))}}>
                                    <div>
                                        <p>Part Number</p>
                                    </div> 
                                    {arrowDirection('PartNumber', 'systemProduct')}
                            </div> 

                            
                            
                            
                            <div className="proposal_list-tittle-item" onClick={()=>{systemProductList.sort(dynamicSort("Manufacturer", 'systemProduct'))}}>
                                <div>
                                    <p>Manufacturer</p>
                                </div> 
                                {arrowDirection('Manufacturer', 'systemProduct')}
                            </div>  

                            <div className="proposal_list-tittle-item" onClick={()=>{systemProductList.sort(dynamicSort("LastModifiedDate", 'systemProduct'))}}>
                                    <div>
                                        <p>Modified Date</p>
                                    </div> 
                                    {arrowDirection('LastModifiedDate', 'systemProduct')}
                            </div> 


                            <p className="proposals_productValue"></p>
                            <p className="proposals_productValue"></p>
                                      
                        </div> 




                        {
                            isLoadingSystemProducts ? 
                            loadingRow()
                            :
                            systemProductList.length > 0 && systemProductList.map(product => 
                                <ProductRow type="system" 
                                            product={product} 
                                            setSystemProductList={setSystemProductList} 
                                            setReloadGetSystemProducts={setReloadGetSystemProducts}/>
                                )
                            
                        }
                      
                    </>
                    :
                    step ===  2 ?
                    <>
                        {showAddBundleSystemProduct && <PopUp setShowSheet={setShowAddBundleSystemProduct} title="Add System Product Bundle" fullHeight width800>
                            <CreateBundleSystemProduct 
                                systemProductList={systemProductList} 
                                setReloadGetBundleProducts={setReloadGetBundleProducts}
                            />
                        </PopUp>}

                        <button className="proposals_addButton" onClick={()=> setShowAddBundleSystemProduct(true)}>Add System Product Bundle</button>


                        <div className="proposals_productRow-header">
                            <div className="proposal_list-tittle-item" onClick={()=>{bundleSystemProductList.sort(dynamicSort("Name", 'bundle'))}}>
                                <div>
                                    <p>Name</p>
                                </div> 
                                {arrowDirection('Name', 'bundle')}
                            </div> 
                            <div className="proposal_list-tittle-item" onClick={()=>{bundleSystemProductList.sort(dynamicSort("ProductPrice", 'bundle'))}}>
                                <div>
                                    <p>Price</p>
                                </div> 
                                {arrowDirection('ProductPrice','bundle')}
                            </div>   
                            
                           
                            <div className="proposal_list-tittle-item" >
                                    <div>
                                        <p>Part Number</p>
                                    </div> 
                                    
                            </div> 

                                                
                            <div className="proposal_list-tittle-item" >
                                <div>
                                    <p>Quantity</p>
                                </div> 
                               
                            </div>  

                            <div className="proposal_list-tittle-item" onClick={()=>{bundleSystemProductList.sort(dynamicSort("LastModifiedDate", 'bundle'))}}>
                                    <div>
                                        <p>Modified Date</p>
                                    </div> 
                                    {arrowDirection('LastModifiedDate', 'bundle')}
                            </div> 


                            <p className="proposals_productValue"></p>
                            <p className="proposals_productValue"></p>
                                      
                        </div> 


                        {
                            isLoadingSystemProducts ? 
                            loadingRow()
                            :
                            bundleSystemProductList.length > 0 && bundleSystemProductList.map(product => 
                                <ProductBundleRow 
                                            key={product.Id}
                                            product={product}
                                            setBundleSystemProductList={setBundleSystemProductList}
                                            systemProductList={systemProductList}
                                            setReloadGetBundleProducts={setReloadGetBundleProducts}
                                          
                                />
                
                                )                      
                        }

                      

                    </>
                    :
                    step ===  3 ?
                    <>  
                        {showAddMonitoringProduct && <PopUp setShowSheet={setShowAddMonitoringProduct} title="Add Monitoring Product">
                            <AddAndEditProposalProduct type="service" setMonitoringProductList={setMonitoringProductList} setReloadGetMonitoringProducts={setReloadGetMonitoringProducts}/>
                        </PopUp>}

                        <button className="proposals_addButton" onClick={()=> setShowAddMonitoringProduct(true)}>Add Service</button>

                        {/* <div className="proposals_productRow-header">
                            <p className="proposals_productValue">Name</p>
                            <p className="proposals_productValue">Price</p>
                            <p className="proposals_productValue">Part Number</p>
                            <p className="proposals_productValue">Service Company</p>
                            <p className="proposals_productValue"></p>
                            <p className="proposals_productValue"></p>
                        </div> */}


                        <div className="proposal_searchContainer">                      
                            {/* SEARCHER */}
                            <div className="applications_filter-searchContainerList">
                                <input type="text" placeholder="Search" value={termSearchMonitoring} className="applications_filter-searchInput" onChange={(e)=>{setTermSearchMonitoring(e.target.value)}}/>
                                <span className="applications_filter-searchCloseIcon" onClick={()=>{setTermSearchMonitoring('')}}>x</span>
                            </div>

                            {/* SEARCHER FILTERS */}
                            <div className="proposal_contaner-filterSearch">
                                <select onChange={(e)=>{setSearchByMonitoring(e.target.value)}} className="proposal-header-buttoms_filter square-proposal">
                                    <option value="All" selected>By Any</option>
                                    <option value="Name">By Name</option>
                                    <option value="PartNumber">By Part Number</option>
                                    <option value="ServiceCompany">By Company</option>                  
                                </select>
                            </div>
                        </div>




                        <div className="proposals_productRow-header">
                            <div className="proposal_list-tittle-item" onClick={()=>{monitoringProductList.sort(dynamicSort("Name", 'monitoringProduct'))}}>
                                <div>
                                    <p>Name</p>
                                </div> 
                                {arrowDirection('Name', 'monitoringProduct')}
                            </div> 
                            <div className="proposal_list-tittle-item" onClick={()=>{monitoringProductList.sort(dynamicSort("ProductPrice", 'monitoringProduct'))}}>
                                <div>
                                    <p>Price</p>
                                </div> 
                                {arrowDirection('ProductPrice', 'monitoringProduct')}
                            </div>   
                            
                           
                            <div className="proposal_list-tittle-item" onClick={()=>{monitoringProductList.sort(dynamicSort("PartNumber", 'monitoringProduct'))}}>
                                    <div>
                                        <p>Part Number</p>
                                    </div> 
                                    {arrowDirection('PartNumber', 'monitoringProduct')}
                            </div> 

                            
                            
                            
                            <div className="proposal_list-tittle-item" onClick={()=>{monitoringProductList.sort(dynamicSort("ServiceCompany", 'monitoringProduct'))}}>
                                <div>
                                    <p>Service Company</p>
                                </div> 
                                {arrowDirection('ServiceCompany', 'monitoringProduct')}
                            </div> 

                            <div className="proposal_list-tittle-item" onClick={()=>{monitoringProductList.sort(dynamicSort("LastModifiedDate", 'monitoringProduct'))}}>
                                    <div>
                                        <p>Modified Date</p>
                                    </div> 
                                    {arrowDirection('LastModifiedDate', 'monitoringProduct')}
                            </div> 
                             
                            <p className="proposals_productValue"></p>
                            <p className="proposals_productValue"></p>
                                      
                        </div> 


                        {
                            isLoadingMonitoringProducts ?
                            loadingRow()
                            :
                            monitoringProductList.length > 0 && monitoringProductList.map(product => <ProductRow type="bundle" product={product} setMonitoringProductList={setMonitoringProductList} setReloadGetMonitoringProducts={setReloadGetMonitoringProducts}/>)
                            
                        }

                    </>
                    :
                    step ===  4 ?
                    <>

                        
                      <Link to="/createsystempackage">
                        <button className="proposals_addButton" >Add System Package</button>
                      </Link>


                      <div className="proposals_productRow-header">
                            <div className="proposal_list-tittle-item" onClick={()=>{systemPackageList.sort(dynamicSort("Name", 'package'))}}>
                                <div>
                                    <p>Name</p>
                                </div> 
                                {arrowDirection('Name', 'package')}
                            </div> 
                            <div className="proposal_list-tittle-item"><p>Default package</p></div>  
                            <div></div>   
                            <div></div>    
                            
                           
                            <div className="proposal_list-tittle-item" onClick={()=>{systemPackageList.sort(dynamicSort("LastModifiedDate", 'package'))}}>
                                    <div>
                                        <p>Modified Date</p>
                                    </div> 
                                    {arrowDirection('LastModifiedDate', 'package')}
                            </div> 


                            <p className="proposals_productValue"></p>
                            <p className="proposals_productValue"></p>
                                      
                        </div> 


                        {
                            isLoadingSystemProducts ? 
                            loadingRow()
                            :
                            systemPackageList.length > 0 && systemPackageList.map(product => 
                                <ProductRow type="package" 
                                            product={product} 
                                            setSystemPackageList={setSystemPackageList}
                                            isPackageRow 
                                            setReloadGetPackage={setReloadGetPackage}
                                           />
                                )
                            
                        }

                    </>
                    :
                    null
                }
                </div>
                
            </div>
        </div>
    )
}

export default Proposals
