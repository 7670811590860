import React, {useState } from 'react';
import '../assets/styles/DealerCard.css'
import DealerDetail from './DealerDetail';

const DealerCard = (props)=>{


    const [showSheet, setShowSheet] = useState(false);

    const showSheetHandler = ()=>{
        if(true){
            setShowSheet(!showSheet);
        }
    }


        return(


                    
            <>  
                {showSheet ?
                <DealerDetail handler={showSheetHandler} dealerId={props.Id}/>
                : null} 
                 


                <div className="dealer-card" onClick={showSheetHandler}>
                    <p className="dealer-card_value user-card_name dealer-card_value-name">{props.Name}</p>
                    <p className="dealer-card_value user-card_majorproduct">{props.MajorProductLine}</p>
                    <p className="dealer-card_value user-card_phone">{props.CreatedDate}</p>
                    <p className="dealer-card_value user-card_address">{props.City}</p>
                    <p className="dealer-card_value user-card_address">{props.State}</p>
                </div>
            
            </>
        );

}

export default DealerCard;