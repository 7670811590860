import React, { useState, useContext, useEffect } from 'react';
import '../assets/styles/DealerDetail.css';
import {AuthContext} from '../AuthContext';
import Spinner from 'react-spinner-material';
import Register from '../containers/Register'
import CreateDealer from './CreateDealer';
import iconArrorBack from '../assets/images/iconArrowBack.png';
import useIsSafari from '../hooks/useIsSafari';


const DealerDetail = (props) =>{

    const{uri} = useContext(AuthContext);

    const isSafari = useIsSafari();

    const [dealerDetail, setDealerDetail] = useState({})

    const [step, setStep] = useState(1);

    const [status, setStatus] = useState('loading')
    const [statusUsers, setStatusUsers] = useState('loading')

    const [fetchError, SetFetchError] = useState(false);

    const [errorIndex, setErrorIndex] = useState(false);

    const [canCreateApplications, setCanCreateApplications] = useState(false)
    const [canCreateProposal, setCanCreateProposal] = useState(false)
    const [canCreateReports, setCanCreateReports] = useState(false)
    const [canCreateCommercials, setCanCreateCommercials] = useState(false)

    const [showRegisterForm, setShowRegisterForm] = useState(false)

    const [reloadGetDealerDetail, setReloadGetDealerDetail] = useState(false)

    const [loadingChangeActiveInactive, setLoadingChangeActiveInactive] = useState(false)

    const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false)
    const [infoConfirmationAquaPopUp, setInfoConfirmationAquaPopUp] = useState({
        fi : '', 
        index : '', 
        permission : ''
    })
    const [checkValue, setCheckValue] = useState(false)

    const [reloadDealerList, setReloadDealerList] = useState(1)


    //CHANGE PERMISSIONS BY FINANCIAL
    const [dealersList, setDealerList] = useState([])
    const [isLoadingChangePermissionByFinancial, setIsLoadingChangePermissionByFinancial] = useState(false)
    const [errorChangingPermissionsByFinancial, setErrorChangingPermissionsByFinancial] = useState(false)
    const [successChangingPermissionsByFinancial, setSuccessChangingPermissionsByFinancial] = useState(false)

    //RECUPERAR VALORES DE SESSIONSTORAGE Y CONTEXTAPI
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const refreshToken = window.localStorage.getItem('refreshToken');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const dealerId = window.localStorage.getItem('dealerId');



     // GET DEALERS DETAILS
     useEffect(()=>{
        //setIsLoadingDetailDealer(true)
        setStatus('loading')
        


        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_GET_DEALER_DETAIL}`
        // console.log(URI)

        const abortController = new AbortController();
        const signal = abortController.signal;


        fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'DealerId' : props.dealerId,
                'Authorization' : token,   
                'x-api-key' : URI_X_API_KEY_I,   
                }
        })
        .then(response => {
            return response.json()
        })
        .then(datos => {
            //setIsLoadingDetailDealer(false)
            setStatus('view')
            setStatusUsers('view')

            // console.log(datos);

            setDealerDetail(datos);
            
            setErrorIndex(false)


            if(datos.FinancialInstitutions){
                setDealerList([...datos.FinancialInstitutions])            
            }

           
           
            if(datos.Permissions){
                datos.Permissions.map(item => {
                    if (item === "can_create_applications") {
                        setCanCreateApplications(true)
                    }else if(item === "allow_customer_proposals"){
                        setCanCreateProposal(true)
                    }else if(item === "allow_customer_reports"){
                        setCanCreateReports(true)
                    }else if(item === "can_create_commercial_applications"){
                        setCanCreateCommercials(true)
                    }
                })
            }


        })
        .catch(error => {
            console.log(error)

                    if(errorIndex === false){

                        
                        /*const URI_X_API_KEY_I = uri.URI_X_API_KEY;
                        const user = window.localStorage.getItem('user');
                        const refreshToken = window.localStorage.getItem('refreshToken');*/
                        const data = {
                            "username" : user,
                            "refresh_token" : refreshToken,
                                
                        }
                        const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;

                        window.fetch(URI_REFRESHTOKEN,{
                        method: 'POST',
                        headers: {           
                        'Content-Type': 'application/json',  
                        'x-api-key' : URI_X_API_KEY_I,         
                        },
                        body: JSON.stringify(data),
                        })
                        .then(response => response.json())
                        .then(datos => { 
                            console.log(datos)
                            window.localStorage.removeItem('token');
                            const newToken = datos.id_token;
                            window.localStorage.setItem('token', newToken);

                            SetFetchError(!fetchError);
                            setErrorIndex(true)
                        })
                    }
        });
        

        return function cleanup(){
            abortController.abort();
        }
        
    },[fetchError, reloadGetDealerDetail]);



   



    //ENVIAR PERMISO PRINCIPAL
    const changePermissionCanCreateApplications = (toggle, value)=>{

        toggle === "application" ? 
        setCanCreateApplications(!canCreateApplications) 
        : 
        toggle === "proposal" ?
        setCanCreateProposal(!canCreateProposal) 
        :
        toggle === "commercial" ?
        setCanCreateCommercials(!canCreateCommercials) 
        :
        setCanCreateReports(!canCreateReports)
      


        setErrorChangingPermissionsByFinancial(false)
        setSuccessChangingPermissionsByFinancial(false)


        // setLoadingChangeActiveInactive(true)
       

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_UPDATE_PERMISSIONS}`;
        // console.log(URI)
      

        const body = {Permissions : []}

        if(toggle === "application"){
            if(value === true){
                body.Permissions.push('can_create_applications') 
                canCreateProposal && body.Permissions.push('allow_customer_proposals') 
                canCreateReports && body.Permissions.push('allow_customer_reports') 
                canCreateCommercials && body.Permissions.push('can_create_commercial_applications') 

            }else{
                canCreateProposal && body.Permissions.push('allow_customer_proposals') 
                canCreateReports && body.Permissions.push('allow_customer_reports')
                canCreateCommercials && body.Permissions.push('can_create_commercial_applications')  
            }
        }
        
        if(toggle === "proposal"){
            if(value === true){
                body.Permissions.push('allow_customer_proposals') 
                canCreateApplications && body.Permissions.push('can_create_applications') 
                canCreateReports && body.Permissions.push('allow_customer_reports')
                canCreateCommercials && body.Permissions.push('can_create_commercial_applications') 
            }else{
                canCreateApplications && body.Permissions.push('can_create_applications') 
                canCreateReports && body.Permissions.push('allow_customer_reports')
                canCreateCommercials && body.Permissions.push('can_create_commercial_applications') 
            }
        }

        if(toggle === "commercial"){
            if(value === true){
                body.Permissions.push('can_create_commercial_applications') 
                canCreateApplications && body.Permissions.push('can_create_applications') 
                canCreateReports && body.Permissions.push('allow_customer_reports')
                canCreateProposal && body.Permissions.push('allow_customer_proposals') 
            }else{
                canCreateApplications && body.Permissions.push('can_create_applications') 
                canCreateReports && body.Permissions.push('allow_customer_reports')
                canCreateProposal && body.Permissions.push('allow_customer_proposals') 
            }
        }

        if(toggle === "reports"){
            if(value === true){
                body.Permissions.push('allow_customer_reports') 
                canCreateApplications && body.Permissions.push('can_create_applications') 
                canCreateProposal && body.Permissions.push('allow_customer_proposals') 
                canCreateCommercials && body.Permissions.push('can_create_commercial_applications') 
            }else{
                canCreateApplications && body.Permissions.push('can_create_applications') 
                canCreateProposal && body.Permissions.push('allow_customer_proposals') 
                canCreateCommercials && body.Permissions.push('can_create_commercial_applications') 
            }
        }

      
        console.log(body)

        window.fetch(URI ,{
        method: 'POST',
        headers: {           
        'Content-Type': 'application/json',  
        'x-api-key' : URI_X_API_KEY_I, 
        'DealerId' : props.dealerId, 
        'Authorization' : token,         
        }, 
        body: JSON.stringify(body),
        
        })
        .then(response => response.json())
        .then(datos => { 
            setLoadingChangeActiveInactive(false)
            // console.log('RESPUESTA ACTIVAR PERMISO')
            // console.log(datos);

            if(canCreateApplications === true){
                window.localStorage.removeItem('dealer_can_create_applications');
            }

            
        })





    }



    //ENVIAR PERMISOS SECUNDARIOS
    const postPermissionByDealer = () => {
            setIsLoadingChangePermissionByFinancial(true)
            setErrorChangingPermissionsByFinancial(false)
            setSuccessChangingPermissionsByFinancial(false)
            //console.log(dealersList)

            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_UPDATE_FI_PERMISSIONS}`;
            // console.log(URI)
            // console.log(dealersList)
            

            window.fetch(URI ,{
            method: 'POST',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : URI_X_API_KEY_I, 
            'DealerId' : props.dealerId, 
            'Authorization' : token,         
            }, 
            body: JSON.stringify(dealersList),
            
            })
            .then(response => response.json())
            .then(data => { 
                setIsLoadingChangePermissionByFinancial(false)
                // console.log(data)
                
                if(data.hasOwnProperty('status')){
                    setErrorChangingPermissionsByFinancial(true)
                }else{
                    setDealerList(data)
                    setSuccessChangingPermissionsByFinancial(true)
                }
                
            })


    }



    //HANDLER CHECK PERMISOS
    const changePermissionByDealer = (code, index, type) =>{
        
        
        const [result] = dealersList.filter(financial => financial.FinancialInstitutionId === code);
        // console.log(result)
        // const result = {...obj}

        if(type === 'fullPermission'){
            if(code === 'FI-0001'){
                if(result.CanDealerCreateCompleteApplications === true){
                    result.CanDealerCreateCompleteApplications = false
                }else{
                    result.CanDealerCreateCompleteApplications = true
                }
            }
            
            
            
            
            
            else{
                if(result.CanDealerCreateCompleteApplications === true){
                    result.CanDealerCreateCompleteApplications = false
                    // result.CanDealerCreateCompleteManualApplications = true
                }else{
                    result.CanDealerCreateCompleteApplications = true
                    if(result.CanDealerCreateCompleteManualApplications === true){
                        result.CanDealerCreateCompleteManualApplications = false
                    }
                    

                }
            }
            

        }else if(type === 'pinnacleManualApproval'){
            if(result.CanDealerCreateCompleteManualApplications === true){
                result.CanDealerCreateCompleteManualApplications = false
                // result.CanDealerCreateCompleteApplications = false
                
            }else{
                result.CanDealerCreateCompleteManualApplications = true
                if(result.CanDealerCreateCompleteApplications === true){
                    result.CanDealerCreateCompleteApplications = false
                    
                }
               
            }

            

        }else if(type === 'documentsPermission'){
            if(result.CanDealerCreateOnlyDocumentsApplications === true){
                result.CanDealerCreateOnlyDocumentsApplications = false
            }else{
                result.CanDealerCreateOnlyDocumentsApplications = true
            }

        }

      
        
        //console.log(dealersList)
        dealersList[index] = {...result}
        const newList = [...dealersList]
        //setDealerList([...dealersList, dealersList[index] = result])
        // console.log(newList[1])

        // setDealerList( dealersList.map((item, index) => {return ({...item, ...newList[index]})}  ))
        setDealerList(newList)

        
    }



    // useEffect(() => {
    //     console.log('reload')
    //     console.log(dealersList)
    //     setDealerList([...dealersList])
    // }, [reloadDealerList])










    //tabs for detail application
    const tabs = () => {      
        return (       
          <div className="dealerDetailsTabsContainer">
            <ul className="dealerDetailsTabs">
              <li className={step===1 ? 'dealerDetailsTabActive' : 'dealerDetailsTab'} onClick={()=>{setStep(1)}}>General</li>
              <li className={step===2 ? 'dealerDetailsTabActive' : 'dealerDetailsTab'} onClick={()=>{setStep(2)}}>Users</li>
              <li className={step===3 ? 'dealerDetailsTabActive' : 'dealerDetailsTab'} onClick={()=>{setStep(3)}}>Permissions</li>
              {/* <li className={step===4 ? 'dealerDetailsTabActive' : 'dealerDetailsTab'} onClick={()=>{setStep(4)}}>Roles</li>           */}
            </ul>
          </div>   
        );    
    }

    //CONFIRMATION PERMISSION AQUA POPUP
    const confirmAquaPermissionPopUp = () => {


        const {fi, index, permission} = infoConfirmationAquaPopUp;
        console.log(fi, index, permission)

        


        return(
            <div className={isSafari ? "justifyContentRight" : "confirmationPopUp"}>
                <div className="confirmationPopUp_spaceBlack"></div>
                <div className="confirmationPopUp_content">
                    <h2 className="confirmationPopUp_message">Bear in mind that any existing users will be created in Aqua Finance.</h2>
                    <div className="confirmationPopUp_buttons">
    
                        <button className="confirmationPop_confirm" onClick={()=>{setShowConfirmationPopUp(false);}}>Confirm</button>

                        <button className="confirmationPop_cancel" onClick={()=>{setShowConfirmationPopUp(false); 
                                                                                changePermissionByDealer(fi, index, permission);
                                                                                document.getElementById("check").checked = false;
                                                                            }}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }

   

  
   

    


    return(
        <>

                

        <div className="PopUp-DealerDetail" id="PopUp-DealerDetail" >
                <div className="DealerDetail-spaceBlack" onClick={props.handler}></div>
                

                    {
                    
                    step === 1 ?
                        (   
                            <div className="DealerDetailContainer">
                            {tabs()}

                            {status === 'edit' ?
                                <p className="dealerDetail_backButton" onClick={()=>{setStatus('view')}}> <img className="dealerDetail_arrowBackIcon" src={iconArrorBack} alt="Back to detail view"/> <span>Back</span> </p>
                              : null  
                            }
        
        
        
        
        
                            {status === 'loading' ? 
                            <div className="spinnerLoadingDealerDetails">
                            <Spinner
                            radius={35}
                            color={"grey"}
                            stroke={5}
                            visible={true}
                            />
                             </div>
        
        
        
        
        
                            : status === 'view' ? 


                            <div>

                            <div className="dealerDetail_content">

                                    {dealerDetail.Name ? 

                                    <div className="dealerDetail_issue">

                                        <span className="dealerDetail_value-title">Name</span>

                                        <p className="dealerDetail_value"><strong>{dealerDetail.Name}</strong></p>

                                    </div>

                                    : null}

        

                                    <div className="dealerDetail_editDealerButton-container">

                                    <button className="dealerDetail_editDealerButton" onClick={()=>{setStatus('edit')}}>Edit Dealer</button>

                                    </div>

                            </div>  




                            <div className="dealerDetail_content">

                                        

                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Address</span>

                                    <p className="dealerDetail_value">{dealerDetail.Address || 'Undefined'}</p>

                                </div>   


                                <div className="dealerDetail_issue"></div>  


                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">City</span>

                                    <p className="dealerDetail_value">{dealerDetail.City || 'Undefined'}</p>

                                </div>   


                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">State</span>

                                    <p className="dealerDetail_value">{dealerDetail.State || 'Undefined'}</p>

                                </div>   


                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">PostalCode</span>

                                    <p className="dealerDetail_value">{dealerDetail.PostalCode || 'Undefined'}</p>

                                </div>

                                <div className="dealerDetail_issue"></div>  

                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">PhoneNumber</span>

                                    <p className="dealerDetail_value">{dealerDetail.PhoneNumber || 'Undefined'}</p>

                                </div>

                                <div className="dealerDetail_issue"></div>  


                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Primary Contact Email</span>

                                    <p className="dealerDetail_value">{dealerDetail.PrimaryContactEmail || 'Undefined'}</p>

                                </div>

                                <div className="dealerDetail_issue"></div> 




                                <div className="dealerDetail_issue">

                                <span className="dealerDetail_value-title">Is Retail Dealer</span>

                                <p className="dealerDetail_value">{dealerDetail.IsRetailDealer === true ? 'Yes' : 'No' }</p>

                                </div >


                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Is Open On Saturday</span>

                                    <p className="dealerDetail_value">{dealerDetail.IsOpenOnSaturday === true ? 'Yes' : 'No'}</p>

                                </div>




                                
                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Major Product Line</span>

                                    <p className="dealerDetail_value">{dealerDetail.MajorProductLine || 'Undefined'}</p>

                                </div>

                                
                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Website</span>

                                    <p className="dealerDetail_value">{dealerDetail.Website || 'Undefined'}</p>

                                </div>  
        

                                
                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Affiliate Name</span>

                                    <p className="dealerDetail_value">{dealerDetail.AffiliateName || 'Undefined'}</p>

                                </div>

                                <div className="dealerDetail_issue"></div>  
                                

                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Next Attestation Date</span>

                                    <p className="dealerDetail_value">{dealerDetail.NextAttestationDate || 'Undefined'}</p>

                                </div>

                                <div className="dealerDetail_issue"></div>  

                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">DealerAlly Fee Amount</span>

                                    <p className="dealerDetail_value">{dealerDetail.DAFeeAmount || 'Undefined'}</p>

                                </div>

                                <div className="dealerDetail_issue"></div> 

        
                                <div className="dealerDetail_issue">
                                    <p className="dealerDetail_value-titleMain">AquaFinance</p>
                                </div>

                                <div className="dealerDetail_issue"></div>
                                
                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">AquaFinance Dealer Number</span>

                                    <p className="dealerDetail_value">{dealerDetail.AquaFinanceDealerNumber || 'Undefined'}</p>

                                </div>



                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">AquaFinance External Id</span>

                                    <p className="dealerDetail_value">{dealerDetail.AquaFinanceExternalId || 'Undefined'}</p>

                                </div>
                               

                                   
                                <div className="dealerDetail_issue">
                                    <p className="dealerDetail_value-titleMain">Pinnacle</p>
                                </div>

                                <div className="dealerDetail_issue"></div>
                                
                                        
                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Pinnacle External Id</span>

                                    <p className="dealerDetail_value">{dealerDetail.PinnacleExternalId || 'Undefined'}</p>

                                </div>


                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Pinnacle Dealer APIKey</span>

                                    <p className="dealerDetail_value">{dealerDetail.PinnacleDealerAPIKey || 'Undefined'}</p>

                                </div>


                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Pinnacle Dealer Username</span>

                                    <p className="dealerDetail_value">{dealerDetail.PinnacleDealerUsername || 'Undefined'}</p>

                                </div>



                                <div className="dealerDetail_issue">

                                    <span className="dealerDetail_value-title">Pinnacle Dealer Password</span>

                                    <p className="dealerDetail_value">{dealerDetail.PinnacleDealerPassword || 'Undefined'}</p>

                                </div>

                                             


                            </div>

                            </div>

        
        
                            // <CreateDealer reloadGetDealer={()=>{setStatus('view'); setReloadGetDealerDetail(!reloadGetDealerDetail)}} dealerData={dealerDetail} dealerId={dealerDetail.Id}/>
        
                            : status === 'edit' ? 
                                <CreateDealer reloadGetDealer={()=>{setStatus('view'); setReloadGetDealerDetail(!reloadGetDealerDetail)}} dealerData={dealerDetail} dealerId={dealerDetail.Id}/>
                            : null
                            }


                             {/* PRODUCTS */}

                             {status === 'view' ?

        

                            dealerDetail.Products ?



                            <div className="subContainer">

                                <span className="dealerDetail_value-titleMain">Products</span>

                                {

                                    dealerDetail.Products.map((item, index)=>{

                                        return(

                                            <div className="dealerDetail_issue" key={index}>

                                                

                                                <ul className="dealerDetail_productContainer">

                                                    <li className="dealerDetail_product-issue">Name: {item.Name}</li>

                                                    <li className="dealerDetail_product-issue">Code: {item.Code}</li>

                                                    <li className="dealerDetail_product-issue">Is Home Improvement: {item.IsHomeImprovement === true ? 'Yes' : 'No'}</li>

                                                    <li className="dealerDetail_product-issue">Is Major Product: {item.IsMajorProduct === true ? 'Yes' : 'No'}</li>

                                                    

                                                </ul>

                                            </div>



                                        )

                                    })

                                }

                            </div>



                            : 

                            <div className="subContainer">

                                <p className="dealerDetail_value-titleMain">Products</p>

                                <p>No Products added</p>

                            </div>

                            : null}







                            {/* VERTICALS */}

                            {status === 'view' ?



                            dealerDetail.Verticals ? 



                            <div className="subContainer">

                                <span className="dealerDetail_value-titleMain">Verticals</span>

                                {

                                    dealerDetail.Verticals.map((item, index)=>{

                                        return(

                                            <div className="verticalCard" key={index}><p className="vertical">{item.Value}</p></div>

                                            

                                        )

                                    })



                                }

                            </div>



                            : 

                            <div className="subContainer">

                                <p className="dealerDetail_value-titleMain">Verticals</p>

                                <p>No Verticals added</p>

                            </div>



                            : null



                            }





                            {/* OPTIONS */}

                            {status === 'view' ?



                            dealerDetail.Options? 



                            <div className="subContainer">

                                <span className="dealerDetail_value-titleMain">Options</span>

                                {

                                    dealerDetail.Options.map((item, index)=>{

                                        return(

                                            <div className="optionCard" key={index}>

                                                <p className="option"><span>Code: </span><span>{item.Code}</span></p> 

                                                <p className="option"><span>Description: </span><span>{item.Description}</span></p> 

                                            </div>

                                            

                                        )

                                    })



                                }

                            </div>



                            : 

                            <div className="subContainer">

                                <p className="dealerDetail_value-titleMain">Options</p>

                                <p>No Options added</p>

                            </div>



                            : null



                            }   
                            
        
        
        
        
        
        
                            
        
                          
        
                            
                            
        
                        </div>
                        )
                    : step === 2 ?
                        (
                            <div className="DealerDetailContainer">
                                {tabs()}
                                {statusUsers === 'edit' ?
                                    <p className="dealerDetail_backButton" onClick={()=>{setStatusUsers('view'); console.log('click')}}> <img className="dealerDetail_arrowBackIcon" src={iconArrorBack} alt="Back to detail view"/> <span>Back</span> </p>
                                : null  
                                }
                                
                                {statusUsers === 'view' ?
                                    <>
                                        <div className="dealerDetail_content">
                                                                                        
                                            <div className="dealerDetail_issue">

                                                <p className="dealerDetail_value"><strong>Users</strong></p>
                                            </div>


                                            <div className="dealerDetail_editDealerButton-container">
                                            <button className="dealerDetail_editDealerButton" onClick={()=>{setStatusUsers('edit')}}>Create User</button>
                                            </div>

                                        </div>  
                                    
                                        {

                                        dealerDetail.Users ?
                                            
                                                
                                            <div className="dealerDetail_user-containerlist">
                                                {dealerDetail.Users.length > 0 ? 
                    
                                                    dealerDetail.Users.map((item, index) => {
                                                        return(
                                                            <div className="dealerDetail_userCard" key={index}>
                                                                <p className="dealerDetail_userName">{`${item.first_name} ${item.last_name}`}</p>
                                                                <p className="dealerDetail_userRole">{item.role}</p>
                                                                <p className="dealerDetail_userItem">{item.email}</p>
                                                                <p className="dealerDetail_userItem">{item.phone_number}</p>                        
                                                                <p className="dealerDetail_userItem dealerDetail_userItem-status">{item.status}</p>
                                                                <p className="dealerDetail_userItem">{item.username}</p>
                                                            </div>
                                                        )
                                                    })
                                                

                                                : <span>There are no registered users in this Dealer</span>
                                                }
                                            </div> 

                                            :  
                                            
                                            <span>There are no registered users in this Dealer</span>
                                        }
                                                    
                        
                                        
                                    

                                    </>


                                : statusUsers === 'edit' ?

                                

                                <div className="dealerDetail-registerContainer">
                                    <Register safari dealerIdProp={dealerDetail.Id}/>
                                </div>

                                : null

                                }
                                        
                            </div>
                        )
                    : step === 3 ?
                        (
                            <div className="DealerDetailContainer">
                                {tabs()}

                                <div className="activateUser">
                                <h3>Reporting</h3>
                                <label className="switch">
                                <input 
                                    type="checkbox" 
                                    // nameName="isActive" 
                                    //id="isActive" 
                                    {...canCreateReports ? { checked: "checked" } : null} 
                                    {...loadingChangeActiveInactive && { disabled: "disabled" }} 
                                    onChange={()=> changePermissionCanCreateApplications('reports', canCreateReports ? false : true)} 
                                />
                                <span className="slider round"></span>
                                </label>
                                </div>


                                <div className="activateUser">
                                <h3>Customer Proposal</h3>
                                <label className="switch">
                                <input 
                                    type="checkbox" 
                                    // nameName="isActive" 
                                    //id="isActive" 
                                    {...canCreateProposal ? { checked: "checked" } : null} 
                                    {...loadingChangeActiveInactive && { disabled: "disabled" }} 
                                    onChange={()=> changePermissionCanCreateApplications('proposal', canCreateProposal ? false : true)} 
                                />
                                <span className="slider round"></span>
                                </label>
                                </div>


                                <div className="activateUser">
                                <h3>Can Create Commercial Applications</h3>
                                <label className="switch">
                                <input 
                                    type="checkbox" 
                                    // nameName="isActive" 
                                    //id="isActive" 
                                    {...canCreateCommercials ? { checked: "checked" } : null} 
                                    {...loadingChangeActiveInactive && { disabled: "disabled" }} 
                                    onChange={()=> changePermissionCanCreateApplications('commercial', canCreateCommercials ? false : true)} 
                                />
                                <span className="slider round"></span>
                                </label>
                                </div>

                                
                                <div className="activateUser">
                                <h3>Can Create Residential Applications</h3>
                                <label className="switch">
                                <input 
                                    type="checkbox" 
                                    // nameName="isActive" 
                                    //id="isActive" 
                                    {...canCreateApplications ? { checked: "checked" } : null} 
                                    {...loadingChangeActiveInactive && { disabled: "disabled" }} 
                                    onChange={()=> changePermissionCanCreateApplications('application', canCreateApplications ? false : true)} 
                                />
                                <span className="slider round"></span>
                                </label>
            





                                {
                                    canCreateApplications ?
                                        <div className="dealerDetail_checkboxSection">
                                            {
                                                (
                                                    dealersList.map((financialI, index)=>
                                                        (   
                                                            <div className="filter_CheckContainer-item" key={financialI.FinancialInstitutionId }>
                                                                <h3 className="filter_CheckContainer-item-title">{financialI.FinancialInstitutionName}</h3>
                                                                <div className="filter_CheckContainer">
                                                                        <label className="containercheck">
                                                                            <span className="filter_CheckSpan">{financialI.FinancialInstitutionId === 'FI-0001' ? 'Can create Complete applications' : 'Can create complete applications - blanket approval'}</span>

                                                                            <input type="checkbox" 
                                                                                name="radio" 
                                                                                {...financialI.CanDealerCreateCompleteManualApplications && { disabled: "disabled" }} 
                                                                                {...financialI.CanDealerCreateCompleteApplications ? { checked: "checked" } : null} 
                                                                                id={financialI.FinancialInstitutionId === 'FI-0001' && 'check'}
                                                                                    
                                                                                onChange={()=>{

                                                                                    if(financialI.FinancialInstitutionId === 'FI-0001' && !financialI.CanDealerCreateCompleteApplications){
                                                                                        setShowConfirmationPopUp(true);
                                                                                        changePermissionByDealer(financialI.FinancialInstitutionId, index, 'fullPermission')
                                                                                        setInfoConfirmationAquaPopUp({fi: financialI.FinancialInstitutionId, index:index, permission:'fullPermission'});

                                                                                    }else{
                                                                                        changePermissionByDealer(financialI.FinancialInstitutionId, index, 'fullPermission')}
                                                                                    }}
                                                                                                
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>    
                                                                </div> 


                                                                {
                                                                    financialI.FinancialInstitutionId === 'FI-0002' &&
                                                                    <div className="filter_CheckContainer">
                                                                        <label className="containercheck">
                                                                        <span className="filter_CheckSpan">Can create complete applications - manual approval </span>
                                                                        <input type="checkbox" 
                                                                                name="radio" 
                                                                                {...financialI.CanDealerCreateCompleteApplications && { disabled: "disabled" }} 
                                                                                {...financialI.CanDealerCreateCompleteManualApplications ? { checked: "checked" } : null} 
                                                                                onChange={()=>{changePermissionByDealer(financialI.FinancialInstitutionId, index, 'pinnacleManualApproval')}}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                        </label>      
                                                                    </div>  
                                                                }
                                                                



                                                                <div className="filter_CheckContainer">
                                                                    <label className="containercheck">
                                                                    <span className="filter_CheckSpan">Can Create Only documents applications</span>
                                                                    <input type="checkbox" 
                                                                            name="radio" 
                                                                           
                                                                            {...financialI.CanDealerCreateOnlyDocumentsApplications ? { checked: "checked" } : null} 
                                                                            onChange={()=>{changePermissionByDealer(financialI.FinancialInstitutionId, index, 'documentsPermission')}}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                    </label>      
                                                                </div> 
                                                                <hr className="filter_CheckContainer-line"/>


                                                            </div>
                                                            
                                                        )
                                                    )  
                                                )
                                            }




























                                            {
                                                isLoadingChangePermissionByFinancial ?
                                                <div className="dealerDetail_changePermissionsbyDealer-button-loading">
                                                    <Spinner
                                                        radius={15}
                                                        color={"white"}
                                                        stroke={3}
                                                        visible={true}
                                                    />
                                                </div>
                                                : dealersList.length > 0 ?
                                                <button className="dealerDetail_changePermissionsbyDealer-button" onClick={postPermissionByDealer}>Save changes</button>
                                                : null
                                                
                                            }
                                        
                                            {
                                                errorChangingPermissionsByFinancial && <p className="dealerDetail_changePermissionsbyDealer-message-red">An error occurred updating the permissions</p>
                                            }
                                            {
                                                successChangingPermissionsByFinancial && <p className="dealerDetail_changePermissionsbyDealer-message-green">Permissions updated successfully</p>
                                            }

                                            {
                                                showConfirmationPopUp && confirmAquaPermissionPopUp()
                                            }               

                                            
                                        </div>
                                    : null
                                }  

                                    
                                                        
                                </div>


                                

                            </div>
                        )
                    :   
                    null

                    }   
                    
                   

        </div>
        </>
    )
}

export default DealerDetail;