import React, { useState, useContext, useEffect, createContext} from 'react';
import '../../assets/styles/ApplicationsCard.css'
import '../../assets/styles/CommercialApplications.css'
import {AuthContext} from '../../AuthContext';
import Sheet from '../Sheet';
import ApplicationFormAquaFinance from "../ApplicationFormAquaFinance"
import iconApplicationDetail from "../../assets/images/iconApplicationDetail.png"
import PinnacleStubForm from '../PinnacleStubForm'
import ApplicationFormPinnacle from '../ApplicationFormPinnacle';
import iconRefresh from '../../assets/images/iconRefresh.png';
import Spinner from 'react-spinner-material';
import usePolling from '../../hooks/usePolling'
import moment from 'moment-timezone';
import ExternalForm from '../ExternalForm'
import ConvertProspect from '../ConvertProspect'
import CreateCommercialLoan from '../../containers/CreateCommercialLoan'
import CommercialLoan from './CommercialLoan';
import { Icon } from '@iconify/react';
import {Link} from 'react-router-dom';




export const ApplicationCardContext = createContext({})
const { Provider } = ApplicationCardContext



const CommercialLoanCard = ({Id, Lender, CommercialStatus, CreatedDate, CreatedBy, Dealer, LegalCompanyName, Submitted, LastModifiedDate, EquipmentPrice})=>{



    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);
   

    const [showSheet, setShowSheet] = useState(false);

    const [newApplicationStatus, setNewApplicationStatus] = useState(false);

    const [newFinancialInstitutionNumber, setNewFinancialInstitutionNumber] = useState(false)
    const [newFinancialInstitutionId, setNewFinancialInstitutionId] = useState(false)

    const [refresh, setRefresh] = useState(false)

    const [loadingNewStatus, setLoadingNewStatus] = useState(false)

    const [pollingCounter, setPollingCounter] = useState(0);
   



    const showSheetHandler = ()=>{
        setShowSheet(!showSheet);

    }


    const dealerId = window.localStorage.getItem('dealerId');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const token = window.localStorage.getItem('token');
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const userRole = localStorage.getItem('userRole');



    //REFRESH STATUS APPLICANT
    // const refreshStatus = (showAnimation)=>{

    // showAnimation && setLoadingNewStatus(true);  
   
    // const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}fi/${uri.URI_GET_APPLICATION_STATUS}${props.application.ApplicationId || props.application.ProspectLoanApplicationId}`
    // //console.log(URI)


    
  
    // window.fetch(URI,{
    //     method: 'GET',
    //     headers: {
    //         'Access-Control-Request-Headers': 'application/json',           
    //         'Content-Type': 'application/json', 
    //         'DealerId' : props.application.DealerId,
    //         'Authorization' : token,  
    //         'x-api-key' : URI_X_API_KEY_I,       
    //       }
    // })
    // .then(response => {

    //     if (response.ok) {
    //         return response.json();
    //       }
    //     return Promise.reject(response);
    // })
    // .then(datos => {
    //     setLoadingNewStatus(false);
    //     // console.log(datos)

    //     if(datos.hasOwnProperty('LoanApplicationStatus')){
    //         setNewApplicationStatus(datos.LoanApplicationStatus)
    //     }

    //     if(datos.hasOwnProperty('Details')){
    //         setNewFinancialInstitutionNumber(datos.Details.Customer)
    //     }else if(datos.hasOwnProperty('FIApplicationId') && datos.hasOwnProperty('FIApplicationNumber')){
            
    //         setNewFinancialInstitutionNumber(datos.FIApplicationNumber);
    //         setNewFinancialInstitutionId(datos.FIApplicationId)
    //     }

    //     setErrorFlagFromContext(false)
        
       
    // }).catch(error => {
    //     console.log(error)
    //     setRefresh(true)
    //     setLoadingNewStatus(false);
    //     if(errorFlagFromContext === false && error.status === 401){
    //         changeToken().then(response => console.log(response)).catch(error => console.log(error))
    //     } 
        
    // })
  
    
       
    // }




    //REFRESH STATUS WHEN OPEN AND CLOSE DETAILS
    // useEffect(() => {

    //     const localTime = new Date().getTime()
    //     const today = moment(localTime).tz('America/New_York').format('YYYY-MM-DD')   
    //     const ny = moment(localTime).tz('America/New_York');
    //     const time8AM = moment.tz(today + " 08:00", "America/New_York");
    //     const timeMidNight = moment.tz(today + " 23:59", "America/New_York");

    

    //     if(showSheet || refresh){
    //             if( props.application.FinancialInstitutionId === 'FI-0002'){

    //                 if(ny > time8AM && ny < timeMidNight){
    //                     if( props.application.LoanApplicationStatus !== 'SignReady' && props.application.LoanApplicationStatus !== 'Error' && newApplicationStatus !== 'SignReady' && newApplicationStatus !== 'Error'){
    //                         // console.log('x')
    //                         refreshStatus()
    //                     } 
    //                 }        
    //             }else if(props.application.FinancialInstitutionId === 'FI-0001'){
                   
    //                 if( props.application.LoanApplicationStatus !== 'Error'  && newApplicationStatus !== 'Error'){
    //                     refreshStatus()
    //                 }
                   
    //             } 
    //     }
        
    // }, [showSheet])






return(
    <Provider value ={{
        showSheet,
        setShowSheet
    }}>

    {showSheet &&
    <Sheet handler={showSheetHandler}>
       
       <CommercialLoan applicationId={Id} inspectMode/>
        
        
    </Sheet>}





    <div  className="applicationCard_card">

        <div className="applicationCard_card-header">
            <div className="applicationCard_header-titleContainer">
                <h2 className="applicationCard_header-numer">{`${Id} - ${LegalCompanyName}`}</h2> 
                {/* <span className="applicationCard_header-financialentity">{Lender} </span> */}

                  
            </div>

            <div className="applicationCard_header-buttonsContainer">
                {
                    Submitted ?
                    <button className="applicationCard_header-DetailsButton" onClick={showSheetHandler}>
                        <img src={iconApplicationDetail} className="applicationCard_header-DetailsButton-img"/>
                        <p className="applicationCard_header-DetailsButton-text" >Details</p>
                    </button>
                    :
                    <Link  to={`/commercial/${Id}`} className="applicationCard_header-DetailsButton" onClick={showSheetHandler}>
                        <Icon width="22px" color={"31D902"} icon="ci:external-link" />
                        <p className="applicationCard_header-DetailsButton-text" >Continue Application</p>
                    </Link>
                }
                    

                    

                {
                    newApplicationStatus ? 

                    <p className={newApplicationStatus === 'Approved' ? "applicationCard_header-status-approved" 
                                :newApplicationStatus === 'SignReady' ? "applicationCard_header-status-approved" 
                                :newApplicationStatus === 'Denied' ? "applicationCard_header-status-denied" 
                                :newApplicationStatus === 'Pending' ? "applicationCard_header-status-pending" 
                                :newApplicationStatus === 'Error' ? "applicationCard_header-status-denied" 
                                :newApplicationStatus === 'FilledOut' ? "applicationCard_header-status-FilledOut"
                                : "applicationCard_header-status-FilledOut" } >{newApplicationStatus}
                    </p>

                    :

                    <p className={CommercialStatus === 'Approved' ? "applicationCard_header-status-approved" 
                                    :CommercialStatus === 'SignReady' ? "applicationCard_header-status-approved" 
                                    :CommercialStatus === 'Denied' ? "applicationCard_header-status-denied" 
                                    :CommercialStatus === 'Pending' ? "applicationCard_header-status-pending" 
                                    :CommercialStatus === 'Error' ? "applicationCard_header-status-denied" 
                                    :CommercialStatus === 'FilledOut' ? "applicationCard_header-status-FilledOut"
                                    : "applicationCard_header-status-FilledOut" } >{CommercialStatus}
                    </p>
                }
                
                {/* {
                    loadingNewStatus ?

                    <Spinner
                    radius={22}
                    color={"grey"}
                    stroke={3}
                    visible={true}
                    />

                    :
                    
                    
                    props.application.DealerId ?
                    
                    <img src={iconRefresh} className="applicationCard_header-refreshIcon" onClick={refreshStatus}/>

                    : null

                } */}



            </div>
        </div>

        
        <div className='commercialLoanCard_bodyContainer'>
            <div>
                <p className="applicationCard_label">Creation Date:</p>
                <p className="">{CreatedDate}</p>
            </div>
            <div>
            <p className="applicationCard_label">Modified Date:</p>
            <p className="">{LastModifiedDate}</p>
            </div>
            <div>
            <p className="applicationCard_label">Created By:</p>
            <p className="">{CreatedBy}</p>
            </div>
            <div>
            <p className="applicationCard_label">Dealer:</p>
            <p className="">{Dealer}</p>
            </div>
            <div>
            <p className="applicationCard_label">Equipment Price:</p>
            <p className="">{parseFloat(EquipmentPrice).toFixed(2)}</p>
            </div>
        </div>


       
    </div>
    
    </Provider>
)};

export default CommercialLoanCard;