import React, {useState, useEffect} from 'react'
import '../assets/styles/AlertBox.css'

const AlertBox = ({type, title, text, showAlert}) => {

    const [show, setShow] = useState(showAlert)

    const dismissAlert = () => {
        setShow(false)
    }

    useEffect(() => {
        setShow(showAlert)
    }, [showAlert])

    
    return (    
        show ? (
           
                <div className={type === 'success' ? "AlertBox AlertGreen" : "AlertBox AlertRed"}>
                    <div>
                        {
                            type === 'success' ?  
                            <figure className="AlertBox_Icon AlertIcon_check">
                                &#10003;
                            </figure> 
                            :
                            <figure className="AlertBox_Icon AlertIcon_x">
                                &#10005;
                            </figure> 
                        }  
                    </div>
                    <div>
                        {title && <h3 className="AlertBox_title">{title}</h3>}
                        {text && <p className="AlertBox_text">{text}</p>}
                    </div>
                    <div className="AlertBox_close" >
                        <span onClick={dismissAlert}>&#10005;</span>
                    </div>
                </div>

     
           
        ) : null
        
        
    )
}

export default AlertBox
