import React from 'react'
import '../assets/styles/AddProductPopUp.css'

const PopUp = ({setShowSheet, title, children, edit, fullHeight, width800}) => {
    return (
        <>
        <div className="addProposalProduct" id="sheet">
            <div className="addProposalProduct_blackspace" onClick={()=>edit ? setShowSheet(state => ({show :!state.show, id: '123'})) : setShowSheet(state => !state)}>

            </div>
      
            <div className={width800 ? "addProposalProduct_container popUp_fullHeight width800"  : fullHeight ? "addProposalProduct_container popUp_fullHeight" : "addProposalProduct_container"} 
                 id="shetContainer">
                <div className="addProposalProduct_container-header">
                    <h3>{title}</h3>
                    <span className="addProposalProduct_container-header-x" onClick={()=>setShowSheet(state => !state)}>&#215;</span>
                </div>
               
                <div>
                    {children}
                </div>
            
            </div>       
        </div>  
    </>
    )
}

export default PopUp
