import React, {useState, useEffect, useContext} from 'react'
import '../assets/styles/CompleteApplication.css'
import ExternalForm from '../components/ExternalForm'
import {AuthContext} from '../AuthContext';
import { useParams } from 'react-router-dom';
import ConvertProspect from '../components/ConvertProspect'

//URL: PROSPECTAPPLICATIONS
const CompleteApplication = () => {

    const formId = useParams().id;


    return (
        <div className="completeApplication_mainContainer">
            <div className="completeApplication_container">
                <ConvertProspect detailsId={formId} />            
            </div>
        </div>
    )
}

export default CompleteApplication;
