import React, {useEffect, useState} from 'react'
import '../../assets/styles/AddProductPopUp.css'
import Tabs from '../Tabs'
import useIsSafari from '../../hooks/useIsSafari'

const AddProductPopUp = ({setShowSheet, opcionalProducts, addProduct, paymentFactor, hasPaymentFactor, showMonthly, showPrice, title}) => {

  
    const [normalProducts, setNormalProducts] = useState([])
    const [bundleProducts, setBundleProducts] = useState([])
    const [step, setStep] = useState(1)
    const [termSearch, setTermSearch] = useState('')
    const isSafari = useIsSafari()

    //LLENAR LAS PESTAÑAS
    useEffect(() => {

        if(opcionalProducts && title === 'Add Product'){
            // console.log(opcionalProducts)
            let normalProducts = []
            let bundleProducts = []
    
            opcionalProducts.map(product => {
                if(product.ProductList){
                    // bundleProducts.push(product)
                    bundleProducts = [...bundleProducts, product]
                }else{
                    // normalProducts.push(product)
                    normalProducts = [...normalProducts, product]
                }
            })
    
            setNormalProducts(normalProducts)
            setBundleProducts(bundleProducts)
    
            // console.log(normalProducts)
            // console.log(bundleProducts)


        }else{
            setNormalProducts(opcionalProducts)
        }
        
       
    }, [])


    const searchFilter = (list) => {
        return list.filter(product => product.Name.toLowerCase().includes(termSearch.toLowerCase()))
    }



    return (
        <>

            <div className="addProposalProduct" id="sheet">
                <div className="addProposalProduct_blackspace" onClick={()=>setShowSheet(state => !state)}>

                </div>
          

                <div className={isSafari ? "addProposalProduct_container isSafari" : "addProposalProduct_container"} id="shetContainer">
                    <div className="addProposalProduct_container-header">
                        <h3>{title}</h3>
                        <span className="addProposalProduct_container-header-x" onClick={()=>setShowSheet(state => !state)}>&#215;</span>
                    </div>

                    {
                        title === 'Add Product' && 
                        <Tabs 
                            step={step} 
                            setStep={setStep}
                            tabList={['Products', 'Bundle Products']}
                        />
                    }

                    <input 
                        type="text" 
                        className="bundleItemList_input" 
                        placeholder="Search"
                        value={termSearch}
                        onChange={e => setTermSearch(e.target.value)}
                    />
                   
                   

                    <div className="addProposalProduct_productList">
                            <div className="addProposalProduct_productRow" >
                                <p className="addProposalProduct_productRow-title">Name</p>
                                <p className="addProposalProduct_productRow-title">{showPrice && title === 'Add Product' ? 'Price' : title !== 'Add Product' ? 'Monthly' : '' }</p>
                                <p className="addProposalProduct_productRow-title">{showMonthly && title === 'Add Product' && 'Monthly'}</p>
                            </div>

                        {   
                         
                         
                        
                            step === 1 ?
                            <>
                            {
                                 normalProducts && searchFilter(normalProducts).map(product => 
                                    <div className="addProposalProduct_productRow" key={product.DealerProposalProductId}>
                                        <p className="addProposalProduct_productRow_item">{product.Name}</p>
                                        <p className="addProposalProduct_productRow_item">{ showPrice && title === 'Add Product' ? `$ ${product.ProductPrice}`  : title !== 'Add Product' ? `$ ${product.ProductPrice}`  : '' }</p>
                                        {title === 'Add Product' ? <p className="addProposalProduct_productRow_item">{showMonthly && hasPaymentFactor ? `$ ${(paymentFactor * product.ProductPrice).toFixed(2)}` : ''}</p> : <span></span>}                               
                                        <button className="addProposalProduct_productAddButton" onClick={()=>addProduct(product.Name, 1, product.DealerProposalProductId, product.ProductPrice, (product.ProductList && product.ProductList))}>Add</button>
                                    </div>
                                ) 
                            }
                            </>
                            :
                            step === 2 ?
                            <>
                            {
                                 bundleProducts && searchFilter(bundleProducts).map(product => 
                                    <div className="addProposalProduct_productRow" key={product.DealerProposalProductId}>
                                        <p className="addProposalProduct_productRow_item">{product.Name}</p>
                                        <p className="addProposalProduct_productRow_item">{ showPrice && title === 'Add Product' ? `$ ${product.ProductPrice}`  : title !== 'Add Product' ? `$ ${product.ProductPrice}`  : '' }</p>
                                        {title === 'Add Product' ? <p className="addProposalProduct_productRow_item">{showMonthly && hasPaymentFactor ? `$ ${(paymentFactor * product.ProductPrice).toFixed(2)}` : ''}</p> : <span></span>}                               
                                        <button className="addProposalProduct_productAddButton" onClick={()=>addProduct(product.Name, 1, product.DealerProposalProductId, product.ProductPrice, (product.ProductList && product.ProductList))}>Add</button>
                                    </div>
                                ) 
                            }
                            </>
                            :
                            null
                        
                               
                        }                          
                    </div>    
                    

                </div>
        </div>  
        </>
    )
}

export default AddProductPopUp;
