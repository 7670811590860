import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/CreateDealer.css'
import '../assets/styles/ApplicationForm.css'
import {AuthContext} from '../AuthContext';
import Notification from '../components/Notification';
import iconSadFace from '../assets/images/iconSadFace.png';
import iconCheck from '../assets/images/iconCheck.png';
import SpinnerPopUp from '../components/SpinnerPopUp';
import iconDelete from '../assets/images/iconDelete.png';
import useIsSafari from '../hooks/useIsSafari'
require('dotenv').config();

const CreateDealer = (props)=>{

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken, firstReloadFunction} = useContext(AuthContext);

    const isSafari = useIsSafari()

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isLoadingCreateDealer, setIsLoadingCreateDealer] = useState(false);

    const [reloadPostForm, setRealoadPostForm] = useState(false)
  
    const [data, setData] = useState({
        AquaFinanceDealerNumber: null,
        PinnacleDealerNumber: null,
        MajorProductLine: null,
        AffiliateName: null,
        City: null,
        State: null,
        PostalCode: null,
        Address: null,
        Name: null,
        PhoneNumber: null,
        IsRetailDealer: false,
        NextAttestationDate: null,
        IsOpenOnSaturday: false,
        AquaFinanceExternalId: null,
        PinnacleExternalId: null,
        PrimaryContactEmail: null,
    });
    
  

    //PRODUCTS
    const [dealerProductList, setdealerProductList] = useState([]);
    const [errorProductList, seterrorProductList] = useState([{}])
    const [isSubmitiongDealerProducts, setIsSubmitingDealerProducts] = useState(false);

    //OPTIONS
    const [dealerOptionList, setdealerOptionList] = useState([]);
    const [errorOptionList, seterrorOptionList] = useState([{}])
    const [isSubmitiongDealerOptions, setIsSubmitingDealerOptions] = useState(false);

    //VERTICALS
    const [dealerVerticalList, setdealerVerticalList] = useState([]);
    const [errorVerticalList, seterrorVerticalList] = useState([{}])
    const [isSubmitingDealerVertical, setIsSubmitingDealerVertical] = useState(false);

  
  

    const [showNotificationOk, SetShowNotificationOk] = useState(false);
    const [showNotificationErrorDealerExist, SetShowNotificationErrorDealerExist] = useState(false);
    const [showNotificationInternalServerError, SetShowNotificationInternalServerError] = useState(false);

    //RECUPERAR VALORES DE SESSION STORAGE Y CONTEXT API
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const user = window.localStorage.getItem('user');
    const token = window.localStorage.getItem('token');
    const refreshToken = window.localStorage.getItem('refreshToken');



    //CAMBIAR EL TOKEN AL ENTRAR EN ESTE COMPONENTE
    useEffect(()=>{


      
      
      const data = {
          "username" : user,
          "refresh_token" : refreshToken,
      }

      const abortController = new AbortController();
      const signal = abortController.signal;

      const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
      window.fetch(URI_REFRESHTOKEN ,{
      method: 'POST',
      signal: signal,
      headers: {           
      'Content-Type': 'application/json',  
      'x-api-key' : URI_X_API_KEY_I,          
      },
      body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(datos => { 
          //console.log(datos)
          window.localStorage.removeItem('token');
          const newToken = datos.id_token;
          window.localStorage.setItem('token', newToken);

      })

      return function cleanup(){
      abortController.abort();
      }
  }, []);


   


 
        //SUBMIT REGISTER FORM
        const submitRegister = async () => {
          //event.preventDefault();
  
         setIsLoadingCreateDealer(true);
      
  
          const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}`
          console.log(URI)

          
  
          const info = {
              ...data,
              Products: dealerProductList,
              Options: dealerOptionList,
              Verticals: dealerVerticalList,
          }

           delete info.Users;

          if(props.type === 'edit'){
            info.Id = props.dealerId
          }
  
          console.log(info)
          console.log(JSON.stringify(info))
        

          try {
              const rawResponse = await window.fetch(URI, {
                method: 'POST',
                headers: {
                  //'Access-Control-Request-Headers': 'application/json',           
                  'Content-Type': 'application/json', 
                  'username' : user,
                  'Authorization' : token,
                  'x-api-key' : URI_X_API_KEY_I,
                },
                body: JSON.stringify(info),
              
              });
              console.log(rawResponse)
              setIsLoadingCreateDealer(false);
      
              
              const content = await rawResponse.json();
              console.log(content);
      
              if(content.status === 'SUCCESS'){
                SetShowNotificationOk(true)
              }else if(content.status === 'INTERNAL_SERVER_ERROR'){
                SetShowNotificationInternalServerError(true)
              }else if(content.status === 'DEALER_ALREADY_SET'){
                SetShowNotificationErrorDealerExist(true)
              }

              setErrorFlagFromContext(false)
          } catch (error) {
              console.log(error)

              if(errorFlagFromContext === false ){
                  changeToken().then(response => {
                    console.log(response)
                    setRealoadPostForm(!reloadPostForm)
                  })
              }
          }
                 
        };





        //LLENAR LOS CAMPOS PARA EDITAR
        useEffect(() => {
          if(props.hasOwnProperty('dealerData')){
            
  
            console.log(props.dealerData)

            const datos = props.dealerData;
            // delete datos.Users;
            // delete datos.Options;
            // delete datos.Products;
            // delete datos.Verticals;
            //delete datos.Permissions;

            setData({...data, ...datos})
          
            //Products
            if(props.dealerData.Products){
              const errorsProducts = props.dealerData.Products
              const numErrorsProducts = errorsProducts.map(item => { return {}})
              seterrorProductList(numErrorsProducts)
              setdealerProductList(props.dealerData.Products)
            }
            


            //Options
            if(props.dealerData.Options){
              const errorsOptions = props.dealerData.Options
              const numErrorsOptions = errorsOptions.map(item => { return {}})
              seterrorOptionList(numErrorsOptions)
              setdealerOptionList(props.dealerData.Options)
            }
            

            //Verticals
            if(props.dealerData.Verticals){
              const errorsVerticals = props.dealerData.Verticals
              const numErrorsVerticals = errorsVerticals.map(item => { return {}})
              seterrorVerticalList(numErrorsVerticals)
              setdealerVerticalList(props.dealerData.Verticals)  
            }
            



          }
        },[props.dealerData])




      


        const handleInput = event => {
            let value = event.target.value;
        
            if (event.target.name === "Permissions") {
                if(value === 'true'){
                  setData({
                    ...data,
                    [event.target.name]: ["can_create_applications"],
                  });
                }else if(value === 'false'){
                  setData({
                    ...data,
                    [event.target.name]: [],
                  });
                }
            
            } else {
            setData({
                ...data,
                [event.target.name]: value
            });
            }
        }; //end






        const handleSubmit = event => {
            event.preventDefault();
            //handle errors
            setErrors(validateForm(data));
            seterrorProductList(validateProduct(dealerProductList))
            setIsSubmitting(true);
            setIsSubmitingDealerProducts(!isSubmitiongDealerProducts)
            seterrorOptionList(validateOption(dealerOptionList));
            setIsSubmitingDealerOptions(!setIsSubmitingDealerOptions)
            seterrorVerticalList(validateVertical(dealerVerticalList))
            setIsSubmitingDealerVertical(!isSubmitingDealerVertical)
        };





        //VALIDAR ERRORES Y ENVIAR FORMULARIO
        useEffect(() => {

          let errProducts = []
      
          errorProductList.forEach(error => {
            if( Object.keys(error).length != 0){
              errProducts = [ ...errProducts, false]
            }
          })

          let errOptions = []
      
          errorOptionList.forEach(error => {
            if( Object.keys(error).length != 0){
              errOptions = [ ...errOptions, false]
            }
          })

          let errVerticals = []
      
          errorVerticalList.forEach(error => {
            if( Object.keys(error).length != 0){
              errVerticals = [ ...errVerticals, false]
            }
          })



            if (Object.keys(errors).length === 0 && isSubmitting && errProducts.length === 0 && errOptions.length === 0 && errVerticals.length === 0) {
            submitRegister();
            } else {
            
            }

            
        }, [errors, isSubmitiongDealerProducts, isSubmitiongDealerOptions, isSubmitingDealerVertical, reloadPostForm]);

     
  
    

    



      //VALIDAR FORMULARIO
      const validateForm = (data) => {
        let errorList = {};
    
        
       
    
        //dealerName - text
        if (!data.Name) {
          errorList.Name = "Please enter a dealer name";
        }

        //phone_number - number
        if (!data.PhoneNumber) {
            errorList.PhoneNumber = "Please enter a phone";
        } else if (!/^[0-9]{10}$/.test(data.PhoneNumber)) {
        errorList.PhoneNumber = "The phone number must have 10 numbers";
        }

        //Permissions - select
        if (!data.Permissions) {
          errorList.Permissions = "Please select an option";
        }


        if(data.PrimaryContactEmail){
          if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,8})$/.test(data.PrimaryContactEmail)){
            errorList.PrimaryContactEmail = "Please enter a valid email";
          }
        }

        //majorProductLine - text
        // if (!data.majorProductLine) {
        //     errorList.majorProductLine = "Please enter a Major Product Line";
        //   }

        //   //Address - text
        // if (!data.address) {
        //     errorList.address = "Please enter an Address";
        //   }


        // //PostalCode - number
        // if (!data.postalCode) {
        //     errorList.postalCode = "Please enter a postalCode";
        // } else if (!/^[0-9]{5}$/.test(data.postalCode)) {
        //     errorList.postalCode = "The postal code must have 5 numbers";
        // } 


        


        // //affiliateName - text
        // if (!data.affiliateName) {
        //     errorList.affiliateName = "Please enter a affiliate Name";
        // }


        //  //nextAttestationDate - date
        // if (!data.nextAttestationDate) {
        //     errorList.nextAttestationDate = "Please enter a date";
        // }
        








    
        
    
       
    
       

         

       
    
        return errorList;
      };







       /*----------------------------------------- PRODUCTS------------------------------------- */ 
       const addProduct=()=>{

        seterrorProductList([...errorProductList, {}])
          
          setdealerProductList([
            ...dealerProductList,
            {
              Id: Math.random() * (1 - 10000000000) + 10000000000,
              
            }
          ])
          
        } 

        
        const handleProductInput=(event, id)=>{

              const newList = dealerProductList.map(product =>{
                
                if(product.Id === id){
                  if(event.target.name === 'Name'){
                      product.Name = event.target.value
                    }else if(event.target.name === 'Code'){
                      product.Code = event.target.value
                    }else if(event.target.name === 'IsHomeImprovement'){
                      product.IsHomeImprovement = event.target.value
                    }else if(event.target.name === 'IsMajorProduct'){
                      product.IsMajorProduct = event.target.value
                    }
                  
                  return product

                }else{
                  return product
                }


            })
              
            setdealerProductList(newList)
                
            console.log(dealerProductList)
              
            
        }


        const deleteProduct=(id, index)=>{

        const newList = dealerProductList.filter(income => income.Id !== id) 
        setdealerProductList(newList);

        const newErrorList = errorProductList.filter((error, i)=> errorProductList.indexOf(error) !== index)
        seterrorProductList(newErrorList)

        } 

 
        const validateProduct=(dealerProductList)=>{


        const errordealerProductList = dealerProductList.map((item)=>{


          let errorList = {};

          //name - text
          if (!item.Name) {
            errorList.Name = "Please select a Product name";
          }
    
          //code - text
          if (!item.Code) {
            errorList.Code = "Please enter a code";
          }
    
          //isHomeImprovement - select
          if (item.IsHomeImprovement === null || item.IsHomeImprovement === undefined) {
            errorList.IsHomeImprovement = "Please select a option";
          }
    
          //IsMajorProduct - select
          if (item.IsMajorProduct === null || item.IsMajorProduct === undefined) {
              errorList.IsMajorProduct = "Please select a option";
            }

          // if (!item.Amount) {
          //   errorList.Amount = "Please enter a value";
          // } else if (!/^[0-9]+\.?[0-9]*$/.test(item.Amount)) {
          //   errorList.Amount = "The value can only contain numbers";
          // }
    
          
          if(errorList.lenght !== 0){
            return errorList;
          }
          
          
        })

        //dealerProductList.errors = errordealerProductList;

        console.log(errordealerProductList)
        

        
        //return errordealerProductList
        if(errordealerProductList.length === 0){
          const vacio = [{}]
          return vacio
        }else{
          return errordealerProductList
        }
        
        
        }

     /*------------------------------------------------------------------------------------------------- */





      /*----------------------------------------- OPTIONS------------------------------------- */ 
      const addOption=()=>{

      seterrorOptionList([...errorProductList, {}])
        
        setdealerOptionList([
          ...dealerOptionList,
          {
            Id: Math.random() * (1 - 10000000000) + 10000000000,
            
          }
        ]) 
        console.log(dealerOptionList)
        
      } 

      
      const handleOptionInput=(event, id)=>{

            const newList = dealerOptionList.map(option =>{
              
              if(option.Id === id){
                if(event.target.name === 'Code'){
                  option.Code = event.target.value
                  }else if(event.target.name === 'Description'){
                    option.Description = event.target.value
                  }
                

                return option

              }else{
                return option
              }


          })
            
          setdealerOptionList(newList)
              
          console.log(dealerOptionList)
            
          
      }


      const deleteOption=(id, index)=>{

      const newList = dealerOptionList.filter(income => income.Id !== id) 
      setdealerOptionList(newList);

      const newErrorList = errorOptionList.filter((error, i)=> errorOptionList.indexOf(error) !== index)
      seterrorOptionList(newErrorList)

      } 


      const validateOption=(dealerOptionList)=>{


      const errordealerOptionList = dealerOptionList.map((item)=>{


        let errorList = {};

        //Code - text
        if (!item.Code) {
          errorList.Code = "Please enter a Code";
        }
  
        //Description - text
        if (!item.Description) {
          errorList.Description = "Please enter a Description";
        }
  
        
  
        
        if(errorList.lenght !== 0){
          return errorList;
        }
        
        
      })

      //dealerProductList.errors = errordealerProductList;

      console.log(errordealerOptionList)
      

      
      //return errordealerProductList
      if(errordealerOptionList.length === 0){
        const vacio = [{}]
        return vacio
      }else{
        return errordealerOptionList
      }
      
      
      }

    /*------------------------------------------------------------------------------------------------- */





    /*----------------------------------------- VERTICALS------------------------------------- */ 
    const addVertical=()=>{

      seterrorVerticalList([...errorVerticalList, {}])
        
        setdealerVerticalList([
          ...dealerVerticalList,
          {
            Id: Math.random() * (1 - 10000000000) + 10000000000,
            
          }
        ]) 
        console.log(dealerVerticalList)
        
      } 

      
    const handleVerticalInput=(event, id)=>{

          const newList = dealerVerticalList.map(vertical =>{
            
            if(vertical.Id === id){
              if(event.target.name === 'Value'){
                vertical.Value = event.target.value
                }
              

              return vertical

            }else{
              return vertical
            }


        })
          
        setdealerVerticalList(newList)
            
        console.log(dealerVerticalList)
          
        
    }


    const deleteVertical=(id, index)=>{

    const newList = dealerVerticalList.filter(income => income.Id !== id) 
    setdealerVerticalList(newList);

    const newErrorList = errorVerticalList.filter((error, i)=> errorVerticalList.indexOf(error) !== index)
    seterrorVerticalList(newErrorList)

    } 


    const validateVertical=(dealerVerticalList)=>{


    const errordealerVerticalList = dealerVerticalList.map((item)=>{


      let errorList = {};

      //Value - text
      if (!item.Value) {
        errorList.Value = "Please enter a Value";
      }

      

      

      
      if(errorList.lenght !== 0){
        return errorList;
      }
      
      
    })

    //dealerProductList.errors = errordealerProductList;

    console.log(errordealerVerticalList)
    

    
    //return errordealerProductList
    if(errordealerVerticalList.length === 0){
      const vacio = [{}]
      return vacio
    }else{
      return errordealerVerticalList
    }
    
    
    }

    /*------------------------------------------------------------------------------------------------- */




    return(
        <>
        
      
            <div className="createDealer-container">
            {props.hasOwnProperty('dealerId') ? null  : <h2 className="createDealer-title">Register Dealer</h2>}  
            
            <p className="createDealer-p"></p>           
        
            
            
            <div className="createDealer-form">

            


            {/* Name */}
            <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Name</label>
                <input
                name="Name"
                value={data.Name}
                type="text"
                placeholder="Dealer Name"
                onChange={handleInput}
                required
                className={errors.Name ? "imputError" : "campo"}
                />
                {errors.Name && <p className="errorMessage">{errors.Name}</p>}
            </div>


             {/* Address */}
             <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Address</label>
                <input
                name="Address"
                value={data.Address}
                type="text"
                placeholder="Address"
                onChange={handleInput}
                required
                className={errors.Address ? "imputError" : "campo"}
                />
                {errors.Address && <p className="errorMessage">{errors.Address}</p>}
            </div>

            {/* City*/}
            <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm">City</label>
                <input
                name="City"
                value={data.City}
                type="text"
                placeholder="City"
                onChange={handleInput}
                required
                className={errors.City ? "imputError" : "campo"}
                />
                {errors.City && <p className="errorMessage">{errors.City}</p>}
            </div>

            {/* State */}
            <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm">State</label>
                <select
                name="State"
                value={data.State}
                onChange={handleInput}
                className={errors.State ? "imputError" : "campo"}
                placeholder="State"
                required

                >
                    <option value="1" disabled selected>State</option>
                    <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   

                </select>
                {errors.State && <p className="errorMessage">{errors.State}</p>}
            </div>


            {/* Postal Code */}          
            <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm">Postal Code</label>
                <input
                name="PostalCode"
                value={data.PostalCode}
                type="number"
                placeholder="Postal Code"
                onChange={handleInput}
                required
                className={errors.PostalCode ? "imputError" : "campo"}
                />
                {errors.PostalCode && <p className="errorMessage">{errors.PostalCode}</p>}
            </div>


           

            {/* Phone Number */}           
            <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Phone Number</label>
                <input
                name="PhoneNumber"
                value={data.PhoneNumber}
                type="number"
                placeholder="Phone Number"
                onChange={handleInput}
                required
                className={errors.PhoneNumber ? "imputError" : "campo"}
                />
                {errors.PhoneNumber && <p className="errorMessage">{errors.PhoneNumber}</p>}
            </div>

            {/* PrimaryContactEmail */}           
            <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Primary Contact Email</label>
                <input
                name="PrimaryContactEmail"
                value={data.PrimaryContactEmail}
                type="email"
                placeholder="Primary Contact Email"
                onChange={handleInput}
                required
                className={errors.PrimaryContactEmail ? "imputError" : "campo"}
                />
                {errors.PrimaryContactEmail && <p className="errorMessage">{errors.PrimaryContactEmail}</p>}
            </div>


            {/* IsRetailDealer */}           
            <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm">Is Retail Dealer</label>
                <select
                name="IsRetailDealer"
                value={data.IsRetailDealer}
                onChange={handleInput}
                className={errors.IsRetailDealer ? "imputError" : "campo"}
                placeholder="Is Major Product"
                required
                >
                    <option value="1" disabled selected>Is Retail Dealer?</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>

                </select>
                {errors.IsRetailDealer && <p className="errorMessage">{errors.IsRetailDealer}</p>}
            </div>


            {/* IsOpenOnSaturday */}           
            <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm">Is Open On Saturday</label>
                <select
                name="IsOpenOnSaturday"
                value={data.IsOpenOnSaturday}
                onChange={handleInput}
                className={errors.IsOpenOnSaturday? "imputError" : "campo"}
                placeholder="Is Major Product"
                required
                >
                    <option value="1" disabled selected>Is Open On Saturday?</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>

                </select>
                {errors.IsOpenOnSaturday && <p className="errorMessage">{errors.IsOpenOnSaturday}</p>}
            </div>



            {/* Major Product Line */}    
            <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Major Product Line</label>
                <input
                name="MajorProductLine"
                value={data.MajorProductLine}
                type="text"
                placeholder="Major Product Line"
                onChange={handleInput}
                required
                className={errors.MajorProductLine ? "imputError" : "campo"}
                />
                {errors.MajorProductLine && <p className="errorMessage">{errors.MajorProductLine}</p>}
            </div>


            {/* Affiliate Name*/}       
            <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Affiliate Name</label>
                <input
                name="AffiliateName"
                value={data.AffiliateName}
                type="text"
                placeholder="Affiliate Name"
                onChange={handleInput}
                required
                className={errors.AffiliateName ? "imputError" : "campo"}
                />
                {errors.AffiliateName && <p className="errorMessage">{errors.AffiliateName}</p>}
            </div>


            {/* Next Attestation Date */}         
            <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Next Attestation Date</label>
                <input
                name="NextAttestationDate"
                value={data.NextAttestationDate}
                type="date"
                placeholder="Next Attestation Date"
                onChange={handleInput}
                required
                className={errors.NextAttestationDate ? "imputError" : "campo"}
                />
                {errors.NextAttestationDate && <p className="errorMessage">{errors.NextAttestationDate}</p>}
            </div>


             {/* DAFeeAmount */}         
             <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">DealerAlly Fee Amount</label>
                <input
                name="DAFeeAmount"
                value={data.DAFeeAmount}
                type="number"
                placeholder="DealerAlly Fee Amount"
                onChange={handleInput}
                required
                className={errors.DAFeeAmount ? "imputError" : "campo"}
                />
                {errors.DAFeeAmount && <p className="errorMessage">{errors.DAFeeAmount}</p>}
            </div>

             {/* Website */}         
             <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Website</label>
                <input
                name="Website"
                value={data.Website}
                type="text"
                placeholder="Website"
                onChange={handleInput}
                required
                className={errors.Website ? "imputError" : "campo"}
                />
                {errors.Website && <p className="errorMessage">{errors.Website}</p>}
            </div>



            {/* Permissions */}  
            {
              !props.hasOwnProperty('dealerData') ? 
              <div className="fullwidth campoContainer">
                <label className="labelApplicationForm">Can Create Applications</label>
                <select
                name="Permissions"
                // value={data.Permissions[0]}
                onChange={handleInput}
                className={errors.Permissions ? "imputError" : "campo"}
                placeholder="Can Create Applications"
                required
                >
                    <option value="1" disabled selected>Can Create Applications?</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>

                </select>
                {errors.Permissions && <p className="errorMessage">{errors.Permissions}</p>}
            </div>
            : null
            }

    
            <p className="fullwidth campoContainer dealerForm_subtitle">AquaFinance</p>

            {/* Dealer Number AquaFinance*/}
            <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm">Dealer Number</label>
                <input
                name="AquaFinanceDealerNumber"
                value={data.AquaFinanceDealerNumber}
                type="text"
                placeholder="Dealer Number"
                onChange={handleInput}
                required
                className={errors.AquaFinanceDealerNumber ? "imputError" : "campo"}
                />
                {errors.AquaFinanceDealerNumber && <p className="errorMessage">{errors.AquaFinanceDealerNumber}</p>}
            </div>


            {/* AquaFinanceExternalId */}       
            <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm">External Id</label>
                <input
                name="AquaFinanceExternalId"
                value={data.AquaFinanceExternalId}
                type="text"
                placeholder="External Id"
                onChange={handleInput}
                required
                className={errors.AquaFinanceExternalId ? "imputError" : "campo"}
                />
                {errors.AquaFinanceExternalId && <p className="errorMessage">{errors.AquaFinanceExternalId}</p>}
            </div>


            <p className="fullwidth campoContainer dealerForm_subtitle">Pinnacle</p>


            {/* PinnacleExternalId */}       
            <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm">External Id</label>
                <input
                name="PinnacleExternalId"
                value={data.PinnacleExternalId}
                type="text"
                placeholder="External Id"
                onChange={handleInput}
                required
                className={errors.PinnacleExternalId ? "imputError" : "campo"}
                />
                {errors.PinnacleExternalId && <p className="errorMessage">{errors.PinnacleExternalId}</p>}
            </div>


            
            {/* PinnacleDealerAPIKey*/}
            <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm">Api Key</label>
                <input
                name="PinnacleDealerAPIKey"
                value={data.PinnacleDealerAPIKey}
                type="text"
                placeholder="Api Key"
                onChange={handleInput}
                required
                className={errors.PinnacleDealerAPIKey ? "imputError" : "campo"}
                />
                {errors.PinnacleDealerAPIKey && <p className="errorMessage">{errors.PinnacleDealerAPIKey}</p>}
            </div>


            {/* PinnacleDealerUsername */}
            <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm">Username</label>
                <input
                name="PinnacleDealerUsername"
                value={data.PinnacleDealerUsername}
                type="text"
                placeholder="Username"
                onChange={handleInput}
                required
                className={errors.PinnacleDealerUsername ? "imputError" : "campo"}
                />
                {errors.PinnacleDealerUsername && <p className="errorMessage">{errors.PinnacleDealerUsername}</p>}
            </div>


            {/* PinnacleDealerPassword */}
            <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm">Password</label>
                <input
                name="PinnacleDealerPassword"
                value={data.PinnacleDealerPassword}
                type="text"
                placeholder="Password"
                onChange={handleInput}
                required
                className={errors.PinnacleDealerPassword ? "imputError" : "campo"}
                />
                {errors.PinnacleDealerPassword && <p className="errorMessage">{errors.PinnacleDealerPassword}</p>}
            </div>
            
        
            </div>


                

        

                {/* AQUI COMIENZA PRODUCTS---------------------------------------------------------------------------------------- */}
             
              
                <h3 className="createDealer_section-tittle">Products</h3>
              

                {/* PRODUCT LISTS */}
                {
                dealerProductList.map((item, index)=>{
                return(
                <div className="formCard" key={index}>

                  

                {props.type === 'edit' ? 
                null
                :
                <div className="mediowidth-right deleteIncome-icon_container">
                <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteProduct(item.Id, index)}}/>
                </div>  
                }

                <div className="mediowidth-right campoContainer">
                 <label className="labelApplicationForm" >Name</label>
                <input
                type="text"
                value={item.Name}
                name="Name"
                onChange={(event)=>{handleProductInput(event, item.Id)}} 
                className={`${errorProductList[index].Name ? "imputError" : "campo"}`}  
                placeholder="Name"
                required  
                />
                {errorProductList[index].Name && <p className="errorMessage">{errorProductList[index].Name}</p>} 
                </div>



                <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm" >Code</label>
                <input
                type="text"
                value={item.Code}
                name="Code"
                onChange={(event)=>{handleProductInput(event, item.Id)}} 
                className={`${errorProductList[index].Code ? "imputError" : "campo"}`}
                placeholder="Code"
                required       
                />
                {errorProductList[index].Code && <p className="errorMessage">{errorProductList[index].Code}</p>} 
                </div>


                <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm" >Is Home Improvement?</label>
                <select
                value={item.IsHomeImprovement}
                name="IsHomeImprovement"
                onChange={(event)=>{handleProductInput(event, item.Id)}}
                className={`${errorProductList[index].IsHomeImprovement ? "imputError" : "campo"}`}
                placeholder="Is Major Product"
                required      
                >
                    <option value="1" disabled selected>Is Home Improvement?</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>

                </select>
                {errorProductList[index].IsHomeImprovement && <p className="errorMessage">{errorProductList[index].IsHomeImprovement}</p>}
                </div>


                <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm" >Is Major Product</label>
                <select
                value={item.IsMajorProduct}
                name="IsMajorProduct"
                onChange={(event)=>{handleProductInput(event, item.Id)}}
                className={`${errorProductList[index].IsMajorProduct ? "imputError" : "campo"}`}
                placeholder="Is Major Product"
                required   
                >
                    <option value="1" disabled selected>Is Major Product?</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>

                </select>
                {errorProductList[index].IsMajorProduct && <p className="errorMessage">{errorProductList[index].IsMajorProduct}</p>}
                </div>



                </div>
                )
                })
                }
        
        
                {props.type === 'edit' ? null : <div className="createDealers_add_button" onClick={addProduct}>Add Product</div> }
          

                {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}




            
                {/* AQUI TERMINA PRODUCTS---------------------------------------------------------------------------------------- */}




                {/* AQUI COMIENZA OPTIONS---------------------------------------------------------------------------------------- */}
             
              
                <h3 className="createDealer_section-tittle">Options</h3>
              

                {/* OPTION LISTS */}
                {
                dealerOptionList.map((item, index)=>{
                return(
                <div className="formCard" key={index}>

                  

                {props.type === 'edit' ? 
                null
                :
                <div className="mediowidth-right deleteIncome-icon_container">
                <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteOption(item.Id, index)}}/>
                </div>  
                }

                <div className="fullwidth campoContainer">
                 <label className="labelApplicationForm" >Code</label>
                <input
                type="text"
                value={item.Code}
                name="Code"
                onChange={(event)=>{handleOptionInput(event, item.Id)}} 
                className={`${errorOptionList[index].Code ? "imputError" : "campo"}`}  
                placeholder="Code"
                required  
                />
                {errorOptionList[index].Code && <p className="errorMessage">{errorOptionList[index].Code}</p>} 
                </div>

                <div className="fullwidth campoContainer">
                 <label className="labelApplicationForm" >Description</label>
                <input
                type="text"
                value={item.Description}
                name="Description"
                onChange={(event)=>{handleOptionInput(event, item.Id)}} 
                className={`${errorOptionList[index].Description ? "imputError" : "campo"}`}  
                placeholder="Description"
                required  
                />
                {errorOptionList[index].Description && <p className="errorMessage">{errorOptionList[index].Description}</p>} 
                </div>

                



                </div>
                )
                })
                }
        
        
                {props.type === 'edit' ? null : <div className="createDealers_add_button" onClick={addOption}>Add Option</div> }
          

                {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}




            
                {/* AQUI TERMINA OPTIONS---------------------------------------------------------------------------------------- */}







                {/* AQUI COMIENZA VERTICALS---------------------------------------------------------------------------------------- */}
             
              
                <h3 className="createDealer_section-tittle">Verticals</h3>
              

                {/* OPTION LISTS */}
                {
                dealerVerticalList.map((item, index)=>{
                return(
                <div className="formCard" key={index}>

                  

                {props.type === 'edit' ? 
                null
                :
                <div className="mediowidth-right deleteIncome-icon_container">
                <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteVertical(item.Id, index)}}/>
                </div>  
                }

                <div className="fullwidth campoContainer">
                 <label className="labelApplicationForm" >Value</label>
                <input
                type="text"
                value={item.Value}
                name="Value"
                onChange={(event)=>{handleVerticalInput(event, item.Id)}} 
                className={`${errorVerticalList[index].Value ? "imputError" : "campo"}`}  
                placeholder="Value"
                required  
                />
                {errorVerticalList[index].Value && <p className="errorMessage">{errorVerticalList[index].Value}</p>} 
                </div>

      

                </div>
                )
                })
                }
        
        
                {props.type === 'edit' ? null : <div className="createDealers_add_button" onClick={addVertical}>Add Vertical</div> }
          

                {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}




            
                {/* AQUI TERMINA VERTICALS---------------------------------------------------------------------------------------- */}


        
                
          


        
          




       


       

 



        
      
        {
          props.hasOwnProperty('dealerId') ? 
            <button
            onClick={handleSubmit}
            type="submit"
            className="createDealer-submit-button">
            Update Dealer
            </button>
        :
          <button
            onClick={handleSubmit}
            type="submit"
            className="createDealer-submit-button">
            Create Dealer
          </button>
        }        

              





        </div>
        
        {
          props.hasOwnProperty('reloadGetDealer') ?

            showNotificationOk  ? 
            <Notification {...isSafari && {flexRight : true}}  type='popup' title='The Dealer was updated successfully.' f={()=>{props.reloadGetDealer()}}>
                <img src={iconCheck} alt="check" />
            </Notification> : null

            :


            showNotificationOk  ? 
            <Notification {...isSafari && {flexRight : true}} type='popup' title='The Dealer was created successfully.' f={()=>{window.location.reload()}}>
                <img src={iconCheck} alt="check" />
            </Notification> : null
        }
        
        
        {showNotificationInternalServerError  ? 
        <Notification {...isSafari && {flexRight : true}} type='popup' title='Server Error, please contact administrator.' f={()=>{SetShowNotificationInternalServerError(false)}}>
            <img src={iconSadFace} alt="check" />
        </Notification> : null}

        {showNotificationErrorDealerExist  ? 
        <Notification {...isSafari && {flexRight : true}} type='popup' title='The Lender Dealer External ID Already Exists.' f={()=>{SetShowNotificationErrorDealerExist(false)}}>
            <img src={iconSadFace} alt="check" />
        </Notification> : null}


        {isLoadingCreateDealer ? <SpinnerPopUp /> : null}
        </>
    );
}



export default CreateDealer;