
import React, { useState, useEffect, useContext, createContext } from 'react'
import '../../assets/styles/CreateProposal.css'
import {AuthContext} from '../../AuthContext';
import ProposalsProductsTab from './ProposalsProductsTab';
import Spinner from 'react-spinner-material';
import {Link} from 'react-router-dom';
import Notification from '../Notification'
import { useParams } from 'react-router-dom';
import PlacesAutocomplete from "react-places-autocomplete";
import useHandlePlacesAutocomplete from '../../hooks/useHandlePlacesAutocomplete';
import {ProposalCardContext} from './ProposalCard'



// const ProposalFormContext = createContext({})
// const { Provider } = ProposalFormContext

const ProposalForm = ({type, history, inDetails, proposalId, applicationId, createProposal, createPackage, editPackage, showPersonalInformation, editProposal, addCustomerPropsalId, dontShowCreateAppButton, isDragDisabled, showCreatePdfButton, closeSheet, showCustomerInformacion, createProposalAfterApplication, SetReloadGetApplicationDetail, allApplicationData, createCommercialProposal}) => {

    const urlParamProposalId = useParams().id
    


   

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken, setProposalInfo, proposalInfo, setOpenSheet, openSheet} = useContext(AuthContext);
    const{setShowSheet, showSheet} = useContext(ProposalCardContext)
    
    const [proposal, setProposal] = useState({});
    const [finalProposalObj, setFinalProposalObj] = useState({})
    const [errors, setErrors] = useState({});
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [redirectToCreateApp, setRedirectToCreateApp] = useState(true)
    const [stayInThePageAfterSubmit, setStayInThePageAfterSubmit] = useState(false)
    const [editProposalFromCreatePage, setEditProposalFromCreatePage] = useState(false)

    const [loadingDetails, setLoadingDetails] = useState(false)
    const [loadingPost, setLoadingPost] = useState(false)
    const [postError, setPostError] = useState(false)
    const [editProposalSuccess, setEditProposalSuccess] = useState(false)
    const [createProposalSuccess, setCreateProposalSuccess] = useState(false)
    const [sendCreatePdf, setSendCreatePdf] = useState(false)

    const [dealerLogoUrl, setDealerLogoUrl] = useState(false)
    

    //VALIDADOR Y DATOS DE LAS DOS TABLAS
    const [defaultProductList, setDefaultProductList] = useState([])
    const [validateDefaultProductList, setValidateDefaultProductList] = useState(0)
    const [defaultMonitoringProductList, setDefaultMonitoringProductList] = useState([])
    const [validateDefaultMonitoringProductList, setValidateDefaultMonitoringProductList] = useState(0)

    const [showMessageNotPackage, setShowMessageNotPackage] = useState(false)
    

    //CAMPOS DE LENDER
    const [financingTermsSelectList, setFinancingTermsSelectList] = useState([])
    const [financingPartnerSelected, setFinancingPartnerSelected] = useState([])


    //Monthly Payment
    const [tabHasPaymentFactor, setTabHasPaymentFactor] = useState(false)
    const [monitoringValue, setMonitoringValue] = useState(0)
    const [equipmentMonthlyPayment, setEquipmentMonthlyPayment] = useState(0)
    const [totalMonthlyPayment, setTotalMonthlyPayment] = useState(0)
    const [installedProduct, setInstalledProduct] = useState(0)

    //Proposal Totals
    const [extPrice, setExtPrice] = useState(0)
    const [extProductsMthly, setExtProductsMthly] = useState(0)
    const [extMonitoringMthly, setExtMonitoringMthly] = useState(0)
    const [totalEquipmentPlusActivation, setTotalEquipmentPlusActivation] = useState(0)
    const [totalAmountFinance, setTotalAmountFinance] = useState(0)
    const [annualSavings, setAnnualSavings] = useState(0)
    const [totalAmountCash, setTotalAmountCash] = useState(0)


    const [products, setProducts] = useState([])
    const [monitoringProducts, setMonitoringProducts] = useState([])
    const [pdfSuccess, setPdfSuccess] = useState(false)

    const [handleChangePlacesAutocomplete, handleSelectPlacesAutocomplete ] = useHandlePlacesAutocomplete(setFinalProposalObj, 'Address1');

    //TOGLES
    const [showPrice, setShowPrice] = useState(false)
    const [showMonthly, setShowMonthly] = useState(false)
    const [showServices, setShowServices] = useState(false)


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const dealerId = window.localStorage.getItem('dealerId');




    //POST
    const postProposal = async () => {
        // console.log(defaultProductList.products)
        // console.log(defaultMonitoringProductList.products)
        setLoadingPost(true)
        setPostError(false)
        setEditProposalSuccess(false)
        setStayInThePageAfterSubmit(false)
        setCreateProposalSuccess(false)
        setEditProposalFromCreatePage(false)

        const DealerProposalProducts = defaultProductList.products ? defaultProductList.products.map(product => { return {'Quantity' : product.Quantity, 'DealerProposalProductId' : product.DealerProposalProductId} }) : []
        const DealerMonitoringProposalProducts = defaultMonitoringProductList.products? defaultMonitoringProductList.products.map(product => { return {'Quantity' : product.Quantity, 'DealerProposalProductId' : product.DealerProposalProductId} }) : []


        let obj = {}
        if((createProposal || editProposal)){
            obj = {
                "Customer": {
                    "FirstName": finalProposalObj.FirstName,
                    "LastName": finalProposalObj.LastName,
                    "Email": finalProposalObj.EmailAddress,
                    "Address1": finalProposalObj.Address1,
                    "City": finalProposalObj.City,
                    "State": finalProposalObj.State,
                    "PostalCode": finalProposalObj.PostalCode,
                    "CellPhone": finalProposalObj.CellPhone,
                    "BusinessPhone": finalProposalObj.BusinessPhone,
                  },
                "LenderPaymentFactorId": finalProposalObj.FinancingTerms ,
                // "ActivationFee": parseFloat(finalProposalObj.Activation),
                "AnnualSavings": parseFloat(annualSavings),
                "DownPayment": parseFloat(finalProposalObj.DownPayment),
                "EquipmentMonthlyPayment": parseFloat(equipmentMonthlyPayment),
                "ServiceTerm": showServices ? finalProposalObj.ServiceTerm : 0,
                "TotalAmountToFinance": parseFloat(totalAmountFinance),
                "TotalEquipment": parseFloat(extPrice),
                "TotalEquipmentPlusActivation": parseFloat(totalEquipmentPlusActivation),
                "TotalMonthlyPayment": parseFloat(totalMonthlyPayment),
                "DealerProposalProducts": DealerProposalProducts,
                "DealerMonitoringProposalProducts": DealerMonitoringProposalProducts,
                "SalesTax" : parseFloat(finalProposalObj.SalesTax),
    
                "PaymentFactor": parseFloat(financingTermsSelectList.PaymentFactor),
                "TotalInstalledProductExtMth" : parseFloat(extProductsMthly),
                "TotalInteractiveServices" : parseFloat(monitoringValue),
                "CashPercent" : parseFloat(finalProposalObj.Discount),
                "TotalAmountCash" : parseFloat(totalAmountCash),    
                "DealerPackageId" : finalProposalObj.DealerPackages,   
                
                "LoanProgram" : finalProposalObj.LoanProgram,

                "ShowMonthly" : showMonthly,
                "ShowPrice" : showPrice,
                "ShowServices" : showServices
            }
           
            if(addCustomerPropsalId || editProposalFromCreatePage){
                obj.CustomerProposalId = proposalId || proposalInfo.info.customerProposalId
                obj.Customer.ApplicantId = proposal.ApplicantId || proposalInfo.info.ApplicantId
            }

            if(createProposalAfterApplication){
                obj.ApplicationId = applicationId
            }

            //COMMERCIAL LOGIC -------------------------------------------------------------------
            if(createCommercialProposal){
                obj.CompanyInformation = {
                    LegalCompanyName : finalProposalObj.LegalCompanyName,
                }
            }

            if((createCommercialProposal && editProposal) || (createCommercialProposal && editProposalFromCreatePage)){
                obj.CompanyInformation = {
                    LegalCompanyName : finalProposalObj.LegalCompanyName,
                    ApplicantId: proposal.ApplicantId || proposalInfo.info.ApplicantId
                }

                delete obj.CustomerProposalId
                delete obj.Customer.ApplicantId
                obj.ProposalId = proposalId || proposalInfo.info.customerProposalId
            }
    
            
        }else{
            obj = {
                "Name" : finalProposalObj.PackageName,
                "LenderPaymentFactorId": finalProposalObj.FinancingTerms ,
                "LenderId" : finalProposalObj.FinancePartners,
                "DealerProposalProducts": DealerProposalProducts,
                "DealerMonitoringProposalProducts": DealerMonitoringProposalProducts,
                "DownPayment": parseFloat(finalProposalObj.DownPayment).toFixed(2),  
                "ServiceTerm": finalProposalObj.ServiceTerm,   
                "SalesTax" : parseFloat(finalProposalObj.SalesTax),
                "LoanProgram" : finalProposalObj.LoanProgram,
                "ShowMonthly" : showMonthly,
                "ShowPrice" : showPrice,
                "ShowServices" : showServices
            }

            if(editPackage){
                obj.Id = urlParamProposalId
            }
    
           
        }

        // console.log(obj)
        // console.log(JSON.stringify(obj))
       


  
        let URI = '';
        if(editPackage){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/update-package-proposal`;
        }else if(createCommercialProposal){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/save-commercial-proposal`;
        }else if(editProposal || editProposalFromCreatePage){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/update-customer-proposal`;
        }else if(createProposal){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal`;
        }else if(createPackage){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/submit-package-proposal`;
        }
   
        //console.log(URI)
  
    
    
        try{
          const rawResponse = await window.fetch(URI, {
            method: 'POST',
            headers: {
              // 'Access-Control-Request-Headers': 'application/json',           
              'Content-Type': 'application/json', 
              'username' : user,
              'Authorization' : token,  
              'x-api-key' : uri.URI_X_API_KEY,       
            },
            body: JSON.stringify(obj),
          
          });
          setLoadingPost(false)
    
          const content = await rawResponse.json();
          //console.log(content);    


          const infoToCreateApp = {
            FirstName: finalProposalObj.FirstName,
            LastName: finalProposalObj.LastName,
            EmailAddress: finalProposalObj.EmailAddress,
            Address1: finalProposalObj.Address1,
            City: finalProposalObj.City,
            State: finalProposalObj.State,
            PostalCode: finalProposalObj.PostalCode,
            CellPhone: finalProposalObj.CellPhone,
            BusinessPhone: finalProposalObj.BusinessPhone,
            customerProposalId : content.customerProposalId,
            ApplicantId : content.applicantId,
            TotalInstalledProduct : totalEquipmentPlusActivation,
            DownPayment: finalProposalObj.DownPayment,
            HasCreatedApplications : proposal.HasCreatedApplications,
            SocialSecurityNumber: proposal.SocialSecurityNumber,
            DateOfBirth: proposal.DateOfBirth,
            FinancialLender: finalProposalObj.FinancePartners,
            SalesTax : finalProposalObj.SalesTax,
            LegalCompanyName: finalProposalObj.LegalCompanyName
        }
    
          if(content.status === 'SUCCESS'){
                if(createProposal || editProposal){
       
                    if(redirectToCreateApp && (content.customerProposalId && content.applicantId)){
                        if(!createProposalAfterApplication){
                            setProposalInfo({
                                info : infoToCreateApp,
                                hasInfo: true
                            })
                        }              
                        window.scrollTo(0, 0)
                        if(createCommercialProposal){
                            history.replace(`/commercial`);
                        }else{
                            history.replace(`/createapplication`);
                        }
                       
                    }else if((!content.customerProposalId || !content.applicantId) && !createProposalAfterApplication){
                        setPostError(true)
                    }else{ 
                        if(stayInThePageAfterSubmit){
                           
                            setCreateProposalSuccess(true)
                            setEditProposalFromCreatePage(true)
                            if(createProposalAfterApplication){
                                SetReloadGetApplicationDetail(state => !state)
                            }
                            if(!createProposalAfterApplication){
                                setProposalInfo({
                                    info : infoToCreateApp,
                                    hasInfo: true
                                })
                            }
                            
                            if(sendCreatePdf){
                                createPDF()
                                setRedirectToCreateApp(true)
                                setStayInThePageAfterSubmit(false)
                                setSendCreatePdf(false)
                            }
                        }
                        else if(editProposal){
                            setEditProposalSuccess(true)
                            
                        }else{
                            if(createCommercialProposal){
                                history.replace(`/commercialapp`);
                            }else{
                                history.replace(`/applicants`);
                            }
                        }
                    }
                    

                }else{
                    window.scrollTo(0, 0)
                    history.replace(`/proposals?tab=4`); 
                    
                }
           

            
        }else if(content.status === 'SYSTEM_ERROR' || content.status === 'CUSTOMER_ERROR_IN_ORG' || content.status === 'CUSTOMER_PROPOSAL_ERROR_IN_ORG' || content.status === 'INTERNAL_SERVER_ERROR' || content.status === 'PROPOSAL_NOT_FOUND'){
        // console.log('error')
        setPostError(true)
        }
    
    
        }
        catch(error){
          //console.log(error)
          setPostError(true)
          setLoadingPost(false)
          setEditProposalSuccess(false)
          setCreateProposalSuccess(false)          
        }


    }



    //SEND PROPOSAL DATA TO CREATE PDF
    const createPDF = async () => {

        setLoadingPost(true)

        
        let paymentFactor = financingTermsSelectList ? parseFloat(financingTermsSelectList.PaymentFactor) : 0 

        let totalQuantity = 0
        products.forEach(products => {
            totalQuantity = totalQuantity + parseInt(products.Quantity)
        })

        let totalProductsExtMonthly = 0;  
        products.forEach(products => {
            totalProductsExtMonthly = totalProductsExtMonthly + (paymentFactor * (parseFloat(products.ProductPrice).toFixed(2) * parseInt(products.Quantity)) ) 
        })


        let totalExtPrice = 0;
        products.forEach(products => {
            totalExtPrice = totalExtPrice + (products.ProductPrice * parseInt(products.Quantity))
        })


        let totalMonitoringMonthly = 0;
        monitoringProducts.forEach(products => {    
            totalMonitoringMonthly = totalMonitoringMonthly + (parseFloat(products.ProductPrice))  
        })

        //console.log(products)

        //CALCULAR TotalMonthly y TotalPrice
        const totalBundle = (bundle, price, monthly) => {
            let totalBundlePrice = 0;  
            bundle.forEach(products => {
              totalBundlePrice = totalBundlePrice + ((parseFloat(products.ProductPrice) * parseFloat(products.Quantity)) ) 
            })
           
      
      
            let totalBundleMonthly = 0;  
            bundle.forEach(products => {
              totalBundleMonthly = totalBundleMonthly + (paymentFactor * parseFloat(products.ProductPrice) * parseFloat(products.Quantity)) 
            })
      
            const value = price ? parseFloat(totalBundlePrice).toFixed(2) : parseFloat(totalBundleMonthly).toFixed(2)
      
            return value || '0'
          }



        const DealerProposalProducts = products.map(product => {



            let productBundleList = []
            if(product.ProductList){
                const productList = product.ProductList.map(p => {
                    return{
                        Quantity: p.Quantity,
                        ProductName: p.Name,
                        Monthly: (paymentFactor * p.ProductPrice).toFixed(2),
                        ExtMth: (paymentFactor * p.ProductPrice * p.Quantity).toFixed(2),
                        Price: p.ProductPrice.toFixed(2),
                        ExtPrice:(p.ProductPrice * p.Quantity).toFixed(2)
                    }
                })
                productBundleList = productList
            }

            return {
                Quantity: product.Quantity,
                DealerProposalProductId: product.DealerProposalProductId,
                ProductName : product.Name,
                Monthly: (paymentFactor * product.ProductPrice).toFixed(2),
                ExtMth : (paymentFactor * (product.ProductPrice * parseInt(product.Quantity))).toFixed(2),
                Price : product.ProductPrice.toFixed(2),
                ExtPrice : (product.ProductPrice * parseInt(product.Quantity)).toFixed(2),
                TotalExtMth: product.ProductList && totalBundle(product.ProductList, false, true),
                TotalExtPrice: product.ProductList && totalBundle(product.ProductList, true, false),
                ProductList: productBundleList.length > 0 ? productBundleList : undefined
            }         
        })

        //console.log(DealerProposalProducts)


        const DealerMonitoringProposalProducts = monitoringProducts.map(product => {
            return {
                Quantity: product.Quantity,
                DealerProposalProductId: product.DealerProposalProductId,
                ProductName : product.Name,
                Monthly : (product.ProductPrice).toFixed(2)
                }
        })

        

        const dealerPackage = proposal.DealerPackages.find(dealerPackage => dealerPackage.DealerPackageId === finalProposalObj.DealerPackages)
        const financePartner = proposal.FinancePartners.find(financePartner => financePartner.Id === finalProposalObj.FinancePartners)  
        const promotion = financingPartnerSelected && financingPartnerSelected.FinancingTerms && financingPartnerSelected.FinancingTerms.find(promotion => promotion.FinancingTermId === finalProposalObj.FinancingTerms)


        const data = {
            "Customer": {
                "FirstName": finalProposalObj.FirstName || allApplicationData.Applicant.FirstName,
                "LastName": finalProposalObj.LastName || allApplicationData.Applicant.LastName,
                "Email": finalProposalObj.EmailAddress || allApplicationData.Applicant.EmailAddress,
                "Address1": finalProposalObj.Address1 || allApplicationData.Applicant.Address1,
                "City": finalProposalObj.City || allApplicationData.Applicant.City,
                "State": finalProposalObj.State || allApplicationData.Applicant.State,
                "PostalCode": finalProposalObj.PostalCode || allApplicationData.Applicant.PostalCode,
                "CellPhone": finalProposalObj.CellPhone || allApplicationData.Applicant.CellPhone,
                "BusinessPhone": finalProposalObj.BusinessPhone
            },
            "DealerPackage": dealerPackage.Name || '',
            "FinancePartner": financePartner.Name || '',
            "Promotion": promotion.PaymentFactorName || '',

            "TotalMonthlyPayment" : parseFloat(totalMonthlyPayment),
            "InteractiveServices" : tabHasPaymentFactor ? parseFloat(monitoringValue) : '',
            "InstalledProducts" : tabHasPaymentFactor ? parseFloat(installedProduct) : '',
            "TotalInstalledProduct" : tabHasPaymentFactor  ? parseFloat(totalEquipmentPlusActivation) : '',
            "DownPayment" : tabHasPaymentFactor ? parseFloat(finalProposalObj.DownPayment) : '',
            "TotalAmountToFinance" : tabHasPaymentFactor &&  (finalProposalObj.DownPayment || finalProposalObj.DownPayment === 0) ? parseFloat(totalAmountFinance) : '',
            "ServiceTerm" : parseFloat(finalProposalObj.ServiceTerm),
            "CustomerOwned" : "YES",
            "CashDiscount" : finalProposalObj.Discount,
            "TotalAmount" : tabHasPaymentFactor ? parseFloat(totalAmountCash) : '',
            "AnnualSavings" : parseFloat(annualSavings),
            "SalesTax" : parseFloat(finalProposalObj.SalesTax),
            "ShowPrice" : showPrice,
            "ShowMonthly" : showMonthly,
            "ShowServices" : showServices,
            "LoanProgram" : finalProposalObj.LoanProgram,
            "DealerProposalProducts": {
                Products : DealerProposalProducts,
                TotalQuantity : totalQuantity,
                TotalProductsExtMonthly: totalProductsExtMonthly,
                TotalExtPrice: totalExtPrice
            }, 
            "DealerMonitoringProposalProducts": {
                Products : DealerMonitoringProposalProducts,
                TotalMonitoringMonthly: totalMonitoringMonthly
            } 
            
            }

            if(createCommercialProposal){
                data.CompanyInformation = {
                    LegalCompanyName: finalProposalObj.LegalCompanyName
                }
            }

            // console.log(JSON.stringify(data))


            
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/email-customer-proposal`;
            
    
        
            try{
              const rawResponse = await window.fetch(URI, {
                method: 'POST',
                headers: {
                  // 'Access-Control-Request-Headers': 'application/json',           
                  'Content-Type': 'application/json', 
                  'username' : user,
                  'Authorization' : token,  
                  'x-api-key' : uri.URI_X_API_KEY,       
                },
                body: JSON.stringify(data),
              
              });
              setLoadingPost(false)
        
              const content = await rawResponse.json();
              //console.log(content);    
        
                if(content.status === 'SUCCESS'){
                    setPdfSuccess(true)
                }     
    
             
        
        
            }
            catch(error){
            //   console.log(error)
              setPostError(true)
              setLoadingPost(false)
          
            }
    }
    


    const handleCreatePdf = () => {
        //createPDF()
        setCreateProposalSuccess(false)

        if(createProposal && !createProposalAfterApplication){
            handleSubmit(); 
            setRedirectToCreateApp(false); 
            setStayInThePageAfterSubmit(true); 
            setSendCreatePdf(true)
        }else{
            createPDF()
        }
    }



    //GET DEFAULT PROPOSAL INFO
    useEffect(()=>{
        if(!inDetails && !editPackage){
        setLoadingDetails(true)
        
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-default-customer-proposal`
        // console.log(URI)
        
    
        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY, 
             }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setLoadingDetails(false)
            // console.log(datos);


            if((!datos.DealerPackages || datos.DealerPackages.length === 0) && createProposal){
                setShowMessageNotPackage(true)
            }else{
                setProposal(datos)

           
                const defaultFinancePartners = datos.FinancePartners.find(item => item.Default)   
                const defaultFinancingTerms = defaultFinancePartners ? defaultFinancePartners.FinancingTerms.find(item => item.Default) : {}
                //PUEDEN NO VENIR
                const defaultDealerPackages = datos.DealerPackages && datos.DealerPackages.find(item => item.Default)
                const defaultMonitoringPackages = datos.MonitoringPackages && datos.MonitoringPackages.find(item => item.Default)

                //console.log(defaultFinancePartners)
                //console.log(defaultFinancingTerms)
                setFinancingPartnerSelected(defaultFinancePartners)
                setFinancingTermsSelectList(defaultFinancingTerms)

                // console.log(defaultDealerPackages)

            
                setFinalProposalObj({
                    ...finalProposalObj,
                    'DealerPackages' : defaultDealerPackages ? defaultDealerPackages.DealerPackageId : undefined,
                    'FinancePartners' : defaultFinancePartners ? defaultFinancePartners.Id : undefined,
                    'MonitoringPackages' :defaultMonitoringPackages ? defaultMonitoringPackages.MonitoringPackageId : undefined,
                    'FinancingTerms' : defaultFinancingTerms ? defaultFinancingTerms.Id === 'FI-0002' ? ( defaultFinancingTerms.Tier === 'Platinum' ? defaultFinancingTerms.FinancingTermId : undefined) : defaultFinancingTerms.FinancingTermId : undefined,
                    // 'Activation' : datos.DealerPackages && datos.DealerPackages[0].ActivationFee.toFixed(2),
                    // 'DownPayment' : defaultDealerPackages.DownPayment === 0 ? null :  defaultDealerPackages.DownPayment,
                    'DownPayment' :  defaultDealerPackages.DownPayment,
                    'ServiceTerm' : (defaultDealerPackages.ServiceTerm || defaultDealerPackages.ServiceTerm === 0) ? defaultDealerPackages.ServiceTerm : 12,
                    'Discount' : 0,
                    // 'SalesTax' :defaultDealerPackages.SalesTax === 0 ? null : defaultDealerPackages.SalesTax,
                    'SalesTax' : defaultDealerPackages.SalesTax,
                    'LoanProgram' : datos.LoanProgram || 'default',
                    'DiscountAmount' : 0,        
                }) 
            }


            setShowMonthly(datos.hasOwnProperty("ShowMonthly") ? datos.ShowMonthly : true)
            setShowPrice(datos.hasOwnProperty("ShowPrice") ? datos.ShowPrice : true )
            setShowServices(datos.hasOwnProperty("ShowServices") ? datos.ShowServices : true )

            
           
            setErrorFlagFromContext(false)

            })
            .catch((error, response) => {
                // console.log(error)
                setLoadingDetails(false)
                if(errorFlagFromContext === false && error.status === 401){
                    changeToken()
                }    
            });

            return function cleanup(){
                abortController.abort();
            }

        }
        
        
    },[reloadFetchFromContext]);






    //GET PROPOSAL DETAILS TO EDIT PROPOSAL AND SHOW IN DETAIL VIEW
    useEffect(()=>{
        if(inDetails){
        setLoadingDetails(true)

        let URI = ''
        if(createCommercialProposal){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-commercial-proposal-update-info/${proposalId}`
        }else{
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-customer-proposal-update-info/${proposalId}`
        }
    
        
    
        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY, 
                }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
                }
            return Promise.reject(response);
        })
        .then(datos => {
            setLoadingDetails(false)
            // console.log(datos);



            const defaultFinancePartners = datos.FinancePartners ? datos.FinancePartners.find(item => item.Id === datos.Proposal.FinancialLender.Id ) : {}
            const defaultFinancingTerms = defaultFinancePartners ? defaultFinancePartners.FinancingTerms.find(item => item.FinancingTermId === datos.Proposal.OriginalPaymentFactor.FinancingTermId) : {}
     
            setFinancingPartnerSelected(defaultFinancePartners)
            setFinancingTermsSelectList(defaultFinancingTerms)

    
            setFinalProposalObj({
                ...finalProposalObj,
                'DealerPackages' : datos.Proposal.DealerPackage.DealerPackageId,
                'FinancePartners' : datos.Proposal.FinancialLender.Id,
                'FinancingTerms' : datos.Proposal.OriginalPaymentFactor.FinancingTermId,
                'DownPayment' : parseFloat(datos.Proposal.DownPayment).toFixed(2),
                'ServiceTerm' : datos.Proposal.ServiceTerm,
                'Discount' : datos.Proposal.CashPercent,
                'DiscountAmount' : 0,
                'SalesTax' : parseFloat(datos.Proposal.SalesTax).toFixed(2),
                
                "LegalCompanyName" : datos.Proposal.CompanyInformation && datos.Proposal.CompanyInformation.LegalCompanyName,
                "FirstName": datos.Proposal.Customer.FirstName,
                "LastName": datos.Proposal.Customer.LastName,
                "EmailAddress": datos.Proposal.Customer.Email,
                "Address1": datos.Proposal.Customer.Address1,
                "City": datos.Proposal.Customer.City,
                "State": datos.Proposal.Customer.State,
                "CellPhone": datos.Proposal.Customer.CellPhone,
                "BusinessPhone": datos.Proposal.Customer.BusinessPhone,
                "PostalCode": datos.Proposal.Customer.PostalCode,
                "LoanProgram": datos.Proposal.LoanProgram || 'default'
                
            })


            setProposal({
                ...proposal,
                'DefaultDealerMonitoringProposalProducts' : datos.Proposal.DealerMonitoringProposalProducts,
                'DefaultDealerProposalProducts' : datos.Proposal.DealerProposalProducts,
               
                'DealerProposalProducts' : datos.SystemProducts,
                'DealerMonitoringProposalProducts' : datos.MonitoringProducts,
                
                'PaymentFactor' : datos.Proposal.OriginalPaymentFactor,
                'DealerPackages': datos.DealerPackages,
                'FinancePartners' : datos.FinancePartners,

                'HasCreatedApplications' : datos.HasCreatedApplications,

                'ApplicantId' : datos.Proposal.Customer.ApplicantId || datos.Proposal.CompanyInformation.ApplicantId,
                'SocialSecurityNumber': datos.Proposal.Customer.SocialSecurityNumber,
                'DateOfBirth' : datos.Proposal.Customer.DateOfBirth     

            })


            setShowMonthly(datos.Proposal.hasOwnProperty("ShowMonthly") ? datos.Proposal.ShowMonthly : true)
            setShowPrice(datos.Proposal.hasOwnProperty("ShowPrice") ? datos.Proposal.ShowPrice : true )
            setShowServices(datos.Proposal.hasOwnProperty("ShowServices") ? datos.Proposal.ShowServices : true )



          
            
            
            setErrorFlagFromContext(false)

        }).catch((error, response) => {
            console.log(error)
            setLoadingDetails(false)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken()
            }

            });

        return function cleanup(){
            abortController.abort();
        }

        }    
    },[reloadFetchFromContext]);




    //CHANGE PACKAGE WITH SELECT
    const changePackage = (id) => {
        setLoadingDetails(true)

        
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-package-proposal/${id}`
        //console.log(URI)
        
    
        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY, 
             }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setLoadingDetails(false)
            // console.log(datos);


            const defaultFinancePartners = proposal.FinancePartners ? proposal.FinancePartners.find(item => item.Id === datos.FinancialLender.Id ) : {}
            //console.log(defaultFinancePartners) 
            const defaultFinancingTerms = defaultFinancePartners ? defaultFinancePartners.FinancingTerms.find(item => item.FinancingTermId === datos.PaymentFactor.FinancingTermId) : {}
            //console.log(defaultFinancingTerms) 

            setFinancingPartnerSelected(defaultFinancePartners)
            setFinancingTermsSelectList(defaultFinancingTerms)


            setFinalProposalObj({
                ...finalProposalObj,
                'DealerPackages' : id,
                'FinancePartners' : datos.FinancialLender.Id,
                'FinancingTerms' : datos.PaymentFactor.FinancingTermId,
                'DownPayment' : parseFloat(datos.DownPayment).toFixed(2),
                'ServiceTerm' : datos.ServiceTerm,
                'SalesTax' : parseFloat(datos.SalesTax).toFixed(2) || 0.00,
                'LoanProgram' : datos.LoanProgram || 'default'

            })

            // console.log(datos.MonitoringProducts ? datos.MonitoringProducts : [])
            // console.log(datos.SystemProducts)

            setProposal({
                ...proposal,
                'DefaultDealerMonitoringProposalProducts' : datos.MonitoringProducts ? datos.MonitoringProducts : [], 
                'DefaultDealerProposalProducts' : datos.SystemProducts ? datos.SystemProducts : []

            })


            setShowMonthly(datos.hasOwnProperty("ShowMonthly") ? datos.ShowMonthly : true)
            setShowPrice(datos.hasOwnProperty("ShowPrice") ? datos.ShowPrice : true )
            setShowServices(datos.hasOwnProperty("ShowServices") ? datos.ShowServices : true )

            
           
            setErrorFlagFromContext(false)

            })
            .catch((error, response) => {
                // console.log(error)
                setLoadingDetails(false)
                if(errorFlagFromContext === false && error.status === 401){
                    changeToken()
                }    
            });

    }









    //GET INFORMATION TO EDIT EXIST PACKAGE
    useEffect(()=>{
        if(!inDetails && editPackage){
        setLoadingDetails(true)
        
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-package-proposal-update-info/${urlParamProposalId}`
        // console.log(URI)
        
    
        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY, 
             }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setLoadingDetails(false)
            //console.log(datos);


               

           
                const defaultFinancePartners = datos.FinancePartners ? datos.FinancePartners.find(item => item.Id === datos.Proposal.FinancialLender.Id ) : {}
                const defaultFinancingTerms = defaultFinancePartners ? defaultFinancePartners.FinancingTerms.find(item => item.FinancingTermId === datos.Proposal.PaymentFactor.FinancingTermId) : {}
         
                setFinancingPartnerSelected(defaultFinancePartners)
                setFinancingTermsSelectList(defaultFinancingTerms)

              

            
                setFinalProposalObj({
                    ...finalProposalObj,
                    'DealerPackages' : urlParamProposalId,
                    'FinancePartners' : datos.Proposal.FinancialLender.Id,
                    'FinancingTerms' : datos.Proposal.PaymentFactor.FinancingTermId,
                    'DownPayment' : parseFloat(datos.Proposal.DownPayment).toFixed(2),
                    'ServiceTerm' : datos.Proposal.ServiceTerm,
                    'PackageName' : datos.Proposal.Name,
                    'SalesTax' : datos.Proposal.SalesTax,
                    'LoanProgram' : datos.Proposal.LoanProgram || 'default',
                })


                setProposal({
                    ...proposal,
                    'DefaultDealerMonitoringProposalProducts' : datos.Proposal.MonitoringProducts,
                    'DefaultDealerProposalProducts' : datos.Proposal.SystemProducts,
                   
                    'DealerProposalProducts' : datos.SystemProducts,
                    'DealerMonitoringProposalProducts' : datos.MonitoringProducts,
                    
                    'PaymentFactor' : datos.Proposal.PaymentFactor,
                    'DealerPackages': datos.DealerPackages,
                    'FinancePartners' : datos.FinancePartners,

                })

                setShowMonthly(datos.Proposal.hasOwnProperty('ShowMonthly') ?  datos.Proposal.ShowMonthly: true)
                setShowPrice(datos.Proposal.hasOwnProperty('ShowPrice') ?  datos.Proposal.ShowPrice : true)
                setShowServices(datos.Proposal.hasOwnProperty('ShowServices') ?  datos.Proposal.ShowServices : true)
            
           
            setErrorFlagFromContext(false)

            })
            .catch((error, response) => {
                setLoadingDetails(false)
                if(errorFlagFromContext === false && error.status === 401){
                    changeToken()
                }    
            });

            return function cleanup(){
                abortController.abort();
            }

        }
        
        
    },[reloadFetchFromContext]);





    //GET DEALER LOGO
    useEffect(()=>{
   
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}dealer/get-cpg-email-logo?dealerId=${dealerId}`
        // console.log(URI)
        
    
        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY, 
                }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
                }
            return Promise.reject(response);
        })
        .then(datos => {
            setLoadingDetails(false)
            // console.log(datos);

            if(datos.Url){
                setDealerLogoUrl(datos.Url)
            }

    

            })
            .catch((error, response) => {
                // console.log(error)
                setLoadingDetails(false)
                if(errorFlagFromContext === false && error.status === 401){
                    changeToken()
                }    
            });

            return function cleanup(){
                abortController.abort();
            }

        
        
        
    },[reloadFetchFromContext]);






    //__________________________________________________________________


    //HANDLE INPUTS
    const handleInput = (e) =>{

        if (e.target.name === "FinancePartners") {
            

            const newFinancingPartner = proposal.FinancePartners.find(item => item.Id === e.target.value)
            setFinancingPartnerSelected(newFinancingPartner)

     
            const newFinancingTerms = newFinancingPartner.FinancingTerms.find(item => item.Default || item.FinancingTermId === 'LART-028')
            // console.log(newFinancingTerms)
            setFinancingTermsSelectList(newFinancingTerms)
           

            setFinalProposalObj({
                ...finalProposalObj,
                [e.target.name] : e.target.value,
                'FinancingTerms' : newFinancingTerms ? newFinancingTerms.FinancingTermId : 'default',
             }) 


        }else if(e.target.name === "FinancingTerms"){
            const newFinancingTerm = financingPartnerSelected.FinancingTerms.find(item => item.FinancingTermId === e.target.value)
            setFinancingTermsSelectList(newFinancingTerm)
            
            setFinalProposalObj({
                ...finalProposalObj,
                [e.target.name] : e.target.value,
             }) 


        }else if(e.target.name === "Discount"){

            const value = tabHasPaymentFactor && ( (parseFloat(totalEquipmentPlusActivation) - parseFloat(finalProposalObj.DownPayment)) * parseFloat(e.target.value))

            setFinalProposalObj({
                ...finalProposalObj,
                [e.target.name] : e.target.value,
                'DiscountAmount' : value.toFixed(2),
             }) 

        }
        else if(e.target.name === "DealerPackages"){
            
            // console.log(e.target.value)
            changePackage(e.target.value)

            setFinalProposalObj({
                ...finalProposalObj,
                [e.target.name] : e.target.value,
             }) 
        }
        else{
            setFinalProposalObj({
                ...finalProposalObj,
                [e.target.name] : e.target.value,
             }) 
        }
        
    }







  
    //------------------------------------------------------------------

    const handleSubmit = () => {


        

        setValidateDefaultProductList(validateDefaultProductList + 1)
        if(showServices){
            setValidateDefaultMonitoringProductList(validateDefaultMonitoringProductList + 1)
        }
        

        
        setErrors(validateForm(finalProposalObj));
        setIsSubmiting(true)
        
        

        
    }


    //POST FINAL
    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmiting && !defaultProductList.error && (showServices ? !defaultMonitoringProductList.error : true)){
            //console.log('post ok')
            postProposal()
        }else{
            // console.log(errors)
            // console.log(defaultProductList.error)
            // console.log(defaultMonitoringProductList.error)
            
        }

    },[isSubmiting, defaultProductList, defaultMonitoringProductList])



    /*----------------------------------------- VALIDAR FORMULARIOS ------------------------------------- */

    //validate applicand and coapplicant
    const validateForm = (applicant) => {
        let errorList = {};


        if ((createProposal || editProposal) && !showCustomerInformacion) {


            //FirstName - text
            if (!applicant.FirstName) {
                errorList.FirstName = "Please enter a name";
            } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.FirstName)) {
                errorList.FirstName = "The name can only contain letters";
            }



            //LastName - text
            if (!applicant.LastName) {
                errorList.LastName = "Please enter a lastname";
            } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.LastName)) {
                errorList.LastName = "The lastname can only contain letters";
            }


            //Address1 - text
            if (!applicant.Address1) {
                errorList.Address1 = "Please enter a address";
            }

            //City - text
            if (!applicant.City) {
                errorList.City = "Please enter a city";
            } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s\.]+$/.test(applicant.City)) {
                errorList.City = "The city can only contain letters";
            }




            //State - select
            if (!applicant.State) {
                errorList.State = "Please select an option";
            } else if (applicant.State === "State") {
                errorList.State = "Please select an option";
            }

            //PostalCode - number
            if (!applicant.PostalCode) {
                errorList.PostalCode = "Please enter a value";
            } else if (!/^[0-9]{5}$/.test(applicant.PostalCode)) {
                errorList.PostalCode = "The value must have 5 numbers";
            }


            //CellPhone - number
            if (!applicant.CellPhone) {
                errorList.CellPhone = "Please enter a primary phone";
            } else if (!/^[0-9]{10}$/.test(applicant.CellPhone)) {
                errorList.CellPhone = "The CellPhone must have 10 numbers";
            } else if (applicant.CellPhone === '0000000000') {
                errorList.CellPhone = "Please enter a valid phone number";
            }

            //BusinessPhone *
            if (applicant.BusinessPhone) {
                if (applicant.BusinessPhone === applicant.CellPhone) {
                    errorList.BusinessPhone = "Phone numbers must be different";
                } else if (!/^[0-9]{10}$/.test(applicant.BusinessPhone)) {
                    errorList.BusinessPhone = "The Business Phone must have 10 numbers";
                } else if (applicant.BusinessPhone === '0000000000') {
                    errorList.BusinessPhone = "Please enter a valid phone number";
                }
            }


            if (!applicant.EmailAddress) {
                errorList.EmailAddress = "Please enter a email";
            } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.EmailAddress)) {
                errorList.EmailAddress = "Please enter a valid email";
            }
        }else{
             //PackageName - text
             if(!showCustomerInformacion){
                if (!applicant.PackageName) {
                    errorList.PackageName = "Please enter a package name";
                }
             }
             
        }

        if(createCommercialProposal){   
            if (!applicant.LegalCompanyName) {
                errorList.LegalCompanyName = "Please enter a company name";
            } 
        }



        // FinancePartners
        if (!applicant.FinancePartners || applicant.FinancePartners === 'default') {
            errorList.FinancePartners = "Please select an option";
        }

        // FinancingTerms
        if (!applicant.FinancingTerms || applicant.FinancingTerms === 'default') {
            errorList.FinancingTerms = "Please select an option";
        }

        //DealerPackages
        if(createProposal){
            if (!applicant.DealerPackages || applicant.DealerPackages === 'default') {
                errorList.DealerPackages = "Please select an option";
            }
        }


         // DownPayment
         if (!applicant.DownPayment && applicant.DownPayment !== 0) {
            errorList.DownPayment = "Please enter the value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.DownPayment)) {
            errorList.DownPayment = "The value can only contain numbers";
        }


         // SalesTax
         if (!applicant.SalesTax && applicant.SalesTax !== 0) {
            errorList.SalesTax = "Please enter the value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.SalesTax)) {
            errorList.SalesTax = "The value can only contain numbers";
        }

        //ServiceTerm
        if(showServices){
            if (!applicant.ServiceTerm && applicant.ServiceTerm !== 0) {
                errorList.ServiceTerm = "Please enter the value";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.ServiceTerm)) {
                errorList.ServiceTerm = "The value can only contain numbers";
            }else if (applicant.ServiceTerm > 99 || applicant.ServiceTerm < 0) {
                errorList.ServiceTerm = "Value must be 1 to 99";
            }
        }
        

        if(applicant.FinancePartners === 'FI-0002'){
            if (!applicant.LoanProgram || applicant.LoanProgram === 'default') {
                errorList.LoanProgram = "Please select an option";
            }
        }
       


        return errorList;
    };









    //-----------------------------------------------------------------
    //CALCULOS


    //Total Monthly Payment
    useEffect(()=>{
        setTotalMonthlyPayment((parseFloat(monitoringValue) + parseFloat(installedProduct)).toFixed(2)) 
    },[monitoringValue, installedProduct])







    //Total Equipment plus Activation
    useEffect(()=>{
        let value = 0
        value = tabHasPaymentFactor && extPrice && (parseFloat(extPrice).toFixed(2))
        if(value){
            setTotalEquipmentPlusActivation(value)
        }
        
    },[extPrice, tabHasPaymentFactor])





    //Total Amount to Finance
    useEffect(()=>{
        const value = (tabHasPaymentFactor  && totalEquipmentPlusActivation && ( (parseFloat(totalEquipmentPlusActivation) + parseFloat(finalProposalObj.SalesTax))  - parseFloat(finalProposalObj.DownPayment))) || 0
        if(value >= 0){
            setTotalAmountFinance(value.toFixed(2))
        }else{
            setTotalAmountFinance(0)
        }
  
    },[totalEquipmentPlusActivation, finalProposalObj.DownPayment, finalProposalObj.SalesTax])



    //AnnualSavings
    useEffect(()=>{
        const value = tabHasPaymentFactor  && installedProduct && (parseFloat(installedProduct) * 12)
        if(value || value === 0){
            setAnnualSavings(value.toFixed(2))
        }
  
    },[installedProduct, finalProposalObj.ServiceTerm])




    //installedProduct
    useEffect(()=>{
        const value = tabHasPaymentFactor  && financingTermsSelectList && totalAmountFinance && (parseFloat(totalAmountFinance) * parseFloat(financingTermsSelectList.PaymentFactor))
        if(value || value === 0){
            setInstalledProduct(value.toFixed(2))
        }
        
    },[totalAmountFinance, financingTermsSelectList])




    //TotalAmountCash
    useEffect(()=>{
        const value = tabHasPaymentFactor && totalEquipmentPlusActivation && 
                        ((parseFloat(totalEquipmentPlusActivation) + parseFloat(finalProposalObj.SalesTax)) - parseFloat(finalProposalObj.DiscountAmount))
        
        if(value || value === 0){
            setTotalAmountCash(value.toFixed(2))
        }
  
    },[totalEquipmentPlusActivation,  finalProposalObj.DiscountAmount, finalProposalObj.SalesTax])






    return (
       <>

        {
            showMessageNotPackage && 
            <Notification type='popup' link="/createsystempackage" title='In order to create a proposal you must first create a package.' f={()=>{}} />
        }
      

    


        <div className="createProposal_mainContainer" >

                {
                    loadingDetails && 
                    <h3 className="applicationFormContainer-tittle">
                        <Spinner
                            radius={30}
                            color={"grey"}
                            stroke={4}
                            visible={true}
                        />
                    </h3>
                }

                {
                   dealerLogoUrl && 
                   <img src={dealerLogoUrl} alt="dealer logo" className="proposal_dealerLogo"/>
                }
                

                {/* {
                    !inDetails &&
                    <p>{financingTermsSelectList[0] ? financingTermsSelectList[0].PaymentFactor : 'Not selected'}</p>
                } */}


                {
                    (createCommercialProposal) &&
                    <>
                     <h3 className="applicationFormContainer-tittle">Company Information</h3>
                     <div className="form">
                        <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Company Name</label>
                            <input
                                type="text"
                                name="LegalCompanyName"
                                onChange={handleInput}
                                className={`${errors.LegalCompanyName ? "imputError" : "campo"}`}
                                value={finalProposalObj.LegalCompanyName}
                                placeholder="Company Name"
                                disabled={type === 'edit' ? "disabled" : null}
                                required
                            />
                            {errors.LegalCompanyName && <p className="errorMessage">{errors.LegalCompanyName}</p>}
                        </div>

                        <div></div>
                     </div>
                    </>
                }
               
                
                {
                    ((createProposal || showPersonalInformation) && !showCustomerInformacion) && 
                    <>
                        <h3 className="applicationFormContainer-tittle">{createCommercialProposal ? 'Owner Information' : 'Customer Information'}</h3>
                        <div className="form">

                            <div className="mediowidth-left campoContainer">
                                <label className="labelApplicationForm" > First Name</label>
                                <input
                                    type="text"

                                    name="FirstName"
                                    onChange={handleInput}
                                    className={`${errors.FirstName ? "imputError" : "campo"}`}
                                    value={finalProposalObj.FirstName}
                                    placeholder="First name"
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                    required
                                />
                                {errors.FirstName && <p className="errorMessage">{errors.FirstName}</p>}
                            </div>


                            <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Last Name</label>
                                <input
                                    type="text"

                                    name="LastName"
                                    onChange={handleInput}
                                    className={`${errors.LastName ? "imputError" : "campo"}`}
                                    placeholder="Last Name"
                                    value={finalProposalObj.LastName}
                                    required
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                />
                                {errors.LastName && <p className="errorMessage">{errors.LastName}</p>}
                            </div>


                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Address</label>
                                    <PlacesAutocomplete
                                        value={finalProposalObj.Address1}
                                        onChange={handleChangePlacesAutocomplete}
                                        onSelect={handleSelectPlacesAutocomplete}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({ placeholder: "Type address" })}
                                            placeholder="Address"
                                            className={`${errors.Address1 ? "imputError" : "campo"}`}
                                            autocomplete="off"
                                            disabled={(type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                                            />

                                            <div className={suggestions.length > 0 && "inputPlacesAutocomplete_optionsContainer"}>
                                            {loading ? <div>...loading</div> : null}

                                            {suggestions.map((suggestion) => {
                                                const style = {
                                                backgroundColor: suggestion.active ? "#e5e5e5" : "#fff",
                                                cursor: "pointer",
                                                marginBotton: '10ppx'
                                                };

                                                return (
                                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                                    {suggestion.description}
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete>
                                    {errors.Address1 && <p className="errorMessage">{errors.Address1}</p>}
                                </div>

                            <div></div>

                            <div className="mediowidth-left campoContainer">
                                <label className="labelApplicationForm" >City</label>
                                <input
                                    type="text"
                                    value={finalProposalObj.City}

                                    name="City"
                                    onChange={handleInput}
                                    className={`${errors.City ? "imputError" : "campo"}`}
                                    placeholder="City"
                                    required
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                />
                                {errors.City && <p className="errorMessage">{errors.City}</p>}
                            </div>




                            <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >State</label>
                                <select
                                    value={finalProposalObj.State || 'selected'}
                                    name="State"
                                    onChange={handleInput}
                                    className={`${errors.State ? "imputError" : "campo"}`}
                                    placeholder="State"
                                    required
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                >
                                    <option value="selected" disabled>
                                        State
                                    </option>
                                    <option value="AK">AK</option>
                                    <option value="AL">AL</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>           
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>                 
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="GU">GU</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>          
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>      
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MS">MS</option>
                                    <option value="MO">MO</option>
                                    <option value="MT">MT</option>
                                    <option value="NE">NE</option>
                                    <option value="NC">NC</option>
                                    <option value="ND">ND</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="PR">PR</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VA">VA</option>
                                    <option value="VI">VI</option>
                                    <option value="VT">VT</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>   
                                </select>
                                {errors.State && <p className="errorMessage">{errors.State}</p>}
                            </div>



                            <div className="mediowidth-left campoContainer">
                                <label className="labelApplicationForm" >PostalCode</label>
                                <input
                                    type="number"
                                    value={finalProposalObj.PostalCode}
                                    name="PostalCode"
                                    onChange={handleInput}
                                    className={`${errors.PostalCode ? "imputError" : "campo"}`}
                                    placeholder='PostalCode'
                                    required
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                />
                                {errors.PostalCode && <p className="errorMessage">{errors.PostalCode}</p>}
                            </div>

                            <div></div>


                            {/* CellPhone */}
                            <div className="mediowidth-left campoContainer">
                                <label className="labelApplicationForm" >Home/Cell Phone</label>
                                <input
                                    type="number"
                                    value={finalProposalObj.CellPhone}
                                    name="CellPhone"
                                    onChange={handleInput}
                                    className={`${errors.CellPhone ? "imputError" : "campo"}`}
                                    placeholder="Do not use dashes"
                                    required
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                />
                                {errors.CellPhone && <p className="errorMessage">{errors.CellPhone}</p>}
                            </div>


                            {/* BusinessPhone */}
                            <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Business Phone</label>
                                <input
                                    type="number"
                                    value={finalProposalObj.BusinessPhone}
                                    name="BusinessPhone"
                                    onChange={handleInput}
                                    className={`${errors.BusinessPhone ? "imputError" : "campo"}`}
                                    placeholder="Do not use dashes"
                                    required
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                />
                                {errors.BusinessPhone && <p className="errorMessage">{errors.BusinessPhone}</p>}
                            </div>



                            <div className="mediowidth-left campoContainer">
                                <label className="labelApplicationForm" >Email</label>
                                <input
                                    type="email"

                                    name="EmailAddress"
                                    onChange={handleInput}
                                    className={`${errors.EmailAddress ? "imputError" : "campo"}`}
                                    placeholder="Email"
                                    value={finalProposalObj.EmailAddress}
                                    required
                                    disabled={(type === 'edit' || proposal.HasCreatedApplications) ? "disabled" : null}
                                />
                                {errors.EmailAddress && <p className="errorMessage">{errors.EmailAddress}</p>}
                            </div>

                            </div>
                    </>
                }

                {
                    (createPackage || editPackage) &&
                    <>
                     <h3 className="applicationFormContainer-tittle">Package Information</h3>
                     <div className="form">
                        <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Package Name</label>
                            <input
                                type="text"
                                name="PackageName"
                                onChange={handleInput}
                                className={`${errors.PackageName ? "imputError" : "campo"}`}
                                value={finalProposalObj.PackageName}
                                placeholder="Package Name"
                                disabled={type === 'edit' ? "disabled" : null}
                                required
                            />
                            {errors.PackageName && <p className="errorMessage">{errors.PackageName}</p>}
                        </div>

                        <div></div>

                        <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Package Template (Use an existing package as a starting point.)</label>
                            <select
                                value={finalProposalObj.DealerPackages}
                                name="DealerPackages"
                                onChange={handleInput}
                                className={`${errors.DealerPackages ? "imputError" : "campo"}`}
                                placeholder="Package Template"
                                required
                                disabled={type === 'edit' ? "disabled" : null}
                            >
                                <option value="default" disabled selected>Dealer Package</option>
                                {
                                    proposal.DealerPackages && proposal.DealerPackages.map((item, index) => {
                                        return( <option value={item.DealerPackageId} key={item.DealerPackageId}>{item.Name}</option>)
                                    })
                                }

                            </select>
                            {errors.DealerPackages && <p className="errorMessage">{errors.DealerPackages}</p>}
                        </div>


                     </div>
                    </>
                }



            
                        



                <h3 className="applicationFormContainer-tittle">Proposal Information</h3>
                <div className="form">

                           
                    {
                        (createProposal || showPersonalInformation) &&
                        <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Dealer Packages</label>
                            <select
                                value={finalProposalObj.DealerPackages}
                                name="DealerPackages"
                                onChange={handleInput}
                                className={`${errors.DealerPackages ? "imputError" : "campo"}`}
                                placeholder="Dealer Package"
                                required
                                disabled={type === 'edit' ? "disabled" : null}
                            >
                                <option value="default" disabled selected>Dealer Package</option>
                                {
                                    proposal.DealerPackages && proposal.DealerPackages.map((item, index) => {
                                        return( <option value={item.DealerPackageId} key={item.DealerPackageId}>{item.Name}</option>)
                                    })
                                }

                            </select>
                            {errors.DealerPackages && <p className="errorMessage">{errors.DealerPackages}</p>}
                        </div>
                    }
                   

                            
                            

                    
                        
                      

                    <div className={(createProposal || showPersonalInformation) ? "mediowidth-right campoContainer" : "mediowidth-left campoContainer"}>
                        <label className="labelApplicationForm" >Finance Partner</label>
                        <select
                            value={finalProposalObj.FinancePartners}

                            name="FinancePartners"
                            onChange={handleInput}
                            className={`${errors.FinancePartners ? "imputError" : "campo"}`}
                            placeholder="Finance Partner"
                            required
                            disabled={type === 'edit' ? "disabled" : null}
                        >
                            <option value="default" disabled selected>Finance Partners</option>
                            {
                                proposal.FinancePartners && proposal.FinancePartners.map((item, index) => {
                                    return( (item.Id !== 'FI-0003' || createCommercialProposal || createPackage || editPackage) && <option value={item.Id} key={item.Id}>{item.Name}</option>)
                                })
                            }
                            


                        </select>
                        {errors.FinancePartner && <p className="errorMessage">{errors.FinancePartner}</p>}
                    </div>
                    

                    
                    

                    <div className={(createProposal || showPersonalInformation) ? "mediowidth-left campoContainer" : "mediowidth-right campoContainer"}>
                    <label className="labelApplicationForm" >Promotion</label>
                    <select
                        value={finalProposalObj.FinancingTerms}
                        name="FinancingTerms"
                        onChange={handleInput}
                        className={`${errors.FinancingTerms ? "imputError" : "campo"}`}
                        placeholder="Promotion"
                        required
                        disabled={type === 'edit' ? "disabled" : null}
                    >
                        <option value="default" disabled selected>Financing Terms</option>             
                        {
                            financingPartnerSelected && financingPartnerSelected.FinancingTerms && financingPartnerSelected.FinancingTerms.map((item, index) => {
                                if(financingPartnerSelected.Id === 'FI-0002'){
                                    if(item.Tier === 'Platinum'){
                                        return( <option value={item.FinancingTermId} key={item.FinancingTermId}>{`${item.PaymentFactorName}`}</option>)
                                    }
                                }else{
                                    return( <option value={item.FinancingTermId} key={item.FinancingTermId}>{`${item.PaymentFactorName}`}</option>)
                                }               
                            })
                        }
                    </select>
                    {errors.FinancingTerms && <p className="errorMessage">{errors.FinancingTerms}</p>}
                    </div>
                    
                    {
                        finalProposalObj.FinancePartners === 'FI-0002' && 
                        <div className={(createProposal || showPersonalInformation) ? "mediowidth-right campoContainer" : "mediowidth-left campoContainer"}>
                        <label className="labelApplicationForm" >Loan Program </label>
                        <select
                            value={finalProposalObj.LoanProgram}
                            name="LoanProgram"
                            onChange={handleInput}
                            className={`${errors.LoanProgram ? "imputError" : "campo"}`}
                            placeholder="Loan Program"
                            required
                            disabled={type === 'edit' ? "disabled" : null}
                        >
                            <option value="default" selected disabled>Loan Program</option>
                            {
                                 financingPartnerSelected && financingPartnerSelected.LoanPrograms && financingPartnerSelected.LoanPrograms.map((item) => {
                                    return( <option value={item.Name} kay={item.Name}>{item.Name}</option> )
                                 })
                            }
                            {/* <option value="StandardFinancing" >Standard Financing</option>
                            <option value="StandardFinancing" >Standard Financing</option>  
                            <option value="90 Days SAC" >90 Days Same-as-Cash</option>
                            <option value="180 Days SAC" >180 Days Same-as-Cash</option>
                            <option value="365 Days SAC" >365 Days Same-as-Cash</option>
                            <option value="18 Months SAC" >18 Months Same-as-Cash</option>
                            <option value="24 Months SAC" >24 Months Same-as-Cash</option>
                            <option value="36 Months SAC" >36 Months Same-as-Cash</option>
                            <option value="90 Days NP" >90 Days No Payments</option>
                            <option value="90 Days NPNI" >90 Days No Payment & No Interest</option> */}
                        </select>
                        {errors.LoanProgram && <p className="errorMessage">{errors.LoanProgram}</p>}
                        </div>
                    }
                    

                    

                    <div></div>

                    <div className="fullwidth separator"></div>


                </div>  
                    
                <div className="proposal_tabsContainer">
                    <ProposalsProductsTab 
                        title="Installed Products"
                        productList={proposal.DefaultDealerProposalProducts || []} 
                        empyList={(proposal.DefaultDealerProposalProducts && proposal.DefaultDealerProposalProducts.length === 0) ? true : false}
                        validateDefaultProductList={validateDefaultProductList}
                        // fillDefaultProductList={fillDefaultProductList}
                        setDefaultProductList={setDefaultProductList}
                        financingTermsSelectList={financingTermsSelectList}
                        setEquipmentMonthlyPayment={setEquipmentMonthlyPayment}
                        setTabHasPaymentFactor={setTabHasPaymentFactor}
                        opcionalProducts={proposal.DealerProposalProducts || []}
                        setExtPrice={setExtPrice}
                        setExtProductsMthly={setExtProductsMthly}
                        showAllCollumns
                        showPrice={showPrice}
                        showMonthly={showMonthly}
                        type={type}
                        PaymentFactor = {proposal.PaymentFactor}
                        isDragDisabled={isDragDisabled}
                        productsWithOutValidate={setProducts}
                    />

                    {
                        showServices &&
                        <ProposalsProductsTab 
                            title="Services"
                            productList={showServices ? (proposal.DefaultDealerMonitoringProposalProducts || []) : []} 
                            validateDefaultProductList={validateDefaultMonitoringProductList}
                            empyList={(proposal.DefaultDealerMonitoringProposalProducts && proposal.DefaultDealerMonitoringProposalProducts.length === 0) ? true : false}
                            // fillDefaultProductList={fillDefaultProductList}
                            setDefaultProductList={setDefaultMonitoringProductList}
                            financingTermsSelectList={financingTermsSelectList}
                            setMonitoringValue={setMonitoringValue}
                            setTabHasPaymentFactor={setTabHasPaymentFactor}
                            opcionalProducts={proposal.DealerMonitoringProposalProducts || []}
                            // showPrice={true}
                            // showMonthly={true}
                            showPrice={showPrice}
                            showMonthly={showMonthly}
                            type={type}
                            PaymentFactor = {proposal.PaymentFactor}
                            isDragDisabled={isDragDisabled}
                            productsWithOutValidate={setMonitoringProducts}
                        />
                    }         
                    
                </div>
                
                
                
                <div className="proposal_bg-grey">
                <h3 className="applicationFormContainer-tittle">Monthly Payment</h3>
                <div className="form">

                    {
                        showServices &&
                        <>
                             <div className="mediowidth-left campoContainer campo_movilFullWidth">
                                <label className="labelApplicationForm" >Total Monthly Payment</label>
                                <input
                                    type="text"
                                    value={tabHasPaymentFactor ? `$ ${totalMonthlyPayment}` : ''}
                                    name="TotalMonthlyPayment"
                                    onChange={handleInput}
                                    className={`${errors.TotalMonthlyPayment ? "imputError" : "campo"}`}
                                    placeholder="Total Monthly Payment"
                                    required
                                    // disabled={type === 'edit' ? "disabled" : null}
                                    disabled="disabled"
                                />
                                {errors.TotalMonthlyPayment && <p className="errorMessage">{errors.TotalMonthlyPayment}</p>}
                            </div>
                            <div></div>

                            <div className="mediowidth-left campoContainer campo_movilFullWidth">
                                <label className="labelApplicationForm" >Services Monthly</label>
                                <input
                                    type="text"
                                    value={tabHasPaymentFactor ? `$ ${monitoringValue}` : ''}
                                    name="Monitoring"
                                    onChange={handleInput}
                                    className={`${errors.Monitoring ? "imputError" : "campo"}`}
                                    placeholder="Services Monthly"
                                    required
                                    // disabled={type === 'edit' ? "disabled" : null}
                                    disabled="disabled"
                                />
                                {errors.Monitoring && <p className="errorMessage">{errors.Monitoring}</p>}
                            </div>
                            <div></div>
                        </>
                    }
                   

                    <div className="mediowidth-left campoContainer campo_movilFullWidth">
                        <label className="labelApplicationForm" >Total Amount to Finance Monthly</label>
                        <input
                            type="text"
                            value={tabHasPaymentFactor ? `$ ${installedProduct}` : ''}
                            name="EquipmentMonthlyPayment"
                            onChange={handleInput}
                            className={`${errors.EquipmentMonthlyPayment ? "imputError" : "campo"}`}
                            placeholder="Total Amount to Finance Monthly"
                            required
                            // disabled={type === 'edit' ? "disabled" : null}
                            disabled="disabled"
                        />
                        {errors.EquipmentMonthlyPayment && <p className="errorMessage">{errors.EquipmentMonthlyPayment}</p>}
                    </div>


                </div>
                </div>



                <h3 className="applicationFormContainer-tittle">Product Totals</h3>
                <div className="form">
                           

                    <div className="mediowidth-left campoContainer">
                        <p className="proposal-label_textRight">Total Installed Product</p>
                    </div>
                    <div className="mediowidth-right campoContainer">
                        <div className={`${errors.TotalEquipmentPlusActivation ? "imputError" : "campo"}`}>
                            <span className="inputCurrencySign">$</span>
                            <input
                                type="number"
                                value={tabHasPaymentFactor  ? totalEquipmentPlusActivation : ''}
                                name="TotalEquipmentPlusActivation"
                                onChange={handleInput}
                                className='inputCurrency'
                                placeholder="Total Installed Product"
                                required
                                disabled="disabled"
                            />
                        </div>
                        
                        {errors.TotalEquipmentPlusActivation && <p className="errorMessage">{errors.TotalEquipmentPlusActivation}</p>}
                    </div>

                    <div className="mediowidth-left campoContainer">
                        <p className="proposal-label_textRight">Sales Tax</p>
                    </div>
                    <div className="mediowidth-right campoContainer">
                        <div className={`${errors.SalesTax ? "imputError" : "campo"}`}>
                            <span className="inputCurrencySign">$</span>
                            <input
                                type="number"
                                value={tabHasPaymentFactor && finalProposalObj.SalesTax}
                                name="SalesTax"
                                onChange={handleInput}
                                onBlur={()=> { setFinalProposalObj({...finalProposalObj, 'SalesTax': parseFloat(finalProposalObj.SalesTax).toFixed(2) })   }}
                                className='inputCurrency'
                                placeholder="Sales Tax"
                                required
                                disabled={type === 'edit' ? "disabled" : null}
                            />
                        </div>
                        
                        {errors.SalesTax && <p className="errorMessage">{errors.SalesTax}</p>}
                    </div>



                    <div className="mediowidth-left campoContainer">
                        <p className="proposal-label_textRight">Down Payment</p>
                    </div>
                    <div className="mediowidth-right campoContainer">
                        <div className={`${errors.DownPayment ? "imputError" : "campo"}`}>
                            <span className="inputCurrencySign">$</span>
                            <input
                                type="number"
                                value={tabHasPaymentFactor && finalProposalObj.DownPayment}
                                name="DownPayment"
                                onChange={handleInput}
                                onBlur={()=> { setFinalProposalObj({...finalProposalObj, 'DownPayment': parseFloat(finalProposalObj.DownPayment).toFixed(2) })   }}
                                className='inputCurrency'
                                placeholder="Down Payment"
                                required
                                disabled={type === 'edit' ? "disabled" : null}
                            />
                        </div>         
                        {errors.DownPayment && <p className="errorMessage">{errors.DownPayment}</p>}
                    </div>


                    


                  

                    <div className="mediowidth-left campoContainer">
                        <p className="proposal-label_textRight">Total Amount to Finance</p>
                    </div>
                    <div className="mediowidth-right campoContainer">
                        <div className={`${errors.TotalAmountFinance ? "imputError" : "campo"}`}>
                            <span className="inputCurrencySign">$</span>
                            <input
                                type="number"
                                value={tabHasPaymentFactor &&  (finalProposalObj.DownPayment || finalProposalObj.DownPayment === 0) ? totalAmountFinance : ''}
                                name="TotalAmountFinance"
                                onChange={handleInput}
                                className='inputCurrency'
                                placeholder="Total Amount to Finance"
                                required
                                disabled="disabled"
                            />
                        </div>         
                        {errors.TotalAmountFinance && <p className="errorMessage">{errors.TotalAmountFinance}</p>}
                    </div>



                    <div className="fullwidth separator"></div>

                    {
                        showServices &&
                        <>
                            <div className="mediowidth-left campoContainer">
                                <p className="proposal-label_textRight">Service Term (Months)</p>
                            </div>


                            {/* ServiceTerm */}
                            <div className="mediowidth-right campoContainer">
                                <input
                                    type="number"
                                    value={tabHasPaymentFactor ? finalProposalObj.ServiceTerm : 0}
                                    name="ServiceTerm"
                                    onChange={handleInput}
                                    className={`${errors.ServiceTerm ? "imputError" : "campo"}`}
                                    placeholder="Service Term (Months)"
                                    required
                                    disabled={type === 'edit' ? "disabled" : null}
                                />
                                {errors.ServiceTerm && <p className="errorMessage">{errors.ServiceTerm}</p>}
                            </div>
                        </>
                    }
                    

                    <div className="mediowidth-left campoContainer">
                        <p className="proposal-label_textRight">Customer Owned</p>
                    </div>


                    {/* CustomerOwned */}
                    <div className="mediowidth-right campoContainer">
                        <input
                            type="text"
                            value='YES'
                            name="CustomerOwned"
                            onChange={handleInput}
                            className={`${errors.CustomerOwned ? "imputError" : "campo"}`}
                            placeholder="Customer Owned"
                            required
                            disabled="disabled"
                        />
                        {errors.CustomerOwned && <p className="errorMessage">{errors.CustomerOwned}</p>}
                    </div>

                   

                    <div className="mediowidth-left campoContainer">
                        <p className="proposal-label_textRight">Annual Savings (After All Products Paid Off)</p>
                    </div>

                    <div className="mediowidth-right campoContainer">
                        <div className={`${errors.AnnualSavings ? "imputError" : "campo"}`}>
                            <span className="inputCurrencySign">$</span>
                            <input
                                type="number"
                                value={tabHasPaymentFactor ? annualSavings : 0}
                                name="AnnualSavings"
                                onChange={handleInput}
                                className='inputCurrency'
                                placeholder="Annual Savings (After All Products Paid Off)"
                                required
                                disabled="disabled"
                            />
                        </div>         
                        {errors.AnnualSavings && <p className="errorMessage">{errors.AnnualSavings}</p>}
                    </div>


                    <div className="fullwidth separator"></div>



                   

                    
                    {
                        (createProposal || editProposal) &&
                        <>
                                 <div className="mediowidth-left campoContainer">
                                    <p className="proposal-label_textRight">Cash Discount</p>
                                </div>
                                <div className="mediowidth-right campoContainer">       
                                <select
                                    value={finalProposalObj.Discount}
                                    name="Discount"
                                    onChange={handleInput}
                                    className={`${errors.Discount ? "imputError" : "campo"}`}
                                    placeholder="Finance Partners"
                                    required
                                    disabled={type === 'edit' ? "disabled" : null}
                                >
                                    <option value="0.00">0%</option>
                                    <option value="0.05">5%</option>
                                </select>
                                {errors.Discount && <p className="errorMessage">{errors.Discount}</p>}
                            </div>

                                
                            <div className="mediowidth-left campoContainer">
                                <p className="proposal-label_textRight">Total Amount - Cash</p>
                            </div>


                            <div className="mediowidth-right campoContainer">
                                <div className={`${errors.TotalEquipmentPlusActivation ? "imputError" : "campo"}`}>
                                    <span className="inputCurrencySign">$</span>
                                    <input
                                        type="number"
                                        value={tabHasPaymentFactor ? totalAmountCash : 0}
                                        name="TotalEquipmentPlusActivation"
                                        onChange={handleInput}
                                        className='inputCurrency'
                                        placeholder="Total Amount - Cash"
                                        required
                                        disabled="disabled"
                                    />
                                </div>                     
                            </div>
                        </>
                    }
                    



                    {
                        type !== 'edit' &&
                        <div className="fullwidth createProposal_buttonsContainer">

                        {   
                            !createProposalAfterApplication &&
                            (
                                closeSheet ? 
                                <button onClick={()=>setShowSheet(!showSheet)} className="createProposal_cancelButton">Cancel</button>
                                :
                                <Link to="/applicants" className="createProposal_cancelButton">Cancel</Link>
                            )
                            

                        }

                        

                        


                        {
                            (loadingPost && (createProposal || editProposal)) ?
                            <button className="createProposal_cancelButton" >
                                <Spinner
                                    radius={20}
                                    color={"#31D902"}
                                    stroke={3}
                                    visible={true}
                                />
                            </button>
                            :
                            createProposal || editProposal ?
                            <button className="createProposal_cancelButton" onClick={()=>{handleSubmit(); setRedirectToCreateApp(false); setStayInThePageAfterSubmit(true)}}>Save</button>
                            :
                            null
                        }

                        {
                            (loadingPost && (createProposal && !createProposalAfterApplication)) ?
                            <button className="createProposal_cancelButton" >
                                <Spinner
                                    radius={20}
                                    color={"#31D902"}
                                    stroke={3}
                                    visible={true}
                                />
                            </button>
                            :
                            (createProposal && !createProposalAfterApplication) ?
                            <button className="createProposal_cancelButton" onClick={()=>{handleSubmit(); setRedirectToCreateApp(false); setStayInThePageAfterSubmit(false)}}>Save & Exit</button>
                            :
                            null
                        }
                       
    
                      
                        {
                            (dontShowCreateAppButton || proposal.HasCreatedApplications) ? null
                            :
                            (loadingPost && !dontShowCreateAppButton) ?
                            <button className="createProposal_newAppButton" >
                                <Spinner
                                    radius={20}
                                    color={"white"}
                                    stroke={3}
                                    visible={true}
                                />
                            </button>
                            :
                            <button className="createProposal_newAppButton" onClick={handleSubmit}>{(createProposal || editProposal) ? 'Create New Application' : 'Save Package'}</button>
                        }
                        
                        </div>
                    }
                   
                    <div></div>

                    {postError &&  <div className="mediowidth-right text_Red" >An error has occurred, please try again.</div>}

                    {editProposalSuccess &&  <div className="mediowidth-right text_green" >The proposal has been edited successfully.</div>}
                    {createProposalSuccess &&  <div className="mediowidth-right text_green" >The proposal has been created successfully.</div>}
                   



                   


                </div>

                {/* TOGGLE */}
                <div className="form">

                    <div className="proposals_switch">
                        <label className="switch">
                        <input type="checkbox" id="checkStubApplication"  {...showServices  && { checked: "checked" } } name="isActive" onClick={()=> setShowServices(!showServices)} />      
                        <span className="slider round"></span>
                        </label>
                        
                        <p className="createApplicationPinnacle_switchText">Show Services</p>
                    </div>

                    <div></div>

                     <div className="proposals_switch">
                        <label className="switch">
                        <input type="checkbox" id="checkStubApplication"  {...showPrice  && { checked: "checked" } } name="isActive"  onClick={()=> setShowPrice(!showPrice)} />      
                        <span className="slider round"></span>
                        </label>
                        
                        <p className="createApplicationPinnacle_switchText">Show Price</p>
                    </div>

                    <div></div>
             
             
                    <div className="proposals_switch">
                        <label className="switch">
                        <input type="checkbox" id="checkStubApplication"  {...showMonthly  && { checked: "checked" } } name="isActive"  onClick={()=> setShowMonthly(!showMonthly)} />      
                        <span className="slider round"></span>
                        </label>
                        
                        <p className="createApplicationPinnacle_switchText">Show Monthly</p>
                    </div>
                    {/* TOGGLE */}


                </div>

                {
                    showCreatePdfButton && 
                    <div className="form">
                    {
                        (loadingPost && (createProposal || editProposal)) ?
                        <button className="createProposal_cancelButton justify-self-start" >
                            <Spinner
                                radius={20}
                                color={"#31D902"}
                                stroke={3}
                                visible={true}
                            />
                        </button>
                        :
                        <button onClick={handleCreatePdf} className="createProposal_cancelButton justify-self-start">Email Proposal</button>                  
                    }
                    <div></div>
                    {
                        pdfSuccess && 
                        <p className="justify-self-start" style={{color: "#31d902", marginTop: "10px"}}>The PDF was successfully sent </p>
                    }
                    
                    </div>  
                }

                
                

                
            </div>
       </>
    )
}

export default ProposalForm;
