import React, { useState, useContext } from 'react';
import '../../assets/styles/ReportRow.css'
import {AuthContext} from '../../AuthContext';
import { useEffect} from 'react';
import SkeletonLoader from "tiny-skeleton-loader-react";
import Paginator from 'react-hooks-paginator';
import ApplicationRow from '../applicationsPage/ApplicationRow';
import iconArrowDownGreen from '../../assets/images/iconArrowDownGreen.png';
import iconArrowDownGrey2 from '../../assets/images/iconArrowDownGrey2.png';
import iconArrowUpGreen from '../../assets/images/iconArrowUpGreen.png';
import iconArrowUpGrey2 from '../../assets/images/iconArrowUpGrey2.png';
import moment from 'moment-timezone';
import RemittanceReportRow from './RemittanceReportRow';
import CsvDownload from 'react-json-to-csv'




const RemittanceReportList = ({termSearch, searchBy, filterStatusValue, filterFIValue, refreshGetApplicant, filterStatusSelected, dateRange, setNetProceedsTotal})=>{


   

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    
    const [applicationData, setApplicationData] = useState([]);

    const [originalData, SetoriginalData] = useState([]);

    //const [showFilter, setShowFilter] = useState(false);

    const [hasData, setHasData] = useState(true);

  
    const [filterIndex, SetFilterIndex] = useState(2);


    const [reorder, setReorder] = useState(false);

    const [propertyFilter, setPropertyFilter] = useState('DealerAllyFundingDate')

    //Pagination
    const pageLimit = 20;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);



    
    //RECUPERAR VALORES DE SESSIONSTORAGE Y CONTEXTAPI
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const endpoint = localStorage.getItem("getApplicationsEndpoint");   
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');



    

    //FETCH GET REPORT APPLICATIONS
    useEffect(()=>{
        setIsLoading(true)


        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_REPORTS}get-applications`
        // console.log(URI)

        const abortController = new AbortController();
        const signal = abortController.signal;


        fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token, 
                //'Authorization' : 'eyJraWQiOiI0UHdIM29paVJRd29RMVJQWldHZVVVN3NxTWNENnk3ck5La1wvRXlcL3FcL09FPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlMDAzZWEzOS1lMjI4LTQzMzEtOWIzZi01ZjI1N2QyYjI1ZTMiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfMmxJenpxcVQ4IiwiY29nbml0bzp1c2VybmFtZSI6ImJvcmlzYmFsZG92aW5vIiwiYXVkIjoiNmk4NW51c3MwbTU5YWpxMmM0MHJkMXUwZzgiLCJldmVudF9pZCI6IjAwMTg4NzBjLWFhYjAtNGUyYS1iZWM5LWU5ZmY0ODVhZmIwNCIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjAwMzE3Njk3LCJuYW1lIjoiQm9yaXMiLCJleHAiOjE2MDA0MDQwOTcsImlhdCI6MTYwMDMxNzY5NywiZmFtaWx5X25hbWUiOiJCYWxkb3Zpbm8iLCJlbWFpbCI6ImJvcmlzamJhbGRvdmlub0BnbWFpbC5jb20ifQ.B4PJrkX-8qiqY5fFkvwqbj7vn9p16NVZoKBe6PsZ6Vx3F9CllmBjn9Zsddm1tw3nlbiTSRmr8bRcev46OJyO-qntYhodTsF0_st3wh04LrGLX6B9DiCvD0-cy9qlQAOEZHjSsjWz5hGBbH1ehrttCiwrPyRw_j3jXmhaKq1GC1uSQ7hQ-2WSs7LzJm7UEpqCoXSriMwqcMbKyFtgKtTtqLaxZgyxI0FSJjarQ-ToZC82657AtrpaFQ8P_UpTXGLMAQUuQzSC3cvBCRns77aLDCjyBV55wTcPn2AB7Q0fxqOpfsCnI5IUd13Oz9kaWimKg8UiE-oYt2XksfRWkM9b0g',    
                'x-api-key' : URI_X_API_KEY_I,   
              }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoading(false)
           
            // console.log(datos);

           // console.log(roundOutNumbers(datos))
                
            if(datos.status === 'NO_APPLICATIONS_FOUND'){
                setHasData(false)
            }else{
                setApplicationData(roundOutNumbers(datos));
                SetoriginalData(roundOutNumbers(datos));

                setNetProceedsTotal(calculateNetProceedsTotal(roundOutNumbers(datos)))

                
            }
           
            

            setErrorFlagFromContext(false)

        })
        .catch((error, response) => {
            console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }    
        });
        

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, refreshGetApplicant]);

 



    const roundOutNumbers = (list) => {
        if(Array.isArray(list)){
            const newList = list.map(item => {
                return(
                    {
                        ...item,
                        ACHBonus : item.ACHBonus ? parseFloat(item.ACHBonus).toFixed(2) : undefined,
                        DocFee : item.DocFee ? parseFloat(item.DocFee).toFixed(2) : undefined,
                        FinalBid : item.FinalBid ? parseFloat(item.FinalBid).toFixed(2) : undefined,
                        NetProceeds : item.NetProceeds ? parseFloat(item.NetProceeds).toFixed(2) : undefined,
                        PurchaseAmt : item.PurchaseAmt ? parseFloat(item.PurchaseAmt).toFixed(2) : undefined,
                        ProgramDiscountPct : item.ProgramDiscountPct ? parseFloat(item.ProgramDiscountPct).toFixed(2) : undefined
                    }
                )
            })
    
            return(newList)
        }else{
            return []
        }
        
    }






    
    //Pagination
    useEffect(() => {

        if(applicationData.length > 0){
            // console.log(applicationData.length)
            setNetProceedsTotal(calculateNetProceedsTotal(applicationData))
            
            setCurrentData(applicationData.slice(offset, offset + pageLimit));       
        }else {
            setNetProceedsTotal(calculateNetProceedsTotal([]))
            setCurrentData([].slice(offset, offset + pageLimit)); 
        }
        

    }, [offset, applicationData, reorder ]);

   





    //SEARCHER AND FILTER
    useEffect(()=>{
        // console.log(searchBy)
        // console.log(termSearch)
        if(termSearch === ''){
            // console.log(filterStatusSelected)
            const results = originalData.filter(applicant => (filterFIValue !== '' ? (applicant.FinancePartner || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true )
                                                            && ( Object.keys(dateRange).length === 0 ? true 
                                                            : 
                                                            applicant.DealerAllyFundingDate === undefined || applicant.DealerAllyFundingDate === '' ?
                                                            false
                                                            :
                                                            moment(applicant.DealerAllyFundingDate).isBetween(moment(dateRange.startDate),moment(dateRange.endDate), undefined, '[]'))
                                                         
        )
        setApplicationData(results)

        }else {      
            const results = originalData.filter(applicant => (  ((applicant[searchBy] || '').toLowerCase().includes(termSearch.toLowerCase()))
                                                                && ((filterFIValue !== '' ? (applicant.FinancePartner || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true ))  
                                                                && (Object.keys(dateRange).length === 0 ? true 
                                                                : 
                                                                applicant.DealerAllyFundingDate === undefined || applicant.DealerAllyFundingDate === '' ?
                                                                false
                                                                : 
                                                                moment(applicant.DealerAllyFundingDate).isBetween(moment(dateRange.startDate),moment(dateRange.endDate)))
                                                            )
                                                                
            )

            setApplicationData(results)
        }
    }, [termSearch, searchBy, filterFIValue, dateRange])
    
  



    
    //CALCULATE Net Proceeds Total
    const calculateNetProceedsTotal = (list) => {
        let total = 0
        list.forEach(item => {
            if(item.NetProceeds){
                total = total + parseFloat(item.NetProceeds)
            }
            
        });

        // console.log(parseFloat(total).toFixed(2))
        return parseFloat(total).toFixed(2)
    }


    //SORTER BY COLUMS
    // const dynamicSort =(property) => {
    //     setReorder(!reorder)
    //     setPropertyFilter(property)
      
    //     return function(a, b) { 
    //         console.log(a[property])        
    //         if (filterIndex === 1) {
            
    //             if(b[property] === undefined){
    //                 b[property] = ''
    //             }
    //             if(a[property] === undefined){
    //                 a[property] = ''
    //             }
    //             SetFilterIndex(2)
    //             return b[property].localeCompare(a[property]);
    //         } else {
    //             if(b[property] === undefined){
    //                 b[property] = ''
    //             }
    //             if(a[property] === undefined){
    //                 a[property] = ''
    //             }
    //             SetFilterIndex(1)
    //             return a[property].localeCompare(b[property]);
    //         }
          
    //     };
     
    // }


    const dynamicSort =(property) => {
        // console.log(property)
        setReorder(!reorder)
        setPropertyFilter(property)
      
        return function(a, b) { 
            // console.log(a[property].toString()) 
            if (filterIndex === 1) {
            
                if(b[property] === undefined ){
                    b[property] = ''
                }
                if(a[property] === undefined ){
                    a[property] = ''
                }
                SetFilterIndex(2)
                return b[property].toString().localeCompare(a[property].toString());
            } else {
                if(b[property] === undefined ){
                    b[property] = ''
                }
                if(a[property] === undefined ){
                    a[property] = ''
                }
                SetFilterIndex(1)
                return a[property].toString().localeCompare(b[property].toString());
            }
          
        };
     
    }




    const arrowDirection = (property) => {

        if(property === propertyFilter && filterIndex === 1){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            )
        }else if(property === propertyFilter && filterIndex === 2 ){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }else{
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }

       
    }




    // useEffect(() => {
    //     setPropertyFilter('LoanNumber')
    // }, [])

   




    //LOADING CARDS
    const loadingCard = () =>{
        const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        return (list.map(item =>{
            return (
                <div className="tableGrid reportRow " key={item}>
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />    
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" /> 
                </div> 

            )
        }))
    }







   






    return(
    <>

   
        <div className="reportsListView_mainContainer height-280">
        
        {
            !isLoading && applicationData.length > 0 &&
           
           
            <CsvDownload 
                data={applicationData} 
                filename="DealerAlly_report.csv"
                style={{ //pass other props, like styles
                    backgroundColor: '#31D902',
                    padding: '8px 10px',
                    borderRadius: '4px',
                    color: 'white',
                    cursor: 'pointer',
                    }}
            >
                Download Report
            </CsvDownload>
           
        }

        {
            !isLoading &&
            <div className="reports_resultsCounter">
                <p>{`${applicationData.length} results`}</p>
            </div>
        }
        
        

        <div className="reports_listMainContainer">

            

                {/* COLUMN HEADERS */}
                <div className={isDeallerAllyAdmin === 'true' ? "tableGridForAdmin report_list-Tableheaders" : "tableGrid report_list-Tableheaders"}>
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("LoanNumber"))}}>
                        <div>
                            <p>Loan Nbr</p>
                        </div> 
                        {arrowDirection('LoanNumber')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("LenderNbr"))}}>
                        <div>
                            <p>Lender Nbr</p>
                        </div> 
                        {arrowDirection('LenderNbr')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("CustomerName"))}}>
                        <div>
                            <p>Customer Name</p>
                        </div> 
                        {arrowDirection('CustomerName')}
                    </div>       
                    {
                    isDeallerAllyAdmin === 'true' &&  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("DealerName"))}}>
                            <div>
                                <p>Dealer Name</p>
                            </div> 
                            {arrowDirection('DealerName')}
                    </div> 
                    }
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("AddressState"))}}>
                        <div>
                            <p>Address State</p>
                        </div> 
                        {arrowDirection('AddressState')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("FinancePartner"))}}>
                        <div>
                            <p>Finance Partner</p>
                        </div> 
                        {arrowDirection('FinancePartner')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("DealerAllyFundingDate"))}}>
                        <div>
                            <p>DealerAlly Funding Date</p>
                        </div> 
                        {arrowDirection('DealerAllyFundingDate')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PurchaseAmt"))}}>
                        <div>
                            <p>Purchase Amt</p>
                        </div> 
                        {arrowDirection('PurchaseAmt')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("BuyRate"))}}>
                        <div>
                            <p>BuyRate</p>
                        </div> 
                        {arrowDirection('BuyRate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("ProgramDiscountPct"))}}>
                        <div>
                            <p>Program <br/> Disc Pct</p>
                        </div> 
                        {arrowDirection('ProgramDiscountPct')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("FinalBid"))}}>
                        <div>
                            <p>Final Bid</p>
                        </div> 
                        {arrowDirection('FinalBid')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("ACHBonus"))}}>
                        <div>
                            <p>ACH Bonus</p>
                        </div> 
                        {arrowDirection('ACHBonus')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("DocFee"))}}>
                        <div>
                            <p>Doc Fee</p>
                        </div> 
                        {arrowDirection('DocFee')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("NetProceeds"))}}>
                        <div>
                            <p>Net Proceeds</p>
                        </div> 
                        {arrowDirection('NetProceeds')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Notes"))}}>
                        <div>
                            <p>Notes</p>
                        </div> 
                        {arrowDirection('Notes')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Promotion"))}}>
                        <div>
                            <p>Promotion</p>
                        </div> 
                        {arrowDirection('Promotion')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("APR"))}}>
                        <div>
                            <p>APR</p>
                        </div> 
                        {arrowDirection('APR')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Term"))}}>
                        <div>
                            <p>Term</p>
                        </div> 
                        {arrowDirection('Term')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Program"))}}>
                        <div>
                            <p>Program</p>
                        </div> 
                        {arrowDirection('Program')}
                    </div> 
                </div> 
        


            
            

                {isLoading ? loadingCard() : null} 
                

                {
                    hasData ?
                    currentData.map(item => <RemittanceReportRow key={item.ApplicationId} {...item} /> )
                    : 
                    <h3 className="application_message-notApplications">No Applications submitted</h3>
                }

            

            
                

            

            
                
                
                <div style={{position:'absolute', bottom:'0', left: '0', right:'0'}}>
                    <Paginator
                    totalRecords={applicationData.length}
                    pageLimit={pageLimit}
                    pageNeighbours={20} 
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    // pageNextClass='bg_green'
                    // pagePrevClass='bg_green'
                />
                </div>


        </div>


       



       



    </div>
    </>
)
}

export default RemittanceReportList;