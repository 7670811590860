import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/Library.css'
import iconAdd from '../assets/images/iconAdd.png';
import LibraryCard from '../components/LibraryCard';
import NewPostLibrary from '../components/NewPostLibrary';
import {AuthContext} from '../AuthContext';
import SkeletonLoader from "tiny-skeleton-loader-react";




const Library = ()=>{

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [showSheet, setShowSheet] = useState(false);

    const [realoadGetLibrary, setReloadGetLibrary] = useState(false);

    const [isloading, setIdLoading] = useState(false);


    
    

    const [postData, setPostData] = useState([]);
    const [hasData, setHasData] = useState(false);



    const token = window.localStorage.getItem('token');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;

    //PERMISOS
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');



        //FETCH GET LIBRARY POSTS
        useEffect(()=>{
            setIdLoading(true)

      
    
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_LIBRARY}${uri.URI_LIBRARY_DOCUMENTS}get-all-documents`
            //console.log(URI)
    
            const abortController = new AbortController();
            const signal = abortController.signal;
    
           
             

            window.fetch(URI,{
                method: 'GET',
                signal: signal,
                headers: {  'Content-Type': 'application/json', 
                            'Authorization' : token, 
                            'x-api-key' : URI_X_API_KEY_I,            
                            }
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                  }
                return Promise.reject(response);
            })
            .then(datos => {
                setIdLoading(false)
                // console.log(datos)

                if(datos.length > 0){
                    setHasData(true);
                    setPostData(datos);
                }
               
                setErrorFlagFromContext(false)
    
    
            }).catch((error, response) => {
                console.log(error)
                if(errorFlagFromContext === false && error.status === 401){
                    changeToken().then(response => console.log(response)).catch(error => console.log(error))
                }    
            });
            
    
            return function cleanup(){
                abortController.abort();
            }
            
        },[realoadGetLibrary, reloadFetchFromContext]);




        const showSheetHandler = ()=>{
            setShowSheet(!showSheet);
    
        }



        const reload = ()=>{
            setReloadGetLibrary(!realoadGetLibrary)
        }


     

        const loadingCard = ()=>{
            const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
            return (list.map(item =>{
                return(
                    <div className="loadingCard">
                        <div className="loadingCard_tittle"><SkeletonLoader width="100%" height="24px"/></div>
                        <div className="loadingCard_items">
                            <div className="loadingCard_item"><SkeletonLoader width="90%" /></div>
                            <div className="loadingCard_item"><SkeletonLoader width="90%" /></div>
                            <div className="loadingCard_item"><SkeletonLoader width="90%" /></div>
                            <div className="loadingCard_item"><SkeletonLoader width="90%" /></div>
                        </div>
                        
                    </div>
                )
                
            }))
            
        }
        

    return(
    <>
        {showSheet ?
        <NewPostLibrary handler={showSheetHandler} reload={reload} />

            
        : null}


        <div className="library">

        
            <div className="library-header">               
                <div className="library-header-tittle">
                    <h2>Library</h2>
                </div>
                <div className="library-header-buttoms">




            {
                isDeallerAllyAdmin &&
                <div onClick={showSheetHandler} className="library-header-buttoms_new">
                <img src={iconAdd} className="aplications-header-buttoms_new_icon" />
                    <p className="library-header-buttoms_new_text">
                    <label className="library-header-buttoms_new_text_1">New</label>   
                    <label className="library-header-buttoms_new_text_2"> Post</label>
                </p>
                </div>
            }
                    


                
            </div>        
        </div>
                   


        <div className="library-content">

            {isloading ? loadingCard() : null} 

            {hasData ? 
             postData.map((item, index) =>{
                return (
                    <LibraryCard key={index} reload={reload} {...item} postData={postData} setPostData={setPostData}/>
                )
            })
            

            :
            
            <h3>There is no post created yet</h3>

            
        }

            
            





        </div>

        </div>

        

    </>
)
}

export default Library;