import {geocodeByAddress} from 'react-places-autocomplete';


export default function useHandlePlacesAutocomplete(setFormState, name) {

  const handleChangePlacesAutocomplete = (e, index) => {


    // if(index){
    //   setFormState(state => {
    //     return state.map((owner, i) => {
    //       if(index === i){
    //         return {
    //           ...state, [name] : e
    //         }
    //       }else{
    //         return owner
    //       }
    //     })
    //   })
    // }else{
    //   setFormState(state => ({...state, [name] : e}))
    // }

    setFormState(state => ({...state, [name] : e}))
    
  };

  const handleSelectPlacesAutocomplete = async (e, index) => {
   
    // console.log(e)
    let indice = e.indexOf(",");
    let extraida = e.substring(0, indice);


    const results = await geocodeByAddress(e);


    const city = results[0].address_components.find(address => {
      if(address.types.includes('locality')){
        return address
      }
    })



    const state = results[0].address_components.find(address => {
      if(address.types.includes('administrative_area_level_1')){
        return address
      }
    })
 


    const postalCode = results[0].address_components.find(address => {
      if(address.types.includes('postal_code')){
        return address
      }
    })

   
    // if(index){  //SI ES UNA LISTA

      
    //   setFormState(oldState => {
    //     return oldState.map((owner, i) => {
    //       if(i === index){
    //         return {
    //           ...owner,
    //           [name] : extraida,
    //           City: city ? city.short_name : '',
    //           State: state ? state.short_name : '',
    //           PostalCode: postalCode ? postalCode.short_name : ''
    //         }
    //       }else{
    //         return owner
    //       }
    //     })
    // })


    // }else{  //SI ES UN OBJETO NORMAL
    //   setFormState(oldState => ({...oldState, 
    //     [name] : extraida,
    //     City: city ? city.short_name : '',
    //     State: state ? state.short_name : '',
    //     PostalCode: postalCode ? postalCode.short_name : ''
    //   }))
    // }

    setFormState(oldState => ({...oldState, 
      [name] : extraida,
      City: city ? city.short_name : '',
      State: state ? state.short_name : '',
      PostalCode: postalCode ? postalCode.short_name : ''
    }))

    
   

  };

  return [
    handleChangePlacesAutocomplete,
    handleSelectPlacesAutocomplete
  ]
    

}