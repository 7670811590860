import React, {useState} from 'react'
import Spinner from 'react-spinner-material';

const AquaPromotionCard = ({Name,  PromotionName,  PromoCode,  EstimatedMonthlyPayment,  PromotionId,  editContract, setPromotionId, promotionId, disabled, Description }) => {

    // console.log(promotionId)
    // console.log(PromotionId)


    const [isLoadingDetails, setIsLoadingDetails] = useState(false);
    const [internalId, setInternalId] = useState()


    
    const selectPromotion = () => {
        // console.log(PromotionId)
        setPromotionId(PromotionId)
    }





    const descriptionMessage = () => {
        if(PromoCode && Description){
            if(PromoCode.includes('SAC')){
                const text = Description.split("+")
                return text[1]
            }
        }
    }

    return (
        <div className={(promotionId === PromotionId) ? "promotionCardActive" 
                                     : editContract ? "promotionCard" 
                                     : "promotionCardNoEditable"}
            onClick={disabled ? null : selectPromotion}
            >
            <h2 className="promotionName">
                {Name || PromotionName}
            </h2>

            <div className="promotionItem">
                <span className="promotionValue">{descriptionMessage()}</span>
            </div>

            <div className="promotionItem">
                <span className="promotionTitleValue">Estimated Monthly Payment: </span>
                <span className="promotionValue">{EstimatedMonthlyPayment}</span>
            </div>


        </div>
    )
}

export default AquaPromotionCard