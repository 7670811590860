import React from 'react'
import ProposalCard from './ProposalCard'

const ProposalList = ({proposalList, applicantId, history, createCommercialProposal}) => {

   
   
    return (
        <>  
            {
                proposalList.length > 0 && proposalList.map((proposal, index)=>
                        <ProposalCard history={history} proposal={proposal} key={index} applicantId={applicantId} createCommercialProposal={createCommercialProposal}/>
                 )
            }
        </>
    )
}

export default ProposalList;
