import React, {useState, useContext} from 'react'
import { AuthContext } from '../../AuthContext';
import Spinner from 'react-spinner-material';

const AquaResendContractButton = ({applicationId, contract}) => {

    const { uri} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState(false)

    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');


    const resendContracts = () => {
        setIsLoading(true)
        setStatus(false)
      

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${applicationId}/resend-documents?documentType=${contract}`
        console.log(URI)

        fetch(URI,{
            method: 'POST',
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY,  
              }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(data => {
            setIsLoading(false)
           
            console.log(data);  

            if(data.status === 'ERROR'){
                setStatus('ERROR')
            }else{
                setStatus('SUCCESS') 
            }
        })
        .catch((error, response) => {
            console.log(error)
            setIsLoading(false)   
        });

    }


    return (
        <>  
            <div className="resendContractButtonContainer">
                <button className="contract_header-editButton" onClick={resendContracts} disabled={isLoading}>
                    Resend Contract  
                    {
                            isLoading &&
                            <Spinner
                            radius={15}
                            color={"white"}
                            stroke={2}
                            visible={true}
                        />
                    }   
                  
                </button>
                {
                    status === 'ERROR' ?
                    <p className="text_Red">Error</p>
                    : 
                    status === 'SUCCESS' ?
                    <p className="text_green">Success</p>
                    :
                    null
                }  

            </div>        
            
        
          

          

          


        </>
    )
}

export default AquaResendContractButton
