import React from 'react'
import iconAddGreen from '../../assets/images/iconAddGreen.png'
import {Link} from 'react-router-dom';


 const ApplicantCard = ({applicant, event, applicantId, isRealApplication}) => {


    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    
    //PERMISO DE CREAR APLIACION O NO
    const cantCreateApplication = window.localStorage.getItem("can_create_applications");
    const dealerCanCreateApplication = window.localStorage.getItem("dealer_can_create_applications");
    
    return(
        <>
            <div className={applicant.ApplicantId === applicantId ? "applicant_applicantCard-active" : "applicant_applicantCard"} >
                <div className="applicant_applicantCard-header">
                    <p className="applicant_applicantCard-name" onClick={event}>{applicant.FullName}</p>
                    
                    {//isDeallerAllyAdmin != 'true'  && (dealerCanCreateApplication === 'true' && waitingForActivation != 'true') ?
                    isDeallerAllyAdmin != 'true' && dealerCanCreateApplication === 'true' && isRealApplication ?
                        cantCreateApplication === 'true' ? 
                            <Link to={`createapplication/${applicant.ApplicantId}`}><img src={iconAddGreen} className="aplications-header-buttoms_new_icon" /></Link>                      
                    : null : null} 



                </div>
                <div className="applicant_applicantCard-body" onClick={event}>
                    <p className="applicant_applicantCard-label">Email:</p>
                    <p className="applicant_applicantCard-value">{applicant.EmailAddress}</p>
                    <p className="applicant_applicantCard-label">Phone:</p>
                    <p className="applicant_applicantCard-value">{applicant.CellPhone}</p>
                    <p className="applicant_applicantCard-label">Address:</p>
                    <p className="applicant_applicantCard-value">{applicant.FullAddress}</p>
                    
                </div>
                

            </div>
        </>
    )



}

export default ApplicantCard
