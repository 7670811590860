import React, {useState, useContext} from 'react'
import '../../assets/styles/AquaFinanceContract.css'
import {AuthContext} from '../../AuthContext';
import IconCheckGreen from '../../assets/images/iconCheck.png'
import IconCheckGray from '../../assets/images/iconCheckGray.png'
import IconCheckOrange from '../../assets/images/iconCheckOrange.png'
import IconArrow from '../../assets/images/iconArrowRightBlack.png'
import AquaFinanceContractSigningContractForm from './AquaFinanceContractSigningContractForm'
import AquaFinanceContractACH from './AquaFinanceContractACH'
import AquaFinanceContractLLC from './AquaFinanceContractLCC'
import iconRefresh from '../../assets/images/iconRefresh.png';
import Spinner from 'react-spinner-material';






const AquaFinanceContract = ({applicationData, SetReloadGetApplicationDetail, setReDoSigningContract, reDoSigningContract, openPromotionFirstAccordion}) => {

   


    const{uri} = useContext(AuthContext);

    const [accordionOpen, setAccordionOpen] = useState(openPromotionFirstAccordion ? 1 : 0);
    const [loadingStatuses, setLoadingStatuses] = useState(false)
    const [contractStatus, setContractStatus] = useState({})

    const [loadingResendContracts, setLoadingResendContracts] = useState(false)
    const [contractsResendedSuccess, setContractsResendedSuccess] = useState(false)


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');   



    const changeAccordionOpen = (n) => {
        if(accordionOpen === n){
            setAccordionOpen(0)
        }else{
            setAccordionOpen(n)
        }
    }


    const refreshContractStatuses = () => {
        setLoadingStatuses(true)
        setContractsResendedSuccess(false)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${applicationData.ApplicationId}/paperwork-signed-status-refresh`


        fetch(URI,{
            method: 'GET',
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY,  
              }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(data => {
            setLoadingStatuses(false)
           
            //console.log(data);

            setContractStatus(
                {
                    ...contractStatus,
                    SigningContractStatus: data.SigningContractStatus,
                    ACHStatus: data.ACHStatus,
                    LCCStatus: data.LCCStatus,
                }
            )

            return data
       

        })
        .catch((error, response) => {
            console.log(error)
            setLoadingStatuses(false)   
        });

    }






    



    return (
        <div className="aquaFinanceContract_mainContainer">

            <div className="aquaFinanceContract_mainContainer-topButtons">
                <button className="aquaFinanceContract_refreshStatusesButton" onClick={refreshContractStatuses} disabled={loadingStatuses}>   
                    <span>Refresh contract statuses</span>
                    {
                        loadingStatuses ? 
                        <Spinner
                        radius={15}
                        color={"grey"}
                        stroke={2}
                        visible={true}
                        />
                        :
                        <img src={iconRefresh} className="aquaFinanceContract_refreshStatusesButton-icon" />
                    }
                    

                    
                </button>

                {/* {
                    (applicationData.SigningContractStatus || applicationData.ACHStatus || applicationData.LCCStatus) &&
                    <button className="aquaFinanceContract_refreshStatusesButton" onClick={resendAllCurrentContracts} disabled={loadingResendContracts}>   
                        <span>Resend All Current Contracts</span>
                        {
                            loadingResendContracts ? 
                            <Spinner
                            radius={15}
                            color={"grey"}
                            stroke={2}
                            visible={true}
                            />
                            :
                            <img src={iconRefresh} className="aquaFinanceContract_refreshStatusesButton-icon" />
                        }             
                    </button>
                } */}
            </div>
            {contractsResendedSuccess &&  <p className="successMessage">All Current Contracts were sended successfully</p>}
           

            
          

            <div className={accordionOpen === 1 ? "aquaFinanceContract_accordionActive" : "aquaFinanceContract_accordion"} onClick={()=>changeAccordionOpen(1)}>
                <p className="aquaFinanceContract_accordionTitle">Customer Contract</p>

                <div className="aquaFinanceContract_accordionIconsContainer">
                    {
                        (applicationData.SigningContractStatus === 'Signed' || contractStatus.SigningContractStatus === 'Signed') ? 
                        <img src={IconCheckGreen} className="aquaFinanceContract_accordionCheckGreen"/> 
                        :
                        (applicationData.SigningContractStatus === 'Pending' || contractStatus.SigningContractStatus === 'Pending') ? 
                        <img src={IconCheckOrange} className="aquaFinanceContract_accordionCheckGray"/> 
                        :
                        <img src={IconCheckGray} className="aquaFinanceContract_accordionCheckGray"/>   
                    }
               
                <img src={IconArrow} className="aquaFinanceContract_accordionArrow"/>
                </div>
            </div>

            {
               accordionOpen === 1 && 
               <div className="aquaFinanceContract_accordionContainer aquaFinanceContract_accordionContainer1">
                   <AquaFinanceContractSigningContractForm 
                        type="create" 
                        applicationData={applicationData} 
                        SetReloadGetApplicationDetail={SetReloadGetApplicationDetail} 
                        setReDoSigningContract={setReDoSigningContract} 
                        reDoSigningContract={reDoSigningContract}
                        reloadGetSignContractStatuses={contractStatus.SigningContractStatus === 'Signed' && true}
                        hasProposal={applicationData.CustomerProposalId}
                        applicantId={applicationData.ApplicantId}
                    />
                </div>
            }
            
            {/* ACH */}
            {/* <div className={accordionOpen === 2 ? "aquaFinanceContract_accordionActive" 
                            : "aquaFinanceContract_accordion"} */}
            <div className={accordionOpen === 2 ? "aquaFinanceContract_accordionActive" 
                            : !applicationData.SigningContractStatus ? "aquaFinanceContract_accordionDisabled" 
                            : "aquaFinanceContract_accordion"}  
            
            
            onClick={()=> applicationData.SigningContractStatus && changeAccordionOpen(2)}>
            {/* onClick={()=> changeAccordionOpen(2)}> */}
                <p className="aquaFinanceContract_accordionTitle">ACH Bank Information</p>

                <div className="aquaFinanceContract_accordionIconsContainer">
                    {
                         (applicationData.ACHStatus === 'Signed' || contractStatus.ACHStatus === 'Signed') ? 
                         <img src={IconCheckGreen} className="aquaFinanceContract_accordionCheckGreen"/> 
                         :
                        (applicationData.ACHStatus === 'Pending' || contractStatus.ACHStatus === 'Pending') ? 
                        <img src={IconCheckOrange} className="aquaFinanceContract_accordionCheckGray"/>
                        : 
                        <img src={IconCheckGray} className="aquaFinanceContract_accordionCheckGray"/>   
                    }

                <img src={IconArrow} className="aquaFinanceContract_accordionArrow"/>
                </div>
            </div>

            {
               accordionOpen === 2 && 
               <div className="aquaFinanceContract_accordionContainer aquaFinanceContract_accordionContainer2">
                   <AquaFinanceContractACH 
                        type="create" 
                        applicationData={applicationData} 
                        SetReloadGetApplicationDetail={SetReloadGetApplicationDetail}
                        reloadGetSignContractStatuses={contractStatus.ACHStatus === 'Signed' && true}
                    />
                </div>
            }

            {/* LCC */}
            {/* <div className={accordionOpen === 3 ? "aquaFinanceContract_accordionActive" 
                            : !applicationData.ACHStatus ? "aquaFinanceContract_accordionDisabled" 
                            : "aquaFinanceContract_accordion"}   */}
            <div className={accordionOpen === 3 ? "aquaFinanceContract_accordionActive" 
                            : "aquaFinanceContract_accordion"}
            // onClick={()=> applicationData.ACHStatus && changeAccordionOpen(3)}>
            onClick={()=> changeAccordionOpen(3)}>

                <p className="aquaFinanceContract_accordionTitle">Completion Certificate</p>

                <div className="aquaFinanceContract_accordionIconsContainer">
         
                    {
                        (applicationData.LCCStatus === 'Signed' || contractStatus.LCCStatus === 'Signed') ? 
                        <img src={IconCheckGreen} className="aquaFinanceContract_accordionCheckGreen"/> 
                        :
                        (applicationData.LCCStatus === 'Pending' || contractStatus.LCCStatus === 'Pending') ? 
                        <img src={IconCheckOrange} className="aquaFinanceContract_accordionCheckGray"/>
                        :  
                        <img src={IconCheckGray} className="aquaFinanceContract_accordionCheckGray"/>   
                    }
                <img src={IconArrow} className="aquaFinanceContract_accordionArrow"/>
                </div>
            </div>

            {
                accordionOpen === 3 && 
                <div className="aquaFinanceContract_accordionContainer aquaFinanceContract_accordionContainer3">
                <AquaFinanceContractLLC 
                    type="create" 
                    applicationData={applicationData} 
                    SetReloadGetApplicationDetail={SetReloadGetApplicationDetail}
                    reloadGetSignContractStatuses={contractStatus.LCCStatus === 'Signed' && true}
                />
                </div>
            }

            {
                applicationData.DealerNumber && 
                <div className="margin-top-20">
                    <p>{`Hint: DocuSign dealer access code: ${applicationData.DealerNumber}XX where XX is the customer’s first and last initials.`}</p>
                </div>
            }


            
           
        </div>
    )
}

export default AquaFinanceContract;
