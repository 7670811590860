import React, {useState, useContext, useEffect} from 'react'
import "../../assets/styles/ApplicationForm.css";
import { AuthContext } from '../../AuthContext';
import AlertBox from "../AlertBox";
import Button from '../general/Button';

const AdditionalNotesForm = ({ type, commercialLoanApplicationId, setStep, step, setCommercialLoanData, prefilledInfo }) => {

  const { uri, changeToken } = useContext(AuthContext);
  const [errors, setErrors] = useState(false)
  const [applicant, setApplicant] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [notificationError, setNotificationError] = useState({show: false})
  

  const user = window.localStorage.getItem('user');
  const token = window.localStorage.getItem('token');

  useEffect(()=>{
    if(prefilledInfo){
      setApplicant(prefilledInfo.AdditionalNotes)
    }
  },[prefilledInfo])


   //POST APPLICATION
   const submitForm = async (event) => {
    event.preventDefault()

    if(!applicant.length > 0){
      setErrors({AdditionalNotes: 'Please enter a response'})
      return
    }

    setIsLoading(true);



    //Armar el objeto que se envia
    const formData = {Submit: false, CommercialLoanApplicationId: commercialLoanApplicationId};
    formData.AdditionalNotes = {AdditionalNotes: applicant};


    // console.log(formData)
    // console.log(JSON.stringify(formData))

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application`;

  
     const headers = {
                    'Access-Control-Request-Headers': 'application/json',
                    'Content-Type': 'application/json',
                    'username': user,
                    'x-api-key': uri.URI_X_API_KEY,
                    'Authorization' : token, 
                }
    
    try {
        const rawResponse = await window.fetch(URI, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(formData),
        });
        setIsLoading(false);
        const resp = await rawResponse.json();
        // console.log(resp);

        if(resp.CommercialLoanApplicationId){
          setStep(state => ({...state, AdditionalNotes: true}))
          setCommercialLoanData(state => ({...state, AdditionalNotes:  formData.AdditionalNotes }))
        }else{
          setNotificationError({ 
            title: resp.Message,
            text: resp.Errors[0],
            type: 'error',
            show: true
          });
        }

    }
    catch (error) {
      setIsLoading(false)
      setNotificationError({ 
        title: 'Error',
        text: 'An unexpected error has occurred. Please try again.',
        type: 'error',
        show: true
      });
      if(error.status === 401){
        changeToken()
      }
    }

};


  return (
      <form action="" id="form" className="form">
        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Additional Notes</label>
          <textarea
            type="text"
            name="AdditionalNotes"
            onChange={(e)=> setApplicant(e.target.value)}
            className={`${errors.AdditionalNotes ? "imputError" : "campo"}`}
            value={applicant}
            disabled={(type === 'edit') ? "disabled" : null}
            required
            style={{height: '100px'}}
          />
          {errors.AdditionalNotes && <p className="errorMessage">{errors.AdditionalNotes}</p>}
        </div>

        <div className='mediowidth-right' style={{justifySelf: 'end'}}>
          <Button
           onClick={submitForm}
           value={(commercialLoanApplicationId && step.AdditionalNotes) ? "Save" : "Save and continue"}
           isLoading={isLoading}
           showButton={type === 'create'}
          />
        </div>

        <div className="fullwidth campoContainer">
          <AlertBox 
              showAlert={notificationError.show}
              type={notificationError.type}
              title={notificationError.title}
              text={notificationError.text}
          />
        </div>
      </form>
  )
}

export default AdditionalNotesForm