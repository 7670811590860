import React, {useState, useContext, useEffect} from 'react'
import {AuthContext} from '../../AuthContext';
import '../../assets/styles/ApplicationForm.css'
import '../../assets/styles/AssignUserToApplicationByIframe.css'
import Spinner from 'react-spinner-material';

const AssignUserToApplicationByIframe = ({dealerId, applicationId, setReloadGetApplications, reloadPage}) => {

        const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

        const [userList, setUserList] = useState([])
        const [userName, setUserName] = useState('')
        const [isLoadingAssignUser, setIsLoadingAssignUser] = useState(false)
     
        const token = window.localStorage.getItem('token');
        const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');

      

         // GET USERS
         useEffect(()=>{

            setIsLoadingAssignUser(true)

            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}get-users-without-basics`
            //console.log(URI)
    
            const abortController = new AbortController();
            const signal = abortController.signal;
    
    
            fetch(URI,{
                method: 'GET',
                signal: signal,
                headers: {
                    'Access-Control-Request-Headers': 'application/json',           
                    'Content-Type': 'application/json', 
                    'DealerId' : dealerId,
                    'Authorization' : token,   
                    'x-api-key' : uri.URI_X_API_KEY,  
                    }
            })
            .then(response => {

                if (response.ok) {
                    return response.json();
                  }
                return Promise.reject(response);
            })
            .then(datos => {
                setIsLoadingAssignUser(false)
    
                // console.log(datos);

                if(datos.Users){
                    setUserList(datos.Users)
                }
                
                setErrorFlagFromContext(false)
    
            })
            .catch((error, response) => {
                console.log(error)
                setIsLoadingAssignUser(false)
                if(errorFlagFromContext === false && error.status === 401){
                    changeToken().then(response => console.log(response)).catch(error => console.log(error))
                }    
            });
            
    
            return function cleanup(){
                abortController.abort();
            }
            
        },[dealerId, applicationId, reloadFetchFromContext]);




        //POST ASSIGN
        const assignUser = () => {

            if(userName === '' || userName === false){
                return
            }
            setIsLoadingAssignUser(true)

            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}application/assign-user`

            const data = {
                ProspectLoanApplicationId : applicationId,
                Username : userName,
            }

            console.log(data)
    

            fetch(URI,{
                method: 'POST',
                headers: {
                    'Access-Control-Request-Headers': 'application/json',           
                    'Content-Type': 'application/json', 
                    'Authorization' : token,   
                    'x-api-key' : uri.URI_X_API_KEY,  
                    },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(datos => {

                setIsLoadingAssignUser(false)
                console.log(datos);

                if(datos.status === 'SUCCESS'){
                    if(reloadPage){
                        window.location.reload()
                    }else{
                        setReloadGetApplications(reloadGetApplications => !reloadGetApplications)
                    }
                    
                }
                
    
    
            })
            .catch(error => {
                console.log(error)        
            });
            



        }




    return (
        <div className="assignUserCheck_container">

            <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm" >Assign a user</label>
                <select
                    onChange={(e)=> setUserName(e.target.value)}
                    className= "campo"
                    placeholder="File Type"
                    required
                >
                    <option value="disable" disabled selected>
                    Assign a user
                    </option>
                    {
                        userList.map((user, index) => {
                            return( <option value={user.username} key={index}>{`${user.first_name} ${user.last_name} - (${user.role})`}</option> )
                        })
                    }
                    

                </select>
               
            </div>

            {
                isLoadingAssignUser ?
                <button className="assignUserButton" >
                    <Spinner
                    radius={20}
                    color={"white"}
                    stroke={3}
                    visible={true}
                    />
                </button>
                :
                <button className="assignUserButton" onClick={assignUser}>Save</button>
            }        
            

            

        </div>
    )
}

export default AssignUserToApplicationByIframe;
