import React from 'react';
import {Link} from 'react-router-dom';
import { AuthConsumer } from '../AuthContext';
import '../assets/styles/Header.css'
import logo from '../assets/images/logo.png'


const Header = ()=>(
    <>
    <header>
    <AuthConsumer>
      {({ isAuth, activateAuth, logout }) => (

        
        <header className="header-nav">
            <Link to="/" className="header-link-logo">
                <img src={logo} className="logo" alt="DealerAlly Logo"/>
            </Link>
          

          
        </header>
      )}
    </AuthConsumer>
  </header>

    
    
    
    </>
);

export default Header;