import React from 'react'
import '../../assets/styles/Button.css'
import classnames from 'classnames'
import Spinner from 'react-spinner-material';
import { Link } from 'react-router-dom';

const Button = ({ children, 
                  size = 'medium', //small, medium, big
                  type = 'primary', // primary, secondary
                  isLoading = false, 
                  link = false,
                  onClick,
                  value,
                  showButton = true,
                  variant = 'normal'
                }) => {


    const Content = () => {
        return (
            <button className={classnames('button', {
                'b-small': size === 'small',
                'b-medium': size === 'medium',
                'b-big': size === 'big',
                'b-primery': type === 'primary',
                'b-secondary': type === 'secondary',
                'b-isLoading': isLoading,
                'rounded' : variant === 'rounded'
            })}
            disabled={isLoading ? true : false}
            onClick={onClick}
            >
                {children || value}
                
                {isLoading && 
                    <Spinner
                        radius={20}
                        color={type === 'secondary' ? "grey" : "white"}
                        stroke={3}
                        visible={true}
                    />
                }
            </button>
        )
    }


    return (
        (
            showButton &&
            (link ?
                <Link to={link}>
                    <Content />
                </Link>
                :
                <Content />
            )
        )
        
    )
}

export default Button