import React, {useState, useEffect, useContext} from 'react'
import '../../assets/styles/AquaLoanHistory.css'
import {AuthContext} from '../../AuthContext';
import Spinner from 'react-spinner-material';

const AquaLoanHistory = ({applicationId}) => {

    const{uri} = useContext(AuthContext);

    const [history, setHistory] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');


    // GET HISTORY 
    useEffect(() => {

        if (applicationId) {

            setIsLoading(true);
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${applicationId}/afi-history`;


            const abortController = new AbortController();
            const signal = abortController.signal;


            window.fetch(URI, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Access-Control-Request-Headers': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'username' : user,
                    'x-api-key': uri.URI_X_API_KEY,
                }
            })
                .then(response => response.json())
                .then(data => {
                    setIsLoading(false);
                   
                    //console.log(data)
                    if(data.HistoryItems){
                        setHistory(data.HistoryItems)
                    }
                   

                  

                })

            return function cleanup() {
                abortController.abort();
            }
        }
    }, [applicationId]);



    return (
        <>
            <h4 className="applicationFormContainer-title" style={{marginBottom: '5px'}}>History (Date and Time are UTC)</h4>

            <div>
                {
                    isLoading ?

                    <div className="updatingDataMessage noSpinner-margin">
                        <Spinner
                            radius={18}
                            color={"grey"}
                            stroke={3}
                            visible={true}
                        />
                        <p>Updating history</p>
                    </div>

                    :

                    history && history.length > 0 ? history.map(message => 
                        <div className="aquaLoanHistory_card">
                            <p className="aquaLoanHistory_card-message">{message.Message}</p>
                            <p className="aquaLoanHistory_card-date">{message.Date}</p>
                        </div>
                    )

                    :

                    null
                    
                }
                
                
            </div>
        </>
    )
}

export default AquaLoanHistory
