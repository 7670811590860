import React, {useState, useContext, useEffect} from 'react'
import '../assets/styles/ExternalCreateApplication.css'
import {AuthContext} from '../AuthContext';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ExternalForm from '../components/ExternalForm'
import Spinner from 'react-spinner-material';

const ExternalCreateApplication = () => {

    const{isAuth, uri} = useContext(AuthContext);

    const formId = useParams().id;

    const [formData, setFormData] = useState({})

    

    //GET DETAILS FROM APPLICATION ID
    useEffect(() => {


        const abortController = new AbortController();
        const signal = abortController.signal;

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_CUSTOMER_FORM}${formId}`;

        window.fetch(URI, {
            method: 'GET',
            signal: signal,
            headers: {
                'x-api-key': uri.URI_X_API_KEY,
            },
        })
            .then(response => response.json())
            .then(datos => {
                //console.log(datos)

                setFormData(datos)

            })
            .catch(error => {
                console.log(error)
            })

        return function cleanup() {
            abortController.abort();
        }
    }, []);






    return (
        <div className={isAuth ? "externalFormPage_mainContainer externalFormPage_marginLeft" : "externalFormPage_mainContainer"}>
            {isAuth ? null : <Header />}
            <div className="externalFormPage_formContainer">
                {
                    formData.LoanApplicationStatus === 'Draft' ?
                        <ExternalForm applicationId={formData.ProspectLoanApplicationId} type="create" fillOutApp/>

                    :

                    formData.LoanApplicationStatus === 'FilledOut' || formData.LoanApplicationStatus === 'Submitted' ?
                        <div>
                            <p className="externalFormPage_thanksTitle">Thank you!</p>
                            <p className="externalFormPage_text">The form has been submitted successfully</p>
                        </div>

                    :

                    <Spinner
                        radius={35}
                        color={"grey"}
                        stroke={5}
                        visible={true}
                    />
                }
               
            </div>   
            {isAuth ? null : <Footer />}
        </div>
    )
}

export default ExternalCreateApplication
