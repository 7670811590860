import React from 'react'

import "../assets/styles/ApplicationForm.css";
import ProposalForm from '../components/proposals/ProposalForm';


const CreateProposal = ({history}) => {

 
    return (
        <div className="createApplication" >
            <div className="createApplication-header">
                <div className="createApplication-header-tittle">
                    <h2>New Proposal</h2>
                </div>
            </div>

            <ProposalForm history={history} type="create" createProposal isDragDisabled={true} showCreatePdfButton/>

        </div>
    )
}

export default CreateProposal
