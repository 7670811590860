import React, { useState, useEffect, useContext } from 'react'
import "../../assets/styles/ApplicationForm.css";
import "../../assets/styles/StepCounter.css";
import "../../assets/styles/ExternalForm.css";
import { AuthContext } from '../../AuthContext';
import Spinner from 'react-spinner-material';
import useIsSafari from '../../hooks/useIsSafari'
import AquaFinanceContractStatusTab from './AquaFinanceContractStatusTab';
import IconLinkOutSide from '../../assets/images/iconLinkOutSide.png'
import AquaResendContractButton from './AquaResendContractButton';

const AquaFinanceContractLLC = (props) => {
    //ESTADOS

    const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);

    const isSafari = useIsSafari()

    
     const [applicationDetails, setApplicationDetails] = useState({})




      //ESTADOS DE CONTRACT
      const [contract, setContract] = useState({})
      const [contractErrors, setContractErrors] = useState({})
      const [isSubmittingContract, setIsSubmittingContract] = useState(false);
      const [editContract, setEditContract] = useState(false);
      const [loadingPostContract, setLoadingPostContract] = useState(false);
      const [contractCompletionStatus, setContractCompletionStatus] = useState(false);
      const [refreshStatusContract, setRefreshStatusContract] = useState(false)

      const [useApplicantAddress, setUseApplicantAddress] = useState(false);
      const [contractType, setContractType] = useState('Email')
      
 



    const [showNotificationOk, SetShowNotificationOk] = useState(false);
    const [showNotificationError, SetShowNotificationError] = useState(false);
    const [showNotificationApplicantAlreadyExists, setShowNotificationApplicantAlreadyExists] = useState(false)


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');








            useEffect(()=>{
                // console.log(props.applicationData)
                if(Object.keys(props.applicationData).length > 0){
                    fillContractData(props.applicationData)

                    setUseApplicantAddress(!props.applicationData.LCCStatus ? true : false)
                  
                    if(Object.keys(props.applicationData.LCCAddress).length !== 0){
    
                      setContract({ Address : props.applicationData.LCCAddress.Address1, 
                                    City : props.applicationData.LCCAddress.City, 
                                    State : props.applicationData.LCCAddress.State, 
                                    PostalCode : props.applicationData.LCCAddress.PostalCode, 
                                    ApplicantEmail: props.applicationData.Applicant.EmailAddress || '',
                                    ContractLanguage: props.applicationData.LoanParameters.ContractLanguage || '',
                                    })
                    }else{
                      setContract({ Address : props.applicationData.Applicant.Address1, 
                                    City : props.applicationData.Applicant.City, 
                                    State : props.applicationData.Applicant.State, 
                                    PostalCode : props.applicationData.Applicant.PostalCode, 
                                    ApplicantEmail: props.applicationData.Applicant.EmailAddress || '',
                                    ContractLanguage: props.applicationData.LoanParameters.ContractLanguage || '',
                                    })
    
                    }
                }
                
               
               
                
            }, [props.applicationData])





          //RELLENAR FORMULARIO DE CONTRACT
          const fillContractData =(data)=>{
            console.log(data)

            setApplicationDetails(data)
            
    
            // if(!data.LCCStatus && (data.LoanApplicationStatus === 'Approved' ||  data.LoanApplicationStatus === 'Received' ||  data.LoanApplicationStatus === 'PendingDocs' ||  data.LoanApplicationStatus === 'ConditionallyApproved' ||  data.LoanApplicationStatus === 'Problem' || data.LoanApplicationStatus === 'Pending')){
            if (!data.LCCStatus && data.LoanApplicationStatus !== 'FundingComplete' && data.LoanApplicationStatus !== 'FundingComplete' ) { 
              setEditContract(true)
            }else if((data.LCCStatus === 'Pending' || data.LCCStatus === 'Signed') && data.LoanApplicationStatus !== 'FundingComplete' && data.LoanApplicationStatus !== 'FundingComplete' ){
              setEditContract(false)
              setContractCompletionStatus(true)
            }

          } //end
    
    
    


    
          //SUBMIT CONTRACT
          const postContract = (event) => {
    
           setLoadingPostContract(true);
        
    
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}submit-lcc/${applicationDetails.ApplicationId}`
            // console.log(URI)  

            let obj = { SignatureType: contractType,}


            if(!useApplicantAddress){
              obj={
                Address: contract.Address,
                City: contract.City,
                State: contract.State,
                PostalCode: contract.PostalCode,
                Email: contract.ApplicantEmail,
                SignatureType: contractType,
                ContractLanguage: contract.ContractLanguage
              }
            }else{
              obj.Email = contract.ApplicantEmail  
              obj.ContractLanguage = contract.ContractLanguage     
            }
           // console.log(obj)

            //console.log(JSON.stringify(obj))
            

            
            
         
      
            window.fetch(URI, {
            method: 'POST',
            headers: {
              //'Access-Control-Request-Headers': 'application/json',           
              'Content-Type': 'application/json', 
              'username' : user,
              'Authorization' : token,
              'x-api-key' : uri.URI_X_API_KEY,
            },
            body: JSON.stringify(obj),
            
          
            }).then(response => response.json())
            .then(datos => {
              setLoadingPostContract(false)
              //console.log('OBJETO QUE RESPONDE EL SERVIDOR')
              // console.log(datos);

              if(datos.status === 'AQUAFINANCE_API_ERROR'){
                SetShowNotificationError(true)
              }else if (datos.status === 'SUCCESS'){
                SetShowNotificationError(false)
                const container = document.getElementById('shetContainer')
                // container.scrollTo(0, 0)
                setRefreshStatusContract(!refreshStatusContract)
                props.SetReloadGetApplicationDetail(reloadGetApplicationDetail => !reloadGetApplicationDetail)
              }else{
                SetShowNotificationError(true)
              }
              
            
            }).catch((error)=>{
              console.log(error)
              setLoadingPostContract(false)
              SetShowNotificationError(true)
            })          
              
          }; //end






          //HANDLE INPUT CONTRACT
          const handleInputContract = event => {
            let value = event.target.value;

            setContract({
                ...contract,
                [event.target.name]: value,
            });

        }; //end






          //HANDLE SUBMIT CONSTRACT
          const handleSubmitContract = event => {
            event.preventDefault();
            //handle errors

            // if(useApplicantAddress){
            //   postContract()
            // }else{
            //   setContractErrors(validateFormContract(contract))
            //   setIsSubmittingContract(true);
            // }

            setContractErrors(validateFormContract(contract))
              setIsSubmittingContract(true);
            
           
        
              
        }; //end







        useEffect(() => {
          if (Object.keys(contractErrors).length === 0 && isSubmittingContract) {
              postContract()
  
          }
      }, [contractErrors, isSubmittingContract]);







          //validate contract
        const validateFormContract = (applicant) => {
          let errorList = {};

          if(!useApplicantAddress){

            //Address1 - text
            if (!applicant.Address) {
              errorList.Address = "Please enter a address";
            }
        
            //City - text
            if (!applicant.City) {
              errorList.City = "Please enter a city";
            } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s\.]+$/.test(applicant.City)) {
              errorList.City = "The city can only contain letters";
            }
        
            //State - select
            if (!applicant.State) {
              errorList.State = "Please select an option";
            } else if (applicant.State === "State") {
              errorList.State = "Please select an option";
            }
        
            //PostalCode - number
            if (!applicant.PostalCode) {
              errorList.PostalCode = "Please enter a postalCode";
            } else if (!/^[0-9]{5}$/.test(applicant.PostalCode)) {
              errorList.PostalCode = "The postal code must have 5 numbers";
            } 

          }

          //ContractLanguage
          if (!applicant.ContractLanguage) {
            errorList.ContractLanguage = "Please select an option";
          }

               
          //EmailAddress *
          if (!applicant.ApplicantEmail) {
              errorList.ApplicantEmail = "Please enter a email";
          } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.ApplicantEmail)) {
              errorList.ApplicantEmail = "Please enter a valid email";
          }

          return errorList;
        };





    
      const handleUseApplicantAddress = () => {
        setUseApplicantAddress(!useApplicantAddress)

        if(useApplicantAddress){

          if(Object.keys(props.applicationData.LCCAddress).length !== 0){
              setContract({ Address : props.applicationData.LCCAddress.Address1, 
                            City : props.applicationData.LCCAddress.City, 
                            State : props.applicationData.LCCAddress.State, 
                            PostalCode : props.applicationData.LCCAddress.PostalCode, 
                            ApplicantEmail: props.applicationData.Applicant.EmailAddress,
                            ContractLanguage: props.applicationData.LoanParameters.ContractLanguage || '',
                            })
          }else{
            setContract({ Address : '', 
                          City : '', 
                          State : '', 
                          PostalCode : '', 
                          ApplicantEmail: props.applicationData.Applicant.EmailAddress,
                          ContractLanguage: props.applicationData.LoanParameters.ContractLanguage || '',
                          })

          }
          
        }else{
          setContract({ Address : props.applicationData.Applicant.Address1, 
                        City : props.applicationData.Applicant.City, 
                        State : props.applicationData.Applicant.State, 
                        PostalCode : props.applicationData.Applicant.PostalCode,
                        ApplicantEmail: props.applicationData.Applicant.EmailAddress, 
                        ContractLanguage: props.applicationData.LoanParameters.ContractLanguage || '',
                        })
        }

      }
      
    
         
    
    
    
    
    
    
    
       
    
    
    
    
          
 







    return(
                <>

            <div className="documentSectionContentMain">

                {
                    contractCompletionStatus  &&
                    <div className="contract-headerContainer">
                       <h3 className="applicationFormContainer-tittle_green">Contract generated</h3>
                       <div className="contract-headerContainer_buttonsContainer">
                            <button className="contract_header-editButton" onClick={()=>{setEditContract(!editContract)}}>Redo Completion Certificate</button>
                            <AquaResendContractButton applicationId={applicationDetails.ApplicationId} contract='LCC'/>
                       </div>
                       
                    </div>
                              
                }

                {editContract && contractCompletionStatus && <p className="pinnacleContract_resendContractMessage">Contract may now be redone.</p>}

                {
                  editContract &&
                  <div className="fullwidth campoContainer">
                    <div className="createApplicationPinnacle_switchButton">
                        <p className="createApplicationPinnacle_switchText margin-right_10">Sign via email  </p>
                        <label className="switch">
                        <input type="checkbox" id="checkStubApplication"  {...contractType === 'Electronic' && { checked: "checked" } } name="isActive" id="isActive" onClick={()=> setContractType(contractType === 'Email' ? 'Electronic' : 'Email')} />      
                        <span className="slider round"></span>
                        </label>
                        
                        <p className="createApplicationPinnacle_switchText">Sign in-person</p>
                    </div>
                  </div>
                }



                <h3 className="applicationFormContainer-tittle">Contract Language</h3>

                <div action="" id="form" className="form">
                    <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Contract Language</label>

                        <select
                        value={contract.ContractLanguage}
                        name="ContractLanguage"
                        onChange={handleInputContract}
                        className={`${contractErrors.ContractLanguage ? "imputError" : "campo"}`}
                        placeholder="ContractLanguage"
                        required
                        disabled={editContract ? null : "disabled"}
                        >
                        <option disabled selected>Contract Language</option>
                        <option value="English">English</option> 
                        <option value="Spanish">Spanish</option> 
                        </select>
                        {contractErrors.ContractLanguage && <p className="errorMessage">{contractErrors.ContractLanguage}</p>}
                    </div>

                </div>

                <h3 className="applicationFormContainer-tittle">Installation Address</h3>
                <div className="form">
                

                  {
                    editContract &&
                      <div className="fullwidth campoContainer checkCopyAddressInfo">
                      <input type="checkbox" 
                            name="isActive" 
                            className="inputCopyAddressInfo" 
                            {...useApplicantAddress ? { checked: "checked" } : null}
                            onChange={handleUseApplicantAddress}
                            /> <label className="labelApplicationForm" > Use applicant's address</label>
                      </div>
                  }
                  

                   {/* ApplicantEmail */}
                   <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Email</label>
                        <input
                            type="email"
                            name="ApplicantEmail"
                            onChange={handleInputContract}
                            className={`${contractErrors.ApplicantEmail ? "imputError" : "campo"}`}
                            placeholder="Email"
                            value={contract.ApplicantEmail}
                            required
                            disabled={editContract ? null : "disabled"}
                        />
                        {contractErrors.ApplicantEmail && <p className="errorMessage">{contractErrors.ApplicantEmail}</p>}
                    </div>



                    {/* ApplicantAddress */}
                   <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Address</label>
                        <input
                            type="text"
                            value={contract.Address}
                            name="Address"
                            onChange={handleInputContract}
                            className={`${contractErrors.Address ? "imputError" : "campo"}`}
                            placeholder="Address"
                            required
                            disabled={(editContract && !useApplicantAddress) ? null : "disabled"}
                        />
                        {contractErrors.Address && <p className="errorMessage">{contractErrors.Address}</p>}
                    </div>


                    {/* ApplicantCity */}
                    <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >City</label>
                        <input
                            type="text"
                            value={contract.City}
                            name="City"
                            onChange={handleInputContract}
                            className={`${contractErrors.City ? "imputError" : "campo"}`}
                            placeholder="City"
                            required
                            disabled={(editContract && !useApplicantAddress) ? null : "disabled"}
                        />
                        {contractErrors.City && <p className="errorMessage">{contractErrors.City}</p>}
                    </div>


                    {/* ApplicantState */}
                    <div className="mediowidth-right campoContainer">
                        <label className="labelApplicationForm" >State</label>
                        <select
                            value={contract.State}
                            name="State"
                            onChange={handleInputContract}
                            className={`${contractErrors.State ? "imputError" : "campo"}`}
                            placeholder="State"
                            required
                            disabled={(editContract && !useApplicantAddress) ? null : "disabled"}
                        >
                            <option value="1" disabled selected>
                                Applicant State
                            </option>
                            <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
                        </select>
                        {contractErrors.State && <p className="errorMessage">{contractErrors.State}</p>}
                    </div>


                    {/* ApplicantZipCode */}
                    <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >Postal Code</label>
                        <input
                            type="number"
                            value={contract.PostalCode}
                            name="PostalCode"
                            onChange={handleInputContract}
                            className={`${contractErrors.PostalCode ? "imputError" : "campo"}`}
                            placeholder="Zip Code"
                            required
                            disabled={(editContract && !useApplicantAddress) ? null : "disabled"}
                        />
                        {contractErrors.PostalCode && <p className="errorMessage">{contractErrors.PostalCode}</p>}
                    </div>

                    <div></div>



                  
                  

                  
                </div>
               

  
                {editContract &&
                <div action="" id="form" className="form">
                   <div className="mediowidth-left"></div>
                 
                    {
                      (
                        loadingPostContract ?
  
                          <div className="mediowidth-right submit campo middle">
                            <Spinner
                              radius={25}
                              color={"white"}
                              stroke={3}
                              visible={true}
                            />
                          </div>
  
                          :
                          <input
                            type="submit"
                            id="submit"
                            onClick={handleSubmitContract}
                            className="mediowidth-right submit campo"
                            placeholder="Save and Send"
                            value="Submit LCC"
                            required
                          />
  
                      )
                    }        
                </div>  
                  } 


                   {
                    showNotificationError &&
                    <div action="" id="form" className="form">
                       <div className="mediowidth-left"></div>
                      <div className="mediowidth-right">
                      <p className="text_Red">An unexpected error has occurred, please try again later</p>
                      </div>
                    </div>
                    }   


                    {
                      (applicationDetails.LCCESignUrl && !editContract) &&
                      <a href={applicationDetails.LCCESignUrl} target="_blank" className="contract_header-editButton-white" >Open to Sign Completion Certificate <img className="iconLinkOutSide" src={IconLinkOutSide}/></a>
                    }          
                



            </div>



            {
                (contractCompletionStatus || props.applicationData.LCCStatus === 'Pending' || props.applicationData.LCCStatus === 'Signed')  &&
                <AquaFinanceContractStatusTab applicationId={props.applicationData.ApplicationId} type="LCC" refreshStatusContract={refreshStatusContract} showReloadButton={props.applicationData.LoanApplicationStatus === 'FundingComplete' ? false : true} userName={props.applicationData.username}/>
            }



               
                
                                </>
                    )

           



  
}

export default AquaFinanceContractLLC;
