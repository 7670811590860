import React, { useState, useContext } from 'react';
import {useParams} from 'react-router-dom';
import '../assets/styles/ResetPassword.css'
import {AuthContext} from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Notification from '../components/Notification';
import iconSadFace from '../assets/images/iconSadFace.png';
import iconAdmiration from '../assets/images/iconAdmiration.png';
import iconCheck from '../assets/images/iconCheck.png';
import Spinner from 'react-spinner-material';
require('dotenv').config();



const ResetPassword = (props)=>{
    
    const source = useParams().source
    
    const{uri} = useContext(AuthContext);
    const{isAuth} = useContext(AuthContext);

    const[step, setStep] = useState(source === 'reset' ? 1 : 3)
    const[isLoading, setIsLoading] = useState(false)

    const [username, setUsername] = useState({})
    const [showErrorMessage, setErrorMessage] = useState(false);
    const [showErrorMessageUsername, setShowErrorMessageUsername] = useState(false);
    const [showErrorMessageCode, setShowErrorMessageCode] = useState(false)
    const [showErrorPassword, setShowErrorPassword] = useState(false)
    const [showErrorNotAuthorized, setShowErrorNotAuthorized] = useState(false)
    const [showErrorDiferentPasswords, setShowErrorDiferentPasswords] = useState(false)

    const [resetData, setResetData] = useState({})
    const [showResetSucces, setShowResetSucces] = useState(false)


    //POST USERNAME
    const submitUsername=(e)=>{
        e.preventDefault();

       
        
        setIsLoading(true)
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_FORGOT_PASSWORD}`;
        //console.log(URI)
        window.fetch(URI, { 
            method: 'POST',
            headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json',  
            'x-api-key' : uri.URI_X_API_KEY,       
            },
            body: JSON.stringify(username),
              
        })
        .then(response => response.json())
        .then(content => {
        setIsLoading(false)
         //console.log(content)
        
         if(content.status === 'SUCCESS'){
            setStep(2)
            setResetData({
                ...resetData,
                username : username.username,            
            })
         }else if(content.status === 'USER_NOT_FOUND'){
            setErrorMessage(true)
            setShowErrorNotAuthorized(false)
         }else if(content.status === 'NOT_AUTHORIZED'){
            setShowErrorNotAuthorized(true)
            setErrorMessage(false)
            
        }

        }).catch(error => {
            setIsLoading(false)
            console.log(error)
        })
    }



    //POST RESETPASSWORD
    const submitResetPassword=(e)=>{
        e.preventDefault();

        const data = resetData

        if(data.new_password !== data.new_password_two){
            setShowErrorDiferentPasswords(true)
        }else{
            setShowErrorDiferentPasswords(false)
            delete data.new_password_two
            //console.log(data)

            setIsLoading(true)
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_RESET_PASSWORD}`;
            //console.log(URI)
            window.fetch(URI, { 
                method: 'POST',
                headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json',  
                'x-api-key' : uri.URI_X_API_KEY,       
                },
                body: JSON.stringify(data),
                
            })
            .then(response => response.json())
            .then(content => {
            setIsLoading(false)
             //console.log(content)
            
            if(content.status === 'SUCCESS'){
                setShowResetSucces(true)
                
            }else if(content.status === 'USER_NOT_FOUND'){
                setShowErrorMessageUsername(true)
                setShowErrorMessageCode(false)
                setShowErrorPassword(false)
                setShowErrorNotAuthorized(false)
            }else if(content.status === 'CODE_MISMATCH'){
                setShowErrorMessageCode(true)
                setShowErrorMessageUsername(false)
                setShowErrorPassword(false)
                setShowErrorNotAuthorized(false)
            }else if(content.status === 'EXPIRED_CODE'){
                setStep(4)
            }else if(content.status === 'INCORRECT_PASSWORD'){
                setShowErrorPassword(true)
                setShowErrorMessageUsername(false)
                setShowErrorMessageCode(false)
                setShowErrorNotAuthorized(false)
            }

            }).catch(error => {
                setIsLoading(false)
                console.log(error)
            })
        }
        
       

        
        
        

    }




    
    const handleInputReset = event => {
        setResetData({
            ...resetData,
            [event.target.name]: event.target.value,            
        })
    }




    const loadingButtom = ()=>{
      return(
        <div className="resetpassword-loadingButtomReset">
            <Spinner
              radius={25}
              color={"white"}
              spinnerWidth={2}
              visible={true}
            />
        </div>
      )
    }



    const sections = ()=>{
        switch (step) {
                default:
                return (
                    <div>Default</div>
                    );

                    
                case 1:
                return (
                    <>
                        <div className="resetpassword_sectionContainer">
                            <h2 className="resetpassword_title">To reset your password please enter your Email and we will send a Confirmation code to your email</h2>

                            <form action="" className="resetpassword_form" onSubmit={submitUsername}>
                            
                            <input 
                                type="email"
                                className="resetpassword_input" 
                                placeholder="Email" 
                                onChange={(e)=>{setUsername({username : e.target.value})}} 
                                required 
                            />

                            {showErrorMessage ? <p className="resetpassword_texterror">Email not found, please try again</p> : null}
                            {showErrorNotAuthorized ? <p className="resetpassword_texterror">User password cannot be reset in the current state, please contact your administrator.</p> : null}

                            
                            {isLoading ? loadingButtom() : <button className="resetpassword_button">Send Email</button>}
                           
                            </form>
                        </div>
                    </>
                );

                case 2:
                return (
                    <>
                        <div className="resetpassword_sectionContainer">
                            <h2 className="resetpassword_title">Please check the confirmation code that we have sent to your email and complete the following form to reset your password.</h2>

                            <form action="" className="resetpassword_form" onSubmit={submitResetPassword}>
                            <input 
                            name="username"
                            type="email"
                            className="resetpassword_input" 
                            placeholder="Email" 
                            onChange={handleInputReset} 
                            required/>
                           

                            <input 
                            name="new_password"
                            type="password" 
                            className="resetpassword_input" 
                            placeholder="New password" 
                            onChange={handleInputReset} 
                            required/>

                            <input 
                            name="new_password_two"
                            type="password" 
                            className="resetpassword_input" 
                            placeholder="Repeat New Password" 
                            onChange={handleInputReset} 
                            required/>
                            

                            <input 
                            name="confirmation_code"
                            className="resetpassword_input" 
                            placeholder="Confirmation code" 
                            onChange={handleInputReset} 
                            required/>
                            {showErrorMessageCode ? <p className="resetpassword_texterror">The code does not match the one we sent to your email</p> : null}
                            {showErrorPassword ? <p className="resetpassword_texterror">Password requires numbers, special character, uppercase and lowercase letters, and minimum 8 characters length.</p> : null}
                            {showErrorMessageUsername ? <p className="resetpassword_texterror">User not found, please try again</p> : null}
                            {showErrorDiferentPasswords ? <p className="resetpassword_texterror">New passwords do not match</p> : null}

                            {isLoading ? loadingButtom() : <button className="resetpassword_button">Reset Password</button> }
                            </form>
                        </div>
                    </>
                );

                case 3:
                return (
                    <>
                        <div className="resetpassword_sectionContainer">
                            <h2 className="resetpassword_title">Your account is in the process of password reset.</h2>

                            <p className="resetpassword_text">Previously we sent a confirmation code to your email to reset the password, please check your email and use the code to reset your password with the following form.</p>
                            <form action="" className="resetpassword_form" onSubmit={submitResetPassword}>
                            <input 
                            name="username"
                            className="resetpassword_input" 
                            placeholder="Username" 
                            onChange={handleInputReset} 
                            required/>
                           

                            <input 
                            name="new_password"
                            type="password" 
                            className="resetpassword_input" 
                            placeholder="New password" 
                            onChange={handleInputReset} 
                            required/>
                          

                            <input 
                            name="confirmation_code"
                            className="resetpassword_input" 
                            placeholder="Confirmation code" 
                            onChange={handleInputReset} 
                            required/>
                            {showErrorMessageCode ? <p className="resetpassword_texterror">The code does not match the one we sent to your email</p> : null}
                            {showErrorMessageUsername ? <p className="resetpassword_texterror">User not found, please try again</p> : null}
                            {showErrorPassword ? <p className="resetpassword_texterror">Password requires numbers, special character, uppercase and lowercase letters, and minimum 8 characters length.</p> : null}


                            {isLoading ? loadingButtom() : <button className="resetpassword_button">Reset Password</button> }
                            </form>

                            <p className="resetpassword_text2">If you do not have a confirmation code, press this button and we will send a new one to your email.</p>
                            <button className="resetpassword_button-newCode" onClick={()=>{setStep(1)}}>Send new email</button>

                        </div>
                    </>
                );

                case 4:
                return (
                    <>
                        <div className="resetpassword_sectionContainer">

                            <img src={iconAdmiration} alt="close" className="login_icon-ex" />
                            <h2 className="resetpassword_title">Your verification code has expired.</h2>

                            

                            
                            {isLoading ? loadingButtom() : <button className="resetpassword_button" onClick={submitUsername}>Send a new code to my email</button>}
                           
                            
                        </div>
                    </>
                );

       }
    }

    return(
        <>
        <div className="resetpassword_mainContainer">
            {isAuth ? null : <Header />}
            <div className="resetpassword_container">  
               {sections() }     
            </div> 
            {isAuth ? null : <Footer />}      
        </div>


        {showResetSucces ? 
        <Notification type='popup' title='Your password has been successfully reset'  f={()=>{setShowResetSucces(false); props.history.push (`/login`);}}>
            <img src={iconCheck} alt="close" className="login_icon-check" />
        </Notification> : null}   
        }
        
        </>
    );
}



export default ResetPassword;