import React from 'react'
import '../../assets/styles/Accordeon.css'
import { Icon } from '@iconify/react';

const Accordeon = ({title, isCompleted, children, id, open, hideIcon}) => {
  return (
    <section className='accordeon' id={id}  >
        <details {...open && { open: "open" }}>
            <summary>
                {title}
                {!hideIcon && <Icon width="20px" color={isCompleted ? "31D902" : "C2C2C2"} icon="akar-icons:circle-check" />}
                
            </summary>
            
              {children}
            
        </details>
    </section>
  )
}

export default Accordeon