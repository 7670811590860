import React, { useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/Users.css'
import { useEffect} from 'react';
import {AuthContext} from '../AuthContext';
import UserCard from '../components/UserCard';
import iconAdd from '../assets/images/iconAdd.png';
import SkeletonLoader from "tiny-skeleton-loader-react";
import iconArrowDownGreen from '../assets/images/iconArrowDownGreen.png';
import iconArrowDownGrey2 from '../assets/images/iconArrowDownGrey2.png';
import iconArrowUpGreen from '../assets/images/iconArrowUpGreen.png';
import iconArrowUpGrey2 from '../assets/images/iconArrowUpGrey2.png';


const Users = ()=>{

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const [usersData, setUsersData] = useState([]);
    const [usersOriginalData, setUsersOriginalData] = useState([]);

    const [reloadGetUsers, SetReloadGetUsers] = useState(false);

    const [termSearch, setTermSearch] = useState('')
    const [searchBy, setSearchBy] = useState('All')

    const [hideInactiveUsers, setHideInactiveUsers] = useState(false)





    const [reorder, setReorder] = useState(false);
    const [propertyFilter, setPropertyFilter] = useState()
    const [filterIndex, SetFilterIndex] = useState(2);

    
    //RECUPERAR DATOS DE SESSINGSTORAGE Y CONTEXT API
    const token = window.localStorage.getItem('token');
    //const user = window.localStorage.getItem('user');
    const endpoint = window.localStorage.getItem("getUsersEndpoint");
    const refreshToken = window.localStorage.getItem('refreshToken');
    const dealerId = window.localStorage.getItem('dealerId');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const cantCreateUsers = localStorage.getItem("can_create_applications");
   




    //GET USERS
    useEffect(()=>{
        setIsLoading(true)

        let URI_PERMISSION;
        if(endpoint === 'can_read_all_users'){
            URI_PERMISSION = 'get-all-users'
        }else if(endpoint === 'can_read_dealer_users'){
            URI_PERMISSION = 'get-dealer-users'
        }
        
         const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${URI_PERMISSION}`
         //console.log(URI)
        
         const header = {
            'Content-Type': 'application/json', 
            'Authorization' : token,   
            'x-api-key' : URI_X_API_KEY_I, 
         }
         if(endpoint === 'can_read_dealer_users'){
            header.DealerId = dealerId
        }

        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: header,
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoading(false)
            // console.log(datos);
            setUsersData(datos);
            setUsersOriginalData(datos);
            setErrorFlagFromContext(false)
            setHideInactiveUsers(true) 

        }).catch((error, response) => {
            // console.log(error)
           

            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }

        
        });

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext]);


    const reload = ()=>{
        SetReloadGetUsers(!reloadGetUsers)
    }



    //BUSCADOR
    useEffect(()=>{

        if(termSearch === ''){
            const results = usersOriginalData.filter(user => hideInactiveUsers ? user.status !== 'Inactive' : true )

            setUsersData(results)

        }else if(searchBy === 'All'){
            const results = usersOriginalData.filter(user=> (
                                                                   (user.first_name || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (user.last_name || '').toLowerCase().includes(termSearch.toLowerCase()) 
                                                                || (user.status || '').toLowerCase().includes(termSearch.toLowerCase())       
                                                                || (user.dealer_name || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (user.role || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (user.username || '').toLowerCase().includes(termSearch.toLowerCase())
                                                             )
                                                                && (hideInactiveUsers ? user.status !== 'Inactive' : true)
                                                        
            )
            setUsersData(results)
        }
        else {      
            const results = usersOriginalData.filter(user => (user[searchBy] || '').toLowerCase().includes(termSearch.toLowerCase())
                                                            && (hideInactiveUsers ? user.status !== 'Inactive' : true)
            
            )
            setUsersData(results)
        }

    }, [termSearch, searchBy, hideInactiveUsers])






    //SORTER
    const dynamicSort =(property) => {
        setReorder(!reorder)
        setPropertyFilter(property)

        return function(a, b) {         
          if (filterIndex === 1) {
            
            if(b[property] === undefined){
                b[property] = ''
            }
            if(a[property] === undefined){
                a[property] = ''
            }
            SetFilterIndex(2)
            return b[property].localeCompare(a[property]);
          } else {
            if(b[property] === undefined){
                b[property] = ''
            }
            if(a[property] === undefined){
                a[property] = ''
            }
            SetFilterIndex(1)
            return a[property].localeCompare(b[property]);
          }
          
        };
     
    }




    const arrowDirection = (property) => {

        if(property === propertyFilter && filterIndex === 1){
            return (
                <div className="users_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-up" />
                    <img src={iconArrowDownGreen} alt="" className="users_list-tittle-icon users_list-tittle-icon-down"/>
                </div>
            )
        }else if(property === propertyFilter && filterIndex === 2 ){
            return (
                <div className="users_list-tittle-iconContainer">
                    <img src={iconArrowUpGreen} alt="" className="users_list-tittle-icon users_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-down"/>
                </div>
            ) 
        }else{
            return (
                <div className="users_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-down"/>
                </div>
            ) 
        }

    
    }

    



    useEffect(() => {
        setPropertyFilter('created_date')
    }, [])



    //LOADING CARDS
    const loadingCard = () =>{
        const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        return (list.map((item, index) =>{
            return (<div className="loadingCardUsers" key={index}>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItemEnd"><SkeletonLoader width="90%" /></div>
          </div>)
        }))
    }

    return(
    <>
        <div className="users">


        <div className="users-header">               
                <div className="users-header-tittle">
                    <h2>Users</h2>
                </div>
                <div className="users-header-buttoms">


                     {cantCreateUsers === 'true' ?
                    <Link to="/register"><div className="users-header-buttoms_new">
                    <img src={iconAdd} className="aplications-header-buttoms_new_icon" />
                    
                        <p className="users-header-buttoms_new_text">
                        <label className="users-header-buttoms_new_text_1">New</label>   
                        <label className="users-header-buttoms_new_text_2"> User</label>
                    </p>
                </div></Link>
                : null}

            </div>        
        </div>


        <div className="users_searchContainer">

               
            {/* SEARCHER */}
            <div className="applications_filter-searchContainerList">
                <input type="text" placeholder="Search" value={termSearch} className="applications_filter-searchInput" onChange={(e)=>{setTermSearch(e.target.value)}}/>
                <span className="applications_filter-searchCloseIcon" onClick={()=>{setTermSearch('')}}>x</span>
            </div>



            {/* SEARCHER FILTERS */}
            <div className="users_contaner-filterSearch">
                <select onChange={(e)=>{setSearchBy(e.target.value)}} className="users-header-buttoms_filter square-users">
                    <option value="All" selected>By Any</option>
                    <option value="first_name">By Name</option>
                    <option value="username">By Username</option>
                    <option value="status">By Status</option>
                    <option value="dealer_name">By Dealer</option> 
                    <option value="role">By Role</option>                  
                </select>
            </div>

            <div className="users_hideInactiveUsers">
                <input type="checkbox" className="users_hideInactiveUsers-check"   {...hideInactiveUsers && { checked: "checked" }} onClick={()=> setHideInactiveUsers(!hideInactiveUsers)}/>
                <label>Hide Inactive users</label>
            </div>


        </div>





      
        <div className="users_list-tittle">
            <div className="users_list-tittle-item" onClick={()=>{usersData.sort(dynamicSort("first_name"))}}>
                <div>
                    <p>Name</p>
                </div> 
                {arrowDirection('first_name')}
            </div> 
            
            <div className="users_list-tittle-item" onClick={()=>{usersData.sort(dynamicSort("status"))}}>
                <div>
                    <p>Status</p>
                </div> 
                {arrowDirection('status')}
            </div>   
            <div className="users_list-tittle-item" onClick={()=>{usersData.sort(dynamicSort("dealer_name"))}}>
                <div>
                    <p>Dealer</p>
                </div> 
                {arrowDirection('dealer_name')}
            </div>  
            <div className="users_list-tittle-item" onClick={()=>{usersData.sort(dynamicSort("role"))}}>
                <div>
                    <p>Role</p>
                </div> 
                {arrowDirection('role')}
            </div> 
            <div className="users_list-tittle-item" onClick={()=>{usersData.sort(dynamicSort("username"))}}>
                <div>
                    <p>Username</p>
                </div> 
                {arrowDirection('username')}
            </div>  
            <div className="users_list-tittle-item" onClick={()=>{usersData.sort(dynamicSort("created_date"))}}>
                <div>
                    <p>Creation Date</p>
                </div> 
                {arrowDirection('created_date')}
            </div>  
            
        </div> 
    

     





        {isLoading ? loadingCard() : null}
        


        {
            usersData.length > 0 ?
                    usersData.map((item, index) => 
                    
                        <UserCard key={index} {...item} reload={reload}/> 
                    )
            :
            <h3 className="users_message-notUsers"></h3>
        }
        

    </div>
        

    </>
)
}

export default Users;