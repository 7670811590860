import React from 'react'
import "../assets/styles/ApplicationForm.css";
import ProposalForm from '../components/proposals/ProposalForm';

const EditSystemPackage = ({history}) => {
    return (
        <div className="createApplication" >
        <div className="createApplication-header">
            <div className="createApplication-header-tittle">
                <h2>Edit System Package</h2>
            </div>
        </div>

        <ProposalForm history={history} type="create" editPackage/>

    </div>
    )
}

export default EditSystemPackage;