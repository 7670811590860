import React from 'react'
import '../assets/styles/NotificationPinnacleOff.css'
import moment from 'moment-timezone';

const NotificationPinnacleOff = ({type}) => {

    const localTime = new Date().getTime()
    const today = moment(localTime).tz('America/Chicago').format('YYYY-MM-DD')   
    const ct = moment(localTime).tz('America/Chicago');
    const time6AM = moment.tz(today + " 06:00", "America/Chicago");
    const timeMidNight = moment.tz(today + " 00:00", "America/Chicago");

    

    //localtime = 00:33
    //today = 2020-11-06
    //ny = 2020-11-06 00:33
    //8am = 2020-11-06 08:00
    //midnight = 2020-11-06 23:59

    if((type === 'create') && (ct > timeMidNight && ct < time6AM )){
        return (
            <div className="notificationPinOff_mainContainer">
                <p>Pinnacle system hours are from 6:00 AM to midnight (CT). Loan Applications submitted after hours will be saved in the DealerAlly Portal and submitted to Pinnacle during business hours.</p> 
            </div>
        )
    }else{
        return null
    }
    
}

export default NotificationPinnacleOff;
