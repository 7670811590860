import React, { useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/Users.css'
import '../assets/styles/Logs.css'
import { useEffect} from 'react';
import {AuthContext} from '../AuthContext';
import UserCard from '../components/UserCard';
import iconAdd from '../assets/images/iconAdd.png';
import SkeletonLoader from "tiny-skeleton-loader-react";
import iconArrowDownGreen from '../assets/images/iconArrowDownGreen.png';
import iconArrowDownGrey2 from '../assets/images/iconArrowDownGrey2.png';
import iconArrowUpGreen from '../assets/images/iconArrowUpGreen.png';
import iconArrowUpGrey2 from '../assets/images/iconArrowUpGrey2.png';
import LogRow from '../components/logs/LogRow';
import moment from 'moment-timezone';


const Logs = ()=>{

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    const [logs, setLogs] = useState([]);
    const [logsOriginalData, setLogsOriginalData] = useState([]);
    const [totalLogs, setTotalLogs] = useState(0)

    const localTime = new Date().getTime()
    const [postObj, setPostObj] = useState({
        level: "ANY",
        beginDate: `${moment(localTime).format('YYYY-MM-DD')} 00:00`,
        endDate: `${moment(localTime).format('YYYY-MM-DD')} 23:59`,
        rows: 100,
        offset: 0,
    })

    const [reloadGetLogs, SetReloadGetLogs] = useState(false);

    const [termSearch, setTermSearch] = useState(moment(localTime).format('YYYY-MM-DD'))
    const [searchBy, setSearchBy] = useState('All')


    const [reorder, setReorder] = useState(false);
    const [propertyFilter, setPropertyFilter] = useState('entryDate')
    const [filterIndex, SetFilterIndex] = useState(2);

    

    const token = window.localStorage.getItem('token');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;

   

   


    //GET LOGS
    useEffect(()=>{
        setIsLoading(true)
        setLogs([])
       
        
         const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}logs`
        //  console.log(URI)
        
         const body = postObj

       
        

        const abortController = new AbortController();
        const signal = abortController.signal;

        window.fetch(URI,{
            method: 'POST',
            signal: signal,
            headers: {
                'Content-Type': 'application/json', 
                'Authorization' : token,   
                'x-api-key' : URI_X_API_KEY_I, 
             },
            body: JSON.stringify(body)
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoading(false)
            //console.log(datos);


            setLogs(datos.Logs);
            setLogsOriginalData(datos.Logs);
            setTotalLogs(datos.Count)
            setErrorFlagFromContext(false)

        }).catch((error, response) => {
            console.log(error)
           

            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }

        
        });

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, postObj]);



    const handleDateInput = (e) => {
        // console.log(e.target.value)
        setTermSearch(e.target.value)
        setPostObj({
            ...postObj,
            offset : 0,
            beginDate: `${e.target.value} 00:00`,
            endDate:  `${e.target.value} 23:59`
        })
    }


    const handlePagination = (direction) => {
        // console.log(direction)

        let newOffset = 0
        if(direction === 'right' && (postObj.offset + 100 < totalLogs) ){
            newOffset = postObj.offset + 100
            setPostObj({...postObj, offset: newOffset})
        }else if(direction === 'left' && postObj.offset !== 0){
            newOffset = postObj.offset - 100
            setPostObj({...postObj, offset: newOffset})
        }

        // console.log(newOffset)
         
       
        
    }




    const reload = ()=>{
        SetReloadGetLogs(!reloadGetLogs)
    }



    //BUSCADOR
    // useEffect(()=>{

    //     if(termSearch === ''){
    //         setLogs(logsOriginalData)
    //     }else if(searchBy === 'All'){
    //         const results = logsOriginalData.filter(user=> (user.first_name || '').toLowerCase().includes(termSearch.toLowerCase())
    //                                                             || (user.last_name || '').toLowerCase().includes(termSearch.toLowerCase()) 
    //                                                             || (user.status || '').toLowerCase().includes(termSearch.toLowerCase())       
    //                                                             || (user.dealer_name || '').toLowerCase().includes(termSearch.toLowerCase())
    //                                                             || (user.role || '').toLowerCase().includes(termSearch.toLowerCase())
    //                                                             || (user.username || '').toLowerCase().includes(termSearch.toLowerCase())
                                                        
    //         )
    //         setLogs(results)
    //     }else if(searchBy === 'first_name'){
    //         const results = logsOriginalData.filter(user=> (user.first_name || '').toLowerCase().includes(termSearch.toLowerCase())
    //                                                             || (user.last_name || '').toLowerCase().includes(termSearch.toLowerCase()) 
                                                                
                                                        
    //         )
    //         setLogs(results)

    //     } else {      
    //         const results = logsOriginalData.filter(user => (user[searchBy] || '').toLowerCase().includes(termSearch.toLowerCase()))
    //         setLogs(results)
    //     }

    // }, [termSearch, searchBy])






    //SORTER
    const dynamicSort =(property) => {
        setReorder(!reorder)
        setPropertyFilter(property)

        return function(a, b) {         
          if (filterIndex === 1) {
            
            if(b[property] === undefined){
                b[property] = ''
            }
            if(a[property] === undefined){
                a[property] = ''
            }
            SetFilterIndex(2)
            return b[property].localeCompare(a[property]);
          } else {
            if(b[property] === undefined){
                b[property] = ''
            }
            if(a[property] === undefined){
                a[property] = ''
            }
            SetFilterIndex(1)
            return a[property].localeCompare(b[property]);
          }
          
        };
     
    }




    const arrowDirection = (property) => {

        if(property === propertyFilter && filterIndex === 1){
            return (
                <div className="users_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-up" />
                    <img src={iconArrowDownGreen} alt="" className="users_list-tittle-icon users_list-tittle-icon-down"/>
                </div>
            )
        }else if(property === propertyFilter && filterIndex === 2 ){
            return (
                <div className="users_list-tittle-iconContainer">
                    <img src={iconArrowUpGreen} alt="" className="users_list-tittle-icon users_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-down"/>
                </div>
            ) 
        }else{
            return (
                <div className="users_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="users_list-tittle-icon users_list-tittle-icon-down"/>
                </div>
            ) 
        }

    
    }



    // useEffect(() => {
    //     setPropertyFilter('created_date')
    // }, [])



    //LOADING CARDS
    const loadingCard = () =>{
        const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        return (list.map((item, index) =>{
            return (<div className="loadingCardUsers" key={index}>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            
          </div>)
        }))
    }

    return(
    <>
        <div className="users">


        <div className="users-header">               
                <div className="users-header-tittle">
                    <h2>Logs</h2>
                </div>

                {/* <div className="users-header-buttoms">


                     {cantCreateUsers === 'true' ?
                    <Link to="/register"><div className="users-header-buttoms_new">
                    <img src={iconAdd} className="aplications-header-buttoms_new_icon" />
                    
                        <p className="users-header-buttoms_new_text">
                        <label className="users-header-buttoms_new_text_1">New</label>   
                        <label className="users-header-buttoms_new_text_2"> User</label>
                    </p>
                </div></Link>
                : null} 

            </div>   */}     
        </div>



        {/* SEARCH */}
        <div className="users_searchContainer">

               
            {/* SEARCHER */}
            <div className="applications_filter-searchContainerList">
                {/* <input type="text" placeholder="Search" value={termSearch} className="applications_filter-searchInput" onChange={(e)=>{setTermSearch(e.target.value)}}/> */}
                <input type="date"  placeholder="Date" value={termSearch} className="applications_filter-searchInput" onChange={(e)=>{handleDateInput(e)}}/>
                {/* <span className="applications_filter-searchCloseIcon" onClick={()=>{setTermSearch('')}}>x</span> */}
            </div>



            {/* SEARCHER FILTERS */}
            <div className="users_contaner-filterSearch">
                <select onChange={(e)=>{setPostObj({...postObj, level : e.target.value, offset: 0})}} className="users-header-buttoms_filter square-users">
                    <option value="ANY" selected>ANY</option>
                    <option value="INFO">INFO</option>
                    <option value="WARN">WARN</option>
                    <option value="ERROR">ERROR</option>
                    <option value="FATAL">FATAL</option> 
                    <option value="DEBUG">DEBUG</option>                  
                </select>
            </div>


            <div className="logs_paginationContainer">
                <span className="logs_paginationContainer-arrow" onClick={()=>handlePagination('left')}>&#8592;</span>
                <span className="logs_paginationContainer-number">{`${postObj.offset}-${postObj.offset + 100 < totalLogs ? (postObj.offset + 100) : (totalLogs) } of ${totalLogs}`}</span>
                <span className="logs_paginationContainer-arrow" onClick={()=>handlePagination('right')}>&#8594;</span>
            </div>

        </div>





        {/* TAB HEADERS */}
        <div className="logs_list-tittle">
            <div className="users_list-tittle-item" >
                <div>
                    <p>Id</p>
                </div> 
                
            </div> 

            <div className="users_list-tittle-item" onClick={()=>{logs.sort(dynamicSort("entryDate"))}}>
                <div>
                    <p>Date</p>
                </div> 
                {arrowDirection('entryDate')}
            </div> 
            
            <div className="users_list-tittle-item" onClick={()=>{logs.sort(dynamicSort("logLevel"))}}>
                <div>
                    <p>Level</p>
                </div> 
                {arrowDirection('logLevel')}
            </div>   
            <div className="users_list-tittle-item" onClick={()=>{logs.sort(dynamicSort("logger"))}}>
                <div>
                    <p>Logger</p>
                </div> 
                {arrowDirection('logger')}
            </div>  
            
            
        </div> 
    

     




        {isLoading ? loadingCard() : null}
        


        {
            logs && logs.length > 0 ?
                    logs.map((item, index) => 
                    
                        <LogRow key={index} {...item} reload={reload}/> 
                    )
            :
            <h3 className="users_message-notUsers"></h3>
        }
        

    </div>
        

    </>
)
}

export default Logs;