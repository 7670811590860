import React, {useEffect, useState} from 'react'
import '../../assets/styles/CommercialApplicationRow.css'

import Sheet from '../Sheet';
import ApplicationFormAquaFinance from "../ApplicationFormAquaFinance"
import PinnacleStubForm from '../PinnacleStubForm'
import ApplicationFormPinnacle from '../ApplicationFormPinnacle';
import CommercialLoan from './CommercialLoan';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';


const CommercialApplicationRow = (props) => {

    // console.log(props)



    const [showSheet, setShowSheet] = useState(false);

    const [newApplicationStatus, setNewApplicationStatus] = useState(false);

    const [status, setStatus] = useState('grayStatus')


    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const token = window.localStorage.getItem('token');
    const hideBuyRate = localStorage.getItem('hideBuyRate');
    const userRole = localStorage.getItem('userRole');


    const showSheetHandler = ()=>{
        setShowSheet(!showSheet);

    }

    useEffect(() => {
        let statusColor

        const red = ['Denied', 'Cancelled', 'SendBack', 'Error']
        const green = [ 'FundingComplete', 'ReadyToFund']
        const blue = []
        const yellow = ['ConditionallyApproved', 'SignReady', 'PendingDocs', 'Pending', 'InVerification', 'InConfirmation', 'Approved']
        const black = ['FilledOut', 'Processing']
        const orange = ['Problem', 'Received']


        if(red.includes(props.CommercialStatus)){
            //RED
            statusColor = 'redStatus'
        }else if(green.includes(props.CommercialStatus)){
            //GREEN
            statusColor = 'greenStatus'
        }else if(blue.includes(props.CommercialStatus)){
            //BLUE
            statusColor = 'blueStatus'
        }else if(yellow.includes(props.CommercialStatus)){ 
            //YELLOW         
            statusColor = 'yellowStatus'
        }else if(black.includes(props.CommercialStatus)){
            //BLACK
            statusColor = 'grayStatus'
        }else if(orange.includes(props.CommercialStatus)){
            //ORANGE
            statusColor = 'orangeStatus'
        }

        setStatus(statusColor)
          
        
    }, [props.CommercialStatus])
    

    
        


    return (
        <>




{showSheet ?
    <Sheet handler={showSheetHandler}>
        <CommercialLoan applicationId={props.Id} inspectMode/>
      
       
        
        
    </Sheet> : null}



        
        <div className="" >

            <div className={ isDeallerAllyAdmin === 'true' ? "commercialApp_list commercialApp_list_adminGrid" : "commercialApp_list"} onClick={showSheetHandler}>
                <p className="application_listItem">{props.Id}</p>
                <p className={`application_listItem ${status}`}>{props.LegalCompanyName}</p>
                {
                   isDeallerAllyAdmin === 'true' &&
                   <p className="application_listItem">{props.DealerName}</p>
                } 
                <p className="application_listItem">{props.FinancialInstitution}</p>
                <p className={`application_listItem ${status}`}>{props.CommercialStatus}</p>
                <p className="application_listItem">{props.CreatedBy}</p>  
                <p className="application_listItem">{props.CreatedDate}</p>
                <p className="application_listItem">{props.LastModifiedDate}</p>
                <p className="application_listItem">{`$${props.EquipmentPrice}`}</p>
                {
                    props.Submitted ? 'Completed' :
                    <Link  to={`/commercial/${props.Id}`} className="commercialApplicationList_continue" onClick={showSheetHandler}>
                        <Icon width="22px" color={"31D902"} icon="ci:external-link" />
                        <p className="applicationCard_header-DetailsButton-text" >Continue</p>
                    </Link>
                }
                {/* <p className="application_listItem">{props.Submitted ? 'Yes' : 'No'}</p> */}
                
                
            </div>
  
        </div>

        


          
            
        </>
    )
}

export default CommercialApplicationRow
