import React, { useState, useEffect, useContext } from 'react'
import "../../assets/styles/ApplicationForm.css";
import NumberFormat from "react-number-format";
import { AuthContext } from '../../AuthContext';
import useIsSafari from '../../hooks/useIsSafari'
import AlertBox from "../AlertBox";
import Button from '../general/Button';
import PlacesAutocomplete from "react-places-autocomplete";
import useHandlePlacesAutocomplete from '../../hooks/useHandlePlacesAutocomplete';

const CompanyInformationForm = ({setCommercialLoanApplicationId, type, setApplicantBusinessId, applicantBusinessId, setStep, commercialLoanApplicationId, step, setCommercialLoanData, prefilledInfo}) => {

  const { uri, changeToken, proposalInfo } = useContext(AuthContext);
  const [applicant, setApplicant] = useState({CorpOnly: 'false'})
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [notificationError, setNotificationError] = useState({show: false})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [handleChangePlacesAutocomplete, handleSelectPlacesAutocomplete ] = useHandlePlacesAutocomplete(setApplicant, 'Address');

  const isSafari = useIsSafari()

  const user = window.localStorage.getItem('user');
  const token = window.localStorage.getItem('token');



  useEffect(()=>{
    if(prefilledInfo){
      setApplicant({...prefilledInfo, CorpOnly: prefilledInfo.CorpOnly ? 'true' : 'false'})
    }
  },[prefilledInfo])


  //POST APPLICATION
  const submitForm = async (event) => {

      setIsLoading(true);
      setNotificationError({ show: false});

      //Armar el objeto que se envia
      const formData = {Submit: false, FinancialInstitution:'FI-0003',  CommercialLoanApplicationId: commercialLoanApplicationId};
      formData.CompanyInformation = applicant;
      if(applicantBusinessId){
        formData.CompanyInformation.ApplicantId =  applicantBusinessId
      }

      if(proposalInfo.hasInfo){
        formData.ProposalId =  proposalInfo.info.customerProposalId
      }

      if(formData.CompanyInformation.LegalBusinessStructure !== 'SoleProprietorship' && formData.CompanyInformation.SSN){
        delete formData.CompanyInformation.SSN
      }

      if(formData.CompanyInformation.LegalBusinessStructure === 'SoleProprietorship' && formData.CompanyInformation.FedTaxId){
        delete formData.CompanyInformation.FedTaxId
      }




      // console.log(formData)
      // console.log(JSON.stringify(formData))

      const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application`;

    
        const headers = {
                      'Access-Control-Request-Headers': 'application/json',
                      'Content-Type': 'application/json',
                      'username': user,
                      'x-api-key': uri.URI_X_API_KEY,
                      'Authorization' : token, 
                  }
      
      try {
          const rawResponse = await window.fetch(URI, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(formData),
          });
          setIsLoading(false);
          const resp = await rawResponse.json();
          // console.log(resp);

          if(resp.CommercialLoanApplicationId){
            setCommercialLoanData(state => ({...state,    
                                                CommercialLoanApplicationId: resp.CommercialLoanApplicationId, 
                                                CompanyInformation: {...formData.CompanyInformation, ApplicantId: resp.ApplicantBusinessId, } 
                                            }))
            setCommercialLoanApplicationId(resp.CommercialLoanApplicationId)
            setApplicantBusinessId(resp.ApplicantBusinessId)
            setStep(state => ({...state, CompanyInformation: true}))
            if(commercialLoanApplicationId){
              setNotificationError({ 
                title: 'Information edited successfully',
                type: 'success',
                show: true
              });
            }
          }else{
            setNotificationError({ 
              title: resp.Message,
              text: resp.Errors[0],
              type: 'error',
              show: true
            });
          }

      }
      catch (error) {
        setIsLoading(false);
        setNotificationError({ 
          title: 'Error',
          text: 'An unexpected error has occurred. Please try again.',
          type: 'error',
          show: true
        });
        if(error.status === 401){
          changeToken()
        } 
      }


  };



  const handleInputApplicant = (e, inputName) => {
    let value = (e.target && e.target.value) || e.value
    let name = (e.target && e.target.name) || inputName

    if (inputName === "CompanyPhone" || inputName === "FedTaxId" || inputName === "SSN") {
      setApplicant({
        ...applicant,
        [inputName]: e.value,
      })
    } else {
      setApplicant({
        ...applicant,
        [e.target.name]: value,
      })
    }
  }





  const validateForm = (data, isCoApplicant) => {
    let errorList = {};



    if (!data.LegalBusinessStructure) {
      errorList.LegalBusinessStructure = "Please select an option";
    } 

    if(applicant.LegalBusinessStructure === 'SoleProprietorship'){
      if (!data.SSN) {
        errorList.SSN = "Please enter a SSN";
      }else if (!/^[0-9]+\.?[0-9]*$/.test(data.SSN)) {
        errorList.SSN = "The value can only contain numbers";
      }
    }
    

    if (!data.LegalCompanyName) {
      errorList.LegalCompanyName = "Please enter a company name";
    }

    if(applicant.LegalBusinessStructure === 'S-Corporation'){
      if (!data.DoingBusinessAs) {
        errorList.DoingBusinessAs = "Please enter a response";
      }
    }
    

    if (!data.CompanyPhone) {
      errorList.CompanyPhone = "Please enter a company phone";
    }else if (!/^[0-9]{10}$/.test(data.CompanyPhone)) {
        errorList.CompanyPhone = "The phone must have 10 numbers";
    } else if (data.CompanyPhone === '0000000000') {
        errorList.CompanyPhone = "Please enter a valid phone number";
    }

    if (!data.Address) {
      errorList.Address = "Please enter a address";
    }

    if (!data.City) {
      errorList.City = "Please enter a city";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ-\s\.]+$/.test(data.City)) {
        errorList.City = "The city can only contain letters";
    }


    if (!data.State) {
        errorList.State = "Please select an option";
    } else if (data.State === "State") {
        errorList.State = "Please select an option";
    }

    
    if (!data.PostalCode) {
        errorList.PostalCode = "Please enter a value";
    } else if (!/^[0-9]{5}$/.test(data.PostalCode)) {
        errorList.PostalCode = "The value must have 5 numbers";
    }


    if (!data.NatureOfBusiness) {
      errorList.NatureOfBusiness = "Please enter a response";
    }


    if (data.AnnualRevenue) {
      if (!/^[0-9]+\.?[0-9]*$/.test(data.AnnualRevenue)) {
        errorList.AnnualRevenue = "Enter a valid number";
      }
    } 
    

    if (!data.EstablishedDate) {
      errorList.EstablishedDate = "Please enter a date";
    } else if (!data.EstablishedDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
        if (isSafari) {
            errorList.EstablishedDate = "The format must be yyyy-mm-dd";
        } else {
            errorList.EstablishedDate = "The format must be dd/mm/yyyy";
        }
    } 


    if (!data.YearsUnderCurrentOwner) {
      errorList.YearsUnderCurrentOwner = "Please enter a response";
    }else if (!/^[0-9]+\.?[0-9]*$/.test(data.YearsUnderCurrentOwner)) {
      errorList.YearsUnderCurrentOwner = "The value can only contain numbers";
    }


    // if(applicant.IsThisATruckingCompany === 'true'){
    //   if (!data.HaulingType) {
    //     errorList.HaulingType = "Please select an option";
    //   }
    // }
     
    return errorList;
  };




  const handleSubmit = event => {
    event.preventDefault();
    setErrors(validateForm(applicant, false));
    setIsSubmitting(true);
};





useEffect(() => {
  if (Object.keys(errors).length === 0 && isSubmitting ) {
    submitForm()
  } else{
      //console.log(errors)
      // console.log(applicant)
      //handleSubmitAllData();
  }

}, [errors, isSubmitting]);




//VALIDAR Y LLENAR INFO DE PROPOSAL
useEffect(()=>{
    if(proposalInfo.hasInfo){
        // console.log(proposalInfo.info)

        setApplicant({
            ...applicant,
            LegalCompanyName : proposalInfo.info.LegalCompanyName
        })

        setErrors(validateForm({LegalCompanyName : proposalInfo.info.LegalCompanyName}, false));

    }

},[proposalInfo])








  return (
    <div>

      <form action="" id="form" className="form">

        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Legal Company Name</label>
          <input
            type="text"
            name="LegalCompanyName"
            onChange={handleInputApplicant}
            className={`${errors.LegalCompanyName ? "imputError" : "campo"}`}
            value={applicant.LegalCompanyName}
            placeholder="Legal Company Name"
            disabled={(type === 'edit') ? "disabled" : null}
            required
          />
          {errors.LegalCompanyName && <p className="errorMessage">{errors.LegalCompanyName}</p>}
        </div>


        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Doing Business As (DBA)</label>
          <input
            type="text"
            name="DoingBusinessAs"
            onChange={handleInputApplicant}
            className={`${errors.DoingBusinessAs ? "imputError" : "campo"}`}
            value={applicant.DoingBusinessAs}
            placeholder="Doing Business As"
            disabled={(type === 'edit') ? "disabled" : null}
            required
          />
          {errors.DoingBusinessAs && <p className="errorMessage">{errors.DoingBusinessAs}</p>}
        </div>

        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Company Phone</label>
          <NumberFormat
            value={applicant.CompanyPhone}
            onValueChange={e => handleInputApplicant(e, 'CompanyPhone')}
            displayType="input"
            type="text"
            name="CompanyPhone"
            placeholder="Do not use dashes"
            format="(###) ### - ####"
            className={`${errors.CompanyPhone ? "imputError" : "campo"}`}
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.CompanyPhone && <p className="errorMessage">{errors.CompanyPhone}</p>}
        </div>


        <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Legal Business Structure</label>
          <select
            value={applicant.LegalBusinessStructure || 'selected'}
            name="LegalBusinessStructure"
            onChange={handleInputApplicant}
            className={`${errors.LegalBusinessStructure ? "imputError" : "campo"}`}
            placeholder="Legal Business Structure"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
              Legal Business Structure
            </option>
            <option value="Corporation">Corporation</option>
            <option value="LLC">LLC</option>
            <option value="LLP">LLP</option>
            <option value="Non-Profit">Non-Profit</option>
            <option value="Partnership">Partnership</option>
            <option value="SoleProprietorship">Sole Proprietorship</option>
            <option value="S-Corporation">S-Corporation</option>
            <option value="LimitedPartnership">Limited Partnership</option>
            <option value="Division">Division</option>
          </select>
          {errors.LegalBusinessStructure && <p className="errorMessage">{errors.LegalBusinessStructure}</p>}
        </div>

        {
          applicant.LegalBusinessStructure !== 'SoleProprietorship' && applicant.LegalBusinessStructure !== undefined &&
          <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Fed Tax ID</label>
          <NumberFormat
            value={applicant.FedTaxId}
            onValueChange={e => handleInputApplicant(e, 'FedTaxId')}
            displayType="input"
            type="text"
            name="FedTaxId"
            placeholder="Fed Tax ID"
            format="## - #######"
            className={`${errors.FedTaxId ? "imputError" : "campo"}`}
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.FedTaxId && <p className="errorMessage">{errors.FedTaxId}</p>}
          </div>
        }
        
        {
          applicant.LegalBusinessStructure === 'SoleProprietorship' &&
          <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Social Security Number</label>
          <NumberFormat
            value={applicant.SSN}
            onValueChange={e => handleInputApplicant(e, 'SSN')}
            displayType="input"
            type="text"
            name="SSN"
            placeholder="Social Security Number"
            format="### - ## - ####"
            className={`${errors.SSN ? "imputError" : "campo"}`}
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.SSN && <p className="errorMessage">{errors.SSN}</p>}
          </div> 
        }
       
        <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Address</label>
            <PlacesAutocomplete
                value={applicant.Address}
                onChange={handleChangePlacesAutocomplete}
                onSelect={handleSelectPlacesAutocomplete}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                    {...getInputProps({ placeholder: "Type address" })}
                    placeholder="Address"
                    className={`${errors.Address ? "imputError" : "campo"}`}
                    autocomplete="off"
                    disabled={(type === 'edit') ? "disabled" : null}
                    />

                    <div className={suggestions.length > 0 && "inputPlacesAutocomplete_optionsContainer"}>
                    {loading ? <div>...loading</div> : null}

                    {suggestions.map((suggestion) => {
                        const style = {
                        backgroundColor: suggestion.active ? "#e5e5e5" : "#fff",
                        cursor: "pointer",
                        marginBotton: '10ppx'
                        };

                        return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                            {suggestion.description}
                        </div>
                        );
                    })}
                    </div>
                </div>
                )}
            </PlacesAutocomplete>
            {errors.Address && <p className="errorMessage">{errors.Address}</p>}
        </div>


        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >City</label>
          <input
            type="text"
            value={applicant.City}
            name="City"
            onChange={handleInputApplicant}
            className={`${errors.City ? "imputError" : "campo"}`}
            placeholder="City"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.City && <p className="errorMessage">{errors.City}</p>}
        </div>



        <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >State</label>
          <select
            value={applicant.State || 'selected'}
            name="State"
            onChange={handleInputApplicant}
            className={`${errors.State ? "imputError" : "campo"}`}
            placeholder="State"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
              State
            </option>
            <option value="AK">AK</option>
            <option value="AL">AL</option>
            <option value="AR">AR</option>
            <option value="AZ">AZ</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DC">DC</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="GU">GU</option>
            <option value="HI">HI</option>
            <option value="IA">IA</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="MA">MA</option>
            <option value="MD">MD</option>
            <option value="ME">ME</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MS">MS</option>
            <option value="MO">MO</option>
            <option value="MT">MT</option>
            <option value="NE">NE</option>
            <option value="NC">NC</option>
            <option value="ND">ND</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NV">NV</option>
            <option value="NY">NY</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="PR">PR</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VA">VA</option>
            <option value="VI">VI</option>
            <option value="VT">VT</option>
            <option value="WA">WA</option>
            <option value="WI">WI</option>
            <option value="WV">WV</option>
            <option value="WY">WY</option>
          </select>
          {errors.State && <p className="errorMessage">{errors.State}</p>}
        </div>


        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Postal Code</label>
          <input
            type="number"
            value={applicant.PostalCode}
            name="PostalCode"
            onChange={handleInputApplicant}
            className={`${errors.PostalCode ? "imputError" : "campo"}`}
            placeholder='Postal Code'
            required
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.PostalCode && <p className="errorMessage">{errors.PostalCode}</p>}
        </div>



        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Nature of Business</label>
          <input
            type="text"
            value={applicant.NatureOfBusiness}
            name="NatureOfBusiness"
            onChange={handleInputApplicant}
            className={`${errors.NatureOfBusiness ? "imputError" : "campo"}`}
            placeholder="Nature of Business"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.NatureOfBusiness && <p className="errorMessage">{errors.NatureOfBusiness}</p>}
        </div>


        {/* <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Is this a trucking company?</label>
          <select
            value={applicant.IsThisATruckingCompany || 'selected'}
            name="IsThisATruckingCompany"
            onChange={handleInputApplicant}
            className={`${errors.IsThisATruckingCompany ? "imputError" : "campo"}`}
            placeholder="Is this a trucking company?"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
              Is this a trucking company?
            </option>
            <option value="true">YES</option>
            <option value="false">NO</option>
          </select>
          {errors.IsThisATruckingCompany && <p className="errorMessage">{errors.IsThisATruckingCompany}</p>}
        </div>

        {
          applicant.IsThisATruckingCompany === 'true' &&
          <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Hauling Type</label>
          <select
            value={applicant.HaulingType || 'selected'}
            name="HaulingType"
            onChange={handleInputApplicant}
            className={`${errors.HaulingType ? "imputError" : "campo"}`}
            placeholder="Hauling Type"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
              Hauling Type
            </option>
            <option value="LongDistance">Long Distance</option>
            <option value="Local">Local</option>
          </select>
          {errors.HaulingType && <p className="errorMessage">{errors.HaulingType}</p>}
          </div>
        } */}


        {
          applicant.HaulingType === 'LongDistance' &&
          <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Number of Power Units in Fleet</label>
          <input
            type="number"
            name="NumberOfPowerUnitsInFleet"
            onChange={handleInputApplicant}
            className={`${errors.NumberOfPowerUnitsInFleet ? "imputError" : "campo"}`}
            placeholder="Number of Power Units in Fleet"
            value={applicant.NumberOfPowerUnitsInFleet}
            required
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.NumberOfPowerUnitsInFleet && <p className="errorMessage">{errors.NumberOfPowerUnitsInFleet}</p>}
          </div>
        }
       


        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Established Date</label>
          <input
            type="date"
            name="EstablishedDate"
            onChange={handleInputApplicant}
            className={`${errors.EstablishedDate ? "imputError" : "campo"}`}
            placeholder="yyyy-mm-dd"
            required
            autocomplete="off"
            autocorrect="off"
            value={applicant.EstablishedDate}
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.EstablishedDate && <p className="errorMessage">{errors.EstablishedDate}</p>}
        </div>


        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Years under current owner</label>
          <input
            type="number"
            name="YearsUnderCurrentOwner"
            onChange={handleInputApplicant}
            className={`${errors.YearsUnderCurrentOwner ? "imputError" : "campo"}`}
            placeholder="Years under current owner"
            value={applicant.YearsUnderCurrentOwner}
            required
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.YearsUnderCurrentOwner && <p className="errorMessage">{errors.YearsUnderCurrentOwner}</p>}
        </div>


        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Annual Revenue</label>
          <input
            type="number"
            name="AnnualRevenue"
            onChange={handleInputApplicant}
            className={`${errors.AnnualRevenue ? "imputError" : "campo"}`}
            onBlur={()=> { setApplicant({...applicant, 'AnnualRevenue': parseFloat(applicant.AnnualRevenue).toFixed(2) })   }}
            placeholder="Annual Revenue"
            value={applicant.AnnualRevenue}
            required
            disabled={(type === 'edit') ? "disabled" : null}
          />
          {errors.AnnualRevenue && <p className="errorMessage">{errors.AnnualRevenue}</p>}
        </div>



        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Corp Only</label>
          <select
            value={applicant.CorpOnly || 'selected'}
            name="CorpOnly"
            onChange={handleInputApplicant}
            className={`${errors.CorpOnly ? "imputError" : "campo"}`}
            placeholder="Corp Only"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
                Corp Only
            </option>
            <option value="true">YES</option>
            <option value="false">NO</option>
          </select>
          {errors.CorpOnly && <p className="errorMessage">{errors.CorpOnly}</p>}
        </div>



        <div className='mediowidth-right' style={{justifySelf: 'end', marginTop: '15px'}}>
          <Button
           onClick={handleSubmit}
           value={(commercialLoanApplicationId && step.CompanyInformation) ? "Save" : "Save and continue"}
           isLoading={isLoading}
           showButton={type === 'create'}
          />
        </div>

      

        <div className="fullwidth campoContainer" >
            <AlertBox 
                showAlert={notificationError.show}
                type={notificationError.type}
                title={notificationError.title}
                text={notificationError.text}
            />
          </div>


      </form>
    </div>
  )
}

export default CompanyInformationForm