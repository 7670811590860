import React, {useState} from 'react'
import PinnacleStubForm from './PinnacleStubForm'

const CreateOnlyDocumentsApp = ({financialInstitutionList}) => {

    

    const [showForm, setShowForm] = useState('general')


    const toggleForm = (lender) => {


        if(showForm === lender){
            setShowForm('general')
        }else {  
            setShowForm(lender)
        }
    }
    return (
        <>
            
            <div className="externalForm_toggleLenderContainer">
                {
                   financialInstitutionList &&  financialInstitutionList.length > 0 && financialInstitutionList.map(item => {
                       if(item.CanDealerCreateOnlyDocumentsApplications){
                        return <div className={showForm === item.Id  ? "externalForm_toggleLender-option-active" : "externalForm_toggleLender-option"} onClick={()=>{toggleForm(item.Id === 'FI-0001' ?  'FI-0001' : 'FI-0002')}} key={item.Id}>
                                    <p className="externalForm_toggleLender-text">Send to:</p>
                                    <p className="externalForm_toggleLender-lenderTitle">{item.Name}</p>
                                </div>
                       }
                       
                   })
                }
                
                
                   
                {/* <div className={showForm === 'pinnacle' ? "externalForm_toggleLender-option-active" : "externalForm_toggleLender-option"} onClick={()=>{toggleForm('pinnacle')}}>
                    <p className="externalForm_toggleLender-text">Send to:</p>
                    <p className="externalForm_toggleLender-lenderTitle">Pinnacle</p>
                </div> */}
            </div>
           
            
            {
               showForm === 'FI-0001' ? 
               <PinnacleStubForm entity="aquaFinance" type="create" showFinancialEntityTitle='true'/>
               :showForm === 'FI-0002' ? 
               <PinnacleStubForm type="create" showFinancialEntityTitle='true'/>
               : <div className="applicationFormContainer">
                   <p>Choose a financial institution to send your Documents-only Application</p>
               </div> 
            }
           

        </>
    )
}

export default CreateOnlyDocumentsApp;
