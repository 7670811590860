import React, {useState, useEffect, useContext} from 'react';
import '../../assets/styles/Applications.css'
import '../../assets/styles/ReportRow.css'
import '../../assets/styles/BasicLayout.css'
import {Link} from 'react-router-dom';
import {AuthContext} from '../../AuthContext';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment-timezone';
import ReportList from './RemittanceReportList';




const RemittanceReport = ()=>{


   // const [showFilter, setShowFilter] = useState(false);
    const{setProposalInfo} = useContext(AuthContext);

    const [layoutCards, setLayoutCards] = useState( localStorage.getItem('applicant_layout') || 'true')

    //SEARCH 
    const [termSearchCard, setTermSearchCard] = useState('')
    const [termSearchList, setTermSearchList] = useState('')
    const [searchBy, setSearchBy] = useState('DealerName')

    //FILTERS STATUS 
    const [filterStatusValue, setFilterStatusValue] = useState('')

    //FILTERS STATUS 
    const [filterFIValue, setFilterFIValue] = useState('')

    //APPLICANT ENDPOINTS
    const [callApplicantRealList, setCallApplicantRealList] = useState(localStorage.getItem('applicant_callApplicantRealList') || 'true')

    const [refreshGetApplicant, setRefreshGetApplicant] = useState(false)

    const [reloadAlertBanner, setReloadAlertBanner] = useState(false)

    const [disableStatusFilter, setDisableStatusFilter] = useState(false)

    //DATA RANGE PICKER
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState({});

    const [netProceedsTotal, setNetProceedsTotal] = useState(0)
    

    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');



   










 




    // MULTISELECT FILTER BY APPLICATION STATUS
    const options = [
        { label: "Approved", value: "Approved" },
        { label: "Denied", value: "Denied" },
        { label: "Error", value: "Error" },
        { label: "Pending", value: "Pending" },
        { label: "Processing", value: "Processing", },
        { label: "FilledOut", value: "FilledOut" },
        { label: "SignReady", value: "SignReady" },
        { label: "Submitted", value: "Submitted" },
        { label: "ConditionallyApproved", value: "ConditionallyApproved" },
        { label: "Received", value: "Received" },
        { label: "PendingDocs", value: "PendingDocs" },
        { label: "InVerification", value: "InVerification" },
        { label: "InConfirmation", value: "InConfirmation" },
        { label: "Verified", value: "Verified" },
        { label: "ReadyToFund", value: "ReadyToFund" },
        { label: "FundingComplete", value: "FundingComplete" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "OnHold", value: "OnHold" },
        { label: "Problem", value: "Problem" },
        { label: "SendBack", value: "SendBack" },
        { label: "SignComplete", value: "SignComplete" },
      ];

      const [statusSelected, setStatusSelected] = useState([]);
      const [filterStatusSelected, setFilterStatusSelected] = useState([]);


      const customValueRenderer = (selected, _options) => {
          if(statusSelected.length && disableStatusFilter){
              return "Status"
          }else if(statusSelected.length){
              return null
          }else{
              return "Status"
          }
        // return statusSelected.length
        //   ? null
        //   : "Status";
      };

      useEffect(()=>{
        const newList = statusSelected.map(item => item.value)
        setFilterStatusSelected(newList)    
      },[statusSelected])





     







 

    return(
    <>
    
    <div  >

    

        {/* HEADER */}
       
           
        <div className="remittanceReport_netProceeds">
            <p>{`Net Proceeds Total: $${netProceedsTotal}`}</p>
        </div>          
           
     

         {/**********************************************   ***************************************************/}

        <div className="applications_filterContainer widthAuto">

            <div className="applications_filterLeft">

                {
                            <>
                            {/* SEARCHER */}
                            {
                                isDeallerAllyAdmin === "true" &&
                                <div className="applications_filter-searchContainerList applications_filter-searchContainerList-borderRadius">
                                    <input type="text" placeholder="Search by DealerName" value={termSearchList} className="applications_filter-searchInput" onChange={(e)=>{setTermSearchList(e.target.value)}}/>
                                    <span className="applications_filter-searchCloseIcon" onClick={()=>setTermSearchList('')}>x</span>
                                </div>
                            }
                            {/* <div className="applications_filter-searchContainerList applications_filter-searchContainerList-borderRadius">
                                <input type="text" placeholder="Search by DealerName" value={termSearchList} className="applications_filter-searchInput" onChange={(e)=>{setTermSearchList(e.target.value)}}/>
                                <span className="applications_filter-searchCloseIcon" onClick={()=>setTermSearchList('')}>x</span>
                                </div> */}
                            

              
                          

                            {/* DATE PICKER */}
                            <div className="datePicker">
                                <button onClick={() => setOpenDatePicker(!openDatePicker)} className={isDeallerAllyAdmin === "true" ? "reports-datePicker-button margin-left-10px" : "reports-datePicker-button "}>
                                    <span>{Object.keys(dateRange).length === 0  ? 'DA Funding Date' : `${moment(dateRange.startDate).format('YYYY/MM/DD') } - ${moment(dateRange.endDate).format('YYYY/MM/DD') }`}</span>
                                    <span className="applications_filter-searchCloseIcon" onClick={()=>setDateRange({})}>x</span>
                                </button>
                              
                                <DateRangePicker
                                        open={openDatePicker}
                                        toggle={()=>setOpenDatePicker(!openDatePicker)}
                                        // onChange={(range) => setDateRange({startDate: moment(range.startDate).format('YYYY/MM/DD h:mm'), endDate: moment(range.endDate).format('YYYY/MM/DD h:mm')})}
                                        onChange={(range) => setDateRange({startDate: moment(range.startDate).format('YYYY-MM-DD'), endDate: moment(range.endDate).format('YYYY-MM-DD')})}
                                    />
                            </div>


                           

                            {/* LENDER FILTER */}
                            <div className="application_contaner-filterSelect">
                                <select onChange={(e)=>{setFilterFIValue(e.target.value)}} className="aplications-header-buttoms_filter" value={filterFIValue}>
                                    <option value="" disabled selected>All Lenders</option>
                                    <option value="">All Lenders</option>
                                    <option value="Aqua Finance">AquaFinance</option>
                                    <option value="Pinnacle Finance">Pinnacle</option>
                                </select>
                            </div>

                    </>
                }
                
            </div>

          

        </div>


        {/**********************************************  VERSION 2 ***************************************************/}
        
       
           
            <ReportList 
                termSearch={termSearchList} 
                searchBy={searchBy} 
                filterStatusValue={filterStatusValue}
                filterFIValue={filterFIValue}
                refreshGetApplicant={refreshGetApplicant}
                filterStatusSelected={filterStatusSelected}
                dateRange={dateRange}
                setNetProceedsTotal={setNetProceedsTotal}
            />
        
        

 

      
        

        
        
        
       


       



       

       
            









    </div>
    </>
)
}

export default RemittanceReport;



