import React, { createContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import { resolve } from 'q';
require('dotenv').config();




export const AuthContext = createContext();

const AuthProvider = ({ children}) => {

    const history = useHistory();

    const [isAuth, setIsAuth] = useState(()=>{
        return window.localStorage.getItem('token');
    });

    const [user, setUser] = useState('');

    const [reloadFetchFromContext, setReloadFetchFromContext] = useState(false)
    const [errorFlagFromContext, setErrorFlagFromContext] = useState(false)
    const [firstReloadFunction, setFirstReloadFunction] = useState(false)
  

    const [uri, setUri] = useState({
        URI_PROTOCOL : 'https://',
        URI_BASE : 'dealerallyapi.com/',
        URI_ENV : `${process.env.REACT_APP_BUILD_ENV}.`,
        URI_VERSION : 'v1/',
        URI_APPLICATION : 'application/',
        URI_USERS : 'users/',
        URI_GET_USER : 'get-user',
        URI_AUTHENTICATE : 'authenticate-user',
        URI_GET_APPLICATION_DETAIL: 'get-details/',
        URI_GET_APPLICATION_STATUS : 'status/',
        URI_X_API_KEY : `${process.env.REACT_APP_API_KEY}`,
        URI_CREATE_USER : 'create-user/',
        URI_GET_PROMOTION : 'get-promotions',
        URI_SET_PROMOTION : 'set-promotion',
        URI_NEW_TOKEN : 'new-token',
        URI_DOCUMENTS : 'documents/',
        URI_DEALER : 'dealer/',
        URI_GET_PRODUCTS : 'get-products',
        URI_LIBRARY : 'library/',
        URI_LIBRARY_DOCUMENTS: 'documents/',
        URI_ROLES: 'roles/',
        URI_REPORTS: 'reports/',
        URI_GET_DEALER_ROLES : 'get-dealer-roles',
        URI_ASSIGN_ROLE :'assign-role',
        URI_GET_ALL_DEALERS : 'get-all-dealers',
        URI_GET_DEALER_DETAIL : 'get-details',
        URI_UPDATE_USER : 'update-user',
        URI_UPDATE_PERMISSIONS : 'update-permissions',
        URI_FORGOT_PASSWORD : 'forgot-password',
        URI_RESET_PASSWORD : 'reset-password',
        URI_GET_APPLICANTS : 'get-applicants',
        URI_GET_APPLICATIONS_BY_ID : 'get-applications-by-applicant-id/',
        URI_CREATE_STUB_APPLICATION: 'create-stub-application',
        URI_GET_APPLICANT_DETAILS : 'get-applicant-details/',
        URI_GET_FINANCIAL_INSTITUTIONS : 'get-financial-institutions',
        URI_CONTRACT_COMPLETION : 'contract-completion/',
        URI_CONTRACT_COMPLETION_RATE : 'contract-completion-rate',
        URI_UPDATE_FI_PERMISSIONS : 'update-fi-permissions',
        URI_GET_DEALER_APPLICATIONS : 'get-dealer-applications',
        URI_GENERATE_CUSTOMER_LINK : 'generate-customer-link',
        URI_CUSTOMER_FORM : 'customer-form/',
        URI_GET_PROSPECT_DETAIL : 'get-prospect-details',
        URI_GET_PROSPECT_APPLICANTS : 'get-prospect-applicants',
        URI_GET_PROSPECT_APPLICATIONS_BY_APPLICANT_ID : 'get-prospect-applications-by-applicant-id/',
        

        
    });

    const [proposalInfo, setProposalInfo] = useState({
        info : {},
        hasInfo : false
    })

    const [openSheet, setOpenSheet] = useState(false)
    const userName = window.localStorage.getItem('user');
    const refreshToken = window.localStorage.getItem('refreshToken');  

    const value = {
        isAuth,

        user,

        uri,

        reloadFetchFromContext,

        errorFlagFromContext,

        setReloadFetchFromContext,

        setErrorFlagFromContext,

        firstReloadFunction,

        setProposalInfo,

        proposalInfo,

        openSheet,

        setOpenSheet,

        changeEnv: (val) =>{
            setUri({
                ...uri,
                URI_ENV : val,

            })
        },
        
        



        activateAuth: (token, user, refreshToken, dealerId, accessToken, userEmail, userRole, hideBuyRate) =>{
            setIsAuth(true); 
            window.localStorage.setItem('token', token);
            window.localStorage.setItem('user', user);
            window.localStorage.setItem('refreshToken', refreshToken);
            window.localStorage.setItem('dealerId', dealerId);
            window.localStorage.setItem('accessToken', accessToken);
            window.localStorage.setItem('userEmail', userEmail);
            window.localStorage.setItem('userRole', userRole);
            window.localStorage.setItem('hideBuyRate', hideBuyRate ? 'true' : 'false');
            setUser(user);   
            history.replace(`/applicants`);        
        },
         
        

        logout: () =>{
            setIsAuth(false);
            window.localStorage.clear();
        },



        changeToken : () =>{

            return new Promise((resolve, reject) =>{

                        //console.log('SE VENCIO EL PRIMER TOKEN')
            
                        const form = {
                            "username" : userName,
                            "refresh_token" : refreshToken,
                            
                        }
                        const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
                
                        window.fetch(URI_REFRESHTOKEN,{
                        method: 'POST',
                        headers: {           
                        'Content-Type': 'application/json',  
                        'x-api-key' : uri.URI_X_API_KEY,         
                        },
                        body: JSON.stringify(form),
                        })
                        .then(response => response.json())
                        .then(datos => { 
                            //console.log(datos)

                            if(datos.status === 'FORCE_LOGOUT'){
                                console.log('entra')
                                setIsAuth(false);
                                window.localStorage.clear();
                                window.location.reload()
                                return
                            }
                            window.localStorage.removeItem('token');
                            window.localStorage.removeItem('accessToken');
                            const newToken = datos.id_token;
                            const newAccessToken = datos.access_token;
                            window.localStorage.setItem('token', newToken);
                            window.localStorage.setItem('accessToken', newAccessToken);
                            // console.log('REPLACE TOKEN FROM CONTEXT');
                            //console.log(datos)

                            setErrorFlagFromContext(true)
                            setFirstReloadFunction(true)
                            setReloadFetchFromContext(!reloadFetchFromContext)
                            
                            // resolve('ok')
       
                        }).catch(error => {
                            // console.log(error)
                            setErrorFlagFromContext(false)
                            reject('error')
                        })

            })
            



        },

        




    }

    return(
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthConsumer = AuthContext.Consumer
export {
    AuthProvider, AuthConsumer
}