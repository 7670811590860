import React, { useState, useContext, useEffect } from 'react';
import '../assets/styles/EditUser.css';
import {AuthContext} from '../AuthContext';
import Spinner from 'react-spinner-material';
import NotificationSettings from './settings/NotificationSettings';
import UserPersonalInformation from './settings/UserPersonalInformation';
import AlertBox  from './AlertBox';


const EditUser = (props) =>{

    const{uri} = useContext(AuthContext);

    const [reloadGetUserDetail, setReloadGetUserDetail] = useState(false);

    const [userData, setUserData] = useState({});
    const [userPermissions, setUserPermissions] = useState([])
    const [hasData , setHasData] = useState(false);
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState(false)
   
    const [errorPassword, setErrorPassword] = useState(false);
    const [loadingChangePassword, setLoadingChangePassword] = useState(false);
    const [passwordChangedSuccess, setPasswordChangedSuccess] = useState(false);

    const [showConfirmationPopUpDelete, setShowConfirmationPopUpDelete] = useState(false);
    const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);
    const [userWasDeleted, setUserWasDeleted] = useState(false);

    const [loadingChangeActiveInactive, setLoadingChangeActiveInactive] = useState(false)

    const [loadingChangeRole, setLoadingChangeRole] = useState(false);

    const [isActive, setIsActive] = useState(false)

    const [isLoadingDetailUser, setIsLoadingDetailUser] = useState(false);

    const [errorIndex, setErrorIndex] = useState(false);

    const [showErrorInactiveUser, setShowErrorInactiveUser] = useState(false);

    const [showErrorWaitingForValidation, setShowErrorWaitingForValidation] = useState(false)
    const [changePasswordAlertBox, setChangePasswordAlertBox] = useState({show: false})

    //CHANGE PASSWORD
    const [newPassword, setNewPassword] = useState({})
    const [repeatNewPassword, setRepeatNewPassword] = useState({})
    const [errorDiferentPasswords, setErrorDiferentPasswords] = useState(false)
    const [passwordLengthError, setPasswordLengthError] = useState(false)

    //NOTIFICATIONS
    const [notificationInfo, setNotificationInfo] = useState({})

    //RECUPERAR VALORES DE SESSIONSTORAGE Y CONTEXTAPI
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const refreshToken = window.localStorage.getItem('refreshToken');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;





    //FETCH GET USER DETAIL
  useEffect(()=>{
    setIsLoadingDetailUser(true)

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_GET_USER}`
    // console.log(URI)

    const username = props.username;
    const form = {username: username}


    const abortController = new AbortController();
    const signal = abortController.signal;


    window.fetch(URI,{
        method: 'POST',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'Authorization' : token, 
            'DealerId' : props.userDealerId,  
            'x-api-key' : URI_X_API_KEY_I,   
          },
        body: JSON.stringify(form),
    })
    .then(response => response.json())
    .then(datos => {
        setIsLoadingDetailUser(false)
       
        //console.log(datos);


            if(datos.status === 'USER_NOT_FOUND' || datos.status === 'INTERNAL_SERVER_ERROR'){
                console.log('USER NOT FOUND')
            }else{
                setUserData(datos);
                setUserPermissions(datos.permissions)
                setHasData(true)
            }
            

            if(datos.status === 'Active' || datos.status === 'ResetPasswordRequired' || datos.status === 'WaitingForActivation'){
                //isActive.setAttribute("checked", "checked");
                document.getElementById("isActive").checked = true;
                setIsActive(true)
            }

            //NOTIFICATIONS
            setNotificationInfo({
                notificationsActive: datos.notificationsActive,
                notificationsStatuses: datos.notificationsStatuses,
    
            })
            

            //document.getElementById("myCheckboxId").checked = true;
            
            setErrorIndex(false)
        

    }).catch(error => {
        console.log(error)

                if(errorIndex === false){
                    // console.log('SE VENCIO EL PRIMER TOKEN')
                    

                    const data = {
                        "username" : user,
                        "refresh_token" : refreshToken,
                        
                    }
                    const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;

                    window.fetch(URI_REFRESHTOKEN,{
                    method: 'POST',
                    headers: {           
                    'Content-Type': 'application/json',  
                    'x-api-key' : URI_X_API_KEY_I,         
                    },
                    body: JSON.stringify(data),
                    })
                    .then(response => response.json())
                    .then(datos => { 
                        // console.log(datos)
                        window.localStorage.removeItem('token');
                        const newToken = datos.id_token;
                        window.localStorage.setItem('token', newToken);
                        // console.log('SE REEMPLAZO EL TOKEN VIEJO POR EL NUEVO');
                        setReloadGetUserDetail(!reloadGetUserDetail);
                        setErrorIndex(true)
                    })
                }
    });
    

    return function cleanup(){
        abortController.abort();
    }
    
},[reloadGetUserDetail, passwordChangedSuccess]);




  //GET ROLES
  useEffect(()=>{

      //setIsLoadingGetRoles(true);

      const abortController = new AbortController();
      const signal = abortController.signal;
  
  
      const URI_GET_DEALER_ROLES = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_ROLES}${uri.URI_GET_DEALER_ROLES}`;
    //   console.log(URI_GET_DEALER_ROLES )
  
      window.fetch(URI_GET_DEALER_ROLES ,{
      //method: 'GET',
      signal: signal,
      headers: {           
      'Content-Type': 'application/json',  
      'x-api-key' : URI_X_API_KEY_I, 
      'DealerId' : props.userDealerId, 
      'Authorization' : token,  
      'username' : user,       
      }, 
      

      })
      .then(response => response.json())
      .then(datos => { 
        //setIsLoadingGetRoles(false)

        //   console.log(datos)

          //setRoles(datos)


          if(datos.hasOwnProperty('STATUS') || datos.hasOwnProperty('status')){
            console.log('NO HAY ROLES DISPONIBLES O HAY ALGUN PROBLEMA')
          }else{
            setRoles(datos)
          }
          
      })
  
      return function cleanup(){
      abortController.abort();
      }

  }, []);


  const changeRole =()=>{
      
    setLoadingChangeRole(true)

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_ASSIGN_ROLE}`;
    // console.log(URI)

    const body = {
        username : userData.username, 
        role : newRole.newRole
     }
    //  console.log(body)

    window.fetch(URI ,{
    method: 'POST',
    headers: {           
    'Content-Type': 'application/json',  
    'x-api-key' : URI_X_API_KEY_I, 
    'DealerId' : props.userDealerId, 
    'Authorization' : token,         
    }, 
    body: JSON.stringify(body),
    
    })
    .then(response => response.json())
    .then(datos => { 
        setLoadingChangeRole(false)
  
        // console.log(datos);

        if(datos.status === 'SUCCESS'){
            setReloadGetUserDetail(!reloadGetUserDetail);
            props.reload();
        }
        
        
    })


  }



  const ActivateOrDeactivateUser =(event)=>{

    /*if(true){
        event.target.setAttribute("checked", "checked");
        
    }*/
    setIsActive(!isActive)

    

    if(!isActive === true){
        // console.log('CAMBIADO EN ESTADOA A ACTIVADO')
        setLoadingChangeActiveInactive(true)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}activate-user`;
            // console.log(URI)

            const body = {username : userData.username}
            // console.log(body)

            window.fetch(URI ,{
            method: 'POST',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : URI_X_API_KEY_I, 
            'DealerId' : props.userDealerId, 
            'Authorization' : token,         
            }, 
            body: JSON.stringify(body),
            
            })
            .then(response => response.json())
            .then(datos => { 
            //setIsLoadingGetRoles(false)
            setLoadingChangeActiveInactive(false)
               
                // console.log(datos);

                if(datos.status === 'SUCCESS'){
                    setReloadGetUserDetail(!reloadGetUserDetail);
                    props.reload();
                }
                
                
                
            })


    }else if(!isActive === false){
        // console.log('CAMBIADO EN ESTADOA A DESACTIVADO');
        setLoadingChangeActiveInactive(true)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}deactivate-user`;
            // console.log(URI)

            const body = {username : userData.username}
            // console.log(body)

            window.fetch(URI ,{
            method: 'POST',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : URI_X_API_KEY_I, 
            'DealerId' : props.userDealerId, 
            'Authorization' : token,         
            }, 
            body: JSON.stringify(body),
            
            })
            .then(response => response.json())
            .then(datos => { 
                setLoadingChangeActiveInactive(false)
                // console.log(datos);

                if(datos.status === 'SUCCESS'){
                    setReloadGetUserDetail(!reloadGetUserDetail);
                    props.reload();
                }
                
                
                
            })
    }
   
  }


  const ChangePassword = ()=>{

        setChangePasswordAlertBox({show:false})

        if(newPassword.newPassword !== repeatNewPassword.repeatNewPassword){
            setErrorDiferentPasswords(true)
            return 
        }
        // else if((newPassword.newPassword && newPassword.newPassword.length > 100) || (repeatNewPassword.repeatNewPassword && repeatNewPassword.repeatNewPassword.length > 100)){
        //     setPasswordLengthError(true)
        //     setErrorDiferentPasswords(false)
        //     return
        // }
        setErrorDiferentPasswords(false)
        setPasswordLengthError(false)
        setLoadingChangePassword(true)
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}admin-change-password`;
        console.log(URI)

        const body = {
            username : userData.username,
            new_password: newPassword.newPassword
        }
        console.log(body)

        window.fetch(URI ,{
        method: 'POST',
        headers: {           
        'Content-Type': 'application/json',  
        'x-api-key' : URI_X_API_KEY_I, 
        //'DealerId' : dealerId, 
        'Authorization' : token,         
        }, 
        body: JSON.stringify(body),
        
        })
        .then(response => response.json())
        .then(datos => { 
            setLoadingChangePassword(false)
   
            // console.log(datos);

            // if(datos.status === 'ERROR' || datos.status === 'INCORRECT_PASSWORD'){
            //     setShowErrorInactiveUser(false)
            //     setErrorPassword(true)
            // }else if(datos.status === 'SUCCESS'){
            //     setErrorPassword(false)
            //     setShowErrorInactiveUser(false)
            //     setShowErrorWaitingForValidation(false)
            //     setPasswordChangedSuccess(true)
            // }else if(datos.status === 'INACTIVE_USER'){
            //     setShowErrorInactiveUser(true)
            // }else if(datos.status === 'USER_WAITING_FOR_ACTIVATION'){
            //     setShowErrorWaitingForValidation(true)
            // }

            if(datos.status === 'SUCCESS'){
                setChangePasswordAlertBox({ 
                  title: 'Password changed successfully.',
                  type: 'success',
                  show: true
                })
            }else{
                setChangePasswordAlertBox({ 
                    title: datos.Message,
                    text: datos.Errors && datos.Errors[0],
                    type: 'error',
                    show: true
                })
            }
            
            
            
        })

  }


  const DeleteUser = ()=>{

    setIsDeleteUserLoading(true)
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}delete-user`;
        console.log(URI)

        const body = {username : userData.username}

        console.log(body)

        window.fetch(URI ,{
        method: 'POST',
        headers: {           
        'Content-Type': 'application/json',  
        'x-api-key' : URI_X_API_KEY_I, 
        'DealerId' : props.userDealerId, 
        'Authorization' : token,         
        }, 
        body: JSON.stringify(body),
        
        })
        .then(response => response.json())
        .then(datos => { 
            setIsDeleteUserLoading(false)
            console.log('RESPUESTA DE DELETE USER')
            console.log(datos);

            if(datos.status === 'SUCCESS'){
                setUserWasDeleted(true)
            }
            
            
            
        })

  }



  const confirmationPopUp=()=>{

    const reload = ()=>{
        props.reload(); 
        props.handler()
    }
        
    return(
        <div className="confirmationPopUp">
            <div className="confirmationPopUp_spaceBlack"></div>
            <div className="confirmationPopUp_content">

                {userWasDeleted ? 

                <div className="confirmationPopUp_content">
                <h2 className="confirmationPopUp_message">User was deleted successfully</h2>

                <button className="confirmationPop_confirm confirmationPop_confirm_done" onClick={reload}>Done</button>

                </div>   

                :


                <div className="confirmatinPopUp_choose">

                

                        <h2 className="confirmationPopUp_message">Are you sure you want to delete this user?</h2>
                        <div className="confirmationPopUp_buttons">
                            {isDeleteUserLoading ? 
                            <button className="confirmationPop_confirm-spinner">
                            <Spinner
                                size={16}
                                color={"white"}
                                spinnerWidth={3}
                                visible={true}
                                />
                            </button>
                            :
                            <button className="confirmationPop_confirm" onClick={DeleteUser}>Confirm</button>

                            }
                            {isDeleteUserLoading ? null
                            :
                            <button className="confirmationPop_cancel" onClick={()=>{setShowConfirmationPopUpDelete(false)}}>Cancel</button>
                            }
                            

                        </div>
                </div>

                
                }

            </div>
        </div>
    )
}


    return(
        <>

                

        <div className="PopUp-EditUser" >
            <div className="EditUser-spaceBlack" onClick={props.handler}></div>

                <div className="EditUserContainer">

                    {/* USER INFO */}
                    <h2>Edit User</h2>


                    <div className="EditUser_GeneralInfo">
                        <UserPersonalInformation isLoadingUser={isLoadingDetailUser} data={userData} showExtraFields reloadGetUserData={setReloadGetUserDetail} >
                            <div className="DetailUser_Status">
                                <div className={userData.status === 'Active' ? "DetailUser_Status-Point-Active" : "DetailUser_Status-Point-disabled"}></div><span>{userData.status}</span>
                            </div>
                        </UserPersonalInformation>  
                    </div>
                    
                   

                    {/* EDIT ROLE */}
                    <div>
                        <h3>Edit Role</h3>
                        
                            <label className="labelApplicationForm">Role</label>
                            <select
                                name="role"
                                onChange={(event)=>{setNewRole({newRole : event.target.value})}}
                                className="campo"
                                placeholder="Choose a Role"
                                value={newRole ? newRole.newRole : userData.role_id}
                                required>
                                <option value="disable" disabled selected>Role</option>
                                
                                {roles.map((item, index)=>{
                                        return(
                                        <option value={item.code} key={index}>{item.name}</option> 
                                        )
                                })}


                            </select>
                            {
                                loadingChangeRole ? 
                                <button className="changeRole-button" onClick={changeRole} disabled>Change Role</button>         
                                :
                                <button className="changeRole-button" onClick={changeRole}>Change Role</button>
                               
                            }
                           
                            
                    </div>




                    {/* {userData.status === 'WaitingForActivation' ? 
                        <div className="activateUser">
                            <h3>User Status</h3>
                            <p>The user is pending for activation in AquaFinance systems.</p>
                        </div>
                    : userData.status === 'ResetPasswordRequired' ?
                        <div className="activateUser">
                            <h3>User Status</h3>
                            <p>User pending to reset password</p>
                        </div>
                    :
                    <div className="activateUser">
                        {isActive ? <h3>Deactivate user</h3> :  <h3>Activate User</h3>}
                        <label className="switch">
                        <input type="checkbox" name="isActive" {...loadingChangeActiveInactive && { disabled: "disabled" } }  id="isActive" onClick={ActivateOrDeactivateUser} />
                        <span className="slider round"></span>
                        </label>
                    </div>
                    } */}
                    <div className="activateUser">
                        {isActive ? <h3>Deactivate user</h3> :  <h3>Activate User</h3>}
                        <label className="switch">
                        <input  type="checkbox" 
                                name="isActive" {...loadingChangeActiveInactive && { disabled: "disabled" } }  
                                id="isActive" 
                                onClick={ActivateOrDeactivateUser}
                        />
                        <span className="slider round"></span>
                        </label>
                    </div>


                    

                    


                    {/* PASSWORD */}
                    <div >
                        <h3 className="editUser_subtitle">Change Password</h3>
                        
                        <input type="password" 
                        className="campo" 
                        name="newPassword" 
                        placeholder="New password"
                        onChange={(event)=>{setNewPassword({newPassword : event.target.value})}} 
                        autoComplete="new-password"
                        />

                        <input type="password" 
                        className="campo" 
                        name="newPassword" 
                        placeholder="Repeat New password"
                        onChange={(event)=>{setRepeatNewPassword({repeatNewPassword : event.target.value})}} 
                        autoComplete="new-password"
                        />

                        {/* {errorDiferentPasswords && <p className="errorPassword">Password does not match</p>}
                        {passwordLengthError && <p className="errorPassword">The password must have a maximum of 100 characters</p>} */}

                        <AlertBox 
                            showAlert={changePasswordAlertBox.show}
                            // showAlert={true}
                            type={changePasswordAlertBox.type}
                            title={changePasswordAlertBox.title}
                            text={changePasswordAlertBox.text}
                        />

                        {loadingChangePassword ? 
                            <div className="loadingButton" onClick={ChangePassword}>
                            <Spinner
                                radius={20}
                                color={"white"}
                                stroke={3}
                                visible={true}
                                />
                            </div>
                        :

                        <button className="changeRole-button" onClick={ChangePassword}>Change Password</button>
                        }
                        
                        
                        
                       
                        {errorPassword ? 
                        <p className="errorPassword">Password requires numbers, special character, uppercase and lowercase letters, and minimum 8 characters length.</p>
                        : null}

                        {passwordChangedSuccess ? 
                        <p className="passwordChangedOkMessage">Password changed successfully.</p>
                        : null}

                        {showErrorInactiveUser ? 
                        <p className="errorPassword">The user is inactive.</p>
                        : null}

                        {showErrorWaitingForValidation ? 
                        <p className="errorPassword">The user is Waiting for activation.</p>
                        : null}     


                        
                    </div>



                    
                    {/* <button className="deleteUserButton" onClick={()=>{setShowConfirmationPopUpDelete(true)}}>Delete User</button> */}
                    
                    {/* {showConfirmationPopUpDelete ? confirmationPopUp() : null} */}


                    {/* NOTIFICATIONS */}
                    <div className="activateUser">
                    <h3 className="editUser_subtitle">User Notifications</h3>
                    <NotificationSettings noPadding notificationInfo={notificationInfo} username={props.username}/>

                    </div>


                    
                    

            </div>

        </div>
        </>
    )
}

export default EditUser;