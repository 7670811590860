import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../AuthContext';
import useAlertBox from "../../hooks/useAlertBox";
import AlertBox from "../AlertBox";
import Button from '../general/Button';

const DocumentsForm = ({ type, commercialLoanApplicationId, setStep, step }) => {

    const { uri } = useContext(AuthContext);
    const errors = {}
    const [documentForm, SetDocumentForm] = useState({});
    const [hasdocuments, SetHasDocuments] = useState(false);
    const [documentsList, SetDocumentsList] = useState([]);
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);
    const [isSubmittingDocument, setIsSubmittingDocument] = useState(false)
    const [reloadGetDocuments, SetReloadGetDocuments] = useState(false);
    const [messageDocumentTypeError, setMessageDocumentTypeError] = useState()
    const [showNotificationTypeDocumentError, SetShowNotificationTypeDocumentError] = useState(false);
    const [errorsdocument, setErrorsDocument] = useState({});
    const [isLoading, setIsLoading] = useState(false)

    const [documentsShowAlert, setDocumentsShowAlert] = useAlertBox({
        autoClose: true,
        autoCloseTimeout: 5000,
        initialShow: false
    })

    const [documentsAlertBox, setDocumentsAlertBox] = useState({
        title: '',
        text: '',
        type: ''
    })

    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const token = window.localStorage.getItem('token');




    // GET DOCUMENTS
    useEffect(() => {
    
        if (commercialLoanApplicationId) {
        
            setIsLoadingDetails(true);
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application/${commercialLoanApplicationId}/documents`;


            const abortController = new AbortController();
            const signal = abortController.signal;


            window.fetch(URI, {
                method: 'GET',
                signal: signal,
                headers: {
                    'Access-Control-Request-Headers': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'x-api-key': uri.URI_X_API_KEY,
                }
            })
                .then(response => response.json())
                .then(datos => {
                    setIsLoadingDetails(false);

                    // console.log(datos)

                    if (datos.length === 0) {

                        SetHasDocuments(false);
                    } else if(datos.status === 'ERROR'){
                        SetHasDocuments(false);
                    }
                    else {
                        SetHasDocuments(true);
                        SetDocumentsList(datos);
                    }

                })

            return function cleanup() {
                abortController.abort();
            }
        }
    }, [commercialLoanApplicationId, reloadGetDocuments]);




    //FUNCION PARA DESCARGAR ARCHIVOS
    function showFile(blob, documentName) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        // console.log(blob)
        var newBlob = new Blob([blob], { type: 'application/pdf' })


        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = documentName;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }


    //DOWLOAD DOCUMENT
    const dowloadDocument = (fileId, documentName) => {

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application/${commercialLoanApplicationId}/documents/${fileId}`;
        // console.log(URI)
        window.fetch(URI, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'x-api-key': uri.URI_X_API_KEY,
            }
        }).then(r => r.blob())
            .then(blob => {
                //console.log(documentName)
                showFile(blob, documentName)
            })


    }


    //POST DOCUMENT
    const sendDocument = () => {
        setIsLoading(true);
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application/${commercialLoanApplicationId}/documents`;

        let data = new FormData()
        data.append('document-type', documentForm.DocumentFileType);
        data.append('file', documentForm.File);

        //console.log(data)

        window.fetch(URI, {
            method: 'PUT',
            headers: {
                'Authorization': token,
                'x-api-key': uri.URI_X_API_KEY,
            },
            body: data
        }).then(response => response.json())
            .then(datos => {
                setIsLoading(false);

                // console.log(datos)
                if (datos.status === 'SUCCESS') {
                    SetReloadGetDocuments(true)
                    SetDocumentForm({});
                    // SetShowPostNotificationSucces(true);

                    delete documentForm.DocumentFileType;
                    delete documentForm.File;

                    setDocumentsShowAlert(true)
                    setDocumentsAlertBox({
                        title: 'The document was successfully uploaded',
                        type: 'success'
                    })
                } else if (datos.status === 'DOCUMENT_TYPE_NOT_ACCEPTED') {
                    setDocumentsShowAlert(true)
                    setDocumentsAlertBox({
                        title: 'Type of document not accepted',
                        text: datos.messages[0],
                        type: 'error'
                    })

                } else if (datos.status === 'FILESIZE_TOO_BIG') {
                    setDocumentsShowAlert(true)
                    setDocumentsAlertBox({
                        title: 'File size is too big',
                        text: datos.messages[0],
                        type: 'error'
                    })

                } else if (datos.status === 'FAILED_UPLOADING_DOCUMENT_TO_LENDER') {
                    setDocumentsShowAlert(true)
                    setDocumentsAlertBox({
                        title: 'Failed uploading document to Aqua',
                        text: 'Please email file to info@dealerallyprogram.com with the subject “File failed to upload to Aqua” and the loan number.',
                        type: 'error'
                    })

                }

            }).catch(error => {
                // console.log(error)
                setIsLoading(false);

                setMessageDocumentTypeError('An unexpected error has occurred, please try again')
                SetDocumentForm({});
                delete documentForm.DocumentFileType;
                delete documentForm.File;
            })
    }



    useEffect(() => {
        if (Object.keys(errorsdocument).length === 0 && isSubmittingDocument) {
            sendDocument();
        }
    }, [errorsdocument]);


    //MANEJADORES DE FORM DE DOCUMENTS
    const handleDocumentForm = event => {
      let value;
      let fileType;

      if(event.target.name === 'File'){

        value = event.target.files[0];
        fileType = value.type;

        if(fileType.includes('image/') || fileType.includes('application/pdf')  || fileType.includes('audio/')){
          if(fileType.includes('application/pdf')){
            if(value.size > 8388608){
              SetShowNotificationTypeDocumentError(true)
              setMessageDocumentTypeError('Info: You can only upload files up to 8MB')
              value = null
            }        
          }else if(fileType.includes('image/') ){
            if(value.size > 8388608 ){
              SetShowNotificationTypeDocumentError(true)
              setMessageDocumentTypeError('Info: You can only upload files up to 8MB')
              value = null
            }  
            
          }
        }else{
            SetShowNotificationTypeDocumentError(true)
            setMessageDocumentTypeError('Info: You can only upload PDF, Audio and Image files')
            value = null
        }
       


        
        
      }else if(event.target.name === 'DocumentFileType'){
        value = event.target.value;
      }
    
      SetDocumentForm({
        ...documentForm,
        [event.target.name]: value,       
    })
    }

    //submit documents
    const handleSubmitDocument = event => {
      event.preventDefault();
      //handle errors
      setErrorsDocument(validateFormDocument(documentForm));
      setIsSubmittingDocument(true);
      //console.log(documentForm)
      
    };

    //validar el formulario de documentos
    const validateFormDocument = (data) => {
      let errorListDocument = {};
  
      //dealerid - check
      if (!data.DocumentFileType) {
        errorListDocument.DocumentFileType = "Please select a option";
      }

      if (!data.File) {
        errorListDocument.File = "Please select a file";
      }
      return errorListDocument;
      };



    return (
        <>
            <div className="documentSectionContent">

                <AlertBox 
                    showAlert={documentsShowAlert}
                    type={documentsAlertBox.type}
                    title={documentsAlertBox.title}
                    text={documentsAlertBox.text}
                />

                <p style={{marginBottom: '20px'}}>Must upload a quote or an invoice.</p>
               
                <div className="">
                    <span className="documentSection-title">Upload Document</span><span className="labelApplicationForm">      * Only .pdf and images accepted</span>
                    <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Document Type</label>
                        <select
                            name="DocumentFileType"
                            onChange={handleDocumentForm}
                            className={`${errors.DocumentFileType ? "imputError" : "campo"}`}
                            placeholder="File Type"
                            required

                        >
                            <option value="disable" disabled selected>
                                Document type
                            </option>
                            <option value="AC">Applicant Contract (AC)	</option>
                            <option value="CC">Completion Certificate (CC)</option>
                            <option value="ID">Identification (ID)</option>
                            <option value="MA">Monitoring/Customer Agreement (MA)	</option>
                            <option value="PH">Proof Of Homeownership (PH)</option>
                            <option value="PI">Proof Of Income (PI)</option>
                            <option value="SE">Schedule Of Equipment (SE)</option>
                            <option value="SV">Signal Verification (SV)</option>
                            <option value="BS">Bank Statement (BS)</option>
                            <option value="QI">Quote/Invoice (QI)</option>
                            <option value="O">Other (O)</option>


                        </select>
                        {errorsdocument.DocumentFileType && <p className="errorMessage">{errorsdocument.DocumentFileType}</p>}
                    </div>

                    <div className="choseDocumentSection">
                        <div className="selectFile">
                            <span className="labelSelectFile" >Choose</span>
                            <input
                                type="file"
                                className="documentChoseButtom"
                                max-size="2000"
                                name="File"
                                onChange={handleDocumentForm} />

                        </div>
                        {documentForm.File ? <span className="choseDocumentSection-span_filename">{documentForm.File.name}</span> : null}
                    </div>
                    {errorsdocument.File && <p className="errorMessage">{errorsdocument.File}</p>}


                    {isLoadingDetails ? <button disabled className="document-sendFile_button submit campo submitDisabled">Upload Document</button>
                        :
                        <div  style={{marginTop: '15px'}}>
                            <Button
                                onClick={handleSubmitDocument}
                                value="Upload Document"
                                isLoading={isLoading}
                            />
                        </div>
                        // <button className="document-sendFile_button submit campo" onClick={handleSubmitDocument} >Upload Document</button>
                    }



                </div>




            </div>

            <div className="documentSection" style={{width: '100%'}}>
                <span className="documentSection-title">Uploaded Documents</span>




                {hasdocuments ?

                    documentsList.map((item, index) => {
                        return (
                            <div className="document-card" key={index}>
                                <div className="document-card_info">
                                    <span className="document-card_info-type document-card_info-span">{item.Type}</span>
                                    <span className="document-card_info-fileName document-card_info-span">{item.Filename}</span>
                                    <div className="document-card_info-idAndDdate">
                                        <span className="document-card_info-id document-card_info-span">{item.Id}</span>
                                        <span className="document-card_info-date document-card_info-span">{item.CreationDate}</span>
                                    </div>

                                </div>
                                <div className="document-card_button">
                                    {isLoadingDetails ?
                                        <>
                                            <button className="document-card_button-dowload">Download</button>
                                            {
                                                isDeallerAllyAdmin === "true" ?
                                                    <button className="document-card_button-delete">Delete</button>
                                                    : null
                                            }
                                        </>
                                        :
                                        <>
                                            <button className="document-card_button-dowload" onClick={() => { dowloadDocument(item.Id, item.Filename) }}>Download</button>
                                            
                                        </>
                                    }

                                </div>
                            </div>
                        )



                    })

                    :
                    <p className="documentSection-title">This application has no documents</p>

                }

            </div>
        </>

    )
}

export default DocumentsForm