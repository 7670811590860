import React, {useState, useContext, useEffect} from 'react'
import "../../assets/styles/ApplicationForm.css";
import { AuthContext } from '../../AuthContext';
import AlertBox from "../AlertBox";
import Button from '../general/Button';

const AdminForm = ({ type, commercialLoanApplicationId, setStep, step, setCommercialLoanData, prefilledInfo, submitted, hasCompanyInfo }) => {

  const { uri, changeToken } = useContext(AuthContext);
  const [errors, setErrors] = useState(false)
  const [applicant, setApplicant] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [notificationError, setNotificationError] = useState({show: false})

  

  const user = window.localStorage.getItem('user');
  const token = window.localStorage.getItem('token');

  useEffect(()=>{
    if(prefilledInfo){
      // console.log(prefilledInfo)
      setApplicant(prefilledInfo)
    }
  },[prefilledInfo])


   //POST APPLICATION
   const submitForm = async (event) => {

    setIsLoading(true);



    //Armar el objeto que se envia
    const formData = {Submit: false, CommercialLoanApplicationId: commercialLoanApplicationId};
    formData.AdminCommercialInfo = {...applicant};


    console.log(formData)
    console.log(JSON.stringify(formData))

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application`;

  
     const headers = {
                    'Access-Control-Request-Headers': 'application/json',
                    'Content-Type': 'application/json',
                    'username': user,
                    'x-api-key': uri.URI_X_API_KEY,
                    'Authorization' : token, 
                }
    
    try {
        const rawResponse = await window.fetch(URI, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(formData),
        });
        setIsLoading(false);
        const resp = await rawResponse.json();
        // console.log(resp);

        if(resp.CommercialLoanApplicationId){
          setStep(state => ({...state, AdminCommercialInfo: true}))
          setCommercialLoanData(state => ({...state, AdminCommercialInfo:  formData.AdminCommercialInfo }))
        }else{
          setNotificationError({ 
            title: resp.Message,
            text: resp.Errors[0],
            type: 'error',
            show: true
          });
        }

    }
    catch (error) {
      setIsLoading(false)
      setNotificationError({ 
        title: 'Error',
        text: 'An unexpected error has occurred. Please try again.',
        type: 'error',
        show: true
      });
      if(error.status === 401){
        changeToken()
      }
   }

};


    const handleInputApplicant = (event) => {
    let value = event.target.value;

    setApplicant({
    ...applicant,
    [event.target.name]: value,
    })
    
    }


    const validateForm = (data) => {
    let errorList = {};

    if (!data.BestRateOption) {
      errorList.BestRateOption = "Please select an option";
    }

    if (!data.CommercialStatus) {
      errorList.CommercialStatus = "Please enter a response";
    }

    if (!data.CreditRequestWriteUp) {
        errorList.CreditRequestWriteUp = "Please enter a response";
      }

    return errorList;
    };


    const handleSubmit = event => {
        event.preventDefault();
        setErrors(validateForm(applicant));
        setIsSubmitting(true);
    };

    useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
        submitForm()
    } else {
        // console.log(errors)
        // console.log(applicant)
        //handleSubmitAllData();
    }

    }, [errors, isSubmitting]);


    const statusOptions = [
        { label: "Draft", value: "Draft" },
        { label: "Approved", value: "Approved" },
        { label: "Denied", value: "Denied" },
        { label: "Error", value: "Error" },
        { label: "Pending", value: "Pending" },
        { label: "Processing", value: "Processing", },
        { label: "FilledOut", value: "FilledOut" },
        { label: "SignReady", value: "SignReady" },
        { label: "Submitted", value: "Submitted" },
        { label: "ConditionallyApproved", value: "ConditionallyApproved" },
        { label: "Received", value: "Received" },
        { label: "PendingDocs", value: "PendingDocs" },
        { label: "InVerification", value: "InVerification" },
        { label: "InConfirmation", value: "InConfirmation" },
        { label: "Verified", value: "Verified" },
        { label: "ReadyToFund", value: "ReadyToFund" },
        { label: "FundingComplete", value: "FundingComplete" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "OnHold", value: "OnHold" },
        { label: "Problem", value: "Problem" },
        { label: "SendBack", value: "SendBack" },
        { label: "SignComplete", value: "SignComplete" },
      ];
    


  return (
      <form action="" id="form" className="form">


        {
          (applicant.CommercialStatus !== 'Draft' || submitted || hasCompanyInfo) &&
          <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Commercial Loan Status</label>
          <select
            value={applicant.CommercialStatus || 'selected'}
            name="CommercialStatus"
            onChange={handleInputApplicant}
            className={`${errors.CommercialStatus ? "imputError" : "campo"}`}
            placeholder="Commercial Status"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
                Commercial Status
            </option>
            {
                statusOptions.map(option => {
                    return <option value={option.value} key={option.value}>{option.value}</option>
                })
            }
            
            
          </select>
          {errors.BestRateOption && <p className="errorMessage">{errors.BestRateOption}</p>}
        </div>
        }




        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Best Rate Option</label>
          <select
            value={applicant.BestRateOption || 'selected'}
            name="BestRateOption"
            onChange={handleInputApplicant}
            className={`${errors.BestRateOption ? "imputError" : "campo"}`}
            placeholder="Best Rate Option"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
                Best Rate Option
            </option>
            <option value="BSB Direct Only">Direct Only</option>
            <option value="BSB Direct or Syndication">Direct or Syndication</option>
          </select>
          {errors.BestRateOption && <p className="errorMessage">{errors.BestRateOption}</p>}
        </div>


     
        


        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Broker Write-Up</label>
          <textarea
            type="text"
            name="CreditRequestWriteUp"
            onChange={handleInputApplicant}
            className={`${errors.CreditRequestWriteUp ? "imputError" : "campo"}`}
            value={applicant.CreditRequestWriteUp}
            disabled={(type === 'edit') ? "disabled" : null}
            required
            style={{height: '100px'}}
          />
          {errors.CreditRequestWriteUp && <p className="errorMessage">{errors.CreditRequestWriteUp}</p>}
        </div>

        <div className='mediowidth-right' style={{justifySelf: 'end'}}>
          <Button
           onClick={handleSubmit}
           value={(commercialLoanApplicationId && step.AdminCommercialInfo) ? "Save" : "Save and continue"}
           isLoading={isLoading}
           showButton={type === 'create'}
          />
        </div>

        <div className="fullwidth campoContainer">
          <AlertBox 
              showAlert={notificationError.show}
              type={notificationError.type}
              title={notificationError.title}
              text={notificationError.text}
          />
        </div>
      </form>
  )
}

export default AdminForm