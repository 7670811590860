import React, { useState, useEffect, useContext } from "react";
import "../assets/styles/ConvertProspect.css";
import { AuthContext } from "../AuthContext";
import ExternalForm from "./ExternalForm";
import ApplicationFormAquaFinance from "./ApplicationFormAquaFinance";
import ApplicationFormPinnacle from "./ApplicationFormPinnacle";
import Spinner from "react-spinner-material";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import AssignUserToApplicationByIframe from "./applicationsPage/AssignUserToApplicationByIframe";

const ConvertProspect = ({
  detailsId,
  appData,
  internal,
  financialInstitutions,
  applicantIdFromPostCommon,
  goToListView,
  urlParamApplicantId,
  setStep,
}) => {
  const {
    uri,
    reloadFetchFromContext,
    errorFlagFromContext,
    setErrorFlagFromContext,
    changeToken,
    setProposalInfo,
  } = useContext(AuthContext);

  const [applicationData, setApplicationData] = useState(appData || {});
  const [financialInstitutionList, setFinancialInstitutionList] = useState([]);

  const [aquaFormFinal, setAquaFormFinal] = useState({});
  const [pinnacleFormFinal, setPinnacleFormFinal] = useState({});
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [showForm, setShowForm] = useState("general");

  const token = window.localStorage.getItem("token");
  const user = window.localStorage.getItem("user");
  const userRole = localStorage.getItem("userRole");
  const isDeallerAllyAdmin = localStorage.getItem("is_dealerally_admin");
  const has_been_validated_in_aqua = window.localStorage.getItem(
    "has_been_validated_in_aqua"
  );
  const dealerIdCode = window.localStorage.getItem("dealerId");

  useEffect(() => {
    if (appData) {
      //console.log(appData)
      setApplicationData(appData);
      setFinancialInstitutionList(financialInstitutions);
    }
  }, [appData, financialInstitutions]);

  // GET APPLICATIONDETAIL DETAIL PARA CREAR APLICACION YA EXISTENTE
  useEffect(() => {
    if (detailsId) {
      setIsLoadingDetails(true);

      const abortController = new AbortController();
      const signal = abortController.signal;

      let URI;
      if (urlParamApplicantId) {
        URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GET_APPLICANT_DETAILS}${urlParamApplicantId}`;
      } else {
        URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${detailsId}/${uri.URI_GET_PROSPECT_DETAIL}`;
      }

      // console.log(URI)

      window
        .fetch(URI, {
          method: "GET",
          signal: signal,
          headers: {
            "Access-Control-Request-Headers": "application/json",
            "Content-Type": "application/json",
            username: user,
            Authorization: token,
            "x-api-key": uri.URI_X_API_KEY,
          },
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((datos) => {
          if (!urlParamApplicantId) {
            setIsLoadingDetails(false);
          }

          console.log(datos);

          if (datos.status === "INTERNAL_SERVER_ERROR") {
            // console.log('INTERNAL_SERVER_ERROR')
          } else {
            if (urlParamApplicantId) {
              setApplicationData({ Applicant: { ...datos } });
              setIsLoadingDetails(false);
            } else {
              setApplicationData(datos);
              setFinancialInstitutionList(datos.FinancialInstitutions);
            }
          }
          setErrorFlagFromContext(false);
        })
        .catch((error, response) => {
          console.log(error);
          if (errorFlagFromContext === false && error.status === 401) {
            changeToken()
              .then((response) => console.log(response))
              .catch((error) => console.log(error));
          }
        });

      return function cleanup() {
        abortController.abort();
      };
    }
  }, [reloadFetchFromContext]);

  //GET FINANCIALS INSTITUTIONS CUADROS BLANCOS
  useEffect(() => {
    if (!detailsId) {
      //setLoadingGetFinancials(true)

      const abortController = new AbortController();
      const signal = abortController.signal;

      const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_GET_FINANCIAL_INSTITUTIONS}`;
      // console.log(URI)

      window
        .fetch(URI, {
          method: "GET",
          signal: signal,
          headers: {
            "Access-Control-Request-Headers": "application/json",
            "Content-Type": "application/json",
            DealerId: dealerIdCode,
            Authorization: token,
            "x-api-key": uri.URI_X_API_KEY,
          },
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((datos) => {
          //setLoadingGetFinancials(false)

          //console.log(datos)

          setFinancialInstitutionList(datos);

          setErrorFlagFromContext(false);
        })
        .catch((error, response) => {
          console.log(error);
          if (errorFlagFromContext === false && error.status === 401) {
            changeToken()
              .then((response) => console.log(response))
              .catch((error) => console.log(error));
          }
        });

      return function cleanup() {
        abortController.abort();
      };
    }
  }, [reloadFetchFromContext]);

  const convertToAqua = () => {
    const aquaForm = {};

    //APPLICANT
    aquaForm.Applicant = {
      FirstName: applicationData.Applicant.FirstName,
      LastName: applicationData.Applicant.LastName,
      SocialSecurityNumber: applicationData.Applicant.SocialSecurityNumber,
      EmailAddress: applicationData.Applicant.EmailAddress,
      DateOfBirth: applicationData.Applicant.DateOfBirth,
      Address1: applicationData.Applicant.Address1,
      City: applicationData.Applicant.City,
      State: applicationData.Applicant.State,
      PostalCode: applicationData.Applicant.PostalCode,
      //PrimaryPhoneType : 'Home',
      //PrimaryPhone : applicationData.Applicant.PrimaryPhone || applicationData.Applicant.CellPhone,
      CellPhone:
        applicationData.Applicant.PrimaryPhone ||
        applicationData.Applicant.CellPhone,
      BusinessPhone:
        applicationData.Applicant.BusinessPhone ===
        applicationData.Applicant.CellPhone
          ? undefined
          : applicationData.Applicant.BusinessPhone,
      //MiddleInitial: applicationData.Applicant.MiddleInitial,

      EmployerState: applicationData.Applicant.EmployerState,
      HireDate: applicationData.Applicant.HireDate,
      LivingSinceCurrentResidence:
        applicationData.Applicant.LivingSinceCurrentResidence,

      // IdentificationType: "Unavailable",

      HomeValue:
        applicationData.Applicant.HomeValue &&
        parseFloat(applicationData.Applicant.HomeValue).toFixed(2),
      ResidenceType: applicationData.Applicant.ResidenceType,
      ResidenceMonthlyPayment:
        applicationData.Applicant.ResidenceMonthlyPayment &&
        parseFloat(applicationData.Applicant.ResidenceMonthlyPayment).toFixed(
          2
        ),

      BankAccountType: applicationData.Applicant.BankAccountType,
      BankRoutingNumber: applicationData.Applicant.BankRoutingNumber,
      BankAccountNumber: applicationData.Applicant.BankAccountNumber,
    };

    // if(applicationData.Applicant.SecondaryPhone && applicationData.Applicant.SecondaryPhoneType){
    //     aquaForm.Applicant.SecondaryPhone = applicationData.Applicant.BusinessPhone
    //     aquaForm.Applicant.SecondaryPhoneType = 'Work'
    // }

    //EMPLOYED
    if (
      applicationData.Applicant.EmploymentType === "Unemployed" ||
      applicationData.Applicant.EmploymentType === "Retired"
    ) {
      aquaForm.Applicant.EmploymentType =
        applicationData.Applicant.EmploymentType;
      aquaForm.Applicant.EmployerState =
        applicationData.Applicant.EmployerState;
      aquaForm.Applicant.HireDate = applicationData.Applicant.HireDate;
    } else if (applicationData.Applicant.EmploymentType === "Employed") {
      aquaForm.Applicant.EmploymentType =
        applicationData.Applicant.EmploymentType;
      aquaForm.Applicant.CurrentEmployer =
        applicationData.Applicant.CurrentEmployer;
      aquaForm.Applicant.Occupation = applicationData.Applicant.Occupation;
      aquaForm.Applicant.HireDate = applicationData.Applicant.HireDate;
      aquaForm.Applicant.GrossMonthlyIncome =
        applicationData.Applicant.GrossMonthlyIncome &&
        parseFloat(applicationData.Applicant.GrossMonthlyIncome).toFixed(2);
      aquaForm.Applicant.EmployerState =
        applicationData.Applicant.EmployerState;
    } else if (applicationData.Applicant.EmploymentType === "SelfEmployed") {
      aquaForm.Applicant.EmploymentType =
        applicationData.Applicant.EmploymentType;
      aquaForm.Applicant.Occupation = applicationData.Applicant.Occupation;
      aquaForm.Applicant.HireDate = applicationData.Applicant.HireDate;
      aquaForm.Applicant.GrossMonthlyIncome =
        applicationData.Applicant.GrossMonthlyIncome &&
        parseFloat(applicationData.Applicant.GrossMonthlyIncome).toFixed(2);
      aquaForm.Applicant.EmployerState =
        applicationData.Applicant.EmployerState;
    }

    //IDENTIFICATION
    aquaForm.Applicant.IdentificationType =
      applicationData.Applicant.IdentificationType;
    aquaForm.Applicant.IdentificationNumber =
      applicationData.Applicant.IdentificationNumber;
    aquaForm.Applicant.IsIssueDatePresent =
      applicationData.Applicant.IsIssueDatePresent;
    aquaForm.Applicant.IsIssueStatePresent =
      applicationData.Applicant.IsIssueStatePresent;
    aquaForm.Applicant.IdentificationIssueState =
      applicationData.Applicant.IdentificationIssueState;
    aquaForm.Applicant.IdentificationIssuedDate =
      applicationData.Applicant.IdentificationIssuedDate;
    aquaForm.Applicant.IdentificationExpirationDate =
      applicationData.Applicant.IdentificationExpirationDate;
    aquaForm.Applicant.ExpirationDateUnavailable =
      applicationData.Applicant.ExpirationDateUnavailable;

    if (applicationData.CoApplicant) {
      aquaForm.Applicant.HasCoApplicant = true;
    } else {
      aquaForm.Applicant.HasCoApplicant = false;
    }

    //MiddleInitial
    if (applicationData.Applicant.MiddleInitial) {
      aquaForm.Applicant.MiddleInitial =
        applicationData.Applicant.MiddleInitial;
    }

    //COAPPLICANT =============================================================================================================
    if (applicationData.CoApplicant) {
      aquaForm.CoApplicant = {
        CoApplicantType: applicationData.CoApplicant.CoApplicantType,
        FirstName: applicationData.CoApplicant.FirstName,
        LastName: applicationData.CoApplicant.LastName,
        SocialSecurityNumber: applicationData.CoApplicant.SocialSecurityNumber,
        EmailAddress: applicationData.CoApplicant.EmailAddress,
        DateOfBirth: applicationData.CoApplicant.DateOfBirth,
        Address1: applicationData.CoApplicant.Address1,
        City: applicationData.CoApplicant.City,
        State: applicationData.CoApplicant.State,
        PostalCode: applicationData.CoApplicant.PostalCode,
        //PrimaryPhoneType : 'Home',
        //PrimaryPhone : applicationData.CoApplicant.PrimaryPhone || applicationData.CoApplicant.CellPhone,
        CellPhone:
          applicationData.CoApplicant.PrimaryPhone ||
          applicationData.CoApplicant.CellPhone,
        BusinessPhone:
          applicationData.CoApplicant.BusinessPhone ===
          applicationData.CoApplicant.CellPhone
            ? undefined
            : applicationData.CoApplicant.BusinessPhone,
        MiddleInitial: applicationData.CoApplicant.MiddleInitial,

        // IdentificationType: "Unavailable",
        EmployerState: applicationData.CoApplicant.EmployerState,
        HireDate: applicationData.CoApplicant.HireDate,
        LivingSinceCurrentResidence:
          applicationData.CoApplicant.LivingSinceCurrentResidence,

        HomeValue:
          applicationData.CoApplicant.HomeValue &&
          parseFloat(applicationData.CoApplicant.HomeValue).toFixed(2),
        ResidenceType: applicationData.CoApplicant.ResidenceType,
        ResidenceMonthlyPayment:
          applicationData.CoApplicant.ResidenceMonthlyPayment &&
          parseFloat(
            applicationData.CoApplicant.ResidenceMonthlyPayment
          ).toFixed(2),
      };

      // if(applicationData.CoApplicant.SecondaryPhone && applicationData.CoApplicant.SecondaryPhoneType){
      //     aquaForm.CoApplicant.SecondaryPhone = applicationData.CoApplicant.BusinessPhone
      //     aquaForm.CoApplicant.SecondaryPhoneType = 'Work'
      // }

      //MiddleInitial
      if (applicationData.CoApplicant.MiddleInitial) {
        aquaForm.CoApplicant.MiddleInitial =
          applicationData.CoApplicant.MiddleInitial;
      }

      //IDENTIFICATION
      aquaForm.CoApplicant.IdentificationType =
        applicationData.CoApplicant.IdentificationType;
      aquaForm.CoApplicant.IdentificationNumber =
        applicationData.CoApplicant.IdentificationNumber;
      aquaForm.CoApplicant.IsIssueDatePresent =
        applicationData.CoApplicant.IsIssueDatePresent;
      aquaForm.CoApplicant.IsIssueStatePresent =
        applicationData.CoApplicant.IsIssueStatePresent;
      aquaForm.CoApplicant.IdentificationIssueState =
        applicationData.CoApplicant.IdentificationIssueState;
      aquaForm.CoApplicant.IdentificationIssuedDate =
        applicationData.CoApplicant.IdentificationIssuedDate;
      aquaForm.CoApplicant.IdentificationExpirationDate =
        applicationData.CoApplicant.IdentificationExpirationDate;
      aquaForm.CoApplicant.ExpirationDateUnavailable =
        applicationData.CoApplicant.ExpirationDateUnavailable;

      if (
        applicationData.CoApplicant.EmploymentType === "Unemployed" ||
        applicationData.CoApplicant.EmploymentType === "Retired"
      ) {
        aquaForm.CoApplicant.EmploymentType =
          applicationData.CoApplicant.EmploymentType;
        aquaForm.CoApplicant.EmployerState =
          applicationData.CoApplicant.EmployerState;
        aquaForm.CoApplicant.HireDate = applicationData.CoApplicant.HireDate;
      } else if (applicationData.CoApplicant.EmploymentType === "Employed") {
        aquaForm.CoApplicant.EmploymentType =
          applicationData.CoApplicant.EmploymentType;
        aquaForm.CoApplicant.CurrentEmployer =
          applicationData.CoApplicant.CurrentEmployer;
        aquaForm.CoApplicant.Occupation =
          applicationData.CoApplicant.Occupation;
        aquaForm.CoApplicant.HireDate = applicationData.CoApplicant.HireDate;
        aquaForm.CoApplicant.GrossMonthlyIncome =
          applicationData.CoApplicant.GrossMonthlyIncome &&
          parseFloat(applicationData.CoApplicant.GrossMonthlyIncome).toFixed(2);
        aquaForm.CoApplicant.EmployerState =
          applicationData.CoApplicant.EmployerState;
      } else if (
        applicationData.CoApplicant.EmploymentType === "SelfEmployed"
      ) {
        aquaForm.CoApplicant.EmploymentType =
          applicationData.CoApplicant.EmploymentType;
        aquaForm.CoApplicant.Occupation =
          applicationData.CoApplicant.Occupation;
        aquaForm.CoApplicant.HireDate = applicationData.CoApplicant.HireDate;
        aquaForm.CoApplicant.GrossMonthlyIncome =
          applicationData.CoApplicant.GrossMonthlyIncome &&
          parseFloat(applicationData.CoApplicant.GrossMonthlyIncome).toFixed(2);
        aquaForm.CoApplicant.EmployerState =
          applicationData.CoApplicant.EmployerState;
      }
    }

    setAquaFormFinal(aquaForm);
  };

  const convertToPinnacle = () => {
    const pinnacleForm = {};

    //APPLICANT
    pinnacleForm.Applicant = {
      FirstName: applicationData.Applicant.FirstName,
      LastName: applicationData.Applicant.LastName,
      SocialSecurityNumber: applicationData.Applicant.SocialSecurityNumber,
      EmailAddress: applicationData.Applicant.EmailAddress,
      DateOfBirth: applicationData.Applicant.DateOfBirth,
      Address1: applicationData.Applicant.Address1,
      City: applicationData.Applicant.City,
      State: applicationData.Applicant.State,
      PostalCode: applicationData.Applicant.PostalCode,
      CellPhone: applicationData.Applicant.CellPhone,
      BusinessPhone:
        applicationData.Applicant.BusinessPhone ||
        applicationData.Applicant.CellPhone,
      MiddleInitial: applicationData.Applicant.MiddleInitial,

      EmploymentType: applicationData.Applicant.EmploymentType,
      CurrentEmployer:
        applicationData.Applicant.CurrentEmployer ||
        applicationData.Applicant.EmploymentType,
      Occupation:
        applicationData.Applicant.Occupation ||
        applicationData.Applicant.EmploymentType,
      HireDate: applicationData.Applicant.HireDate,
      EmployerState: applicationData.Applicant.EmployerState,

      LivingSinceCurrentResidence:
        applicationData.Applicant.LivingSinceCurrentResidence,
      ResidenceType: applicationData.Applicant.ResidenceType,
      ResidenceMonthlyPayment:
        applicationData.Applicant.ResidenceMonthlyPayment &&
        parseFloat(applicationData.Applicant.ResidenceMonthlyPayment).toFixed(
          2
        ),
      HomeValue:
        applicationData.Applicant.HomeValue &&
        parseFloat(applicationData.Applicant.HomeValue).toFixed(2),

      BankAccountType: applicationData.Applicant.BankAccountType,
      BankRoutingNumber: applicationData.Applicant.BankRoutingNumber,
      BankAccountNumber: applicationData.Applicant.BankAccountNumber,
    };

    //IDENTIFICATION
    pinnacleForm.Applicant.IdentificationType =
      applicationData.Applicant.IdentificationType;
    pinnacleForm.Applicant.IdentificationNumber =
      applicationData.Applicant.IdentificationNumber;
    pinnacleForm.Applicant.IsIssueDatePresent =
      applicationData.Applicant.IsIssueDatePresent;
    pinnacleForm.Applicant.IsIssueStatePresent =
      applicationData.Applicant.IsIssueStatePresent;
    pinnacleForm.Applicant.IdentificationIssueState =
      applicationData.Applicant.IdentificationIssueState;
    pinnacleForm.Applicant.IdentificationIssuedDate =
      applicationData.Applicant.IdentificationIssuedDate;
    pinnacleForm.Applicant.IdentificationExpirationDate =
      applicationData.Applicant.IdentificationExpirationDate;
    pinnacleForm.Applicant.ExpirationDateUnavailable =
      applicationData.Applicant.ExpirationDateUnavailable;

    if (applicationData.CoApplicant) {
      pinnacleForm.Applicant.HasCoApplicant = true;
    } else {
      pinnacleForm.Applicant.HasCoApplicant = false;
    }

    if (applicationData.Applicant.GrossMonthlyIncome) {
      pinnacleForm.Applicant.GrossMonthlyIncome =
        applicationData.Applicant.GrossMonthlyIncome &&
        parseFloat(applicationData.Applicant.GrossMonthlyIncome).toFixed(2);
    }

    //CALCULAR GrossMonthlyIncome
    // if(applicationData.Applicant.AdditionalIncomeSources){
    //     let applicantTotalIncome = 0
    //     applicationData.Applicant.AdditionalIncomeSources.forEach(income => {
    //         applicantTotalIncome = applicantTotalIncome + income.Amount
    //     })

    //     if(applicationData.Applicant.GrossMonthlyIncome){
    //         applicantTotalIncome = applicantTotalIncome + applicationData.Applicant.GrossMonthlyIncome
    //     }

    //     pinnacleForm.Applicant.GrossMonthlyIncome = applicantTotalIncome
    // }

    //COAPPLICANT ==================================================================================
    if (applicationData.CoApplicant) {
      pinnacleForm.CoApplicant = {
        CoApplicantType: applicationData.CoApplicant.CoApplicantType,
        FirstName: applicationData.CoApplicant.FirstName,
        LastName: applicationData.CoApplicant.LastName,
        SocialSecurityNumber: applicationData.CoApplicant.SocialSecurityNumber,
        EmailAddress: applicationData.CoApplicant.EmailAddress,
        DateOfBirth: applicationData.CoApplicant.DateOfBirth,
        Address1: applicationData.CoApplicant.Address1,
        City: applicationData.CoApplicant.City,
        State: applicationData.CoApplicant.State,
        PostalCode: applicationData.CoApplicant.PostalCode,
        CellPhone: applicationData.CoApplicant.CellPhone,
        BusinessPhone:
          applicationData.CoApplicant.BusinessPhone ||
          applicationData.CoApplicant.CellPhone,
        MiddleInitial: applicationData.CoApplicant.MiddleInitial,

        EmploymentType: applicationData.CoApplicant.EmploymentType,
        CurrentEmployer:
          applicationData.CoApplicant.CurrentEmployer ||
          applicationData.Applicant.EmploymentType,
        Occupation:
          applicationData.CoApplicant.Occupation ||
          applicationData.Applicant.EmploymentType,
        HireDate: applicationData.CoApplicant.HireDate,
        EmployerState: applicationData.CoApplicant.EmployerState,

        LivingSinceCurrentResidence:
          applicationData.CoApplicant.LivingSinceCurrentResidence,
        ResidenceType: applicationData.CoApplicant.ResidenceType,
        ResidenceMonthlyPayment:
          applicationData.CoApplicant.ResidenceMonthlyPayment &&
          parseFloat(
            applicationData.CoApplicant.ResidenceMonthlyPayment
          ).toFixed(2),
        HomeValue:
          applicationData.CoApplicant.HomeValue &&
          parseFloat(applicationData.CoApplicant.HomeValue).toFixed(2),
      };

      //IDENTIFICATION
      pinnacleForm.CoApplicant.IdentificationType =
        applicationData.CoApplicant.IdentificationType;
      pinnacleForm.CoApplicant.IdentificationNumber =
        applicationData.CoApplicant.IdentificationNumber;
      pinnacleForm.CoApplicant.IsIssueDatePresent =
        applicationData.CoApplicant.IsIssueDatePresent;
      pinnacleForm.CoApplicant.IsIssueStatePresent =
        applicationData.CoApplicant.IsIssueStatePresent;
      pinnacleForm.CoApplicant.IdentificationIssueState =
        applicationData.CoApplicant.IdentificationIssueState;
      pinnacleForm.CoApplicant.IdentificationIssuedDate =
        applicationData.CoApplicant.IdentificationIssuedDate;
      pinnacleForm.CoApplicant.IdentificationExpirationDate =
        applicationData.CoApplicant.IdentificationExpirationDate;
      pinnacleForm.CoApplicant.ExpirationDateUnavailable =
        applicationData.CoApplicant.ExpirationDateUnavailable;

      if (applicationData.CoApplicant.GrossMonthlyIncome) {
        pinnacleForm.CoApplicant.GrossMonthlyIncome =
          applicationData.CoApplicant.GrossMonthlyIncome &&
          parseFloat(applicationData.CoApplicant.GrossMonthlyIncome).toFixed(2);
      }

      //CALCULAR GrossMonthlyIncome
      // if(applicationData.CoApplicant.AdditionalIncomeSources){
      //     let applicantTotalIncome = 0
      //     applicationData.CoApplicant.AdditionalIncomeSources.forEach(income => {
      //         applicantTotalIncome = applicantTotalIncome + income.Amount
      //     })

      //     if(applicationData.CoApplicant.GrossMonthlyIncome){
      //         applicantTotalIncome = applicantTotalIncome + applicationData.CoApplicant.GrossMonthlyIncome
      //     }

      //     pinnacleForm.CoApplicant.GrossMonthlyIncome = applicantTotalIncome
      // }
    }

    setPinnacleFormFinal(pinnacleForm);
  };

  const fillAquaLoanFromProposal = () => {
    if (applicationData.Applicant.ProposalDetails) {
      const data = {
        TotalInstalledProduct: parseFloat(
          applicationData.Applicant.ProposalDetails.SellingPrice
        ).toFixed(2),
        DownPayment: parseFloat(
          applicationData.Applicant.ProposalDetails.DownPayment
        ).toFixed(2),
        SalesTaxIncluded:
          parseFloat(applicationData.Applicant.ProposalDetails.SalesTax) > 0
            ? "true"
            : "false",
        SalesTax: parseFloat(
          applicationData.Applicant.ProposalDetails.SalesTax
        ),
        ApplicantId: urlParamApplicantId,
      };
      setProposalInfo({
        info: data,
        hasInfo: true,
      });
    }
  };

  const fillPinnacleLoanFromProposal = () => {
    if (applicationData.Applicant.ProposalDetails) {
      const data = {
        TotalInstalledProduct:
          parseFloat(applicationData.Applicant.ProposalDetails.SalesTax) > 0
            ? parseFloat(
                applicationData.Applicant.ProposalDetails.SellingPrice
              ) + parseFloat(applicationData.Applicant.ProposalDetails.SalesTax)
            : parseFloat(
                applicationData.Applicant.ProposalDetails.SellingPrice
              ),
        DownPayment: applicationData.Applicant.ProposalDetails.DownPayment,
        ApplicantId: urlParamApplicantId,
      };
      setProposalInfo({
        info: data,
        hasInfo: true,
      });
    }
  };

  const toggleForm = (lender) => {
    if (showForm === lender) {
      setShowForm("general");
    } else if (lender === "FI-0001") {
      convertToAqua();
      fillAquaLoanFromProposal();
      setShowForm(lender);
    } else if (lender === "FI-0002") {
      convertToPinnacle();
      fillPinnacleLoanFromProposal();
      setShowForm(lender);
    }
  };

  const Tooltip = ({ item, content }) => {
    return (
      <Tippy content={content} placement="top">
        <div className="externalForm_toggleLender-option-block">
          <p className="externalForm_toggleLender-text">Send to:</p>
          <p className="externalForm_toggleLender-lenderTitle">{item.Name}</p>
        </div>
      </Tippy>
    );
  };

  return (
    <>
      <div className="convertProspect_mainContainer">
        {((isDeallerAllyAdmin !== "true" && applicationData.UserFullName) ||
          internal) &&
          !isLoadingDetails && (
            <div className="externalForm_toggleLenderContainer">
              {financialInstitutionList.map((item, index) => {
                if (
                  item.ParametersProperlySet === true &&
                  (item.CanDealerCreateCompleteApplications === true ||
                    item.CanDealerCreateCompleteManualApplications === true)
                ) {
                  if (
                    item.Id === "FI-0001" &&
                    has_been_validated_in_aqua === "false"
                  ) {
                    return (
                      <Tooltip
                        key={item.Id}
                        item={item}
                        content="Your user has not been activated in AquaFinance yet"
                      />
                    );
                  } else if (
                    (applicationData.Applicant.ResidenceType === "Rent" ||
                      applicationData.Applicant.ResidenceType === "Other") &&
                    item.Id === "FI-0002" &&
                    !item.RenterProgram
                  ) {
                    return (
                      <Tooltip
                        key={item.Id}
                        item={item}
                        content="ResidenceType should be 'Own with Mortgage' or 'Owned Free & Clear' "
                      />
                    );
                  }
                  return (
                    <div
                      className={
                        showForm === item.Id
                          ? "externalForm_toggleLender-option-active"
                          : "externalForm_toggleLender-option"
                      }
                      onClick={() => {
                        toggleForm(item.Id);
                      }}
                    >
                      <p className="externalForm_toggleLender-text">Send to:</p>
                      <p className="externalForm_toggleLender-lenderTitle">
                        {item.Name}
                      </p>
                    </div>
                  );
                } else if (
                  item.CanDealerCreateCompleteApplications === false &&
                  (item.CanDealerCreateCompleteManualApplications === false ||
                    item.CanDealerCreateCompleteManualApplications ===
                      undefined)
                ) {
                  return (
                    <Tooltip
                      key={item.Id}
                      item={item}
                      content="You don't have access to this Financial Institution"
                    />
                  );
                } else if (
                  item.ParametersProperlySet === false &&
                  (item.CanDealerCreateCompleteApplications === true ||
                    item.CanDealerCreateCompleteManualApplications === true)
                ) {
                  return (
                    <Tooltip
                      key={item.Id}
                      item={item}
                      content="Dealer parameters not properly set"
                    />
                  );
                }
              })}
            </div>
          )}

        {!applicationData.UserFullName &&
          (userRole === "Manager" ||
            isDeallerAllyAdmin === "true" ||
            userRole === "Finance Specialist/Admin") &&
          !isLoadingDetails &&
          !internal && (
            <AssignUserToApplicationByIframe
              dealerId={applicationData.DealerId}
              applicationId={applicationData.ProspectLoanApplicationId}
              reloadPage
            />
          )}

        {showForm === "general" && Object.keys(applicationData).length > 0 ? (
          <>
            {detailsId && (
              <p style={{ marginLeft: "40px" }}>
                To edit applicant information select lender and then click back
                arrow {`(<)`}{" "}
              </p>
            )}

            <ExternalForm
              detailsId={detailsId}
              appData={applicationData}
              type="edit"
              internalTabs
              dontRevalidateProposalInfo
            />
          </>
        ) : showForm === "FI-0001" ? (
          <ApplicationFormAquaFinance
            type="create"
            prospectData={aquaFormFinal}
            detailsId={detailsId}
            appData={applicationData}
            {...(applicationData.CoApplicant && {
              prospectDataCoApplicant: true,
            })}
            applicantIdFromPostCommon={
              applicantIdFromPostCommon ||
              applicationData.ProspectLoanApplicationId
            }
            setStep3={urlParamApplicantId ? true : true}
            goToListView={goToListView ? true : false}
            urlParamApplicantId={urlParamApplicantId}
          />
        ) : showForm === "FI-0002" ? (
          <ApplicationFormPinnacle
            type="create"
            prospectData={pinnacleFormFinal}
            detailsId={detailsId}
            appData={applicationData}
            {...(applicationData.CoApplicant && {
              prospectDataCoApplicant: true,
            })}
            setStep3={urlParamApplicantId ? true : true}
            validateGrossMonthlyIncome
            applicantIdFromPostCommon={
              applicantIdFromPostCommon ||
              applicationData.ProspectLoanApplicationId
            }
            goToListView={goToListView ? true : false}
            urlParamApplicantId={urlParamApplicantId}
            showRentOption={financialInstitutionList[1].RenterProgram}
          />
        ) : (
          <div className="spinnerDetails">
            <Spinner radius={35} color={"grey"} stroke={5} visible={true} />
          </div>
        )}
      </div>
    </>
  );
};

export default ConvertProspect;
