import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import Accordeon from '../general/Accordeon'
import CompanyInformationForm from './CompanyInformationForm'
import ContactInformationForm from './ContactInformationForm'
import DocumentsForm from './DocumentsForm'
import EquipmentInformationForm from './EquipmentInformationForm'
import AdditionalNotesForm from './AdditionalNotesForm'
import { GetCommercialLoanInfo } from '../../api/CommercialLoan'
import { AuthContext } from '../../AuthContext';
import SpinnerPopUp from '../SpinnerPopUp'
import Notification from '../Notification'
import iconCheck from '../../assets/images/iconCheck.png';
import AlertBox from "../AlertBox";
import AdminForm from './AdminForm';
import ExternalURL from './ExternalURL';


const CommercialLoanForm = ({ setStep, step, applicationId, inspectMode, setCommercialId }) => {

  const uriId = useParams().id;
  const { uri } = useContext(AuthContext);

  const [applicationInfo, setApplicationInfo] = useState({})
  const [commercialLoanApplicationId, setCommercialLoanApplicationId] = useState(undefined)
  const [applicantBusinessId, setApplicantBusinessId] = useState(undefined)
  const [commercialLoanData, setCommercialLoanData] = useState({})
  const [authorization, setAuthorization] = useState(false)
  const [termsCheckBorderRed, setTermsCheckBorderRed] = useState(false)
  const [showSuccessNotification, setShowSuccessNotification] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [submitedSuccessfully, setSubmitedSuccessfully] = useState(false)
  const ApplicationId = applicationId || uriId
  const [reloadGetDetails, setReloadGetDetails] = useState(false)
  const [submitErrorAlert, setSubmitErrorAlert] = useState({show: false})
  const [submitted, setSubmitted] = useState(false)
  const [commercialStatus, setCommercialStatus] = useState(false)
  const [isCorpOnly, setIsCorpOnly] = useState(false)
  



  const user = window.localStorage.getItem('user');
  const token = window.localStorage.getItem('token');
  const refreshToken = window.localStorage.getItem('refreshToken');
  const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');



    //CAMBIAR EL TOKEN AL ENTRAR EN ESTE COMPONENTE
    useEffect(() => {
      const data = {
        "username": user,
        "refresh_token": refreshToken,
      }
  
      const abortController = new AbortController();
      const signal = abortController.signal;
  
      const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
      window.fetch(URI_REFRESHTOKEN, {
        method: 'POST',
        signal: signal,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': uri.URI_X_API_KEY,
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(datos => {
          //console.log(datos)
          window.localStorage.removeItem('token');
          const newToken = datos.id_token;
          window.localStorage.setItem('token', newToken);
        })
  
      return function cleanup() {
        abortController.abort();
      }
    }, []);


  const { CompanyInformation,
    ContactInformation,
    EquipmentInformation,
    AdminCommercialInfo,
    AdditionalNotes
    // BrokerWriteUp,
    // BestRateOption 
  } = step


  const validateSteps = (form) => {
    setStep({
      CompanyInformation: form.CompanyInformation && true,
      ContactInformation: form.ContactInformation && true,
      EquipmentInformation: form.EquipmentInformation && true,
      AdminCommercialInfo: form.AdminCommercialInfo && true,
      AdditionalNotes: form.AdditionalNotes && true
      // BrokerWriteUp: form.CreditRequestWriteUp && true,
      // BestRateOption: form.RateOption && true,
    })
  }



  const submitForm = async (event) => {
    setSubmitErrorAlert({ show: false});

    if (!authorization && isDeallerAllyAdmin !== 'true') {
      setTermsCheckBorderRed(true)
      return
    }

 

    setIsLoading(true);
    const formData = { ...commercialLoanData, Submit: true, FinancialInstitution: 'FI-0003', CommercialLoanApplicationId: ApplicationId || commercialLoanApplicationId };

    // console.log(formData)
    // console.log(JSON.stringify(formData))

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application`;

    try {
      const rawResponse = await window.fetch(URI, {
        method: 'POST',
        headers: {
          'Access-Control-Request-Headers': 'application/json',
          'Content-Type': 'application/json',
          'username': user,
          'x-api-key': uri.URI_X_API_KEY,
          'Authorization': token,
        },
        body: JSON.stringify(formData),
      });
      setIsLoading(false);
      const resp = await rawResponse.json();
      // console.log(resp);

      if (resp.CommercialLoanApplicationId) {

        if (inspectMode) {
          setShowSuccessNotification(true)
          setReloadGetDetails(!reloadGetDetails)
        } else {
          setSubmitedSuccessfully(true)
          setSubmitted(true)
        }

        if(resp.BSBFormURL){
          setApplicationInfo({...applicationInfo, BSBFormURL: resp.BSBFormURL})
        }
       
        
      }else{
        setSubmitErrorAlert({ 
          title: resp.Message || 'Error',
          text: resp.Errors[0] || 'An unexpected error has occurred. Please try again.',
          type: 'error',
          show: true
        });
      }



    }catch (error) {
      console.log(error)
      setIsLoading(false)
          setSubmitErrorAlert({ 
          title: 'Error',
          text: 'An unexpected error has occurred. Please try again.',
          type: 'error',
          show: true
        });
    }

  };



  //GET DETAILS
  useEffect(() => {

    const id = applicationId || uriId || ApplicationId || commercialLoanApplicationId
    if (id) {
      GetCommercialLoanInfo(uri, id)
        .then(resp => {
          // console.log(resp)
          
          setApplicationInfo(resp)
          setCommercialLoanData({
            CompanyInformation: resp.CompanyInformation,
            ContactInformation: resp.ContactInformation,
            EquipmentInformation: resp.EquipmentInformation,
            AdminCommercialInfo: resp.AdminCommercialInfo,
            AdditionalNotes: resp.AdditionalNotes
            // CreditRequestWriteUp: resp.CreditRequestWriteUp,
            // RateOption: resp.RateOption
          })
          // setIsCorpOnly(resp.CompanyInformation && resp.CompanyInformation.CorpOnly === true)
          setCommercialLoanApplicationId(id)
          setApplicantBusinessId(resp.CompanyInformation.ApplicantId)
          validateSteps(resp)

          if(resp.Submitted){
            setSubmitted(true)
          }

          if(resp.AdminCommercialInfo && resp.AdminCommercialInfo.CommercialStatus){
            setCommercialStatus(resp.AdminCommercialInfo.CommercialStatus)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [reloadGetDetails])

  //VALIDATE ISCORPONLY
  useEffect(()=>{
    setIsCorpOnly(commercialLoanData.CompanyInformation && (commercialLoanData.CompanyInformation.CorpOnly == 'true' || commercialLoanData.CompanyInformation.CorpOnly == true))
  },[commercialLoanData])



  useEffect(()=>{
    if(setCommercialId){
      setCommercialId(commercialLoanApplicationId)
    }
    
  },[commercialLoanApplicationId])



  return (
    <div style={{ maxWidth: '600px' }}>
      <AlertBox
        showAlert={showSuccessNotification}
        type={'success'}
        title={'The application was created successfully.'}
      />
      {
        commercialStatus &&
        <p style={{marginBottom: '10px'}}>Commercial Status: <span>{commercialStatus}</span></p>
      }


      
      <Accordeon title="Company Information" isCompleted={step.CompanyInformation} id="company" open={!step.CompanyInformation ? true : false}>
        <CompanyInformationForm
          type={(inspectMode || submitted) ? "edit" : "create"}
          setCommercialLoanApplicationId={setCommercialLoanApplicationId}
          setApplicantBusinessId={setApplicantBusinessId}
          applicantBusinessId={applicantBusinessId}
          setStep={setStep}
          commercialLoanApplicationId={commercialLoanApplicationId}
          step={step}
          setCommercialLoanData={setCommercialLoanData}
          prefilledInfo={applicationInfo.CompanyInformation}
        />
      </Accordeon>
      {
        (commercialLoanApplicationId) ?
          <>
            <Accordeon title="Ownership/Contact Information" isCompleted={step.ContactInformation} id="contact" open={(!step.ContactInformation || (applicationInfo.ContactInformation && !applicationInfo.ContactInformation[0].SSN)) ? true : false}>
              <ContactInformationForm
                type={(inspectMode || submitted) ? "edit" : "create"}
                setStep={setStep}
                commercialLoanApplicationId={commercialLoanApplicationId}
                step={step}
                setCommercialLoanData={setCommercialLoanData}
                prefilledInfo={applicationInfo.ContactInformation}
                isCorpOnly={isCorpOnly}
                validateFields={step.ContactInformation && (applicationInfo.ContactInformation && !applicationInfo.ContactInformation[0].SSN ) }
              />
            </Accordeon>
            <Accordeon title="Equipment Information" isCompleted={step.EquipmentInformation} id="equipment" open={!step.EquipmentInformation ? true : false}>
              <EquipmentInformationForm
                type={(inspectMode || submitted) ? "edit" : "create"}
                setStep={setStep}
                commercialLoanApplicationId={commercialLoanApplicationId}
                step={step}
                setCommercialLoanData={setCommercialLoanData}
                prefilledInfo={applicationInfo.EquipmentInformation}
              />
            </Accordeon>
            <Accordeon title="Additional Notes" isCompleted={step.AdditionalNotes} id="aditionalNotes" open={!step.AdditionalNotes ? true : false}>
              <AdditionalNotesForm
                type={(inspectMode || submitted) ? "edit" : "create"}
                commercialLoanApplicationId={commercialLoanApplicationId}
                setStep={setStep}
                step={step}
                setCommercialLoanData={setCommercialLoanData}
                prefilledInfo={applicationInfo.AdditionalNotes}
              />
            </Accordeon>
            <Accordeon title="File Upload" isCompleted={step.FileUpload} id="files" open={false} hideIcon>
              <DocumentsForm
                type={inspectMode ? "edit" : "create"}
                commercialLoanApplicationId={commercialLoanApplicationId}
                setStep={setStep}
                step={step}
                setCommercialLoanData={setCommercialLoanData}
              />
            </Accordeon>
            {
               isDeallerAllyAdmin === "true" &&
               <Accordeon title="DA Administrator" isCompleted={step.AdminCommercialInfo} id="admin-info" open={!step.AdminCommercialInfo ? true : false} hideIcon>
                <AdminForm
                  type={"create"}
                  commercialLoanApplicationId={commercialLoanApplicationId}
                  setStep={setStep}
                  step={step}
                  setCommercialLoanData={setCommercialLoanData}
                  prefilledInfo={applicationInfo.AdminCommercialInfo}
                  submitted={submitted}
                  hasCompanyInfo={applicationInfo.CompanyInformation ? true : false}
                />
              </Accordeon>
            }
            
          </>
          :
          <div className='commercial_infoMessage'>
            <p>
              Complete the company information first to unlock the rest of the form, then you can complete and save each section of the form in any order you wish.
            </p>
          </div>
      }

      <AlertBox
        showAlert={submitErrorAlert.show}
        type={submitErrorAlert.type}
        title={submitErrorAlert.title}
        text={submitErrorAlert.text}
      />

      {
        CompanyInformation &&
        ContactInformation &&
        EquipmentInformation &&
        AdditionalNotes &&
        !submitted &&
        // BrokerWriteUp &&
        // BestRateOption &&
        // isDeallerAllyAdmin === 'true' && applicationInfo.CompanyInformation && !submitted &&
        <div className='commercial_submitAllContainer'>
          <p className='commercial_submitAllMessage'>You have completed all sections of the application. You can now send it for review and approval.  Once you send an application to DealerAlly it is no longer editable and its status is changed to “Filled Out”.</p>
          <div style={{ marginBottom: '20px' }}>


            <div className="checkCopyAddressInfo">
              <input type="checkbox"
                name="isActive"
                className="inputCopyAddressInfo"
                {...authorization ? { checked: "checked" } : null}
                onClick={() => setAuthorization(!authorization)} />
              <label className="labelApplicationForm" style={{ color: termsCheckBorderRed ? 'red' : 'black' }}> Applicant Authorization</label>
            </div>
            <span >DealerAlly and/or its assigns will obtain a consumer credit report about each person identified as a guarantor in this credit application. By checking the box above, the user has “Obtained Credit Authorization” . (a) the user providing DealerAlly and/or its assigns with guarantor information represents that (b) each guarantor has authorized the user to supply DealerAlly and/or its assigns with such information in this application and (c) each guarantor understands that DealerAlly and/or it’s assigns will obtain a consumer credit report about the guarantor in connection with this application.</span>
          </div>

          <button className='commercial_submitAllButton' onClick={submitForm} >Send Completed Application to DealerAlly</button>
        </div>

      }


      {
        isDeallerAllyAdmin === 'true' && applicationInfo.CompanyInformation && submitted && 
        <div className='commercial_submitAllContainer'>
          <p className='commercial_submitAllMessage'>Get BSB URL</p>
          <button className='commercial_submitAllButton' onClick={submitForm} >Submit</button>
        </div>
      }
      






      {isLoading && <SpinnerPopUp />}
      {/* {
        submitedSuccessfully &&
        <Notification type='popup' link="/commercialapp" title='The application was created successfully.' f={() => { setSubmitedSuccessfully(false) }}>
          <img src={iconCheck} alt="check" />
        </Notification>
      } */}

      <AlertBox
        showAlert={submitedSuccessfully}
        type={'success'}
        title={'The application was created successfully.'}
      />

      {
        isDeallerAllyAdmin === 'true' && submitted && applicationInfo.BSBFormURL &&
        <ExternalURL url={applicationInfo.BSBFormURL}/>
      }
      

      {
        inspectMode && <div style={{ width: '532px' }}></div>
      }


    </div>
  )
}

export default CommercialLoanForm