import React, {useState, useEffect, useContext} from 'react'
import PopUp from '../PopUp';
import '../../assets/styles/EditUser.css';
import '../../assets/styles/ProposalProductRow.css';
import Spinner from 'react-spinner-material';
import {AuthContext} from '../../AuthContext';
import AddAndEditProposalProduct from './AddAndEditProposalProduct';
import {Link} from 'react-router-dom';

const ProductRow = ({type, product, setSystemProductList, setMonitoringProductList, setReloadGetSystemProducts, setReloadGetMonitoringProducts, setSystemPackageList, isPackageRow, setReloadGetPackage}) => {

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [showSheet, setShowSheet] = useState(false);


    //DELETE
    const [userWasDeleted, setUserWasDeleted] = useState(false);
    const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);
    const [showConfirmationPopUpDelete, setShowConfirmationPopUpDelete] = useState({show :false, id: undefined});
    const [showEditPopUp, setShowEditPopUp] = useState({show :false, id: undefined});
    const [showChangeDefaultPackage, setShowChangeDefaultPackage] = useState({show :false, id: undefined});

    const [belongToPackageValidation, setBelongToPackageValidation] = useState(false)
    const [validateDelete, setValidateDelete] = useState(false)

    const [productInfo, setProductInfo] = useState({})

    const token = window.localStorage.getItem('token');
 




    const deleteProduct = () => {

       


        setIsDeleteUserLoading(true)
        let URI = ''
        if(type === 'system'){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/system-product/${showConfirmationPopUpDelete.id}`;
        }else if(type === 'package'){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/disable-package-proposal/${showConfirmationPopUpDelete.id}`;
        }else{
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/monitoring-product/${showConfirmationPopUpDelete.id}`
        }
 
        //console.log(URI)

        

        window.fetch(URI ,{
        method: 'DELETE',
        headers: {           
        'Content-Type': 'application/json',  
        'x-api-key' : uri.URI_X_API_KEY,
        // 'DealerId' : props.userDealerId, 
        'Authorization' : token,         
        }, 
        // body: JSON.stringify(body),
        
        })
        .then(response => response.json())
        .then(datos => { 
            setIsDeleteUserLoading(false)
            //console.log(datos);

            if(datos.status === 'SUCCESS'){
                setUserWasDeleted(true)

                if(type === 'system'){

                    setSystemProductList(state => (state.filter(product => (product.DealerProposalProductId !== showConfirmationPopUpDelete.id) && product)))
                }else if(type === 'package'){
                    setSystemPackageList(state => (state.filter(product => (product.DealerPackageId !== showConfirmationPopUpDelete.id) && product)))
                }
                else{
                    setMonitoringProductList(state => (state.filter(product => (product.DealerProposalProductId !== showConfirmationPopUpDelete.id) && product)))
                }
            }
            
            
            
        })
    }




    const changeDefaultPackage = () => {
        console.log(showChangeDefaultPackage.id)


        setIsDeleteUserLoading(true)
        let URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/set-package-proposal-as-default/${showChangeDefaultPackage.id}`;
        
 
        console.log(URI)

        

        window.fetch(URI ,{
        method: 'POST',
        headers: {           
        'Content-Type': 'application/json',  
        'x-api-key' : uri.URI_X_API_KEY,
        // 'DealerId' : props.userDealerId, 
        'Authorization' : token,         
        }, 
        // body: JSON.stringify(body),
        
        })
        .then(response => response.json())
        .then(datos => { 
            setIsDeleteUserLoading(false)
            console.log(datos);

            if(datos.status === 'SUCCESS'){
                setUserWasDeleted(true)
                setReloadGetPackage(state => !state)
                
            }
            
            
            
        })
    }




    const checkIfProductExiste = () => {

        const URI =  `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/system-product/belongs-to-package/${product.DealerProposalProductId}`;
        // console.log(URI)

        window.fetch(URI ,{
            method: 'GET',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : uri.URI_X_API_KEY,
            'Authorization' : token,         
            }, 
            
            })
            .then(response => response.json())
            .then(datos => { 
                
                //console.log(datos);
                setBelongToPackageValidation(datos)
            
            })
    }






 


    const confirmationPopUp=()=>{

        // checkIfProductExiste()

            
        return(
            <div className="confirmationPopUp">
                <div className="confirmationPopUp_spaceBlack"></div>
                <div className="confirmationPopUp_content">
    
                    {userWasDeleted ? 
    
                    <div className="confirmationPopUp_content">
                    <h2 className="confirmationPopUp_message">Product was deleted successfully</h2>
    
                    <button className="confirmationPop_confirm confirmationPop_confirm_done" onClick={()=> {setShowConfirmationPopUpDelete({show: false, id: undefined}); setUserWasDeleted(false)}}>Done</button>
    
                    </div>   
    
                    :
    
                    <div className="confirmatinPopUp_choose">
    
                            <h2 className="confirmationPopUp_message">Are you sure you want to delete this product?</h2>

                            {
                                (belongToPackageValidation.BelongsToPackage ||  belongToPackageValidation.BelongsToBundle) &&
                                <div className="proposalProductRow_deleteWarningMessage">
                                    {
                                       belongToPackageValidation.BelongsToPackage &&
                                       <>
                                            <p className="proposalProductRow_deleteWarningMessage_text">{`Product ${product.Name || product.ProductDescription} is part of the following System Packages:`}</p>
                                            <ul>
                                                {
                                                    belongToPackageValidation && belongToPackageValidation.PackageNames && belongToPackageValidation.PackageNames.map(packageName => {return (<li>{`·${packageName}`}</li>)} )
                                                }
                                            </ul>
                                       </> 
                                    }

                                    {
                                       belongToPackageValidation.BelongsToBundle &&
                                       <>
                                            <p className="proposalProductRow_deleteWarningMessage_text">{`Product ${product.Name || product.ProductDescription} is part of the following Bundle:`}</p>
                                            <ul>
                                                {
                                                    belongToPackageValidation && belongToPackageValidation.BundleNames && belongToPackageValidation.BundleNames.map(bundleName => {return (<li>{`·${bundleName}`}</li>)} )
                                                }
                                            </ul>
                                       </> 
                                    }
                                    
                                </div>
                            }
                            

                            <div className="confirmationPopUp_buttons">
                                {isDeleteUserLoading ? 
                                <button className="confirmationPop_confirm-spinner">
                                <Spinner
                                    radius={16}
                                    color={"white"}
                                    stroke={4}
                                    visible={true}
                                    />
                                </button>
                                :
                                <button className="confirmationPop_confirm" onClick={deleteProduct}>Confirm</button>
    
                                }
                                {isDeleteUserLoading ? null
                                :
                                <button className="confirmationPop_cancel" onClick={()=>{setShowConfirmationPopUpDelete({show :false, id: undefined})}}>Cancel</button>
                                }
                                
    
                            </div>
                    </div>
             
                    }
    
                </div>
            </div>
        )
    }







    const confirmationNewDefaultPackage=()=>{

       
            
        return(
            <div className="confirmationPopUp">
                <div className="confirmationPopUp_spaceBlack"></div>
                <div className="confirmationPopUp_content">
    
                    {userWasDeleted ? 
    
                    <div className="confirmationPopUp_content">
                    <h2 className="confirmationPopUp_message">The package was set by default</h2>
    
                    <button className="confirmationPop_confirm confirmationPop_confirm_done" onClick={()=> {setShowChangeDefaultPackage({show: false, id: undefined}); setUserWasDeleted(false)}}>Done</button>
    
                    </div>   
    
                    :
    
                    <div className="confirmatinPopUp_choose">
    
                            <h2 className="confirmationPopUp_message">Are you sure you want to set this package as the default package?</h2>
                            <div className="confirmationPopUp_buttons">
                                {isDeleteUserLoading ? 
                                <button className="confirmationPop_confirm-spinner">
                                <Spinner
                                    radius={16}
                                    color={"white"}
                                    stroke={4}
                                    visible={true}
                                    />
                                </button>
                                :
                                <button className="confirmationPop_confirm" onClick={changeDefaultPackage}>Confirm</button>
    
                                }
                                {isDeleteUserLoading ? null
                                :
                                <button className="confirmationPop_cancel" onClick={()=>{setShowChangeDefaultPackage({show :false, id: undefined})}}>Cancel</button>
                                }
                                
    
                            </div>
                    </div>
             
                    }
    
                </div>
            </div>
        )
    }



    return (
        <>  
          
            <div className="proposals_productRow">
                <p className="proposals_productValue">{product.Name || product.ProductDescription}</p>
                {
                    isPackageRow ? 
                        product.IsDefault ? 
                            <p className="proposals_productValue productRow_defaultPackageText">Default Product</p>
                            :
                            <p className="proposals_productValue productRow_NondefaultPackageText" onClick={()=>{setShowChangeDefaultPackage({show :true, id: product.DealerPackageId})}}>set as default</p>
                    :
                    <p className="proposals_productValue">{product.ProductPrice && `$ ${parseFloat(product.ProductPrice).toFixed(2)}`}</p>
                }
                <p className="proposals_productValue">{product.PartNumber}</p>
                <p className="proposals_productValue">{type === 'system' ? product.Manufacturer : product.ServiceCompany}</p>
                <p className="proposals_productValue">{product.LastModifiedDate}</p>
                {
                    type === 'package' ?
                    <Link to={`/editsystempackage/${product.DealerPackageId}`}>
                        <p className="proposals_productValue productRow_editButton" >Edit</p>
                    </Link>
                    :
                    <p className="proposals_productValue productRow_editButton" onClick={()=>{setShowEditPopUp({show :true, id: '123'})}}>Edit</p>
                }
                
                <p className="proposals_productValue productRow_deleteButton" onClick={()=>{setShowConfirmationPopUpDelete({show :true, id: product.DealerProposalProductId || product.DealerPackageId}); checkIfProductExiste()}}>Delete</p>
            </div>

            {/* EDIT */}
            {showEditPopUp.show && <PopUp 
                                    setShowSheet={setShowEditPopUp} 
                                    edit 
                                    title="Edit">
                                        <AddAndEditProposalProduct 
                                            productInfo={product} 
                                            edit 
                                            type={type} 
                                            setSystemProductList={setSystemProductList} 
                                            setMonitoringProductList={setMonitoringProductList}
                                            setReloadGetSystemProducts={setReloadGetSystemProducts}
                                            setReloadGetMonitoringProducts = {setReloadGetMonitoringProducts}
                                            />
                                    </PopUp>}

            {/* DELETE */}
            {showConfirmationPopUpDelete.show && confirmationPopUp()}

            {/* CHANGE DEFAULT PACKAGE */}
            {showChangeDefaultPackage.show && confirmationNewDefaultPackage()}
        </>
        
    )
}

export default ProductRow;
