import React, {useEffect, useState} from 'react'
import '../../assets/styles/ApplicationRow.css'

import Sheet from '../Sheet';
import ApplicationFormAquaFinance from "../ApplicationFormAquaFinance"
import PinnacleStubForm from '../PinnacleStubForm'
import ApplicationFormPinnacle from '../ApplicationFormPinnacle';


const ApplicationRow = (props) => {

    // console.log(props)



    const [showSheet, setShowSheet] = useState(false);

    const [newApplicationStatus, setNewApplicationStatus] = useState(false);

    const [status, setStatus] = useState('grayStatus')


    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const token = window.localStorage.getItem('token');
    const hideBuyRate = localStorage.getItem('hideBuyRate');
    const userRole = localStorage.getItem('userRole');


    const showSheetHandler = ()=>{
        setShowSheet(!showSheet);

    }

    useEffect(() => {
        let statusColor

        const red = ['Denied', 'Cancelled', 'SendBack', 'Error']
        const green = [  'FundingComplete', 'ReadyToFund']
        const blue = []
        const yellow = ['ConditionallyApproved', 'SignReady', 'PendingDocs', 'Pending', 'InVerification', 'InConfirmation', 'Approved']
        const black = ['FilledOut', 'Processing']
        const orange = ['Problem', 'Received']


        if(red.includes(props.LoanApplicationStatus)){
            //RED
            statusColor = 'redStatus'
        }else if(green.includes(props.LoanApplicationStatus)){
            //GREEN
            statusColor = 'greenStatus'
        }else if(blue.includes(props.LoanApplicationStatus)){
            //BLUE
            statusColor = 'blueStatus'
        }else if(yellow.includes(props.LoanApplicationStatus)){ 
            //YELLOW         
            statusColor = 'yellowStatus'
        }else if(black.includes(props.LoanApplicationStatus)){
            //BLACK
            statusColor = 'grayStatus'
        }else if(orange.includes(props.LoanApplicationStatus)){
            //ORANGE
            statusColor = 'orangeStatus'
        }

        setStatus(statusColor)
          
        
    }, [props.LoanApplicationStatus])
    

    
        


    return (
        <>



{showSheet ?
    <Sheet handler={showSheetHandler}>
        {   
            props.FinancialInstitutionId === 'FI-0001' ?

            <>
                {   
                     props.IsStubApplication === true ?

                     <PinnacleStubForm entity="aquaFinance" application_id={props.ApplicationId} type="edit" handler={showSheetHandler}/>
                     :

                        props.ApplicantFullName === 'Applicant' ? 
                            <ApplicationFormAquaFinance type="create" 
                                                        updateId={props.ApplicationId} 
                                                        application_id={props.ApplicationId} 
                                                        applicant_id={props.ApplicantId}
                                                        applicantName={props.ApplicantFullName}
                                                        /> 


                        : props.LoanApplicationStatus === 'Error' && isDeallerAllyAdmin != 'true' ? 
                            <ApplicationFormAquaFinance type="create" 
                                                        application_id={props.ApplicationId} 
                                                        updateId={props.ApplicationId}
                                                        applicant_id={props.ApplicantId}
                                                        showErrorMessages
                                                        errorStatus
                                                        applicantName={props.ApplicantFullName}
                                                        /> 

                                                        
                        :<ApplicationFormAquaFinance application_id={props.ApplicationId} 
                                                    type="edit" 
                                                    handler={showSheetHandler}
                                                    applicantName={props.ApplicantFullName}
                                                    />
                }
                

            </>

            : props.FinancialInstitutionId === 'FI-0002' ?

            <>
                {
                    props.IsStubApplication === true ?
                    <PinnacleStubForm application_id={props.ApplicationId} type="edit" handler={showSheetHandler} />
                    :

                         props.LoanApplicationStatus === 'Error' && isDeallerAllyAdmin != 'true' ? 
                            <ApplicationFormPinnacle  type="create" 
                                                    application_id={props.ApplicationId} 
                                                    updateId={props.ApplicationId}   
                                                    applicant_id={props.ApplicantId}  
                                                    showErrorMessages  
                                                    errorStatus
                                                    applicantName={props.ApplicantFullName}
                                                    />                                
                                                    // application_status={newApplicationStatus || props.application.LoanApplicationStatus} 
                                                   


                            : <ApplicationFormPinnacle 
                                application_id={props.ApplicationId} 
                                type="edit" 
                                handler={showSheetHandler} 
                                application_status={newApplicationStatus || props.LoanApplicationStatus} 
                                applicantName={props.ApplicantFullName}
                                />
                        
                }
                
            </>

            : null

             
        }
       
        
        
    </Sheet> : null}



        
        <div className="application_listContainer" >

            <div className={ isDeallerAllyAdmin === 'true' ? "application_list-adminView" : "application_list"} onClick={showSheetHandler}>
                <p className="application_listItem">{props.ApplicationId}</p>
                <p className={`application_listItem ${status}`}>{props.ApplicantFullName}</p>
                {
                   isDeallerAllyAdmin === 'true' &&
                   <p className="application_listItem">{props.DealerName}</p>
                } 
                <p className="application_listItem">{props.FinancialInstitutionId === 'FI-0001' ? 'Aqua' : 'Pinnacle'}</p>
                <p className={`application_listItem ${status}`}>{props.LoanApplicationStatus}</p>
                <p className="application_listItem">{props.FIApplicationNumber}</p>  
                <p className="application_listItem">{props.UserFullName}</p>
                <p className="application_listItem">{props.CreationDate}</p>
                <p className="application_listItem">{props.LastModifiedDate}</p>
                <p className="application_listItem">{props.RTCDate}</p>
                <p className="application_listItem">{`$${props.AmountFinanced}`}</p>
                {
                    (hideBuyRate === 'true' && userRole === 'Sales Representative') ? null :
                    <p className="application_listItem">{props.BuyRate}</p>
                }
                
                
            </div>
  
        </div>

        


          
            
        </>
    )
}

export default ApplicationRow
