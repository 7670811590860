import React, { useContext } from 'react'
import { AuthContext } from '../AuthContext';




export const GetCommercialLoanInfo = async (uri, applicantId) => {

    const user = window.localStorage.getItem('user');
    const token = window.localStorage.getItem('token'); 




    // const { uri } = useContext(AuthContext);

    // const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application/get-applications-by-applicant-id/${applicantId}`; 
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application/${applicantId}/get-details`; 
    try {
       const rawResponse = await window.fetch(URI, {
           method: 'GET',
           headers: {
            'Access-Control-Request-Headers': 'application/json',
            'Content-Type': 'application/json',
            'username': user,
            'x-api-key': uri.URI_X_API_KEY,
            'Authorization' : token, 
        }
       });
       const resp = await rawResponse.json();
    //    console.log(resp);
       return resp

   }
   catch (error) {
        console.log(error)
   }

}