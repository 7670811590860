import React from 'react';
import ReactDOM from 'react-dom';
// import './assets/styles/globalStyles.css'

import App from './routes/App';


ReactDOM.render(<App />, document.getElementById('root'));



