import React, {useState, useContext, useEffect} from 'react'
import '../assets/styles/ApplicationDetailContainer.css';
import '../assets/styles/ApplicationsCard.css';
import {AuthContext} from '../AuthContext';
import { useParams } from 'react-router-dom';
import Spinner from 'react-spinner-material';
import iconRefresh from '../assets/images/iconRefresh.png';
import ApplicationFormAquaFinance from '../components/ApplicationFormAquaFinance';
import ApplicationFormPinnacle from '../components/ApplicationFormPinnacle'
import ConvertProspect from '../components/ConvertProspect'
import PinnacleStubForm from '../components/PinnacleStubForm'


const ApplicationDetailContainer = () => {

    const{uri, changeToken} = useContext(AuthContext);

    const applicationId = useParams().id;

    const [applicationData, setApplicationData] = useState({})
    const [loadingGetApplicationDetails, setLoadingGetApplicationDetails] = useState(true)
    const [hasData, setHasData] = useState(false)
    const [applicationNotFound, setApplicationNotFound] = useState(false)

    const [newApplicationStatus, setNewApplicationStatus] = useState(false);

    const [newFinancialInstitutionNumber, setNewFinancialInstitutionNumber] = useState(false)
    const [newFinancialInstitutionId, setNewFinancialInstitutionId] = useState(false)

    const [refresh, setRefresh] = useState(false)

    const [loadingNewStatus, setLoadingNewStatus] = useState(false)


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');

   
    
    //GET APPLICATION GENERAL INFO
    useEffect(() => {
        setLoadingGetApplicationDetails(true)

        const abortController = new AbortController();
        const signal = abortController.signal;

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${applicationId}/get-compact-details`
        // console.log(URI)

        window.fetch(URI, {
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : uri.URI_X_API_KEY,
                }
        })
            .then(response => response.json())
            .then(datos => {
                setLoadingGetApplicationDetails(false)
               
                // console.log(datos)

                if(datos.status === 'APPLICATION_NOT_FOUND'){
                    setApplicationNotFound(true)
                }else{
                    setHasData(true)
                    setApplicationData(datos[0])
                }

                

            })
            .catch(error => {
                setLoadingGetApplicationDetails(false)
                console.log(error)
            })

        return function cleanup() {
            abortController.abort();
        }
    }, []);




     //REFRESH STATUS APPLICANT
     const refreshStatus = (showAnimation)=>{

        showAnimation && setLoadingNewStatus(true);  
       
       
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}fi/${uri.URI_GET_APPLICATION_STATUS}${applicationId}`
        //console.log(URI)
        
      
        window.fetch(URI,{
            method: 'GET',
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'DealerId' : applicationData.DealerId,
                'Authorization' : token,  
                'x-api-key' : uri.URI_X_API_KEY,    
              }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setLoadingNewStatus(false);
            // console.log(datos)
    
            if(datos.hasOwnProperty('LoanApplicationStatus')){
                setNewApplicationStatus(datos.LoanApplicationStatus)
            }
    
            if(datos.hasOwnProperty('Details')){
                setNewFinancialInstitutionNumber(datos.Details.Customer)
            }else if(datos.hasOwnProperty('FIApplicationId') && datos.hasOwnProperty('FIApplicationNumber')){
                
                setNewFinancialInstitutionNumber(datos.FIApplicationNumber);
                setNewFinancialInstitutionId(datos.FIApplicationId)
            }
    
            setRefresh(true)
            
           
        }).catch(error => {
            console.log(error)
            setRefresh(true)
            setLoadingNewStatus(false);
            changeToken();
            
        })
      
        
           
    }





    return (
        <div className="applicationDetailContainer_mainContainer">

            <div className="applicationDetailContainerExternal">


                {
                    loadingGetApplicationDetails ? 

                    <p>Loading</p>

                    :

                    ( hasData  && loadingGetApplicationDetails === false) ?
                    <div className="applicationCard_card height300">

                    <div className="applicationCard_card-headerFromExternalPage">
                        <div className="applicationCard_header-titleContainer">
                            <h2 className="applicationCard_header-numer">{applicationData.ApplicationId || applicationData.ProspectLoanApplicationId} </h2>
                            <h2 className="applicationCard_header-numer">{applicationData.Applicant ? applicationData.Applicant.FirstName : ''} {applicationData.Applicant ? applicationData.Applicant.LastName : ''}</h2>
                            {
                                applicationData.FinancialInstitutionId &&
                                <>
                                    <span className="applicationCard_header-financialentity">{applicationData.FinancialInstitution} </span>
                                    <span className="applicationCard_header-financialentity">
                                        - {applicationData.FinancialInstitutionId === 'FI-0001' ?

                                            applicationData.IsStubApplication ? 'Only documents' :

                                                (applicationData.FIApplicationId && applicationData.FIApplicationNumber) ? <a className="applicationCard_header-link" target="_blank" href={'https://portal.aquafinance.com/applications/' + applicationData.FIApplicationId + '/paperwork/application/'} >{applicationData.FIApplicationNumber}</a>

                                                    : 
                                                    ((newFinancialInstitutionId && newFinancialInstitutionNumber) ?
                                                        <a className="applicationCard_header-link" target="_blank" href={'https://portal.aquafinance.com/applications/' + newFinancialInstitutionId + '/paperwork/application/'} >{newFinancialInstitutionNumber}</a>
                                                        : 'Processing'
                                                        )

                                            :

                                            applicationData.FinancialInstitutionId === 'FI-0002' ?
                                                applicationData.IsStubApplication ? 'Only documents' : (applicationData.FIApplicationId || newFinancialInstitutionNumber || 'Processing')
                                                : 'None'}
                                    </span>
                                </>
                            }

                        </div>

                        <div className="applicationCard_header-buttonsContainer marginTop20">
                            {/* <button className="applicationCard_header-DetailsButton" onClick={showSheetHandler}>
                                <img src={iconApplicationDetail} className="applicationCard_header-DetailsButton-img" />
                                <p className="applicationCard_header-DetailsButton-text" >Details</p>
                            </button> */}

                            {
                                newApplicationStatus ?

                                    <p className={newApplicationStatus === 'Approved' ? "applicationCard_header-status-approved"
                                        : newApplicationStatus === 'SignReady' ? "applicationCard_header-status-approved"
                                            : newApplicationStatus === 'Denied' ? "applicationCard_header-status-denied"
                                                : newApplicationStatus === 'Pending' ? "applicationCard_header-status-pending"
                                                    : newApplicationStatus === 'Error' ? "applicationCard_header-status-denied"
                                                        : newApplicationStatus === 'FilledOut' ? "applicationCard_header-status-FilledOut"
                                                            : "applicationCard_header-status-FilledOut"} >{newApplicationStatus}
                                    </p>

                                    :

                                    <p className={applicationData.LoanApplicationStatus === 'Approved' ? "applicationCard_header-status-approved"
                                        : applicationData.LoanApplicationStatus === 'SignReady' ? "applicationCard_header-status-approved"
                                            : applicationData.LoanApplicationStatus === 'Denied' ? "applicationCard_header-status-denied"
                                                : applicationData.LoanApplicationStatus === 'Pending' ? "applicationCard_header-status-pending"
                                                    : applicationData.LoanApplicationStatus === 'Error' ? "applicationCard_header-status-denied"
                                                        : applicationData.LoanApplicationStatus === 'FilledOut' ? "applicationCard_header-status-FilledOut"
                                                            : "applicationCard_header-status-FilledOut"} >{applicationData.LoanApplicationStatus}
                                    </p>
                            }

                            {
                                loadingNewStatus ?

                                    <Spinner
                                        radius={22}
                                        color={"grey"}
                                        stroke={3}
                                        visible={true}
                                    />

                                    :

                                    <img src={iconRefresh} className="applicationCard_header-refreshIcon" onClick={refreshStatus} />

                            }



                        </div>
                    </div>



                    <div className="applicationCard_card-bodyFromExternalPage">

                        <div className="applicationCard_body-section">
                            <div className="applicationCard_body-section-table applicationCard_body-section-table-first">
                                <p className="applicationCard_label">Creation Date:</p>
                                <p className="applicationCard_value">{applicationData.CreationDate}</p>
                                <p className="applicationCard_label">Created By:</p>
                                <p className="applicationCard_value">{applicationData.UserFullName || 'Pending to be assigned'}</p>
                                <p className="applicationCard_label">Dealer:</p>
                                <p className="applicationCard_value">{applicationData.DealerName}</p>
                            </div>

                        </div>


                    </div>

                   





                </div>
                    
                    : null

                }
               


                {
                     loadingGetApplicationDetails ? 

                     <p>Loading</p>
 
                     :
               
                    ( hasData  && loadingGetApplicationDetails === false) ?   
                    <div className="applicationDetail_formContainer">

                       

                        {
                            applicationData.FinancialInstitutionId === 'FI-0001' ?

                                <>
                                    {
                                        applicationData.IsStubApplication === true ?

                                            <PinnacleStubForm entity="aquaFinance" application_id={applicationData.ApplicationId} type="edit"  />
                                            

                                            : applicationData.Applicant.FirstName === 'Applicant' ?
                                                <ApplicationFormAquaFinance type="create"
                                                    updateId={applicationData.ApplicationId}
                                                    application_id={applicationData.ApplicationId}
                                                    applicant_id={applicationData.Applicant.ApplicantId} />


                                            : applicationData.LoanApplicationStatus === 'Error' && isDeallerAllyAdmin != 'true' ?
                                                <ApplicationFormAquaFinance type="create"
                                                    application_id={applicationData.ApplicationId}
                                                    updateId={applicationData.ApplicationId}
                                                    applicant_id={applicationData.Applicant.ApplicantId}
                                                    showErrorMessages
                                                    errorStatus
                                                />


                                            : <ApplicationFormAquaFinance 
                                                application_id={applicationData.ApplicationId}
                                                type="edit"
                                                />
                                    }


                                </>

                                : applicationData.FinancialInstitutionId === 'FI-0002' ?

                                    <>
                                        {
                                            applicationData.IsStubApplication === true ?
                                                <PinnacleStubForm 
                                                    application_id={applicationData.ApplicationId} 
                                                    type="edit"  />
                                                

                                                : applicationData.LoanApplicationStatus === 'Error' && isDeallerAllyAdmin != 'true' ?
                                                <ApplicationFormPinnacle type="create"
                                                    application_id={applicationData.ApplicationId}
                                                    updateId={applicationData.ApplicationId}
                                                    applicant_id={applicationData.Applicant.ApplicantId}
                                                    showErrorMessages
                                                    errorStatus

                                                />
                                                    // application_status={newApplicationStatus || props.application.LoanApplicationStatus} 
                                                    //refresh_status={refreshStatus}/>


                                                : <ApplicationFormPinnacle 
                                                    application_id={applicationData.ApplicationId} 
                                                    type="edit"  
                                                    application_status={newApplicationStatus || applicationData.LoanApplicationStatus}  
                                                    refresh_status={refreshStatus}
                                                    />
                                        }

                                    </>

                                    :

                                    <p>Not available</p>

                                    // <>
                                    //     <ConvertProspect detailsId={applicationData.ProspectLoanApplicationId} />
                                    // </>


                        }



                    </div>
                     
                     : null
                }        






                    
              

            </div>   


            {
                applicationNotFound &&
                <div className="applicationDetail_generalMessageDiv">
                    <p>Application Not Found</p>
                </div>  
            }    
             
        </div>
    )
}

export default ApplicationDetailContainer;
