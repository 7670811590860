import React, { useState, useContext, useEffect } from 'react';
import '../assets/styles/LibraryPostDetail.css';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import {AuthContext} from '../AuthContext';
import iconPdf from '../assets/images/iconPdf.png'

const LibraryPostDetail = (props)=>{


  const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

  const[reloadGetPostDetail, setReloadGetPostDetail] = useState(false);

  const[detailPostData, setDetailPostData] = useState({});

  const [errorIndex, setErrorIndex] = useState(false);

  const token = window.localStorage.getItem('token');
  const user = window.localStorage.getItem('user');
  const URI_X_API_KEY_I = uri.URI_X_API_KEY;



  //FETCH GET POST DETAIL
  useEffect(()=>{
    //setIsLoading(true)

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_LIBRARY}${uri.URI_LIBRARY_DOCUMENTS}${props.post_id}/get-details`
    // console.log(URI)

    const abortController = new AbortController();
    const signal = abortController.signal;


    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token,   
            'x-api-key' : URI_X_API_KEY_I,   
          }
    })
    .then(response => {

      if (response.ok) {
          return response.json();
        }
      return Promise.reject(response);
     })
    .then(datos => {
        //setIsLoading(false)

        // console.log(datos);

        if(datos.Status === 'DOCUMENT_NOT_FOUND'){
          console.log('ERROR DOCUMENTO NO ENCONTRADO')
        }else{
          setDetailPostData(datos[0]);
        }
        

        setErrorFlagFromContext(false)

    }).catch((error, response) => {
      console.log(error)
      if(errorFlagFromContext === false && error.status === 401){
          changeToken().then(response => console.log(response)).catch(error => console.log(error))
      }    
  });
    

    return function cleanup(){
        abortController.abort();
    }
    
},[reloadGetPostDetail, reloadFetchFromContext]);


// //FUNCION PARA DESCARGAR ARCHIVOS
// function showFile(blob){
//   // It is necessary to create a new blob object with mime-type explicitly set
//   // otherwise only Chrome works like it should
//   console.log(blob)
//   var newBlob = new Blob([blob], {type: "application/pdf"})

//   // IE doesn't allow using a blob object directly as link href
//   // instead it is necessary to use msSaveOrOpenBlob
//   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//     window.navigator.msSaveOrOpenBlob(newBlob);
//     return;
//   } 

//   // For other browsers: 
//   // Create a link pointing to the ObjectURL containing the blob.
//   const data = window.URL.createObjectURL(newBlob);
//   var link = document.createElement('a');
//   link.href = data;
//   link.download="document name";
//   link.click();
//   setTimeout(function(){
//     // For Firefox it is necessary to delay revoking the ObjectURL
//     window.URL.revokeObjectURL(data);
//   }, 100);
// }



//FUNCION PARA DESCARGAR ARCHIVOS
function showFile(blob, documentName){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  //console.log(blob)
  var newBlob = new Blob([blob], {type: 'application/pdf'})
  

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download= documentName;
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}


//FUNCION PARA DESCARGAR EL DOCUMENTO
const dowloadDocument = (fileId, documentName) =>{


  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_LIBRARY}${uri.URI_LIBRARY_DOCUMENTS}${fileId}`;
  console.log(URI)
  window.fetch(URI,{
      method: 'GET',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I, 
          'username' : user,      
        }
  }).then(r => r.blob())
  .then(blob => {
    showFile(blob, documentName)
  })
  

}




    const modules = {
        toolbar: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link", "image", "video"],
          ["clean"]
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false
        }
      };
    
      const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video"
      ];


    return(
        <>
            <div className="popUp_PostDetail">
                <div className="postDetail_spaceBlack" onClick={props.handler}></div>
                <div className="postDetail_container">

                    <h2 className="library_postTitle">{detailPostData.Title}</h2>

                    {detailPostData.hasOwnProperty('Filename') ? 
                    <div>
                        <div className="library_iconSection">
                          <img src={iconPdf} className="library_FileIcon" />
                          <p className="library_FileName">{detailPostData.Filename}</p>
                        </div>
                        <button className="library_dowloadDocument-button" onClick={()=>{dowloadDocument(detailPostData.Id, detailPostData.Filename)}}>Dowload Document</button>
                    </div>
                    : null}
                    
                    {
                      detailPostData.Content &&
                      <div className="library_detail-editor">
                        <ReactQuill
                                theme="bubble"
                                //onChange={handleChangeTextEditor}
                                value={detailPostData.Content ? detailPostData.Content : ''}
                                modules={modules}
                                formats={formats}
                                bounds={".app"}
                                placeholder="placeholder"
                                readOnly={true}
                                
                        />
                      </div>
                    }
                    
                    

      

                </div>
            </div>
        </>

    );
}

export default LibraryPostDetail;