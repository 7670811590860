import React, {useState, useContext, useEffect} from 'react'
import '../assets/styles/iframeContainer.css'
import {AuthContext} from '../AuthContext';
import { useParams } from 'react-router-dom';
import ExternalForm from '../components/ExternalForm'
import Logo from '../assets/images/logo.png'

const IframeContainer = () => {

    const{uri} = useContext(AuthContext);

    const iframeId = useParams().id;

    const [formData, setFormData] = useState({})


    //GET DEALER ID
    useEffect(() => {


        const abortController = new AbortController();
        const signal = abortController.signal;

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}dealer/get-dealer-by-uuid/${iframeId}`;
        console.log(URI)

        window.fetch(URI, {
            method: 'GET',
            signal: signal,
            headers: {
                'x-api-key': uri.URI_X_API_KEY,
            },
        })
            .then(response => response.json())
            .then(datos => {
                console.log(datos)

                setFormData(datos)

            })
            .catch(error => {
                console.log(error)
            })

        return function cleanup() {
            abortController.abort();
        }
    }, []);



    return (
        <div className="iframeContainer_mainContainer">
            <ExternalForm iframe={true} dealerId={formData.Id} iframe={true} type="create"/>  

            <div className="iframe_footerContainer">
                <p>Powered by</p>
                <img src={Logo} alt="DealerAlly Logo" className="iframe_daLogo"/>
            </div>  

        </div>
    )
}

export default IframeContainer;
