import {useState, useEffect} from 'react'

const useAlertBox = ({autoClose, autoCloseTimeout = 5000, initialShow}) => {
   
    const [showAlert, setShowAlert] = useState(initialShow);
    const [timeOut, setTimeOut] = useState()

	function toggleAlert() {
		setShowAlert(!showAlert);
	}

	useEffect(() => {
		if (!showAlert) {
			return () => setTimeOut();
		}

		if (autoClose && showAlert) {
			setTimeOut(setTimeout(() => {
				setShowAlert(false);
			}, autoCloseTimeout)) 
			return () => setTimeOut();
		}
	}, [autoClose, autoCloseTimeout, showAlert]);

	return [showAlert, setShowAlert, toggleAlert];

}

export default useAlertBox
