import React, {useState, useEffect, useContext} from 'react'
import '../assets/styles/ApplicationForm.css'
import '../assets/styles/CreateLinkToExternalForm.css'
import Spinner from 'react-spinner-material';
import {AuthContext} from '../AuthContext';

const CreateLinkToExternalForm = () => {

    const [customerEmail, setCustomerEmail] = useState({email:''})
    const [isLoaing, setIsLoading] = useState(false)
    const [data, setData] = useState(null)

    const{uri} = useContext(AuthContext);

    const user = window.localStorage.getItem('user');
    const token = window.localStorage.getItem('token');



    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

       
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GENERATE_CUSTOMER_LINK}`


        let data = new FormData();
        data.append('email', customerEmail.email);



        try{
            const rawResponse = await window.fetch(URI, {
              method: 'POST',
              headers: {           
           
              'x-api-key' : uri.URI_X_API_KEY,  
              'Authorization' : token, 
              "username" : user,         
              },
              body: data,
            
            });
           
            setIsLoading(false);      
            const content = await rawResponse.json();
            //console.log(content)

            if(content.status === 'SUCCESS'){
                setData(content)
            }
               
 
             
        
      }catch(error){
        console.log('Catch error')
        console.log(error)
        setIsLoading(false);

      }


    }


    return (
        <div className="createLink_MainContainer">
            <p className="createLink_text1 fullwidth" >The customer receives an email with a link where he/she can complete a form.</p>

            <form className="form" onSubmit={handleSubmit}>

                <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Enter the customer's email to send a link</label>
                <input
                    type="email"     
                    name="FirstName"
                    onChange={(e)=>{setCustomerEmail({email: e.target.value})}}
                    value={customerEmail.email}
                    className="campo"
                    placeholder="Customer email"
                    required
                />
                </div>

                {
                    isLoaing ? 
                    <div className="createLink_submitLoading">
                        <Spinner
                        radius={30}
                        color={"white"}
                        stroke={4}
                        visible={true}
                        />
                    </div>
                    :
                    <input
                        type="submit"
                        className="createLink_submit campo"
                        value="Send Email"
                    /> 
                }
                 

               

            </form>

            {
                data && 
                <div className="createLink_confirmationContainer">
             

                <div className="createLink_linkContainer">
                    <a href={data.uri} target="_blank"className="createLink_link" >Go to link</a>
                </div>   
                
                
                <p className="createLink_text2">{`The link was successfully sent to the email ${customerEmail.email}`}</p> 
                <br/>
                <p className="createLink_text2">We will notify you when the customer has completed the form.</p>
                
                </div>

            }        
           
        </div>
    )
}

export default CreateLinkToExternalForm
