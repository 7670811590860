import React, {useState, useContext, createContext} from 'react'
import '../../assets/styles/ApplicationsCard.css'
import {AuthContext} from '../../AuthContext';
import iconApplicationDetail from "../../assets/images/iconApplicationDetail.png"
import ProposalForm from './ProposalForm';
import Sheet from '../Sheet';
import {Link} from 'react-router-dom';


export const ProposalCardContext = createContext({})
const { Provider } = ProposalCardContext


const ProposalCard = ({proposal, applicantId, history, createCommercialProposal}) => {

    //console.log(proposal)

    

    const{setProposalInfo} = useContext(AuthContext);

    const [showSheet, setShowSheet] = useState(false);
    const [editProposal, setEditProposal] = useState(false);


    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const cantCreateApplication = window.localStorage.getItem("can_create_applications");
    const dealerCanCreateApplication = window.localStorage.getItem("dealer_can_create_applications");


    const fillProposalInfo = () => {
      
        setProposalInfo({
            info : {
                FirstName: proposal.Customer.FirstName,
                LastName: proposal.Customer.LastName,
                EmailAddress: proposal.Customer.Email,
                Address1: proposal.Customer.Address1,
                City: proposal.Customer.City,
                State: proposal.Customer.State,
                PostalCode: proposal.Customer.PostalCode,
                CellPhone: proposal.Customer.CellPhone,
                BusinessPhone: proposal.Customer.BusinessPhone,
                customerProposalId : proposal.CustomerProposalId,
                ApplicantId : applicantId,
                TotalInstalledProduct : proposal.TotalEquipmentPlusActivation,
                DownPayment: proposal.DownPayment,
            },
            hasInfo: true
        })
    }
    

    return (
        <Provider value ={{
            setShowSheet,
            showSheet
        }}>

                {showSheet &&
                <Sheet handler={()=>setShowSheet(!showSheet)}>
                    <div style={{width: '100%'}}>
                        <button className="proposalCard_editButton" onClick={()=> setEditProposal(!editProposal)}>Edit proposal</button>
                        {editProposal && <p className="proposalCard_editButton_message">The form is now editable</p>}
                        <ProposalForm 
                            history={history}
                            proposalId={proposal.CustomerProposalId || proposal.ProposalId} 
                            inDetails={true} 
                            type={editProposal ? 'create' : "edit"} 
                            showPersonalInformation 
                            editProposal={editProposal ? true : false}
                            addCustomerPropsalId
                            showCreatePdfButton={true}
                            closeSheet
                            createCommercialProposal={createCommercialProposal}
                        />
                    </div>
                    
                </Sheet>

                }

                <div  className="applicationCard_card">

                    <div className="applicationCard_card-header">
                        <div className="applicationCard_header-titleContainer">
                            <h2 className="applicationCard_header-numer">{`${proposal.CustomerProposalId || proposal.ProposalId} - ${proposal.Customer && proposal.Customer.FirstName} ${proposal.Customer && proposal.Customer.LastName}`}</h2> 
                            
                                    <span className="applicationCard_header-financialentity">{proposal.FinancialPartner && proposal.FinancialPartner.Name}</span>
                                    {/* <span className="applicationCard_header-financialentity">
                                        - {props.application.FinancialInstitutionId === 'FI-0001' ?

                                            props.application.IsStubApplication ? 'Only documents' :

                                                (props.application.FIApplicationId && props.application.FIApplicationNumber) ? <a className="applicationCard_header-link" target="_blank" href={'https://portal.aquafinance.com/applications/' + props.application.FIApplicationId + '/paperwork/application/'} >{props.application.FIApplicationNumber}</a>

                                                    : ((newFinancialInstitutionId && newFinancialInstitutionNumber) ?
                                                        <a className="applicationCard_header-link" target="_blank" href={'https://portal.aquafinance.com/applications/' + newFinancialInstitutionId + '/paperwork/application/'} >{newFinancialInstitutionNumber}</a>
                                                        : 'Processing')

                                            :

                                            props.application.FinancialInstitutionId === 'FI-0002' ?
                                                props.application.IsStubApplication ? 'Only documents' : (props.application.FIApplicationId || newFinancialInstitutionNumber || 'Processing')
                                                : 'None'}
                                    </span> */}
                             
                            
                        </div>

                        <div className="applicationCard_header-buttonsContainer">
                                <button className="applicationCard_header-DetailsButton" onClick={()=>setShowSheet(!showSheet)}>
                                    <img src={iconApplicationDetail} className="applicationCard_header-DetailsButton-img"/>
                                    <p className="applicationCard_header-DetailsButton-text" >Details</p>
                                </button>

                       
                            

                        </div>
                    </div>



                    <div className="applicationCard_card-body">

                        <div className="applicationCard_body-section">
                            <div className="applicationCard_body-section-table applicationCard_body-section-table-first">
                                <p className="applicationCard_label">Creation Date:</p>
                                <p className="applicationCard_value">{proposal.CreationDate}</p>
                                <p className="applicationCard_label">Created By:</p>
                                <p className="applicationCard_value">{proposal.UserFullName}</p>
                                <p className="applicationCard_label">Dealer:</p>
                                <p className="applicationCard_value">{proposal.DealerName}</p>
                            </div>
                            
                        </div>

                       
                        <div className="applicationCard_body-section-line"></div>

                        <div className="applicationCard_body-section">
                        
                        <p className="applicationCard_body-subtitleContainer"><span></span><span className="applicationCard_body-subtitle">Proposal Totals</span></p>
                        <div className="applicationCard_body-section-table applicationCard_body-section-table-second">
                            <p className="applicationCard_label">Total Installed Product:</p>
                            <p className="applicationCard_value">{parseFloat(proposal.TotalEquipmentPlusActivation).toFixed(2)}</p>
                            <p className="applicationCard_label">Total Amount to Finance:</p>
                            <p className="applicationCard_value">{parseFloat(proposal.TotalAmountToFinance).toFixed(2)}</p>
                            <p className="applicationCard_label">Total Monthly Payment:</p>
                            <p className="applicationCard_value">{parseFloat(proposal.TotalMonthlyPayment).toFixed(2)}</p>
                        </div>
                        
                        </div>

                        <div className="applicationCard_body-section-line"></div>
                        
                        {/* {
                            isDeallerAllyAdmin !== 'true' && cantCreateApplication === 'true' && dealerCanCreateApplication === 'true' &&
                            <div className="proposalCard-CreateNewApp_ButtonContainer">
                            <Link to={`createapplication/`} onClick={fillProposalInfo} className="proposalCard-CreateNewApp_Button">New Application</Link>
                            </div>   
                        } */}
                                  
                    
                        

                       

                       

                    </div>

                    

                    </div>
        </Provider>
    )
}

export default ProposalCard;
