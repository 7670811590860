import React, { useState, useContext } from 'react';
import '../../assets/styles/Applications.css'
import {AuthContext} from '../../AuthContext';
import { useEffect} from 'react';
import SkeletonLoader from "tiny-skeleton-loader-react";
import Paginator from 'react-hooks-paginator';
import ApplicationRow from './ApplicationRow';
import iconArrowDownGreen from '../../assets/images/iconArrowDownGreen.png';
import iconArrowDownGrey2 from '../../assets/images/iconArrowDownGrey2.png';
import iconArrowUpGreen from '../../assets/images/iconArrowUpGreen.png';
import iconArrowUpGrey2 from '../../assets/images/iconArrowUpGrey2.png';




const ApplicationsListView = ({termSearch, searchBy, filterStatusValue, filterFIValue, refreshGetApplicant, filterStatusSelected, resetPaginationFlag})=>{


   

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    
    const [applicationData, setApplicationData] = useState([]);

    const [originalData, SetoriginalData] = useState([]);

    //const [showFilter, setShowFilter] = useState(false);

    const [hasData, setHasData] = useState(true);

  
    const [filterIndex, SetFilterIndex] = useState(2);


    const [reorder, setReorder] = useState(false);

    const [propertyFilter, setPropertyFilter] = useState()

    //Pagination
    const pageLimit = 20;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);



    
    //RECUPERAR VALORES DE SESSIONSTORAGE Y CONTEXTAPI
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const endpoint = localStorage.getItem("getApplicationsEndpoint");   
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const hideBuyRate = localStorage.getItem('hideBuyRate');
    const userRole = localStorage.getItem('userRole');



    

    //FETCH GET APPLICATIONS LIST
    useEffect(()=>{
        setIsLoading(true)


        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}get-complete-and-doc-applications`
        //console.log(URI)

        const abortController = new AbortController();
        const signal = abortController.signal;


        fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : URI_X_API_KEY_I,   
              }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoading(false)
           
            //console.log(datos);
           
            if(datos.status === 'NO_APPLICATIONS_FOUND'){
                setHasData(false)
            }
           
            setApplicationData(datos);
            SetoriginalData(datos);

            setErrorFlagFromContext(false)

        })
        .catch((error, response) => {
            console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }    
        });
        

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, refreshGetApplicant]);

 

    //Pagination
    useEffect(() => {

        if(applicationData.length > 0){
            setCurrentData(applicationData.slice(offset, offset + pageLimit));       
        }else {
            setCurrentData([].slice(offset, offset + pageLimit)); 
        }
        

    }, [offset, applicationData, reorder]);

    //Reset Pagination
    useEffect(()=>{
        if(resetPaginationFlag > 0){
            setOffset(0); 
            setCurrentPage(1);
        }
    },[resetPaginationFlag])

   





    //SEARCHER AND FILTER
    useEffect(()=>{
        // console.log(searchBy)
        // console.log(termSearch)
        if(termSearch === ''){
            // console.log(filterStatusSelected)
            const results = originalData.filter(applicant => (filterStatusSelected.length > 0 ?  filterStatusSelected.includes(applicant.LoanApplicationStatus): true )
                                                            && (filterFIValue !== '' ? (applicant.FinancialInstitution || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true )
        )
        setApplicationData(results)

        }else if(searchBy === 'All'){
            //console.log(filterFIValue)
            //console.log(filterStatusValue)
            const results = originalData.filter(applicant => ((applicant.ApplicantFullName || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.DealerName || '').toLowerCase().includes(termSearch.toLowerCase())       
                                                                || (applicant.FIApplicationNumber || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.BuyRate || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.Tier || '').toLowerCase().includes(termSearch.toLowerCase()) 
                                                                || (applicant.ApplicationId || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.UserFullName || '').toLowerCase().includes(termSearch.toLowerCase()))
                                                                && (filterFIValue !== '' ? (applicant.FinancialInstitution || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true )
                                                                && (filterStatusSelected.length > 0 ?  filterStatusSelected.includes(applicant.LoanApplicationStatus): true )
            )
            //console.log(results)
            setApplicationData(results)
        }else {      
            const results = originalData.filter(applicant => (  ((applicant[searchBy] || '').toLowerCase().includes(termSearch.toLowerCase()))
                                                                && (filterStatusSelected.length > 0 ?  filterStatusSelected.includes(applicant.LoanApplicationStatus): true ) 
                                                                && ((filterFIValue !== '' ? (applicant.FinancialInstitution || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true ))  )
                                                                
            )

            setApplicationData(results)
        }
    }, [termSearch, searchBy, filterStatusValue, filterFIValue, filterStatusSelected])
    
  

 


    //SORTER BY COLUMS
    const dynamicSort =(property) => {
            setReorder(!reorder)
            setPropertyFilter(property)

            return function(a, b) {         
                if (filterIndex === 1) {
                    
                    if(b[property] === undefined){
                        b[property] = ''
                    }
                    if(a[property] === undefined){
                        a[property] = ''
                    }
                    SetFilterIndex(2)
                    if(property === 'BuyRate'){       
                        const bb = b[property].length > 0 ? parseFloat(b[property].replace("%","")) : -1
                        const aa = a[property].length > 0 ? parseFloat(a[property].replace("%","")) : -1    
                        return bb - aa
                    }
                    return b[property].localeCompare(a[property]);
                } else {
                    if(b[property] === undefined){
                        b[property] = ''
                    }
                    if(a[property] === undefined){
                        a[property] = ''
                    }
                    SetFilterIndex(1)
                    if(property === 'BuyRate'){       
                        const bb = b[property].length > 0 ? parseFloat(b[property].replace("%","")) : -1
                        const aa = a[property].length > 0 ? parseFloat(a[property].replace("%","")) : -1               
                        return aa - bb
                    }
                    return a[property].localeCompare(b[property]);
                }
              
            };
         
    }




    //LOADING CARDS
    const loadingCard = () =>{
        const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        return (list.map(item =>{
            return (
                <div className="applications_list-loading" key={item}>
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />    
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" /> 
                </div> 

            )
        }))
    }




    const arrowDirection = (property) => {

        if(property === propertyFilter && filterIndex === 1){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            )
        }else if(property === propertyFilter && filterIndex === 2 ){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }else{
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }

       
    }




    
    useEffect(() => {
        setPropertyFilter('CreationDate')
    }, [])






    return(
    <>

   
    <div style={{paddingRight: '15px'}}>
        <div className={isDeallerAllyAdmin === 'true' ? "applications_list-tittle-adminView" : "applications_list-tittle"}>
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("ApplicationId"))}}>
                <div>
                    <p>DA Nbr</p>
                </div> 
                {arrowDirection('ApplicationId')}
            </div> 
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("ApplicantFullName"))}}>
                <div>
                    <p>Applicant</p>
                </div> 
                {arrowDirection('ApplicantFullName')}
            </div>   
            {
              isDeallerAllyAdmin === 'true' &&  
               <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("DealerName"))}}>
                    <div>
                        <p>Dealer</p>
                    </div> 
                    {arrowDirection('DealerName')}
               </div> 
            }
             
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("FinancialInstitution"))}}>
                <div>
                    <p>Lender</p>
                </div> 
                {arrowDirection('FinancialInstitution')}
            </div>  
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("LoanApplicationStatus"))}}>
                <div>
                    <p>Status</p>
                </div> 
                {arrowDirection('LoanApplicationStatus')}
            </div>  
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("FIApplicationNumber"))}}>
                <div>
                    <p>Lender Nbr</p>
                </div> 
                {arrowDirection('FIApplicationNumber')}
            </div>  
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("UserFullName"))}}>
                <div>
                    <p>Created By</p>
                </div> 
                {arrowDirection('UserFullName')}
            </div> 
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("CreationDate"))}}>
                <div>
                    <p>Created (ET)</p>
                </div> 
                {arrowDirection('CreationDate')}
            </div> 
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("LastModifiedDate"))}}>
                <div>
                    <p>Modified (ET)</p>
                </div> 
                {arrowDirection('LastModifiedDate')}
            </div>  
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("RTCDate"))}}>
                <div>
                    <p>RTC Date</p>
                </div> 
                {arrowDirection('RTCDate')}
            </div> 
            <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("AmountFinanced"))}}>
                <div>
                    <p>Amount Financed</p>
                </div> 
                {arrowDirection('AmountFinanced')}
            </div> 
            {
                (hideBuyRate === 'true' && userRole === 'Sales Representative') ? null :
                <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("BuyRate"))}}>
                    <div>
                        <p>Buy Rate</p>
                    </div> 
                    {arrowDirection('BuyRate')}
                </div>  
             
            } 
            
        </div> 
    </div>


    
    <div className="applicationListView_mainContainer">

       

    
         {isLoading ? loadingCard() : null} 
        

        {
            hasData ?
            currentData.map(item => 
            
                <ApplicationRow key={item.ApplicationId} {...item} /> 
            )
            : 
            <h3 className="application_message-notApplications">No Applications submitted</h3>
        }

      

       
        
        
        <div>
            <Paginator
            totalRecords={applicationData.length}
            pageLimit={pageLimit}
            pageNeighbours={20} 
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            // pageNextClass='bg_green'
            // pagePrevClass='bg_green'
         />
        </div>


       



       



    </div>
    </>
)
}

export default ApplicationsListView;