import React, {useState, useEffect, useContext} from 'react'
import '../../assets/styles/AquaFinanceContractStatusTab.css'
import iconCheckGray from '../../assets/images/iconCheckGray.png'
import iconRefresh from '../../assets/images/iconRefresh.png'
import iconCheck from '../../assets/images/iconCheck.png'
import Spinner from 'react-spinner-material';
import {AuthContext} from '../../AuthContext';

const AquaFinanceContractStatusTab = ({applicationId, type, refreshStatusContract, showReloadButton, userName, reloadGetSignContractStatuses}) => {

    const{uri} = useContext(AuthContext);

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');







    const getContractStatusInfo = () => {

        setLoading(true)

        const abortController = new AbortController();
        const signal = abortController.signal;
    
      

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${applicationId}/paperwork-signed-status/${type}`
        // console.log(URI)
       
        window.fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : userName,
                'Authorization' : token,  
                'x-api-key' : uri.URI_X_API_KEY,
                      
              }
        })
        .then(response => response.json())
        .then(datos => {
            setLoading(false)
    
            //console.log('DETALLES DE LA APLICACION');       
            //console.log(datos)

            if(datos.Messages || datos.status){
                console.log('Error')
                setError(true)
            }else{
                setError(false)
                setData(datos)
            }
    
            
    
           
           

            
        }).catch(error => {
            console.log(error)
            setError(true)
        })
    }


    useEffect(()=>{
        getContractStatusInfo()
    }, [refreshStatusContract, reloadGetSignContractStatuses])









    return (
        <div className="aquaFinanceContractStatusTab_mainContainer">
            <p className="aquaFinanceContractStatusTab_noteText">
            Note: It may take up to a minute to create the DocuSign paperwork and to get the initial Contract Status. Click circular refresh icon to get latest Contract Status.
            </p>
            <div className="aquaFinanceContractStatusTab_header">
                <h3 className="aquaFinanceContractStatusTab_headerTitle">Contract Status</h3>
                {
                    loading ? 
                    <Spinner
                        radius={20}
                        color={"grey"}
                        stroke={3}
                        visible={true}
                    />
                    :
                    (
                        showReloadButton 
                        ? 
                        <img src={iconRefresh} onClick={getContractStatusInfo} className="aquaFinanceContractStatusTab_headerReloadIcon" /> 
                        :
                        null
                    )
                    
                }
          
                
            </div>

            {
                data.map((item, index) => {

                    // if(item.SigneeType === 'Applicant')
                    return(
                        <div className="aquaFinanceContractStatusTab_body" key={index}>
                        <p className="aquaFinanceContractStatusTab_bodyTitle">{item.SigneeType}</p>
                        <div >
                            <p className="aquaFinanceContractStatusTab_bodyDateTitle">Sent date</p>
                            <p className="aquaFinanceContractStatusTab_bodyDate">{item.DateSent || '-'}</p>
                        </div>
                        <div>
                            <p className="aquaFinanceContractStatusTab_bodyDateTitle">Signed date</p>
                            <p className="aquaFinanceContractStatusTab_bodyDate">{item.DateSigned || (item.SigneeType === 'Dealer' && item.Reviewed ? 'Reviewed' : '-' ) }</p>
                        </div>
                        {
                         ((item.DateSigned || item.Reviewed) ) ?
                         <img src={iconCheck} className="aquaFinanceContractStatusTab_bodyIconCheck" />
                         :
                         <img src={iconCheckGray} className="aquaFinanceContractStatusTab_bodyIconCheck" />
                        }
                        
                        

                    </div>
                    )
                })
            }

            {
                error &&
                <div>
                    <p className="aquaFinanceContractStatusTab_errorMessage">An unexpected error has occurred, please try again</p>
                </div>
            }
            



  
        </div>
    )
}

export default AquaFinanceContractStatusTab;
