import React from 'react'
import '../../assets/styles/ReportRow.css'
import Hint from "react-portal-hint";



const LoanApplicationDetailRow = (props) => {

  
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');

    return (
        <>


        
       

            <div className={ isDeallerAllyAdmin === 'true' ? "tableGridForAdmin31 reportRow" : "tableGrid30 reportRow"} >
                <p className="report_listItem">{props.DaNbr}</p>
                {
                   isDeallerAllyAdmin === 'true' &&
                   <p className="report_listItem">{props.Dealer }</p>
                }
                <p className="report_listItem">{props.Applicant}</p>
                <p className="report_listItem">{props.CoApplicant}</p>           
                <p className="report_listItem">{props.Lender === 'Aqua Finance' ? 'Aqua' : 'Pinnacle' }</p>
                <p className="report_listItem">{props.Status}</p>
                <p className="report_listItem">{props.StatusDate}</p>        
                <p className="report_listItem">{props.LenderNbr}</p>
                <p className="report_listItem">{props.UserFirstName}</p>
                <p className="report_listItem">{props.UserLastName}</p>
                <p className="report_listItem">{props.Created}</p>
                <p className="report_listItem">{props.RTCDate}</p>
                <p className="report_listItem">{props.AmountFinanced && parseFloat(props.AmountFinanced).toFixed(2)}</p>      
                <p className="report_listItem">{props.BuyRate }</p>
                <p className="report_listItem">{props.Tier }</p>
                <p className="report_listItem">{props.AquaPromotion }</p>
                <p className="report_listItem">{props.SaleAmount && parseFloat(props.SaleAmount).toFixed(2)}</p>
                <p className="report_listItem">{props.DownPayment && parseFloat(props.DownPayment).toFixed(2)}</p>
                <p className="report_listItem">{props.SalesTax && parseFloat(props.SalesTax).toFixed(2)}</p>
                <p className="report_listItem">{props.PinnacleProgram}</p> 
                <p className="report_listItem">{props.PinnacleTerm}</p>   
                <p className="report_listItem">{props.PinnacleAPR}</p>
                <p className="report_listItem">{props.PinnaclePaymentFactor}</p>
                <p className="report_listItem">{props.PinnacleFirstPaymentDate}</p>
                <p className="report_listItem">{props.CCDocUploadDate}</p>
                <p className="report_listItem">{props.MADocUploadDate}</p>
                <p className="report_listItem">{props.SVDocUploadDate}</p>
                <p className="report_listItem">{props.PHDocUploadDate}</p>
                <p className="report_listItem">{props.PIDocUploadDate}</p>
                <p className="report_listItem">{props.SEDocUploadDate}</p>
                <p className="report_listItem">{props.IDDocUploadDate}</p>
                <p className="report_listItem">{props.OtherDocUploadDate}</p>

                
              
            </div>
  
       

        


          
            
        </>
    )
}

export default LoanApplicationDetailRow