import React, { useState, useContext, useEffect } from 'react'
import "../../assets/styles/ApplicationForm.css";
import { AuthContext } from '../../AuthContext';
import Spinner from 'react-spinner-material';
import CreateBundleProductList from './CreateBundleProductList';
import '../../assets/styles/CreateBundleSystemProduct.css'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const CreateBundleSystemProduct = ({systemProductList, productInfo, edit, setReloadGetBundleProducts}) => {
    const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);

    const [errors, setErrors] = useState({})

    const [product, setProduct] = useState({})
    const [isSending, setIsSending] = useState(false)

    const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
    const [createdError, setCreatedError] = useState(false)
    const [productExist, setProductExist] = useState(false)


    const [productList, setProductList] = useState((productInfo && productInfo.ProductList) || [])

    const [isSystem, setIsSystem] = useState(false)

    const user = window.localStorage.getItem('user');
    const token = window.localStorage.getItem('token');


    //CREATE AND EDIT
    const createProduct = async (e) => {
        e.preventDefault()

        setCreatedSuccessfully(false)

        let URI;
        if(edit){
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/update-system-product-bundle`
        }else{
            URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/submit-system-product-bundle`
        }
        
        // console.log(URI)


        const body = {
            ProductPrice: product.ProductPrice,
            Notes: product.Notes,
            Name: product.Name
        }

        const productListFinal = productList.map(product => {return {ProductId: product.DealerProposalProductId || product.ProductId, Quantity: product.Quantity || 1}}) 

        if(productListFinal.length > 0){
            body.ProductList = productListFinal
        }

        if (edit) {
            body.Id = product.Id
        }


        if (!body.ProductPrice || !body.Name || productListFinal.length === 0) {
            const error = {}
            if (!body.Name) {
                error.Name = 'Please enter a Name'
            }
            if (!body.ProductPrice) {
                error.ProductPrice = 'Please enter a Price'
            }
            if(productListFinal.length === 0){
                error.ProductList = 'Please select a product'
            }
            setErrors({ ...error })
            return
        }
        setErrors({})


        setIsSending(true);

        // console.log(body)

        try {
            const rawResponse = await window.fetch(URI, {
                method: 'POST',
                headers: {
                    //'Access-Control-Request-Headers': 'application/json',           
                    'Content-Type': 'application/json',
                    'username': user,
                    'Authorization': token,
                    'x-api-key': uri.URI_X_API_KEY,
                },
                body: JSON.stringify(body),

            });
            setIsSending(false);
            const content = await rawResponse.json();
            // console.log(content);

            if (content.status === 'SYSTEM_ERROR' || content.status === 'PRODUCT_ERROR_IN_ORG' || content.status === 'INTERNAL_SERVER_ERROR') {
                setCreatedSuccessfully(false)
                setProductExist(false)
                setCreatedError(true)

            } else if (content.status === 'PRODUCT_ALREADY_EXISTS') {
                setCreatedError(false)
                setCreatedSuccessfully(false)
                setProductExist(true)
            } else if (content.status === 'SUCCESS') {
                setCreatedError(false)
                setProductExist(false)
                setCreatedSuccessfully(true)
                // setProduct({})
                // setProductList([])
                
                setReloadGetBundleProducts(state => (!state))
               
            }


        } catch (e) {
            console.log(e)
            setCreatedSuccessfully(false)
            setCreatedError(true)
        }


    }



    //LLENAR PARA EDITAR
    useEffect(() => {
        if (productInfo) {
            console.log(productInfo)
            setProduct({ ...productInfo })

            // if(productInfo.Manufacturer){
            //     setIsSystem(true)
            // }
        }
    }, [productInfo])



    const removeProduct = (id) => {
        const newList = productList.filter(product => product.DealerProposalProductId !== id)
        setProductList(newList)
    }




    const handleSelectProductInput=(event, id)=>{
        // console.log(id)
        const newList = productList.map(product =>{
          
          if(product.DealerProposalProductId === id ){     
            product.Quantity = event.target.value   
            return product
          }else{
            return product
          }


      })
        
      setProductList(newList)
          

    }



    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };


    const onDragEnd = (result) => {
        // console.log(result)
        const { source, destination } = result;
        if (!destination) {
          return;
        }
        if (
          source.index === destination.index &&
          source.droppableId === destination.droppableId
        ) {
          return;
        }

        setProductList((prevProduct) =>
          reorder(prevProduct, source.index, destination.index)
        );
    }






    return (
        <div className="createBundleSystemProduct">

            <div>

            <form action="" id="form" className="form">

                <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" >  Name </label>
                    <input
                        type="text"
                        name="Name"
                        onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })}
                        className={`${errors.Name ? "imputError" : "campo"}`}
                        value={product.Name || ''}
                        placeholder="Name"
                        required
                    />
                    {errors.Name && <p className="errorMessage">{errors.Name}</p>}
                </div>

                <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" > Price </label>
                    <input
                        type="number"
                        name="ProductPrice"
                        onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })}
                        className={`${errors.ProductPrice ? "imputError" : "campo"}`}
                        value={product.ProductPrice || ''}
                        placeholder="Price"
                        required
                    />
                    {errors.ProductPrice && <p className="errorMessage">{errors.ProductPrice}</p>}
                </div>

                <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" >  Notes </label>
                    <input
                        type="text"
                        name="Notes"
                        onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })}
                        className={`${errors.FirstName ? "imputError" : "campo"}`}
                        value={product.Notes || ''}
                        placeholder="Note"
                        required
                    />
                </div>







                <DragDropContext onDragEnd={onDragEnd}>

                    <div className="fullwidth campoContainer ">

                            <label className="labelApplicationForm" >  Products </label>
                            {errors.ProductList && <p className="errorMessage">{errors.ProductList}</p>}

                            <Droppable droppableId="tasks">
                                {(droppableProvided) => (

                                        <div 
                                        {...droppableProvided.droppableProps}
                                        ref={droppableProvided.innerRef}
                                        className="selectListContainer"

                                        >
                                        {
                                            
                                                productList.map((product, index) => (
                                                    <Draggable key={product.DealerProposalProductId} draggableId={product.DealerProposalProductId} index={index}>
                                                    {(draggableProvided) => (
                                                            <div 
                                                            {...draggableProvided.draggableProps}
                                                            ref={draggableProvided.innerRef}
                                                            {...draggableProvided.dragHandleProps}
                                                            className="bundleSelectItemCard"
                                                            >
                                                                    <p className="bundleSelectItemCard_name">{product.Name}</p>  
                                                                    <p className="bundleSelectItemCard_name">{`$${parseFloat(product.ProductPrice).toFixed(2)}`}</p>
                                                                    <input 
                                                                        type="text" 
                                                                        className="bundleSelectItemCard_input"
                                                                        value={product.Quantity}
                                                                        onChange={e => handleSelectProductInput(e, (product.DealerProposalProductId))}
                                                                    />  
                                                                    
                                                                    <div onClick={()=> removeProduct(product.DealerProposalProductId)} className="bundleSelectItemCard_close">&#215;</div>       
                                                            </div>
                                                    )}
                                                    </Draggable>
                                                 
                                                    
                                                ))}
                                                {droppableProvided.placeholder}
                                        </div>
                                       

                                )}
                            </Droppable>
                            
                                    
                    
                    </div>

                </DragDropContext>

                    

           
                




                {
                    isSending ?
                        <button className="fullwidth submit campo">
                            <Spinner
                                radius={25}
                                color={"white"}
                                stroke={4}
                                visible={true}
                            />
                        </button>
                        :
                        <input
                            onClick={createProduct}
                            type="submit"
                            className="fullwidth submit campo"
                            placeholder="Save Changes"
                            value="Save Changes"
                            required
                        />
                }

                {createdSuccessfully && <p className="fullwidth textColor_green">The product has been created successfully.</p>}

                {createdError && <p className="fullwidth textColor_red">An error occurred creating the product. Please try again.</p>}

                {productExist && <p className="fullwidth textColor_red">This product already exists.</p>}





            </form>

            </div>



            <CreateBundleProductList systemProductList={systemProductList} setProductList={setProductList} productList={productList}/>


        </div>

    )
}


export default CreateBundleSystemProduct;
