import React from 'react';
import Sidebar from './Sidebar';
import { AuthConsumer } from '../AuthContext';
import '../assets/styles/Layout.css'

const Layout = ({ children })=>(
    <AuthConsumer>
        {({ isAuth }) => (
            <div className="layout">
                {isAuth ?  <Sidebar /> : null}
                {children}
            </div>)}
    </AuthConsumer>
);


export default Layout;