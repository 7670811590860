import React, {useState, useEffect, useContext} from 'react';
import '../assets/styles/Applications.css'
import '../assets/styles/ReportRow.css'
import '../assets/styles/BasicLayout.css'
import '../assets/styles/Reports.css'
import iconArrowRightGray from '../assets/images/iconArrowRightGray.png'
import iconArrowBack from '../assets/images/iconArrowBack.png'
import RemittanceReport from '../components/reporting/RemittanceReport'
import LoanApplicationDetailReport from '../components/reporting/LoanApplicationDetailReport';





const Reports = ()=>{


   const [step, setStep] = useState(1)

   const userRole = localStorage.getItem('userRole');

 

    return(
    <>
    <div className="pageGeneralContainer">
        <div className="mainHeaderTittle">
            {
                step === 1 ?
                <h2>Reports</h2>

                :

                step === 2 ?
                <h2 onClick={()=>setStep(1)}>
                    <img src={iconArrowBack} alt="back" style={{height: '24px', marginRight: '10px'}}/>
                    <span  className="reports_headerBack">Reports /</span> Remittance Report
                </h2>

                :

                step === 3 ?
                <h2 onClick={()=>setStep(1)}>
                    <img src={iconArrowBack} alt="back" style={{height: '24px', marginRight: '10px'}}/>
                    <span className="reports_headerBack">Reports /</span> Loan Application Detail Report
                </h2>
                :

                null
            }
            
        </div>

        {
            step === 1 ?
            <div className="reports_selectReport-container">
                {
                    (userRole === 'Manager' || userRole === 'DealerAlly Admin') && 
                    <div className="reports_reportRow" onClick={()=> setStep(2)}>
                        <p>Remittance Report</p>
                        <img src={iconArrowRightGray} alt="view report"/>
                    </div>
                }
                
                <div className="reports_reportRow" onClick={()=> setStep(3)}>
                    <p>Loan Application Detail Report</p>
                    <img src={iconArrowRightGray} alt="view report"/>
                </div>
            </div>

            :

            step === 2 ?
            <RemittanceReport />

            :

            step === 3 ?
            <LoanApplicationDetailReport />

            :

            null

        }
       


       
        
    </div>

    
    

    </>
)
}

export default Reports;



