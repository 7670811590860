import React, {useState, useContext} from 'react';
import '../assets/styles/UserCard.css'
import EditUser from './EditUser';
import Sheet from './Sheet'
import Register from '../containers/Register'
import {AuthContext} from '../AuthContext';

const UserCard = (props)=>{

    const{uri} = useContext(AuthContext);


    const canEditUsers = window.localStorage.getItem('can_edit_users');
    

    const [showSheet, setShowSheet] = useState(false);

    const showSheetHandler = ()=>{
        if(canEditUsers === 'true'){
            setShowSheet(!showSheet);
        }
    }


        return(
            

                    
            <>  
                {showSheet ?
                    (
                        props.is_basic_user ? 
                        
                        <Sheet handler={showSheetHandler}>
                            <Register safari dealerIdProp={props.dealer_id} showTitle type="edit" {...props} converBasicUser/> 
                        </Sheet>
                        :
                        <EditUser handler={showSheetHandler} username={props.username} reload={props.reload} userDealerId={props.dealer_id}/>
                    )
                   
                : null} 


                <div className="user-card" onClick={showSheetHandler}>
                    <p className="user-card_value user-card_name">{props.first_name + " "+props.last_name}</p>
                  
                    <p className="user-card_value user-card_status">{props.status}</p>
                    <p className="user-card_value user-card_dealer">{props.dealer_name}</p>
                    <p className="user-card_value user-card_role">{props.role}</p>
                    <p className="user-card_value user-card_username">{props.username}</p>
                    <p className="user-card_value user-card_date">{props.created_date}</p>
                </div>
            
            </>
        );

}

export default UserCard;