import React, {useState} from 'react'
import '../../assets/styles/LogRow.css'
import Sheet from '../Sheet'
import {AuthContext} from '../../AuthContext';

const LogRow = (props) => {

    const [showSheet, setShowSheet] = useState(false);

    const showSheetHandler = ()=>{
            setShowSheet(!showSheet); 
    }

    return (
        <>  
        {showSheet &&  
            <Sheet handler={showSheetHandler}>
                <div className="log-card_codeContainer">
                    <code>{props.message}</code>
                </div>    
            </Sheet>    
        } 


        <div className="log-card" onClick={showSheetHandler}>
            <p className="log-card_value log-card_name">{props.logId}</p>
            <p className="log-card_value log-card_name">{props.entryDate}</p>
          
            <p className="log-card_value log-card_status">{props.logLevel}</p>
            <p className="log-card_value log-card_dealer">{props.logger}</p>
            
        </div>
    
    </>
    )
}

export default LogRow;
