import React, {useState, useEffect, useContext} from 'react';
import '../assets/styles/Applications.css'
import {Link} from 'react-router-dom';
import iconAdd from '../assets/images/iconAdd.png';
import {AuthContext} from '../AuthContext';
import ApplicationsListView from '../components/applicationsPage/ApplicationsListView'
import ApplicanstCardsView from '../components/applicationsPage/ApplicantsCardsView'
import iconLayoutList from '../assets/images/iconLayoutList.png'
import iconLayoutCards from '../assets/images/iconLayoutCards.png'
import iconRefresh from '../assets/images/iconRefresh.png';
import AlertBanner from '../components/AlertBanner'
import MultiSelect from "react-multi-select-component";




const Applicants = ({history})=>{


   // const [showFilter, setShowFilter] = useState(false);
    const{setProposalInfo} = useContext(AuthContext);

    const [layoutCards, setLayoutCards] = useState( localStorage.getItem('applicant_layout') || 'true')

    //SEARCH 
    const [termSearchCard, setTermSearchCard] = useState('')
    const [termSearchList, setTermSearchList] = useState('')
    const [searchBy, setSearchBy] = useState('All')

    //FILTERS STATUS 
    const [filterStatusValue, setFilterStatusValue] = useState('')

    //FILTERS STATUS 
    const [filterFIValue, setFilterFIValue] = useState('')

    //APPLICANT ENDPOINTS
    const [callApplicantRealList, setCallApplicantRealList] = useState(localStorage.getItem('applicant_callApplicantRealList') || 'true')

    const [refreshGetApplicant, setRefreshGetApplicant] = useState(false)

    const [reloadAlertBanner, setReloadAlertBanner] = useState(false)

    const [disableStatusFilter, setDisableStatusFilter] = useState(false)

    const [resetPaginationFlag, setResetPaginationFlag] = useState(0)
    

    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const cantCreateApplication = window.localStorage.getItem("can_create_applications");
    const canCreateProposal = window.localStorage.getItem("allow_customer_proposals");
    const dealerCanCreateApplication = window.localStorage.getItem("dealer_can_create_applications");



    useEffect(()=>{
        localStorage.setItem('applicant_layout', layoutCards);
        localStorage.setItem('applicant_callApplicantRealList', callApplicantRealList);
    },[layoutCards, callApplicantRealList])





    //RESPONSIVE LOGIC
    const getWidth = () => window.innerWidth 
    || document.documentElement.clientWidth 
    || document.body.clientWidth;
    const [width, setWidth] = useState(getWidth());
    const useCurrentWitdh =()=> {
        
        useEffect(() => { 
            let timeoutId = null;
            const resizeListener = () => {
            clearTimeout(timeoutId); 
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
            };    
            window.addEventListener('resize', resizeListener);      
            return () => {
            window.removeEventListener('resize', resizeListener);
            }
        }, [])
        
        return width; 
    }
    useCurrentWitdh()


    useEffect(() => {    
        if(width < 1050){
            setLayoutCards('true')
        }
    }, [width])







    // MULTISELECT
    const options = [
        { label: "Approved", value: "Approved" },
        { label: "Denied", value: "Denied" },
        { label: "Error", value: "Error" },
        { label: "Pending", value: "Pending" },
        { label: "Processing", value: "Processing", },
        { label: "FilledOut", value: "FilledOut" },
        { label: "SignReady", value: "SignReady" },
        { label: "Submitted", value: "Submitted" },
        { label: "ConditionallyApproved", value: "ConditionallyApproved" },
        { label: "Received", value: "Received" },
        { label: "PendingDocs", value: "PendingDocs" },
        { label: "InVerification", value: "InVerification" },
        { label: "InConfirmation", value: "InConfirmation" },
        { label: "Verified", value: "Verified" },
        { label: "ReadyToFund", value: "ReadyToFund" },
        { label: "FundingComplete", value: "FundingComplete" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "OnHold", value: "OnHold" },
        { label: "Problem", value: "Problem" },
        { label: "SendBack", value: "SendBack" },
        { label: "SignComplete", value: "SignComplete" },
      ];

    const [statusSelected, setStatusSelected] = useState([]);
    const [filterStatusSelected, setFilterStatusSelected] = useState([]);


    const customValueRenderer = (selected, _options) => {
        if(statusSelected.length && disableStatusFilter){
            return "Status"
        }else if(statusSelected.length){
            return null
        }else{
            return "Status"
        }
    // return statusSelected.length
    //   ? null
    //   : "Status";
    };


    //LIMPIAR LA INFORMACION DE PROPOSAL DEL CONTEXT
    useEffect(()=>{
    setProposalInfo({info:{}, hasInfo: false})
    },[])




    useEffect(()=>{

    const newList = statusSelected.map(item => item.value)
    setFilterStatusSelected(newList)
    
    
    },[statusSelected])




      const handleShowActiveApplications = () => {
        setDisableStatusFilter(!disableStatusFilter)

        if(disableStatusFilter){
            setFilterStatusSelected([])
            setStatusSelected([])
        }else{
            // setStatusSelected([])
            setFilterStatusSelected(["FilledOut", "Submitted", "Processing", "Pending", "ConditionallyApproved", "Approved", "SignReady", "Received", "PendingDocs"])
        }
        
      }

     
   




 

    return(
    <>
    
    <div className="applications" >

        <AlertBanner reloadAlertBanner={reloadAlertBanner}/>

        {/* HEADER */}
        <div className="applications-header">
    
            <div className="applications-header-tittle">
                {
                    layoutCards === 'true' ?
                    <h2>Residential Applications <span className="applications-header-tittle-hide">Main View</span></h2> 
                    :
                    <h2>Residential Applications List View</h2> 

                }  
                <img src={iconRefresh} 
                    className="application_header-refreshIcon" 
                    onClick={()=>{  setRefreshGetApplicant(!refreshGetApplicant); 
                                    setReloadAlertBanner(!reloadAlertBanner);
                                    setTermSearchCard(''); 
                                    setTermSearchList(''); 
                                    setSearchBy('All'); 
                                    setFilterStatusValue('');
                                    setFilterFIValue('')
                                    setStatusSelected([])
                                    setDisableStatusFilter(false)
                                    setResetPaginationFlag(resetPaginationFlag + 1)
                                }}
                />  
            </div>


            <div className="aplications-header-buttoms">

                {
                    canCreateProposal === 'true' && isDeallerAllyAdmin !=='true' &&
                    <Link to="/createproposal">
                        <div className="aplications-header-buttoms_proposal">           
                            <label className="aplications-header-buttoms_new_text_1">New Proposal</label>       
                        </div>
                    </Link>
                }
                
    

                 {  
                     //isDeallerAllyAdmin != 'true'  && (dealerCanCreateApplication === 'true' && waitingForActivation != 'true') ?
                     isDeallerAllyAdmin != 'true' && dealerCanCreateApplication === 'true' ?
                        cantCreateApplication === 'true' ? 
                            <>
                            
                            <Link to="/createapplication">
                            <div className="aplications-header-buttoms_new">
                            
                                <img src={iconAdd} className="aplications-header-buttoms_new_icon" />

                                {/* <img src={iconAddGreen} className="aplications-header-buttoms_new_icon" /> */}
                                
                                <p className="aplications-header-buttoms_new_text">
                                    <label className="aplications-header-buttoms_new_text_1">New Application</label> 
                                     {/* <label className="aplications-header-buttoms_new_text_2"> Application</label> */}
                                </p>
                            </div>
                            </Link>
                            </>
                        : null

                    : null
                } 

            </div>    
        </div>

         {/**********************************************   ***************************************************/}

        <div className="applications_filterContainer">

            <div className="applications_filterLeft">

                {
                    layoutCards === 'true' ?


                    
                    
                            /* SEARCHER CARDS*/
                            <div className="applications_filter-searchContainer">
                            <input type="text" placeholder="Search by Applicant Name" value={termSearchCard} className="applications_filter-searchInput"  onChange={(e)=>{setTermSearchCard(e.target.value)}}/>
                            <span className="applications_filter-searchCloseIcon" onClick={()=>setTermSearchCard('')}>x</span>
                            </div>

                            : 

                            <>
                            {/* SEARCHER */}
                            <div className="applications_filter-searchContainerList">
                            <input type="text" placeholder="Search" value={termSearchList} className="applications_filter-searchInput" onChange={(e)=>{setTermSearchList(e.target.value)}}/>
                            <span className="applications_filter-searchCloseIcon" onClick={()=>setTermSearchList('')}>x</span>
                            </div>



                            {/* SEARCHER FILTERS */}
                            <div className="application_contaner-filterSearch">
                                <select onChange={(e)=>{setSearchBy(e.target.value)}} className="aplications-header-buttoms_filter square" value={searchBy}>
                                    <option value="All" selected>By Any</option>
                                    <option value="ApplicationId">By Id</option>
                                    <option value="ApplicantFullName">By Name</option>
                                    {
                                        isDeallerAllyAdmin === 'true' && 
                                        <option value="DealerName">By Dealer</option>
                                    }
                                    
                                    {/* <option value="FinancialInstitution">By Lender</option> */}
                                    {/* <option value="LoanApplicationStatus">By Status</option> */}
                                    <option value="FIApplicationNumber">By Number</option>
                                    <option value="UserFullName">By Creator</option>
                                    {/* <option value="PI">By Creation Date</option>
                                    <option value="MA">By Update Date</option> */}
                                    <option value="BuyRate">By BuyRate</option>
                                    {/* <option value="Tier">By Tier</option> */}
                                
                                </select>
                            </div>
                           





                            {/* LENDER FILTER */}
                            <div className="application_contaner-filterSelect">
                                <select onChange={(e)=>{setFilterFIValue(e.target.value)}} className="aplications-header-buttoms_filter" value={filterFIValue}>
                                    <option value="" disabled selected>Lender</option>
                                    <option value="">All Lenders</option>
                                    <option value="Aqua Finance">AquaFinance</option>
                                    <option value="Pinnacle Finance">Pinnacle</option>
                                </select>
                            </div>


                             {/* STATUS FILTER */}


                             <div className={disableStatusFilter ? "application_contaner-filterSelectDisabled" : "application_contaner-filterSelect"}>
                                <MultiSelect
                                    options={options}
                                    value={statusSelected}
                                    onChange={setStatusSelected}
                                    // onChange={(list)=>{handleSelectStatus(list) }}
                                    labelledBy={"Status"}
                                    disableSearch
                                    selectAllLabel={"All Status"}
                                    valueRenderer={customValueRenderer}
                                    disabled={disableStatusFilter}
                                    className={disableStatusFilter ? "statusMultiSelectDisabled" : "multi-select"}
                                />
                            </div>

                            <div className="selectActiveApplications">
                                <input type="checkbox" onChange={handleShowActiveApplications} {...disableStatusFilter ? { checked: "checked" } : null}/>
                                <label className="selectActiveApplications_text">Active Applications</label>
                            </div>


                    </>
                }
                
            </div>

            <div className="applications_togglesRightContainer">

               
                    <div className="applications_layoutButtonsContainer applications_marginRight15 layoutTabs">
                        <div onClick={() => { setCallApplicantRealList('true') }} className={`${callApplicantRealList === 'true' ? "applications_layoutButtons applications_layoutButtons-left applications_layoutButtons-select" : "applications_layoutButtons applications_layoutButtons-left "}`}>
                            <p>Applications</p>
                        </div>
                        <div onClick={() => { setCallApplicantRealList('false'); setLayoutCards('true'); setTermSearchCard('') }} className={`${callApplicantRealList === 'false' ? "applications_layoutButtons applications_layoutButtons-right applications_layoutButtons-select" : "applications_layoutButtons applications_layoutButtons-right "}`}>
                            <p>Prefilled</p>
                        </div>
                    </div>
               
                


                <div className="applications_layoutButtonsContainer application_applications_layoutButtonsContainer_movil">
                    <div onClick={()=>{setLayoutCards('false'); setTermSearchList(''); setCallApplicantRealList('true')}} className={`${ layoutCards === 'true' ? "applications_layoutButtons applications_layoutButtons-left" : "applications_layoutButtons applications_layoutButtons-left applications_layoutButtons-select"}`}>
                        <img src={iconLayoutList} alt="Layout List" className="applications_layoutButtonsIcon "/>
                    </div>
                    <div onClick={()=>{setLayoutCards('true'); setTermSearchCard('')}} className={`${ layoutCards === 'true' ? "applications_layoutButtons applications_layoutButtons-right applications_layoutButtons-select" : "applications_layoutButtons applications_layoutButtons-right "}`}>
                        <img src={iconLayoutCards} alt="Layout Card" className="applications_layoutButtonsIcon"/>
                    </div>
                    {/* <button onClick={()=>{ layoutCards === 'true' ? setLayoutCards('false') : setLayoutCards('true')}}>Switch</button> */}
                </div>
                
            </div>    
            

        
        </div>


        {/**********************************************  VERSION 2 ***************************************************/}
        
        {
            layoutCards === 'true' ?
            <ApplicanstCardsView 
                history={history}
                termSearch={termSearchCard} 
                callApplicantRealList={callApplicantRealList}
                refreshGetApplicant={refreshGetApplicant}
               
            /> 
            :
            <ApplicationsListView 
                termSearch={termSearchList} 
                searchBy={searchBy} 
                filterStatusValue={filterStatusValue}
                filterFIValue={filterFIValue}
                refreshGetApplicant={refreshGetApplicant}
                filterStatusSelected={filterStatusSelected}
                resetPaginationFlag={resetPaginationFlag}
            />
        }
        

        {/* <ApplicanstCardsView /> */}

      
        

        
        
        
       


       



       

       
            









    </div>
    </>
)
}

export default Applicants;


