import React, {useState, useEffect} from 'react'
import '../../assets/styles/ProposalsProductTab.css'
import iconDelete from '../../assets/images/iconDelete.png';
import AddProductPopUp from './AddProductPopUp';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ProposalsProductsTab = ({type, productList = false, validateDefaultProductList, empyList = false, financingTermsSelectList, title, setMonitoringValue, setEquipmentMonthlyPayment, setTabHasPaymentFactor, opcionalProducts, setDefaultProductList, setExtPrice, showAllCollumns, showPrice, showMonthly, setExtProductsMthly, setExtMonitoringMthly, PaymentFactor, isDragDisabled, productsWithOutValidate}) => {

  

    const [products, setProducts] = useState([]);
    const [errorProductList, seterrorProductList] = useState([{}])
    const [isSubmitiongProducts, setIsSubmitingProducts] = useState(false);
    const [paymentFactor, setPaymentFactor] = useState(1)
    const [hasPaymentFactor, sethasPaymentFactor] = useState(false)


    const [showSheet, setShowSheet] = useState(false);

    const [voidValidation, setVoidValidation] = useState(false)

    //TOGLES
    // const [showPrice, setShowPrice] = useState(true)
    // const [showMonthly, setShowMonthly] = useState(true)



    //TOTALES DE LA TABLA
    const [totalQuantities, setTotalQuantities] = useState(0)
    const [totalExtPrice, setTotalExtPrice] = useState(0)
    const [totalMonthly, setTotalMonthly] = useState(0)
    const [totalIndividualMonthly, setTotalIndividualMonthly] = useState(0)

    const [addProductList, setAddProductList] = useState([])


    
 


    useEffect(()=>{ 
      if(productList.length > 0 || empyList){
       
        const numErrors = productList.map(item => { return {} })
        seterrorProductList(numErrors)
      
        setProducts(productList)
        setVoidValidation(true)
     
      }
      
    },[productList])



    useEffect(() => {
      productsWithOutValidate(products)
    }, [products])




    //LLENAR LA TABLA DE ADDPRODUCTLIST
    useEffect(()=>{

      if(opcionalProducts.length > 0 ){
        setAddProductList(opcionalProducts)
      }
        
    },[opcionalProducts])







    //PAYMENT FACTOR
    useEffect(()=>{
      if(financingTermsSelectList && financingTermsSelectList.PaymentFactor){
        sethasPaymentFactor(true)
        setTabHasPaymentFactor(true)
        setPaymentFactor(parseFloat(financingTermsSelectList.PaymentFactor))
        // console.log(parseFloat(financingTermsSelectList[0].PaymentFactor))
      }else if(type === "edit"){
        sethasPaymentFactor(true)
        setTabHasPaymentFactor(true)
        PaymentFactor && setPaymentFactor(PaymentFactor)  
      }
      else{
        sethasPaymentFactor(false)
        setTabHasPaymentFactor(false)
      } 
    },[financingTermsSelectList])


    useEffect(()=>{
      if(validateDefaultProductList > 0){
        seterrorProductList(validateProduct(products))
        setIsSubmitingProducts(true)
      }

    },[validateDefaultProductList])



    useEffect(() => {
      let errInc = []
      
      errorProductList.forEach(error => {
        if( Object.keys(error).length != 0){
          errInc = [ ...errInc, false]
        }
        
      })

      if (isSubmitiongProducts && errInc.length === 0) {
   
        // console.log('OK')
        setDefaultProductList({'products' : products, 'error' : false})
      } else{
        // console.log('entra')
        // console.log(errInc)
        setDefaultProductList({'products' : [], 'error' : true})
      }

    }, [errorProductList, isSubmitiongProducts]);





    //TOTAL QUANTITIES
    useEffect(()=>{
      let total = 0;
      
      products.forEach(products => {
        total = total + parseInt(products.Quantity)
      })
      setTotalQuantities(total)

    },[products])



    //TOTAL EXTPRICE
    useEffect(()=>{
      let total = 0;
      
      products.forEach(products => {
        total = total + (products.ProductPrice * parseInt(products.Quantity))
      })
      setTotalExtPrice(total.toFixed(2))
      if(setExtPrice){
        setExtPrice(total.toFixed(2))
      }
      

    },[products])

    

    //TOTAL MONTHLY
    useEffect(()=>{
      
    //PRODUCTS
    if(setMonitoringValue){
      let total = 0;  
      products.forEach(products => {
        total = total + ((parseFloat(products.ProductPrice) * parseFloat(products.Quantity)) ) 
      })

      setTotalMonthly(parseFloat(total).toFixed(2))

      setMonitoringValue(parseFloat(total).toFixed(2))
    }



    //MONITORING
    if(setEquipmentMonthlyPayment){
      let total = 0;  
      products.forEach(products => {
        total = total + (paymentFactor * (parseFloat(products.ProductPrice) * parseFloat(products.Quantity)) ) 
      })

      setTotalMonthly(parseFloat(total).toFixed(2))

      setEquipmentMonthlyPayment(parseFloat(total).toFixed(2))

      if(setExtProductsMthly){
        setExtProductsMthly(parseFloat(total).toFixed(2))
      }
    }
   

    },[products, paymentFactor])


    //TOTAL INDIVIDUAL MONTHLY
    useEffect(()=>{
      let total = 0;
      
      products.forEach(products => {
        // console.log(paymentFactor)
        // console.log( (parseInt(products.ProductPrice) * parseInt(products.Quantity)) )
        if(title === 'Installed Product'){
          total = total + (paymentFactor * parseFloat(products.ProductPrice)) 
        }else{
          total = total + (parseFloat(products.ProductPrice)) 
        }
       
      })

     

      setTotalIndividualMonthly(parseFloat(total).toFixed(2))

    
   

    },[products, paymentFactor])




      const addProduct=(name, quantity, id, price, productList)=>{

       
          let value = false
        
          for(let i = 0; i < products.length; i++) {
              if (products[i].DealerProposalProductId === id) {
                      value = true  
              }
          }
          
          if(!value){
            seterrorProductList([...errorProductList, {}])
            setProducts([
              ...products,
              {
                Name: name,
                Quantity: quantity,
                ProductPrice: price,
                DealerProposalProductId: id,
                ProductList: productList,
              }
            ]) 
          }

          
        } 

      
      const handleProductInput=(event, id)=>{

            const newList = products.map(product =>{
              
              if(product.DealerProposalProductId === id){
                if(event.target.name === 'Quantity'){
                    product.Quantity = event.target.value
                  }
                
                return product

              }else{
                return product
              }
          })
            
          setProducts(newList)  
      }


      const deleteProduct=(id, index)=>{

        const newList = products.filter(product => product.DealerProposalProductId !== id) 
        setProducts(newList);

        const newErrorList = errorProductList.filter((error, i)=> errorProductList.indexOf(error) !== index)
        seterrorProductList(newErrorList)


      } 


      const validateProduct=(dealerProductList)=>{


          const errordealerProductList = products.map((item)=>{


            let errorList = {};

            if (!item.Quantity) {
              errorList.Quantity = "Please enter a value";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(item.Quantity)) {
              errorList.Quantity = "The value can only contain numbers";
            }else if (item.Quantity <= 0) {
              errorList.Quantity = "The value can only contain numbers";
            }
      
            
            if(errorList.lenght !== 0){
              return errorList;
            }
            
            
          })

 
          //console.log(errordealerProductList)
          

          if(errordealerProductList.length === 0){
            const vacio = [{}]
            return vacio
          }else{
            return errordealerProductList
          }
          
      
      }



      const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };




      const onDragEnd = (result) => {
        // console.log(result)
        const { source, destination } = result;
        if (!destination) {
          return;
        }
        if (
          source.index === destination.index &&
          source.droppableId === destination.droppableId
        ) {
          return;
        }

        setProducts((prevProduct) =>
          reorder(prevProduct, source.index, destination.index)
        );
    }


    const totalBundle = (bundle, type) => {

      let value = '0'
      if(type === 'price'){
        let totalBundlePrice = 0;  
        bundle.forEach(products => {
          totalBundlePrice = totalBundlePrice + ((parseFloat(products.ProductPrice)) ) 
        })
        value = parseFloat(totalBundlePrice).toFixed(2) 

      }else if(type === 'extPrice'){
        let totalBundleExtPrice = 0;  
        bundle.forEach(products => {
          totalBundleExtPrice = totalBundleExtPrice + ((parseFloat(products.ProductPrice) * parseFloat(products.Quantity)) ) 
        })
        value = parseFloat(totalBundleExtPrice).toFixed(2) 

      }else if(type === 'monthly'){
        let totalBundleMonthly = 0;  
        bundle.forEach(products => {
          totalBundleMonthly = totalBundleMonthly + (paymentFactor * parseFloat(products.ProductPrice)) 
        })
        value = parseFloat(totalBundleMonthly).toFixed(2) 

      }else if(type === 'extMonthly'){
        let totalBundleExtMonthly = 0;  
        bundle.forEach(products => {
          totalBundleExtMonthly = totalBundleExtMonthly + (paymentFactor * parseFloat(products.ProductPrice) * parseFloat(products.Quantity)) 
        })
        value = parseFloat(totalBundleExtMonthly).toFixed(2) 
      }

      return value || '0'
    }




    return (
        <>   
            {showSheet &&  <AddProductPopUp 
                              setShowSheet={setShowSheet} 
                              opcionalProducts={addProductList} 
                              addProduct={addProduct} 
                              paymentFactor={paymentFactor} 
                              hasPaymentFactor={hasPaymentFactor} 
                              showMonthly={showMonthly} 
                              showPrice={showPrice}
                              title={title === 'Installed Products' ? 'Add Product' : 'Add Service'}
                              />}
           

            <div className="proposalsProductsTabMainContainer">
                <h3 className="proposalsProducts_titleTab">{title}</h3>


                <div className="proposalProductRow_header">
                    <p className="proposalProductRow_header-title">{title}</p>
                    {showAllCollumns && <p className="proposalProductRow_header-title">Qty</p>}

                    {
                      title === 'Installed Products' ? 
                      <p className="proposalProductRow_header-title">{showMonthly ? 'Monthly' : ''}</p>
                      :
                      <p className="proposalProductRow_header-title">{ 'Monthly' }</p>
                    }
                    
                    {/* <p className="proposalProductRow_header-title">{showMonthly && title === 'Installed Products' ? 'Monthly' : title !== 'Installed Products' ? 'Monthly' : ''}</p> */}
                    {showAllCollumns && <p className="proposalProductRow_header-title">{showMonthly ? 'Ext Mth' : ''}</p>} 
                    {showAllCollumns && <p className="proposalProductRow_header-title">{showPrice ? 'Price' : ''}</p>} 
                    {showAllCollumns && <p className="proposalProductRow_header-title">{showPrice ? 'Ext Price' : ''}</p>}  
                    
                    {type !== "edit" && <div className="deleteIncome-icon_vacio" />}
                    
                </div>





                <DragDropContext onDragEnd={onDragEnd}>

                <Droppable droppableId="tasks">
                    {(droppableProvided) => (
                        <div
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          
                        >
                           {
                             
                             products && products.map((item, index) => (


                              <Draggable key={item.DealerProposalProductId} draggableId={item.DealerProposalProductId} index={index} isDragDisabled={type === 'edit'}>
                              {(draggableProvided) => (
                                
                                   <div
                                    {...draggableProvided.draggableProps}
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.dragHandleProps}
                                   >


                                  <div className="proposalProductRow" >
                                            <p className="proposalProductName">{item.Name}</p>
          
                                            {showAllCollumns && 
                                            <div>
                                              <input
                                                type="number"
                                                name="Quantity"
                                                value={item.Quantity}
                                                onChange={(event)=>{handleProductInput(event, item.DealerProposalProductId)}} 
                                                placeholder="Quantity"
                                                className={`${errorProductList[index].Quantity? "proposalTabInputError" : "proposalTabInput"}`} 
                                                required
                                                disabled={type === 'edit' ? "disabled" : null}
                                              />
                                              {/* {errorProductList[index].Quantity&& <p className="errorMessage">{errorProductList[index].Quantity}</p>}  */}
                                            </div>
                                            }
          
                                            {/* Monthly */}   
                                            {
                                              title === 'Installed Products' ? 
                                              <p className="proposalProductName">{hasPaymentFactor && showMonthly && `$ ${(paymentFactor * item.ProductPrice).toFixed(2)}` }</p>
                                              :
                                              <p className="proposalProductName">{hasPaymentFactor &&  `$ ${( item.ProductPrice ).toFixed(2)}`} </p>
                                            }
          
                                            {/* EXT Monthly */}
                                            {showAllCollumns && <p className="proposalProductName">{showMonthly && hasPaymentFactor ? `$ ${(paymentFactor * (item.ProductPrice * parseInt(item.Quantity))).toFixed(2)}` : ''}</p>}
          
                                            {/* Price */}
                                            {showAllCollumns && <p className="proposalProductName">{showPrice ? `$ ${item.ProductPrice.toFixed(2)}` : ''}</p>}
          
                                            {/* Ext Prc */}
                                            {showAllCollumns && <p className="proposalProductName">{showPrice ? `$ ${(item.ProductPrice * parseInt(item.Quantity)).toFixed(2)}` : ''}</p>}
                                          
          
                                            {
                                              type !== "edit" &&
                                              <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteProduct(item.DealerProposalProductId, index)}}/>
          
                                            }
                                          
                                        </div>
                                        

                                        {/* BUNDLES */}
                                        {
                                          item && item.ProductList && item.ProductList.length > 0 && item.ProductList.map(product => {return(
                                            <div className="proposalProductRow proposalProductSubRow" key={product.DealerProposalProductId}>
                                              <p className="proposalProductName">· {product.Name}</p>
                                              <p className="proposalProductQuantity">{product.Quantity}</p>
                                              <p className="proposalProductQuantity">{hasPaymentFactor && showMonthly && `$ ${(paymentFactor * product.ProductPrice).toFixed(2)}` }</p>
                                              <p className="proposalProductQuantity">{hasPaymentFactor && showMonthly ?`$ ${(paymentFactor * (product.ProductPrice * parseInt(product.Quantity))).toFixed(2)}` : '' }</p>
                                              {showAllCollumns && <p className="proposalProductQuantity">{showPrice ? `$ ${product.ProductPrice.toFixed(2)}` : ''}</p>}
                                              <p className="proposalProductQuantity">{showPrice ? `$ ${(product.ProductPrice * parseInt(product.Quantity)).toFixed(2)}` : ''}</p>
                                              {
                                                type !== "edit" &&
                                                <div className="deleteIncome-icon_vacio" />
                                              }
                                              
                                            </div>
                                          )})
                                        }
                                        {
                                          item && item.ProductList && item.ProductList.length > 0 &&
                                          <div className="proposalProductRow proposalProductSubRow proposalProductSubRowTotalDivider" >
                                              <p className="proposalProductName"></p>
                                              <p className="proposalProductQuantity"></p>
                                              <p className="proposalProductQuantity"></p>
                                              <p className="proposalProductQuantity">{hasPaymentFactor && showMonthly ? `$ ${totalBundle(item.ProductList, 'extMonthly')}` : '' }</p>
                                              {showAllCollumns && <p className="proposalProductQuantity"></p>}
                                              <p className="proposalProductQuantity">{showPrice ? `$ ${totalBundle(item.ProductList, 'extPrice')}` : ''}</p>
                                              <div className="deleteIncome-icon_vacio" />
                                              
                                          </div>
                                        }

                                    </div>

                                )}
                              </Draggable>
                      
                             
                          ))} 
                          {droppableProvided.placeholder}



                        </div>
                        
                        )}
                </Droppable>

                </DragDropContext>



  

                {/* TOTAL ROW */}
                <div className="proposalProductRow_header proposal_bgGrey">
                    <p className="proposalProductRow_header-title">Total</p>
                    {showAllCollumns && <p className="proposalProductRow_header-title">{totalQuantities}</p>}
                    {showAllCollumns && <p className="proposalProductRow_header-title"></p>}
                    {!showAllCollumns && <p className="proposalProductRow_header-title">{showMonthly && hasPaymentFactor ? `$ ${totalIndividualMonthly}` : ''}</p>}
                    {showAllCollumns && <p className="proposalProductRow_header-title">{showMonthly && hasPaymentFactor ? `$ ${totalMonthly}` : ''}</p>}
                    {showAllCollumns && <p className="proposalProductRow_header-title"></p>}
                    {showAllCollumns && <p className="proposalProductRow_header-title">{showPrice ? `$ ${totalExtPrice}` : ''}</p>}

                    {type !== "edit" && <div className="deleteIncome-icon_vacio" />}
                    
                </div>

                {title === 'Installed Products' && type !== "edit" && <p>*Monthly amounts are subject to rounding.</p>}
               


                {
                  type !== 'edit' &&
                  <button className="proposal_addProductButton" onClick={()=>setShowSheet(true)}>{title === 'Installed Products' ? 'Add Product' : 'Add Service'}</button>
                }
               
            
            </div>
        </>
        
    )
}

export default ProposalsProductsTab

