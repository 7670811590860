import React, { useState, useContext, useEffect } from 'react';
import '../assets/styles/NewPostLibrary.css';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import {AuthContext} from '../AuthContext';
import Spinner from 'react-spinner-material';
import Notification from './Notification';

const NewPostLibrary = (props)=>{

    const{uri} = useContext(AuthContext);


    //data
    const [formData, setFormData] = useState({});
    const [editorData, setEditorData] = useState({ content: ''});

    const [errorsPost, setErrorsPost] = useState({});
    const [isSubmittingPost, setIsSubmittingPost] = useState(false);

    const [isLoadingPost, setIsLoadingPost] = useState(false)

    //erros
    const [showNotificationOk, setShowNotificationOk] = useState(false);
    const [showNotificationError, SetShowNotificationError] = useState(false);
    const [showNotificationErrorDocumentType, SetShowNotificationErrorDocumentType] = useState(false)


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const refreshToken = window.localStorage.getItem('refreshToken');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;



    //FETCH POST POST LIBRARY
    const sendPostLibrary = () =>{
    setIsLoadingPost(true);
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_LIBRARY}documents`;
    console.log(URI)
  
    let postdata = new FormData();
   
    if(formData.hasOwnProperty('documentFile')){
      postdata.append('file', formData.documentFile);
    }
     
    postdata.append('title', formData.PostName);
    postdata.append('content', editorData.content);
    postdata.append('document-type', 'Standard');

  //  console.log(postdata)
    
   
    window.fetch(URI,{
        method: 'PUT',
        headers: {
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I, 
            'username' : user,      
          },
          body: postdata
    }).then(response => response.json())
    .then(datos => {
        setIsLoadingPost(false);
       
        // console.log(datos)

        

        if(datos.status === 'SUCCESS'){
            setShowNotificationOk(true);
            
            props.handler();
            props.reload();
            
        }else if(datos.status === 'DOCUMENT_TYPE_NOT_ACCEPTED'){
            SetShowNotificationErrorDocumentType(true)
        }else{
            SetShowNotificationError(true)
        }
       
  
  }).catch(error => {
    console.log(error)
    setIsLoadingPost(false);
    SetShowNotificationError(true)
  })
  }




  useEffect(() => {
    if (Object.keys(errorsPost).length === 0 && isSubmittingPost) {    
        sendPostLibrary();
    } 
  }, [errorsPost]);

    
    //MANEJADOR DEL EDITOR DE TEXTO
    const handleChangeTextEditor = html => {
        setEditorData({ content: html });
  
      
    };


    //MANEJADORES DE CAMPOS DE FORM DE DOCUMENTS
    const handlePostForm = event => {
    let value;
    if(event.target.name === 'documentFile'){
        value = event.target.files[0];
    }else if(event.target.name === 'PostName'){
        value = event.target.value;
    }
    
    setFormData({
        ...formData,
        [event.target.name]: value,       
    })
    }



    const handleSubmitPost = event => {
        event.preventDefault();
        //handle errors
        setErrorsPost(validateFormPost(formData));
        setIsSubmittingPost(true);
        // console.log(formData);
        // console.log(editorData);
        
        
      };
  

      
      const validateFormPost = (data) => {
        let errorListPost = {};
    
        //dealerid - check
        if (!data.PostName) {
          errorListPost.PostName = "Please enter a title";
        }
  
        
        return errorListPost;
        };

    //["bold", "italic", "underline", "strike", "blockquote"],    
    const modules = {
        toolbar: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["link"],
          ["bold", "italic", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          
          ["clean"],
          // ["image"]
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false
        }
      };
    
      const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video"
      ];


    return(
        <>


            <div className="PopUp-NewPost" >
            <div className="newPost-spaceBlack" onClick={props.handler}></div>

            <div className="newPostContainer">

                {isLoadingPost ? 
                <div className="spinnerDetails"><Spinner
                  radius={40}
                  color={"grey"}
                  stroke={5}
                  visible={true}
                /></div> : null}


                     

                <div className="newPost-closeButton" onClick={props.handler}>@</div>
                <h2 className="newPost-title">New Post</h2>
                
                <div className="newPost-input">
                    <label className="newPost-input_label">Title</label>
                    <input 
                    className="newPost-input_name" 
                    type="text" 
                    name="PostName" 
                    placeholder="Post Title" 
                    onChange={handlePostForm}></input>
                    {errorsPost.PostName && <p className="errorMessage">{errorsPost.PostName}</p>}
                </div>

               
                    
                    <p className="newPost_fileName">* Only .pdf and images accepted</p> 
                    <div className="newPost-buttons">
                      <div className="selectFile">
                      <span className="labelSelectFile" >Upload a document</span>
                      <input 
                      type="file" 
                      className="documentChoseButtom" 
                      name="documentFile"
                      onChange={handlePostForm}
                      /> 
                      </div>    
                    </div>
                    {formData.documentFile ? <p className="newPost_fileName">{formData.documentFile.name}</p> : null}
                   
                    
                    

                    
                        <div className="editorContainer">
                        <ReactQuill
                            theme="snow"
                            onChange={handleChangeTextEditor}
                            value={editorData.content}
                            modules={modules}
                            formats={formats}
                            bounds={".app"}
                            style={{'height' : '400px', }}
                        />
                        </div>

                        <button className="Library_Create_Post" onClick={handleSubmitPost}>Create Post</button>

                      {showNotificationOk &&
                      <Notification title="The post has been published" f={()=>{setShowNotificationOk(false)}}/>
                      } 

                      {showNotificationError && 
                      <Notification color="red" title="An error has occurred" f={()=>{SetShowNotificationError(false)}}/> 
                      }

                      {showNotificationErrorDocumentType && 
                      <Notification color="red" title="Type of document not accepted" f={()=>{SetShowNotificationErrorDocumentType(false)}}/> 
                      }


                       
                    
                    

                </div>

            </div>
        </>
    )
}

export default NewPostLibrary;