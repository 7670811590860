import React from 'react';
import '../assets/styles/Sheet.css'


const Sheet = (props)=>(
    
        <div className="sheet" id="sheet">
                <div onClick={props.handler} className={ props.hasOwnProperty('half') ? "blackspaceHalf" : "blackspace"} ></div>
          
                <div className={ props.hasOwnProperty('half') ? "shetContainerHalf" : "sheetcontainer"} id="shetContainer">
                {props.children}
                </div>
        </div>  
);

export default Sheet;