import React, {useState, useEffect, useContext} from 'react'
import '../assets/styles/AlertBanner.css'
import { AuthContext } from '../AuthContext';

const AlertBanner = ({reloadAlertBanner}) => {

    const {uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [alertList, setAlertList] = useState([])

    const token = window.localStorage.getItem('token');


     //GET ALERTS
     useEffect(()=>{
   
        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}app/get-banner-alerts`
        // console.log(URI)
       

       const abortController = new AbortController();
       const signal = abortController.signal;


        const header = {
        'Content-Type': 'application/json', 
        'Authorization' : token,   
        'x-api-key' :  uri.URI_X_API_KEY, 
        }



       window.fetch(URI,{
           method: 'GET',
           signal: signal,
           headers: header,
       })
       .then(response => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
       .then(datos => {
  
            // console.log(datos);

            if(datos.status === 'INTERNAL_SERVER_ERROR' ){
                console.log('INTERNAL_SERVER_ERROR GET ALERTS')
            }else if(datos.status === 'NO_BANNER_ALERTS_FOUND'){
                console.log('NO_BANNER_ALERTS_FOUND')
            }else{
                setAlertList(datos)
            }

    setErrorFlagFromContext(false)
           

       })
       .catch((error, response) => {
        console.log(error)
       

        if(errorFlagFromContext === false && error.status === 401){
            changeToken().then(response => console.log(response)).catch(error => console.log(error))
        }

        });

          return function cleanup() {
            abortController.abort();
          }

    }, [reloadFetchFromContext, reloadAlertBanner])







    //DELTE
    const deleteAlert=(id)=>{

        const newList = alertList.filter(alert => alert.Id !== id) 
        setAlertList(newList);

    }


    return (
        <div>
            {
               alertList.map((alert, index) => {
                   if(alert.IsActive){
                    return(
                        <div className={alert.Type === 'Primary' ? "alertItemCard-primaryBanner alertItemCardBanner" :
                        alert.Type === 'Secundary' ? "alertItemCard-secundaryBanner alertItemCardBanner" :
                        alert.Type === 'Success' ? "alertItemCard-successBanner alertItemCardBanner" :
                        alert.Type === 'Error' ? "alertItemCard-errorBanner alertItemCardBanner" :
                        alert.Type === 'Warning' ? "alertItemCard-warningBanner alertItemCardBanner" :
                        "alertItemCard-secundaryBanner alertItemCardBanner"
                        } key={index}>
    
                            <p>{alert.Text}</p>
            
                            <p className="alertBanner_closeButton" onClick={()=>deleteAlert(alert.Id)}>x</p>
        
        
                        </div>
                       )
                   }
                   
               }) 
            }
           

        </div>
    )
}

export default AlertBanner;
