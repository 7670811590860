import React, {useState, useEffect, useContext} from 'react'
import PopUp from '../PopUp';
import '../../assets/styles/EditUser.css';
import '../../assets/styles/ProposalProductRow.css';
import Spinner from 'react-spinner-material';
import {AuthContext} from '../../AuthContext';
import CreateBundleSystemProduct from './CreateBundleSystemProduct'

const ProductBundleRow = ({product, setReloadGetBundleProducts, systemProductList, setBundleSystemProductList}) => {

    


    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);



    //DELETE
    const [userWasDeleted, setUserWasDeleted] = useState(false);
    const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);
    const [showConfirmationPopUpDelete, setShowConfirmationPopUpDelete] = useState({show :false, id: undefined});
    const [showEditPopUp, setShowEditPopUp] = useState({show :false, id: undefined});
    const [belongToPackageValidation, setBelongToPackageValidation] = useState(false)


    const token = window.localStorage.getItem('token');
 



    const checkIfProductExiste = () => {

        const URI =  `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/system-product/belongs-to-package/${product.Id}`;
        setBelongToPackageValidation(false)

        window.fetch(URI ,{
            method: 'GET',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : uri.URI_X_API_KEY,
            'Authorization' : token,         
            }, 
            
            })
            .then(response => response.json())
            .then(datos => { 
                
                // console.log(datos);
                setBelongToPackageValidation(datos)
            
            })
    }


    const deleteProduct = () => {




        setIsDeleteUserLoading(true)
        let URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/disable-system-product-bundle/${showConfirmationPopUpDelete.id}`;
       


        window.fetch(URI ,{
        method: 'DELETE',
        headers: {           
        'Content-Type': 'application/json',  
        'x-api-key' : uri.URI_X_API_KEY,
        // 'DealerId' : props.userDealerId, 
        'Authorization' : token,         
        }, 
        // body: JSON.stringify(body),
        
        })
        .then(response => response.json())
        .then(datos => { 
            setIsDeleteUserLoading(false)
            console.log(datos);

            if(datos.status === 'SUCCESS'){
                setUserWasDeleted(true)

                setBundleSystemProductList(state => (state.filter(product => (product.Id !== showConfirmationPopUpDelete.id) && product)))
            }
            
            
            
        })
    }




    const confirmationPopUp=()=>{
            
        return(
            <div className="confirmationPopUp">
                <div className="confirmationPopUp_spaceBlack"></div>
                <div className="confirmationPopUp_content">
    
                    {userWasDeleted ? 
                    
                    <div className="confirmationPopUp_content">     
                        <h2 className="confirmationPopUp_message">Product was deleted successfully</h2>
                        <button className="confirmationPop_confirm confirmationPop_confirm_done" onClick={()=> {setShowConfirmationPopUpDelete({show: false, id: undefined}); setUserWasDeleted(false)}}>Done</button>
                    </div>   
    
                    :
    
                    <div className="confirmatinPopUp_choose">
    
                            <h2 className="confirmationPopUp_message">Are you sure you want to delete this product?</h2>

                            {
                                belongToPackageValidation.BelongsToPackage &&
                                <div className="proposalProductRow_deleteWarningMessage">
                                    <p className="proposalProductRow_deleteWarningMessage_text">{`Product ${product.Name || product.ProductDescription} is part of the following System Packages`}</p>
                                    <ul>
                                        {
                                            belongToPackageValidation && belongToPackageValidation.PackageNames && belongToPackageValidation.PackageNames.map(packageName => {return (<li>{`·${packageName}`}</li>)} )
                                        }
                                    </ul>
                                </div>
                            }

                            <div className="confirmationPopUp_buttons">
                                {isDeleteUserLoading ? 
                                <button className="confirmationPop_confirm-spinner">
                                <Spinner
                                    radius={16}
                                    color={"white"}
                                    stroke={4}
                                    visible={true}
                                    />
                                </button>
                                :
                                <button className="confirmationPop_confirm" onClick={deleteProduct}>Confirm</button>
    
                                }
                                {isDeleteUserLoading ? null
                                :
                                <button className="confirmationPop_cancel" onClick={()=>{setShowConfirmationPopUpDelete({show :false, id: undefined})}}>Cancel</button>
                                }
                                
    
                            </div>
                    </div>
             
                    }
    
                </div>
            </div>
        )
    }







    return (
        <>  


            <div className="pb-4">
                <div>
                    <div className="proposals_productRow pb-0">
                        <p className="proposals_productValue">{product.Name}</p>
                        <p className="proposals_productValue">{`$${parseFloat(product.ProductPrice).toFixed(2)}`}</p>
                        <p className="proposals_productValue">-</p>
                        <p className="proposals_productValue">-</p>
                        <p className="proposals_productValue">{product.LastModifiedDate}</p>
                        <p className="proposals_productValue productRow_editButton" onClick={()=>{setShowEditPopUp({show :true, id: '123'})}}>Edit</p>
                        <p className="proposals_productValue productRow_deleteButton" onClick={()=>{setShowConfirmationPopUpDelete({show :true, id: product.Id}); checkIfProductExiste()}}>Delete</p>
                    </div>
                </div>

                {
                    product.ProductList && product.ProductList.map(product => {
                        return(
                            <div className="proposals_productRow bundleSystemProductRow pb-0">
                                <p className="proposals_productValue">{product.Name}</p>
                                <p className="proposals_productValue">{`$${product.ProductPrice}`}</p>
                                <p className="proposals_productValue">{product.PartNumber}</p>
                                <p className="proposals_productValue">{product.Quantity}</p>
                                <p className="proposals_productValue"></p>
                            <div></div>
                            <div></div>
                            </div>
                        )
                    })
                }
                
            </div>




             {/* EDIT */}
             {showEditPopUp.show && <PopUp 
                                    setShowSheet={setShowEditPopUp} 
                                    edit 
                                    fullHeight 
                                    width800
                                    title="Edit">
                                        <CreateBundleSystemProduct 
                                            productInfo={product} 
                                            edit 
                                            // type={type} 
                                            // setSystemProductList={setSystemProductList} 
                                            // setMonitoringProductList={setMonitoringProductList}
                                            // setReloadGetSystemProducts={setReloadGetSystemProducts}
                                            // setReloadGetMonitoringProducts = {setReloadGetMonitoringProducts}
                                            setReloadGetBundleProducts={setReloadGetBundleProducts}
                                            systemProductList={systemProductList}
                                            />
                                    </PopUp>}

            {/* DELETE */}
            {showConfirmationPopUpDelete.show ? confirmationPopUp() : null}
  
        </>
        
    )
}


export default ProductBundleRow;
