import React, { useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import '../assets/styles/Login.css'
import { AuthConsumer} from '../AuthContext';
import {AuthContext} from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Notification from '../components/Notification';
import iconSadFace from '../assets/images/iconSadFace.png';
import iconAdmiration from '../assets/images/iconAdmiration.png';
import Spinner from 'react-spinner-material';
import { isMobile, isBrowser } from 'react-device-detect';
require('dotenv').config();


const Login = (props)=>{
    
    
    const [isLoading, setIsLoading] = useState(false);

    const{uri} = useContext(AuthContext);


    const [showNotification, SetShowNotification] = useState(false);
    const [showNotificationUserInactive, setShowNotificationUserInactive] = useState(false);
    const [showNotificationPendingForActivation, setShowNotificationPendingForActivation] = useState(false);
    const [showNotificationGeneralError, setShowNotificationGeneralError] = useState(false);

    //INFORMACION DEL DISPOSITIVO


    const [form, setValues] = useState({
      password: '',
      username: '',
      device_type: isMobile ? "Mobile" : "Desktop"
    });

    
    const handleInput = event => {
        setValues({
            ...form,
            [event.target.name]: event.target.value,            
        })
    }

  

    const loadingButtom = ()=>{
      return(
        <div className="loadingButtom">
            <Spinner
              radius={30}
              color={"white"}
              stroke={4}
              visible={true}
            />
        </div>
      )
    }

    return(
        <>
        <AuthConsumer>
       
        {({ activateAuth, isAuth }) => (
        <div className="login-page_container">
        {isAuth ? null : <Header />}
        <div className="login-page">
            <section className="login-container">
            <h2 className="login-title">Login</h2>
            <p className="login-p">Enter your username and password to login. </p>
            <p style={{fontSize: '12px'}}>(Username and password are case sensitive.)</p>
            <form action="" onSubmit={
               async (e) => {
                    e.preventDefault();

          


                    setIsLoading(true)
                    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
                     
                    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_AUTHENTICATE}`

                    try{
                        const rawResponse = await window.fetch(URI, {
                          method: 'POST',
                          headers: {           
                          'Content-Type': 'application/json', 
                          'x-api-key' : URI_X_API_KEY_I,           
                          },
                          body: JSON.stringify(form),
                        
                        });
                       
                        setIsLoading(false);
                        //console.log('LOGIN RESPONDE')
                        
                    
                        const content = await rawResponse.json();
                        //  console.log(content)
                       
                        

                    

                    const loginSucces =()=>{
                        const token = content.id_token;
                        const user = form.username
                        const refreshToken = content.refresh_token;
                        const dealerId = content.dealer_id;
                        const accessToken = content.access_token;
                        const userEmail = content.email;
                        const userRole = content.role;
                        const hideBuyRate = content.hide_buy_rate_from_sales_reps;
                        //console.log('REFRESH TOKEN:')
                        //console.log(refreshToken)

                        const permissions = content.permissions;
                        permissions.map(item => {
                            if (item === "can_read_all_applications") {
                                localStorage.setItem("getApplicationsEndpoint", item);
                              } else if (item === "can_read_dealer_applications") {
                                localStorage.setItem("getApplicationsEndpoint", item);
                              } else if (item === "can_read_own_applications") {
                                localStorage.setItem("getApplicationsEndpoint", item);
                              } 

                            else if (item === "can_create_applications") {
                              localStorage.setItem("can_create_applications", 'true');
                            } else if (item === "can_create_users") {
                              localStorage.setItem("can_create_users", 'true');
                            }
                            
                            else if (item === "can_read_all_users") {
                              localStorage.setItem("getUsersEndpoint", item);
                            } else if (item === "can_read_dealer_users") {
                              localStorage.setItem("getUsersEndpoint", item);
                            }
                            
                            else if(item === 'is_dealerally_admin'){
                              localStorage.setItem("is_dealerally_admin", 'true');
                            }

                            else if(item === 'can_edit_users'){
                              localStorage.setItem("can_edit_users", 'true');
                            }
                            
                            
                            else if (item === "can_delete_users") {
                              localStorage.setItem("can_delete_users", 'true');
                            } else if (item === "can_edit_usersroles") {
                              localStorage.setItem("can_edit_usersroles", 'true');
                            } 
                            
                            else if (item === "can_read_all_roles") {
                              localStorage.setItem("can_read_all_roles", 'true');
                            } else if (item === "can_read_dealer_roles") {
                              localStorage.setItem("can_read_dealer_roles", 'true');
                            } else if (item === "can_create_roles") {
                              localStorage.setItem("can_create_roles", 'true');
                            } else if (item === "can_edit_roles") {
                              localStorage.setItem("can_edit_roles", 'true');
                            }
                          });

                          const dealerPermissions = content.dealer_permissions;
                          if(dealerPermissions != undefined && dealerPermissions != null){
                            dealerPermissions.map(item => {
                              if (item === "can_create_applications") {
                                localStorage.setItem("dealer_can_create_applications", 'true');
                              }else if(item === "allow_customer_proposals"){
                                localStorage.setItem("allow_customer_proposals", 'true');
                              }else if(item === "allow_customer_reports"){
                                localStorage.setItem("allow_customer_reports", 'true');
                              }else if(item === "can_create_commercial_applications"){
                                localStorage.setItem("canCreateCommercials", 'true');
                              }
                            })
                          }
                          

                          // if(content.status === 'WAITING_FOR_ACTIVATION'){
                          //   localStorage.setItem("waitingForActivation", 'true');
                          // }

                          if(content.has_been_validated_in_aqua){
                            localStorage.setItem("has_been_validated_in_aqua", 'true');
                          }else{
                            localStorage.setItem("has_been_validated_in_aqua", 'false');
                          }
                    
                        activateAuth(token, user, refreshToken, dealerId, accessToken, userEmail, userRole, hideBuyRate);
                        
                        
                        
                       

                    }






                    

                    if(content.status === 'NEW_PASSWORD_REQUIRED'){
                        props.history.push (`/changepassword`);
                    }else if(content.status === 'SUCCESS'){                        
                      loginSucces();
                    }else if(content.status === 'UNAUTHORIZED'){
                        console.log('INAUTORIZADO')
                        SetShowNotification(true);
                        
                    }else if(content.status === 'ERROR'){
                      console.log('ERROR')
                      SetShowNotification(true);
                    }else if(content.status === 'INACTIVE_USER'){
                      console.log('USUARIO DESACTIVADO');
                      setShowNotificationUserInactive(true);
                    }else if(content.status === 'USER_NOT_FOUND'){
                      console.log('USUARIO NO ENCONTRADO');
                      SetShowNotification(true);              
                    }
                    else if(content.status === 'WAITING_FOR_ACTIVATION'){
                      console.log('WAITING_FOR_ACTIVATION');           
                      loginSucces();    
                             
                    }else if(content.status === 'PASSWORD_RESET_REQUIRED'){
                      console.log('PASSWORD_RESET_REQUIRED');           
                      props.history.push (`/resetpassword/login`)
                             
                    }else if(content.status === 'FORCE_CHANGE_PASSWORD'){
                      console.log('FORCE_CHANGE_PASSWORD');           
                      SetShowNotification(true);
                             
                    }else{
                      console.log('ERROR');           
                      SetShowNotification(true);
                    }
                    
                  }catch(error){
                    console.log('Catch error')
                    console.log(error)
                    setIsLoading(false);
                    setShowNotificationGeneralError(true)
                  }
                    
                    
            
            
            }} className="login-form">
                <input 
                    name="username"
                    className=""
                    type="text" 
                    placeholder="Username" 
                    onChange={handleInput}
                    required
                    className="login-input"
                />
                <input 
                    name="password"
                    type="password" 
                    placeholder="Password"
                    onChange={handleInput} 
                    required
                    className="login-input"
                />
                
                {isLoading ? loadingButtom() 
                : <button type="submit" className="login-submit-button">Login</button>}
            </form>

            
            
            <Link to="/resetpassword/reset" className="login-forgot">Forgot your password?</Link>
            </section>
            <p>Need an Account? Contact DealerAlly to <a href='https://dealerallyprogram.com/commercial/' target='_blank'>create one.</a></p>
        </div>
        {isAuth ? null : <Footer />}
        </div>
        )}

       
        </AuthConsumer>

        {showNotification ? 
        <Notification type='popup' title='Something went wrong' text='Check your username and password, and if it does not work contact your support team. Reminder: Username must be all lowercase and phones often change the first letter to uppercase.' f={()=>{SetShowNotification(false)}}>
            <img src={iconSadFace} alt="close" />
        </Notification> : null}


        {showNotificationUserInactive ? 
        <Notification type='popup' title='The user is deactivated' text='Contact your administrator.' f={()=>{setShowNotificationUserInactive(false)}}>
            <img src={iconSadFace} alt="close" />
        </Notification> : null}

        {showNotificationGeneralError ? 
        <Notification type='popup' title='Something went wrong' text='Contact your administrator.' f={()=>{setShowNotificationGeneralError(false)}}>
            <img src={iconSadFace} alt="close" />
        </Notification> : null}

        {showNotificationPendingForActivation ? 
        <Notification type='popup' title='Info: The user is pending for activation in AquaFinance systems.' text='Try again later.' f={()=>{setShowNotificationPendingForActivation(false)}}>
            <img src={iconAdmiration} alt="close" className="login_icon-admiration" />
        </Notification> : null}
        </>
    );
}



export default Login;