import React, { useState, useEffect, useContext} from 'react';
import '../assets/styles/LibraryCard.css'
import LibraryPostDetail from '../components/LibraryPostDetail';
import {AuthContext} from '../AuthContext';
import Spinner from 'react-spinner-material';

const LibraryCard =(props)=>{

    const{uri} = useContext(AuthContext);

    const [showSheet, setShowSheet] = useState(false);

    const [showConfirmationPopUpOk, setShowConfirmationPopUpOk] = useState(false);
    const [showConfirmationPopUpRefuse, setShowConfirmationPopUpRefuse] = useState(false);
    const [showConfirmationPopUpDelete, setShowConfirmationPopUpDelete] = useState(false);

    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)

    //PERMISOS
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const refreshToken = window.localStorage.getItem('refreshToken');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;


    //FUNCION PARA CAMBIAR ESTATUS DE UN POST
    const changeStatus = (status)=>{

        setIsConfirmationLoading(true)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_LIBRARY}${uri.URI_LIBRARY_DOCUMENTS}${props.Id}/${status}`
        console.log(URI)

        window.fetch(URI,{
            method: 'GET',
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : URI_X_API_KEY_I,   
              }
        })
        .then(response => response.json())
        .then(datos => {
            setIsConfirmationLoading(false)
            console.log('RESPUESTA DE CAMBIAR ESTADOS DE POST');
            console.log(datos);
            if(datos.status === 'SUCCESS'){
                setShowConfirmationPopUpOk(false);
                setShowConfirmationPopUpRefuse(false);
                props.reload();
            }

    }).catch(error => {
        console.log('SE VENCIO EL PRIMER TOKEN')
        
        /*const URI_X_API_KEY_I = uri.URI_X_API_KEY;
        const user = window.localStorage.getItem('user');
        const refreshToken = window.localStorage.getItem('refreshToken');*/
        const data = {
            "username" : user,
            "refresh_token" : refreshToken,
             
        }
        const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;

        window.fetch(URI_REFRESHTOKEN,{
        method: 'POST',
        headers: {           
        'Content-Type': 'application/json',  
        'x-api-key' : URI_X_API_KEY_I,         
        },
        body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(datos => { 
            console.log(datos)
            window.localStorage.removeItem('token');
            const newToken = datos.id_token;
            window.localStorage.setItem('token', newToken);
            console.log('SE REEMPLAZO EL TOKEN VIEJO POR EL NUEVO');
            props.reload();
        })
});

} //FIN FUNCION CAMBIAR STATUS DE UN POST



//DELETE POST
const deleteDocument = (postId) =>{
    setIsConfirmationLoading(true)
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_LIBRARY}${uri.URI_LIBRARY_DOCUMENTS}${postId}`;
    // console.log(URI)
    
    window.fetch(URI,{
        method: 'DELETE',
        headers: {
            'username' : user,
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,       
          }
    }).then(response => response.json())
    .then(datos => {
        setIsConfirmationLoading(false)

        // console.log(datos)

        if(datos.status === 'SUCCESS'){
            setShowConfirmationPopUpDelete(false)
            // window.location.reload();
            const newList = props.postData.filter(post => post.Id !== postId )
            props.setPostData(newList)

        }
        
  
  })
  }


const showSheetHandler = ()=>{
    if(props.Status === 'Approved'){
        setShowSheet(!showSheet);
    }
}
    //APROVE POST
    // const confirmationPopUpOk=()=>{
        
    //     return(
    //         <div className="confirmationPopUp">
    //             <div className="confirmationPopUp_spaceBlack"></div>
    //             <div className="confirmationPopUp_content">
    //                 <h2 className="confirmationPopUp_message">Are you sure you want to approve this post?</h2>
    //                 <div className="confirmationPopUp_buttons">
    //                     {isConfirmationLoading ? 
    //                     <button className="confirmationPop_confirm-spinner">
    //                     <Spinner
    //                         size={16}
    //                         color={"white"}
    //                         spinnerWidth={3}
    //                         visible={true}
    //                         />
    //                     </button>
    //                     :
    //                     <button className="confirmationPop_confirm" onClick={()=>{changeStatus('approve')}}>Confirm</button>

    //                     }
    //                     <button className="confirmationPop_cancel" onClick={()=>{setShowConfirmationPopUpOk(false)}}>Cancel</button>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }


    //REFUSE POST
    // const confirmationPopUpRefuse=()=>{
        
    //     return(
    //         <div className="confirmationPopUp">
    //             <div className="confirmationPopUp_spaceBlack"></div>
    //             <div className="confirmationPopUp_content">
    //                 <h2 className="confirmationPopUp_message">Are you sure you want to reject this post?</h2>
    //                 <div className="confirmationPopUp_buttons">

    //                 {isConfirmationLoading ? 
    //                     <button className="confirmationPop_confirm-spinner">
    //                     <Spinner
    //                         size={16}
    //                         color={"white"}
    //                         spinnerWidth={3}
    //                         visible={true}
    //                         />
    //                     </button>
    //                     :
    //                     <button className="confirmationPop_confirm" onClick={()=>{changeStatus('deny')}}>Confirm</button>

    //                     }

    //                     <button className="confirmationPop_cancel" onClick={()=>{setShowConfirmationPopUpRefuse(false)}}>Cancel</button>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const confirmationPopUpDelete=()=>{
        
        return(
            <div className="confirmationPopUp">
                <div className="confirmationPopUp_spaceBlack"></div>
                <div className="confirmationPopUp_content">
                    <h2 className="confirmationPopUp_message">Are you sure you want to delete this post?</h2>
                    <div className="confirmationPopUp_buttons">


                    {isConfirmationLoading ? 
                        <button className="confirmationPop_confirm-spinner">
                        <Spinner
                            radius={16}
                            color={"white"}
                            stroke={3}
                            visible={true}
                            />
                        </button>
                        :
                        <button className="confirmationPop_confirm" onClick={()=>{deleteDocument(props.Id)}}>Confirm</button>

                        }
                        
                        <button className="confirmationPop_cancel" onClick={()=>{setShowConfirmationPopUpDelete(false)}}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }





    return(
        <>
            {showSheet ?
                <LibraryPostDetail handler={showSheetHandler} post_id={props.Id}/>
            : null}

          
            {showConfirmationPopUpDelete ? confirmationPopUpDelete() : null}


            <div  className="libraryCard">
                <div onClick={showSheetHandler}>
                    <h3 className="libraryCard-tittle">{props.Title}</h3>
                    <p className="libraryCard-details"> 
                        <span className="libraryCard-Autor libraryCard-detail">{props.CreatedBy}</span>        
                    </p>
                    <p className="libraryCard-Date">
                        <span>{props.CreationDate}</span>
                        {/* <span className="libraryCard-Status">{props.Status}</span> */}
                    </p>

                </div>
               

                {/* {isDeallerAllyAdmin === 'true' && props.Status === 'Pending' ?
                    <div className="libraryCard_Buttons">
                        <button className="libraryCard_Buttons-approve" onClick={()=>{setShowConfirmationPopUpOk(true)}}>Approve</button>
                        <button className="libraryCard_Buttons-refuse" onClick={()=>{setShowConfirmationPopUpRefuse(true)}}>Deny</button>
                        
                    </div>
                : null  
                } */}

                {isDeallerAllyAdmin === 'true' ?
                <button className="libraryCard_Buttons-delete" onClick={()=>{setShowConfirmationPopUpDelete(true)}}>Delete</button>
                : null
                }
                
               
            </div>
        
        </>

    );
}

export default LibraryCard;