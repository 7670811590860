import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/Dealers.css'
import iconAdd from '../assets/images/iconAdd.png';
import {AuthContext} from '../AuthContext';
import SkeletonLoader from "tiny-skeleton-loader-react";
import iconSadFace from '../assets/images/iconSadFace.png';
import DealerCard from '../components/DealerCard';
import CreateDealer from '../components/CreateDealer';
import Sheet from '../components/Sheet';
import Notification from '../components/Notification';
import iconArrowDownGreen from '../assets/images/iconArrowDownGreen.png';
import iconArrowDownGrey2 from '../assets/images/iconArrowDownGrey2.png';
import iconArrowUpGreen from '../assets/images/iconArrowUpGreen.png';
import iconArrowUpGrey2 from '../assets/images/iconArrowUpGrey2.png';




const Dealers = ()=>{

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);


    const [dealersData, setDealersData] = useState([]);
    const [dealersOriginalData, setDealersOriginalData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [hasData, setHasData] = useState(true);

    const [showCreateDealer, setShowCreateDealer] = useState(false)

    const [realoadGetDealers, setReloadGetDealers] = useState(false)

    const [showNotificationInternalServerError, setShowNotificationInternalServerError] = useState(false)

    const [termSearch, setTermSearch] = useState('')
    const [searchBy, setSearchBy] = useState('All')

    const [hideInactiveDealers, setHideInactiveDealers] = useState(false)

    const [reorder, setReorder] = useState(false);
    const [propertyFilter, setPropertyFilter] = useState('CreatedDate')
    const [filterIndex, SetFilterIndex] = useState(2);


    //RECUPERAR VALORES DE SESSIONSTORAGE Y CONTEXTAPI
    const token = window.localStorage.getItem('token');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;

    
    
    //GET DEALERS
    useEffect(()=>{
        setIsLoading(true)

        

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_GET_ALL_DEALERS}`
        //console.log(URI)

        const abortController = new AbortController();
        const signal = abortController.signal;


        fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'Authorization' : token,   
                'x-api-key' : URI_X_API_KEY_I,   
                }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoading(false)
            // console.log('INFORMACION DE TODAS LOS DEALERS');
            // console.log(datos);

            if(datos.hasOwnProperty('status')){
                // console.log('hay un error')

            }else if(datos.length > 0){
                setDealersData(datos)
                setDealersOriginalData(datos)
                
            }else if(datos.length === 0){
                setHasData(false)
            }

            if(datos.status === 'INTERNAL_SERVER_ERROR'){
                setShowNotificationInternalServerError(true)
            }
            
            setErrorFlagFromContext(false)

        })
        .catch((error, response) => {
            console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }    
        });
        

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, realoadGetDealers]);


    const reload = ()=>{
        setReloadGetDealers(!realoadGetDealers)
    }



    const showSheetHandler = ()=>{
        setShowCreateDealer(!showCreateDealer);

    }



    //SORTER
    const dynamicSort =(property) => {
        setReorder(!reorder)
        setPropertyFilter(property)
       
        return function(a, b) {        
            if (filterIndex === 1) {
                if(b[property] === undefined){
                    b[property] = ''
                }
                if(a[property] === undefined){
                    a[property] = ''
                }
                SetFilterIndex(2)
                return b[property].localeCompare(a[property]);
            } else if(filterIndex === 2){
                if(b[property] === undefined){
                    b[property] = ''
                }
                if(a[property] === undefined){
                    a[property] = ''
                }
                SetFilterIndex(1)
                return a[property].localeCompare(b[property]);
            }
            
        };
        
    }


     //BUSCADOR
     useEffect(()=>{

        if(termSearch === ''){
            const results = dealersOriginalData.filter(dealer => hideInactiveDealers ? dealer.CanCreateApplications !== false : true )

            setDealersData(results)
        }else if(searchBy === 'All'){
            const results = dealersOriginalData.filter(dealer=> (
                                                                    (dealer.Name || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                    || (dealer.MajorProductLine || '').toLowerCase().includes(termSearch.toLowerCase())       
                                                                    || (dealer.Address || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                
                                                                )
                                                                && (hideInactiveDealers ? dealer.CanCreateApplications !== false : true)
            )
            setDealersData(results)
        }else {      
            const results = dealersOriginalData.filter(dealer => (dealer[searchBy] || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                 && (hideInactiveDealers ? dealer.CanCreateApplications !== false : true)
            
            )
            setDealersData(results)
        }

    }, [termSearch, searchBy, hideInactiveDealers])


   










     const arrowDirection = (property) => {

        if(property === propertyFilter && filterIndex === 1){
            return (
                <div className="dealers_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="dealers_list-tittle-icon dealers_list-tittle-icon-up" />
                    <img src={iconArrowDownGreen} alt="" className="dealers_list-tittle-icon dealers_list-tittle-icon-down"/>
                </div>
            )
        }else if(property === propertyFilter && filterIndex === 2 ){
            return (
                <div className="dealers_list-tittle-iconContainer">
                    <img src={iconArrowUpGreen} alt="" className="dealers_list-tittle-icon dealers_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="dealers_list-tittle-icon dealers_list-tittle-icon-down"/>
                </div>
            ) 
        }else{
            return (
                <div className="dealers_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="dealers_list-tittle-icon dealers_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="dealers_list-tittle-icon dealers_list-tittle-icon-down"/>
                </div>
            ) 
        }

    
    }

    // useEffect(() => {
    //     setPropertyFilter('CreatedDate')
    // }, [])








    //LOADING CARDS
    const loadingCard = () =>{
        const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        return (list.map(item =>{
            return (<div className="loadingCardDealer">
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItem"><SkeletonLoader width="90%" /></div>
            <div className="loadingItemEnd"><SkeletonLoader width="90%" /></div>
            <div className="loadingItemEnd"><SkeletonLoader width="90%" /></div>
          </div>)
        }))
    }

   
    return(
    <>
        
        {showCreateDealer ? <Sheet handler={()=>{setShowCreateDealer(false)}}><CreateDealer reload={reload} handler={showSheetHandler}/></Sheet>: null}



        <div className="dealer">

        
            <div className="dealer-header">               
                <div className="dealer-header-tittle">
                    <h2>Dealers</h2>
                </div>
                    <div className="dealer-header-buttoms">

                    <div  className="dealer-header-buttoms_new" onClick={()=>{setShowCreateDealer(true)}}>
                    <img src={iconAdd} className="aplications-header-buttoms_new_icon" />
                        <p className="dealer-header-buttoms_new_text">
                        <label className="dealer-header-buttoms_new_text_1">New</label>   
                        <label className="dealer-header-buttoms_new_text_2"> Dealer</label>
                    </p>
                    </div>

                    </div>        
            </div>


                       
            <div className="dealers_searchContainer">

               
                {/* SEARCHER */}
                <div className="applications_filter-searchContainerList">
                    <input type="text" placeholder="Search" value={termSearch} className="applications_filter-searchInput" onChange={(e)=>{setTermSearch(e.target.value)}}/>
                    <span className="applications_filter-searchCloseIcon" onClick={()=>setTermSearch('')}>x</span>
                </div>



                {/* SEARCHER FILTERS */}
                <div className="dealers_contaner-filterSearch">
                    <select onChange={(e)=>{setSearchBy(e.target.value)}} className="dealers-header-buttoms_filter square-dealers">
                        <option value="All" selected>By Any</option>
                        <option value="Name">By Name</option>
                        <option value="MajorProductLine">By Major Product Line</option>
                        <option value="Address">By Address</option>                 
                    </select>
                </div>

                <div className="users_hideInactiveUsers">
                <input type="checkbox" className="users_hideInactiveUsers-check" onClick={()=> setHideInactiveDealers(!hideInactiveDealers)}/>
                <label>Hide Inactive Dealers</label>
            </div>

            </div>

              


            <div className="dealers_list-tittle">
                <div className="dealers_list-tittle-item" onClick={()=>{dealersData.sort(dynamicSort("Name"))}}>
                    <div>
                        <p>Name</p>
                    </div> 
                    {arrowDirection('Name')}
                </div> 
                <div className="dealers_list-tittle-item" onClick={()=>{dealersData.sort(dynamicSort("MajorProductLine"))}}>
                    <div>
                        <p>Major Product Line</p>
                    </div> 
                    {arrowDirection('MajorProductLine')}
                </div>   
                <div className="dealers_list-tittle-item" onClick={()=>{dealersData.sort(dynamicSort("CreatedDate"))}}>
                    <div>
                        <p>Created Date</p>
                    </div> 
                    {arrowDirection('CreatedDate')}
                </div>  
                <div className="dealers_list-tittle-item" onClick={()=>{dealersData.sort(dynamicSort("City"))}}>
                    <div>
                        <p>City</p>
                    </div> 
                    {arrowDirection('City')}
                </div>  
                <div className="dealers_list-tittle-item" onClick={()=>{dealersData.sort(dynamicSort("State"))}}>
                    <div>
                        <p>State</p>
                    </div> 
                    {arrowDirection('State')}
                </div>  
            
            </div> 




            <div className="dealer-content">

             {/* ******************************************************************************** */}







            {isLoading ? loadingCard() : null}

           
           
            {
            hasData ?
                dealersData.map(item =>     
                    <DealerCard key={item.Id} {...item}/>         
                )
            : 
            <h3>No Dealers submited</h3>
            }

           
            





        </div>

        </div>

        {showNotificationInternalServerError  ? 
        <Notification type='popup' title='Server Error, please contact administrator.' f={()=>{setShowNotificationInternalServerError(false)}}>
            <img src={iconSadFace} alt="check" />
        </Notification> : null}

    </>
)
}

export default Dealers;