import React, { useState, useEffect, useContext  } from 'react'
import "../../assets/styles/ApplicationForm.css";
import NumberFormat from "react-number-format";
import { AuthContext } from '../../AuthContext';
import useIsSafari from '../../hooks/useIsSafari'
import AlertBox from "../AlertBox";
import Button from '../general/Button';

const EquipmentInformationForm = ({ commercialLoanApplicationId, type, setStep, step, setCommercialLoanData, prefilledInfo }) => {

  const { uri, changeToken, proposalInfo } = useContext(AuthContext);
  const [applicant, setApplicant] = useState({ResidualType: 'EFA', TransactionType: 'Vendor Sale', IsHardAssetWorkingCapital: 'false', EquipmentCondition: 'New', LeaseTerm: '60'})
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [notificationError, setNotificationError] = useState({show: false})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [locationSameAsBusiness, setLocationSameAsBusiness] = useState(false)

  const isSafari = useIsSafari()

  const user = window.localStorage.getItem('user');
  const token = window.localStorage.getItem('token');

  useEffect(()=>{
    if(prefilledInfo){
      setApplicant({...prefilledInfo, IsHardAssetWorkingCapital : prefilledInfo.IsHardAssetWorkingCapital ? 'true' : 'false'})
      if(prefilledInfo.EquipmentLocationSameAsBusinessAddress){
        setLocationSameAsBusiness(true)
      }else{
        setLocationSameAsBusiness(false)
      }
    }
  },[prefilledInfo])


  //POST APPLICATION
  const submitForm = async (event) => {

    setIsLoading(true);

    //Armar el objeto que se envia
    const formData = { Submit: false, 
                       CommercialLoanApplicationId: commercialLoanApplicationId};
    formData.EquipmentInformation = applicant;
    formData.EquipmentInformation.EquipmentLocationSameAsBusinessAddress = locationSameAsBusiness

    if(formData.EquipmentLocationSameAsBusinessAddress){
      delete formData.EquipmentInformation.EquipmentAddress
      delete formData.EquipmentInformation.EquipmentCity
      delete formData.EquipmentInformation.EquipmentState
      // delete formData.EquipmentInformation.EquipmentCountry
      delete formData.EquipmentInformation.EquipmentPostalCode
    }
    // console.log(formData)
    // console.log(JSON.stringify(formData))

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application`;


    const headers = {
      'Access-Control-Request-Headers': 'application/json',
      'Content-Type': 'application/json',
      'username': user,
      'x-api-key': uri.URI_X_API_KEY,
      'Authorization': token,
    }

    try {
      const rawResponse = await window.fetch(URI, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(formData),
      });
      setIsLoading(false);
      const resp = await rawResponse.json();
      // console.log(resp);

      if (resp.CommercialLoanApplicationId) {
        setCommercialLoanData(state => ({...state, EquipmentInformation: formData.EquipmentInformation }))
        setStep(state => ({ ...state, EquipmentInformation: true }))
      }else{
        setNotificationError({ 
          title: resp.Message,
          text: resp.Errors[0],
          type: 'error',
          show: true
        });
      }

    }
    catch (error) {
      setIsLoading(false)
      setNotificationError({ 
        title: 'Error',
        text: 'An unexpected error has occurred. Please try again.',
        type: 'error',
        show: true
      });
      if(error.status === 401){
        changeToken()
      } 
    }









  };



  const handleInputApplicant = (event, inputName) => {
    let value = event.target && event.target.value;

    if (inputName === "CompanyPhone" || inputName === "FedTaxId") {
      setApplicant({
        ...applicant,
        [inputName]: event.value,
      })
    } else {
      setApplicant({
        ...applicant,
        [event.target.name]: value,
      })
    }
  }


  const validateForm = (data) => {
    let errorList = {};



    if (!data.TransactionType) {
      errorList.TransactionType = "Please select an option";
    }

    if (!data.IsHardAssetWorkingCapital) {
      errorList.IsHardAssetWorkingCapital = "Please select an option";
    }

    if (!data.DetailedEquipmentDescription) {
      errorList.DetailedEquipmentDescription = "Please enter a response";
    }

    if (!data.EquipmentCondition) {
      errorList.EquipmentCondition = "Please select an option";
    }

    // if (!data.TitledEquipment) {
    //   errorList.TitledEquipment = "Please select an option";
    // }

    // if(applicant.TitledEquipment === 'true'){
    //   if (!data.VehicleYear) {
    //     errorList.VehicleYear = "Please enter a year";
    //   } else if (!/^[0-9]+\.?[0-9]*$/.test(data.VehicleYear)) {
    //     errorList.VehicleYear = "The value can only contain numbers";
    //   }
  
    //   if (!data.VehicleMake) {
    //     errorList.VehicleMake = "Please enter a response";
    //   }
  
    //   if (!data.VehicleModel) {
    //     errorList.VehicleModel = "Please enter a response";
    //   }
    // }


    if (!data.EquipmentPrice) {
      errorList.EquipmentPrice = "Please enter a price";
    } else if (!/^[0-9]+\.?[0-9]*$/.test(data.EquipmentPrice)) {
      errorList.EquipmentPrice = "The value can only contain numbers";
    }

    if (!data.LeaseTerm) {
      errorList.LeaseTerm = "Please enter a value";
    } else if (!/^[0-9]+\.?[0-9]*$/.test(data.LeaseTerm)) {
      errorList.LeaseTerm = "The value can only contain numbers";
    }

    if (!data.ResidualType) {
      errorList.ResidualType = "Please select an option";
    }

    if (!data.AdvancePayments) {
      errorList.AdvancePayments = "Please enter a response";
    }

    if(!locationSameAsBusiness){
      if (!data.EquipmentAddress) {
        errorList.EquipmentAddress = "Please enter a address";
      }
  
      // if (!data.EquipmentCountry) {
      //   errorList.EquipmentCountry = "Please enter a country";
      // }
  
      if (!data.EquipmentCity) {
        errorList.EquipmentCity = "Please enter a city";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ-\s\.]+$/.test(data.EquipmentCity)) {
        errorList.EquipmentCity = "The city can only contain letters";
      }
  
  
      if (!data.EquipmentState) {
        errorList.EquipmentState = "Please select an option";
      } 
  
  
      if (!data.EquipmentPostalCode) {
        errorList.EquipmentPostalCode = "Please enter a value";
      } else if (!/^[0-9]{5}$/.test(data.EquipmentPostalCode)) {
        errorList.EquipmentPostalCode = "The value must have 5 numbers";
      }
    } 

    return errorList;
  };




  const handleSubmit = event => {
    event.preventDefault();
    setErrors(validateForm(applicant, false));
    setIsSubmitting(true);
  };





  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitForm()
    } else {
      //console.log(errors)
      // console.log(applicant)
      //handleSubmitAllData();
    }

  }, [errors, isSubmitting]);



  //VALIDAR Y LLENAR INFO DE PROPOSAL
useEffect(()=>{
  if(proposalInfo.hasInfo){
      // console.log(proposalInfo.info)

      setApplicant({
          ...applicant,
          EquipmentPrice : proposalInfo.info.TotalInstalledProduct
      })

      // setErrors(validateForm({EquipmentPrice : proposalInfo.info.TotalInstalledProduct}, false));

  }

},[proposalInfo])




  return (
    <div>
       
      <form action="" id="form" className="form">

        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Transaction Type</label>
          <select
            value={applicant.TransactionType || 'selected'}
            name="TransactionType"
            onChange={handleInputApplicant}
            className={`${errors.TransactionType ? "imputError" : "campo"}`}
            placeholder="Transaction Type"
            required
            disabled={ "disabled" }
          >
            <option value="selected" disabled>
              Transaction Type
            </option>
            <option value="Vendor Sale">Vendor Sale</option>
            <option value="Sale Lease Back">Sale Lease Back</option>
            <option value="Private Party Sale">Private Party Sale</option>
            <option value="Working Capital">Working Capital</option>
          </select>
          {errors.TransactionType && <p className="errorMessage">{errors.TransactionType}</p>}
        </div>

        <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Hard Asset Working Capital?</label>
          <select
            value={(applicant.IsHardAssetWorkingCapital ) || "selected"}
            name="IsHardAssetWorkingCapital"
            onChange={handleInputApplicant}
            className={`${errors.IsHardAssetWorkingCapital ? "imputError" : "campo"}`}
            placeholder="Hard Asset Working Capital?"
            required
            disabled={ "disabled" }
          >
            <option value="selected" disabled>
              Hard Asset Working Capital?
            </option>
            <option value= "true">YES</option>
            <option value= "false">NO</option>
          </select>
          {errors.IsHardAssetWorkingCapital && <p className="errorMessage">{errors.IsHardAssetWorkingCapital}</p>}
        </div>

        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Detailed Equipment Description</label>
           <textarea
            type="text"
            name="DetailedEquipmentDescription"
            onChange={handleInputApplicant}
            className={`${errors.DetailedEquipmentDescription ? "imputError" : "campo"}`}
            value={applicant.DetailedEquipmentDescription}
            disabled={(type === 'edit') ? "disabled" : null}
            required
            style={{height: '100px'}}
          />
          {errors.DetailedEquipmentDescription && <p className="errorMessage">{errors.DetailedEquipmentDescription}</p>}
        </div>


        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Equipment Condition</label>
          <select
            value={applicant.EquipmentCondition || 'selected'}
            name="EquipmentCondition"
            onChange={handleInputApplicant}
            className={`${errors.EquipmentCondition ? "imputError" : "campo"}`}
            placeholder="Equipment Condition"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
              Equipment Condition
            </option>
            <option value="New">New</option>
            <option value="Used">Used</option>
          </select>
          {errors.EquipmentCondition && <p className="errorMessage">{errors.EquipmentCondition}</p>}
        </div>


        {/* <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Titled Equipment?</label>
          <select
            value={applicant.TitledEquipment || 'selected'}
            name="TitledEquipment"
            onChange={handleInputApplicant}
            className={`${errors.TitledEquipment ? "imputError" : "campo"}`}
            placeholder="Titled Equipment?"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
              Titled Equipment?
            </option>
            <option value="true">YES</option>
            <option value="false">NO</option>
          </select>
          {errors.TitledEquipment && <p className="errorMessage">{errors.TitledEquipment}</p>}
        </div>

        {
          applicant.TitledEquipment === 'true' &&
          <>
                <div className="mediowidth-left campoContainer">
                <label className="labelApplicationForm" >Vehicle Year</label>
                <input
                  type="number"
                  name="VehicleYear"
                  onChange={handleInputApplicant}
                  className={`${errors.VehicleYear ? "imputError" : "campo"}`}
                  placeholder="Vehicle Year"
                  value={applicant.VehicleYear}
                  required
                  disabled={(type === 'edit') ? "disabled" : null}
                />
                {errors.VehicleYear && <p className="errorMessage">{errors.VehicleYear}</p>}
              </div>

              <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm" >Vehicle Model</label>
                <input
                  type="text"
                  name="VehicleModel"
                  onChange={handleInputApplicant}
                  className={`${errors.VehicleModel ? "imputError" : "campo"}`}
                  placeholder="Vehicle Model"
                  value={applicant.VehicleModel}
                  required
                  disabled={(type === 'edit') ? "disabled" : null}
                />
                {errors.VehicleModel && <p className="errorMessage">{errors.VehicleModel}</p>}
              </div>



              <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Vehicle Make</label>
                <input
                  type="text"
                  name="VehicleMake"
                  onChange={handleInputApplicant}
                  className={`${errors.VehicleMake ? "imputError" : "campo"}`}
                  placeholder="Vehicle Make"
                  value={applicant.VehicleMake}
                  required
                  disabled={(type === 'edit') ? "disabled" : null}
                />
                {errors.VehicleMake && <p className="errorMessage">{errors.VehicleMake}</p>}
              </div>
          </>
        } */}




        <div className="fullwidth  campoContainer">
          <label className="labelApplicationForm" >Equipment Price</label>
          <input
            type="number"
            value={applicant.EquipmentPrice}
            name="EquipmentPrice"
            onChange={handleInputApplicant}
            onBlur={() => { setApplicant({ ...applicant, 'EquipmentPrice': parseFloat(applicant.EquipmentPrice).toFixed(2) }) }}
            className={`${errors.EquipmentPrice ? "imputError" : "campo"}`}
            placeholder="Equipment Price"
            required
            disabled={type === 'edit' ? "disabled" : null}
          />
          {errors.EquipmentPrice && <p className="errorMessage">{errors.EquipmentPrice}</p>}
        </div>




        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Requested Lease Term</label>
          <select
            value={applicant.LeaseTerm || 'selected'}
            name="LeaseTerm"
            onChange={handleInputApplicant}
            className={`${errors.LeaseTerm ? "imputError" : "campo"}`}
            placeholder="Requested Lease Term"
            required
            disabled={(type === 'edit') ? "disabled" : null}
          >
            <option value="selected" disabled>
              Requested Lease Term
            </option>
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="36">36</option>
            <option value="48">48</option>
            <option value="60">60</option>
          </select>
          {errors.LeaseTerm && <p className="errorMessage">{errors.LeaseTerm}</p>}
        </div>


        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Residual Type</label>
          <select
            value={applicant.ResidualType || 'selected'}
            name="ResidualType"
            onChange={handleInputApplicant}
            className={`${errors.ResidualType ? "imputError" : "campo"}`}
            placeholder="Residual Type"
            required
            disabled={"disabled" }
          >
            <option value="selected" disabled>
              Residual Type
            </option>
            <option value="$1 Out">$1 Out</option>
            <option value="10% Put">10% Put</option>
            <option value="FMV">FMV</option>
            <option value="EFA">EFA</option>
            <option value="Affiliate Residual">Affiliate Residual</option>
            <option value="$101 FL">$101 FL</option>
          </select>
          {errors.ResidualType && <p className="errorMessage">{errors.ResidualType}</p>}
        </div>


        <div className="fullwidth campoContainer">
          <label className="labelApplicationForm" >Dealer Requested Pre-funding (Fee may apply)</label>
          <input
            type="text"
            name="AdvancePayments"
            onChange={handleInputApplicant}
            className={`${errors.AdvancePayments ? "imputError" : "campo"}`}
            value={applicant.AdvancePayments}
            placeholder="Advance Payments"
            disabled={(type === 'edit') ? "disabled" : null}
            required
          />
          {errors.AdvancePayments && <p className="errorMessage">{errors.AdvancePayments}</p>}
        </div>

       
          <div className="fullwidth campoContainer checkCopyAddressInfoExternalForm">
            <input 
              type="checkbox"
              name="isActive"
              className="inputCopyAddressInfo"
              {...locationSameAsBusiness && { checked: "checked" }}
              onChange={(e)=>{setLocationSameAsBusiness(!locationSameAsBusiness)}}
              disabled={(type === 'edit') ? "disabled" : null}
            /> <label className="labelApplicationForm" > Equipment location same as business address?</label>
          </div>
        


        {
            !locationSameAsBusiness &&
            <>
                 <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Address</label>
                  <input
                    type="text"
                    name="EquipmentAddress"
                    onChange={handleInputApplicant}
                    className={`${errors.EquipmentAddress ? "imputError" : "campo"}`}
                    value={applicant.EquipmentAddress}
                    placeholder="Address"
                    disabled={(type === 'edit') ? "disabled" : null}
                    required
                  />
                  {errors.EquipmentAddress && <p className="errorMessage">{errors.EquipmentAddress}</p>}
                </div>


                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm" >Equipment City</label>
                  <input
                    type="text"
                    value={applicant.EquipmentCity}
                    name="EquipmentCity"
                    onChange={handleInputApplicant}
                    className={`${errors.EquipmentCity ? "imputError" : "campo"}`}
                    placeholder="Equipment City"
                    required
                    disabled={(type === 'edit') ? "disabled" : null}
                  />
                  {errors.EquipmentCity && <p className="errorMessage">{errors.EquipmentCity}</p>}
                </div>



                <div className="mediowidth-right campoContainer">
                  <label className="labelApplicationForm" >Equipment State</label>
                  <select
                    value={applicant.EquipmentState || 'selected'}
                    name="EquipmentState"
                    onChange={handleInputApplicant}
                    className={`${errors.EquipmentState ? "imputError" : "campo"}`}
                    placeholder="Equipment State"
                    required
                    disabled={(type === 'edit') ? "disabled" : null}
                  >
                    <option value="selected" disabled>
                      Equipment State
                    </option>
                    <option value="AK">AK</option>
                    <option value="AL">AL</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DC">DC</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="GU">GU</option>
                    <option value="HI">HI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="PR">PR</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VA">VA</option>
                    <option value="VI">VI</option>
                    <option value="VT">VT</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </select>
                  {errors.EquipmentState && <p className="errorMessage">{errors.EquipmentState}</p>}
                </div>


                {/* <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm" >Equipment Country</label>
                  <select
                    value={applicant.EquipmentCountry || 'selected'}
                    name="EquipmentCountry"
                    onChange={handleInputApplicant}
                    className={`${errors.EquipmentCountry ? "imputError" : "campo"}`}
                    placeholder="Equipment Country"
                    required
                    disabled={(type === 'edit') ? "disabled" : null}
                  >
                    <option value="selected" disabled>
                      Equipment Country
                    </option>
                    <option value="US">US</option>
                    <option value="CA">CA</option>
                  </select>
                  {errors.EquipmentCountry && <p className="errorMessage">{errors.EquipmentCountry}</p>}
                </div> */}


                <div className="mediowidth-left campoContainer">
                  <label className="labelApplicationForm" >Equipment Postal Code</label>
                  <input
                    type="number"
                    value={applicant.EquipmentPostalCode}
                    name="EquipmentPostalCode"
                    onChange={handleInputApplicant}
                    className={`${errors.EquipmentPostalCode ? "imputError" : "campo"}`}
                    placeholder='Equipment Postal Code'
                    required
                    disabled={(type === 'edit') ? "disabled" : null}
                  />
                  {errors.EquipmentPostalCode && <p className="errorMessage">{errors.EquipmentPostalCode}</p>}
                </div>

                <div className="mediowidth-right campoContainer"></div>
            </>
        }

        <div className='mediowidth-right' style={{justifySelf: 'end', marginTop: '15px'}}>
          <Button
           onClick={handleSubmit}
           value={(commercialLoanApplicationId && step.EquipmentInformation) ? "Save" : "Save and continue"}
           isLoading={isLoading}
           showButton={type === 'create'}
          />
        </div>

        <div className="fullwidth campoContainer">
          <AlertBox 
              showAlert={notificationError.show}
              type={notificationError.type}
              title={notificationError.title}
              text={notificationError.text}
          />
        </div>
       

      </form>
    </div>
  )
}

export default EquipmentInformationForm