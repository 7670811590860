import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/Settings.css'
import { AuthContext } from '../AuthContext';
import SkeletonLoader from "tiny-skeleton-loader-react";
import Spinner from 'react-spinner-material';
import NotificationManagementBanner from '../components/NotificationManagementBanner'
import NotificationSettings from '../components/settings/NotificationSettings';
import UserPersonalInformation from '../components/settings/UserPersonalInformation';
import AlertBox from '../components/AlertBox'
require('dotenv').config();




const Settings = () => {

  const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);


  const [editMode, setEditMode] = useState(false)
  const [editData, setEditData] = useState({})
  const [isLoadingEditUser, setIsLoadingEditUser] = useState(false)

  const [errorsChangePassword, setErrorsChangePassword] = useState({});
  const [isSubmittingChangePassword, setIsSubmittingChangePassword] = useState(false);

  const [userData, setUserData] = useState({})
  const [reloadGetUserDetail, setReloadGetUserDetail] = useState(false)

  const [reloadGetUserData, setReloadGetUserData] = useState(false)

  const [dealerUUId, setDealerUUId] = useState('') 

  const [isLoadingUser, setIsLoadingUser] = useState(false)

  const [changePasswordForm, setChangePasswordForm] = useState({})
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [changePasswordStatusOk, setChangePasswordStatusOk] = useState(false);
  const [changePasswordStatusWrongPassword, setChangePasswordStatusWrongPassword] = useState(false);
  const [changePasswordStatusGeneralWrong, setChangePasswordStatusGeneralWrong] = useState(false);
  const [changePasswordStatusManyIntents, setChangePasswordStatusManyIntents] = useState(false);
  const [changePasswordAlertBox, setChangePasswordAlertBox] = useState({show: false})

  //NOTIFICATIONS
  const [notificationInfo, setNotificationInfo] = useState({})








  const token = window.localStorage.getItem('token');
  const user = window.localStorage.getItem('user');
  const refreshToken = window.localStorage.getItem('refreshToken');
  const dealerId = window.localStorage.getItem('dealerId');
  const accessToken = window.localStorage.getItem('accessToken');
  const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
  const userRole = localStorage.getItem('userRole');
  const URI_X_API_KEY_I = uri.URI_X_API_KEY;

  




  /************************************ GET USAR DETAILS *********************************************************/
  //FETCH GET USER DETAIL
  useEffect(() => {
    setIsLoadingUser(true)


    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_GET_USER}`
    // console.log(URI)

    const username = user;
    const form = { username: username }


    const abortController = new AbortController();
    const signal = abortController.signal;


    window.fetch(URI, {
      method: 'POST',
      signal: signal,
      headers: {
        'Access-Control-Request-Headers': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token,
        'DealerId': dealerId,
        'x-api-key': URI_X_API_KEY_I,
      },
      body: JSON.stringify(form),
    })
    .then(response => {

      if (response.ok) {
          return response.json();
        }
      return Promise.reject(response);
    })
      .then(datos => {
        setIsLoadingUser(false)

        //console.log(datos);


        setUserData(datos)

        setDealerUUId(datos.dealer_uuid)

        const data = {}
        data.first_name = datos.first_name;
        data.last_name = datos.last_name;
        data.phone_number = datos.phone_number;
        data.title = datos.title;
        data.username = datos.username;
        setEditData(data)


        //NOTIFICATIONS
        setNotificationInfo({
          notificationsActive: datos.notificationsActive,
          notificationsStatuses: datos.notificationsStatuses,

        })

        // setEnableNotifications(datos.notificationsActive)

        // if(datos.hasOwnProperty('notificationsStatuses')){
          
        //   const newNotificationsOptions = {}

        //   datos.notificationsStatuses.forEach(item => {
        //   if (item === "All") {
        //     newNotificationsOptions.All = true
        //   } else if (item === "Pending") {
        //     newNotificationsOptions.Pending = true
        //   } else if (item === "ConditionallyApproved") {
        //     newNotificationsOptions.ConditionallyApproved = true
        //   } else if (item === "Approved") {
        //     newNotificationsOptions.Approved = true
        //   } else if (item === "SignReady") {
        //     newNotificationsOptions.SignReady = true
        //   } else if (item === "Received") {
        //     newNotificationsOptions.Received = true
        //   } else if (item === "PendingDocs") {
        //     newNotificationsOptions.PendingDocs = true
        //   } else if (item === "InVerification") {
        //     newNotificationsOptions.InVerification = true
        //   } else if (item === "InConfirmation") {
        //     newNotificationsOptions.InConfirmation = true
        //   } else if (item === "ReadyToFund") {
        //     newNotificationsOptions.ReadyToFund = true
        //   }else if (item === "Denied") {
        //     newNotificationsOptions.Denied = true
        //   }else if (item === "Problem") {
        //     newNotificationsOptions.Problem = true
        //   }else if (item === "Cancelled") {
        //     newNotificationsOptions.Cancelled = true
        //   }else if (item === "StatusMessages") {
        //     newNotificationsOptions.StatusMessages = true
        //   }
        // })

        // setNotificationsOptions({
        //   ...notificationsOptions,
        //   ...newNotificationsOptions,
        // })


        // }




        setErrorFlagFromContext(false)


      }).catch((error, response) => {
        console.log(error)
        if(errorFlagFromContext === false && error.status === 401){
            changeToken().then(response => console.log(response)).catch(error => console.log(error))
        }    
    });


    return function cleanup() {
      abortController.abort();
    }


  }, [reloadFetchFromContext, reloadGetUserData]);




  /************************************ CHANGE PASSWORD *********************************************************/
  //CHANGE PASSWORD
  const changePassword = () => {


    setChangePasswordAlertBox({show:false})
    setLoadingChangePassword(true)
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}change-password`;
    console.log(URI)

    const body = {
      username: user,
      password: changePasswordForm.password,
      new_password: changePasswordForm.newPassword,
      access_token: accessToken,
    }
    console.log(body)

    window.fetch(URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': URI_X_API_KEY_I,
        'Authorization': token,
      },
      body: JSON.stringify(body),

    })
      .then(response => response.json())
      .then(datos => {
        setLoadingChangePassword(false);
        console.log('RESPUESTA DE CHANGE PASSWORD')
        console.log(datos);

        //setReloadToken(true)

        //console.log(datos.messages[0])

        // if (datos.Messages[0] === 'Info: Access Token has expired') {
        //   //changeTokens()

        // }



        // if (datos.status === 'INCORRECT_USERNAME_OR_PASSWORD') {
        //   setChangePasswordStatusManyIntents(false)
        //   setChangePasswordStatusOk(false)
        //   setChangePasswordStatusGeneralWrong(false);
        //   setChangePasswordStatusWrongPassword(true)
        // } else if (datos.status === 'ATTEMPT_LIMIT_EXCEEDED') {
        //   setChangePasswordStatusOk(false)
        //   setChangePasswordStatusGeneralWrong(false);
        //   setChangePasswordStatusWrongPassword(false)
        //   setChangePasswordStatusManyIntents(true)
        // } else if (datos.status === 'SUCCESS') {
        //   setChangePasswordStatusGeneralWrong(false);
        //   setChangePasswordStatusWrongPassword(false)
        //   setChangePasswordStatusManyIntents(false)
        //   setChangePasswordStatusOk(true)
        // } else if (datos.status === 'ERROR') {
        //   setChangePasswordStatusWrongPassword(false)
        //   setChangePasswordStatusManyIntents(false)
        //   setChangePasswordStatusOk(false)
        //   setChangePasswordStatusGeneralWrong(true);
        // }

        if(datos.status === 'SUCCESS'){
          setChangePasswordAlertBox({ 
            title: 'Password changed successfully.',
            type: 'success',
            show: true
          })
        }else{
          setChangePasswordAlertBox({ 
            title: datos.Message,
            text: datos.Errors[0],
            type: 'error',
            show: true
          })
        }
        



      })







  }


  const handleSubmit = event => {

    setErrorsChangePassword(validateFormChangePassword(changePasswordForm));
    setIsSubmittingChangePassword(true);

  };

  //VALIDAR ERRORES Y ENVIAR FORMULARIO DE CAMBIAR CONTRASEÑA
  useEffect(() => {
    if (Object.keys(errorsChangePassword).length === 0 && isSubmittingChangePassword) {

      changePassword();
    } 
  }, [errorsChangePassword]);



  const validateFormChangePassword = (data) => {
    let errorList = {};

    //Password
    if (!data.password) {
      errorList.password = "Please enter the current password";
    }

    //New Password
    if (!data.newPassword) {
      errorList.newPassword = "Please enter a new password";
    } else if (data.newPassword !== data.newPassword2) {
      errorList.newPassword = "The new password does not match";
    } 
    // else if (data.newPassword.length > 100) {
    //   errorList.newPassword = "The password must have a maximum of 100 characters";
    // } 

    //New Password2
    if (!data.newPassword2) {
      errorList.newPassword2 = "Please repeat the new password";
    } else if (data.newPassword2 !== data.newPassword) {
      errorList.newPassword2 = "The new password does not match";
    }
    // else if (data.newPassword2.length > 100) {
    //   errorList.newPassword2 = "The password must have a maximum of 100 characters";
    // } 

    return errorList;
  };




  /************************************ EDIT USER *********************************************************/

  const handleEditInput = (event) => {
    setEditData({
      ...editData,
      [event.target.name]: event.target.value,
    })
  }


  //FETCH POST EDIT USER
  const handleInputEditUser = () => {

    setIsLoadingEditUser(true);

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_UPDATE_USER}`
    //console.log(URI)

    //console.log(editData)

    window.fetch(URI, {
      method: 'POST',
      headers: {
        'Access-Control-Request-Headers': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token,
        'DealerId': dealerId,
        'x-api-key': URI_X_API_KEY_I,
      },
      body: JSON.stringify(editData),
    })
      .then(response => {
        //console.log(response.status)
        return response.json()
      })
      .then(datos => {
        setIsLoadingEditUser(false);

        //console.log(datos);

        if (datos.status === 'SUCCESS') {
          window.location.reload();
        }




      }).catch(error => {
        console.log(error)
        // changeToken();
        // setReloadGetUserDetail(!reloadGetUserDetail);


      });


  }




 
  






  return (
    <>
      <div className="settings">


        {/* GENERAL INFO */}
        <div className="settings_container">
          <UserPersonalInformation data={userData} isLoadingUser={isLoadingUser} reloadGetUserData={setReloadGetUserData}>
              <h2 className="settings-title_section">Account information</h2> 
          </UserPersonalInformation>
        </div>


         {/* NOTIFICATIONS */}
         <NotificationSettings notificationInfo={notificationInfo}/>








        {/* ALERTS*/}
        {
          isDeallerAllyAdmin === 'true' &&
          <div className="settings_container">
            <h2 className="settings-title_section">Alerts</h2>
            <NotificationManagementBanner />
          </div>  
        }
        



        {/* PASSWORD*/}
        <div className="settings_container">

          <h2 className="settings-title_section">Change Password</h2>
          <p className="settings_smalltext">Password requires numbers, special character, uppercase and lowercase letters, and minimum 8 characters length.</p>

          <div className="settings-changePassword">
            <input
              type="password"
              name="password"
              className="settings-changePassword_input"
              placeholder="Current password"
              onChange={(e) => { setChangePasswordForm({ ...changePasswordForm, password: e.target.value }) }}
            />
            {errorsChangePassword.password && <p className="errorMessage">{errorsChangePassword.password}</p>}

            <input
              type="password"
              name="newPassword"
              className="settings-changePassword_input"
              placeholder="New password"
              onChange={(e) => { setChangePasswordForm({ ...changePasswordForm, newPassword: e.target.value }) }}
            />
            {errorsChangePassword.newPassword && <p className="errorMessage">{errorsChangePassword.newPassword}</p>}

            <input
              type="password"
              name="newPassword2"
              className="settings-changePassword_input"
              placeholder="Repeat new password"
              onChange={(e) => { setChangePasswordForm({ ...changePasswordForm, newPassword2: e.target.value }) }}
            />
            {errorsChangePassword.newPassword2 && <p className="errorMessage">{errorsChangePassword.newPassword2}</p>}

            {loadingChangePassword ?
              <div className="settings-changePassword_button">
                <Spinner
                  radius={20}
                  color={"white"}
                  stroke={3}
                  visible={true}
                />
              </div>
              :
              <button className="settings-changePassword_button" onClick={handleSubmit}>Change password</button>

            }

            {/* {changePasswordStatusOk ? <p className="settings-message_ok">Password changed successfully.</p> : null}

            {changePasswordStatusWrongPassword ? <p className="settings-message_error">Error. Wrong password. Check your password and try again.</p> : null}

            {changePasswordStatusGeneralWrong ? <p className="settings-message_error">Password requires numbers, special character, uppercase and lowercase letters, and minimum 8 characters length.</p> : null}

            {changePasswordStatusManyIntents ? <p className="settings-message_error">Attempt limit exceeded, please try after some time.</p> : null} */}

            <AlertBox 
              showAlert={changePasswordAlertBox.show}
              // showAlert={true}
              type={changePasswordAlertBox.type}
              title={changePasswordAlertBox.title}
              text={changePasswordAlertBox.text}
            />


          </div>


        </div>


        {/* IFRAME */}
        {
          (userRole === 'DealerAlly Admin' || userRole === 'Manager') &&
            <div className="settings_container">
            <h2 className="settings-title_section">Iframe</h2>
            <p className="settings_smalltext">Use the following Iframe to render a loan form on your website.</p>

            <div className="iframeContainer">
              <p>{
                process.env.REACT_APP_BUILD_ENV === 'dev' || process.env.REACT_APP_BUILD_ENV === 'stg' ?
                `<iframe src="https://portal-${process.env.REACT_APP_BUILD_ENV}.dealerallyapi.com/iframe/${dealerUUId}" 
                  frameborder="0"
                  id="daIframe"
                  height="800px"   
                  />
                `
                :
                `<iframe src="https://portal.dealerallyapi.com/iframe/${dealerUUId}" 
                  frameborder="0"
                  id="daIframe"
                  height="800px"   
                  />
                `         
              }
              </p>
            </div>
            </div>
        }
        
            

        <div className="settings_container">
          <p>DealerAlly Portal V1.35.0</p>
        </div>

      </div>


    </>
  )
}

export default Settings;