import React, { useState, useContext, useEffect } from 'react';
import {Link} from 'react-router-dom';
//import '../../assets/styles/Applications.css'
import '../../assets/styles/ApplicantsCardsView.css'
import ApplicationsCard from './ApplicationsCard';
import SkeletonLoader from "tiny-skeleton-loader-react";
import {AuthContext} from '../../AuthContext';
import ApplicantCard from './ApplicantCard'
import ProposalList from '../proposals/ProposalList';




 const ApplicanstCardsView = ({termSearch, callApplicantRealList, refreshGetApplicant, history}) => {
   

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [fetchError, SetFetchError] = useState(false);

    const [errorIndex, setErrorIndex] = useState(false);

    const [reloadGetApplications, setReloadGetApplications] = useState(false)
   
    const [applicantList, setApplicantList] = useState([])
    const [applicantList2, setApplicantList2] = useState([])
    const [applicationList, setApplicationList] = useState([])

    const [proposalList, setProposalList] = useState([])

    const [showDetailsApplicationInMobile, setShowDetailsApplicationInMobile] = useState(false)

    const [isLoadingApplicantList, setIsLoadingApplicantList] = useState(false);
    const [statusFetchGetApplications, setStatusFetchGetApplications] = useState('')
    
    const [applicantId, setApplicantId] = useState('')

    //const [termSearch, setTermSearch] = useState('')
    

    
    //RECUPERAR VALORES DE SESSIONSTORAGE Y CONTEXTAPI
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const endpoint = localStorage.getItem("getApplicationsEndpoint");
    const refreshToken = window.localStorage.getItem('refreshToken');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    
    //PERMISO DE CREAR APLIACION O NO
    const cantCreateApplication = window.localStorage.getItem("can_create_applications");
    const dealerCanCreateApplication = window.localStorage.getItem("dealer_can_create_applications");


    


    //FETCH GET APPLICANTS
    useEffect(()=>{
        setIsLoadingApplicantList(true)

        // setStatusFetchGetApplications('')
        // setApplicationList([])
        

        let endpoint = ''
        if(callApplicantRealList === 'true'){
            endpoint = uri.URI_GET_APPLICANTS
        }else{
            endpoint = uri.URI_GET_PROSPECT_APPLICANTS
        }


        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${endpoint}`
        //console.log(URI)

        const abortController = new AbortController();
        const signal = abortController.signal;

        

        fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,   
                'x-api-key' : URI_X_API_KEY_I,   
              }
        })
        .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoadingApplicantList(false)
   
            //console.log(datos);
           
            if(datos.status === 'NO_APPLICANTS_FOUND'){
                setApplicantList([])
                setApplicantList2([])
                setApplicantId('')
                setApplicationList([])
                setStatusFetchGetApplications('')
            }else if(datos.status === 'USER_NOT_FOUND'){
                //console.log('USER_NOT_FOUND')
            }else if(datos.status === 'INTERNAL_SERVER_ERROR'){
                //console.log('INTERNAL_SERVER_ERROR')
            }else if(Array.isArray(datos)){
                setApplicantList(datos)
                setApplicantList2(datos)

                if(width >= 701){
                    setApplicantId(datos[0].ApplicantId)
                }
                
            }


            
            setErrorFlagFromContext(false)

        })
        .catch((error, response) => {
           

            if(errorFlagFromContext === false && error.status === 401){
                changeToken()
            }

        
        });
        

        return function cleanup(){
            abortController.abort();
        }
        
    },[fetchError, callApplicantRealList, refreshGetApplicant, reloadFetchFromContext]);


  



    //FETCH GET APPLICATIONS
    useEffect(()=>{


        if(applicantId !== '' ){


                setStatusFetchGetApplications('loading')


                let endpoint = ''
                if(callApplicantRealList === 'true'){
                    endpoint = uri.URI_GET_APPLICATIONS_BY_ID
                }else{
                    endpoint = uri.URI_GET_PROSPECT_APPLICATIONS_BY_APPLICANT_ID
                }

        

                const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${endpoint}${applicantId}`
                //console.log(URI)


                const abortController = new AbortController();
                const signal = abortController.signal;


                fetch(URI,{
                    method: 'GET',
                    signal: signal,
                    headers: {
                        'Access-Control-Request-Headers': 'application/json',           
                        'Content-Type': 'application/json', 
                        'username' : user,
                        'Authorization' : token,   
                        'x-api-key' : URI_X_API_KEY_I,   
                      }
                })
                .then(response => {

                    if (response.ok) {
                        return response.json();
                      }
                    return Promise.reject(response);
                })
                .then(datos => {
                   

  
                    //console.log(datos);
                
                    if(datos.status === 'NO_APPLICATIONS_FOUND'){
                        //console.log('NO_APPLICATIONS_FOUND')
                        setStatusFetchGetApplications('not_found')
                    }else if(datos.status === 'INTERNAL_SERVER_ERROR'){
                        //console.log('INTERNAL_SERVER_ERROR')
                    }else{
                        setApplicationList(datos)
                        setStatusFetchGetApplications('has_application')
                    }


                    setErrorFlagFromContext(false)

                })
                .catch((error, response) => {
                    console.log(error)
                    if(errorFlagFromContext === false && error.status === 401){
                        changeToken().then(response => console.log(response)).catch(error => console.log(error))
                    }    
                });
                

                return function cleanup(){
                    abortController.abort();
                }


        }
        
        
        
    },[fetchError, applicantId, reloadGetApplications, reloadFetchFromContext]);





     //FETCH GET PROPOSALS
     useEffect(()=>{


        if(applicantId !== '' && callApplicantRealList === 'true'){
            // setStatusFetchGetApplications('loading')

            setProposalList([])
            
            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-customer-proposal-by-applicant-id/${applicantId}`
            // console.log(URI)


            const abortController = new AbortController();
            const signal = abortController.signal;


            fetch(URI,{
                method: 'GET',
                signal: signal,
                headers: {
                    'Access-Control-Request-Headers': 'application/json',           
                    'Content-Type': 'application/json', 
                    'username' : user,
                    'Authorization' : token,   
                    'x-api-key' : URI_X_API_KEY_I,   
                  }
            })
            .then(response => response.json())
            .then(datos => {
               

            //  console.log(datos);
               if(datos.status === 'PROPOSALS_NOT_FOUND'){
                //    console.log('PROPOSALS_NOT_FOUND')
                setProposalList([])
               }else if(datos.length > 0){
                   setProposalList(datos) 
               }
              
  
            })
            .catch(error => {
                console.log(error)

               

            })
               
        
        }else if(callApplicantRealList === 'false'){
            setProposalList([])
        }
    
        
    },[applicantId]);








    //LIMPIAR APPLICATIONSLIST CUANDO EL USUARIO BUSCA
    useEffect(()=>{
        setApplicantId('')
        setApplicationList([])
        setStatusFetchGetApplications('')
        setProposalList([])
    }, [termSearch])

   

    //BUSCADOR
    useEffect(()=>{
        if(termSearch === ''){
            setApplicantList(applicantList2)
        }else{
            const results = applicantList2.filter(applicant => applicant.FullName.toLowerCase().includes(termSearch.toLowerCase()))
            setApplicantList(results)
        }
    }, [termSearch])





    //LOADING CARDS
    const loadingApplicantCard = () =>{
        const list = [1,2,3,4,5,6,7,8]
        return (list.map((item, index) =>{
            return (<div className="loadingApplicantCard" key={index}>
                <div className="loading_applicantCard-header">
                        <SkeletonLoader width="70%" height="20px"/>
                        <div className="applicant_applicantCard-number"></div>
                </div>
                <div className="loading_applicantCard-body">
                    <div className="loading_applicantCard-body1"><SkeletonLoader width="100%"/></div>
                    <div className="loading_applicantCard-body2"><SkeletonLoader width="100%"/></div>
                    <div className="loading_applicantCard-body1"><SkeletonLoader width="100%"/></div>
                    <div className="loading_applicantCard-body2"><SkeletonLoader width="100%"/></div>
                    <div className="loading_applicantCard-body1"><SkeletonLoader width="100%"/></div>
                    <div className="loading_applicantCard-body2"><SkeletonLoader width="100%"/></div>
                   
                </div>

            
          </div>)
        }))
    }


     //LOADING CARDS
     const loadingApplicationCard = () =>{
        const list = [1,2,3,4]
        return (list.map((item, index) =>{
            return (
            <div className="loadingApplicationCard" key={index}>

                <div className="loadingApplicationCard_header">
                    <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="20%" height="25px"/></div>
                    <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="10%" height="15px"/></div>
                </div >

                <div className="loadingApplicationCard_body">

                    <div className="loadingApplicationCard_body-section"> 
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                    </div>

                    <div className="loadingApplicationCard-line"></div>

                    <div className="loadingApplicationCard_body-section"> 
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                    </div>

                    <div className="loadingApplicationCard-line"></div>

                    <div className="loadingApplicationCard_body-section"> 
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                        <div className="loadingApplicationCard_marginBotton"><SkeletonLoader width="100%" height="15px"/></div>
                    </div>

                </div>

            
            </div>
          )
        }))
    }

    
    
    //RESPONSIVE LOGIC
    const getWidth = () => window.innerWidth 
        || document.documentElement.clientWidth 
        || document.body.clientWidth;
    const [width, setWidth] = useState(getWidth());
        const useCurrentWitdh =()=> {
        
        useEffect(() => { 
            let timeoutId = null;
            const resizeListener = () => {
            clearTimeout(timeoutId); 
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
            };    
            window.addEventListener('resize', resizeListener);      
            return () => {
            window.removeEventListener('resize', resizeListener);
            }
        }, [])
        
        return width; 
    }
    useCurrentWitdh()



    const showDetailsInMobile =( applicantIdVar)=>{  
        setApplicantId(applicantIdVar);

        if(applicantId === '' || applicantId != applicantIdVar){
            setShowDetailsApplicationInMobile(true)
        }else{
            setShowDetailsApplicationInMobile(false)
                if( width <= 701){
                    setApplicantId('')
                }
            
        }
    }




    return(
    <>
    
   
    
            <div className="applicants_mainContainer">

                {/* Columna de applicants*/}
                <div className="applicants_mainContainer-applicantsColumn">
                   


                    <div className="applicants_listContainer">
                    

                    {isLoadingApplicantList ? loadingApplicantCard() : null}
                    {
                        applicantList.map((item, index) => {
                                return(
                                    <div className="applicant_card-container" key={index}>
                                        {/* {applicantCard(item)} */}
                                        <ApplicantCard applicant={item} event={()=>{showDetailsInMobile(item.ApplicantId)}} applicantId={applicantId} isRealApplication={callApplicantRealList === 'true' ? true : false}/>

                                        <div className="applicant_applicationDetail-mobile">

                                            {   
                                                    // validacion para abrir y cerrar los detalles en mobile
                                                    width < 701 && item.ApplicantId === applicantId && showDetailsApplicationInMobile === true ? 

                                                        <div className="applicant_applicationCard">
                                                        

                                                            <>
                                                        
                                                                {statusFetchGetApplications === 'loading' ? loadingApplicationCard() : 
                                                            
                                                                statusFetchGetApplications === 'not_found' ? 
                                                                    <div className="applicants_applicationsListContainer-text-button-div">
                                                                        <p className="applicants_applicationsListContainer-text">This account does not have any applications created yet.</p>
                                                                        {//isDeallerAllyAdmin != 'true'  && (dealerCanCreateApplication === 'true' && waitingForActivation != 'true') ?
                                                                        isDeallerAllyAdmin != 'true' && dealerCanCreateApplication === 'true' && callApplicantRealList === 'true' ?
                                                                            cantCreateApplication === 'true' ?
                                                                                <Link to={`createapplication/${applicantId}`} className="applicants_applicationsListContainer-newApplicationButton">New Application</Link>
                                                                        : null : null}
                                                                    </div> :



                                                                statusFetchGetApplications === 'has_application' ? 
                                                                <>
                                                                    {
                                                                        applicationList.map((item, index)=>{
                                                                            return(
                                                                                <ApplicationsCard application={item} key={index} setReloadGetApplications={setReloadGetApplications}/>
                                                                            )
                                                                        })
                                                                    }
                                                                    <div className="applicants_applicationsListContainer-newApplicationButton-container">
                                                                        {//isDeallerAllyAdmin != 'true'  && (dealerCanCreateApplication === 'true' && waitingForActivation != 'true') ?
                                                                            isDeallerAllyAdmin != 'true' && dealerCanCreateApplication === 'true' && callApplicantRealList === 'true'?
                                                                                cantCreateApplication === 'true' ?
                                                                                    <Link to={`createapplication/${applicantId}`} className="applicants_applicationsListContainer-newApplicationButton">New Application</Link>
                                                                        : null : null}
                                                                    </div>
                                                                </>
                                                                : null


                                                                
                                                                }

                                                                {applicantId && proposalList.length > 0 && 
                                                                <>
                                                                    <h3 className="applicants_applicationsListContainer-subtitle margin-top-20px proposals_subtitle">Proposals</h3>
                                                                    <ProposalList history={history} proposalList={proposalList} applicantId={applicantId}/>
                                                                </>
                                                                }
                                                            </>



                                                        </div>

                                                    : null
                                            }
                                            
                                        </div>
                                    </div>
                                )
                        })
                    }
                    </div>
                    </div>





                {/* Columna de applications */}
                <div className="applicants_applicationsListContainer" >
                    
                            {
                                width >= 701 ? 
                                    <>
                                        {applicantId && <h3 className="applicants_applicationsListContainer-subtitle">Applications</h3>}

                                        {statusFetchGetApplications === 'loading' ? loadingApplicationCard() 
                                        
                                        : 

                                        statusFetchGetApplications === 'not_found' ? 
                                            <div className="applicants_applicationsListContainer-text-button-div">
                                                <p className="applicants_applicationsListContainer-text">This account does not have any applications created yet.</p>

                                                
                                                {//isDeallerAllyAdmin != 'true'  && (dealerCanCreateApplication === 'true' && waitingForActivation != 'true') ?
                                                isDeallerAllyAdmin != 'true' && dealerCanCreateApplication === 'true' && callApplicantRealList === 'true'?
                                                    cantCreateApplication === 'true' ? 
                                                        <Link to={`createapplication/${applicantId}`} className="applicants_applicationsListContainer-newApplicationButton">New Application</Link>
                                                : null : null} 


                                            </div> 
                                            
                                        :


                                        statusFetchGetApplications === 'has_application' ? 
                                        <>
                                        {
                                            applicationList.map((item, index)=>{
                                                return(
                                                    <ApplicationsCard application={item} key={index} applicant_Id={applicantId} setReloadGetApplications={setReloadGetApplications}/>
                                                )
                                            })
                                        }

                                        <div className="applicants_applicationsListContainer-newApplicationButton-container">
                                            {//isDeallerAllyAdmin != 'true'  && (dealerCanCreateApplication === 'true' && waitingForActivation != 'true') ?
                                            isDeallerAllyAdmin != 'true' && dealerCanCreateApplication === 'true' && callApplicantRealList === 'true'?
                                                cantCreateApplication === 'true' ? 
                                                    <Link to={`createapplication/${applicantId}`} className="applicants_applicationsListContainer-newApplicationButton">New Application for This Applicant</Link>
                                            : null : null} 
                                        </div>

                                        </>
                                        : null
                                    }

                                    {applicantId && proposalList.length > 0 && 
                                    <>
                                        <h3 className="applicants_applicationsListContainer-subtitle margin-top-20px">Proposals</h3>
                                        <ProposalList history={history} proposalList={proposalList} applicantId={applicantId}/>
                                    </>
                                    }
                                    </>
                                : null
                            }
                            
                </div>


            </div>
      

      

   
    </>
)













}



export default ApplicanstCardsView
