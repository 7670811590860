import React, { useState, useContext } from 'react';
import '../../assets/styles/ReportRow.css'
import {AuthContext} from '../../AuthContext';
import { useEffect} from 'react';
import SkeletonLoader from "tiny-skeleton-loader-react";
import Paginator from 'react-hooks-paginator';
import ApplicationRow from '../applicationsPage/ApplicationRow';
import iconArrowDownGreen from '../../assets/images/iconArrowDownGreen.png';
import iconArrowDownGrey2 from '../../assets/images/iconArrowDownGrey2.png';
import iconArrowUpGreen from '../../assets/images/iconArrowUpGreen.png';
import iconArrowUpGrey2 from '../../assets/images/iconArrowUpGrey2.png';
import moment from 'moment-timezone';
import LoanApplicationDetailRow from './LoanApplicationDetailRow';
import CsvDownload from 'react-json-to-csv'


const ApplicationDetailReportList = ({termSearch, searchBy, filterStatusValue, filterFIValue, refreshGetApplicant, filterStatusSelected, dateRange, setNetProceedsTotal})=>{


   

    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);

    
    const [applicationData, setApplicationData] = useState([]);

    const [originalData, SetoriginalData] = useState([]);

    //const [showFilter, setShowFilter] = useState(false);

    const [hasData, setHasData] = useState(true);

  
    const [filterIndex, SetFilterIndex] = useState(2);


    const [reorder, setReorder] = useState(false);

    const [propertyFilter, setPropertyFilter] = useState('DealerAllyFundingDate')

    //Pagination
    const pageLimit = 20;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);



    
    //RECUPERAR VALORES DE SESSIONSTORAGE Y CONTEXTAPI
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');
    const endpoint = localStorage.getItem("getApplicationsEndpoint");   
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');



    

    //FETCH GET REPORT APPLICATIONS
    useEffect(()=>{
        setIsLoading(true)


        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_REPORTS}get-all-applications`
        // console.log(URI)

        const abortController = new AbortController();
        const signal = abortController.signal;


        fetch(URI,{
            method: 'GET',
            signal: signal,
            headers: {
                'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token, 
                'x-api-key' : URI_X_API_KEY_I,   
              }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
        .then(datos => {
            setIsLoading(false)
           
            // console.log(datos);

           // console.log(roundOutNumbers(datos))
                
            if(datos.status === 'NO_APPLICATIONS_FOUND'){
                setHasData(false)
            }else{
                setApplicationData(roundOutNumbers(datos));
                SetoriginalData(roundOutNumbers(datos));

                

                
            }
           
            

            setErrorFlagFromContext(false)

        })
        .catch((error, response) => {
            // console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }    
        });
        

        return function cleanup(){
            abortController.abort();
        }
        
    },[reloadFetchFromContext, refreshGetApplicant]);

 



    const roundOutNumbers = (list) => {
        if(Array.isArray(list)){
            const newList = list.map(item => {
                return(
                    {
                        ...item,
                        AmountFinanced : item.AmountFinanced ? parseFloat(item.AmountFinanced).toFixed(2) : undefined,
                        SaleAmount : item.SaleAmount ? parseFloat(item.SaleAmount).toFixed(2) : undefined,
                        DownPayment : item.DownPayment ? parseFloat(item.DownPayment).toFixed(2) : undefined,
                        SalesTax : item.SalesTax ? parseFloat(item.SalesTax).toFixed(2) : undefined,
                    }
                )
            })
    
            return(newList)
        }else{
            return []
        }
        
    }






    
    //Pagination
    useEffect(() => {

        if(applicationData.length > 0){
            // console.log(applicationData.length)
            
            setCurrentData(applicationData.slice(offset, offset + pageLimit));       
        }else {
            setCurrentData([].slice(offset, offset + pageLimit)); 
        }
        

    }, [offset, applicationData, reorder ]);

   





     //SEARCHER AND FILTER
     useEffect(()=>{

        if(termSearch === ''){
            const results = originalData.filter(applicant => (filterStatusSelected.length > 0 ?  filterStatusSelected.includes(applicant.Status): true )
                                                            && (filterFIValue !== '' ? (applicant.Lender || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true )
                                                            && ( Object.keys(dateRange).length === 0 ? true 
                                                            : 
                                                            applicant.Created === undefined || applicant.Created === '' ?
                                                            false
                                                            :
                                                            moment(applicant.Created).isBetween(moment(dateRange.startDate),moment(dateRange.endDate), undefined, '[]'))
        )
        setApplicationData(results)

        }else if(searchBy === 'All'){
            const results = originalData.filter(applicant => (
                                                                   ((applicant.DaNbr || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.Dealer || '').toLowerCase().includes(termSearch.toLowerCase())       
                                                                || (applicant.Applicant || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.LenderNbr || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.PinnacleProgram || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.UserFirstName || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.BuyRate || '').toLowerCase().includes(termSearch.toLowerCase())
                                                                || (applicant.Tier || '').toLowerCase().includes(termSearch.toLowerCase()))
                                                                && (filterFIValue !== '' ? (applicant.Lender || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true )
                                                                && (filterStatusSelected.length > 0 ?  filterStatusSelected.includes(applicant.Status): true )
                                                                && (Object.keys(dateRange).length === 0 ? true 
                                                                : 
                                                                applicant.Created === undefined || applicant.Created === '' ? false
                                                                : 
                                                                moment(applicant.Created).isBetween(moment(dateRange.startDate),moment(dateRange.endDate), undefined, '[]'))
                                                            ))
            //console.log(results)
            setApplicationData(results)
        }else { 
            const results = originalData.filter(applicant => (  ((applicant[searchBy] || '').toLowerCase().includes(termSearch.toLowerCase()))
                                                                && (filterStatusSelected.length > 0 ?  filterStatusSelected.includes(applicant.Status): true ) 
                                                                && ((filterFIValue !== '' ? (applicant.Lender || '').toLowerCase().includes(filterFIValue.toLowerCase()) : true ))  
                                                                && (Object.keys(dateRange).length === 0 ? true 
                                                                : 
                                                                applicant.Created === undefined || applicant.Created === '' ? false
                                                                : 
                                                                moment(applicant.Created).isBetween(moment(dateRange.startDate),moment(dateRange.endDate), undefined, '[]'))
                                                            )
                                                                
            )

            setApplicationData(results)
        }
    }, [termSearch, searchBy, filterStatusValue, filterFIValue, filterStatusSelected, dateRange])
    
  



    



    //SORTER BY COLUMS
    // const dynamicSort =(property) => {
    //     setReorder(!reorder)
    //     setPropertyFilter(property)
      
    //     return function(a, b) { 
    //         console.log(a[property])        
    //         if (filterIndex === 1) {
            
    //             if(b[property] === undefined){
    //                 b[property] = ''
    //             }
    //             if(a[property] === undefined){
    //                 a[property] = ''
    //             }
    //             SetFilterIndex(2)
    //             return b[property].localeCompare(a[property]);
    //         } else {
    //             if(b[property] === undefined){
    //                 b[property] = ''
    //             }
    //             if(a[property] === undefined){
    //                 a[property] = ''
    //             }
    //             SetFilterIndex(1)
    //             return a[property].localeCompare(b[property]);
    //         }
          
    //     };
     
    // }


    const dynamicSort =(property) => {
        // console.log(property)
        setReorder(!reorder)
        setPropertyFilter(property)
      
        return function(a, b) { 
            // console.log(a[property].toString()) 
            if (filterIndex === 1) {
            
                if(b[property] === undefined ){
                    b[property] = ''
                }
                if(a[property] === undefined ){
                    a[property] = ''
                }
                SetFilterIndex(2)
                return b[property].toString().localeCompare(a[property].toString());
            } else {
                if(b[property] === undefined ){
                    b[property] = ''
                }
                if(a[property] === undefined ){
                    a[property] = ''
                }
                SetFilterIndex(1)
                return a[property].toString().localeCompare(b[property].toString());
            }
          
        };
     
    }




    const arrowDirection = (property) => {

        if(property === propertyFilter && filterIndex === 1){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            )
        }else if(property === propertyFilter && filterIndex === 2 ){
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGreen} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }else{
            return (
                <div className="applications_list-tittle-iconContainer">
                    <img src={iconArrowUpGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-up" />
                    <img src={iconArrowDownGrey2} alt="" className="applications_list-tittle-icon applications_list-tittle-icon-down"/>
                </div>
            ) 
        }

       
    }




    // useEffect(() => {
    //     setPropertyFilter('LoanNumber')
    // }, [])

   




    //LOADING CARDS
    const loadingCard = () =>{
        const list = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
        return (list.map(item =>{
            return (
                <div className="tableGrid30 reportRow " key={item}>
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />    
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" /> 
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />    
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" />
                    <SkeletonLoader width="90%" />   
                    <SkeletonLoader width="90%" /> 
                </div> 

            )
        }))
    }







   






    return(
    <>

   
        <div className="reportsListView_mainContainer height-200">
        
        {
            !isLoading && applicationData.length > 0 &&
           
           
            <CsvDownload 
                data={applicationData} 
                filename="DealerAlly_report.csv"
                style={{ //pass other props, like styles
                    backgroundColor: '#31D902',
                    padding: '8px 10px',
                    borderRadius: '4px',
                    color: 'white',
                    cursor: 'pointer',
                    }}
            >
                Download Report
            </CsvDownload>
           
        }

        {
            !isLoading &&
            <div className="reports_resultsCounter">
                <p>{`${applicationData.length} results`}</p>
            </div>
        }
        
        

        <div className="applicationDetailReport_listMainContainer">

            
                {/* COLUMN HEADERS */}
                <div className={isDeallerAllyAdmin === 'true' ? "tableGridForAdmin31 report_list-Tableheaders" : "tableGrid30 report_list-Tableheaders"}>
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("DaNbr"))}}>
                        <div>
                            <p>DANbr</p>
                        </div> 
                        {arrowDirection('DaNbr')}
                    </div> 
                    {
                    isDeallerAllyAdmin === 'true' &&  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Dealer"))}}>
                            <div>
                                <p>Dealer</p>
                            </div> 
                            {arrowDirection('Dealer')}
                    </div> 
                    }
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Applicant"))}}>
                        <div>
                            <p>Applicant</p>
                        </div> 
                        {arrowDirection('Applicant')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("CoApplicant"))}}>
                        <div>
                            <p>CoApplicant</p>
                        </div> 
                        {arrowDirection('CoApplicant')}
                    </div>   
                    
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Lender"))}}>
                            <div>
                                <p>Lender</p>
                            </div> 
                            {arrowDirection('Lender')}
                    </div> 
                    
                    
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Status"))}}>
                        <div>
                            <p>Status</p>
                        </div> 
                        {arrowDirection('Status')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("StatusDate"))}}>
                        <div>
                            <p>Status Date</p>
                        </div> 
                        {arrowDirection('StatusDate')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("LenderNbr"))}}>
                        <div>
                            <p>Lender Nbr</p>
                        </div> 
                        {arrowDirection('LenderNbr')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("UserFirstName"))}}>
                        <div>
                            <p>UserFirstName</p>
                        </div> 
                        {arrowDirection('UserFirstName')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("UserLastName"))}}>
                        <div>
                            <p>UserLastName</p>
                        </div> 
                        {arrowDirection('UserLastName')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Created"))}}>
                        <div>
                            <p>Created</p>
                        </div> 
                        {arrowDirection('Created')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("RTCDate"))}}>
                        <div>
                            <p>RTC Date</p>
                        </div> 
                        {arrowDirection('RTCDate')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("AmountFinanced"))}}>
                        <div>
                            <p>Amount Financed</p>
                        </div> 
                        {arrowDirection('AmountFinanced')}
                    </div>  
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("BuyRate"))}}>
                        <div>
                            <p>Buy Rate</p>
                        </div> 
                        {arrowDirection('BuyRate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("Tier"))}}>
                        <div>
                            <p>Tier</p>
                        </div> 
                        {arrowDirection('Tier')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("AquaPromotion"))}}>
                        <div>
                            <p>AquaPromotion</p>
                        </div> 
                        {arrowDirection('AquaPromotion')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("SaleAmount"))}}>
                        <div>
                            <p>SaleAmount</p>
                        </div> 
                        {arrowDirection('SaleAmount')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("DownPayment"))}}>
                        <div>
                            <p>DownPayment</p>
                        </div> 
                        {arrowDirection('DownPayment')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("SalesTax"))}}>
                        <div>
                            <p>SalesTax</p>
                        </div> 
                        {arrowDirection('SalesTax')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PinnacleProgram"))}}>
                        <div>
                            <p>PinnacleProgram</p>
                        </div> 
                        {arrowDirection('PinnacleProgram')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PinnacleTerm"))}}>
                        <div>
                            <p>PinnacleTerm</p>
                        </div> 
                        {arrowDirection('PinnacleTerm')}
                    </div>     
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PinnacleAPR"))}}>
                        <div>
                            <p>PinnacleAPR</p>
                        </div> 
                        {arrowDirection('PinnacleAPR')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PinnaclePaymentFactor"))}}>
                        <div>
                            <p>Pinnacle PaymentFactor</p>
                        </div> 
                        {arrowDirection('PinnaclePaymentFactor')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PinnacleFirstPaymentDate"))}}>
                        <div>
                            <p>Pinnacle First Payment Date</p>
                        </div> 
                        {arrowDirection('PinnacleFirstPaymentDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("CCDocUploadDate"))}}>
                        <div>
                            <p>CCDocUploadDate</p>
                        </div> 
                        {arrowDirection('CCDocUploadDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("MADocUploadDate"))}}>
                        <div>
                            <p>MADocUploadDate</p>
                        </div> 
                        {arrowDirection('MADocUploadDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("SVDocUploadDate"))}}>
                        <div>
                            <p>SVDocUploadDate</p>
                        </div> 
                        {arrowDirection('SVDocUploadDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PHDocUploadDate"))}}>
                        <div>
                            <p>PHDocUploadDate</p>
                        </div> 
                        {arrowDirection('PHDocUploadDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("PIDocUploadDate"))}}>
                        <div>
                            <p>PIDocUploadDate</p>
                        </div> 
                        {arrowDirection('PIDocUploadDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("SEDocUploadDate"))}}>
                        <div>
                            <p>SEDocUploadDate</p>
                        </div> 
                        {arrowDirection('SEDocUploadDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("IDDocUploadDate"))}}>
                        <div>
                            <p>IDDocUploadDate</p>
                        </div> 
                        {arrowDirection('IDDocUploadDate')}
                    </div> 
                    <div className="applications_list-tittle-item" onClick={()=>{applicationData.sort(dynamicSort("OtherDocUploadDate"))}}>
                        <div>
                            <p>OtherDocUploadDate</p>
                        </div> 
                        {arrowDirection('OtherDocUploadDate')}
                    </div> 
                </div> 
        

      

                {isLoading ? loadingCard() : null} 
                

                {
                    hasData ?
                    currentData.map(item => <LoanApplicationDetailRow key={item.DANbr} {...item} /> )
                    : 
                    <h3 className="application_message-notApplications">No Applications submitted</h3>
                }


            
                <div style={{position:'absolute', bottom:'0', left: '0', right:'0'}}>
                    <Paginator
                    totalRecords={applicationData.length}
                    pageLimit={pageLimit}
                    pageNeighbours={20} 
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    // pageNextClass='bg_green'
                    // pagePrevClass='bg_green'
                />
                </div>
                
                


        </div>


        


       



       



    </div>
    </>
)
}

export default ApplicationDetailReportList;