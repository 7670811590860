import React, { useState, useEffect, useContext } from "react";
import "../assets/styles/ApplicationForm.css";
import "../assets/styles/StepCounter.css";
import Notification from './Notification';
import {AuthContext} from '../AuthContext';
import iconCheck from '../assets/images/iconCheck.png';
import iconCheckOrange from '../assets/images/iconCheckOrange.png';
import SpinnerPopUp from './SpinnerPopUp';
import Spinner from 'react-spinner-material';
import iconArrowBack from '../assets/images/iconArrowBack.png';
import iconSadFace from '../assets/images/iconSadFace.png';
import iconAdmiration from '../assets/images/iconAdmiration.png';
import useIsSafari from '../hooks/useIsSafari'
import NotificationPinnacleOff from './NotificationPinnacleOff'
import moment from 'moment-timezone';
import iconDelete from '../assets/images/iconDelete.png';
import ExternalForm from "./ExternalForm";
import ProposalForm from './proposals/ProposalForm'
import PinnacleContractStatusTab from "./applicationsPage/PinnacleContractStatusTab";
import PinnacleContract from './applicationsPage/PinnacleContract'
import SkeletonLoader from "tiny-skeleton-loader-react";
import AlertBox from "./AlertBox";
import useAlertBox from "../hooks/useAlertBox";


require('dotenv').config();

const ApplicationFormPinnacle =(props) =>{


  //ESTADOS
  
  const{uri, proposalInfo, setProposalInfo, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);

  const isSafari = useIsSafari()

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [errorIndex, setErrorIndex] = useState(false);

  const [step, setStep] = useState(props.setStep3 ? 3 : props.urlParamApplicantId ? 1 : props.errorStatus ? 1 : 4);

  //ESTADOS DE CREAR APPLICATION
  const [applicant, setApplicant] = useState(props.prospectData ? props.prospectData.Applicant : {});
  // const [coApplicant, setCoApplicant] = useState(props.prospectData ? props.prospectData.CoApplicant ? props.prospectData.CoApplicant : {} : {});
  const [coApplicant, setCoApplicant] = useState(props.prospectDataCoApplicant ? props.prospectData.CoApplicant : {});
  const [loan, setLoan] = useState({});
  const [statusMessages, SetStatusMessages] = useState([])
  const [applicationStatus, SetApplicationStatus] = useState('')
  const [hasMessages, SetHasMessages] = useState(false);
  const [hasStipulations, setHasStipulations] = useState(false);
  const [statusStipulations, setStatusStipulations] = useState([])
  const [hasCoApplicant, setHasCoapplicant] = useState(props.prospectData ? props.prospectData.CoApplicant ? true :false : false);
  const [hasCoApplicantValue, setHasCoapplicantValue] = useState({});
  const [applicationProduct, SetApplicationProduct] = useState([]);
  const [showNotificationPinnacleParametersNotSeat, setShowNotificationPinnacleParametersNotSeat] = useState(false);
  const [showNotificationUserAlredyExists, setShowNotificationUserAlredyExists] = useState(false)
  const [errorResponse, setErrorResponse] = useState({})
  const [termsCheck, setTermsCheck] = useState(false)
  const [termsCheckBorderRed, setTermsCheckBorderRed] = useState(false)
  const [allowEditSSN, setAllowEditSSN] = useState(props.prospectData && props.prospectData.Applicant && props.prospectData.Applicant.SocialSecurityNumber ? false : true)
  const [isLoadingGetStatus, setIsLoadingGetStatus] = useState(false)
  const [applicationStatusInfo, setApplicationStatusInfo] = useState({})

  //handler includetax
  const [includeTax, setIncludeTax] = useState(false);
  const [changeStateIncludeTax, setChangeStateIncludeTax] = useState(true);

  //handler creditType
  const [creditTypeClosedEnd, setCreditTypeClosedEnd] = useState(false);
  const [changeInputTax, setChangeInputTax] = useState(false);


  //ESTADOS DE PROMOTIONS
  const [hasPromotion, SetHasPromotion] = useState(false)
  const [availablePromotions, setAvailablePromotions] = useState([])
  const [promotions, SetPromotions] = useState({});
  const [isSeetingPromotion, SetIsSeetingPromotion] = useState(false);
  const [promotionSaved, SetPromotionSaved] = useState(false);
  const [noPromotion, setNoPromotion] = useState(false)
  const [reloadGetPromotions, SetReloadGetPromotions] = useState(false);

  const [editProposal, setEditProposal] = useState(false);
  const [createProposalAfterApplication, setCreateProposalAfterApplication] = useState(false)
 

  const [errors, setErrors] = useState({});
  const [errorsCoApplicant, setErrorsCoApplicant] = useState({});
  const [errorsLoan, setErrorsLoan] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingCoApplicant, setIsSubmittingCoApplicant] = useState(false);
  const [isSubmittingLoan, setIsSubmittingLoan] = useState(false);

  const [showNotificationOk, SetShowNotificationOk] = useState(false);
  const [showNotificationError, SetShowNotificationError] = useState(false)

  const [allApplicationData, setAllApplicationData] = useState({})

  //INCOMES
  const [incomeList, setIncomeList] = useState([]);
  const [errorIncomes, setErrorIncomes] = useState([{}])
  const [incomeListCoApplicant, setIncomeListCoApplicant] = useState([]);
  const [errorIncomesCoApplicant, setErrorIncomesCoApplicant] = useState([{}])
  const [isSubmitiongIncomeApplicant, setIsSubmitingIncomeApplicant] = useState(false);
  const [isSubmitiongIncomeCoApplicant, setIsSubmitingIncomeCoApplicant] = useState(false)
  const [incomesFilled, setIncomesFilled] = useState(false)


  //ESTADOS DE DOCUMENTS
  const [documentsList, SetDocumentsList] = useState([]);
  const [errorsdocument, setErrorsDocument] = useState({});
  const [isSubmittingDocument, setIsSubmittingDocument] = useState(false);
  const [documentForm, SetDocumentForm] = useState({});
  const [hasdocuments, SetHasDocuments] = useState(false);
  const [reloadGetDocuments, SetReloadGetDocuments] = useState(false);
  const [showPostNotificationSucces, SetShowPostNotificationSucces] = useState(false);
  const [showNotificationDocumentAlreadyExist, SetShowNotificationDocumentAlreadyExist] = useState(false);
  const [showNotificationTypeDocumentError, SetShowNotificationTypeDocumentError] = useState(false);
  const [showNotificationDocumentDeletedSuccessfully, SetShowNotificationDocumentDeletedSuccessfully] = useState(false);
  const [messageDocumentTypeError, setMessageDocumentTypeError] = useState()
  const [showNotificationDocumentUploadError, setShowNotificationDocumentUploadError] = useState(false)
  const [reloadGetApplicationDetail, SetReloadGetApplicationDetail] = useState(false);
  const [documentsAlertBox, setDocumentsAlertBox] = useState({
    title: '',
    text: '',
    type: ''
  } )

  const [documentsShowAlert, setDocumentsShowAlert] = useAlertBox({
    autoClose : true, 
    autoCloseTimeout : 5000, 
    initialShow: false
  })

  //ESTADOS DE CONTRACT
  const [contract, setContract] = useState({})
  const [contractErrors, setContractErrors] = useState({})
  const [isSubmittingContract, setIsSubmittingContract] = useState(false);
  const [editContract, setEditContract] = useState(false);
  const [contractSalesAmount, setContractSalesAmount] = useState();
  const [loadingPostContract, setLoadingPostContract] = useState(false);
  const [reloadGetContract, setReloadGetContract] = useState(false);
  const [showNotificationContractSendedOk, setShowNotificationContractSendedOk] = useState(false);
  const [contractCompletionStatus, setContractCompletionStatus] = useState(false)
  const [showNotificationPostContractError, setShowNotificationPostContractError] = useState(false);
  const [tierContract, setTierContract] = useState('')
  const [newAmountFinanced, setNewAmountFinanced] = useState(null)
  const [paymentFactor, setPaymentFactor] = useState(null)
  const [monthlyPayment, setMonthlyPayment] = useState(null)
  const [loadingCalculatedPaymentFactor, setLoadingCalculatedPaymentFactor] = useState(false)
  const [createCompleteManualApplications, setCreateCompleteManualApplications] = useState(false)
  const [loadingResendContract, setLoadingResendContract] = useState(false)
  const [resendContractSuccess, setResendContractSuccess] = useState(false)
  const [resendContractError, setResendContractError] = useState(false)
  const [incluideBankNumbers, setIncluideBankNumbers] = useState(true)
  const [bidPercentContract, setBidPercentContract] = useState('')
  const [contractStatusDate, setContractStatusDate] = useState({})
  const [contractApprovalType, setContractApprovalType] = useState(false)
  const [callContractTypeEndpoint, setCallContractTypeEndpoint] = useState(false)







  const [FIApplicationId, setFIApplicationId] = useState('')

  const [copyAddresDataFromApplicant, setCopyAddressDataFromApplicant] = useState(false)
  

  const [errorFetch, setErrorFetch] = useState(false)



  const token = window.localStorage.getItem('token');
  const user = window.localStorage.getItem('user');
  const URI_X_API_KEY_I = uri.URI_X_API_KEY;
  const refreshToken = window.localStorage.getItem('refreshToken');
  const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
  const dealerIdCode = window.localStorage.getItem('dealerId');
  const canCreateProposal = window.localStorage.getItem("allow_customer_proposals");
  const hideBuyRate = localStorage.getItem('hideBuyRate');
  const userRole = localStorage.getItem('userRole');











  //POST APPLICATION
  const handleSubmitAllData = async (event) => {
  
    setIsLoading(true);

    //RESIDENCE APPLICANT
    if(applicant.ResidenceType === 'OwnedFreeAndClear'){
      delete applicant.ResidenceMonthlyPayment;
    }

   


    //Armar el objeto que se envia
    const formData = {};

      formData.Applicant = applicant;
      formData.LoanParameters = loan;
      formData.FinantialInstitutionId = 'FI-0002';
      formData.AssignedUserEmailAddress = window.localStorage.getItem('userEmail');

    if(hasCoApplicant === true){
      formData.CoApplicant = coApplicant;
    }


    // if(props.hasOwnProperty('appData')){
    //   console.log(props.appData.ApplicationId )
    //   formData.ProspectLoanApplicationId = props.appData.ApplicationId || props.appData.ProspectLoanApplicationId
    // }
    
  
    //AÑADIR HOMEPHONE --------------------------------------------------------------------------
    formData.Applicant.HomePhone = applicant.CellPhone;


    //AÑADIR APPLICANTID
    if(props.hasOwnProperty('applicant_id')){
      formData.Applicant.ApplicantId = props.applicant_id
    }

    if(props.applicantIdFromPostCommon){
      formData.ProspectLoanApplicationId = props.applicantIdFromPostCommon
    }

    if(props.urlParamApplicantId){
      formData.Applicant.ApplicantId = props.urlParamApplicantId
    }


    if(proposalInfo.hasInfo){
      formData.CustomerProposalId = proposalInfo.info.customerProposalId
      formData.Applicant.ApplicantId = proposalInfo.info.ApplicantId
    }
    //-----------------------------------------------------------------------------------------
   




    //AÑADIR IncomeType Y PayFrequency
    formData.Applicant.EmploymentTermType = 'M';
    formData.Applicant.PayFrequency = 'M';
    if(hasCoApplicant === true){
      formData.CoApplicant.EmploymentTermType = 'M';
    }


    


    //PostalCodePlus4
    if(!applicant.PostalCodePlus4){
      formData.Applicant.PostalCodePlus4 = '0000';
    }else if(applicant.PostalCodePlus4 === ''){
      formData.Applicant.PostalCodePlus4 = '0000';
    }

    if(hasCoApplicant === true){
      if(!coApplicant.PostalCodePlus4){
        formData.CoApplicant.PostalCodePlus4 = '0000';
      }else if(coApplicant.PostalCodePlus4 === ''){
        formData.CoApplicant.PostalCodePlus4 = '0000';
      }
    }

    //AÑADIR GROSSMONTHLYINCOME
    if(!applicant.GrossMonthlyIncome){
      formData.Applicant.GrossMonthlyIncome = 0
    }
    if(hasCoApplicant === true){
      if(!coApplicant.GrossMonthlyIncome){
        formData.CoApplicant.GrossMonthlyIncome = 0
      }
    }

    //AÑADIR NET INCOME
    formData.Applicant.NetIncome = applicant.GrossMonthlyIncome || formData.Applicant.GrossMonthlyIncome;
    if(hasCoApplicant === true){
      formData.CoApplicant.NetIncome = coApplicant.GrossMonthlyIncome || formData.CoApplicant.GrossMonthlyIncome;
    }



    //BORRAR MIDLEINITIAL
    if(formData.Applicant.MiddleInitial === false || formData.Applicant.MiddleInitial === ''){
      delete formData.Applicant.MiddleInitial
    }
    if(hasCoApplicant === true){
      if(formData.CoApplicant.MiddleInitial === false || formData.CoApplicant.MiddleInitial === ''){
        delete formData.CoApplicant.MiddleInitial
      }
    }

    
    


    //Añadir otherincomes a Applicant
    if(incomeList.length > 0){
      const newList = incomeList.map(item => { if(item.Id && item.Id.includes('Income') ){delete item.Id}; item.Amount = parseFloat(item.Amount); return item})
      formData.Applicant.AdditionalIncomeSources = newList;
      //setApplicant(newIncomeList)
    }

    //añadir otherincomes a Coapplicant
    if(incomeListCoApplicant.length > 0){
      const newList = incomeListCoApplicant.map(item=> { if(item.Id && item.Id.includes('Income') ){delete item.Id}; item.Amount = parseFloat(item.Amount); return item}) 
      formData.CoApplicant.AdditionalIncomeSources = newList;
      //setCoApplicant(newIncomeListCoApplicant)
    }


    //CONVERT FLOAT LOANPARAMETERS
    formData.LoanParameters.SaleAmount = parseFloat(formData.LoanParameters.SaleAmount);
    formData.LoanParameters.DownPayment = parseFloat(formData.LoanParameters.DownPayment);
    formData.Applicant.GrossMonthlyIncome = parseFloat(formData.Applicant.GrossMonthlyIncome);
    formData.Applicant.NetIncome = parseFloat(formData.Applicant.NetIncome);
    if(hasCoApplicant === true){
      formData.CoApplicant.GrossMonthlyIncome = parseFloat(formData.CoApplicant.GrossMonthlyIncome);
      formData.CoApplicant.NetIncome = parseFloat(formData.CoApplicant.NetIncome);
    }




    //console.log(formData)


    let URI = '';
    if(props.updateId){
      URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}update/${props.updateId}`;
    }else{
      URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}`;
    }
   
      
    // console.log(URI)
   console.log(JSON.stringify(formData))

    try{
      const rawResponse = await window.fetch(URI, {
        method: 'POST',
        headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        },
        body: JSON.stringify(formData),
      
      });
      
      setIsLoading(false);
      const content = await rawResponse.json();
      
      console.log(content);    
      setProposalInfo({info:{}, hasInfo: false})

      if(content.Status === 'FilledOut' || content.Status === 'Approved' || content.Status === 'Pending'){    
        SetShowNotificationOk(true);
      }else if(content.Status === 'SUCCESS'){
        SetShowNotificationError(true);
      }else if(content.status === 'PINNACLE_DEALER_PARAMETERS_NOT_SET'){
        setShowNotificationPinnacleParametersNotSeat(true);
      }else if(content.status === 'APPLICANT_ALREADY_EXISTS'){
        setErrorResponse({Message : "Applicant already exists."})
        setShowNotificationUserAlredyExists(true);
      }else if(content.status === 'USER_NOT_FOUND' || 
        content.status === 'DEALER_NOT_FOUND' ||
        content.status === 'APPLICANT_NOT_FOUND' ||
        content.status === 'COAPPLICANT_NOT_FOUNDD' ||
        content.status === 'DEALER_EXTERNAL_ID_NOT_SET' ||
        content.status === 'FINANCIAL_INSTITUTION_NOT_FOUND' ||
        content.status === 'PINNACLE_DEALER_PARAMETERS_NOT_SET' ||
        content.status === 'FINANCIAL_INSTITUTION_NOT_FOUND' ||
        content.status === 'APPLICANT_IS_NULL' 
      ){
        setErrorResponse({Message : content.status, Errors: content.messages})
        SetShowNotificationError(true)
     
      }else{
        setErrorResponse(content)
        SetShowNotificationError(true);
        
      }


    }
    catch(error){
      console.log(error)
      setIsLoading(false);
      SetShowNotificationError(true);            
    }     
  
};

  



  // GET APPLICATIONDETAIL DETAIL
  useEffect(()=>{

    if(props.application_id){
      setIsLoadingDetails(true);
      

    const abortController = new AbortController();
    const signal = abortController.signal;

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/get-common-details`
   
    

    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,
                  
          }
    })
    .then(response => response.json())
    .then(datos => {
      setIsLoadingDetails(false);

        //console.log('DETALLES DE LA APLICACION');       
        console.log(datos)


        setAllApplicationData(datos)

       
        if(datos.Applicant){
          setApplicant(datos.Applicant);
        } 

        if(datos.CoApplicant){
          setHasCoapplicant(true);
          setCoApplicant(datos.CoApplicant);        
        } 

        
        if(datos.hasOwnProperty('LoanParameters')){
          if(datos.LoanParameters.SalesTaxIncluded != null && datos.LoanParameters.SalesTaxIncluded === 'true' ){
            setIncludeTax(true);
          }
        }
        

        //PROMOTIONS
        if(datos.hasOwnProperty('Promotion')){
          SetHasPromotion(true)
          SetPromotions(datos.Promotion)
        }else{
          SetHasPromotion(false)
        }

        //INCOMES APPLICANT
        if(datos.Applicant.AdditionalIncomeSources){
          //setIncomeList([])
          const errors = datos.Applicant.AdditionalIncomeSources
          const numErrors = errors.map(item => { return {}})
          setErrorIncomes(numErrors)
          setIncomeList(datos.Applicant.AdditionalIncomeSources)
          
        }

        //INCOMES COAPPLICANT
        if(datos.CoApplicant){
          if(datos.CoApplicant.AdditionalIncomeSources){

            const errors = datos.CoApplicant.AdditionalIncomeSources
            const numErrors = errors.map(item => { return {}})
            setErrorIncomesCoApplicant(numErrors)
            setIncomeListCoApplicant(datos.CoApplicant.AdditionalIncomeSources)
          } 
        }
        
        if(datos.LoanParameters){
          setLoan({...datos.LoanParameters,
                      SaleAmount: parseFloat(datos.LoanParameters.SaleAmount).toFixed(2),
                      DownPayment: parseFloat(datos.LoanParameters.DownPayment).toFixed(2),
                      AmountFinanced: parseFloat(datos.LoanParameters.AmountFinanced).toFixed(2),
          
          });
        } 

        
        //LLENAR FIApplicationId
        setFIApplicationId(datos.FIApplicationId)

        setBidPercentContract(datos.BidPercent)
        //RELLENAR DATOS DE CONTRACT
        if(!props.showErrorMessages){
          if(datos.ContractCompletionStatus === 'Success' || datos.ContractCompletionStatus === 'Empty' || datos.ContractCompletionStatus === 'Resent' || datos.ContractCompletionStatus === 'SignerComplete' ){
           
            fillContractData(datos)
          }
        }
       
        
       
       

        setErrorFlagFromContext(false)

        
        
    }).catch((error, response) => {
      console.log(error)
      if(errorFlagFromContext === false && error.status === 401){
          changeToken().then(response => console.log(response)).catch(error => console.log(error))
      }    
      });

    return function cleanup(){
      abortController.abort();
  }
    }   
},[reloadGetPromotions, reloadGetApplicationDetail, reloadGetContract, reloadFetchFromContext]);





 // RELLENAR APPLICANT EXISTENTE
 useEffect(()=>{

  if(props.urlParamApplicant_Id){
    setIsLoadingDetails(true);
    

  const abortController = new AbortController();
  const signal = abortController.signal;

  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GET_APPLICANT_DETAILS}${props.urlParamApplicant_Id}`
 
  

  window.fetch(URI,{
      method: 'GET',
      signal: signal,
      headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'username' : user,
          'Authorization' : token, 
          'FinancialInstitution' : 'FI-0002', 
          'x-api-key' : URI_X_API_KEY_I,
                
        }
  })
  .then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);

      //console.log('INFORMACION DEL APPLICANT');       
      console.log(datos)

      //CAMBIAR EL VALOR DE HASCOAPPLICANT
      datos.HasCoApplicant = false;
     
      // if(datos){
      //   setApplicant(datos);
      // } 

      if(datos.ResidenceType === 'Rent' || datos.ResidenceType === 'Other'){
        setApplicant({...datos, ResidenceType : null,})
      }else{
        setApplicant(datos);
      }

  
     
      

      setErrorIndex(false)
      
  }).catch(error => {
    console.log(error)

            if(errorIndex === false){
            //console.log('SE VENCIO EL PRIMER TOKEN')
          
            const data = {
                "username" : user,
                "refresh_token" : refreshToken,
                'x-api-key' : URI_X_API_KEY_I, 
            }

            const URI_REFRESHTOKEN = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}${uri.URI_NEW_TOKEN}`;
            window.fetch(URI_REFRESHTOKEN ,{
            method: 'POST',
            headers: {           
            'Content-Type': 'application/json',  
            'x-api-key' : URI_X_API_KEY_I,          
            },
            body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(datos => { 
                //console.log(datos)

                window.localStorage.removeItem('token');
                const newToken = datos.id_token;
                window.localStorage.setItem('token', newToken);
                console.log('SE REEMPLAZO EL TOKEN VIEJO POR EL NUEVO');
                SetReloadGetApplicationDetail(!reloadGetApplicationDetail)
                setErrorIndex(true)
            })
          }
});

  return function cleanup(){
    abortController.abort();
}
  }   
},[reloadGetPromotions, reloadGetApplicationDetail]);




//LLENAR LOS DATOS DE OTHER INCOMES DEL EXTERNAL FORM
useEffect(()=>{

  if(props.hasOwnProperty('appData')){

      //INCOMES
      if (props.appData.Applicant.AdditionalIncomeSources) {
          //setIncomeList([])
          const errors = props.appData.Applicant.AdditionalIncomeSources
          const numErrors = errors.map(item => { return {} })
          setErrorIncomes(numErrors)
          setIncomeList(props.appData.Applicant.AdditionalIncomeSources)

      }

      //INCOMES COAPPLICANT
      if (props.appData.CoApplicant) {
          if (props.appData.CoApplicant.AdditionalIncomeSources) {

              const errors = props.appData.CoApplicant.AdditionalIncomeSources
              const numErrors = errors.map(item => { return {} })
              setErrorIncomesCoApplicant(numErrors)
              setIncomeListCoApplicant(props.appData.CoApplicant.AdditionalIncomeSources)
          }
      }

      setIncomesFilled(true)

  }
}, [])



const getStatus = () => {
  if(props.application_id){
    setIsLoadingGetStatus(true)
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_GET_APPLICATION_STATUS}${props.application_id}`
    // console.log(URI)
  
    const abortController = new AbortController();
    const signal = abortController.signal;
    
  
    window.fetch(URI,{
        method: 'GET',
        signal: signal,
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,       
          }
    })
    .then(response => {

      if (response.ok) {
          return response.json();
        }
      return Promise.reject(response);
    })
    .then(datos => {
        setIsLoadingGetStatus(false)
        // console.log(datos)

        setApplicationStatusInfo(datos)
  
        if(datos.StatusMessages){
          SetHasMessages(true)
        }

        if(datos.Stipulations){
          setHasStipulations(true)
        }

        setStatusStipulations(datos.Stipulations)
        SetStatusMessages(datos.StatusMessages)
        SetApplicationStatus(datos.Status)  

        setErrorFlagFromContext(false)
       
       
    }).catch((error, response) => {
      setIsLoadingGetStatus(false)
      //console.log(error)
      if(errorFlagFromContext === false && error.status === 401){
          changeToken().then(response => console.log(response)).catch(error => console.log(error))
      }    
  });
  
    return function cleanup(){
      abortController.abort();
  }
    } 
}


//GET STATUS APPLICANT
useEffect(()=>{
  getStatus()  
},[reloadGetApplicationDetail]);



//VALIDAR GrossMonthlyIncome PARA CAMBIAR STEP
useEffect(()=>{
  if(props.validateGrossMonthlyIncome && incomesFilled){
    if (!applicant.GrossMonthlyIncome && incomeList.length === 0 ) {
      // let errorList = {};
      // errorList.GrossMonthlyIncome = "Please enter a value";
      setStep(1)
      setErrors({GrossMonthlyIncome : "Please enter a value. Enter 0 if no income"});
    }
  }
},[props.validateGrossMonthlyIncome, incomesFilled ])





    //LLENAR INFO DE PROPOSAL
    useEffect(()=>{
      if(proposalInfo.hasInfo){
          //console.log(proposalInfo.info)
          setLoan({
              ...loan,
              SaleAmount: parseFloat(proposalInfo.info.SalesTax) > 0 ? (parseFloat(proposalInfo.info.TotalInstalledProduct) + parseFloat(proposalInfo.info.SalesTax)) : parseFloat(proposalInfo.info.TotalInstalledProduct),
              DownPayment: proposalInfo.info.DownPayment,
          })

      }
    },[proposalInfo])




/*----------------------------------------- DOCUMENTS------------------------------------- */

// GET DOCUMENTS
useEffect(()=>{

  if(props.application_id){
    setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}`;


  const abortController = new AbortController();
  const signal = abortController.signal;
  

  window.fetch(URI,{
      method: 'GET',
      signal: signal,
      headers: {
          'Access-Control-Request-Headers': 'application/json',           
          'Content-Type': 'application/json', 
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  })
  .then(response => response.json())
  .then(datos => {
   setIsLoadingDetails(false);
      //console.log('DATOS DE DOCUMENTS')
      //console.log(datos)

      if(datos.length === 0){
        //console.log('ESTA APLICACION NO TIENE NINGUN DOCUMENTO GUARDADO');
        SetHasDocuments(false);
      }else{
        SetHasDocuments(true);
        SetDocumentsList(datos);
      }   
 
  })

  return function cleanup(){
    abortController.abort();
}
  }   
},[reloadGetDocuments, reloadGetApplicationDetail]);




//FUNCION PARA DESCARGAR ARCHIVOS
function showFile(blob, documentName){
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  //console.log(blob)
  var newBlob = new Blob([blob], {type: 'application/pdf'})
  

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  } 

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download= documentName;
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}


//DOWLOAD DOCUMENT
const dowloadDocument = (fileId, documentName) =>{

  
  //const uriEncode = encodeURIComponent(filename);
  //console.log(uriEncode)

  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}${fileId}`;
  //console.log(URI)
  window.fetch(URI,{
      method: 'GET',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  }).then(r =>  r.blob())
  //.then(showFile)
  .then(blob=>{
    //console.log(documentName)
    showFile(blob, documentName)
  })
  
  
  /*.then(response => response.json())
  .then(datos => {
      console.log('DATOS DOWLOAD DOCUMENT')
      console.log(datos)


})*/
}

//DELETE DOCUMENT
const deleteDocument = (fileId) =>{
  setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}${fileId}`;
  
  window.fetch(URI,{
      method: 'DELETE',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        }
  }).then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);
      //console.log('DATOS DOWLOAD DOCUMENT')
      //console.log(datos)
      if(datos.status === 'SUCCESS'){
        SetReloadGetDocuments(!reloadGetDocuments)
        SetShowNotificationDocumentDeletedSuccessfully(true);
      }

})
}

//POST DOCUMENT
const sendDocument = () =>{
  setIsLoadingDetails(true);
  const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${props.application_id}/${uri.URI_DOCUMENTS}`;

  let data = new FormData()
  data.append('document-type', documentForm.DocumentFileType);
  data.append('file', documentForm.File);

  //console.log(data)
 
  window.fetch(URI,{
      method: 'PUT',
      headers: {
          'Authorization' : token,  
          'x-api-key' : URI_X_API_KEY_I,       
        },
        body: data
  }).then(response => response.json())
  .then(datos => {
    setIsLoadingDetails(false);
      //console.log('DATOS POST DOCUMENT')
      //console.log(datos)
      if(datos.status === 'SUCCESS'){
        SetReloadGetDocuments(!reloadGetDocuments)
        SetDocumentForm({});
        // SetShowPostNotificationSucces(true);
        delete documentForm.DocumentFileType;
        delete documentForm.File;

        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'The document was successfully uploaded',
          type: 'success'
        })

      }else if(datos.status === 'DOCUMENT_TYPE_NOT_ACCEPTED'){
        // setShowNotificationDocumentUploadError(true)
        // setMessageDocumentTypeError({message: datos.messages[0], title: "Type of document not accepted"})

        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'Type of document not accepted',
          text: datos.messages[0],
          type: 'error'
        })

      }else if(datos.status === 'FILESIZE_TOO_BIG'){
        // setShowNotificationDocumentUploadError(true)
        // setMessageDocumentTypeError({message: datos.messages[0], title: "File size is too big"})
        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'File size is too big',
          text: datos.messages[0],
          type: 'error'
        })

      }else if(datos.status === 'FAILED_UPLOADING_DOCUMENT_TO_LENDER'){
        // setShowNotificationDocumentUploadError(true)
        // setMessageDocumentTypeError({message: datos.messages, title: "Failed uploading document to lender"})

        setDocumentsShowAlert(true)
        setDocumentsAlertBox({
          title: 'Failed uploading document to Aqua',
          text: 'Please email file to info@dealerallyprogram.com with the subject “File failed to upload to Aqua” and the loan number.',
          type: 'error'
        })
      }

})
}

useEffect(() => {
  if (Object.keys(errorsdocument).length === 0 && isSubmittingDocument) {
    sendDocument();
  } else {
    //console.log("hay un problema o los campos estan vacios");
  }
}, [errorsdocument]);




/*----------------------------------------- FORMULARIOS Y VALIDACION DE DOCUMENTS ------------------------------------- */
  //MANEJADORES DE FORM DE DOCUMENTS
  const handleDocumentForm = event => {
    let value;
    let fileType;

    if(event.target.name === 'File'){
      value = event.target.files[0];
      fileType = value.type;
      if(fileType.includes('image/') || fileType.includes('application/pdf')  || fileType.includes('audio/')){
          if(fileType.includes('application/pdf')){
            if(value.size > 8388608){
              SetShowNotificationTypeDocumentError(true)
              setMessageDocumentTypeError('Info: You can only upload files up to 8MB')
              value = null
            }        
          }else if(fileType.includes('image/') ){
            if(value.size > 8388608 ){
              SetShowNotificationTypeDocumentError(true)
              setMessageDocumentTypeError('Info: You can only upload files up to 8MB')
              value = null
            }  
            
          }
        }else{
            SetShowNotificationTypeDocumentError(true)
            setMessageDocumentTypeError('Info: You can only upload PDF, Audio and Image files')
            value = null
        }
      
    }else if(event.target.name === 'DocumentFileType'){
      value = event.target.value;
    }
  
    SetDocumentForm({
      ...documentForm,
      [event.target.name]: value,       
  })
  }

  //submit documents
  const handleSubmitDocument = event => {
    event.preventDefault();
    //handle errors
    setErrorsDocument(validateFormDocument(documentForm));
    setIsSubmittingDocument(true);
    //console.log(documentForm)
    
  };

  //validar el formulario de documentos
  const validateFormDocument = (data) => {
    let errorListDocument = {};

    //dealerid - check
    if (!data.DocumentFileType) {
      errorListDocument.DocumentFileType = "Please select a option";
    }

    if (!data.File) {
      errorListDocument.File = "Please select a file";
    }
    return errorListDocument;
    };
  /*--------------------------------------------------------------------------------------------------------------------- */  













  /*----------------------------------------- CONTRACTS ------------------------------------- */

      //RELLENAR FORMULARIO DE CONTRACT
      const fillContractData =(data)=>{
         //console.log(data)
       
        

        if((data.ContractCompletionStatus !== 'Success' && data.ContractCompletionStatus !== 'Resent' && data.ContractCompletionStatus !== 'SignerComplete' )  && (props.application_status === 'Approved' ||  props.application_status === 'SignReady')){
          setEditContract(true)
        }else if((data.ContractCompletionStatus === 'Success' || data.ContractCompletionStatus === 'Resent' || data.ContractCompletionStatus === 'SignerComplete') && (props.application_status === 'Approved' ||  props.application_status === 'SignReady' )){
          setEditContract(false)
          setContractCompletionStatus(true)
        }

       
        setBidPercentContract(data.BidPercent)
        setTierContract(data.Tier)

        setContractApprovalType(data.ContractApprovalType)

        if(data.ContractApprovalType &&  data.ContractApprovalType === 'Manual'){
          setCreateCompleteManualApplications(true)
        }


      

        //CONSTRACT STATUS DATE TABLITA
        setContractStatusDate({
          ContractCompletionSentDate : data.ContractCompletionSentDate,
          ContractCompletionSignedDate : data.ContractCompletionSignedDate,
        })

        let f =new Date();
        let d = new Date();
        d.setDate(d.getDate()+30);
        //console.log(str1.padStart(2, '0'));

        const contractData = {
          ApplicantFirstName : data.Applicant.FirstName || '',
          ApplicantLastName : data.Applicant.LastName || '',
          ApplicantEmail: data.Applicant.EmailAddress || '',
          ApplicantAddress : data.Applicant.Address1 || '',
          ApplicantCity : data.Applicant.City || '',
          ApplicantState : data.Applicant.State || '',
          ApplicantZipCode : data.Applicant.ZipCode ||  data.Applicant.PostalCode || '',
          ApplicantPhone : data.Applicant.CellPhone || '',

          ManufacturerGoodsServiceSold: data.LoanParameters.ManufacturerGoodsServiceSold || '',
          ProductOrService : data.LoanParameters.ProductOrService || 'Security System',
          SerialNumberGoodsServiceSold: data.LoanParameters.SerialNumberGoodsServiceSold || '',
          

          LoanProgram: data.LoanParameters.LoanProgram,

         
              
          LoanDate : data.LoanParameters.RemittanceUpdateFlag ? (data.LoanParameters.LoanDate || null) : (data.LoanParameters.LoanDate || `${f.getFullYear()}-${(f.getMonth()+1).toString().padStart(2, '0')}-${f.getDate().toString().padStart(2, '0')}`),  
          FirstPaymentDate: data.LoanParameters.RemittanceUpdateFlag ? null : (data.LoanParameters.FirstPaymentDate || `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`),
          RepaymentTerms: data.LoanParameters.RepaymentTerms || null, 
          APR: data.LoanParameters.APR ? data.LoanParameters.APR
               : data.Tier === 'PLATINUM' ? '9.99'
               : data.Tier === 'GOLD' ? '11.99'
               : data.Tier === 'SILVER' ? '13.99'
               : null,
                        

          
          //FirstPaymentDate: data.LoanParameters.FirstPaymentDate || null,

          //LoanDate: data.LoanParameters.LoanDate || data.LoanParameters.CompletionDate || `${f.getFullYear()}-${(f.getMonth()+1).toString().padStart(2, '0')}-${f.getDate().toString().padStart(2, '0')}`,
         


          CompletionDate:data.LoanParameters.RemittanceUpdateFlag ? null : (data.LoanParameters.CompletionDate || null),

          BankAccountType : data.LoanParameters.BankAccountType || null,
          BankRoutingNumber : data.LoanParameters.BankRoutingNumber ,
          BankAccountNumber: data.LoanParameters.BankAccountNumber ,

         
          
        }

        if(data.LoanParameters.SaleAmount){
          contractData.SaleAmount = data.LoanParameters.RemittanceUpdateFlag ? null : parseFloat(data.LoanParameters.SaleAmount).toFixed(2);
          contractData.DownPayment = data.LoanParameters.RemittanceUpdateFlag ? null : parseFloat(data.LoanParameters.DownPayment).toFixed(2);
        }

        if(data.hasOwnProperty('CoApplicant')){
          contractData.CoApplicantFirstName = data.CoApplicant.FirstName || ''
          contractData.CoApplicantLastName = data.CoApplicant.LastName || ''
          contractData.CoApplicantEmail = data.CoApplicant.EmailAddress || ''
          contractData.CoApplicantPhone = data.CoApplicant.CellPhone || ''
        }

       

       

        setContractSalesAmount(parseInt(data.LoanParameters.SaleAmount))

        setContract({...contract, ...contractData})

        setCallContractTypeEndpoint(true)
      } //end




      //SUBMIT CONTRACT
      const postContract = (event) => {

        setLoadingPostContract(true);
    

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_CONTRACT_COMPLETION}${props.application_id}`
        //console.log(URI)  
        
        
        const contractSubmit = {
          LoanProgram: contract.LoanProgram || '365 Days SAC',
          CompletionDate : contract.CompletionDate,
          // BankAccountType : contract.BankAccountType,
          // BankRoutingNumber : contract.BankRoutingNumber,
          // BankAccountNumber : contract.BankAccountNumber,
          ManufacturerGoodsServiceSold : contract.ManufacturerGoodsServiceSold,
          ProductOrService : contract.ProductOrService,
          SerialNumberGoodsServiceSold : contract.SerialNumberGoodsServiceSold,
          SaleAmount : contract.SaleAmount,
          DownPayment : contract.DownPayment,
          LoanApplicationDate: contract.LoanDate,
          APR : contract.APR,
          RepaymentTerms : contract.RepaymentTerms,
          ApplicantEmail: contract.ApplicantEmail,
          CoApplicantEmail: contract.CoApplicantEmail,
          ApplicantPhone: contract.ApplicantPhone,
          CoApplicantPhone: contract.CoApplicantPhone,
          ApplicantFirstName: contract.ApplicantFirstName,
          ApplicantLastName: contract.ApplicantLastName
        }

        if(incluideBankNumbers){
          contractSubmit.BankAccountType = contract.BankAccountType
          contractSubmit.BankRoutingNumber = contract.BankRoutingNumber
          contractSubmit.BankAccountNumber = contract.BankAccountNumber
        }
        
        if(createCompleteManualApplications){
          contractSubmit.ContractApprovalType = 'Manual'
        }else{
          contractSubmit.ContractApprovalType = 'Blanket'
        }
        

        // console.log(contractSubmit)
        console.log(JSON.stringify(contractSubmit))
        
        
            window.fetch(URI, {
            method: 'POST',
            headers: {
              //'Access-Control-Request-Headers': 'application/json',           
              'Content-Type': 'application/json', 
              'username' : user,
              'Authorization' : token,
              'x-api-key' : URI_X_API_KEY_I,
            },
            body: JSON.stringify(contractSubmit),
          
            }).then(response => {
              
              if (response.status === 200) {
                  return response.json();
                }
              return Promise.reject(response);
              
            })
            .then(datos => {
              setLoadingPostContract(false)
              //console.log('OBJETO QUE RESPONDE EL SERVIDOR')
              console.log(datos);

              if(datos.status === 'SUCCESS'){
                setShowNotificationContractSendedOk({show: true, text: 'Contract completion form submitted, this could take a few minutes.', type: 'ok'}) 
              }else{
                //console.log('ERROR')
                setShowNotificationPostContractError({error: true, text: 'An error has ocurred, please contact the admin'})
              }
              
            
            }).catch((error)=>{
              console.log(error)
              if(error.status === 504){
                setLoadingPostContract(false)
                setShowNotificationContractSendedOk({show: true, text: 'Contract Completion is taking longer than expected, it will complete in a few minutes.', type: 'timeOut'}) 
              }else{
                setLoadingPostContract(false)
                setShowNotificationPostContractError({error: true, text: 'An error has ocurred, please contact the admin'})
              }
             
            })      
            
            
            
              // const response = await fetch('https://pokeapi.co/api/v2/pokemondfhfjfds', {
              //   method: 'GET',
              //   headers: {
              //     //'Access-Control-Request-Headers': 'application/json',           
              //     'Content-Type': 'application/json', 
              //     // 'username' : user,
              //     // 'Authorization' : token,
              //     // 'x-api-key' : URI_X_API_KEY_I,
              //   },
              //   // body: JSON.stringify(contractSubmit),
              
              //   })
               
              //   console.log(response.status)
              //   console.log(errorResponse)
            
            
          
      }; //end

      
  

      //HANDLE INPUT CONTRACT
      const handleInputContract = event => {
        let value = event.target.value;

  
        if(event.target.name === 'LoanDate' ){
          if(contract.LoanProgram !== '90 Days NP' && contract.LoanProgram !== '90 Days NPNI'){
            let d = new Date(value)
            d.setDate(d.getDate()+30);
            //console.log(d)
      
            setContract({
              ...contract,
              [event.target.name]: value,
              FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
            });
          }else{
            let d = new Date(value)
            d.setDate(d.getDate()+90);
            //console.log(d)

       
            setContract({
              ...contract,
              [event.target.name]: value,
              FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
            });
          }
          
        }else if(event.target.name === 'LoanProgram'){
          if(value === '90 Days NP' || value === '90 Days NPNI'){

            let d = new Date(contract.LoanDate)
            d.setDate(d.getDate()+90);

          
            setContract({
              ...contract,
              [event.target.name]: value,
              FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
            });
          }else{

            let d = new Date(contract.LoanDate)
            d.setDate(d.getDate()+30);

           
            setContract({
              ...contract,
              [event.target.name]: value,
              FirstPaymentDate: `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`,
            });

          }

        }else{
          setContract({
            ...contract,
            [event.target.name]: value,
          });
        }

        
       
        
        
      }; //end




      //HANDLE SUBMIT CONSTRACT
      const handleSubmitContract = event => {
        event.preventDefault();
        //handle errors
        console.log(contract)
        setContractErrors(validateFormContract(contract));
        setIsSubmittingContract(true);   
       
        
      }; //end



      //POST CONTRACT
      useEffect(() => {
        if (Object.keys(contractErrors).length === 0 && isSubmittingContract) {     
          postContract()
        
        } 
      }, [contractErrors, isSubmittingContract]);






      //CONSULTAR QUE TIPO DE CONTRACT ES MANUAL O BLANCKET SI EL CONTRATO NO HA SIDO ENVIADO AUN
      useEffect(() => {

        

        if(step === 5 && !contractApprovalType && callContractTypeEndpoint){

        
          

          const abortController = new AbortController();
          const signal = abortController.signal;
      
          const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_DEALER}${uri.URI_GET_FINANCIAL_INSTITUTIONS}`
          // console.log(URI)
          
  
          window.fetch(URI,{
              method: 'GET',
              signal: signal,
              headers: {
                  'Access-Control-Request-Headers': 'application/json',           
                  'Content-Type': 'application/json', 
                  'DealerId' : dealerIdCode,
                  'Authorization' : token,  
                  'x-api-key' : uri.URI_X_API_KEY,
                          
                  }
          })
          .then(response => {

            if (response.ok) {
                return response.json();
              }
            return Promise.reject(response);
        })
          .then(datos => {
              //setLoadingGetFinancials(false)
      
            //console.log(datos)
            
              datos.map(item => {
                if(item.Id === 'FI-0002'){
                  if(item.CanDealerCreateCompleteManualApplications || newAmountFinanced > 5000){
                    setCreateCompleteManualApplications(true)  
                    setContract({...contract, RepaymentTerms: '96'})
                    calculatePaymentFactor('96', contract.APR)
                  }else{
                    if(!contractCompletionStatus){
                      setContract({...contract, APR : false})
                    }
                  }
                } 
              })
            

              
          }).catch((error, response) => {
            console.log(error)
            if(errorFlagFromContext === false && error.status === 401){
                changeToken().then(response => console.log(response)).catch(error => console.log(error))
            }    
        });
      
          return function cleanup(){
              abortController.abort();
          }
          } 
        
      }, [step, reloadFetchFromContext, callContractTypeEndpoint])




      //validate contract
      const validateFormContract = (applicant) => {
        let errorList = {};

          //FirstName - text
          if (!applicant.ApplicantFirstName) {
            errorList.ApplicantFirstName = "Please enter a name";
          } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.ApplicantFirstName)) {
            errorList.ApplicantFirstName = "The name can only contain letters";
          }




          //LastName - text
          if (!applicant.ApplicantLastName) {
            errorList.ApplicantLastName = "Please enter a lastname";
          } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.ApplicantLastName)) {
            errorList.ApplicantLastName = "The lastname can only contain letters";
          }
    
        
        
          //ManufacturerGoodsServiceSold
          if (!applicant.ManufacturerGoodsServiceSold) {
            errorList.ManufacturerGoodsServiceSold = "Please enter a Manufacturer Goods Service Sold";
          }else if (!/^[a-zA-Z0-9_\-\s\.]{0,200}$/.test(applicant.ManufacturerGoodsServiceSold)) {
            errorList.ManufacturerGoodsServiceSold = "The Manufacturer Goods Service Sold can only contain 200 characters";
          }


          //ProductOrService
          if (!applicant.ProductOrService) {
            errorList.ProductOrService = "Please enter a Description Goods Service Sold";
          }else if (!/^[a-zA-Z0-9_\-\s\.]{0,500}$/.test(applicant.ProductOrService)) {
            errorList.ProductOrService = "The Product Or Service can only contain 500 characters";
          }


          //SerialNumberGoodsServiceSold
          if (!applicant.SerialNumberGoodsServiceSold) {
            errorList.SerialNumberGoodsServiceSold = "Please enter a Serial Number Goods Service Sold";
          }else if (!/^[a-zA-Z0-9_\-\.]{0,50}$/.test(applicant.SerialNumberGoodsServiceSold)) {
            errorList.SerialNumberGoodsServiceSold = "The Serial Number Goods Service Sold can only contain 50 characters";
          }
          


          //SaleAmount *
          if (!applicant.SaleAmount ) {
            errorList.SaleAmount = "Please enter a value";
          } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.SaleAmount)) {
            errorList.SaleAmount = "The value can only contain numbers";
          } 
          // else if(applicant.SaleAmount > contractSalesAmount){
          //   errorList.SaleAmount = "Should be less or equeal to the previous value";
          // }


          //DownPayment *
          if (applicant.DownPayment === null || applicant.DownPayment === '') {
            errorList.DownPayment = "Please enter a value";
          } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.DownPayment)) {
            errorList.DownPayment = "The value can only contain numbers";
          } else if(parseInt(applicant.DownPayment) > parseInt(applicant.SaleAmount)){
            errorList.DownPayment = "Should be less than Sale Amount";
          }


          // RepaymentTerms - LoanTerm
          if (!applicant.RepaymentTerms) {
            errorList.RepaymentTerms = "Please select a Loan Term";
          }


          //APR
          if (!applicant.APR) {
            errorList.APR = "Please select a APR";
          }



          //LoanDate
          if (!applicant.LoanDate) {
            errorList.LoanDate = "Please enter a Loan Date";
          } else if(!applicant.LoanDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
            if(isSafari){
              errorList.LoanDate = "The format must be yyyy-mm-dd";
            }else{
              errorList.LoanDate = "The format must be dd/mm/yyyy";
            }     
          }



          //FirstPaymentDate
          if (!applicant.FirstPaymentDate) {
            errorList.FirstPaymentDate = "Please enter a First Payment Date";
          }
  
  
        //CompletionDate
          if (!applicant.CompletionDate) {
            errorList.CompletionDate = "Please enter a date";
          } else if(!applicant.CompletionDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
              if(isSafari){
                errorList.CompletionDate = "The format must be yyyy-mm-dd";
              }else{
                errorList.CompletionDate = "The format must be dd/mm/yyyy";
              }     
          }else if(applicant.CompletionDate){
              let dob2 = new Date (applicant.CompletionDate);
              const loanDate = new Date(applicant.LoanDate);
              if(dob2 < loanDate){
                errorList.CompletionDate = "The date must be after the Loan Date";
              }

  
              const localTime = new Date().getTime()
              const completionDate = moment.tz(applicant.CompletionDate + " 00:00", "America/Chicago") 
              const today00 = moment(localTime).tz('America/Chicago').set({hour:0,minute:0,second:0,millisecond:0})
      

              if(completionDate.isBefore(today00)){
                errorList.CompletionDate = "The date must be today or in the future";
              }
          }
          

          //LoanProgram
          if(createCompleteManualApplications){
            if (!applicant.LoanProgram) {
              errorList.LoanProgram = "Please select an option";
            }
          }
          



          //BankAccountType
          if(incluideBankNumbers || !createCompleteManualApplications){
            if (!applicant.BankAccountType) {
              errorList.BankAccountType = "Please select an option";
            }
          }
          


           //BankRoutingNumber *
           if(incluideBankNumbers || !createCompleteManualApplications){
            if (!applicant.BankRoutingNumber ) {
              errorList.BankRoutingNumber = "Please enter a value";
            } else if (!/^[0-9]{9}$/.test(applicant.BankRoutingNumber)) {
              errorList.BankRoutingNumber = "The Bank Routing Number must have 9 numbers";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankRoutingNumber)) {
              errorList.BankRoutingNumber = "The value can only contain numbers";
            }
           }
           
          



           //BankAccountNumber *
           if(incluideBankNumbers || !createCompleteManualApplications){
            if (!applicant.BankAccountNumber ) {
              errorList.BankAccountNumber = "Please enter a value";
            } 
           }
           
          // else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankAccountNumber)) {
          //   errorList.BankAccountNumber = "The value can only contain numbers";
          // }


          //EmailAddress *
          if(!applicant.ApplicantEmail){
            errorList.ApplicantEmail = "Please enter a email";
          }else if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.ApplicantEmail)){
              errorList.ApplicantEmail = "Please enter a valid email";
          }


          //PHONE
          if (!applicant.ApplicantPhone) {
            errorList.ApplicantPhone = "Please enter a phone";
          } else if (!/^[0-9]{10}$/.test(applicant.ApplicantPhone)) {
              errorList.ApplicantPhone = "The phone must have 10 numbers";
          } else if (applicant.ApplicantPhone === '0000000000') {
              errorList.ApplicantPhone = "Please enter a valid phone number";
          }


          //COAPPLICANT PHONE
          if(contract.hasOwnProperty('CoApplicantFirstName')){
            if (!applicant.CoApplicantPhone) {
              errorList.CoApplicantPhone = "Please enter a phone";
            } else if (!/^[0-9]{10}$/.test(applicant.CoApplicantPhone)) {
                errorList.CoApplicantPhone = "The phone must have 10 numbers";
            } else if (applicant.CoApplicantPhone === '0000000000') {
                errorList.CoApplicantPhone = "Please enter a valid phone number";
            }
          }
          

          



          //CoApplicantEmail *
          if(contract.hasOwnProperty('CoApplicantFirstName')){
            if(!applicant.CoApplicantEmail){
              errorList.CoApplicantEmail = "Please enter a email";
            }else if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.CoApplicantEmail)){
                errorList.CoApplicantEmail = "Please enter a valid email";
            }else if(applicant.CoApplicantEmail === applicant.ApplicantEmail){
                errorList.CoApplicantEmail = "The email must be different from the applicant's";
            }
          }

          
          
          
         
  
      
  
  

  
    
    
        return errorList;
      };


      
      





      //CAMBIAR EL VALOR DE MONTHLY PAYMENT
      useEffect(() => {


        if(contract.hasOwnProperty('SaleAmount') && paymentFactor){
          let value = (parseInt(contract.SaleAmount)) - (parseInt(contract.DownPayment)) ;
          calculateMonthlyPayment(paymentFactor, value)
         
        }else{
            setMonthlyPayment(0)
          }
                
      }, [contract.SaleAmount, contract.DownPayment])




      const calculatePaymentFactor = (terms, apr) => {

        const repaymentTerms = terms ? terms : contract.RepaymentTerms
        const aprValue = apr ? apr : contract.APR

     

        if(repaymentTerms && aprValue ){

         

          setLoadingCalculatedPaymentFactor(true)

          const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_CONTRACT_COMPLETION_RATE}`
          //console.log(URI)  
          
          
          const data = {
            APR : contract.APR,
            RepaymentTerms : repaymentTerms,
            Tier: tierContract,
  
          }
  
  
          //console.log(data)
          //console.log(JSON.stringify(data))
          
          
              window.fetch(URI, {
              method: 'POST',
              headers: {
                //'Access-Control-Request-Headers': 'application/json',           
                'Content-Type': 'application/json', 
                'username' : user,
                'Authorization' : token,
                'x-api-key' : URI_X_API_KEY_I,
              },
              body: JSON.stringify(data),
            
              }).then(response => response.json())
              .then(datos => {
                setLoadingCalculatedPaymentFactor(false)
                //console.log('RESPUESTA PAYMENT FACTOR')
                //console.log(datos);
  
                if(datos.hasOwnProperty('PaymentFactor')){
                  setPaymentFactor(datos.PaymentFactor)
                  let amountFinan = (parseInt(contract.SaleAmount)) - (parseInt(contract.DownPayment)) ;
                  
                  
                  calculateMonthlyPayment(datos.PaymentFactor, amountFinan)
                }
               
              
              }).catch((error)=>{
                //console.log(error)
              })       
          

        }
      }






      //ENDPOINT CALCULAR PAYMENT FACTOR
      useEffect(() => {
      
        calculatePaymentFactor()
        
      }, [contract.RepaymentTerms, contract.APR])
      





       //CALCULAR AMOUNT FINANCED
      useEffect(() => {

       
       


          if(contract.SaleAmount && contract.DownPayment){

            let value = ((parseFloat(contract.SaleAmount)) - (parseFloat(contract.DownPayment))).toFixed(2)
            
            if(value >= 0){  
              setNewAmountFinanced(value)
            }else{
              setNewAmountFinanced(0)
            }
            
          }else{
            setNewAmountFinanced(parseFloat(loan.AmountFinanced).toFixed(2))
          }
      
      
      
    }, [contract.SaleAmount, contract.DownPayment, loan])

    






      //CALCULAR MONTHLY PAYMENT
      const calculateMonthlyPayment = (paymentFactorNew, amountF) =>{
       
        let monthlyPaymentNew = (parseFloat(amountF) * parseFloat(paymentFactorNew)).toFixed(2)

        setMonthlyPayment(monthlyPaymentNew);
        
      }












      //RESEND CONTRACT
      const resendContract = () => {

        setLoadingResendContract(true)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${uri.URI_CONTRACT_COMPLETION}${props.application_id}/resend`
      
            window.fetch(URI, {
            method: 'GET',
            headers: {
              //'Access-Control-Request-Headers': 'application/json',           
              'Content-Type': 'application/json', 
              'username' : user,
              'Authorization' : token,
              'x-api-key' : URI_X_API_KEY_I,
            },
           
          
            }).then(response => response.json())
            .then(datos => {
              setLoadingResendContract(false)
             
              // console.log(datos);

              if(datos.status === 'SUCCESS'){
                setResendContractError(false)
                setResendContractSuccess(true)
              }else{
                setResendContractSuccess(false)
                setResendContractError(true)
              }

             
              
            
            }).catch((error)=>{
              console.log(error)
              setLoadingResendContract(false)
            }) 

      }


















  /*----------------------------------------- HANDLE INPUTS APPLICANT COAPPLICANT LOAN------------------------------------- */

  //HANDLE INPUTS
    //inputs applicant
    const handleInputApplicant = event => {
      let value = event.target.value;



        if (event.target.name === "AdditionalIncome") {
          let income = ''
          if(applicant.hasOwnProperty('AdditionalIncomeSources')){
            income = applicant.AdditionalIncomeSources[0];
            income.Amount = parseFloat(value);
          }else{
            income = {Amount: parseFloat(value)}
          }
           

            setApplicant({
              ...applicant,
              AdditionalIncomeSources: [income],          
            })
        }else{

          setApplicant({
            ...applicant,
            [event.target.name]: value,          
        })

        }

       
    }

    //inputs coapplicant
    const handleInputCoApplicant = event => {
    let value = event.target.value;

        // if (event.target.name === "EmploymentType") {
        //   if(value === "Unemployed" || value === "Retired"){
            
        //     delete coApplicant.CurrentEmployer;
        //     delete coApplicant.CurrentEmployerPhoneNumber;
        //     delete coApplicant.EmploymentMonth;
        //     delete coApplicant.EmploymentYear;
        //     delete coApplicant.Occupation;
        //     delete coApplicant.EmploymentStartDate;
        //   }else if(value === 'SelfEmployed'){
        //     delete coApplicant.CurrentEmployer;
        //   }
        //   value = value

        // }

        

      setCoApplicant({
          ...coApplicant,
          [event.target.name]: value,            
      })
    }

    //inputs loan
    const handleInputLoan = event => {
      let value = event.target.value;

      if (event.target.name === "SalesTaxIncluded") {
        if(value === "true"){
          setIncludeTax(true);
         
          
          
          //setChangeStateIncludeTax(!changeStateIncludeTax);
          setChangeInputTax(!changeInputTax);
          value = value
        }else if(value === "false"){
          
          setIncludeTax(false);
          //setChangeStateIncludeTax(!changeStateIncludeTax);
          setChangeInputTax(!changeInputTax)
          value = value

        }
      }else if(event.target.name === "SellingPrice") {
        setChangeInputTax(!changeInputTax)
        value = value
      }else if(event.target.name === "DownPayment") {
        setChangeInputTax(!changeInputTax)
        value = value
      }else if(event.target.name === "SalesTax") {
        setChangeInputTax(!changeInputTax)
        value = value
      }

    setLoan({
        ...loan,
        [event.target.name]: value,            
    })

 
    }

    //input select coapplicant
    const handleInputHasCoApplicant = event => {

      if(event.target.value === "true"){
        setHasCoapplicant(true)
      }else if(event.target.value === "false"){
        setHasCoapplicant(false)
      }

      setHasCoapplicantValue({
        ...hasCoApplicantValue,
        [event.target.name]: event.target.value         
    })

    }


    //hascoapplicant value
    useEffect(() => {
      if(hasCoApplicant === true){
        setApplicant({
          ...applicant,
          HasCoApplicant: true,            
      })

      }else if(hasCoApplicant === false){
        setApplicant({
          ...applicant,
          HasCoApplicant: false,            
      })
      }
      }, [hasCoApplicant]);



    //set CoApplicantType
    const setCoApplicantType =()=>{
      setCoApplicant({
        ...coApplicant,
        CoApplicantType: coApplicant.ApplicantRelationship,            
      })

      
    }

  
     /*--------------------------------------------------------------------------------------------------------------------- */ 

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 

    

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    /*----------------------------------------- VALIDAR FORMULARIOS ------------------------------------- */

    //validate applicand and coapplicant
    const validateForm = (data, isCoApplicant) => {
      let errorList = {};
  
      
     


      //FirstName - text *
      if (!data.FirstName) {
        errorList.FirstName = "Please enter a name";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(data.FirstName)) {
        errorList.FirstName = "The name can only contain letters";
      }

      //MiddleInitial
      if (data.MiddleInitial) {
        if(!/^[a-zA-Z]{1}$/.test(data.MiddleInitial)) {
          errorList.MiddleInitial = "Can only contain one letter";
        }
      }

 
  
      //LastName - text *
      if (!data.LastName) {
        errorList.LastName = "Please enter a lastname";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(data.LastName)) {
        errorList.LastName = "The lastname can only contain letters";
      }
  
      //Address1 - text *
      if (!data.Address1) {
        errorList.Address1 = "Please enter a address";
      }
  
      //City - text *
      if (!data.City) {
        errorList.City = "Please enter a city";
      } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s\.]+$/.test(data.City)) {
        errorList.City = "The city can only contain letters";
      }
  
      //State - select *
      if (!data.State) {
        errorList.State = "Please select an option";
      } else if (data.State === "State") {
        errorList.State = "Please select an option";
      }
  
      //PostalCode - number *
      if (!data.PostalCode) {
        errorList.PostalCode = "Please enter a postalCode";
      } else if (!/^[0-9]{5}$/.test(data.PostalCode)) {
        errorList.PostalCode = "The zip code must have 5 numbers";
      } 

      //PostalCodePlus4 - number *
      if (data.PostalCodePlus4) {
        if (!/^[0-9]{4}$/.test(data.PostalCodePlus4)) {
          errorList.PostalCodePlus4 = "The value must have 4 numbers";
        }
      }
       
  

      //EmailAddress *
      if(isCoApplicant === false){ 
          if(!data.EmailAddress){
            errorList.EmailAddress = "Please enter a email";
          }else if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(data.EmailAddress)){
              errorList.EmailAddress = "Please enter a valid email";
          } 
      }else{
        if(!data.EmailAddress){
          errorList.EmailAddress = "Please enter a email";
        }else if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(data.EmailAddress)){
            errorList.EmailAddress = "Please enter a valid email";
        }else if(data.EmailAddress === applicant.EmailAddress){
          errorList.EmailAddress = "The email must be different from the applicant's";
        }
      }




       //CellPhone - number *
        if (!data.CellPhone) {
          errorList.CellPhone = "Please enter a Cell Phone";
        } else if (!/^[0-9]{10}$/.test(data.CellPhone)) {
          errorList.CellPhone = "The Cell Phone must have 10 numbers";
        }else if(data.CellPhone === '0000000000' ){
          errorList.CellPhone = "Please enter a valid phone number";
        }


        //BusinessPhone *
        if (!data.BusinessPhone) {
          errorList.BusinessPhone = "Please enter a Business Phone";
        } else if (!/^[0-9]{10}$/.test(data.BusinessPhone)) {
          errorList.BusinessPhone = "The Business Phone must have 10 numbers";
        }else if(data.BusinessPhone === '0000000000' ){
          errorList.BusinessPhone = "Please enter a valid phone number";
        }
        // else if(data.BusinessPhone === data.CellPhone){
        //   errorList.BusinessPhone = "Phone numbers must be different";
        // }
         
          

    
      

      //SocialSecurityNumber - number *
      if (!data.SocialSecurityNumber) {
        errorList.SocialSecurityNumber = "Please enter a Social Security Number";
      }else if (!/^[0-9]{9}$/.test(data.SocialSecurityNumber)) {
        errorList.SocialSecurityNumber = "The social security number must have 9 numbers";
      }



      //DateOfBirth *
      if (!data.DateOfBirth) {
        errorList.DateOfBirth = "Please enter a date";
      } else if(!data.DateOfBirth.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
        if(isSafari){
          errorList.DateOfBirth = "The format must be yyyy-mm-dd";
        }else{
          errorList.DateOfBirth = "The format must be dd/mm/yyyy";
        }     
      }else if(data.DateOfBirth){
        let dob = new Date (data.DateOfBirth);
        const nowDate = new Date();
        nowDate.setFullYear(nowDate.getFullYear() - 18);
        if(dob > nowDate){
          errorList.DateOfBirth = "Must be over 18 years old";
        }
      }




      //CurrentEmployer
      if (!data.CurrentEmployer) {
        errorList.CurrentEmployer = "Please enter a Current Employer";
      }else if (data.CurrentEmployer.length < 3) {
          errorList.CurrentEmployer = "Employer name must be at least 3 characters long";
        }
      




      //EmployerState
      if (!data.EmployerState) {
        errorList.EmployerState = "Please select a Employer State";
      }
   


      //Occupation * 
      if (!data.Occupation) {
        errorList.Occupation = "Please enter a Occupation";
      }


      //HireDate
        if (!data.HireDate) {
          errorList.HireDate = "Please enter a date";
        } else if(!data.HireDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
          if(isSafari){
            errorList.HireDate = "The format must be yyyy-mm-dd";
          }else{
            errorList.HireDate = "The format must be dd/mm/yyyy";
          }     
        }else if(data.HireDate){
          let dob2 = new Date (data.HireDate);
          const nowDate = new Date();
          if(nowDate < dob2){
            errorList.HireDate = "The date must be in the past";
          }
        }


      //LivingSinceCurrentResidence
      if(isCoApplicant === false){ 
          if (!data.LivingSinceCurrentResidence) {
            errorList.LivingSinceCurrentResidence = "Please enter a date";
          } else if(!data.LivingSinceCurrentResidence.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)){
            if(isSafari){
              errorList.LivingSinceCurrentResidence = "The format must be yyyy-mm-dd";
            }else{
              errorList.LivingSinceCurrentResidence = "The format must be dd/mm/yyyy";
            }     
          }else if(data.LivingSinceCurrentResidence){
            let dob2 = new Date (data.LivingSinceCurrentResidence);
            const nowDate = new Date();
            if(nowDate < dob2){
              errorList.LivingSinceCurrentResidence = "The date must be in the past";
            }
          }
      }
       



      //GrossMonthlyIncome *
      if (!data.GrossMonthlyIncome ) {
        errorList.GrossMonthlyIncome = "Please enter a value. Enter 0 if no income.";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(data.GrossMonthlyIncome)) {
        errorList.GrossMonthlyIncome = "The value can only contain numbers";
      }else if (isCoApplicant && data.GrossMonthlyIncome == 0) {
        errorList.GrossMonthlyIncome = "The value must be greater than 0";
      }


      //ResidenceType
      if(isCoApplicant === false){ 
      if (!data.ResidenceType) {
        errorList.ResidenceType = "Please select a Residence Type";
      }
      }


      //ResidenceMonthlyPayment
      if(isCoApplicant === false && data.ResidenceType !== 'OwnedFreeAndClear'){ 
        if (!data.ResidenceMonthlyPayment) {
          errorList.ResidenceMonthlyPayment = "Please enter a value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(data.ResidenceMonthlyPayment)) {
          errorList.ResidenceMonthlyPayment = "The value can only contain numbers";
        }
      }




     
  
      return errorList;
    };

    //validate loan
    const validateFormLoan = applicant => {
      let errorList = {};
  

  

      //DownPayment - number 
      if (!applicant.DownPayment && !applicant.DownPayment === 0) {
        errorList.DownPayment = "Please enter a value";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.DownPayment)) {
        errorList.DownPayment = "The value can only contain numbers";
      } else if(parseInt(applicant.DownPayment) > parseInt(applicant.SaleAmount)){
        errorList.DownPayment = "Should be less than Sale Amount";
      }



      //SaleAmount
      if (!applicant.SaleAmount) {
        errorList.SaleAmount = "Please enter a value";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.SaleAmount)) {
        errorList.SaleAmount = "The value can only contain numbers";
      }

      //AmountFinanced
      if( (parseFloat(loan.SaleAmount) - parseFloat(loan.DownPayment)) < 1000){
        errorList.AmountFinanced = "Minimum Amount Finances is $1000";
      }
  


    
     
  
      return errorList;
    };
    /*------------------------------------------------------------------------------------------------- */
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 


   /*----------------------------------------- SUBMIT FORMULARIOS------------------------------------- */
    //submit applicant
    const handleSubmitApplicant = event => {
      event.preventDefault();
      //handle errors
      setErrors(validateForm(applicant, false));
      setErrorIncomes(validateIncome(incomeList))
      setIsSubmitting(true);
      setIsSubmitingIncomeApplicant(!isSubmitiongIncomeApplicant)
    };

    //submit coapplicant
    const handleSubmitCoApplicant = event => {
      event.preventDefault();
      //handle errors
      setErrorsCoApplicant(validateForm(coApplicant, true));
      setErrorIncomesCoApplicant(validateIncome(incomeListCoApplicant));
      setIsSubmittingCoApplicant(true);
      setIsSubmitingIncomeCoApplicant(!isSubmitiongIncomeCoApplicant)
    };

    //submit loan
    const handleSubmitLoan = event => {
      event.preventDefault();
      
     
      if(termsCheck === false) {
        setTermsCheckBorderRed(true)
      }


      setErrorsLoan(validateFormLoan(loan));
      setIsSubmittingLoan(true);
    };
  /*------------------------------------------------------------------------------------------------- */

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 


    /*----------------------------------------- USE EFFECTS POS FORMULARIOS------------------------------------- */
    //post applicant
    useEffect(() => {

      let errInc = []
      
      errorIncomes.forEach(error => {
        if( Object.keys(error).length != 0){
          errInc = [ ...errInc, false]
        }
        
      })


      if (Object.keys(errors).length === 0 && isSubmitting && errInc.length === 0) {
        
        //changeEmploymentDatesApplicant();
        //console.log(applicant);
        //setCoApplicantType()
        nextStep();
      } else {
        
        // console.log(errors)
       
      }
    }, [errors, isSubmitiongIncomeApplicant]);


    //post coapplicant
    useEffect(() => {

      let errInc = []
      
      errorIncomesCoApplicant.forEach(error => {
        if( Object.keys(error).length != 0){
          errInc = [ ...errInc, false]
        }
      })


      if (Object.keys(errorsCoApplicant).length === 0 && isSubmittingCoApplicant && errInc.length === 0) {
        
        //changeEmploymentDatesCoApplicant();
        //console.log(coApplicant);
        nextStep();
      } else {
        
        //console.log(errorsCoApplicant)
      }
    }, [errorsCoApplicant, isSubmitiongIncomeCoApplicant]);


    //post loan
    useEffect(() => {
      if (Object.keys(errorsLoan).length === 0 && isSubmittingLoan && termsCheck) {
        
        //changeEmploymentDates(applicant);
        //console.log(loan);

        handleSubmitAllData();
        
      } else {
        //console.log("hay un problema o los campos estan vacios en Loan");
        //console.log(errorsLoan)
      }
    }, [errorsLoan]);
   /*------------------------------------------------------------------------------------------------- */
    
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 


   /*----------------------------------------- STEPS Y TABS------------------------------------- */   
  //set step
   const setStep1 = () => {
          setStep(1);    
    };
    const setStep2 = () => {
      setStep(2);    
    };
    const setStep3 = () => {
      setStep(3);    
    };
    const setStep4 = () => {
      setStep(4);    
    };
    const setStep5 = () => {
        setStep(5);    
      };
    const setStep6 = () => {
          setStep(6);    
        };
    const nextStep = () => {
        setStep(step + 1);      
    };
    const prevStep = () => {
        setStep(step - 1);  
    };
    const restar = () => {
        setStep(1);
    };


    //steps for create new application
    const stepsCounter = () => {
      const styletitlemobile1 = ["stepmobiletittle"];
      const styletitlemobile2 = ["stepmobiletittle"];
      const styletitlemobile3 = ["stepmobiletittle"];
      
      const stylenumber1 = ["stepnumber"];
      const styletitle1 = ["steptittle"];
      const stylenumber2 = ["stepnumber"];
      const styletitle2 = ["steptittle"];
      const stylenumber3 = ["stepnumber"];
      const styletitle3 = ["steptittle"];
      
      if (step === 1) {
        stylenumber1.push("stepnumberactive");
        styletitle1.push("steptittleactive");
        styletitlemobile1.push("showstepmobiletittle");
      }
      if (step === 2) {
        stylenumber1.push("stepnumbercomplete");
        stylenumber2.push("stepnumberactive");
        styletitle2.push("steptittleactive");
        styletitlemobile2.push("showstepmobiletittle");
      }
      if (step === 3) {
        stylenumber1.push("stepnumbercomplete");
        stylenumber2.push("stepnumbercomplete");
        stylenumber3.push("stepnumberactive");
        styletitle3.push("steptittleactive");
        styletitlemobile3.push("showstepmobiletittle");
      }
      
  
      return (
        <div className="stepscontainer">
          <div className="stepsmobiletittlecontainer">
          
            
            <h3 className={styletitlemobile1.join(" ")}>Applicant Information</h3>
            <h3 className={styletitlemobile2.join(" ")}>CoApplicant Information</h3>
            <h3 className={styletitlemobile3.join(" ")}>Loan Parameters</h3>
            
          </div>


          <div className="stepsdesktopcontainer">
            {step === 1 ? null : <img src={iconArrowBack } onClick={prevStep} className="backButton"/>}
            
            <div className="step">
              <span className={stylenumber1.join(" ")}>1</span>
              <h3 className={styletitle1.join(" ")}>Applicant Information</h3>
            </div>
            <div className="step">
              <span className={stylenumber2.join(" ")}>2</span>
              <h3 className={styletitle2.join(" ")}>
                CoApplicant Information
              </h3>
            </div>
            <div className="step">
              <span className={stylenumber3.join(" ")}>3</span>
              <h3 className={styletitle3.join(" ")}>Loan Parameters</h3>
            </div>
           
          </div>
        </div>
      );
    };


    //tabs for detail application
    const tabs = () => {
        
      return (
        
        <div className="applicationTabsContainer">
          <ul className="applicationTabs">
            <li className={step===4 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep4}>Status</li>  
              {
              props.errorStatus ? 
              <>
               <li className={step===1 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(1) }>Applicant</li>
                <li className={step===2 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(2) }>CoApplicant</li>
              </>
              :
              <>
              <li className={step===7 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(7) }>Applicant</li>
              <li className={step===8 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(8) }>CoApplicant</li>
              </>
              }
            <li className={step===3 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep3}>Parameters</li>  
            {
              canCreateProposal && 
              <li className={step===9 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(9)}>Proposal</li>   
            }
             
                   
            {/* <li className={step===5 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep5}>Contract</li>  */}
            <li className={step===10 ? 'applicationTabActive' : 'applicationTab'} onClick={()=> setStep(10)}>Contract</li> 
            <li className={step===6 ? 'applicationTabActive' : 'applicationTab'} onClick={setStep6}>Documents</li>
           
           
            
            
          </ul>
        </div>
        
      );
    
    
    }
    /*------------------------------------------------------------------------------------------------- */

    const copyAddressData = () =>{
  

    if(copyAddresDataFromApplicant === false){
      setCoApplicant({
        ...coApplicant,
        Address1: applicant.Address1,
        City: applicant.City,
        State: applicant.State,
        PostalCode: applicant.PostalCode,   
        PostalCodePlus4 : applicant.PostalCodePlus4 || '',    
    })
    }else{
      setCoApplicant({
        ...coApplicant,
        Address1: null,
        City: null,
        State: null,
        PostalCode: null,   
        PostalCodePlus4 : '',   
    })
    }

    setCopyAddressDataFromApplicant(!copyAddresDataFromApplicant)
    }


        /*----------------------------------------- INCOMES------------------------------------- */ 
        const addIncome=()=>{

          setErrorIncomes([...errorIncomes, {}])
            
            setIncomeList([
              ...incomeList,
              {
                Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`,
                
              }
            ]) 
            //console.log(incomeList)
            
          } 
  
        const addIncomeCoApplicant=()=>{
          setErrorIncomesCoApplicant([...errorIncomesCoApplicant, {}])
          
          setIncomeListCoApplicant([
            ...incomeListCoApplicant,
            {
              Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`,
              //errors : [{}]
            }
          ]) 
          //console.log(incomeListCoApplicant)
          
        }
  
        
        const handleIncomeInput=(event, id)=>{
  
              const newList = incomeList.map(income =>{
                
                if(income.Id === id){
                  if(event.target.name === 'AdditionalIncomeSource'){
                      income.AdditionalIncomeSource = event.target.value
                    }else if(event.target.name === 'Description'){
                      income.Description = event.target.value
                    }else if(event.target.name === 'Amount'){
                      income.Amount = event.target.value
                    }else if(event.target.name === 'Source'){
                      income.Source = event.target.value
                    }
                  
  
                  return income
  
                }else{
                  return income
                }
  
  
            })
              
            setIncomeList(newList)
                
            //console.log(incomeList)
              
            
        }
  
  
        const handleIncomeInputCoApplicant=(event, id)=>{
  
          const newList = incomeListCoApplicant.map(income =>{
                
            if(income.Id === id){
              if(event.target.name === 'AdditionalIncomeSource'){
                  income.AdditionalIncomeSource = event.target.value
                }else if(event.target.name === 'Description'){
                  income.Description = event.target.value
                }else if(event.target.name === 'Amount'){
                  income.Amount = event.target.value
                }else if(event.target.name === 'Source'){
                  income.Source = event.target.value
                }
              
  
              return income
  
            }else{
              return income
            }
  
  
        })
          
        setIncomeListCoApplicant(newList)
            
        //console.log(incomeListCoApplicant)
      }
  
  
        const deleteIncome=(id, index)=>{
  
            const newList = incomeList.filter(income => income.Id !== id) 
            setIncomeList(newList);
  
            const newErrorList = errorIncomes.filter((error, i)=> errorIncomes.indexOf(error) !== index)
            setErrorIncomes(newErrorList)
  
        } 
  
        
        const deleteIncomeCoApplicant=(id)=>{
          
            const newList = incomeListCoApplicant.filter(income => income.Id !== id) 
            setIncomeListCoApplicant(newList)
            
        } 
      
  
        const validateIncome=(incomeList)=>{
  
  
          const errorIncomeList = incomeList.map((item)=>{
  
  
            let errorList = {};
  
            //AdditionalIncomeSource - select
            if (!item.AdditionalIncomeSource) {
              errorList.AdditionalIncomeSource = "Please select an option";
            }
      
            //Description - text
            if (!item.Description) {
              errorList.Description = "Please enter a description";
            }
  
      
            //Amount - number
            if (!item.Amount) {
              errorList.Amount = "Please enter a value";
            } else if (!/^[0-9]+\.?[0-9]*$/.test(item.Amount)) {
              errorList.Amount = "The value can only contain numbers";
            }
      
            
            if(errorList.lenght !== 0){
              return errorList;
            }
            
            
          })
  
          //incomeList.errors = errorIncomeList;
  
          //console.log(errorIncomeList)
          
  
          
          //return errorIncomeList
          if(errorIncomeList.length === 0){
            //console.log(errorIncomeList)
            const vacio = []
            return vacio
          }else{
            console.log(errorIncomeList)
            return errorIncomeList
          }
          
          
        }
  
       /*------------------------------------------------------------------------------------------------- */
   














  switch (step) {
    default:
      return (
        <div>
          <h1>breack</h1>
          <button onClick={restar} value="Next">
            Next
          </button>
        </div>
      );
    case 1:
          
      return (
        <>

        <div className="tabsMainContainer">

        {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
          <div className="applicationForm-titleFinancialEntityContainer">
            <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p>
            <p className="applicationForm_formtitle">Pinnacle Form</p>
          </div>
          : null
        }

        <NotificationPinnacleOff type={props.type}/>

        {props.type === 'create' ? stepsCounter()
        : tabs()
        }

        {isLoadingDetails ? 
        <div className="spinnerDetails"><Spinner
              size={25}
              color={"grey"}
              spinnerWidth={3}
              visible={true}
            /></div> : null}


          {
            props.showErrorMessages &&
            <div className="sheetContainer">      
            {
                  hasMessages ?  
                                                  
                        statusMessages.map((item, index) => {
                          return (
                            <div className="cardMessage_error" key={index}>
                              <p className="statusMessage_error">{item.Text}</p>
                            </div>
                          );
                        })
                        
                
                  : 
                    <p className="applicationFormContainer-statusNotMessage">This app has no status messages available.</p>
              }
          </div>
          }
          

          <div className="applicationFormContainer">
            <h3 className="applicationFormContainer-tittle">
              Main Information
            </h3>
            <form action="" id="form" className="form">

              {/* <button onClick={()=>{nextStep()}}>Next</button> */}

              {/* FirstName */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >First Name</label>
              <input
                type="text"
                name="FirstName"
                onChange={handleInputApplicant}
                className={`${errors.FirstName ? "imputError" : "campo"}`}
                value={applicant.FirstName}
                placeholder="First name"
                disabled={(props.type === 'edit' || props.urlParamApplicant_Id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                required
              />
              {errors.FirstName && <p className="errorMessage">{errors.FirstName}</p>}
              </div>


              {/* MiddleInitial */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Middle Initial</label>
              <input
                type="text"   
                name="MiddleInitial"
                onChange={handleInputApplicant}
                className={`${errors.MiddleInitial ? "imputError" : "campo"}`}
                value={applicant.MiddleInitial}
                placeholder="Only one letter"
                disabled={(props.type === 'edit' || props.urlParamApplicant_Id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                required
              />
              {errors.MiddleInitial && <p className="errorMessage">{errors.MiddleInitial}</p>}
              </div>


              {/* LastName */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Last Name</label>
                <input
                  type="text"  
                  name="LastName"
                  onChange={handleInputApplicant}
                  className={`${errors.LastName ? "imputError" : "campo"}`}
                  placeholder="Last Name"
                  value={applicant.LastName}
                  required
                  disabled={(props.type === 'edit' || props.urlParamApplicant_Id || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                />
                {errors.LastName && <p className="errorMessage">{errors.LastName}</p>}
              </div>


              {/* SocialSecurityNumber */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Social Security Number</label>
                  <input
                    value={props.type === 'edit' ? applicant.hasOwnProperty('SocialSecurityNumber') ? ('*****' + applicant.SocialSecurityNumber.toString().substr(5,9)) : '' : applicant.SocialSecurityNumber}
                    type={props.type === 'edit' ? "text" : "number"}
                    name="SocialSecurityNumber"
                    onChange={handleInputApplicant}
                    className={`${errors.SocialSecurityNumber? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={((props.type === 'edit' || props.urlParamApplicant_Id || props.appData || proposalInfo.info.HasCreatedApplications) && !allowEditSSN) ? "disabled" : null}
                  />
                  {errors.SocialSecurityNumber && <p className="errorMessage">{errors.SocialSecurityNumber}</p>}
              </div>


              {/* EmailAddress */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Email</label>
                <input
                  type="email"       
                  name="EmailAddress"
                  onChange={handleInputApplicant}
                  className={`${errors.EmailAddress ? "imputError" : "campo"}`}
                  placeholder="Email"
                  value={applicant.EmailAddress}
                  required
                  disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                />
                {errors.EmailAddress && <p className="errorMessage">{errors.EmailAddress}</p>}
              </div>



              {/* DateOfBirth */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Date of Birth</label>
                  <input
                    type="date"
                    name="DateOfBirth"
                    onChange={handleInputApplicant}
                    className={`${errors.DateOfBirth ? "imputError" : "campo"}`}
                    placeholder="yyyy-mm-dd"
                    required
                    value={applicant.DateOfBirth}
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.DateOfBirth && <p className="errorMessage">{errors.DateOfBirth}</p>}
              </div>

  
              {/* Address1 */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Address</label>
                  <input
                    type="text"
                    value={applicant.Address1}
                    name="Address1"
                    onChange={handleInputApplicant}
                    className={`${errors.Address1 ? "imputError" : "campo"}`}
                    placeholder="Address"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.Address1 && <p className="errorMessage">{errors.Address1}</p>}
              </div>
             

              {/* City */}
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >City</label>
                  <input
                    type="text"
                    value={applicant.City}
                    name="City"
                    onChange={handleInputApplicant}
                    className={`${errors.City ? "imputError" : "campo"}`}
                    placeholder="City"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.City && <p className="errorMessage">{errors.City}</p>}
              </div>


              {/* State */}
              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >State</label>
              <select
                value={applicant.State}
                name="State"
                onChange={handleInputApplicant}
                className={`${errors.State ? "imputError" : "campo"}`}
                placeholder="State"
                required
                disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
              >
                <option value="1" disabled selected>
                  State
                </option>
                <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
              </select>
              {errors.State && <p className="errorMessage">{errors.State}</p>}
              </div>
              
              
              {/* PostalCode */}
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Zip Code</label>
                  <input
                    type="number"
                    value={applicant.PostalCode}
                    name="PostalCode"
                    onChange={handleInputApplicant}
                    className={`${errors.PostalCode ? "imputError" : "campo"}`}
                    placeholder="Zip Code"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.PostalCode && <p className="errorMessage">{errors.PostalCode}</p>}
              </div>


               {/* PostalCodePlus4 */}
               <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Plus 4</label>
                  <input
                    type="number"
                    value={applicant.PostalCodePlus4}
                    name="PostalCodePlus4"
                    onChange={handleInputApplicant}
                    className={`${errors.PostalCodePlus4 ? "imputError" : "campo"}`}
                    placeholder="Plus 4"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications)? "disabled" : null}
                  />
                  {errors.PostalCodePlus4 && <p className="errorMessage">{errors.PostalCodePlus4}</p>}
              </div>

              

          


              {/* CellPhone */}
              <div className="mediowidth-left  campoContainer">
              <label className="labelApplicationForm" >Home/Cell Phone</label>
                  <input
                    type="number"
                    value={applicant.CellPhone}
                    name="CellPhone"
                    onChange={handleInputApplicant}
                    className={`${errors.CellPhone ? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.CellPhone && <p className="errorMessage">{errors.CellPhone}</p>}
              </div>


              {/* BusinessPhone */}
              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Business Phone</label>
                  <input
                    type="number"
                    value={applicant.BusinessPhone}
                    name="BusinessPhone"
                    onChange={handleInputApplicant}
                    className={`${errors.BusinessPhone ? "imputError" : "campo"}`}
                    placeholder="Business Phone"
                    required
                    disabled={(props.type === 'edit' || proposalInfo.info.HasCreatedApplications) ? "disabled" : null}
                  />
                  {errors.BusinessPhone && <p className="errorMessage">{errors.BusinessPhone}</p>}
              </div>

              <div></div>




             
             
              

          
              </form>




              <h3 className="applicationFormContainer-tittle">
              Employment Information
              </h3>

              <form action="" id="form" className="form">


              {/* CurrentEmployer */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Current Employer</label>
                  <input
                  type="text"
                  value={applicant.CurrentEmployer}
                  name="CurrentEmployer"
                  onChange={handleInputApplicant}
                  className={`${errors.CurrentEmployer ? "imputError" : "campo"}`}
                  placeholder="Current Employer"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.CurrentEmployer && <p className="errorMessage">{errors.CurrentEmployer}</p>}
              </div>
                  

              {/* Occupation */}
              <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Occupation</label>
                <input
                  type="text"
                  value={applicant.Occupation}
                  name="Occupation"
                  onChange={handleInputApplicant}
                  className={`${errors.Occupation ? "imputError" : "campo"}`}
                  placeholder="Occupation"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
               {errors.Occupation && <p className="errorMessage">{errors.Occupation}</p>}
                </div>
                




              {/* HireDate */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Employment Start Date</label>
                  <input
                  type="date"
                  value={applicant.HireDate}
                  name="HireDate"
                  onChange={handleInputApplicant}
                  className={`${errors.HireDate ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.HireDate && <p className="errorMessage">{errors.HireDate}</p>}
              </div>
              

              {/* EmployerState */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Employer State</label>
              <select
                value={applicant.EmployerState}
                name="EmployerState"
                onChange={handleInputApplicant}
                className={`${errors.EmployerState ? "imputError" : "campo"}`}
                placeholder="Drivers License State"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              >
                <option value="1" disabled selected>
                Employer State
                </option>
                <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
              </select>
              {errors.EmployerState && <p className="errorMessage">{errors.EmployerState}</p>}
              </div>
             

            
            
              

               {/* GrossMonthlyIncome */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Gross Monthly Income (Include all types of income)</label> 
                  <input
                    type="number"
                    value={applicant.GrossMonthlyIncome}
                    name="GrossMonthlyIncome"
                    onChange={handleInputApplicant}
                    onBlur={()=> { setApplicant({...applicant, 'GrossMonthlyIncome': parseFloat(applicant.GrossMonthlyIncome).toFixed(2) })   }}
                    className={`${errors.GrossMonthlyIncome ? "imputError" : "campo"}`}
                    placeholder="Include all types of income"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.GrossMonthlyIncome && <p className="errorMessage">{errors.GrossMonthlyIncome}</p>}
              </div>


              
              
              </form>

              
              {/*   AQUI COMIENZA OTHER INCOMES---------------------------------------------------------------------------------------- */}
             
              
             <h3 className="applicationFormContainer-tittle">
              Other Monthly Income
              </h3>
              

             {/* INCOME LISTS */}
                  {
                incomeList.map((item, index)=>{
                return(
                <div className="incomeCard" key={index}>

                  

                  {props.type === 'edit' ? 
                  null
                  :
                  <div className="mediowidth-right deleteIncome-icon_container">
                   <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteIncome(item.Id, index)}}/>
                  </div>  
                  }

                        <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >Additional Income Source</label>
                        <select
                        value={item.AdditionalIncomeSource} 
                        name="AdditionalIncomeSource"
                        onChange={(event)=>{handleIncomeInput(event, item.Id)}} 
                        className={`${errorIncomes[index].AdditionalIncomeSource ? "imputError" : "campo"}`} 
                        // className={`${errorIncomes.includes(`${errorIncomes[index].AdditionalIncomeSource}`, index ) ? "imputError" : "campo"}`} 
                        placeholder="Additional Income Source"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                        >
                          <option value="disable" disabled selected>
                            Additional Income Source
                            </option>
                            <option value="Other">Other</option>
                            <option value="RetirementSSI">Retirement SSI</option>
                            <option value="Investments">Investments</option>
                            <option value="Rental">Rental</option>
                            </select>
                            
                             {errorIncomes[index].AdditionalIncomeSource && <p className="errorMessage">{errorIncomes[index].AdditionalIncomeSource}</p>} 
                            {/* {errorIncomes.includes('AdditionalIncomeSource', index ) && <p className="errorMessage">{errorIncomes[index].AdditionalIncomeSource}</p>} */}
                        </div>




                    <div className="mediowidth-right campoContainer">
                       <label className="labelApplicationForm" >Description</label>
                      <input
                      type="text"
                      value={item.Description}
                      name="Description"
                      onChange={(event)=>{handleIncomeInput(event, item.Id)}} 
                      className={`${errorIncomes[index].Description ? "imputError" : "campo"}`}
                      // className={`${errorIncomes.includes('Description', index ) ? "imputError" : "campo"}`}
                      placeholder="Description"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorIncomes[index].Description && <p className="errorMessage">{errorIncomes[index].Description}</p>} 
                      {/* {errorIncomes.includes('Description', index ) && <p className="errorMessage">{errorIncomes[index].Description}</p>} */}
                      </div>



                      {/* <div className="mediowidth-left campoContainer">
                      <label className="labelApplicationForm" >Source</label>
                      <input
                      type="text"
                      value={item.Source}
                      name="Source"
                      onChange={(event)=>{handleIncomeInput(event, item.Id)}} 
                      className={`${errorIncomes[index].Source ? "imputError" : "campo"}`}
                      //className={`${errorIncomes.includes('Source', index ) ? "imputError" : "campo"}`}
                      placeholder="Source"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorIncomes[index].Source && <p className="errorMessage">{errorIncomes[index].Source}</p>} 
                      </div> */}


                      <div className="mediowidth-left campoContainer">
                      <label className="labelApplicationForm" >Amount</label>
                      <input
                      type="number"
                      value={item.Amount}
                      name="Amount"
                      onChange={(event)=>{handleIncomeInput(event, item.Id)}}
                      className={`${errorIncomes[index].Amount ? "imputError" : "campo"}`}
                      //className={`${errorIncomes.includes('Amount', index )? "imputError" : "campo"}`}
                      placeholder="Amount"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                      />
                      {errorIncomes[index].Amount && <p className="errorMessage">{errorIncomes[index].Amount}</p>}
                      {/* {errorIncomes.includes('Amount', index ) && <p className="errorMessage">{errorIncomes[index].Amount}</p>} */}
                      </div>
                      </div>
                      )
                      })
                      }





                      {props.type === 'edit' ? 
                      null
                      :
                      <button className="addIncome_button" onClick={addIncome}>Add Income</button>
                      }
                      

                      {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}






            <h3 className="applicationFormContainer-tittle">
            Residence
            </h3>

            <form action="" id="form" className="form">


              {/* ResidenceType */}
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Residence Type</label>
                <select
                  value={applicant.ResidenceType}
                  name="ResidenceType"
                  onChange={handleInputApplicant}
                  className={`${errors.ResidenceType? "imputError" : "campo"}`}
                  placeholder=""
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                >
                  <option value="disable" disabled selected>
                  Residence Type
                  </option>
                  {
                    props.showRentOption && <option value="Rent">Rent</option> 
                  }
                  <option value="OwnWithMortgage">Own with Mortgage</option>
                  <option value="OwnedFreeAndClear">Owned Free & Clear</option>
                  {/* <option value="Other">Other</option> */}
                  
                </select>
                {errors.ResidenceType && <p className="errorMessage">{errors.ResidenceType}</p>}
            </div>


            {/* LivingSinceCurrentResidence */}
            <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Date Moved into Home</label>
                  <input
                  type="date"
                  value={applicant.LivingSinceCurrentResidence}
                  name="LivingSinceCurrentResidence"
                  onChange={handleInputApplicant}
                  className={`${errors.LivingSinceCurrentResidence ? "imputError" : "campo"}`}
                  placeholder="yyyy-mm-dd"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errors.LivingSinceCurrentResidence && <p className="errorMessage">{errors.LivingSinceCurrentResidence}</p>}
              </div>
 


            
            
            
            {/* ResidenceMonthlyPayment */}
            {applicant.ResidenceType !== 'OwnedFreeAndClear' &&
            <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Residence Monthly Payment</label>
                <input
                  type="number"
                  value={applicant.ResidenceMonthlyPayment}
                  name="ResidenceMonthlyPayment"
                  onChange={handleInputApplicant}
                  onBlur={()=> { setApplicant({...applicant, 'ResidenceMonthlyPayment': parseFloat(applicant.ResidenceMonthlyPayment).toFixed(2) })   }}
                  className={`${errors.ResidenceMonthlyPayment ? "imputError" : "campo"}`}
                  placeholder="Residence Monthly Payment"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errors.ResidenceMonthlyPayment && <p className="errorMessage">{errors.ResidenceMonthlyPayment}</p>}
            </div>
            }
            

             </form> 








              
           
            


            <form action="" id="form" className="form">






























              {props.type === 'create' ? 
              <input
              onClick={handleSubmitApplicant}
              type="submit"
              id="submit"
              className="fullwidth submit campo"
              placeholder="Save Changes"
              value="Save Changes"
              required
            />        
              : null
              }
            </form>
          </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
        <div className="tabsMainContainer">
        
        {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
          <div className="applicationForm-titleFinancialEntityContainer">
            <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p>
            <p className="applicationForm_formtitle">Pinnacle Form</p>
          </div>
          : null
        }

        {props.type === 'create' ? stepsCounter()
        : tabs()
        }

        {isLoadingDetails ? 
        <div className="spinnerDetails"><Spinner
              size={25}
              color={"grey"}
              spinnerWidth={3}
              visible={true}
            /></div> : null}


          
          {props.type === 'create' ?
          <div className="applicationFormContainer">

          {props.type === 'create' ?
          <>
          <h3 className="applicationFormContainer-tittle ">
          Do you want to add a CoApplicant?
           </h3>
           <p className="coapplicantNote">If the primary applicant is married then the co-applicant must be the spouse, otherwise the co-applicant must reside in the house with the primary applicant. Adding a co-applicant may result in a better promotion offering.</p>
           </>
          :null
          }

          {props.type === 'create' ?
          <div className="form">
            <div className="fullwidth campoContainer ">
            <label className="labelApplicationForm" >Add a Co-Applicant?</label>
                <select
                  id=""
                  name="HasCoApplicant"
                  onChange={handleInputHasCoApplicant}
                  className="campo"
                  placeholder="Has CoApplicant"
                  value={applicant.HasCoApplicant === true ? 'true' : null}
                  required
                >
                  <option value="default" disabled selected>
                    Select
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                 
                </select>
                
            </div>
            </div>
            :null
            }
            

              {hasCoApplicantValue.HasCoApplicant === "false" ?
                <div className="form">
                  <button 
                  className="mediowidth-left campo back"
                  onClick={prevStep}
                  >Back</button>

                  <input
                    onClick={nextStep}
                    type="submit"
                    id="submit"
                    className="mediowidth-right submit campo"
                    placeholder="Save and continue"
                    value="Continue"
                    required
                  />
                </div>
                
                  : null
                }
           
          </div>
          : null}







          {hasCoApplicant ? 
          <div className="applicationFormContainer">
            <h3 className="applicationFormContainer-tittle">
              Main Information
            </h3>
            <div className="form">

            {/* <button onClick={()=>{nextStep()}}>Next</button> */}

            {/* ApplicantRelationship */}
            {/* <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Applicant Relationship</label>
                  <input
                  type="text"
                  value={coApplicant.ApplicantRelationship}
                  name="ApplicantRelationship"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.ApplicantRelationship ? "imputError" : "campo"}`}
                  placeholder="Applicant Relationship"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                  />

                {errorsCoApplicant.ApplicantRelationship && <p className="errorMessage">{errorsCoApplicant.ApplicantRelationship}</p>}
              </div> */}


              {/* FirstName */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" > First Name</label>
              <input
                type="text"
                value={coApplicant.FirstName}
                name="FirstName"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.FirstName ? "imputError" : "campo"}`}
                placeholder="First name"               
                required
                autoFocus
                disabled={props.type === 'edit' ? "disabled" : null}
              />
              {errorsCoApplicant.FirstName && <p className="errorMessage">{errorsCoApplicant.FirstName}</p>}
              </div>


              {/* MiddleInitial */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Middle Initial</label>
              <input
                type="text"   
                name="MiddleInitial"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.MiddleInitial ? "imputError" : "campo"}`}
                value={coApplicant.MiddleInitial}
                placeholder="Only one letter"
                disabled={props.type === 'edit' ? "disabled" : null}
                required
              />
              {errorsCoApplicant.MiddleInitial && <p className="errorMessage">{errorsCoApplicant.MiddleInitial}</p>}
              </div>


              {/* LastName */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Last Name</label>
                <input
                  type="text"
                  value={coApplicant.LastName}
                  name="LastName"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.LastName ? "imputError" : "campo"}`}
                  placeholder="Last Name"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.LastName && <p className="errorMessage">{errorsCoApplicant.LastName}</p>}
              </div>


              {/* SocialSecurityNumber */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Social Security Number</label>
                  <input
                    value={props.type === 'edit' ? coApplicant.hasOwnProperty('SocialSecurityNumber') ? ('*****' + coApplicant.SocialSecurityNumber.toString().substr(5,9)) : '' : coApplicant.SocialSecurityNumber}
                    type={props.type === 'edit' ? "text" : "number"}
                    name="SocialSecurityNumber"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.SocialSecurityNumber? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.SocialSecurityNumber && <p className="errorMessage">{errorsCoApplicant.SocialSecurityNumber}</p>}
              </div>


              {/* EmailAddress */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Email (must be different than applicant email)</label>
                <input
                  type="email"
                  
                  name="EmailAddress"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.EmailAddress ? "imputError" : "campo"}`}
                  placeholder="Email"
                  value={coApplicant.EmailAddress}
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsCoApplicant.EmailAddress && <p className="errorMessage">{errorsCoApplicant.EmailAddress}</p>}
              </div>



              {/* DateOfBirth */}
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Date of Birth</label>
                  <input
                    type="date"
                    value={coApplicant.DateOfBirth}
                    name="DateOfBirth"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.DateOfBirth ? "imputError" : "campo"}`}
                    placeholder="yyyy-mm-dd"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.DateOfBirth && <p className="errorMessage">{errorsCoApplicant.DateOfBirth}</p>}
              </div>
              
              
              {props.type === 'create' ? 
              <div className="fullwidth campoContainer checkCopyAddressInfo">
                <input type="checkbox" 
                      name="isActive" 
                      className="inputCopyAddressInfo" 
                      onClick={copyAddressData} /> <label className="labelApplicationForm" > Copy address info from Applicant</label>
              </div>
              : null
              }


              {/* Address1 */}
              {copyAddresDataFromApplicant ? null :
                <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Address</label>
                    <input
                      type="text"
                      value={coApplicant.Address1}
                      name="Address1"
                      onChange={handleInputCoApplicant}
                      className={`${errorsCoApplicant.Address1 ? "imputError" : "campo"}`}
                      placeholder="Address"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                    />
                    {errorsCoApplicant.Address1 && <p className="errorMessage">{errorsCoApplicant.Address1}</p>}
                </div>
              }
              
             

              {/* City */}
              {copyAddresDataFromApplicant ? null :
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >City</label>
                  <input
                    type="text"
                    value={coApplicant.City}
                    name="City"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.City ? "imputError" : "campo"}`}
                    placeholder="City"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.City && <p className="errorMessage">{errorsCoApplicant.City}</p>}
              </div>
              }

              {/* State */}
              {copyAddresDataFromApplicant ? null :
              <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >State</label>
              <select
                value={coApplicant.State}
                name="State"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.State ? "imputError" : "campo"}`}
                placeholder="State"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              >
                <option value="1" disabled selected>
                  State
                </option>
                <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
              </select>
              {errorsCoApplicant.State && <p className="errorMessage">{errorsCoApplicant.State}</p>}
              </div>
              }
              

              {/* PostalCode */}
              {copyAddresDataFromApplicant ? null :
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Zip Code</label>
                  <input
                    type="number"
                    value={coApplicant.PostalCode}
                    name="PostalCode"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.PostalCode ? "imputError" : "campo"}`}
                    placeholder="Zip Code"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.PostalCode && <p className="errorMessage">{errorsCoApplicant.PostalCode}</p>}
              </div>
              }
            

             {/* PostalCodePlus4 */}
             {copyAddresDataFromApplicant ? null :
             <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Plus 4</label>
                  <input
                    type="number"
                    value={coApplicant.PostalCodePlus4}
                    name="PostalCodePlus4"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.PostalCodePlus4 ? "imputError" : "campo"}`}
                    placeholder="Plus 4"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.PostalCodePlus4 && <p className="errorMessage">{errorsCoApplicant.PostalCodePlus4}</p>}
              </div>
             }


              {/* CellPhone */}
              <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Home/Cell Phone</label>
                  <input
                    type="number"
                    value={coApplicant.CellPhone}
                    name="CellPhone"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.CellPhone ? "imputError" : "campo"}`}
                    placeholder="Do not use dashes"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.CellPhone && <p className="errorMessage">{errorsCoApplicant.CellPhone}</p>}
              </div>


              {/* BusinessPhone */}
              <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm" >BusinessPhone</label>
                    <input
                      type="number"
                      value={coApplicant.BusinessPhone}
                      name="BusinessPhone"
                      onChange={handleInputCoApplicant}
                      className={`${errorsCoApplicant.BusinessPhone ? "imputError" : "campo"}`}
                      placeholder="Do not use dashes"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                    />
                    {errorsCoApplicant.BusinessPhone && <p className="errorMessage">{errorsCoApplicant.BusinessPhone}</p>}
                </div>

              

              {/* DriversLicense */}
              {/* <div className="mediowidth-left campoContainer">
              <label className="labelApplicationForm" >Drivers License</label>
                  <input
                    type="text"
                    value={coApplicant.DriversLicense}
                    name="DriversLicense"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.DriversLicense ? "imputError" : "campo"}`}
                    placeholder="Drivers License"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  />
                  {errorsCoApplicant.DriversLicense && <p className="errorMessage">{errorsCoApplicant.DriversLicense}</p>}
              </div> */}


              {/* DriversLicenseState */}
              {/* <div className="mediowidth-right campoContainer">
              <label className="labelApplicationForm" >Drivers License State</label>
              <select
                value={coApplicant.DriversLicenseState}
                name="DriversLicenseState"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.DriversLicenseState ? "imputError" : "campo"}`}
                placeholder="Drivers License State"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              >
                <option value="1" disabled selected>
                  Drivers License State
                </option>
                <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DE">DE</option>
                  <option value="DC">DC</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                  <option value="GU">GU</option>
                  <option value="PR">PR</option>
                  <option value="VI">VI</option>

                  <option value="FM">FM</option>
                  <option value="AS">AS</option>
                  <option value="AF">AF</option>
                  <option value="AA">AA</option>
                  <option value="AC">AC</option>
                  <option value="AE">AE</option>
                  <option value="AP">AP</option>
                  <option value="MH">MH</option>
                  <option value="MP">MP</option>
                  <option value="PW">PW</option>
              </select>
              {errorsCoApplicant.DriversLicenseState && <p className="errorMessage">{errorsCoApplicant.DriversLicenseState}</p>}
              </div> */}

              
             
              
              </div>




            <h3 className="applicationFormContainer-tittle">
              Employment Information
              </h3>

              <div className="form">
    
                {/* CurrentEmployer */}
                <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Current Employer</label>
                    <input
                    type="text"
                    value={coApplicant.CurrentEmployer}
                    name="CurrentEmployer"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.CurrentEmployer ? "imputError" : "campo"}`}
                    placeholder="Current Employer"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                    />
                    {errorsCoApplicant.CurrentEmployer && <p className="errorMessage">{errorsCoApplicant.CurrentEmployer}</p>}
                </div>



                {/* Occupation */}
                <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Occupation</label>
                <input
                  type="text"
                  value={coApplicant.Occupation}
                  name="Occupation"
                  onChange={handleInputCoApplicant}
                  className={`${errorsCoApplicant.Occupation ? "imputError" : "campo"}`}
                  placeholder="Occupation"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
               {errorsCoApplicant.Occupation && <p className="errorMessage">{errorsCoApplicant.Occupation}</p>}
                </div>
                


                {/* EmploymentTermType Income Type*/}
                {/* <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Income Type</label>
                  <select
                    value={coApplicant.EmploymentTermType}
                    name="EmploymentTermType"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.EmploymentTermType ? "imputError" : "campo"}`}
                    placeholder=""
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                  >
                    <option value="disable" disabled selected>
                    Income Type
                    </option>
                    <option value="A">Anual</option>
                    <option value="M">Monthly</option>              
                  </select>
                  {errorsCoApplicant.EmploymentTermType && <p className="errorMessage">{errorsCoApplicant.EmploymentTermType}</p>}
                  </div> */}


                {/* HireDate */}
                <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Employment Start Date</label>
                    <input
                    type="date"
                    value={coApplicant.HireDate}
                    name="HireDate"
                    onChange={handleInputCoApplicant}
                    className={`${errorsCoApplicant.HireDate ? "imputError" : "campo"}`}
                    placeholder="yyyy-mm-dd"
                    required
                    disabled={props.type === 'edit' ? "disabled" : null}
                    />
                    {errorsCoApplicant.HireDate && <p className="errorMessage">{errorsCoApplicant.HireDate}</p>}
                </div>


                {/* EmployerState */}
                <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Employer State</label>
              <select
                value={coApplicant.EmployerState}
                name="EmployerState"
                onChange={handleInputCoApplicant}
                className={`${errorsCoApplicant.EmployerState ? "imputError" : "campo"}`}
                placeholder="Drivers License State"
                required
                disabled={props.type === 'edit' ? "disabled" : null}
              >
                <option value="1" disabled selected>
                Employer State
                </option>
                <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
              </select>
              {errorsCoApplicant.EmployerState && <p className="errorMessage">{errorsCoApplicant.EmployerState}</p>}
              </div>
   


                {/* GrossMonthlyIncome */}
                <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Gross Monthly Income (Include all types of income)</label>
                    <input
                      type="number"
                      value={coApplicant.GrossMonthlyIncome}
                      name="GrossMonthlyIncome"
                      onChange={handleInputCoApplicant}
                      onBlur={()=> { setCoApplicant({...coApplicant, 'GrossMonthlyIncome': parseFloat(coApplicant.GrossMonthlyIncome).toFixed(2) })   }}
                      className={`${errorsCoApplicant.GrossMonthlyIncome ? "imputError" : "campo"}`}
                      placeholder="Gross Monthly Income"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                    />
                    {errorsCoApplicant.GrossMonthlyIncome && <p className="errorMessage">{errorsCoApplicant.GrossMonthlyIncome}</p>}
                </div>


                {/* NetIncome */}
                {/* <div className="mediowidth-right campoContainer">
                <label className="labelApplicationForm" >Net Income</label>
                    <input
                      type="number"
                      value={coApplicant.NetIncome}
                      name="NetIncome"
                      onChange={handleInputCoApplicant}
                      className={`${errorsCoApplicant.NetIncome ? "imputError" : "campo"}`}
                      placeholder="Net Income"
                      required
                      disabled={props.type === 'edit' ? "disabled" : null}
                    />
                    {errorsCoApplicant.NetIncome && <p className="errorMessage">{errorsCoApplicant.NetIncome}</p>}
                </div> */}


            </div>


             {/*   AQUI COMIENZA OTHER INCOMES---------------------------------------------------------------------------------------- */}

           <h3 className="applicationFormContainer-tittle">
              Other Monthly Income
              </h3>


             {/* INCOME LISTS */}
                  {
                incomeListCoApplicant.map((item, index)=>{
                return(
                <div className="incomeCard" key={index}>

                  {props.type === 'edit' ? 
                  null
                  :
                  <div className="mediowidth-right deleteIncome-icon_container">
                   <img src={iconDelete} className="deleteIncome-icon" onClick={()=>{deleteIncomeCoApplicant(item.Id)}}/>
                  </div>  
                  }

                        <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >Additional Income Source</label>
                        <select
                        value={item.AdditionalIncomeSource} 
                        name="AdditionalIncomeSource"
                        onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}} 
                        className={`${errorIncomesCoApplicant[index].AdditionalIncomeSource ? "imputError" : "campo"}`} 
                        placeholder="Additional Income Source"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                        >
                          <option value="disable" disabled selected>
                            Additional Income Source
                            </option>
                            <option value="Other">Other</option>
                            <option value="RetirementSSI">Retirement SSI</option>
                            <option value="Investments">Investments</option>
                            <option value="Rental">Rental</option>
                            </select>
                            
                            {errorIncomesCoApplicant[index].AdditionalIncomeSource && <p className="errorMessage">{errorIncomesCoApplicant[index].AdditionalIncomeSource}</p>}
                        </div>




                        <div className="mediowidth-right campoContainer">
                        <label className="labelApplicationForm" >Description</label>
                        <input
                        type="text"
                        value={item.Description}
                        name="Description"
                        onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}} 
                        className={`${errorIncomesCoApplicant[index].Description ? "imputError" : "campo"}`}
                        placeholder="Description"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                        />
                        {errorIncomesCoApplicant[index].Description && <p className="errorMessage">{errorIncomesCoApplicant[index].Description}</p>}
                        </div>



                        {/* <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >Source</label>
                        <input
                        type="text"
                        value={item.Source}
                        name="Source"
                        onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}} 
                        className={`${errorIncomesCoApplicant[index].Source ? "imputError" : "campo"}`}
                        placeholder="Source"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                        />
                        {errorIncomesCoApplicant[index].Source && <p className="errorMessage">{errorIncomesCoApplicant[index].Source}</p>}
                        </div> */}


                        <div className="mediowidth-left campoContainer">
                        <label className="labelApplicationForm" >Amount</label>
                        <input
                        type="number"
                        value={item.Amount}
                        name="Amount"
                        onChange={(event)=>{handleIncomeInputCoApplicant(event, item.Id)}}
                        className={`${errorIncomesCoApplicant[index].Amount ? "imputError" : "campo"}`}
                        placeholder="Amount"
                        required
                        disabled={props.type === 'edit' ? "disabled" : null}
                        />
                        {errorIncomesCoApplicant[index].Amount && <p className="errorMessage">{errorIncomesCoApplicant[index].Amount}</p>}
                        </div>
                        </div>
                        )
                        })
                        }




                        {props.type === 'edit' ? 
                        null
                        :
                        <button className="addIncome_button" onClick={addIncomeCoApplicant}>Add Income</button>
                      }

                        {/* <button className="addIncome_button" onClick={i}>Validar form</button> */}




            
            {/*   AQUI TERMINA OTHER INCOMES---------------------------------------------------------------------------------------- */}








            {/* <h3 className="applicationFormContainer-tittle">
            Other Information
            </h3>

            <div className="form">

           
            


            </div>  */}





            <div action="" id="form" className="form">
















            

            
           
              {props.type === 'edit' ? null
              :
              <button 
              className="mediowidth-left campo back"
              onClick={prevStep}
              >Back</button>
              }
              
              {props.type === 'edit' ? null
              :
              <input
                onClick={handleSubmitCoApplicant}
                type="submit"
                id="submit"
                className="mediowidth-right submit campo"
                placeholder="Save and continue"
                value="Continue"
                required
                
              />
              }

               
            </div>
          </div>

          : 

            props.type === 'edit' ?

            <div className="applicationFormContainer">
              
              <p className="applicationHasNotCoApplicant-message">This Application has no Co Applicant</p>

            </div>

            : null
          }

         
        </div>
        </>
      );
    case 3:
      return (
        <>
        <div className="tabsMainContainer">

        {props.type === 'create' && props.showFinancialEntityTitle === 'true'?
          <div className="applicationForm-titleFinancialEntityContainer">
            <p className="applicationForm_backButtonTitle" onClick={()=>{props.changeRender()}}> <img className="applicationForm_arrowBackIconTitle" src={iconArrowBack} alt="Back to detail view"/> <span>Cancel</span> </p>
            <p className="applicationForm_formtitle">Pinnacle Form</p>
          </div>
          : null
        }

        
        {props.type === 'create' ? stepsCounter()
        : tabs()
        }

        {isLoadingDetails ? 
          <div className="spinnerDetails"><Spinner
            size={25}
            color={"grey"}
            spinnerWidth={3}
            visible={true}
          /></div> : null}


        <div className="applicationFormContainer">
          <h3 className="applicationFormContainer-tittle">Loan Parameters</h3>

          {
            FIApplicationId ?
            <h3 className="applicationFormContainer-tittle">Application Number: {FIApplicationId}</h3>
            : null
          }
          

          <form action="" id="form" className="form">




          
            {/* SaleAmount */}
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Sale Amount</label>
                <input
                  type="number"
                  value={loan.SaleAmount}
                  name="SaleAmount"
                  onChange={handleInputLoan}
                  onBlur={()=> { setLoan({...loan, 'SaleAmount': parseFloat(loan.SaleAmount).toFixed(2) })   }}
                  className={`${errorsLoan.SaleAmount ? "imputError" : "campo"}`}
                  placeholder="Sale Amount"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsLoan.SaleAmount && <p className="errorMessage">{errorsLoan.SaleAmount}</p>}
            </div>
            

            {/* DownPayment */}
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Down Payment</label>
                <input
                  type="number"
                  value={loan.DownPayment}
                  name="DownPayment"
                  onChange={handleInputLoan}
                  onBlur={()=> { setLoan({...loan, 'DownPayment': parseFloat(loan.DownPayment).toFixed(2) })   }}
                  className={`${errorsLoan.DownPayment ? "imputError" : "campo"}`}
                  placeholder="Down Payment"
                  required
                  disabled={props.type === 'edit' ? "disabled" : null}
                />
                {errorsLoan.DownPayment && <p className="errorMessage">{errorsLoan.DownPayment}</p>}
            </div>


            {/* AmountFinanced */}
          
              <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Amount Financed</label>
                  <input
                    type="number"
                    value={loan.AmountFinanced || (parseFloat(loan.SaleAmount) - parseFloat(loan.DownPayment)).toFixed(2)}
                    name="AmountFinanced"
                    //onChange={handleInputLoan}
                    className={`${errorsLoan.AmountFinanced ? "imputError" : "campo"}`}
                    placeholder="Amount Financed"
                    required
                    //disabled={props.type === 'edit' ? "disabled" : null}
                    disabled = "disabled"
                  />
                  {errorsLoan.AmountFinanced && <p className="errorMessage">{errorsLoan.AmountFinanced}</p>}
              </div>

              {
              props.type === 'create' &&
              <div className={termsCheckBorderRed ?  "fullwidth termsContainerError" : "fullwidth termsContainer" }>
              <div className="checkCopyAddressInfo">
                <input type="checkbox" 
                      name="isActive" 
                      className="inputCopyAddressInfo" 
                      {...termsCheck ? { checked: "checked" } : null}
                      onClick={()=> setTermsCheck(!termsCheck)} /> 
                <label className="labelApplicationForm" > Applicant Authorization</label>
              </div> 
              <p>
              By checking the following box, the dealer warrants that all information provided is true and complete. The dealer guarantees that the applicant authorizes a full investigation and release of any records from any source, including credit bureau reports, to check the accuracy of information given and to determine whether the applicant's credit application is approved by the Dealer. The applicant understands no agreement exists until and unless the application is approved by the Dealer.              </p>

              </div>
            }
             
            
            



           
            {props.type === 'create' ? 
            <button 
            className="mediowidth-left campo back"
            onClick={prevStep}
            >Back</button>
            :null
            }
            
            {props.type === 'create' && termsCheck ?
            <input
              type="submit"
              id="submit"
              onClick={handleSubmitLoan}
              className="mediowidth-right submit campo"
              placeholder="Save and Send"
              value="Submit Application"
              required 
            />
            : props.type === 'create' && !termsCheck ?
            <input
              type="submit"
              id="submit"
              onClick={handleSubmitLoan}
              className="mediowidth-right submitDisabled campo"
              placeholder="Save and Send"
              value="Submit Application"
              required 
              disabled
            />

            :null
           }
          </form>



            


           





          {showNotificationOk  ? 
              props.updateId || props.hasOwnProperty('appData') && !props.goToListView ?
                <Notification {...isSafari && {flexRight : true}} type='popup' reload='true' link ="/applicants" title='The application was created successfully.' f={()=>{SetShowNotificationOk(false)}}>
                    <img src={iconCheck} alt="check" />
                </Notification> 
                : 
                <Notification {...isSafari && {flexRight : true}} type='popup' link ="/applicants" title='The application was created successfully.' f={()=>{SetShowNotificationOk(false)}}>
                    <img src={iconCheck} alt="check" />
                </Notification> 
            :
            null}

        {showNotificationError  ? 
          <Notification {...isSafari && {flexRight : true}}  errorResponse={errorResponse} error={true} type='popup' title='An error has occurred creating the application. Please verify the information and try again.' f={()=>{SetShowNotificationError(false)}}>
              <img src={iconSadFace} alt="check" />
          </Notification> : null}

        
        {showNotificationPinnacleParametersNotSeat  ? 
        <Notification {...isSafari && {flexRight : true}} type='popup' title='Loan Application saved but hasnt been sent since Pinnacle Parameters not properly set: API Key, Username and Password.' f={()=>{setShowNotificationPinnacleParametersNotSeat(false)}}>
            <img src={iconAdmiration} alt="check" />
        </Notification> : null}


        {showNotificationUserAlredyExists  ? 
          <Notification {...isSafari && {flexRight : true}}  errorResponse={errorResponse} error={true} type='popup' title='Another applicant already exists with the same Social Security Number.' f={()=>{setShowNotificationUserAlredyExists(false)}}>
              <img src={iconSadFace} alt="check" />
          </Notification> : null}



        {isLoading ? <SpinnerPopUp /> : null}


        </div>
        </div>
        </>
      );
      //PROPOSALS
      case 9:
        return(<>
          <div className="tabsMainContainer">{props.type === 'create' ? stepsCounter():tabs()}
            {
             ( allApplicationData.CustomerProposalId || createProposalAfterApplication) ?
              // HAS PROPOSAL
              <div>
                {allApplicationData.CustomerProposalId && <button className="proposalCard_editButton" onClick={()=> setEditProposal(!editProposal)}>Edit proposal</button>}
                {editProposal && <p className="proposalCard_editButton_message">The form is now editable</p>} 
                <ProposalForm 
                    allApplicationData={allApplicationData}
                    proposalId={allApplicationData.CustomerProposalId} 
                    inDetails={allApplicationData.CustomerProposalId ? true : false} 
                    type={(editProposal || createProposalAfterApplication) ? 'create' : "edit"}  
                    showPersonalInformation
                    editProposal={editProposal ? true : false}
                    createProposal={createProposalAfterApplication ? true : false}
                    addCustomerPropsalId
                    dontShowCreateAppButton
                    showCreatePdfButton={true}
                    showCustomerInformacion
                    createProposalAfterApplication={createProposalAfterApplication}
                    applicationId = {props.application_id}
                    SetReloadGetApplicationDetail={SetReloadGetApplicationDetail}
                />
              </div>
              :
              // DONT HAS PROPOSAL
              <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>      
                <p className="noProposalText">This application has no associated proposal</p>
                  <button className="button_secondary-green" onClick={()=> setCreateProposalAfterApplication(true)}>Create a new proposal</button>    
              </div>
              
            }
           


          </div>
        </>)
      //Status
      case 4:
      return(<>
              <div className="tabsMainContainer">
              
              {props.type === 'create' ? stepsCounter():tabs()}


                <div className="applicationFormContainer">

                <p className="applicationFormContainer-title-value">{`${props.application_id} - ${props.applicantName}`}</p>

                <div className="aquaStatusTab_MainValuesGrid">

                    <div>
                      <h4 className="applicationFormContainer-title">Status:</h4>
                      <p className="applicationFormContainer-title-value">{applicationStatus || '-'}</p>
                    </div>

                    <div>
                      
                          <h4 className="applicationFormContainer-title">Tier:</h4>
                          <p className="applicationFormContainer-title-value">{tierContract || '-'}</p>
                       
                    </div>

                    {
                     (hideBuyRate === 'true' && userRole === 'Sales Representative') ? null :
                      <div>
                          <h4 className="applicationFormContainer-title">Bid Percent:</h4>
                          <p className="applicationFormContainer-title-value">{bidPercentContract ? `${bidPercentContract}%` : '-'}</p>
                      </div>
                    }
                    


                    <div>
                      <h4 className="applicationFormContainer-title">Loan Program:</h4>
                      <p className="applicationFormContainer-title-value">{applicationStatusInfo.LoanProgram || '-'}</p>
                    </div>

                    <div>
                      <h4 className="applicationFormContainer-title">APR:</h4>
                      <p className="applicationFormContainer-title-value">{applicationStatusInfo.APR || '-'}</p>
                    </div>

                    <div>
                      <h4 className="applicationFormContainer-title">Loan Term:</h4>
                      <p className="applicationFormContainer-title-value">{applicationStatusInfo.LoanTerm || '-'}</p>
                    </div>

                    <div>
                      <h4 className="applicationFormContainer-title">Contract E-sign status:</h4>
                      <p className="applicationFormContainer-title-value">{applicationStatusInfo.ContractCompletionStatus || '-'}</p>
                    </div>

                </div>
               

                <h4 className="applicationFormContainer-title">Messages</h4>
                {
                  isLoadingGetStatus ?

                  <div className="statusLoaderContainer">
                    <SkeletonLoader width="100%" height="50px"/>
                    <SkeletonLoader width="100%" height="35px"/>
                    <SkeletonLoader width="100%" height="20px"/>
                    <SkeletonLoader width="100%" height="45px"/>
                  </div>

                  :

                  hasMessages ?                  
                    isDeallerAllyAdmin != 'true' && applicationStatus === 'Error' ?
                    null
                    :               
                                          
                    statusMessages.map((item, index) => 
                    (
                      <div className="cardMessage" key={index}>
                        <p className="statusMessage">{item.Text}</p>
                      </div>
                    ))
                                      
                         
                : 
                  <p className="applicationFormContainer-statusNotMessage">This app has no status messages available.</p>
                }



                <h4 className="applicationFormContainer-title margin-top30">Stipulations</h4>
                {
                  isLoadingGetStatus ? 
                  <div className="statusLoaderContainer">
                    <SkeletonLoader width="100%" height="50px"/>
                    <SkeletonLoader width="100%" height="35px"/>
                    <SkeletonLoader width="100%" height="20px"/>
                    <SkeletonLoader width="100%" height="45px"/>
                  </div>
                :
                  hasStipulations ?
                  <>
                  
                  {statusStipulations.map((item, index) => {
                    return (
                      <div className="cardMessage" key={index}>
                        <p className="statusMessage">{item.Text}</p>
                        <p className="statusMessage_date">{item.LastModifiedDate}</p>
                      </div>
                    );
                  })}
                  </>
                  :
                  <p className="applicationFormContainer-statusNotMessage">This app has no stipulations yet.</p>
                }


                {
                  applicationStatusInfo.BasicUser &&
                  <>
                    <h4 className="margin-top-40 applicationFormContainer-title">Creation info</h4>
                    <p className="aquaLoanHistory_card-message">{`Created by: ${applicationStatusInfo.BasicUser.FirstName} ${applicationStatusInfo.BasicUser.LastName}`}</p>
                    <p className="aquaLoanHistory_card-message">{`Created Date: ${applicationStatusInfo.CreatedDate}`}</p>
                    <p className="aquaLoanHistory_card-message">{`Updated Date: ${applicationStatusInfo.UpdatedDate}`}</p>
                  </>
                }


              </div></div>

         
          </>);
      case 10:
        return(
          <div className="tabsMainContainer">
          {tabs()}
          <PinnacleContract 
            applicationData={allApplicationData} 
            isLoadingDetails={isLoadingDetails} 
            application_id={props.application_id} 
            application_status={props.application_status} 
            setReloadGetContract={setReloadGetContract}
            applicantId={allApplicationData.ApplicantId}
            hasProposal={allApplicationData.CustomerProposalId}
            />
          </div>
        );
      //Documents
        case 6:
          return(
            <><div className="tabsMainContainer">
                {props.type === 'create' ? stepsCounter()
                : tabs()
                }

          {isLoadingDetails ? 
            <div className="spinnerDetails"><Spinner
                  size={25}
                  color={"grey"}
                  spinnerWidth={3}
                  visible={true}
                /></div> : null}

                <div className="applicationFormContainer">
                {/* <h2 className="tabTittle">Documents</h2> */}

                <div className="documentSection">

                <AlertBox 
                    showAlert={documentsShowAlert}
                    type={documentsAlertBox.type}
                    title={documentsAlertBox.title}
                    text={documentsAlertBox.text}
                />
                  <span className="documentSection-title">Upload Document</span><span className="labelApplicationForm">      * Only .pdf and images accepted</span>
                      <div className="fullwidth campoContainer">
                        <label className="labelApplicationForm" >Document Type</label>
                            <select
                            name="DocumentFileType"
                            onChange={handleDocumentForm}
                            className={`${errors.PrimaryPhoneType ? "imputError" : "campo"}`}
                            placeholder="File Type"
                            required
                            
                            >
                            <option value="disable" disabled selected>
                            Document type
                            </option>
                            <option value="CC">Completion Certificate (CC)</option>
                            <option value="ID">Identification (ID)</option>                  
                            <option value="PH">Proof Of Homeownership (PH)</option>
                            <option value="O">Other (O)</option>
                            
                            
                          
                            </select>
                            {errorsdocument.DocumentFileType && <p className="errorMessage">{errorsdocument.DocumentFileType}</p>}
                      </div>

                      <div className="choseDocumentSection">
                      <div className="selectFile">
                        <span className="labelSelectFile" >Choose</span>
                        <input 
                        type="file" 
                        className="documentChoseButtom" 
                        max-size="2000"
                        name="File"
                        onChange={handleDocumentForm} />
                        
                      </div>
                      {documentForm.File ? <span className="choseDocumentSection-span_filename">{documentForm.File.name}</span> : null}
                      </div>
                      {errorsdocument.File && <p className="errorMessage">{errorsdocument.File}</p>}


                      {isLoadingDetails ? <button disabled className="document-sendFile_button submit campo submitDisabled">Upload Document</button>                     
                      :
                      <button className="document-sendFile_button submit campo" onClick={handleSubmitDocument} >Upload Document</button>
                      }

                      {/* {showPostNotificationSucces ? 
                      <Notification title="The document was successfully uploaded" f={()=>{SetShowPostNotificationSucces(false)}}/>
                      :
                      null
                      }

                      {showNotificationDocumentUploadError &&
                      <Notification title={messageDocumentTypeError.title} text={messageDocumentTypeError.message} color="red" f={()=>{setShowNotificationDocumentUploadError(false)}}/>
                      }

                      {showNotificationTypeDocumentError ?
                      <Notification title="Type of document not accepted" text={messageDocumentTypeError} color="red" f={()=>{SetShowNotificationTypeDocumentError(false)}}/>
                      :
                      null
                      }
                      {showNotificationDocumentDeletedSuccessfully ?
                      <Notification title="Document deleted successfully" f={()=>{SetShowNotificationDocumentDeletedSuccessfully(false)}}/>
                      :
                      null
                      } */}

                     

                      </div>

                      <div className="documentSection">
                        <p className="documentSection-title">Uploaded Documents</p>




                        {hasdocuments ? 
                        
                        documentsList.map((item, index) => {
                          return(
                          <div className="document-card" key={index}>
                            <div className="document-card_info">
                              <span className="document-card_info-type document-card_info-span">{item.Type}</span>
                              <span className="document-card_info-fileName document-card_info-span">{item.Filename}</span>
                              <div className="document-card_info-idAndDdate">
                                <span className="document-card_info-id document-card_info-span">{item.Id}</span>
                                <span className="document-card_info-date document-card_info-span">{item.CreationDate}</span>
                              </div>
                              
                            </div>
                            <div className="document-card_button">
                              {isLoadingDetails ? 
                              <>
                                <button className="document-card_button-dowload">Download</button>
                                {
                                  isDeallerAllyAdmin === "true" ?
                                    <button className="document-card_button-delete">Delete</button>
                                  : null
                                }
                              </>
                                :
                                <>
                                <button className="document-card_button-dowload" onClick={()=>{dowloadDocument(item.Id, item.Filename)}}>Download</button>
                                {
                                  isDeallerAllyAdmin === "true" ?
                                  <button className="document-card_button-delete" onClick={()=>{deleteDocument(item.Id)}}>Delete</button>
                                  : null
                                }      
                                </>
                            }
                             
                            </div>
                          </div>
                          )
                          


                    })
                    
                      :
                      <p className="documentSection-title">This application has no documents</p>
                      
                      }
                    
                      </div>
                </div>
                </div>
            </>

          ); 
        case 7:
        case 8:
          return(  
            <>
            <div className="tabsMainContainer">
              {tabs()}

              {isLoadingDetails && 
              <div className="spinnerDetails">
                <Spinner
                    size={25}
                    color={"grey"}
                    spinnerWidth={3}
                    visible={true}
                  /></div>}


              <ExternalForm detailsId={'123'} appData={allApplicationData} type="edit" externalStep={step}/>
            </div>
              
            </>
          );   
  }
}

export default ApplicationFormPinnacle;
