import React, {useState, useContext} from 'react';
import '../assets/styles/Sidebar.css';
import {Link} from 'react-router-dom';
import logoicono from '../assets/images/logo-icono.jpg'
import dalogo from '../assets/images/dalogo.png'
import iconList from '../assets/images/iconList.png';
import iconUsers from '../assets/images/iconUsers.png';
import iconLibrary from '../assets/images/iconLibrary.png';
import iconLogoff from '../assets/images/iconLogoff.png';
import iconDealer from '../assets/images/iconDealer.png';
import iconSettingsMaterial from '../assets/images/iconSetingsMaterial.png';
import iconMenu from '../assets/images/iconMenu.png'
import iconExcel from '../assets/images/iconExcel.png'
import iconLogs from '../assets/images/iconLogs.png'
import iconProposalsWhite from '../assets/images/iconProposalsWhite.png'
import iconCommercialLoan from '../assets/images/iconCommercialLoan.png'
import Sheet from '../components/Sheet'
import {AuthContext} from '../AuthContext';
import Hint from "react-portal-hint";
import { Icon } from '@iconify/react';


const Sidebar = ()=>{

    const url = window.location.pathname;
    // console.log(url.includes('/iframe/'))

    const{logout} = useContext(AuthContext);

    const [showMobileMenu, setshowMobileMenu] = useState(false)

    //VALIDAR PERMISOS
    const cantReadAllUsers = localStorage.getItem("getUsersEndpoint");
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const userRole = localStorage.getItem('userRole');
    const canCreateProposal = window.localStorage.getItem("allow_customer_proposals");
    const canCreateReports = window.localStorage.getItem("allow_customer_reports");
    const canCreateCommercials = window.localStorage.getItem("canCreateCommercials");

    const showSheetHandler = ()=>{
        setshowMobileMenu(!showMobileMenu);

    }

    if(url.includes('/iframe/') ){
        return null
    }
    

return(
    
   
        <>
        {
            showMobileMenu ? 
            <Sheet handler={showSheetHandler} half="true">

                <div className="sidebar_mobileMenu-Container">
                    <div className="sidebar_mobileMenu-section">

                        <div className="sidebar_mobileMenu-item">
                            <Link to="/applicants" ><img src={iconList} className="sidebarIcon" alt="menu-icon"/></Link>
                            <Link to="/applicants" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Residential Applications</Link>
                        </div>

                        {canCreateCommercials === 'true' &&
                        <div className="sidebar_mobileMenu-item">
                            <Link to="/commercialapp" ><img src={iconList} className="sidebarIcon" alt="menu-icon"/></Link>
                            <Link to="/commercialapp" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Commercial Applications</Link>
                        </div>
                        }
                        

                        
                        
                        {isDeallerAllyAdmin === "true" ?
                        <div className="sidebar_mobileMenu-item">
                            <Link to="/dealers" ><img src={iconDealer} className="sidebarIcon"/></Link>
                            <Link to="/dealers" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Dealers</Link>
                        </div>
                        : null}

                        {cantReadAllUsers === 'can_read_all_users' || cantReadAllUsers === 'can_read_dealer_users' ?
                        <div className="sidebar_mobileMenu-item">
                            <Link to="/users" ><img src={iconUsers} className="sidebarIcon"/></Link>
                            <Link to="/users" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Users</Link>
                        </div>
                        : null}

                        <div className="sidebar_mobileMenu-item">
                            <Link to="/library" ><img src={iconLibrary} className="sidebarIcon"/></Link>
                            <Link to="/library" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Library</Link>
                        </div>

                        {
                            userRole === 'Manager' && canCreateProposal === 'true' && 
                            <div className="sidebar_mobileMenu-item">
                            <Link to="/proposals" ><img src={iconProposalsWhite} className="sidebarIcon"/></Link>
                            <Link to="/proposals" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Proposal Setup</Link>
                            </div>
                        }

                        {
                            userRole === 'Manager' && canCreateProposal === 'true' && 
                            <div className="sidebar_mobileMenu-item">
                            <Link to="/reports" ><img src={iconProposalsWhite} className="sidebarIcon"/></Link>
                            <Link to="/reports" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Reports</Link>
                            </div>
                        }

                        {isDeallerAllyAdmin === "true" ?
                            <div className="sidebar_mobileMenu-item">
                                <Link to="/logs" ><img src={iconLogs} className="sidebarIcon"/></Link>
                                <Link to="/logs" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Logs</Link>
                            </div>
                        : null}
                       

                    </div>

                    <div className="sidebar_mobileMenu-section">
                        <div className="sidebar_mobileMenu-item">
                        <Link to="/settings" ><img src={iconSettingsMaterial} className="sidebarIcon"/></Link>
                            <Link to="/settings" className="sidebar_mobileMenu-item-title" onClick={()=>{setshowMobileMenu(false)}}>Settings</Link>
                        </div>
                        <div className="sidebar_mobileMenu-item">
                            <img src={iconLogoff } onClick={logout} className="sidebarIcon"/>   
                            <p onClick={logout} className="sidebar_mobileMenu-item-title">Logout</p>
                        </div>
                       
                    </div>
                    

                </div>  
             </Sheet>
            : null
        }
       

        <div className="sidebar">
            <div className="sidebar-logo">
                <img src={dalogo} alt="logo" className='companylogo'/>
            </div>
                
            <div className="sidebar-menu">

              
                    <Link to="/applicants" >
                        <Hint content="Residential Applications" place="right">
                            <img src={iconList} className="sidebarIcon sidebarIcon_displayNone"/>
                        </Hint>
                    </Link>

                    {canCreateCommercials === 'true' &&
                        <Link to="/commercialapp" >
                            <Hint content="Commercial Applications" place="right">
                                <img src={iconCommercialLoan} className="sidebarIcon sidebarIcon_displayNone"/>
                            </Hint>
                        </Link>
                    }
                    

                    {/* <Link to="/commercialapp" >
                        <Hint content="Commercial Loan Applications" place="right">
                            <div>
                                <Icon width="30px" color="white" icon="tabler:brand-appgallery" style={{paddingRight: '5px'}} />
                            </div>
                        </Hint>   
                           
                    </Link> */}
               
               

              
                
                {isDeallerAllyAdmin === "true" &&
                    <Link to="/dealers" >
                        <Hint content="Dealers" place="right">
                          <img src={iconDealer} className="sidebarIcon sidebarIcon_displayNone"/>
                        </Hint>        
                    </Link>
                }
                

                {(cantReadAllUsers === 'can_read_all_users' || cantReadAllUsers === 'can_read_dealer_users') &&
                <Link to="/users" >
                    <Hint content="Users" place="right">
                        <img src={iconUsers} className="sidebarIcon sidebarIcon_displayNone"/>
                    </Hint>    
                </Link>
                }



                <Link to="/library" >
                    <Hint content="Resource Library" place="right">
                        <img src={iconLibrary} className="sidebarIcon sidebarIcon_displayNone"/>
                    </Hint>
                </Link>



                {
                    userRole === 'Manager' && canCreateProposal === 'true' && 
                    <Link to="/proposals" >
                        <Hint content="Proposal Setup" place="right">
                            <img src={iconProposalsWhite} className="sidebarIcon sidebarIcon_displayNone"/>
                        </Hint>  
                    </Link>
                }


                 
                <Link to="/reports" >
                    <Hint content="Reports" place="right">
                        <img src={iconExcel} className="sidebarIcon sidebarIcon_displayNone"/>
                    </Hint>        
                </Link>
                


                {isDeallerAllyAdmin === "true" &&
                    <Link to="/logs" >
                         <Hint content="Logs" place="right">
                            <img src={iconLogs} className="sidebarIcon sidebarIcon_displayNone"/>
                         </Hint>    
                    </Link>
                    
                }
              
            </div>

            <div className="sidebar-settings">

            <Link to="/settings" >
                <Hint content="User Settings" place="right">
                    <img src={iconSettingsMaterial} className="sidebarIcon sidebarIcon_displayNone"/>
                </Hint>      
            </Link>

            <div onClick={logout}>
                <Hint content="Logout" place="right" >
                    <img src={iconLogoff }  className="sidebarIcon sidebarIcon_displayNone"/>
                </Hint>
            </div>
           
            

            <img src={iconMenu } onClick={()=>{setshowMobileMenu(true)}} className="sidebarIconMenu"/>
          
            </div>       
        </div>
        </>
    
   

)}

export default Sidebar;