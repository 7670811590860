import React, { useState, useEffect, useContext } from 'react'
import "../../assets/styles/ApplicationForm.css";
import "../../assets/styles/StepCounter.css";
import "../../assets/styles/ExternalForm.css";
import { AuthContext } from '../../AuthContext';
import Spinner from 'react-spinner-material';
import useIsSafari from '../../hooks/useIsSafari'
import moment from 'moment-timezone';
import AquaFinanceContractStatusTab from './AquaFinanceContractStatusTab';
import IconLinkOutSide from '../../assets/images/iconLinkOutSide.png'
import AquaPromotionCard from './AquaPromotionCard';
import AquaResendContractButton from './AquaResendContractButton';
import NumberFormat from "react-number-format";
import Button from "../general/Button"


const AquaFinanceContractSigningContractForm = (props) => {

  
  //ESTADOS

  const { uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [showStatusTab, setShowStatusTab] = useState(false)

  const isSafari = useIsSafari()




  const [includeTax, setIncludeTax] = useState(false);
  const [changeInputTax, setChangeInputTax] = useState(false);


  const [applicationDetails, setApplicationDetails] = useState({})

  const [errorChoosePromotion, setErrorChoosePromotion] = useState(false)




  //ESTADOS DE CONTRACT
  const [contract, setContract] = useState({})
  const [contractErrors, setContractErrors] = useState({})
  const [isSubmittingContract, setIsSubmittingContract] = useState(false);
  const [editContract, setEditContract] = useState(false);
  const [loadingPostContract, setLoadingPostContract] = useState(false);
  const [contractCompletionStatus, setContractCompletionStatus] = useState(false)
  const [refreshStatusContract, setRefreshStatusContract] = useState(false)
  const [sendingContract, setSendingContract] = useState(false)
  const [contractType, setContractType] = useState('Email')

  const [sendACHToo, setSendACHToo] = useState(true)
  const [ACHForm, setACHForm] = useState({})
  const [validateACH, setValidateACH] = useState(false)
  const [ACHErrors, setACHErrors] = useState([])
  const [isSubmitionTwoContracts, setIsSubmitionTwoContracts] = useState(false)
  const [sendingTwoFormsStatus, setSendingTwoFormsStatus] = useState('')
  const [loadingCopyProposal, setLoadingCopyProposal] = useState(false)




  const [showNotificationOk, SetShowNotificationOk] = useState(false);
  const [showNotificationError, SetShowNotificationError] = useState(false);
  const [showNotificationApplicantAlreadyExists, setShowNotificationApplicantAlreadyExists] = useState(false)


  //PROMOTIONS
  const [hasPromotion, SetHasPromotion] = useState(false)
  const [promotionSaved, setPromotionSaved] = useState({});
  const [promotionList, SetPromotionList] = useState({});
  const [promotionId, setPromotionId] = useState()
  const [noPromotion, setNoPromotion] = useState(false)
  const [loadingPromotions, setLoadingPromotions] = useState(false)
  const [amountFinancedInitial, setAmountFinancedInitial] = useState()


  const URI_X_API_KEY_I = uri.URI_X_API_KEY;
  const token = window.localStorage.getItem('token');
  const user = window.localStorage.getItem('user');








  useEffect(() => {
    if(Object.keys(props.applicationData).length > 0){
      fillContractData(props.applicationData)
    }
  }, [props.applicationData])





  //RELLENAR FORMULARIO DE CONTRACT
  const fillContractData = (data) => {
    //console.log(data)

    setApplicationDetails(data)
    setAmountFinancedInitial(data.LoanParameters.AmountFinanced)




    // if (!data.SigningContractStatus && (data.LoanApplicationStatus === 'Approved' || data.LoanApplicationStatus === 'Received' || data.LoanApplicationStatus === 'PendingDocs' || data.LoanApplicationStatus === 'ConditionallyApproved' || data.LoanApplicationStatus === 'Problem' || data.LoanApplicationStatus === 'Pending')) {
    if (!data.SigningContractStatus && data.LoanApplicationStatus !== 'FundingComplete' && data.LoanApplicationStatus !== 'Processing' ) {
      setEditContract(true)
    } else if ((data.SigningContractStatus === 'Pending' || data.SigningContractStatus === 'Signed') && data.LoanApplicationStatus !== 'FundingComplete' && data.LoanApplicationStatus !== 'FundingComplete') {
      setEditContract(false)
      setContractCompletionStatus(true)
    }

    //set promotion
    if(data.Promotion){
      console.log(data.Promotion)
      SetHasPromotion(true)
      // setPromotionId(data.Promotion.PromotionId)
      setPromotionSaved(data.Promotion)
      
    }



    let f = new Date();
    let d = new Date();
    d.setDate(d.getDate() + 30);
    //console.log(str1.padStart(2, '0'));

    const contractData = {
      ApplicantFirstName: data.Applicant.FirstName || '',
      ApplicantLastName: data.Applicant.LastName || '',
      ApplicantEmail: data.Applicant.EmailAddress || '',
      ApplicantAddress: data.Applicant.Address1 || '',
      ApplicantCity: data.Applicant.City || '',
      ApplicantState: data.Applicant.State || '',
      ApplicantZipCode: data.Applicant.ZipCode || data.Applicant.PostalCode || '',
      CellPhone: data.Applicant.CellPhone,

      

      ContractLanguage: data.LoanParameters.ContractLanguage,


      SellingPrice: data.LoanParameters.RemittanceUpdateFlag ? null : parseFloat(data.LoanParameters.SellingPrice).toFixed(2),
      DownPayment: data.LoanParameters.RemittanceUpdateFlag ? null : parseFloat(data.LoanParameters.DownPayment).toFixed(2),
      SalesTaxIncluded: data.LoanParameters.RemittanceUpdateFlag ? false : data.LoanParameters.SalesTaxIncluded,
      SalesTax: data.LoanParameters.SalesTax ? parseFloat(data.LoanParameters.SalesTax).toFixed(2) : parseFloat(0.00).toFixed(2),
      AmountFinanced: parseFloat(data.LoanParameters.AmountFinanced).toFixed(2),
      CompletionDate: data.LoanParameters.RemittanceUpdateFlag ? null : data.LoanParameters.CompletionDate,
      LoanDate: data.LoanParameters.LoanDate,


      BankAccountNumber: data.LoanParameters.BankAccountNumber ? data.LoanParameters.BankAccountNumber : null,
      BankAccountType: data.LoanParameters.BankAccountType ? data.LoanParameters.BankAccountType : null,
      BankRoutingNumber: data.LoanParameters.BankRoutingNumber ? data.LoanParameters.BankRoutingNumber : null,


    }

    if(data.Applicant.IdentificationType){
      contractData.IdentificationType= data.Applicant.IdentificationType
      contractData.IsIssueDatePresent= data.Applicant.IsIssueDatePresent
      contractData.IsIssueStatePresent = data.Applicant.IsIssueStatePresent
      contractData.IdentificationNumber= data.Applicant.IdentificationNumber
      contractData.IdentificationIssueState= data.Applicant.IdentificationIssueState
      contractData.IdentificationIssuedDate= data.Applicant.IdentificationIssuedDate
      contractData.IdentificationExpirationDate= data.Applicant.IdentificationExpirationDate
      contractData.ExpirationDateUnavailable= data.Applicant.ExpirationDateUnavailable
    }

    if (data.hasOwnProperty('CoApplicant')) {
      contractData.CoApplicantFirstName = data.CoApplicant.FirstName || ''
      contractData.CoApplicantLastName = data.CoApplicant.LastName || ''
      contractData.CoApplicantEmail = data.CoApplicant.EmailAddress || ''
      contractData.CoApplicantPhone = data.CoApplicant.CellPhone || ''
      //contractData.CoApplicantPrimaryPhoneType = data.CoApplicant.PrimaryPhoneType || ''

      if(data.CoApplicant.IdentificationType){
        contractData.CoIdentificationType= data.CoApplicant.IdentificationType
        contractData.CoIsIssueDatePresent= data.CoApplicant.IsIssueDatePresent
        contractData.CoIsIssueStatePresent = data.CoApplicant.IsIssueStatePresent
        contractData.CoIdentificationNumber= data.CoApplicant.IdentificationNumber
        contractData.CoIdentificationIssueState= data.CoApplicant.IdentificationIssueState
        contractData.CoIdentificationIssuedDate= data.CoApplicant.IdentificationIssuedDate
        contractData.CoIdentificationExpirationDate= data.CoApplicant.IdentificationExpirationDate
        contractData.CoExpirationDateUnavailable= data.CoApplicant.ExpirationDateUnavailable
      }
    }




    setContract({ ...contract, ...contractData })


    //LOAN PARAMETERS
    if (data.hasOwnProperty('LoanParameters')) {
      if (data.LoanParameters.SalesTaxIncluded !== null && (data.LoanParameters.SalesTaxIncluded === 'true' || data.LoanParameters.SalesTaxIncluded === true)) {
        setIncludeTax(true);
      }
    }


 

  } //end






  //SUBMIT SIGNING CONTRACT
  const postContract = (sendACH) => {

    setLoadingPostContract(true);

    if (sendACH) {
      setSendingTwoFormsStatus("sendingSigning")
    }


    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}signing-contract/${applicationDetails.ApplicationId}`
    // console.log(URI)  


    const obj = {
      Applicant: {
        EmailAddress: contract.ApplicantEmail,
        CellPhone: contract.CellPhone,
        Address1: contract.ApplicantAddress,
        State: contract.ApplicantState,
        City: contract.ApplicantCity,
        PostalCode: contract.ApplicantZipCode,
        FirstName: contract.ApplicantFirstName,
        LastName: contract.ApplicantLastName,
        IdentificationType: contract.IdentificationType,
        IsIssueDatePresent: contract.IsIssueDatePresent,
        IsIssueStatePresent : contract.IsIssueStatePresent, 
        IdentificationNumber: contract.IdentificationNumber,
        IdentificationIssueState: contract.IdentificationIssueState,
        IdentificationIssuedDate: contract.IdentificationIssuedDate,
        IdentificationExpirationDate: contract.IdentificationExpirationDate,
        ExpirationDateUnavailable: contract.ExpirationDateUnavailable
      },
      CoApplicant: {
        EmailAddress: contract.CoApplicantEmail,
        CellPhone: contract.CoApplicantPhone,
        FirstName: contract.CoApplicantFirstName,
        LastName: contract.CoApplicantLastName,
        IdentificationType: contract.CoIdentificationType,
        IsIssueDatePresent: contract.CoIsIssueDatePresent,
        IsIssueStatePresent : contract.CoIsIssueStatePresent, 
        IdentificationNumber: contract.CoIdentificationNumber,
        IdentificationIssueState: contract.CoIdentificationIssueState,
        IdentificationIssuedDate: contract.CoIdentificationIssuedDate,
        IdentificationExpirationDate: contract.CoIdentificationExpirationDate,
        ExpirationDateUnavailable: contract.CoExpirationDateUnavailable
      },
      LoanParameters: {
        SellingPrice: contract.SellingPrice,
        DownPayment: contract.DownPayment,
        SalesTaxIncluded: contract.SalesTaxIncluded,
        SalesTax: contract.SalesTax,
        AmountFinanced: contract.AmountFinanced,
        CompletionDate: contract.CompletionDate,
        PromotionId: promotionId,
        ContractLanguage: contract.ContractLanguage
      },
      SignatureType: contractType,
    }

    if (sendACHToo) {
      obj.ACHInformation = {
        BankAccountNumber: contract.BankAccountNumber,
        BankAccountType: contract.BankAccountType,
        BankRoutingNumber: contract.BankRoutingNumber,
      }
    }


    if(obj.Applicant.IdentificationType === 'Unavailable'){
      delete obj.Applicant.IdentificationNumber;
      delete obj.Applicant.IsIssueDatePresent;
      delete obj.Applicant.IsIssueStatePresent;
      delete obj.Applicant.IdentificationIssueState;
      delete obj.Applicant.IdentificationIssuedDate;
      delete obj.Applicant.IdentificationExpirationDate; 
      delete obj.Applicant.ExpirationDateUnavailable; 
  }else if(obj.Applicant.IdentificationType === 'PermanentResidencyCard' ){
    obj.Applicant.IsIssueDatePresent = 'true'
      delete obj.Applicant.ExpirationDateUnavailable; 
      delete obj.Applicant.IdentificationIssueState
      delete obj.Applicant.IsIssueStatePresent
  }
  else if(obj.Applicant.IdentificationType === 'UnitedStatesMilitaryId'){
      delete obj.Applicant.IsIssueStatePresent
      delete obj.Applicant.IdentificationIssueState
      if(obj.Applicant.ExpirationDateUnavailable === 'true'){
          delete obj.Applicant.IdentificationExpirationDate
      }
  }else if(obj.Applicant.IdentificationType === 'UnitedStatesPassport'){
      delete obj.Applicant.IdentificationIssueState
      delete obj.Applicant.IsIssueStatePresent
      delete obj.Applicant.ExpirationDateUnavailable;
  }else if(obj.Applicant.IdentificationType === 'DriversLicense'){
    delete obj.Applicant.ExpirationDateUnavailable;
  }


  if(obj.Applicant.IsIssueDatePresent === 'false'){
      delete obj.Applicant.IdentificationIssuedDate
  }

  if(obj.Applicant.IsIssueStatePresent === 'false'){
      delete obj.Applicant.IdentificationIssueState
  }

  if(obj.Applicant.ExpirationDateUnavailable === 'true'){
      delete obj.Applicant.IdentificationExpirationDate
  }

  // delete obj.Applicant.ExpirationDateUnavailable






  if(obj.CoApplicant.EmailAddress ){
    if(obj.CoApplicant.IdentificationType === 'Unavailable'){
        delete obj.CoApplicant.IdentificationNumber;
        delete obj.CoApplicant.IsIssueDatePresent;
        delete obj.CoApplicant.IsIssueStatePresent;
        delete obj.CoApplicant.IdentificationIssueState;
        delete obj.CoApplicant.IdentificationIssuedDate;
        delete obj.CoApplicant.IdentificationExpirationDate; 
        delete obj.CoApplicant.ExpirationDateUnavailable; 
    }else if(obj.CoApplicant.IdentificationType === 'PermanentResidencyCard' ){
        obj.CoApplicant.IsIssueDatePresent = 'true'
        delete obj.CoApplicant.ExpirationDateUnavailable; 
        delete obj.CoApplicant.IdentificationIssueState
        delete obj.CoApplicant.IsIssueStatePresent
    }
    else if(obj.CoApplicant.IdentificationType === 'UnitedStatesMilitaryId'){
        delete obj.CoApplicant.IsIssueStatePresent
        delete obj.CoApplicant.IdentificationIssueState
        if(obj.CoApplicant.ExpirationDateUnavailable === 'true'){
            delete obj.CoApplicant.IdentificationExpirationDate
        }
    }else if(obj.CoApplicant.IdentificationType === 'UnitedStatesPassport'){
        delete obj.CoApplicant.IdentificationIssueState
        delete obj.CoApplicant.IsIssueStatePresent
        delete obj.CoApplicant.ExpirationDateUnavailable;
    }else if(obj.CoApplicant.IdentificationType === 'DriversLicense'){
      delete obj.CoApplicant.ExpirationDateUnavailable; 
    }



    if(obj.CoApplicant.IsIssueDatePresent === 'false'){
        delete obj.CoApplicant.IdentificationIssuedDate
    }

    if(obj.CoApplicant.IsIssueStatePresent === 'false'){
        delete obj.CoApplicant.IdentificationIssueState
    }

    if(obj.CoApplicant.ExpirationDateUnavailable === 'true'){
        delete obj.CoApplicant.IdentificationExpirationDate
    }

    // delete obj.CoApplicant.ExpirationDateUnavailable
  
}

   


    console.log(obj)

    // console.log(JSON.stringify(obj))


    window.fetch(URI, {
      method: 'POST',
      headers: {
        //'Access-Control-Request-Headers': 'application/json',           
        'Content-Type': 'application/json',
        'username': user,
        'Authorization': token,
        'x-api-key': URI_X_API_KEY_I,
      },
      body: JSON.stringify(obj),

    }).then(response => response.json())
      .then(datos => {
        setLoadingPostContract(false)


        console.log(datos);

        if (datos.status === 'AQUAFINANCE_API_ERROR') {
          SetShowNotificationError(true)
        } else if (datos.status === 'SUCCESS') {
          SetShowNotificationError(false)
          setPromotionId(null)
          const container = document.getElementById('shetContainer')
          // container.scrollTo(0, 0)
          setRefreshStatusContract(!refreshStatusContract)
          props.SetReloadGetApplicationDetail(reloadGetApplicationDetail => !reloadGetApplicationDetail)




        } else {
          SetShowNotificationError(true)
        }


      }).catch((error) => {
        console.log(error)
        setLoadingPostContract(false)
        SetShowNotificationError(true)
      })

  }; //end





  //HANDLE INPUT SIGNING CONTRACT
  const handleInputContract = (event, inputName) => {
    let value = event.target && event.target.value;


    if (event.target && event.target.name === "SalesTaxIncluded") {
      if (value === "true") {
        setIncludeTax(true);

        setChangeInputTax(!changeInputTax);
        value = value
      } else if (value === "false") {

        setIncludeTax(false);

        setChangeInputTax(!changeInputTax)
        value = value

      }
    } else if(event.target && event.target.name === "IdentificationType"){
      setContract({
        ...contract,
        IdentificationType: value,
        IsIssueDatePresent: 'false',
        IsIssueStatePresent : 'false', 
        IdentificationNumber: '',
        IdentificationIssueState: null,
        IdentificationIssuedDate: '',
        IdentificationExpirationDate: '',
        ExpirationDateUnavailable: 'true'
      })
      return
    }else if(event.target && event.target.name === "CoIdentificationType"){
      setContract({
        ...contract,
        CoIdentificationType: value,
        CoIsIssueDatePresent: 'false',
        CoIsIssueStatePresent : 'false', 
        CoIdentificationNumber: '',
        CoIdentificationIssueState: null,
        CoIdentificationIssuedDate: '',
        CoIdentificationExpirationDate: '',
        CoExpirationDateUnavailable: 'true'
      })
      return
    }
    
    else if (event.target && event.target.name === "SellingPrice") {
      setChangeInputTax(!changeInputTax)
      value = value
    } else if (event.target && event.target.name === "DownPayment") {
      setChangeInputTax(!changeInputTax)
      value = value
    } else if (event.target && event.target.name === "SalesTax") {
      setChangeInputTax(!changeInputTax)
      value = value
    }

    if (inputName === "CellPhone" || inputName === "CoApplicantPhone") {
      setContract({
            ...contract,
            [inputName]: event.value,
        })
    }else{
      setContract({
            ...contract,
            [event.target.name]: value,
        })
    }




  }; //end







  //HANDLE SUBMIT CONSTRACT
  const handleSubmitContract = event => {
    event.preventDefault();
    //handle errors
    setContractErrors(validateFormContract(contract));
    setIsSubmittingContract(true);
    setSendingContract(!sendingContract)

  }; //end    






  //USEEFFECT SIGNING
  useEffect(() => {

    if (Object.keys(contractErrors).length === 0 && isSubmittingContract ) {
      postContract()

    } else{
      console.log(contractErrors)
    }
  }, [isSubmittingContract, sendingContract]);





  //validate contract
  const validateFormContract = (applicant) => {
    let errorList = {};


    if(!promotionId){
      errorList.PromotionId = 'Please select a promotion'
    }

    if(loadingPromotions){
      errorList.PromotionId = 'Please wait until you get the updated promotions'
    }


    //FirstName - text
    if (!applicant.ApplicantFirstName) {
      errorList.ApplicantFirstName = "Please enter a name";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.ApplicantFirstName)) {
      errorList.ApplicantFirstName = "The name can only contain letters";
    }


    //LastName - text
    if (!applicant.ApplicantLastName) {
      errorList.ApplicantLastName = "Please enter a lastname";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.ApplicantLastName)) {
      errorList.ApplicantLastName = "The lastname can only contain letters";
    }


    //EmailAddress *
    if (!applicant.ApplicantEmail) {
      errorList.ApplicantEmail = "Please enter a email";
    } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.ApplicantEmail)) {
      errorList.ApplicantEmail = "Please enter a valid email";
    }


    if (contract.hasOwnProperty('CoApplicantFirstName')) {
        //CoApplicantFirstName - text
        if (!applicant.CoApplicantFirstName) {
          errorList.CoApplicantFirstName = "Please enter a name";
        } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(applicant.CoApplicantFirstName)) {
          errorList.CoApplicantFirstName = "The name can only contain letters";
        }

        //CoApplicantLastName - text
        if (!applicant.CoApplicantLastName) {
          errorList.CoApplicantLastName = "Please enter a lastname";
        } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(applicant.CoApplicantLastName)) {
          errorList.CoApplicantLastName = "The lastname can only contain letters";
        }
    }


    



    //CoApplicantEmail *
    if (contract.hasOwnProperty('CoApplicantFirstName')) {
      if (!applicant.CoApplicantEmail) {
        errorList.CoApplicantEmail = "Please enter a email";
      } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(applicant.CoApplicantEmail)) {
        errorList.CoApplicantEmail = "Please enter a valid email";
      }
    }


    //CellPhone - number
    if (!applicant.CellPhone) {
      errorList.CellPhone = "Please enter a primary phone";
    } else if (!/^[0-9]{10}$/.test(applicant.CellPhone)) {
      errorList.CellPhone = "The CellPhone must have 10 numbers";
    } else if (applicant.CellPhone === '0000000000') {
      errorList.CellPhone = "Please enter a valid phone number";
    } else if (applicant.CellPhone[0] === "1" || applicant.CellPhone[0] === "0") {
      errorList.CellPhone = "Phone number must not start with 0 or 1";
    }


    //Address1 - text *
    if (!applicant.ApplicantAddress) {
      errorList.ApplicantAddress = "Please enter a address";
    }

    //City - text *
    if (!applicant.ApplicantCity) {
      errorList.ApplicantCity = "Please enter a city";
    } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ-\s\.]+$/.test(applicant.ApplicantCity)) {
      errorList.ApplicantCity = "The city can only contain letters";
    }

    //State - select *
    if (!applicant.ApplicantState) {
      errorList.ApplicantState = "Please select an option";
    } else if (applicant.ApplicantState === "State") {
      errorList.ApplicantState = "Please select an option";
    }

    //PostalCode - number *
    if (!applicant.ApplicantZipCode) {
      errorList.ApplicantZipCode = "Please enter a postalCode";
    } else if (!/^[0-9]{5}$/.test(applicant.ApplicantZipCode)) {
      errorList.ApplicantZipCode = "The zip code must have 5 numbers";
    }


    //ContractLanguage
    if (!applicant.ContractLanguage) {
      errorList.ContractLanguage = "Please select an option";
    }


   




    //PrimaryPhone - number
    if (contract.hasOwnProperty('CoApplicantFirstName')) {
      if (!applicant.CoApplicantPhone) {
        errorList.CoApplicantPhone = "Please enter a primary phone";
      } else if (!/^[0-9]{10}$/.test(applicant.CoApplicantPhone)) {
        errorList.CoApplicantPhone = "The primary phone must have 10 numbers";
      } else if (applicant.CoApplicantPhone === '0000000000') {
        errorList.CoApplicantPhone = "Please enter a valid phone number";
      } else if (applicant.CoApplicantPhone[0] === '0' || applicant.CoApplicantPhone[0] === '1') {
        errorList.CoApplicantPhone = "The phone number cannot start with 0 or 1";
      } else if (applicant.CoApplicantPhone[0] === "1" || applicant.CoApplicantPhone[0] === "0") {
        errorList.CoApplicantPhone = "Phone number must not start with 0 or 1";
      }
    }






    //salesTaxincluded - select
    if (!contract.SalesTaxIncluded && !contract.SalesTaxIncluded === false) {
      errorList.SalesTaxIncluded = "Please select an option";
    } else if (contract.PaymentTerm === "Sales Tax Included") {
      errorList.SalesTaxIncluded = "Please select an option";
    }

    //salesTax - select

    if (contract.SalesTaxIncluded === 'true') {
      if (!contract.SalesTax) {
        errorList.SalesTax = "Please enter a value";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(contract.SalesTax)) {
        errorList.SalesTax = "The value can only contain numbers";
      }
    }




    //SellingPrice - number
    if (!contract.SellingPrice) {
      errorList.SellingPrice = "Please enter a value";
    } else if (!/^[0-9]+\.?[0-9]*$/.test(contract.SellingPrice)) {
      errorList.SellingPrice = "The value can only contain numbers";
    }

    //DownPayment - number / si es igual a 0 no se envia
    if (!contract.DownPayment && contract.DownPayment !== 0 && contract.DownPayment !== '0') {
      errorList.DownPayment = "Please enter a value";
    } else if (!/^[0-9]+\.?[0-9]*$/.test(contract.DownPayment)) {
      errorList.DownPayment = "The value can only contain numbers";
    }

    //AmountFinanced - number
    if (contract.AmountFinanced < 1000) {
      errorList.AmountFinanced = "Minimum Amount Finances is $1000";
    } 
    // else if (applicationDetails.Applicant.ResidenceType === 'Rent') {
    //   if (contract.AmountFinanced > 5000) {
    //     errorList.AmountFinanced = "AmountFinanced must be less than or equal to $5000";
    //   }
    // }




    if (!contract.CompletionDate) {
      errorList.CompletionDate = "Please select a date";
    } else if (!contract.CompletionDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
      if (isSafari) {
        errorList.CompletionDate = "The format must be yyyy-mm-dd";
      } else {
        errorList.CompletionDate = "The format must be dd/mm/yyyy";
      }
    } else if (contract.CompletionDate) {
      let dob2 = new Date(contract.CompletionDate);
      const creation = applicationDetails.CreationDate.substring(0, 10)
      const creationDate = new Date(creation);

      const localTime = new Date().getTime()
      const completionDate = moment.tz(contract.CompletionDate + " 00:00", "America/Chicago")
      const today00 = moment(localTime).tz('America/Chicago').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if ((completionDate.isBefore(today00))) {
        errorList.CompletionDate = "The date must be today or in the future";
      }

    }



    if (sendACHToo) {

      //BankAccountType
      if (!applicant.BankAccountType) {
        errorList.BankAccountType = "Please select an option";
      }


      //BankRoutingNumber *
      if (!applicant.BankRoutingNumber && applicant.BankRoutingNumber !== '') {
        errorList.BankRoutingNumber = "Please enter a value";
      } else if (!/^[0-9]{9}$/.test(applicant.BankRoutingNumber)) {
        errorList.BankRoutingNumber = "The Bank Routing Number must have 9 numbers";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankRoutingNumber)) {
        errorList.BankRoutingNumber = "The value can only contain numbers";
      }

      //BankAccountNumber *
      if (!applicant.BankAccountNumber && applicant.BankAccountNumber !== '') {
        errorList.BankAccountNumber = "Please enter a value";
      } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.BankAccountNumber)) {
        errorList.BankAccountNumber = "The value can only contain numbers";
      }


    }


              if (!applicant.IdentificationType) {
                errorList.IdentificationType = "Please select an option";
            }

            //IdentificationType - select
            if (applicant.IdentificationType) {

              //IdentificationNumber - text
              if (applicant.IdentificationType === "Unavailable") {
                  //console.log('ok')
              } else {
                  if (!applicant.IdentificationNumber) {
                      errorList.IdentificationNumber = "Please enter an identification number";
                  } else if(applicant.IdentificationNumber && applicant.IdentificationNumber.length > 20){
                      errorList.IdentificationNumber = "The identification number must be less than 20 characters";
                  }
                  else if (!/^[a-zA-Z0-9,-\s]*$/.test(applicant.IdentificationNumber)) {
                      errorList.IdentificationNumber = "The value can only contain numbers, letters, spaces, or dashes";
                  }
  
  
              }
  
  
  
              if (applicant.IdentificationType === "Unavailable" || applicant.IsIssueDatePresent === 'false' || applicant.IsIssueDatePresent === false) {
                  //console.log('ok')
              } else {
                  //IdentificationIssuedDate - date
                  if (!applicant.IdentificationIssuedDate) {
                      errorList.IdentificationIssuedDate = "Please enter a date";
                  } else if (!applicant.IdentificationIssuedDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
                      if (isSafari) {
                          errorList.IdentificationIssuedDate = "The format must be yyyy-mm-dd";
                      } else {
                          errorList.IdentificationIssuedDate = "The format must be dd/mm/yyyy";
                      }
                  } else if (applicant.IdentificationIssuedDate) {
                      let dob2 = new Date(applicant.IdentificationIssuedDate);
                      const nowDate = new Date();
                      if (nowDate < dob2) {
                          errorList.IdentificationIssuedDate = "The date must be in the past";
                      }
                  }
              }
  
  
  
  
              //IdentificationExpirationDate - date
             
              if ((applicant.IdentificationType === "Unavailable" || applicant.ExpirationDateUnavailable == true) ) {
              
              } else {
                  if (!applicant.IdentificationExpirationDate) {
                      errorList.IdentificationExpirationDate = "Please enter a date";
                  } else if (!applicant.IdentificationExpirationDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
                      if (isSafari) {
                          errorList.IdentificationExpirationDate = "The format must be yyyy-mm-dd";
                      } else {
                          errorList.IdentificationExpirationDate = "The format must be dd/mm/yyyy";
                      }
                  } else if (applicant.IdentificationExpirationDate) {
                      let dob3 = new Date(applicant.IdentificationExpirationDate);
                      const nowDate = new Date();
                      if (nowDate > dob3) {
                          errorList.IdentificationExpirationDate = "The date must be in the future";
                      }
                  }
              }

             
  
  
  
  
  
             
  
  
              //IsIssueDatePresent
              if (applicant.IdentificationType === "Unavailable" || applicant.IdentificationType === "PermanentResidencyCard") {
                  //console.log('ok')
              } else {
                  if (props.application_id) {
                      if ((!applicant.IsIssueDatePresent && !applicant.IsIssueDatePresent === false) || applicant.IsIssueDatePresent === 'disable') {
                          errorList.IsIssueDatePresent = "Please select an option";
                      }
                  } else if (applicant.IsIssueDatePresent === null || applicant.IsIssueDatePresent === undefined || applicant.IsIssueDatePresent === 'disable') {
                      errorList.IsIssueDatePresent = "Please select an option";
                  }
  
              }
  
              //IsIssueStatePresent
              if (applicant.IdentificationType === "Unavailable" || applicant.IdentificationType === "UnitedStatesPassport" || applicant.IdentificationType === "PermanentResidencyCard" || applicant.IdentificationType === "UnitedStatesMilitaryId") {
                  //console.log('ok')
              } else {
                  if (props.application_id) {
                      if ((!applicant.IsIssueStatePresent && !applicant.IsIssueStatePresent === false) || applicant.IsIssueStatePresent === 'disable') {
                          errorList.IsIssueStatePresent = "Please select an option";
                      }
                  } else if (applicant.IsIssueStatePresent === null || applicant.IsIssueStatePresent === undefined || applicant.IsIssueStatePresent === 'disable') {
                      errorList.IsIssueStatePresent = "Please select an option";
                  }
              }
  
  
  
  
              if (applicant.IdentificationType === "Unavailable" || applicant.IdentificationType === "UnitedStatesPassport" || applicant.IdentificationType === "PermanentResidencyCard"  || applicant.IdentificationType === "UnitedStatesMilitaryId" || applicant.IsIssueStatePresent === 'false' || applicant.IsIssueStatePresent === false) {
                  //console.log('ok')
              } else {
                  //IdentificationIssueState - select
  
                  if (!applicant.IdentificationIssueState) {
                      errorList.IdentificationIssueState = "Please select an option";
                  } else if (
                    applicant.IdentificationIssueState === "Identification Issue State"
                  ) {
                      errorList.IdentificationIssueState = "Please select an option";
                  }
  
  
              }
  
  
              //ExpirationDateUnavailable
              if (applicant.IdentificationType === "Unavailable" || applicant.IdentificationType === "DriversLicense" ||  applicant.IdentificationType === "UnitedStatesPassport" || applicant.IdentificationType === "PermanentResidencyCard") {
                  //console.log('ok')
              } else {
                  if (props.application_id) {
                      if ((!applicant.ExpirationDateUnavailable && !applicant.ExpirationDateUnavailable === false) || applicant.ExpirationDateUnavailable === 'disable') {
                          errorList.ExpirationDateUnavailable = "Please select an option";
                      }
                  } else if (applicant.ExpirationDateUnavailable === null || applicant.ExpirationDateUnavailable === undefined || applicant.ExpirationDateUnavailable === 'disable') {
                      errorList.ExpirationDateUnavailable = "Please select an option";
                  }
              }
  
  
          }













          if (contract.hasOwnProperty('CoApplicantFirstName')) {

            if (!applicant.CoIdentificationType) {
              errorList.CoIdentificationType = "Please select an option";
          }

          //IdentificationType - select
          if (applicant.CoIdentificationType) {

            //IdentificationNumber - text
            if (applicant.CoIdentificationType === "Unavailable") {
                //console.log('ok')
            } else {
                if (!applicant.CoIdentificationNumber) {
                    errorList.CoIdentificationNumber = "Please enter an identification number";
                } else if(applicant.CoIdentificationNumber && applicant.CoIdentificationNumber.length > 20){
                    errorList.CoIdentificationNumber = "The identification number must be less than 20 characters";
                }
                else if (!/^[a-zA-Z0-9,-\s]*$/.test(applicant.CoIdentificationNumber)) {
                    errorList.CoIdentificationNumber = "The value can only contain numbers, letters, spaces, or dashes";
                }


            }



            if (applicant.CoIdentificationType === "Unavailable" || applicant.CoIsIssueDatePresent === 'false' || applicant.CoIsIssueDatePresent === false) {
                //console.log('ok')
            } else {
                //IdentificationIssuedDate - date
                if (!applicant.CoIdentificationIssuedDate) {
                    errorList.CoIdentificationIssuedDate = "Please enter a date";
                } else if (!applicant.CoIdentificationIssuedDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
                    if (isSafari) {
                        errorList.CoIdentificationIssuedDate = "The format must be yyyy-mm-dd";
                    } else {
                        errorList.CoIdentificationIssuedDate = "The format must be dd/mm/yyyy";
                    }
                } else if (applicant.CoIdentificationIssuedDate) {
                    let dob2 = new Date(applicant.CoIdentificationIssuedDate);
                    const nowDate = new Date();
                    if (nowDate < dob2) {
                        errorList.CoIdentificationIssuedDate = "The date must be in the past";
                    }
                }
            }




            //IdentificationExpirationDate - date
            if (applicant.CoIdentificationType === "Unavailable" || applicant.CoExpirationDateUnavailable === 'true') {

            } else {
                if (!applicant.CoIdentificationExpirationDate) {
                    errorList.CoIdentificationExpirationDate = "Please enter a date";
                } else if (!applicant.CoIdentificationExpirationDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
                    if (isSafari) {
                        errorList.CoIdentificationExpirationDate = "The format must be yyyy-mm-dd";
                    } else {
                        errorList.CoIdentificationExpirationDate = "The format must be dd/mm/yyyy";
                    }
                } else if (applicant.CoIdentificationExpirationDate) {
                    let dob3 = new Date(applicant.CoIdentificationExpirationDate);
                    const nowDate = new Date();
                    if (nowDate > dob3) {
                        errorList.CoIdentificationExpirationDate = "The date must be in the future";
                    }
                }
            }






           


            //IsIssueDatePresent
            if (applicant.CoIdentificationType === "Unavailable" || applicant.CoIdentificationType === "PermanentResidencyCard") {
                //console.log('ok')
            } else {
                if (props.application_id) {
                    if ((!applicant.CoIsIssueDatePresent && !applicant.CoIsIssueDatePresent === false) || applicant.CoIsIssueDatePresent === 'disable') {
                        errorList.CoIsIssueDatePresent = "Please select an option";
                    }
                } else if (applicant.CoIsIssueDatePresent === null || applicant.CoIsIssueDatePresent === undefined || applicant.CoIsIssueDatePresent === 'disable') {
                    errorList.CoIsIssueDatePresent = "Please select an option";
                }

            }

            //IsIssueStatePresent
            if (applicant.CoIdentificationType === "Unavailable" || applicant.CoIdentificationType === "UnitedStatesPassport" || applicant.CoIdentificationType === "PermanentResidencyCard" || applicant.CoIdentificationType === "UnitedStatesMilitaryId") {
                //console.log('ok')
            } else {
                if (props.application_id) {
                    if ((!applicant.CoIsIssueStatePresent && !applicant.CoIsIssueStatePresent === false) || applicant.CoIsIssueStatePresent === 'disable') {
                        errorList.CoIsIssueStatePresent = "Please select an option";
                    }
                } else if (applicant.CoIsIssueStatePresent === null || applicant.CoIsIssueStatePresent === undefined || applicant.CoIsIssueStatePresent === 'disable') {
                    errorList.CoIsIssueStatePresent = "Please select an option";
                }
            }




            if (applicant.CoIdentificationType === "Unavailable" || applicant.CoIdentificationType === "UnitedStatesPassport" || applicant.CoIdentificationType === "PermanentResidencyCard"  || applicant.CoIdentificationType === "UnitedStatesMilitaryId" || applicant.CoIsIssueStatePresent === 'false' || applicant.CoIsIssueStatePresent === false) {
                //console.log('ok')
            } else {
                //IdentificationIssueState - select

                if (!applicant.CoIdentificationIssueState) {
                    errorList.CoIdentificationIssueState = "Please select an option";
                } else if (
                  applicant.CoIdentificationIssueState === "Identification Issue State"
                ) {
                    errorList.CoIdentificationIssueState = "Please select an option";
                }


            }


            //ExpirationDateUnavailable
            if (applicant.CoIdentificationType === "Unavailable" || applicant.CoIdentificationType === "DriversLicense" ||  applicant.CoIdentificationType === "UnitedStatesPassport" || applicant.CoIdentificationType === "PermanentResidencyCard") {
                //console.log('ok')
            } else {
                if (props.application_id) {
                    if ((!applicant.CoExpirationDateUnavailable && !applicant.CoExpirationDateUnavailable === false) || applicant.CoExpirationDateUnavailable === 'disable') {
                        errorList.CoExpirationDateUnavailable = "Please select an option";
                    }
                } else if (applicant.CoExpirationDateUnavailable === null || applicant.CoExpirationDateUnavailable === undefined || applicant.CoExpirationDateUnavailable === 'disable') {
                    errorList.CoExpirationDateUnavailable = "Please select an option";
                }
            }


        }


          }








    return errorList;
  };




  //calcular el tax
  useEffect(() => {

    //   if(includeTax === false){
    //       console.log('xd')
    //     setLoan({
    //       ...loan,
    //       SalesTax: 0.0,            
    //   }) 

    //   }

    if (contract.hasOwnProperty('SellingPrice') && contract.hasOwnProperty('DownPayment')) {

      let salesTax;
      if (includeTax === false) {
        salesTax = 0.0;
      } else {
        salesTax = parseFloat(contract.SalesTax);
      }



      const sellingPrice = parseFloat(contract.SellingPrice);
      const downPayment = parseFloat(contract.DownPayment);
      //const salesTax = parseFloat(loan.SalesTax);
      const amountFinanced =  ((sellingPrice - downPayment) + salesTax)


      // const exponente = Math.pow(10, 2);
      // const value = (amountFinanced >= 0 || -1) * Math.round(Math.abs(amountFinanced) * exponente) / exponente;


      setContract({
        ...contract,
        SalesTax: salesTax,
        AmountFinanced: amountFinanced.toFixed(2),
      })

    }



  }, [changeInputTax]);



  const handleRedoContract = () => {

    // if(props.reDoSigningContract === false){
    //   setEditContract(!editContract);
    // }

    setEditContract(!editContract)

    props.setReDoSigningContract(value => !value)

  }





    /*----------------------------------------- PROMOTIONS  ------------------------------------- */


    const getPromotions = () => {

      setLoadingPromotions(true);
      setPromotionId(null)
      let URI = ''

      if(amountFinancedInitial === contract.AmountFinanced){
        URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${applicationDetails.ApplicationId}/${uri.URI_GET_PROMOTION}?`;   
      }else{
        URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}${applicationDetails.ApplicationId}/${uri.URI_GET_PROMOTION}?amount=${contract.AmountFinanced}`;
      }   
      
      // console.log(URI)

      const abortController = new AbortController();
      const signal = abortController.signal;

      window.fetch(URI, {
        method: 'GET',
        signal: signal,
        headers: {
          // 'Access-Control-Request-Headers': 'application/json',
          'Content-Type': 'application/json',
          'username': user,
          'Authorization': token,
          'x-api-key': URI_X_API_KEY_I,
        }
      })
        .then(response => response.json())
        .then(datos => {
          setLoadingPromotions(false);
        
          //console.log(datos)
          if (datos.status === 'APPLICATION_NOT_FOUND_IN_BROKER' || datos.status === 'APPLICATION_NOT_APPROVED') {
            setNoPromotion(true);
          } else if (datos.status === 'INTERNAL_SERVER_ERROR') {
            //console.log('INTERNAL SERVER ERROR')    
          } else {
            SetPromotionList(datos);
          }

        }).catch(error => {
          console.log(error)
          setLoadingPromotions(false)
        })



      return function cleanup() {
        abortController.abort();
      }
    }






  //GET PROMOTIONS
  // useEffect(() => {

  //   if ( applicationDetails.ApplicationId ) {
  //     getPromotions()
  //   }
  // }, [applicationDetails.ApplicationId]);



  const [time, setTime] = useState(null)

  useEffect(() => {

    setLoadingPromotions(true)
  
    const timer = () => setTimeout(() => {
      getPromotions()
    }, 3500);

    clearTimeout(time)
    setTime(timer) 


  }, [contract.AmountFinanced])










  const copyProposalInfo = () => {

    setLoadingCopyProposal(true)
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/get-customer-proposal-by-applicant-id/${props.applicantId}`
    //console.log(URI)


    fetch(URI,{
        method: 'GET',
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'username' : user,
            'Authorization' : token,   
            'x-api-key' : URI_X_API_KEY_I,   
          }
    })
    .then(response => {

        if (response.ok) {
            return response.json();
          }
        return Promise.reject(response);
    })
    .then(datos => {
       
        setLoadingCopyProposal(false)
        // console.log(datos)  
        
    
        setContract({
            ...contract,
            SellingPrice: datos[0].TotalEquipment,
            DownPayment: datos[0].DownPayment,
            SalesTax: parseFloat(datos[0].SalesTax).toFixed(2),
            AmountFinanced: datos[0].TotalEquipment - datos[0].DownPayment + (datos[0].SalesTax || 0),
            SalesTaxIncluded: datos[0].SalesTax ? true : false
            
        })
        // setNewAmountFinanced(parseFloat(datos[0].TotalEquipment + datos[0].SalesTax - datos[0].DownPayment).toFixed(2))


        setErrorFlagFromContext(false)

    })
    .catch((error, response) => {
        setLoadingCopyProposal(false)
        if(errorFlagFromContext === false && error.status === 401){
            changeToken().then(response => console.log(response)).catch(error => console.log(error))
        }    
    });
}













  return (
    <>

      <div className="documentSectionContentMain" id="SignMainContainer">

        {
          contractCompletionStatus &&
          <div className="contract-headerContainer">
            <h3 className="applicationFormContainer-tittle_green">Contract generated</h3>
            <div className="contract-headerContainer_buttonsContainer">
              <button className="contract_header-editButton" onClick={handleRedoContract}>Redo Customer Contract</button>
              <AquaResendContractButton applicationId={applicationDetails.ApplicationId} contract='FULL'/>


            </div>

          </div>

        }

        {editContract && contractCompletionStatus && <p className="pinnacleContract_resendContractMessage">Contract may now be redone.</p>}

        {
          editContract &&
          <div className="fullwidth campoContainer">
            <div className="createApplicationPinnacle_switchButton">
              <p className="createApplicationPinnacle_switchText margin-right_10">Sign via email  </p>
              <label className="switch">
                <input type="checkbox" id="checkStubApplication"  {...contractType === 'Electronic' && { checked: "checked" }} name="isActive" onClick={() => setContractType(contractType === 'Email' ? 'Electronic' : 'Email')} />
                <span className="slider round"></span>
              </label>

              <p className="createApplicationPinnacle_switchText">Sign in-person</p>
            </div>
          </div>
        }

        <h3 className="applicationFormContainer-tittle">Contract Language</h3>

        <div action="" id="form" className="form">
          <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Contract Language</label>

            <select
              value={contract.ContractLanguage}
              name="ContractLanguage"
              onChange={handleInputContract}
              className={`${contractErrors.ContractLanguage ? "imputError" : "campo"}`}
              placeholder="ContractLanguage"
              required
              disabled={editContract ? null : "disabled"}
            >
              <option disabled selected>Contract Language</option>
              <option value="English">English</option> 
              <option value="Spanish">Spanish</option> 
            </select>
            {contractErrors.ContractLanguage && <p className="errorMessage">{contractErrors.ContractLanguage}</p>}
          </div>

        </div>



        <h3 className="applicationFormContainer-tittle">Applicant</h3>



        <div action="" id="form" className="form">




          {/* ApplicantFirsName */}
          <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >First Name</label>
            <input
              type="text"
              name="ApplicantFirstName"
              onChange={handleInputContract}
              className={`${contractErrors.ApplicantFirstName ? "imputError" : "campo"}`}
              value={contract.ApplicantFirstName}
              placeholder="First Name"
              disabled={editContract ? null : "disabled"}
              required
            />
            {contractErrors.ApplicantFirstName && <p className="errorMessage">{contractErrors.ApplicantFirstName}</p>}
          </div>


          {/* ApplicantLastName */}
          <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Last Name</label>
            <input
              type="text"
              name="ApplicantLastName"
              onChange={handleInputContract}
              className={`${contractErrors.ApplicantLastName ? "imputError" : "campo"}`}
              placeholder="Last Name"
              value={contract.ApplicantLastName}
              required
              disabled={editContract ? null : "disabled"}
            />
            {contractErrors.ApplicantLastName && <p className="errorMessage">{contractErrors.ApplicantLastName}</p>}
          </div>


          {/* ApplicantEmail */}
          <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Email</label>
            <input
              type="email"
              name="ApplicantEmail"
              onChange={handleInputContract}
              className={`${contractErrors.ApplicantEmail ? "imputError" : "campo"}`}
              placeholder="Email"
              value={contract.ApplicantEmail}
              required
              disabled={editContract ? null : "disabled"}
            />
            {contractErrors.ApplicantEmail && <p className="errorMessage">{contractErrors.ApplicantEmail}</p>}
          </div>


          {/* ApplicantAddress */}
          <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >Address</label>
            <input
              type="text"
              value={contract.ApplicantAddress}
              name="ApplicantAddress"
              onChange={handleInputContract}
              className={`${contractErrors.ApplicantAddress ? "imputError" : "campo"}`}
              placeholder="Address"
              required
              disabled={editContract ? null : "disabled"}
            />
            {contractErrors.ApplicantAddress && <p className="errorMessage">{contractErrors.ApplicantAddress}</p>}
          </div>


          {/* ApplicantCity */}
          <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >City</label>
            <input
              type="text"
              value={contract.ApplicantCity}
              name="ApplicantCity"
              onChange={handleInputContract}
              className={`${contractErrors.ApplicantCity ? "imputError" : "campo"}`}
              placeholder="City"
              required
              disabled={editContract ? null : "disabled"}
            />
            {contractErrors.ApplicantCity && <p className="errorMessage">{contractErrors.ApplicantCity}</p>}
          </div>


          {/* ApplicantState */}
          <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >State</label>
            <select
              value={contract.ApplicantState}
              name="ApplicantState"
              onChange={handleInputContract}
              className={`${contractErrors.ApplicantState ? "imputError" : "campo"}`}
              placeholder="State"
              required
              disabled={editContract ? null : "disabled"}
            >
              <option value="1" disabled selected>
                Applicant State
                            </option>
                            <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>           
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>                 
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="GU">GU</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>          
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>      
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MS">MS</option>
                            <option value="MO">MO</option>
                            <option value="MT">MT</option>
                            <option value="NE">NE</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="PR">PR</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VI">VI</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>   
            </select>
            {contractErrors.ApplicantState && <p className="errorMessage">{contractErrors.ApplicantState}</p>}
          </div>


          {/* ApplicantZipCode */}
          <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Zip Code</label>
            <input
              type="number"
              value={contract.ApplicantZipCode}
              name="ApplicantZipCode"
              onChange={handleInputContract}
              className={`${contractErrors.ApplicantZipCode ? "imputError" : "campo"}`}
              placeholder="Zip Code"
              required
              disabled={editContract ? null : "disabled"}
            />
            {contractErrors.ApplicantZipCode && <p className="errorMessage">{contractErrors.ApplicantZipCode}</p>}
          </div>



          {/* CellPhone */}
          <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Home/Cell Phone</label>
            <NumberFormat
                value={contract.CellPhone}
                onValueChange={e => handleInputContract(e, 'CellPhone')}
                displayType="input"
                type="text"
                name="CellPhone"
                placeholder="Do not use dashes"
                format="(###) ### - ####"
                className={`${contractErrors.CellPhone ? "imputError" : "campo"}`}
                // className={stylesForInputMask(errors.CellPhone)}
                disabled={editContract ? null : "disabled"}
            />
            {/* <input
              type="number"
              value={contract.CellPhone}
              name="CellPhone"
              onChange={handleInputContract}
              className={`${contractErrors.CellPhone ? "imputError" : "campo"}`}
              placeholder="Do not use dashes"
              required
              disabled={editContract ? null : "disabled"}
            /> */}
            {contractErrors.CellPhone && <p className="errorMessage">{contractErrors.CellPhone}</p>}
          </div>



        </div>












      
                            
                            <div action="" id="form" className="form">
                            

                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Type</label>
                                <select
                                value={contract.IdentificationType}

                                name="IdentificationType"
                                onChange={handleInputContract}
                                className={`${contractErrors.IdentificationType ? "imputError" : "campo"}`}
                                placeholder="Identification Type"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="default" disabled selected>
                                    Identification Type
                                </option>
                                <option value="Unavailable">Unavailable</option>
                                <option value="DriversLicense">Drivers License</option>
                                <option value="StateId">State Id</option>
                                <option value="UnitedStatesMilitaryId">United States Military Id</option>
                                <option value="UnitedStatesPassport">United States Passport</option>
                                <option value="PermanentResidencyCard">Permanent Residency Card</option>
                                </select>
                                {contractErrors.IdentificationType && <p className="errorMessage">{contractErrors.IdentificationType}</p>}
                            </div>



                            {contract.IdentificationType === 'DriversLicense' || contract.IdentificationType === 'StateId' || contract.IdentificationType === 'UnitedStatesMilitaryId' || contract.IdentificationType === 'UnitedStatesPassport' || contract.IdentificationType === 'PermanentResidencyCard'  ? 
                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Number</label>
                                <input
                                type="text"
                                value={contract.IdentificationNumber}
                                name="IdentificationNumber"
                                onChange={handleInputContract}
                                className={`${contractErrors.IdentificationNumber ? "imputError" : "campo"}`}
                                placeholder="Identification Number"
                                required
                                disabled={editContract ? null : "disabled"}
                                />
                                {contractErrors.IdentificationNumber && <p className="errorMessage">{contractErrors.IdentificationNumber}</p>}
                            </div>
                            : null
                        
                        
                            }
                            

                            {contract.IdentificationType === 'DriversLicense' || contract.IdentificationType === 'StateId' || contract.IdentificationType === 'UnitedStatesMilitaryId' || contract.IdentificationType === 'UnitedStatesPassport' ? 
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue Date Present</label>
                                <select
                                value={contract.IsIssueDatePresent}
                                name="IsIssueDatePresent"
                                onChange={handleInputContract}
                                className={`${contractErrors.IsIssueDatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue Date Present"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="disable" disabled selected>
                                Is Issue Date Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {contractErrors.IsIssueDatePresent && <p className="errorMessage">{contractErrors.IsIssueDatePresent}</p>}
                            </div>
                            : null
                            
                            
                            }




                            {contract.IdentificationType === 'DriversLicense' || contract.IdentificationType === 'StateId' || contract.IdentificationType === 'UnitedStatesMilitaryId' || contract.IdentificationType === 'UnitedStatesPassport' || contract.IdentificationType === 'PermanentResidencyCard' ?    
                                (contract.IsIssueDatePresent === true || contract.IsIssueDatePresent === 'true') || contract.IdentificationType === 'PermanentResidencyCard' ?
                                        <div className={contract.IdentificationType === 'PermanentResidencyCard' ? "fullwidth campoContainer" : "mediowidth-right campoContainer"}>
                                        <label className="labelApplicationForm" >Identification Issue Date</label>
                                            <input
                                            type="date"
                                            value={contract.IdentificationIssuedDate}
                                            name="IdentificationIssuedDate"
                                            onChange={handleInputContract}
                                            className={`${contractErrors.IdentificationIssuedDate ? "imputError" : "campo"}`}
                                            placeholder="yyyy-mm-dd"
                                            required
                                            disabled={editContract ? null : "disabled"}
                                            />
                                            {contractErrors.IdentificationIssuedDate && <p className="errorMessage">{contractErrors.IdentificationIssuedDate}</p>}
                                        </div>
                                        :null
                                : null   
                                }
                            


                            {contract.IdentificationType === 'DriversLicense' || contract.IdentificationType === 'StateId'  ?
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue State Present</label>
                                <select
                                value={contract.IsIssueStatePresent}
                                name="IsIssueStatePresent"
                                onChange={handleInputContract}
                                className={`${contractErrors.IsIssueStatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue State Present"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="disable" disabled selected>
                                Is Issue State Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                                

                                </select>
                                {contractErrors.IsIssueStatePresent && <p className="errorMessage">{contractErrors.IsIssueStatePresent}</p>}
                            </div>
                            : null
                            
                            
                            }


                        



                        {contract.IdentificationType === 'DriversLicense' || contract.IdentificationType === 'StateId'  || contract.IdentificationType === 'PermanentResidencyCard'  ?

                            contract.IsIssueStatePresent === true || contract.IsIssueStatePresent === 'true' ?
                                <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Identification Issue State</label>
                                <select
                                    value={contract.IdentificationIssueState}
                                    name="IdentificationIssueState"
                                    onChange={handleInputContract}
                                    className={`${contractErrors.IdentificationIssueState ? "imputError" : "campo"}`}
                                    placeholder="Identification Issue State"
                                    required
                                    disabled={editContract ? null : "disabled"}
                                >
                                    <option value="disable" disabled selected>
                                    Identification Issue State
                                    </option>
                                    <option value="AK">AK</option>
                                    <option value="AL">AL</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>           
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>                 
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="GU">GU</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>          
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>      
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MS">MS</option>
                                    <option value="MO">MO</option>
                                    <option value="MT">MT</option>
                                    <option value="NE">NE</option>
                                    <option value="NC">NC</option>
                                    <option value="ND">ND</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="PR">PR</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VA">VA</option>
                                    <option value="VI">VI</option>
                                    <option value="VT">VT</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>   
                                </select>
                                {contractErrors.IdentificationIssueState && <p className="errorMessage">{contractErrors.IdentificationIssueState}</p>}
                                </div>
                                :
                                null
                            : null
                        }


                        {contract.IdentificationType === 'UnitedStatesMilitaryId' || contract.IdentificationType === 'StateId'   ?
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Expiration Date Present</label>
                                <select
                                value={contract.ExpirationDateUnavailable}
                                name="ExpirationDateUnavailable"
                                onChange={handleInputContract}
                                className={`${contractErrors.ExpirationDateUnavailable ? "imputError" : "campo"}`}
                                placeholder="Is Expiration Date Present"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="disable" disabled selected>
                                Is Expiration Date Present
                                </option>
                                <option value="false">Yes</option>
                                <option value="true">No</option>
                                

                                </select>
                                {contractErrors.ExpirationDateUnavailable && <p className="errorMessage">{contractErrors.ExpirationDateUnavailable}</p>}
                            </div>
                            : null
                        
                            }


                            {contract.IdentificationType === 'UnitedStatesMilitaryId' || contract.IdentificationType === 'StateId'  ?
                                contract.ExpirationDateUnavailable === false || contract.ExpirationDateUnavailable === 'false' ?
                                <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Identification Expiration Date</label>
                                    <input
                                        type="date"
                                        value={contract.IdentificationExpirationDate}
                                        name="IdentificationExpirationDate"
                                        onChange={handleInputContract}
                                        className={`${contractErrors.IdentificationExpirationDate ? "imputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        disabled={editContract ? null : "disabled"}
                                    />
                                    {contractErrors.IdentificationExpirationDate && <p className="errorMessage">{contractErrors.IdentificationExpirationDate}</p>}
                                </div>
                                : null
                            : null
                            
                            
                            }
                            


                            {contract.IdentificationType === 'DriversLicense' || contract.IdentificationType === 'UnitedStatesPassport' || contract.IdentificationType === 'PermanentResidencyCard'?
                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Expiration Date</label>
                                <input
                                type="date"
                                value={contract.IdentificationExpirationDate}
                                name="IdentificationExpirationDate"
                                onChange={handleInputContract}
                                className={`${contractErrors.IdentificationExpirationDate ? "imputError" : "campo"}`}
                                placeholder="yyyy-mm-dd"
                                required
                                disabled={editContract ? null : "disabled"}
                                />
                                {contractErrors.IdentificationExpirationDate && <p className="errorMessage">{contractErrors.IdentificationExpirationDate}</p>}
                            </div>
                            : null    
                            }
                            
                            
                            </div>


        {
          contract.hasOwnProperty('CoApplicantFirstName') ?
            <>

              <h3 className="applicationFormContainer-tittle">Co-Applicant</h3>

              <div action="" id="form" className="form">



                {/* CoApplicantFirstName */}
                <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Firs Name</label>
                  <input
                    type="text"
                    name="CoApplicantFirstName"
                    onChange={handleInputContract}
                    className={`${contractErrors.CoApplicantFirstName ? "imputError" : "campo"}`}
                    value={contract.CoApplicantFirstName}
                    placeholder="Firs Name"
                    disabled={editContract ? null : "disabled"}
                    required
                  />
                  {contractErrors.CoApplicantFirstName && <p className="errorMessage">{contractErrors.CoApplicantFirstName}</p>}
                </div>



                {/* CoApplicantLastName */}
                <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Last Name</label>
                  <input
                    type="text"
                    name="CoApplicantLastName"
                    onChange={handleInputContract}
                    className={`${contractErrors.CoApplicantLastName ? "imputError" : "campo"}`}
                    value={contract.CoApplicantLastName}
                    placeholder="Last Name"
                    disabled={editContract ? null : "disabled"}
                    required
                  />
                  {contractErrors.CoApplicantLastName && <p className="errorMessage">{contractErrors.CoApplicantLastName}</p>}
                </div>



                {/* CoApplicantEmail */}
                <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Email</label>
                  <input
                    type="text"
                    value={contract.CoApplicantEmail}
                    name="CoApplicantEmail"
                    onChange={handleInputContract}
                    className={`${contractErrors.CoApplicantEmail ? "imputError" : "campo"}`}
                    placeholder="Email"
                    required
                    disabled={editContract ? null : "disabled"}
                  />
                  {contractErrors.CoApplicantEmail && <p className="errorMessage">{contractErrors.CoApplicantEmail}</p>}
                </div>

                <div></div>

                {/* CellPhone */}
                <div className="fullwidth campoContainer">
                  <label className="labelApplicationForm" >Home/Cell Phone</label>
                  <NumberFormat
                      value={contract.CoApplicantPhone}
                      onValueChange={e => handleInputContract(e, 'CoApplicantPhone')}
                      displayType="input"
                      type="text"
                      name="CoApplicantPhone"
                      placeholder="Do not use dashes"
                      format="(###) ### - ####"
                      className={`${contractErrors.CoApplicantPhone ? "imputError" : "campo"}`}
                      // className={stylesForInputMask(errors.CellPhone)}
                      disabled={editContract ? null : "disabled"}
                  />
                  {/* <input
                    type="number"
                    value={contract.CoApplicantPhone}
                    name="CoApplicantPhone"
                    onChange={handleInputContract}
                    className={`${contractErrors.CoApplicantPhone ? "imputError" : "campo"}`}
                    placeholder="Phone"
                    required
                    disabled={editContract ? null : "disabled"}
                  /> */}
                  {contractErrors.CoApplicantPhone && <p className="errorMessage">{contractErrors.CoApplicantPhone}</p>}
                </div>


                

              </div>

              <div action="" id="form" className="form">
                            

                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Type</label>
                                <select
                                value={contract.CoIdentificationType}

                                name="CoIdentificationType"
                                onChange={handleInputContract}
                                className={`${contractErrors.CoIdentificationType ? "imputError" : "campo"}`}
                                placeholder="Identification Type"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="default" disabled selected>
                                    Identification Type
                                </option>
                                <option value="Unavailable">Unavailable</option>
                                <option value="DriversLicense">Drivers License</option>
                                <option value="StateId">State Id</option>
                                <option value="UnitedStatesMilitaryId">United States Military Id</option>
                                <option value="UnitedStatesPassport">United States Passport</option>
                                <option value="PermanentResidencyCard">Permanent Residency Card</option>
                                </select>
                                {contractErrors.CoIdentificationType && <p className="errorMessage">{contractErrors.CoIdentificationType}</p>}
                            </div>



                            {contract.CoIdentificationType === 'DriversLicense' || contract.CoIdentificationType === 'StateId' || contract.CoIdentificationType === 'UnitedStatesMilitaryId' || contract.CoIdentificationType === 'UnitedStatesPassport' || contract.CoIdentificationType === 'PermanentResidencyCard'  ? 
                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Number</label>
                                <input
                                type="text"
                                value={contract.CoIdentificationNumber}
                                name="CoIdentificationNumber"
                                onChange={handleInputContract}
                                className={`${contractErrors.CoIdentificationNumber ? "imputError" : "campo"}`}
                                placeholder="Identification Number"
                                required
                                disabled={editContract ? null : "disabled"}
                                />
                                {contractErrors.CoIdentificationNumber && <p className="errorMessage">{contractErrors.CoIdentificationNumber}</p>}
                            </div>
                            : null
                        
                        
                            }
                            

                            {contract.CoIdentificationType === 'DriversLicense' || contract.CoIdentificationType === 'StateId' || contract.CoIdentificationType === 'UnitedStatesMilitaryId' || contract.CoIdentificationType === 'UnitedStatesPassport' ? 
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue Date Present</label>
                                <select
                                value={contract.CoIsIssueDatePresent}
                                name="CoIsIssueDatePresent"
                                onChange={handleInputContract}
                                className={`${contractErrors.CoIsIssueDatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue Date Present"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="disable" disabled selected>
                                Is Issue Date Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                            {contractErrors.CoIsIssueDatePresent && <p className="errorMessage">{contractErrors.CoIsIssueDatePresent}</p>}
                            </div>
                            : null
                            
                            
                            }




                            {contract.CoIdentificationType === 'DriversLicense' || contract.CoIdentificationType === 'StateId' || contract.CoIdentificationType === 'UnitedStatesMilitaryId' || contract.CoIdentificationType === 'UnitedStatesPassport' || contract.CoIdentificationType === 'PermanentResidencyCard' ?    
                                (contract.CoIsIssueDatePresent === true || contract.CoIsIssueDatePresent === 'true') || contract.CoIdentificationType === 'PermanentResidencyCard' ?
                                        <div className={contract.CoIdentificationType === 'PermanentResidencyCard' ? "fullwidth campoContainer" : "mediowidth-right campoContainer"}>
                                        <label className="labelApplicationForm" >Identification Issue Date</label>
                                            <input
                                            type="date"
                                            value={contract.CoIdentificationIssuedDate}
                                            name="CoIdentificationIssuedDate"
                                            onChange={handleInputContract}
                                            className={`${contractErrors.CoIdentificationIssuedDate ? "imputError" : "campo"}`}
                                            placeholder="yyyy-mm-dd"
                                            required
                                            disabled={editContract ? null : "disabled"}
                                            />
                                            {contractErrors.CoIdentificationIssuedDate && <p className="errorMessage">{contractErrors.CoIdentificationIssuedDate}</p>}
                                        </div>
                                        :null
                                : null   
                                }
                            


                            {contract.CoIdentificationType === 'DriversLicense' || contract.CoIdentificationType === 'StateId'  ?
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Issue State Present</label>
                                <select
                                value={contract.CoIsIssueStatePresent}
                                name="CoIsIssueStatePresent"
                                onChange={handleInputContract}
                                className={`${contractErrors.CoIsIssueStatePresent ? "imputError" : "campo"}`}
                                placeholder="Is Issue State Present"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="disable" disabled selected>
                                Is Issue State Present
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                                

                                </select>
                                {contractErrors.CoIsIssueStatePresent && <p className="errorMessage">{contractErrors.CoIsIssueStatePresent}</p>}
                            </div>
                            : null
                            
                            
                            }


                        



                        {contract.CoIdentificationType === 'DriversLicense' || contract.CoIdentificationType === 'StateId'  || contract.CoIdentificationType === 'PermanentResidencyCard'  ?

                            contract.CoIsIssueStatePresent === true || contract.CoIsIssueStatePresent === 'true' ?
                                <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Identification Issue State</label>
                                <select
                                    value={contract.CoIdentificationIssueState}
                                    name="CoIdentificationIssueState"
                                    onChange={handleInputContract}
                                    className={`${contractErrors.CoIdentificationIssueState ? "imputError" : "campo"}`}
                                    placeholder="Identification Issue State"
                                    required
                                    disabled={editContract ? null : "disabled"}
                                >
                                    <option value="disable" disabled selected>
                                    Identification Issue State
                                    </option>
                                    <option value="AK">AK</option>
                                    <option value="AL">AL</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>           
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>                 
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="GU">GU</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>          
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>      
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MS">MS</option>
                                    <option value="MO">MO</option>
                                    <option value="MT">MT</option>
                                    <option value="NE">NE</option>
                                    <option value="NC">NC</option>
                                    <option value="ND">ND</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="PR">PR</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VA">VA</option>
                                    <option value="VI">VI</option>
                                    <option value="VT">VT</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>   
                                </select>
                                {contractErrors.CoIdentificationIssueState && <p className="errorMessage">{contractErrors.CoIdentificationIssueState}</p>}
                                </div>
                                :
                                null
                            : null
                        }


                        {contract.CoIdentificationType === 'UnitedStatesMilitaryId' || contract.CoIdentificationType === 'StateId'   ?
                            <div className="mediowidth-left campoContainer">
                            <label className="labelApplicationForm" >Is Expiration Date Present</label>
                                <select
                                value={contract.CoExpirationDateUnavailable}
                                name="CoExpirationDateUnavailable"
                                onChange={handleInputContract}
                                className={`${contractErrors.CoExpirationDateUnavailable ? "imputError" : "campo"}`}
                                placeholder="Is Expiration Date Present"
                                required
                                disabled={editContract ? null : "disabled"}
                                >
                                <option value="disable" disabled selected>
                                Is Expiration Date Present
                                </option>
                                <option value="false">Yes</option>
                                <option value="true">No</option>
                                

                                </select>
                                {contractErrors.CoExpirationDateUnavailable && <p className="errorMessage">{contractErrors.CoExpirationDateUnavailable}</p>}
                            </div>
                            : null
                        
                            }


                            {contract.CoIdentificationType === 'UnitedStatesMilitaryId' || contract.CoIdentificationType === 'StateId'  ?
                                contract.CoExpirationDateUnavailable === false || contract.CoExpirationDateUnavailable === 'false' ?
                                <div className="mediowidth-right campoContainer">
                                <label className="labelApplicationForm" >Identification Expiration Date</label>
                                    <input
                                        type="date"
                                        value={contract.CoIdentificationExpirationDate}
                                        name="CoIdentificationExpirationDate"
                                        onChange={handleInputContract}
                                        className={`${contractErrors.CoIdentificationExpirationDate ? "imputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        disabled={editContract ? null : "disabled"}
                                    />
                                    {contractErrors.CoIdentificationExpirationDate && <p className="errorMessage">{contractErrors.CoIdentificationExpirationDate}</p>}
                                </div>
                                : null
                            : null
                            
                            
                            }
                            


                            {contract.CoIdentificationType === 'DriversLicense' || contract.CoIdentificationType === 'UnitedStatesPassport' || contract.CoIdentificationType === 'PermanentResidencyCard'?
                            <div className="fullwidth campoContainer">
                            <label className="labelApplicationForm" >Identification Expiration Date</label>
                                <input
                                type="date"
                                value={contract.CoIdentificationExpirationDate}
                                name="CoIdentificationExpirationDate"
                                onChange={handleInputContract}
                                className={`${contractErrors.CoIdentificationExpirationDate ? "imputError" : "campo"}`}
                                placeholder="yyyy-mm-dd"
                                required
                                disabled={editContract ? null : "disabled"}
                                />
                                {contractErrors.CoIdentificationExpirationDate && <p className="errorMessage">{contractErrors.CoIdentificationExpirationDate}</p>}
                            </div>
                            : null    
                            }
                            
                            
                            </div>

            </>

            :

            <>
              <h3 className="applicationFormContainer-tittle">Co-Applicant</h3>
              <div action="" id="form" className="form">
                <p className="fullwidth campoContainer">This Application does not have a CoApplicant</p>
              </div>

            </>
        }



      </div>
































      <h3 className="applicationFormContainer-tittle">Loan Parameters</h3>
      <form action="" id="form" className="form">


      <div className="fullwidth" style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
          <Button
              onClick={copyProposalInfo}
              value="Copy Proposal"
              isLoading={loadingCopyProposal}
              showButton={(props.hasProposal ? true : false) && editContract}
              size="small"
          />
      </div>



        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Selling Price</label>
          <input
            type="number"
            value={contract.SellingPrice}
            name="SellingPrice"
            onChange={handleInputContract}
            onBlur={()=> { setContract({...contract, 'SellingPrice': parseFloat(contract.SellingPrice).toFixed(2) })   }}
            className={`${contractErrors.SellingPrice ? "imputError" : "campo"}`}
            placeholder="Selling Price"
            required
            disabled={editContract ? null : "disabled"}
          />
          {contractErrors.SellingPrice && <p className="errorMessage">{contractErrors.SellingPrice}</p>}
        </div>



        <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Down Payment</label>
          <input
            type="number"
            value={contract.DownPayment}
            name="DownPayment"
            onChange={handleInputContract}
            onBlur={()=> { setContract({...contract, 'DownPayment': parseFloat(contract.DownPayment).toFixed(2) })   }}
            className={`${contractErrors.DownPayment ? "imputError" : "campo"}`}
            placeholder="Down Payment"
            required
            disabled={editContract ? null : "disabled"}
          />
          {contractErrors.DownPayment && <p className="errorMessage">{contractErrors.DownPayment}</p>}
        </div>

        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Add Sales Tax Line Item</label>

          <select
            value={contract.SalesTaxIncluded}
            name="SalesTaxIncluded"
            onChange={handleInputContract}
            className={`${contractErrors.SalesTaxIncluded ? "imputError" : "campo"}`}
            placeholder="Add Sales Tax Line Item"
            required
            disabled={editContract ? null : "disabled"}
          >
            <option value="Sales Tax Included" disabled selected>
              Add Sales Tax Line Item
                  </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          {contractErrors.SalesTaxIncluded && <p className="errorMessage">{contractErrors.SalesTaxIncluded}</p>}
        </div>

        {includeTax || contract.SalesTaxIncluded === true ?
          <div className="mediowidth-right campoContainer">
            <label className="labelApplicationForm" >Sales Tax</label>
            <input
              type="number"
              value={contract.SalesTax}
              name="SalesTax"
              onChange={handleInputContract}
              onBlur={()=> { setContract({...contract, 'SalesTax': parseFloat(contract.SalesTax).toFixed(2) })   }}
              className={`${contractErrors.SalesTax ? "imputError" : "campo"}`}
              placeholder="Sales Tax"
              required
              disabled={editContract ? null : "disabled"}

            />
            {contractErrors.SalesTax && <p className="errorMessage">{contractErrors.SalesTax}</p>}
          </div>
          : null}

        <div className="mediowidth-left campoContainer">
          <label className="labelApplicationForm" >Amount Financed</label>
          <input
            type="number"
            value={contract.AmountFinanced}
            name="AmountFinanced"
            onChange={handleInputContract}
            onBlur={()=> { setContract({...contract, 'AmountFinanced': parseFloat(contract.AmountFinanced).toFixed(2) })   }}
            className={`${contractErrors.AmountFinanced ? "imputError" : "campo"}`}
            placeholder="Amount Financed"
            required
            disabled
            value={contract.AmountFinanced ? contract.AmountFinanced : 0}
          />
          {contractErrors.AmountFinanced && <p className="errorMessage">{contractErrors.AmountFinanced}</p>}
        </div>





        <div className="mediowidth-right campoContainer">
          <label className="labelApplicationForm" >Estimated Installation Date</label>
          <input
            type="date"
            value={contract.CompletionDate}
            name="CompletionDate"
            onChange={handleInputContract}
            className={`${contractErrors.CompletionDate ? "imputError" : "campo"}`}
            placeholder="yyyy-mm-dd"
            required
            disabled={editContract ? null : "disabled"}
          />
          {contractErrors.CompletionDate && <p className="errorMessage">{contractErrors.CompletionDate}</p>}
        </div>

        {
          props.applicationData && props.applicationData.LoanParameters && props.applicationData.LoanParameters.RemittanceUpdateFlag &&
          <div className="mediowidth-left campoContainer">
            <label className="labelApplicationForm" >Loan Approved Date</label>
            <input
              type="date"
              value={contract.LoanDate}
              name="LoanDate"
              onChange={handleInputContract}
              className={`${contractErrors.CompletionDate ? "imputError" : "campo"}`}
              placeholder="yyyy-mm-dd"
              required
              disabled={editContract ? null : "disabled"}
            />
            {contractErrors.LoanDate && <p className="errorMessage">{contractErrors.LoanDate}</p>}
          </div>

        }






















          


        {
          (loadingPromotions && editContract) ?
          <div className="fullwidth campoContainer checkCopyAddressInfo">
          <h3 className="marginLeft-menos20 applicationFormContainer-tittle">Promotions</h3>
          <div className="loadingPromotionsContainer">
            <Spinner
                  radius={25}
                  color={"grey"}
                  stroke={3}
                  visible={true}
                />         
              
              <h4 className="margin-left-10">Loading Promotions</h4>
          </div>
          {contractErrors.PromotionId && <p className="textColor_red">{contractErrors.PromotionId}</p>}
            </div>

          :

          ( hasPromotion && !editContract) ?
          <>
         
            <div className="fullwidth campoContainer checkCopyAddressInfo">
            <h3 className="marginLeft-menos20 applicationFormContainer-tittle">Promotions</h3>
                <AquaPromotionCard {...promotionSaved} editContract={editContract} setPromotionId={setPromotionId} promotionId={promotionId} disabled/>
            </div>
          </>

          :

          <>
         
            <div className="fullwidth campoContainer checkCopyAddressInfo">
            <h3 className="marginLeft-menos20 applicationFormContainer-tittle">Promotions</h3>
                { promotionList && promotionList.length > 0 && promotionList.map(promotion =>  
                    <AquaPromotionCard {...promotion} editContract={editContract} setPromotionId={setPromotionId} promotionId={promotionId} key={promotion.PromotionId}/> 
                )}
                {contractErrors.PromotionId && <p className="textColor_red">{contractErrors.PromotionId}</p>}
            </div>
          </>


        }
        



        {/* {
         ( hasPromotion && !editContract) ?
          <>
         
            <div className="fullwidth campoContainer checkCopyAddressInfo">
            <h3 className="marginLeft-menos20 applicationFormContainer-tittle">Promotions</h3>
                <AquaPromotionCard {...promotionSaved} editContract={editContract} setPromotionId={setPromotionId} promotionId={promotionId}/>
            </div>
          </>

          :

          <>
         
            <div className="fullwidth campoContainer checkCopyAddressInfo">
            <h3 className="marginLeft-menos20 applicationFormContainer-tittle">Promotions</h3>
                { promotionList && promotionList.length > 0 && promotionList.map(promotion =>  <AquaPromotionCard {...promotion} editContract={editContract} setPromotionId={setPromotionId} promotionId={promotionId} key={promotion.PromotionId}/> )}
            </div>
          </> 
        } */}
        













        {
          (editContract) &&       
            <div className="fullwidth campoContainer checkCopyAddressInfo">
              <input type="checkbox"
                name="isActive"
                className="inputSentACHToo"
                onClick={() => setSendACHToo(!sendACHToo)}
                {...sendACHToo && { checked: "checked" } }
              />
              <label className="applicationFormContainer-tittle-noPadding" > Submit customer contract and ACH documents at the same time.</label>
            </div>    
        }


      </form>





     













      {
        (sendACHToo && editContract) &&
        <>

          <h3 className="applicationFormContainer-tittle">ACH</h3>



          <div action="" id="form" className="form">



            {/* BankAccountType */}
            <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Bank Account Type</label>
              <select
                value={contract.BankAccountType}
                name="BankAccountType"
                onChange={handleInputContract}
                className={`${contractErrors.BankAccountType ? "imputError" : "campo"}`}
                placeholder="Applicant State"
                required
                disabled={editContract ? null : "disabled"}
              >
                <option value="" selected disabled >Bank Account Type</option>
                <option value="C" >Checking</option>
                <option value="S" >Savings</option>
              </select>
              {contractErrors.BankAccountType && <p className="errorMessage">{contractErrors.BankAccountType}</p>}
            </div>


            {/* BankRoutingNumber */}
            <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Bank Routing Number</label>
              <input
                type="number"
                name="BankRoutingNumber"
                onChange={handleInputContract}
                className={`${contractErrors.BankRoutingNumber ? "imputError" : "campo"}`}
                value={contract.BankRoutingNumber}
                placeholder="Bank Routing Number"
                disabled={editContract ? null : "disabled"}
                required
              />
              {contractErrors.BankRoutingNumber && <p className="errorMessage">{contractErrors.BankRoutingNumber}</p>}
            </div>



            {/* BankAccountNumber */}
            <div className="fullwidth campoContainer">
              <label className="labelApplicationForm" >Bank Account Number</label>
              <input
                type="number"
                name="BankAccountNumber"
                onChange={handleInputContract}
                className={`${contractErrors.BankAccountNumber ? "imputError" : "campo"}`}
                value={contract.BankAccountNumber}
                placeholder="Bank Account Number"
                disabled={editContract ? null : "disabled"}
                required
              />
              {contractErrors.BankAccountNumber && <p className="errorMessage">{contractErrors.BankAccountNumber}</p>}
            </div>

          </div>

        </>
      }



      {/* BOTON PARA ENVIAR DOS */}
      {
        (editContract &&
          <div className="form">
            {
              (
                loadingPostContract ?

                  <div className="mediowidth-right submit campo middle">
                    <Spinner
                      radius={25}
                      color={"white"}
                      stroke={3}
                      visible={true}
                    />
                  </div>

                  :
                  <input
                    type="submit"
                    id="submit"
                    onClick={handleSubmitContract}
                    className="mediowidth-right submit campo"
                    placeholder="Save and Send"
                    value={sendACHToo ? "Submit Contract & ACH" : 'Submit Contract'}
                    required
                  />

              )
            }
          </div>

        )
      }








      <div action="" id="form" className="form">
        


        {
          showNotificationError &&
          <div className="mediowidth-right">
            <p className="text_Red">An unexpected error has occurred.  Please wait a few moments and click the button again.  If it still fails after 3 attempts please notify DealerAlly.</p>
          </div>
        }
      </div>

      {
        (applicationDetails.SigningContractESignUrl && !editContract) &&
        <a href={applicationDetails.SigningContractESignUrl} target="_blank" className="contract_header-editButton-white" >Open to Sign Customer Contract <img className="iconLinkOutSide" src={IconLinkOutSide} /></a>
      }




      {
        (sendingTwoFormsStatus !== '' && showNotificationError === false) &&
        (
          <>
            {
              sendingTwoFormsStatus === 'sendingSigning' ?

                <p className="signingContract_sendingStatus">Sending Signing Contract...</p>
                :
                sendingTwoFormsStatus === 'sendingACH' ?
                  <>
                    <p className="signingContract_sendingStatus">Signing Contract Sent</p>
                    <p className="signingContract_sendingStatus">Sending ACH...</p>
                  </>
                  :
                  sendingTwoFormsStatus === 'finishOk' ?
                    <>
                      <p className="signingContract_sendingStatus">Signing Contract Sent</p>
                      <p className="signingContract_sendingStatus">ACH Sent</p>
                    </>
                    :
                    null


            }






          </>
        )
      }








      {/* {
             editContract &&
             <DownloadDocumentsButton />
           }
           */}

      {
        (contractCompletionStatus || props.applicationData.SigningContractStatus === 'Pending' || props.applicationData.SigningContractStatus === 'Signed') &&
        <AquaFinanceContractStatusTab 
            applicationId={props.applicationData.ApplicationId} 
            type="FULL" 
            refreshStatusContract={refreshStatusContract} 
            showReloadButton={props.applicationData.LoanApplicationStatus === 'FundingComplete' ? false : true} 
            userName={props.applicationData.username} 
            reloadGetSignContractStatuses={props.reloadGetSignContractStatuses}
        />
      }






    </>
  )






}

export default AquaFinanceContractSigningContractForm;
