import React, { useState, useEffect, useContext } from 'react'
import "../../assets/styles/ApplicationForm.css";
import "../../assets/styles/CommercialApplications.css"
import NumberFormat from "react-number-format";
import { AuthContext } from '../../AuthContext';
import { Icon } from '@iconify/react';
import AlertBox from "../AlertBox";
import Button from '../general/Button';
import PlacesAutocomplete from "react-places-autocomplete";
import useHandlePlacesAutocomplete from '../../hooks/useHandlePlacesAutocomplete';

const ContactInformationForm = ({ type, commercialLoanApplicationId, setStep, step, setCommercialLoanData, prefilledInfo, isCorpOnly, validateFields, isExternalForm, ownerId }) => {

  const { uri, changeToken, proposalInfo } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)
  const [notificationError, setNotificationError] = useState({ show: false })
  const [isSubmitting, setIsSubmitting] = useState(1)
  const [contactList, setContactList] = useState([{ Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`, IsPersonalGuarantor: 'true', SendEmail: false }]);
  const [contactErrors, setContactErrors] = useState([{}])
  const [validateIsCorpOnly, setValidateIsCorpOnly] = useState()
  const [dispatchSubmit, setDispatchSubmit] = useState(1)
  const [handleChangePlacesAutocomplete, handleSelectPlacesAutocomplete] = useHandlePlacesAutocomplete(setContactList, 'Address');
  const [authorization, setAuthorization] = useState(false)
  const [termsCheckBorderRed, setTermsCheckBorderRed] = useState(false)


  const user = window.localStorage.getItem('user');
  const token = window.localStorage.getItem('token');

  useEffect(() => {
    if (prefilledInfo) {
      const errors = prefilledInfo
      const numErrors = errors.map(item => { return {} })
      setContactErrors(numErrors)
      setContactList(prefilledInfo.map(item => {
        return { ...item, IsPersonalGuarantor: item.IsPersonalGuarantor ? 'true' : 'false' }
      }))
    }
  }, [prefilledInfo])



  //POST APPLICATION
  const submitForm = async (event) => {

    setIsLoading(true);

    //Armar el objeto que se envia
    let formData = {}
    if (isExternalForm) {
      formData = {
        OwnerId: ownerId,
        ...contactList[0]
      }
    } else {
      formData = { Submit: false, CommercialLoanApplicationId: commercialLoanApplicationId };
      if (contactList.length > 0) {
        const newList = contactList.map(item => { if (item.Id && item.Id.includes('Income')) { delete item.Id }; delete item.SendEmail; return item })
        formData.ContactInformation = newList;
      }
    }




    // console.log(formData)
    // console.log(JSON.stringify(formData))
    let URI = ''
    if (isExternalForm) {
      URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application/save-commercial-owner`;
    } else {
      URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application`;
    }



    const headers = {
      'Access-Control-Request-Headers': 'application/json',
      'Content-Type': 'application/json',
      'username': user,
      'x-api-key': uri.URI_X_API_KEY,
      'Authorization': token,
    }
    if (isExternalForm) {
      delete headers.Authorization
      delete headers.username
    }

    try {
      const rawResponse = await window.fetch(URI, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(formData),
      });
      setIsLoading(false);
      const resp = await rawResponse.json();
      // console.log(resp);

      if (isExternalForm) {
        if (resp.status === 'SUCCESS') {
          setNotificationError({
            title: 'Success',
            text: 'Owner updated successfully',
            type: 'success',
            show: true
          });
        } else {
          setNotificationError({
            title: 'Error',
            type: 'error',
            show: true
          });
        }

      } else {
        if (resp.CommercialLoanApplicationId) {
          setStep(state => ({ ...state, ContactInformation: true }))
          setCommercialLoanData(state => ({ ...state, ContactInformation: formData.ContactInformation }))
        } else {
          setNotificationError({
            title: resp.Message,
            text: resp.Errors[0],
            type: 'error',
            show: true
          });
        }


      }

    }
    catch (error) {
      setIsLoading(false)
      setNotificationError({
        title: 'Error',
        text: 'An unexpected error has occurred. Please try again.',
        type: 'error',
        show: true
      });
      if (error.status === 401) {
        changeToken()
      }
    }

  };




  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isExternalForm && authorization === false) {
      setTermsCheckBorderRed(true)
    } else {
      setTermsCheckBorderRed(false)
    }
    setIsSubmitting(isSubmitting + 1);
    setContactErrors(validateContact(contactList))
    // setDispatchSubmit(dispatchSubmit + 1)

  };





  useEffect(() => {
    let errInc = []

    contactErrors.forEach(error => {
      if (Object.keys(error).length != 0) {
        errInc = [...errInc, false]
      }
    })

    if (isSubmitting > 1 && errInc.length === 0 && (isExternalForm ? authorization : true)) {
      // console.log(contactList)
      submitForm()
    } else {
      // console.log(contactErrors)
      // console.log(contactList)

      // handleSubmitAllData();
    }

  }, [isSubmitting]);



  useEffect(() => {
    setValidateIsCorpOnly(isCorpOnly)
  }, [isCorpOnly])







  const addContact = async () => {
    if (contactList.length >= 5) {
      return
    }


    setContactList([
      ...contactList,
      {
        Id: `Income${Math.random() * (1 - 10000000000) + 10000000000}`,
        IsPersonalGuarantor: 'true',
        SendEmail: false
      }
    ])

    setContactErrors([...contactErrors, {}])


  }

  const handleInput = (e, id, inputName) => {
    let value = (e.target && e.target.value) || e.value
    let name = (e.target && e.target.name) || inputName

    const newList = contactList.map(income => {

      if (income.Id === id) {
        income[name] = value
        return income
      } else {
        return income
      }
    })
    setContactList(newList)
    //console.log(contactList)
  }

  const deleteContact = (id, index) => {

    const newList = contactList.filter(income => income.Id !== id)
    setContactList(newList);

    const newErrorList = contactErrors.filter((error, i) => contactErrors.indexOf(error) !== index)
    setContactErrors(newErrorList)

  }

  const validateContact = (contactList) => {


    const errorIncomeList = contactList.map((data) => {


      let errorList = {};



      if (!data.FirstName) {
        errorList.FirstName = "Please enter a firstname";
      }

      if (!data.LastName) {
        errorList.LastName = "Please enter a lastname";
      }

      if (!data.Email) {
        errorList.Email = "Please enter a email";
      } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(data.Email)) {
        errorList.Email = "Please enter a valid email";
      }

      if (!data.SendEmail) {


        if (!validateIsCorpOnly) {
          if (!data.SSN) {
            errorList.SSN = "Please enter a Social Security Number";
          } else if (!/^[0-9]{9}$/.test(data.SSN)) {
            errorList.SSN = "The social security number must have 9 numbers";
          } else if (!/^[0-9]+\.?[0-9]*$/.test(data.SSN)) {
            errorList.SSN = "The value can only contain numbers";
          }
        } else if (data.SSN && data.SSN !== '') {
          if (!/^[0-9]{9}$/.test(data.SSN)) {
            errorList.SSN = "The social security number must have 9 numbers";
          } else if (!/^[0-9]+\.?[0-9]*$/.test(data.SSN)) {
            errorList.SSN = "The value can only contain numbers";
          }
        }


        if (!data.OwnershipPct) {
          errorList.OwnershipPct = "Please enter a response";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(data.OwnershipPct)) {
          errorList.OwnershipPct = "The value can only contain numbers";
        } else if(data.OwnershipPct > 100){
          errorList.OwnershipPct = "The range should be from 0 to 100";
        }

        if (!data.Title) {
          errorList.Title = "Please enter a lastname";
        }


        if (!data.Email) {
          errorList.Email = "Please enter a email";
        } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(data.Email)) {
          errorList.Email = "Please enter a valid email";
        }

        if (!data.PrimaryPhone) {
          errorList.PrimaryPhone = "Please enter a company phone";
        } else if (!/^[0-9]{10}$/.test(data.PrimaryPhone)) {
          errorList.PrimaryPhone = "The phone must have 10 numbers";
        } else if (data.PrimaryPhone === '0000000000') {
          errorList.PrimaryPhone = "Please enter a valid phone number";
        }


        if (!data.Address) {
          errorList.Address = "Please enter a Address";
        }

        if (!data.City) {
          errorList.City = "Please enter a city";
        } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ-\s\.]+$/.test(data.City)) {
          errorList.City = "The city can only contain letters";
        }

        if (!data.State) {
          errorList.State = "Please select an option";
        } else if (data.State === "State") {
          errorList.State = "Please select an option";
        }

        if (!data.PostalCode) {
          errorList.PostalCode = "Please enter a value";
        } else if (!/^[0-9]{5}$/.test(data.PostalCode)) {
          errorList.PostalCode = "The value must have 5 numbers";
        }



      }



      if (errorList.lenght !== 0) {
        return errorList;
      }


    })

    //contactList.contactErrors[index] = errorIncomeList;

    //console.log(errorIncomeList)



    //return errorIncomeList
    if (errorIncomeList.length === 0) {
      //console.log(errorIncomeList)
      const vacio = []
      return vacio
    } else {
      //console.log(errorIncomeList)
      return errorIncomeList
    }


  }


  //VALIDAR Y LLENAR INFO DE PROPOSAL
  useEffect(() => {
    if (proposalInfo.hasInfo) {
      // console.log(proposalInfo.info)

      const userInfo = {
        FirstName: proposalInfo.info.FirstName,
        LastName: proposalInfo.info.LastName,
        Email: proposalInfo.info.EmailAddress,
        Address: proposalInfo.info.Address1,
        City: proposalInfo.info.City,
        State: proposalInfo.info.State,
        PostalCode: proposalInfo.info.PostalCode,
        PrimaryPhone: proposalInfo.info.CellPhone,
        SecondaryPhone: proposalInfo.info.BusinessPhone,
        SSN: proposalInfo.info.SocialSecurityNumber,
      }

      setContactList([{
        ...contactList[0],
        ...userInfo,
      }])

      setContactErrors(validateContact([userInfo]));

    }

  }, [proposalInfo])


  //VALIDAR CAMPOS
  useEffect(() => {
    if (validateFields) {
      setContactErrors(validateContact(prefilledInfo));
    }
  }, [validateFields])




  //POST INDIVIDUAL EMAIL
  const submitIndividualEmail = async (index) => {

    setIsLoading(true);

    //Armar el objeto que se envia

    const formData = {
      FirstName: contactList[index].FirstName,
      LastName: contactList[index].LastName,
      Email: contactList[index].Email,
    };
    if (contactList[index].Id && contactList[index].Id.includes('Income')) {
      formData.ApplicationId = commercialLoanApplicationId
    } else {
      formData.Id = contactList[index].Id
    }

    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}commercial-application/send-commercial-owner-email`;

    try {
      const rawResponse = await window.fetch(URI, {
        method: 'POST',
        headers: {
          'Access-Control-Request-Headers': 'application/json',
          'Content-Type': 'application/json',
          'username': user,
          'x-api-key': uri.URI_X_API_KEY,
          'Authorization': token,
        },
        body: JSON.stringify(formData),
      });
      setIsLoading(false);
      const resp = await rawResponse.json();
      console.log(resp);

      if (resp.Id) {
        const newList = [...contactList]
        newList[index].Id = resp.Id
        setContactList([...newList])
      }

    }
    catch (error) {
      setIsLoading(false)
      setNotificationError({
        title: 'Error',
        text: 'An unexpected error has occurred. Please try again.',
        type: 'error',
        show: true
      });
      if (error.status === 401) {
        changeToken()
      }
    }

  };





  return (
    <div>

      {
        contactList.map((contact, index) => {
          return (
            <div className="form" key={index}>

              <div className="fullwidth campoContainer">
                <div className='contactForm_removeContact'>
                  <h4>{`Ownership Information (${index + 1})`}</h4>
                  {
                    type === 'create' && !isExternalForm &&
                    <span onClick={() => deleteContact(contact.Id, index)} className="commercial_removeContactButton">Remove contact</span>
                  }

                </div>

              </div>






              <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >First Name</label>
                <input
                  type="text"
                  name="FirstName"
                  onChange={(e) => handleInput(e, contact.Id)}
                  className={`${contactErrors[index].FirstName ? "imputError" : "campo"}`}
                  value={contact.FirstName}
                  placeholder="First name"
                  disabled={(type === 'edit') ? "disabled" : null}
                  required
                />
                {contactErrors[index].FirstName && <p className="errorMessage">{contactErrors[index].FirstName}</p>}
              </div>


              <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Last Name</label>
                <input
                  type="text"

                  name="LastName"
                  onChange={(e) => handleInput(e, contact.Id)}
                  className={`${contactErrors[index].LastName ? "imputError" : "campo"}`}
                  placeholder="Last Name"
                  value={contact.LastName}
                  required
                  disabled={(type === 'edit') ? "disabled" : null}
                />
                {contactErrors[index].LastName && <p className="errorMessage">{contactErrors[index].LastName}</p>}
              </div>

              <div className="fullwidth campoContainer">
                <label className="labelApplicationForm" >Email</label>
                <input
                  type="email"
                  name="Email"
                  onChange={(e) => handleInput(e, contact.Id)}
                  className={`${contactErrors[index].Email ? "imputError" : "campo"}`}
                  placeholder="Email"
                  value={contact.Email}
                  required
                  disabled={(type === 'edit') ? "disabled" : null}
                />
                {contactErrors[index].Email && <p className="errorMessage">{contactErrors[index].Email}</p>}
              </div>

              {
                !isExternalForm &&
                <div className="fullwidth campoContainer">
                  <div className='commercial_ownerEmailCheck'>


                    <label className="switch">
                      <input
                        type="checkbox"
                        {...contact.SendEmail && { checked: "checked" }}
                        {...type === 'edit' && { disabled: "disabled" }}
                        onChange={() => handleInput({ value: !contact.SendEmail }, contact.Id, 'SendEmail')}
                      />
                      <span className="slider round"></span>
                    </label>
                    <p>Email Link to Owner to Fill out Owner Information</p>
                  </div>
                </div>
              }

              {
                contact.SendEmail &&
                <div className='mediowidth-left' style={{ justifySelf: 'start', marginTop: '10px', marginBottom: '10px' }}>
                  <Button
                    onClick={() => submitIndividualEmail(index)}
                    value="Send Email"
                    isLoading={isLoading}
                    size="small"
                  />
                </div>
              }


              {
                !contact.SendEmail &&
                <>
                  <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" >Social Security Number</label>
                    {
                      type === 'edit' ?
                        <input
                          value={type === 'edit' ? (contact.SSN ? ('*****' + contact.SSN.toString().substr(5, 9)) : '') : contact.SSN}
                          type="text"
                          name="SSN"
                          onChange={(e) => handleInput(e, contact.Id)}
                          className={`${contactErrors[index].SSN ? "imputError" : "campo"}`}
                          placeholder="Do not use dashes"
                          required
                          disabled={(type === 'edit') ? "disabled" : null}
                        />
                        :
                        <NumberFormat
                          value={type === 'edit' ? (contact.SSN ? ('*****' + contact.SSN.toString().substr(5, 9)) : '') : contact.SSN}
                          onValueChange={e => handleInput(e, contact.Id, 'SSN')}
                          displayType="input"
                          type="text"
                          name="SSN"
                          placeholder="Do not use dashes"
                          format="###-##-####"
                          className={`${contactErrors[index].SSN ? "imputError" : "campo"}`}
                          disabled={(type === 'edit') ? "disabled" : null}
                        />
                    }
                    {contactErrors[index].SSN && <p className="errorMessage">{contactErrors[index].SSN}</p>}
                  </div>

                  <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" >Ownership %</label>
                    <input
                      type="number"
                      name="OwnershipPct"
                      onChange={(e) => handleInput(e, contact.Id)}
                      className={`${contactErrors[index].OwnershipPct ? "imputError" : "campo"}`}
                      placeholder="Ownership %"
                      value={contact.OwnershipPct}
                      required
                      disabled={(type === 'edit') ? "disabled" : null}
                    />
                    {contactErrors[index].OwnershipPct && <p className="errorMessage">{contactErrors[index].OwnershipPct}</p>}
                  </div>


                  <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" >Title</label>
                    <input
                      type="text"
                      name="Title"
                      onChange={(e) => handleInput(e, contact.Id)}
                      className={`${contactErrors[index].Title ? "imputError" : "campo"}`}
                      placeholder="Title"
                      value={contact.Title}
                      required
                      disabled={(type === 'edit') ? "disabled" : null}
                    />
                    {contactErrors[index].Title && <p className="errorMessage">{contactErrors[index].Title}</p>}
                  </div>





                  <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" >Contact Address</label>
                    <input
                      type="text"
                      name="Address"
                      onChange={(e) => handleInput(e, contact.Id)}
                      className={`${contactErrors[index].Address ? "imputError" : "campo"}`}
                      value={contact.Address}
                      placeholder="Contact Address"
                      disabled={(type === 'edit') ? "disabled" : null}
                      required
                    />
                    {contactErrors[index].Address && <p className="errorMessage">{contactErrors[index].Address}</p>}
                  </div>


                  <div className="mediowidth-left campoContainer">
                    <label className="labelApplicationForm" >Contact City</label>
                    <input
                      type="text"
                      value={contact.City}
                      name="City"
                      onChange={(e) => handleInput(e, contact.Id)}
                      className={`${contactErrors[index].City ? "imputError" : "campo"}`}
                      placeholder="City"
                      required
                      disabled={(type === 'edit') ? "disabled" : null}
                    />
                    {contactErrors[index].City && <p className="errorMessage">{contactErrors[index].City}</p>}
                  </div>



                  <div className="mediowidth-right campoContainer">
                    <label className="labelApplicationForm" >Contact State</label>
                    <select
                      value={contact.State || 'selected'}
                      name="State"
                      onChange={(e) => handleInput(e, contact.Id)}
                      className={`${contactErrors[index].State ? "imputError" : "campo"}`}
                      placeholder="State"
                      required
                      disabled={(type === 'edit') ? "disabled" : null}
                    >
                      <option value="selected" disabled>
                        State
                      </option>
                      <option value="AK">AK</option>
                      <option value="AL">AL</option>
                      <option value="AR">AR</option>
                      <option value="AZ">AZ</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DC">DC</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="GU">GU</option>
                      <option value="HI">HI</option>
                      <option value="IA">IA</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="MA">MA</option>
                      <option value="MD">MD</option>
                      <option value="ME">ME</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MS">MS</option>
                      <option value="MO">MO</option>
                      <option value="MT">MT</option>
                      <option value="NE">NE</option>
                      <option value="NC">NC</option>
                      <option value="ND">ND</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NV">NV</option>
                      <option value="NY">NY</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="PR">PR</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VA">VA</option>
                      <option value="VI">VI</option>
                      <option value="VT">VT</option>
                      <option value="WA">WA</option>
                      <option value="WI">WI</option>
                      <option value="WV">WV</option>
                      <option value="WY">WY</option>
                    </select>
                    {contactErrors[index].State && <p className="errorMessage">{contactErrors[index].State}</p>}
                  </div>


                  <div className="mediowidth-left campoContainer">
                    <label className="labelApplicationForm" >Contact Postal Code</label>
                    <input
                      type="number"
                      value={contact.PostalCode}
                      name="PostalCode"
                      onChange={(e) => handleInput(e, contact.Id)}
                      className={`${contactErrors[index].PostalCode ? "imputError" : "campo"}`}
                      placeholder='Postal Code'
                      required
                      disabled={(type === 'edit') ? "disabled" : null}
                    />
                    {contactErrors[index].PostalCode && <p className="errorMessage">{contactErrors[index].PostalCode}</p>}
                  </div>

                  <div className="mediowidth-left campoContainer">
                    <label className="labelApplicationForm" >Primary Phone</label>
                    <NumberFormat
                      value={contact.PrimaryPhone}
                      onValueChange={e => handleInput(e, contact.Id, 'PrimaryPhone')}
                      displayType="input"
                      type="text"
                      name="PrimaryPhone"
                      placeholder="Do not use dashes"
                      format="(###) ### - ####"
                      className={`${contactErrors[index].PrimaryPhone ? "imputError" : "campo"}`}
                      disabled={(type === 'edit') ? "disabled" : null}
                    />
                    {contactErrors[index].PrimaryPhone && <p className="errorMessage">{contactErrors[index].PrimaryPhone}</p>}
                  </div>

                  <div className="mediowidth-right campoContainer">
                    <label className="labelApplicationForm" >Secondary Phone</label>
                    <NumberFormat
                      value={contact.SecondaryPhone}
                      onValueChange={e => handleInput(e, contact.Id, 'SecondaryPhone')}
                      displayType="input"
                      type="text"
                      name="SecondaryPhone"
                      placeholder="Do not use dashes"
                      format="(###) ### - ####"
                      className={`${contactErrors[index].SecondaryPhone ? "imputError" : "campo"}`}
                      disabled={(type === 'edit') ? "disabled" : null}
                    />
                    {contactErrors[index].SecondaryPhone && <p className="errorMessage">{contactErrors[index].SecondaryPhone}</p>}
                  </div>


                  <div className="fullwidth campoContainer">
                    <label className="labelApplicationForm" >Is Personal Guarantor?</label>
                    <select
                      value={contact.IsPersonalGuarantor || 'selected'}
                      name="IsPersonalGuarantor"
                      onChange={(e) => handleInput(e, contact.Id)}
                      className={`${contactErrors[index].IsPersonalGuarantor ? "imputError" : "campo"}`}
                      placeholder="Is Personal Guarantor?"
                      required
                      disabled={(type === 'edit') ? "disabled" : null}
                    >
                      <option value="selected" disabled>
                        Is Personal Guarantor?
                      </option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </select>
                    {contactErrors[index].IsPersonalGuarantor && <p className="errorMessage">{contactErrors[index].IsPersonalGuarantor}</p>}
                  </div>

                </>
              }



              {
                contact.EmailFilledOut &&
                <div className="fullwidth campoContainer">
                  <h4 style={{ fontWeight: '500' }}>Applicant Authorization Received</h4>
                </div>
              }





            </div>
          )
        })
      }


      <div className="form">
        {
          (contactList.length < 5 && type === 'create' && !isExternalForm) &&
          <div className="fullwidth campoContainer">
            <span className='commercialApp_addUserButton' onClick={addContact}>
              <div>
                <Icon width="20px" icon="ant-design:plus-circle-outlined" />
                <span>Add Owner/Guarantor</span>
              </div>
            </span>
          </div>
        }

        {
          isExternalForm &&
          <div className="fullwidth campoContainer">
            <div className='commercial_submitAllContainer' style={{ maxWidth: '500px' }}>
              <div style={{ marginBottom: '20px' }}>


                <div className="checkCopyAddressInfo">
                  <input type="checkbox"
                    name="isActive"
                    className="inputCopyAddressInfo"
                    {...authorization ? { checked: "checked" } : null}
                    onClick={() => setAuthorization(!authorization)} />
                  <label className="labelApplicationForm" style={{ color: termsCheckBorderRed ? 'red' : 'black' }}> Owner Authorization</label>
                </div>
                <span >DealerAlly and/or its assigns will obtain a consumer credit report about each person identified as a guarantor in this credit application. By checking the box above, I, as a guarantor, am granting authorization to DealerAlly and/or its assigns to obtain my credit in connection with this application.</span>
              </div>

            </div>
          </div>
        }



        <div className='mediowidth-right' style={{ justifySelf: 'end', marginTop: '15px' }}>
          <Button
            onClick={handleSubmit}
            value={(commercialLoanApplicationId && step.ContactInformation) ? "Save" : "Save and continue"}
            isLoading={isLoading}
            showButton={type === 'create'}
          />
        </div>

        <div className="fullwidth campoContainer">
          <AlertBox
            showAlert={notificationError.show}
            type={notificationError.type}
            title={notificationError.title}
            text={notificationError.text}
          />
        </div>
      </div>




    </div>
  )
}

export default ContactInformationForm