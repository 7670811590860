import React, { useState, useEffect, useContext } from 'react'
import '../../assets/styles/Settings.css'
import { AuthContext } from '../../AuthContext';
import SkeletonLoader from "tiny-skeleton-loader-react";
import Spinner from 'react-spinner-material';

const NotificationSettings = ({ notificationInfo, noPadding, username, creatingUser, getNotificationInfo, setnotificationInfo, setNotificationError}) => {

    const { uri } = useContext(AuthContext);


    const [enableNotifications, setEnableNotifications] = useState(false)
    const [notificationsOptions, setNotificationsOptions] = useState({
        All: false,
        Pending: false,
        ConditionallyApproved: false,
        Approved: false,
        SignReady: false,
        Received: true,
        PendingDocs: true,
        InVerification: false,
        InConfirmation: false,
        ReadyToFund: false,
        Denied: false,
        Problem: true,
        Cancelled: false,
        StatusMessages: true,
    })

    const [notificationsOptionsCopy, setNotificationsOptionsCopy] = useState({
        All: false,
        Pending: false,
        ConditionallyApproved: false,
        Approved: false,
        SignReady: false,
        Received: true,
        PendingDocs: true,
        InVerification: false,
        InConfirmation: false,
        ReadyToFund: false,
        Denied: false,
        Problem: true,
        Cancelled: false,
        StatusMessages: true,
    })


    const [choseOneOptionError, setChoseOneOptionError] = useState(false)
    const [loadingNotificationsOptions, setLoadingNotificationsOptions] = useState(false)
    const [permissionChangedSuccesfully, setPermissionChangedSuccesfully] = useState(false)
    const [changeToggle, setChangeTogge] = useState(false)


    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');




    //LLENAR INFO CUANDO VIENE POR PROPS Y NO SE LLAMA DETAILS
    useEffect(() => {
        if (notificationInfo) {
           


            setEnableNotifications(notificationInfo.notificationsActive)

            if (notificationInfo.notificationsStatuses) {

                const newNotificationsOptions = {}

                notificationInfo.notificationsStatuses.forEach(item => {
                    if (item === "All") {
                        newNotificationsOptions.All = true
                    } else if (item === "Pending") {
                        newNotificationsOptions.Pending = true
                    } else if (item === "ConditionallyApproved") {
                        newNotificationsOptions.ConditionallyApproved = true
                    } else if (item === "Approved") {
                        newNotificationsOptions.Approved = true
                    } else if (item === "SignReady") {
                        newNotificationsOptions.SignReady = true
                    } else if (item === "Received") {
                        newNotificationsOptions.Received = true
                    } else if (item === "PendingDocs") {
                        newNotificationsOptions.PendingDocs = true
                    } else if (item === "InVerification") {
                        newNotificationsOptions.InVerification = true
                    } else if (item === "InConfirmation") {
                        newNotificationsOptions.InConfirmation = true
                    } else if (item === "ReadyToFund") {
                        newNotificationsOptions.ReadyToFund = true
                    } else if (item === "Denied") {
                        newNotificationsOptions.Denied = true
                    } else if (item === "Problem") {
                        newNotificationsOptions.Problem = true
                    } else if (item === "Cancelled") {
                        newNotificationsOptions.Cancelled = true
                    } else if (item === "StatusMessages") {
                        newNotificationsOptions.StatusMessages = true
                    }
                })




                setNotificationsOptions({
                    ...notificationsOptions,
                    ...newNotificationsOptions,
                })

                setNotificationsOptionsCopy({
                    ...notificationsOptions,
                    ...newNotificationsOptions,
                })

             


            }

        }
    }, [notificationInfo])



    useEffect(() => {
        
        if(creatingUser){
            setEnableNotifications(true)
        }
    }, [])










    const handleNotificationCheckBox = (e) => {

        if (e.target.name === 'All') {

            if (notificationsOptions.All === true) {
                setNotificationsOptions({
                    ...notificationsOptionsCopy,
                    All: false,
                    // All: false,
                    // Pending: false,
                    // ConditionallyApproved: false,
                    // Approved: false,
                    // SignReady: false,
                    // Received: false,
                    // PendingDocs: false,
                    // InVerification: false,
                    // InConfirmation: false,
                    // ReadyToFund: false,
                    // Denied: false,
                    // Problem: false,
                    // Cancelled: false,
                    // StatusMessages: false,
                })
            } else {
                setNotificationsOptions({
                    All: true,
                    Pending: true,
                    ConditionallyApproved: true,
                    Approved: true,
                    SignReady: true,
                    Received: true,
                    PendingDocs: true,
                    InVerification: true,
                    InConfirmation: true,
                    ReadyToFund: true,
                    Denied: true,
                    Problem: true,
                    Cancelled: true,
                    StatusMessages: true,
                })

            }

        } else {
            setNotificationsOptions({
                ...notificationsOptions,
                [e.target.name]: !notificationsOptions[e.target.name]
            })

            setNotificationsOptionsCopy({
                ...notificationsOptions,
                [e.target.name]: !notificationsOptions[e.target.name]
            })

        }



    }









    //POST CHANGE NOTIFICATIONS TOGGLE
    const handleToggleEnableNotifications = (post) => {
        setEnableNotifications(!enableNotifications)

        if (post) {
            return
        }

        setLoadingNotificationsOptions(true);

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}update-notification-settings`
        // console.log(URI)

        //ARMAR LA LISTA
        let notificationsListToSend = [];

        for (const notification in notificationsOptions) {
            if (notificationsOptions[notification]) {
                notificationsListToSend.push(notification)
            }
        }

        //ARMAR EL OBJETO
        const data = {
            // NotificationsActive : enableNotifications,
            NotificationsStatuses: notificationsListToSend,
            NotificationsDeliveryMethod: ['Email']
        }


        data.NotificationsActive = !enableNotifications


        // console.log(data)
        // console.log(JSON.stringify(data))



        window.fetch(URI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                'username': username || user,
                'x-api-key': uri.URI_X_API_KEY,
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(datos => {
                setLoadingNotificationsOptions(false);

                // console.log(datos);





            }).catch(error => {
                console.log(error)
                setLoadingNotificationsOptions(false)

            });



    }









    //POST CHANGE NOTIFICATIONS BUTTON
    const handleNotificationsOptionsSubmit = (fromOptions) => {



        const { All, Pending, ConditionallyApproved, Approved, SignReady, Received, PendingDocs, InVerification, InConfirmation, ReadyToFund, Denied, Problem, Cancelled, StatusMessages } = notificationsOptions;


        if (!All && !Pending && !ConditionallyApproved && !Approved && !SignReady && !Received && !PendingDocs && !InVerification && !InConfirmation && !ReadyToFund && !Denied && !Problem && !Cancelled && !StatusMessages) {
            setChoseOneOptionError(true)


        } else {
            setChoseOneOptionError(false)

            if (!fromOptions) {
                setEnableNotifications(!enableNotifications)
            }


            setLoadingNotificationsOptions(true);

            const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_USERS}update-notification-settings`
            // console.log(URI)

            //ARMAR LA LISTA
            let notificationsListToSend = [];

            for (const notification in notificationsOptions) {
                if (notificationsOptions[notification]) {
                    notificationsListToSend.push(notification)
                }
            }

            //ARMAR EL OBJETO
            const data = {
                // NotificationsActive : enableNotifications,
                NotificationsStatuses: notificationsListToSend,
                NotificationsDeliveryMethod: ['Email']
            }

            if (fromOptions) {
                data.NotificationsActive = enableNotifications
            } else {
                data.NotificationsActive = !enableNotifications
            }

            // console.log(data)
            // console.log(JSON.stringify(data))



            window.fetch(URI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    'username': username || user,
                    'x-api-key': uri.URI_X_API_KEY,
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(datos => {
                    setLoadingNotificationsOptions(false);

                    // console.log(datos);

                    if (datos.status === 'SUCCESS' && fromOptions) {
                        setPermissionChangedSuccesfully(true)
                    }



                }).catch(error => {
                    console.log(error)
                    setLoadingNotificationsOptions(false)

                });


        }
    }









    //OBTENER DATOS DE NOTIFICATIONS PARA CREAR USUARIO NUEVO
    useEffect(() => {

        if(getNotificationInfo > 1){

            const { All, Pending, ConditionallyApproved, Approved, SignReady, Received, PendingDocs, InVerification, InConfirmation, ReadyToFund, Denied, Problem, Cancelled, StatusMessages } = notificationsOptions;


            if (!All && !Pending && !ConditionallyApproved && !Approved && !SignReady && !Received && !PendingDocs && !InVerification && !InConfirmation && !ReadyToFund && !Denied && !Problem && !Cancelled && !StatusMessages && enableNotifications) {
                setChoseOneOptionError(true)
                // setnotificationInfo({data: {}, error: true})
                setnotificationInfo({})


            } else {
                setChoseOneOptionError(false)
                

                //ARMAR LA LISTA
                let notificationsListToSend = [];

                for (const notification in notificationsOptions) {
                    if (notificationsOptions[notification]) {
                        notificationsListToSend.push(notification)
                    }
                }

                //ARMAR EL OBJETO
                const data = {
                    // NotificationsActive : enableNotifications,
                    NotificationsStatuses: notificationsListToSend,
                    NotificationsDeliveryMethod: ['Email'],
                    NotificationsActive: enableNotifications,
                }

                // if (fromOptions) {
                //     data.NotificationsActive = enableNotifications
                // } else {
                //     data.NotificationsActive = !enableNotifications
                // }

                // setnotificationInfo({data, error: false})
                setnotificationInfo(data)
               
                // console.log(data)

            }

        }


    }, [getNotificationInfo])















    return (
        <div className={noPadding ? "settings_container setting_noPaddingContainer" : "settings_container"}>
            {noPadding ? null : <h2 className="settings-title_section">Notifications</h2>}


            <div className="settings_switchNotificationsContainer">
                <p>Send notifications of LoanApplications updates by email</p>
                
                <label className="switch">
                    <input
                        type="checkbox"
                        {...enableNotifications && { checked: "checked" } }
                        {...loadingNotificationsOptions && { disabled: "disabled" }}
                        // onChange={handleNotificationsOptionsSubmit}
                        onChange={() => { handleToggleEnableNotifications(creatingUser) }}
                    />
                    <span className="slider round"></span>
                </label>
            </div>

            {/* <button onClick={() => console.log(notificationsOptions)}>ver estados</button> */}

            {/* <hr className="filter_CheckContainer-line" /> */}

            {
                enableNotifications &&

                <div className="settings_switchNotificationsContainer">

                    <div className="filter_CheckContainer">
                        <label className="containercheck">
                            <span className="filter_CheckSpan">All</span>
                            <input type="checkbox"
                                name="All"
                                {...notificationsOptions.All && { checked: "checked" }}
                                onChange={handleNotificationCheckBox}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>


                    <div>
                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Pending</span>

                                <input type="checkbox"
                                    name="Pending"
                                    {...notificationsOptions.Pending && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">ConditionallyApproved</span>

                                <input type="checkbox"
                                    name="ConditionallyApproved"
                                    {...notificationsOptions.ConditionallyApproved && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Approved</span>

                                <input type="checkbox"
                                    name="Approved"
                                    {...notificationsOptions.Approved && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>


                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">SignReady</span>

                                <input type="checkbox"
                                    name="SignReady"
                                    {...notificationsOptions.SignReady && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Received</span>

                                <input type="checkbox"
                                    name="Received"
                                    {...notificationsOptions.Received && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Denied</span>

                                <input type="checkbox"
                                    name="Denied"
                                    {...notificationsOptions.Denied && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">PendingDocs</span>

                                <input type="checkbox"
                                    name="PendingDocs"
                                    {...notificationsOptions.PendingDocs && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">InVerification</span>

                                <input type="checkbox"
                                    name="InVerification"
                                    {...notificationsOptions.InVerification && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">InConfirmation</span>

                                <input type="checkbox"
                                    name="InConfirmation"
                                    {...notificationsOptions.InConfirmation && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>



                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">ReadyToFund</span>

                                <input type="checkbox"
                                    name="ReadyToFund"
                                    {...notificationsOptions.ReadyToFund && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>


                            </label>
                        </div>


                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Problem</span>

                                <input type="checkbox"
                                    name="Problem"
                                    {...notificationsOptions.Problem && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>


                            </label>
                        </div>
                    </div>


                    <div className="filter_CheckContainer">
                        <label className="containercheck">
                            <span className="filter_CheckSpan">Cancelled</span>

                            <input type="checkbox"
                                name="Cancelled"
                                {...notificationsOptions.Cancelled && { checked: "checked" }}
                                onChange={handleNotificationCheckBox}
                            />
                            <span className="checkmark"></span>


                        </label>
                    </div>


                    <div className="filter_CheckContainer">
                        <label className="containercheck">
                            <span className="filter_CheckSpan">Status Messages</span>

                            <input type="checkbox"
                                name="StatusMessages"
                                {...notificationsOptions.StatusMessages && { checked: "checked" }}
                                onChange={handleNotificationCheckBox}
                            />
                            <span className="checkmark"></span>


                        </label>
                    </div>

                    {/* <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">ConditionallyApproved</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="ConditionallyApproved"
                                    {...notificationsOptions.ConditionallyApproved && { checked: "checked" } }
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Approved</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="Approved"
                                    {...notificationsOptions.Approved && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">SignReady</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="SignReady"
                                    {...notificationsOptions.SignReady && { checked: "checked" } }
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Received</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="Received"
                                    {...notificationsOptions.Received && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Denied</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="Denied"
                                    {...notificationsOptions.Denied && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">PendingDocs</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="PendingDocs"
                                    {...notificationsOptions.PendingDocs && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">InVerification</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="InVerification"
                                    {...notificationsOptions.InVerification && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">InConfirmation</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                   <input type="checkbox"
                                    name="InConfirmation"
                                    {...notificationsOptions.InConfirmation && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>

                                   </>
                                }
                                
                            </label>
                        </div>

                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">ReadyToFund</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                    <input type="checkbox"
                                    name="ReadyToFund"
                                    {...notificationsOptions.ReadyToFund && { checked: "checked" }}
                                    onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>
                                   </>
                                }
                                
                            </label>
                        </div>


                        <div className="filter_CheckContainer">
                            <label className="containercheck">
                                <span className="filter_CheckSpan">Problem</span>
                                {
                                   !notificationsOptions.All && 
                                   <>
                                    <input type="checkbox"
                                    name="Problem"
                                    {...notificationsOptions.Problem && { checked: "checked" }}
                                        onChange={handleNotificationCheckBox}
                                    />
                                    <span className="checkmark"></span>
                                   </>
                                }
                                
                            </label>
                        </div>
                    </div> */}


                    {/* <div className="filter_CheckContainer">
                        <label className="containercheck">
                            <span className="filter_CheckSpan">Cancelled</span>
                            {
                                !notificationsOptions.All && 
                                <>
                                <input type="checkbox"
                                name="Cancelled"
                                {...notificationsOptions.Cancelled && { checked: "checked" }}
                                onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>
                                 </>
                            }
                            
                        </label>
                    </div>


                    <div className="filter_CheckContainer">
                        <label className="containercheck">
                            <span className="filter_CheckSpan">Status Messages</span>
                            {
                                !notificationsOptions.All && 
                                <>
                                <input type="checkbox"
                                name="StatusMessages"
                                {...notificationsOptions.StatusMessages && { checked: "checked" }}
                                onChange={handleNotificationCheckBox}
                                />
                                <span className="checkmark"></span>
                                </>
                            }
                            
                        </label>
                    </div> */}

                    {
                        !creatingUser &&

                        (
                            loadingNotificationsOptions ?
                                <div className="settings-changeNotificationsOptions_button">
                                    <Spinner
                                        radius={18}
                                        color={"white"}
                                        stroke={3}
                                        visible={true}
                                    />
                                </div>
                                :
                                <button className="settings-changeNotificationsOptions_button" onClick={() => { handleNotificationsOptionsSubmit(true) }}>Save Changes</button>
                        )
                    }




                    {choseOneOptionError && <p className="settings-message_error">Please choose at least one option</p>}

                    {permissionChangedSuccesfully && <p className="settings-message_ok">Permissions updated successfully</p>}


                </div>

            }




        </div>
    )
}

export default NotificationSettings
