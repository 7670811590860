import React from 'react'
import '../assets/styles/Tabs.css'

const Tabs = ({step, setStep, tabList}) => {
    return (
        <div className="tabsContainer">
            <ul className="tabsList">  
                {
                    tabList.map((item, index) => {return (
                        <li className={step === (index + 1) ? 'tabActive' : 'tab'} onClick={()=> {setStep(index + 1)}}>{item}</li>
                    )})
                }          
              {/* <li className={step===1 ? 'tabActive' : 'tab'} onClick={()=> {setStep(1)}}>System Products</li>
              <li className={step===2 ? 'tabActive' : 'tab'} onClick={()=> {setStep(2)}}>System Product Bundles</li>               */}
            </ul>
        </div>  
    )
}

export default Tabs;
