import React from 'react'
import iconAddGreen from '../../assets/images/iconAddGreen.png'
import {Link} from 'react-router-dom';


 const CompanyCard = ({applicant, event, applicantId, isRealApplication}) => {


    // const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    // const cantCreateApplication = window.localStorage.getItem("can_create_applications");
    // const dealerCanCreateApplication = window.localStorage.getItem("dealer_can_create_applications");
    
    return(
        <>
            <div className={applicant.ApplicantId === applicantId ? "applicant_applicantCard-active" : "applicant_applicantCard"} >
                <div className="applicant_applicantCard-header">
                    <p className="applicant_applicantCard-name" onClick={event}>{applicant.LegalCompanyName}</p>
                    
                    {/* {isDeallerAllyAdmin != 'true' && dealerCanCreateApplication === 'true' && isRealApplication ?
                        cantCreateApplication === 'true' ? 
                            <Link to={`createapplication/${applicant.ApplicantId}`}><img src={iconAddGreen} className="aplications-header-buttoms_new_icon" /></Link>                      
                    : null : null}  */}

                </div>
                <div className="applicant_applicantCard-body" onClick={event}>
                    {/* <p className="applicant_applicantCard-label">Email:</p>
                    <p className="applicant_applicantCard-value">{applicant.EmailAddress}</p> */}
                    <p className="applicant_applicantCard-label">Dealer:</p>
                    <p className="applicant_applicantCard-value">{applicant.Dealer}</p>
                    <p className="applicant_applicantCard-label">Created By:</p>
                    <p className="applicant_applicantCard-value">{applicant.CreatedBy}</p>
                    <p className="applicant_applicantCard-label">Status:</p>
                    <p className="applicant_applicantCard-value">{applicant.Status}</p>
                    <p className="applicant_applicantCard-label">Modified Date:</p>
                    <p className="applicant_applicantCard-value">{applicant.LastModifiedDate}</p>
                    
                </div>
                

            </div>
        </>
    )



}

export default CompanyCard
