import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/Applications.css'
import Page from '../components/general/Page'
import { Link } from 'react-router-dom';
import iconAdd from '../assets/images/iconAdd.png';
import { AuthContext } from '../AuthContext';
import ApplicationsListView from '../components/applicationsPage/ApplicationsListView'
import ApplicanstCardsView from '../components/applicationsPage/ApplicantsCardsView'
import iconLayoutList from '../assets/images/iconLayoutList.png'
import iconLayoutCards from '../assets/images/iconLayoutCards.png'
import iconRefresh from '../assets/images/iconRefresh.png';
import AlertBanner from '../components/AlertBanner'
import MultiSelect from "react-multi-select-component";
import CommercialsCardView from '../components/CommercialLoan/CommercialsCardView';
import CommercialsListView from '../components/CommercialLoan/CommercialsListView';


const CommercialLoan = ({ history }) => {


    // const [showFilter, setShowFilter] = useState(false);
    const{setProposalInfo} = useContext(AuthContext);


    const [layoutCards, setLayoutCards] = useState(localStorage.getItem('applicant_layout') || 'true')

    //SEARCH 
    const [termSearchCard, setTermSearchCard] = useState('')
    const [termSearchList, setTermSearchList] = useState('')
    const [searchBy, setSearchBy] = useState('All')

    //FILTERS STATUS 
    const [filterStatusValue, setFilterStatusValue] = useState('')

    //FILTERS STATUS 
    const [filterFIValue, setFilterFIValue] = useState('')

    //APPLICANT ENDPOINTS
    const [callApplicantRealList, setCallApplicantRealList] = useState(localStorage.getItem('applicant_callApplicantRealList') || 'true')


    const [reloadAlertBanner, setReloadAlertBanner] = useState(false)

    const [disableStatusFilter, setDisableStatusFilter] = useState(false)

    const [resetPaginationFlag, setResetPaginationFlag] = useState(0)

    const [refreshPage, setRefreshPage] = useState(0)


    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const cantCreateApplication = window.localStorage.getItem("can_create_applications");
    const canCreateProposal = window.localStorage.getItem("allow_customer_proposals");
    const dealerCanCreateApplication = window.localStorage.getItem("dealer_can_create_applications");








    useEffect(() => {
        localStorage.setItem('applicant_layout', layoutCards);
        localStorage.setItem('applicant_callApplicantRealList', callApplicantRealList);
    }, [layoutCards, callApplicantRealList])





    //RESPONSIVE LOGIC
    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    const [width, setWidth] = useState(getWidth());
    const useCurrentWitdh = () => {

        useEffect(() => {
            let timeoutId = null;
            const resizeListener = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => setWidth(getWidth()), 150);
            };
            window.addEventListener('resize', resizeListener);
            return () => {
                window.removeEventListener('resize', resizeListener);
            }
        }, [])

        return width;
    }
    useCurrentWitdh()


    useEffect(() => {
        if (width < 1050) {
            setLayoutCards('true')
        }
    }, [width])







    // MULTISELECT
    const options = [
        { label: "Approved", value: "Approved" },
        { label: "Draft", value: "Draft" },
        { label: "Denied", value: "Denied" },
        { label: "Error", value: "Error" },
        { label: "Pending", value: "Pending" },
        { label: "Processing", value: "Processing", },
        { label: "FilledOut", value: "FilledOut" },
        { label: "SignReady", value: "SignReady" },
        { label: "Submitted", value: "Submitted" },
        { label: "ConditionallyApproved", value: "ConditionallyApproved" },
        { label: "Received", value: "Received" },
        { label: "PendingDocs", value: "PendingDocs" },
        { label: "InVerification", value: "InVerification" },
        { label: "InConfirmation", value: "InConfirmation" },
        { label: "Verified", value: "Verified" },
        { label: "ReadyToFund", value: "ReadyToFund" },
        { label: "FundingComplete", value: "FundingComplete" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "OnHold", value: "OnHold" },
        { label: "Problem", value: "Problem" },
        { label: "SendBack", value: "SendBack" },
        { label: "SignComplete", value: "SignComplete" },
    ];

    const [statusSelected, setStatusSelected] = useState([]);
    const [filterStatusSelected, setFilterStatusSelected] = useState([]);


    const customValueRenderer = (selected, _options) => {
        if (statusSelected.length && disableStatusFilter) {
            return "Status"
        } else if (statusSelected.length) {
            return null
        } else {
            return "Status"
        }
        // return statusSelected.length
        //   ? null
        //   : "Status";
    };


    useEffect(()=>{
        setProposalInfo({info:{}, hasInfo: false})
    },[])


    




    useEffect(() => {

        const newList = statusSelected.map(item => item.value)
        setFilterStatusSelected(newList)


    }, [statusSelected])




    const handleShowActiveApplications = () => {
        setDisableStatusFilter(!disableStatusFilter)

        if (disableStatusFilter) {
            setFilterStatusSelected([])
            setStatusSelected([])
        } else {
            // setStatusSelected([])
            setFilterStatusSelected(["FilledOut", "Submitted", "Processing", "Pending", "ConditionallyApproved", "Approved", "SignReady", "Received", "PendingDocs"])
        }

    }




    return (
        <Page
            title={ layoutCards === 'true'  ? 'Commercial Applications Main View' : 'Commercial Applications List View'}
            reloadButton={<img src={iconRefresh} 
                            className="application_header-refreshIcon" 
                            onClick={()=>{setRefreshPage(refreshPage + 1); setTermSearchCard('')}}
                        /> }
            mainButton={{
                name: 'New Commercial Loan',
                link: '/commercial'
            }}
            secondaryButton={canCreateProposal === 'true' && {
                name: 'New Commercial Proposal',
                link: '/createcommercialproposal'
            }}
        >
            <>
                
                    {/**********************************************   ***************************************************/}

                  

                        {/* <div className="applications_filterLeft" style={{marginBottom: '15px'}}>       
                            <div className="applications_filter-searchContainer">
                                <input type="text" placeholder="Search by Applicant Name" value={termSearchCard} className="applications_filter-searchInput" onChange={(e) => { setTermSearchCard(e.target.value) }} />
                                <span className="applications_filter-searchCloseIcon" onClick={() => setTermSearchCard('')}>x</span>
                            </div>
                        </div> */}


                                 {/**********************************************   ***************************************************/}

                            <div className="commercialApplications_filterContainer">

                            <div className="applications_filterLeft">

                                {
                                    layoutCards === 'true' ?


                            
                            
                                    /* SEARCHER CARDS*/
                                    <div className="applications_filterLeft" style={{marginBottom: '15px'}}>       
                                        <div className="applications_filter-searchContainer">
                                            <input type="text" placeholder="Search by Applicant Name" value={termSearchCard} className="applications_filter-searchInput" onChange={(e) => { setTermSearchCard(e.target.value) }} />
                                            <span className="applications_filter-searchCloseIcon" onClick={() => setTermSearchCard('')}>x</span>
                                        </div>
                                    </div> 


                                    : 

                                    <>
                                    {/* SEARCHER */}
                                    <div className="applications_filter-searchContainerList">
                                    <input type="text" placeholder="Search" value={termSearchList} className="applications_filter-searchInput" onChange={(e)=>{setTermSearchList(e.target.value)}}/>
                                    <span className="applications_filter-searchCloseIcon" onClick={()=>setTermSearchList('')}>x</span>
                                    </div>



                                    {/* SEARCHER FILTERS */}
                                    <div className="application_contaner-filterSearch">
                                        <select onChange={(e)=>{setSearchBy(e.target.value)}} className="aplications-header-buttoms_filter square" value={searchBy}>
                                            <option value="All" selected>By Any</option>
                                            <option value="Id">By Id</option>
                                            <option value="LegalCompanyName">By Company Name</option>
                                            {/* {
                                                isDeallerAllyAdmin === 'true' && 
                                                <option value="DealerName">By Dealer</option>
                                            } */}
                                            
                                            <option value="FinancialInstitution">By Lender</option>
                                            {/* <option value="LoanApplicationStatus">By Status</option> */}
                                            {/* <option value="FIApplicationNumber">By Number</option> */}
                                            <option value="CreatedBy">By Creator</option>
                                            {/* <option value="PI">By Creation Date</option>
                                            <option value="MA">By Update Date</option> */}
                                            {/* <option value="BuyRate">By BuyRate</option> */}
                                            {/* <option value="Tier">By Tier</option> */}
                                        
                                        </select>
                                    </div>
                                





                                    {/* LENDER FILTER */}
                                    {/* <div className="application_contaner-filterSelect">
                                        <select onChange={(e)=>{setFilterFIValue(e.target.value)}} className="aplications-header-buttoms_filter" value={filterFIValue}>
                                            <option value="" disabled selected>Lender</option>
                                            <option value="">All Lenders</option>
                                            <option value="Aqua Finance">AquaFinance</option>
                                            <option value="Pinnacle Finance">Pinnacle</option>
                                        </select>
                                    </div> */}


                                    {/* STATUS FILTER */}


                                    <div className={disableStatusFilter ? "application_contaner-filterSelectDisabled" : "application_contaner-filterSelect"}>
                                        <MultiSelect
                                            options={options}
                                            value={statusSelected}
                                            onChange={setStatusSelected}
                                            // onChange={(list)=>{handleSelectStatus(list) }}
                                            labelledBy={"Status"}
                                            disableSearch
                                            selectAllLabel={"All Status"}
                                            valueRenderer={customValueRenderer}
                                            disabled={disableStatusFilter}
                                            className={disableStatusFilter ? "statusMultiSelectDisabled" : "multi-select"}
                                        />
                                    </div>

                                    <div className="selectActiveApplications">
                                        <input type="checkbox" onChange={handleShowActiveApplications} {...disableStatusFilter ? { checked: "checked" } : null}/>
                                        <label className="selectActiveApplications_text">Active Applications</label>
                                    </div>


                            </>
                        }
                        
                    </div>

                    <div className="applications_togglesRightContainer">

                        <div className="applications_layoutButtonsContainer application_applications_layoutButtonsContainer_movil">
                            <div onClick={()=>{setLayoutCards('false'); setTermSearchList(''); setCallApplicantRealList('true')}} className={`${ layoutCards === 'true' ? "applications_layoutButtons applications_layoutButtons-left" : "applications_layoutButtons applications_layoutButtons-left applications_layoutButtons-select"}`}>
                                <img src={iconLayoutList} alt="Layout List" className="applications_layoutButtonsIcon "/>
                            </div>
                            <div onClick={()=>{setLayoutCards('true'); setTermSearchCard('')}} className={`${ layoutCards === 'true' ? "applications_layoutButtons applications_layoutButtons-right applications_layoutButtons-select" : "applications_layoutButtons applications_layoutButtons-right "}`}>
                                <img src={iconLayoutCards} alt="Layout Card" className="applications_layoutButtonsIcon"/>
                            </div>
                            {/* <button onClick={()=>{ layoutCards === 'true' ? setLayoutCards('false') : setLayoutCards('true')}}>Switch</button> */}
                        </div>
                        
                    </div>    



                    </div>


                   
  
                        

                {
                    layoutCards === 'true' ?
                        <CommercialsCardView
                            history={history}
                            termSearch={termSearchCard}
                            callApplicantRealList={callApplicantRealList}
                            refreshPage={refreshPage}

                        />
                    :
                    
                    <CommercialsListView 
                        termSearch={termSearchList} 
                        searchBy={searchBy} 
                        filterStatusValue={filterStatusValue}
                        filterFIValue={filterFIValue}
                        refreshGetApplicant={refreshPage}
                        filterStatusSelected={filterStatusSelected}
                        resetPaginationFlag={resetPaginationFlag}
                    />
                }
                          
               
            </>
        </Page>
    )
}

export default CommercialLoan