import React, {useState, useEffect} from 'react'

const useIsSafari = () => {

    const [isSafari, setIsSafari] = useState(false)

    useEffect(()=>{
        const isSafariR = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));
        //console.log(isSafariR)

        const validateInIpad = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('iPad') != -1

        const isSafariOnIpad = navigator.userAgent.match(/iPad/i) != null && 
                       navigator.userAgent.match(/Safari/i) != null &&
                       navigator.platform.match(/iPad/i) != null;

        //  const isSafariOnIpadWithiPadOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && // Verificar si es un dispositivo iOS
        //                !window.MSStream && // Verificar si no es Edge
        //                navigator.userAgent.indexOf('Macintosh') > -1 && // Verificar si es una Mac
        //                navigator.userAgent.indexOf('Mobile') === -1 && // Verificar que no es un dispositivo móvil
        //                navigator.platform.indexOf('Mac') > -1 && // Verificar que la plataforma es Mac
        //                navigator.userAgent.indexOf('iPadOS') > -1 && // Verificar si el sistema operativo es iPadOS
        //                !navigator.userAgent.match(/CriOS/i); // Verificar si no es Chrome para iOS
        
        const isiPad = 'ontouchstart' in window || navigator.maxTouchPoints

        if(isSafariR || validateInIpad || isSafariOnIpad || isiPad){
            setIsSafari(true) 
        }

    }, [])

   

    return isSafari;
}

export default useIsSafari;
