import React, {useState, useContext, useEffect} from 'react'
import "../../assets/styles/ApplicationForm.css";
import {AuthContext} from '../../AuthContext';
import Spinner from 'react-spinner-material';

const AddAndEditProposalProduct = ({type, productInfo, edit, setReloadGetSystemProducts, setReloadGetMonitoringProducts, }) => {

    const{uri} = useContext(AuthContext);

    const [errors, setErrors] = useState({})

    const [product, setProduct] = useState({})
    const [isSending, setIsSending] = useState(false)
 

    const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
    const [createdError, setCreatedError] = useState(false)
    const [productExist, setProductExist] = useState(false)



    const user = window.localStorage.getItem('user');
    const token = window.localStorage.getItem('token');





    //POST
    const  createProduct = async (e) => {
        e.preventDefault()

        setCreatedSuccessfully(false)

        const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}customer-proposal/submit-product`
        // console.log(URI)
    
    
        const body = {     
            ProductPrice: product.ProductPrice,
            PartNumber: product.PartNumber,
            Notes: product.Notes,
            ProductDescription : product.ProductDescription
        }

        if(type === 'system'){
            body.Manufacturer = product.Manufacturer
            body.ProductType = 'System'
        }else{
            body.ServiceCompany = product.ServiceCompany
            body.ProductType = 'Service'
        }

        if(edit){
            body.DealerProposalProductId = product.DealerProposalProductId
        }

        
        if(!body.ProductPrice || !body.ProductDescription){
            const error = {}
            if(!body.ProductDescription){
                error.ProductDescription = 'Please enter a Name'    
            }
            if(!body.ProductPrice){
                error.ProductPrice = 'Please enter a Price'        
            }
            setErrors({...error})
            return
        }
        setErrors({})

         
        setIsSending(true);

        //console.log(body)

        try{
            const rawResponse = await window.fetch(URI, {
                method: 'POST',
                headers: {
                  //'Access-Control-Request-Headers': 'application/json',           
                  'Content-Type': 'application/json',
                  'username': user,
                  'Authorization': token,
                  'x-api-key': uri.URI_X_API_KEY,
                },
                body: JSON.stringify(body),
          
              });
              setIsSending(false);
              const content = await rawResponse.json();
              //console.log(content);

              if(content.status === 'SYSTEM_ERROR' || content.status === 'PRODUCT_ERROR_IN_ORG'){
                setCreatedSuccessfully(false)
                setProductExist(false)
                setCreatedError(true)
                
              }else if(content.status === 'PRODUCT_ALREADY_EXISTS'){
                setCreatedError(false)
                setCreatedSuccessfully(false)
                setProductExist(true)
              }else if(content.status === 'SUCCESS'){
       
                setProduct({})
                setCreatedError(false)
                setProductExist(false)
                setCreatedSuccessfully(true)
              
                if(type === 'system'){
                    setReloadGetSystemProducts(state => (!state))
                }else{
                    setReloadGetMonitoringProducts(state => (!state))
                }
              }


        }catch(e){
            console.log(e)
            setCreatedSuccessfully(false)
            setCreatedError(true)
        }


    }


    //LLENAR PARA EDITAR
    useEffect(() => {
        if(productInfo){
            setProduct({...productInfo, ProductDescription : productInfo.Name || productInfo.ProductDescription})
        }      
    }, [productInfo])








    return (
        <div>
        <form action="" id="form" className="form">

        <div className="fullwidth campoContainer">
        <label className="labelApplicationForm" >  Name </label>
        <input
            type="text"         
            name="ProductDescription"
            value={product.ProductDescription || ''}
            onChange={(e)=> setProduct({...product, [e.target.name] : e.target.value})}
            className={`${errors.ProductDescription ? "imputError" : "campo"}`}   
            placeholder="Name"
            required
        />
         {errors.ProductDescription && <p className="errorMessage">{errors.ProductDescription}</p>}
        </div>

        <div className="fullwidth campoContainer">
        <label className="labelApplicationForm" > Price </label>
        <input
            type="number"         
            name="ProductPrice"
            value={product.ProductPrice || ''}
            onChange={(e)=> setProduct({...product, [e.target.name] : e.target.value})}
            className={`${errors.ProductPrice ? "imputError" : "campo"}`} 
            placeholder="Price"
            required
        />
        {errors.ProductPrice && <p className="errorMessage">{errors.ProductPrice}</p>}
        </div>

        <div className="fullwidth campoContainer">
        <label className="labelApplicationForm" > Part Number </label>
        <input
            type="text"         
            name="PartNumber"
            value={product.PartNumber || ''}
            onChange={(e)=> setProduct({...product, [e.target.name] : e.target.value})}
            className={`${errors.FirstName ? "imputError" : "campo"}`}
            placeholder="Part Number"
            required
        />
        </div>

        {
            type === 'system' ? 
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >  Manufacturer </label>
            <input
                type="text"         
                name="Manufacturer"
                value={product.Manufacturer || ''}
                onChange={(e)=> setProduct({...product, [e.target.name] : e.target.value})}
                className={`${errors.FirstName ? "imputError" : "campo"}`}
                placeholder="Service Company"
                required
            />
            </div>
            :
            <div className="fullwidth campoContainer">
            <label className="labelApplicationForm" >  Service Company </label>
            <input
                type="text"         
                name="ServiceCompany"
                onChange={(e)=> setProduct({...product, [e.target.name] : e.target.value})}
                className={`${errors.FirstName ? "imputError" : "campo"}`}
                value={product.ServiceCompany || ''}
                placeholder="Service Company"
                required
            />
            </div>
            
        }
        

        <div className="fullwidth campoContainer">
        <label className="labelApplicationForm" >  Notes </label>
        <input
            type="text"         
            name="Notes"
            onChange={(e)=> setProduct({...product, [e.target.name] : e.target.value})}
            className={`${errors.FirstName ? "imputError" : "campo"}`}
            value={product.Notes || ''}
            placeholder="Note"
            required
        />
        </div>


     

        {
            isSending ? 
            <button className="fullwidth submit campo">
                 <Spinner
                    radius={25}
                    color={"white"}
                    stroke={4}
                    visible={true}
                 />
            </button>
            :
            <input
                onClick={createProduct}
                type="submit"
                className="fullwidth submit campo"
                placeholder="Save Changes"
                value="Save Changes"
                required
            /> 
        }

        {createdSuccessfully &&  <p className="fullwidth textColor_green">The product has been created successfully.</p>}

        {createdError && <p className="fullwidth textColor_red">An error occurred creating the product. Please try again.</p>}

        {productExist && <p className="fullwidth textColor_red">This product already exists.</p>}
       

      
        

    </form>
    </div>
    )
}

export default AddAndEditProposalProduct;
