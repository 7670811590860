import React, { useState, useContext, useEffect, createContext} from 'react';
import '../../assets/styles/ApplicationsCard.css'
import {AuthContext} from '../../AuthContext';
import Sheet from '../Sheet';
import ApplicationFormAquaFinance from "../ApplicationFormAquaFinance"
import iconApplicationDetail from "../../assets/images/iconApplicationDetail.png"
import PinnacleStubForm from '../PinnacleStubForm'
import ApplicationFormPinnacle from '../ApplicationFormPinnacle';
import iconRefresh from '../../assets/images/iconRefresh.png';
import Spinner from 'react-spinner-material';
import usePolling from '../../hooks/usePolling'
import moment from 'moment-timezone';
import ExternalForm from '../ExternalForm'
import ConvertProspect from '../ConvertProspect'
import AssignUserToApplicationByIframe from './AssignUserToApplicationByIframe';



export const ApplicationCardContext = createContext({})
const { Provider } = ApplicationCardContext



const ApplicationsCard = (props)=>{



    const{uri, reloadFetchFromContext, errorFlagFromContext, setErrorFlagFromContext, changeToken} = useContext(AuthContext);
   

    const [showSheet, setShowSheet] = useState(false);

    const [newApplicationStatus, setNewApplicationStatus] = useState(false);

    const [newFinancialInstitutionNumber, setNewFinancialInstitutionNumber] = useState(false)
    const [newFinancialInstitutionId, setNewFinancialInstitutionId] = useState(false)

    const [refresh, setRefresh] = useState(false)

    const [loadingNewStatus, setLoadingNewStatus] = useState(false)

    const [pollingCounter, setPollingCounter] = useState(0);
   



    const showSheetHandler = ()=>{
        setShowSheet(!showSheet);

    }


    const dealerId = window.localStorage.getItem('dealerId');
    const URI_X_API_KEY_I = uri.URI_X_API_KEY;
    const token = window.localStorage.getItem('token');
    const isDeallerAllyAdmin = localStorage.getItem('is_dealerally_admin');
    const userRole = localStorage.getItem('userRole');



    //REFRESH STATUS APPLICANT
    const refreshStatus = (showAnimation)=>{

    showAnimation && setLoadingNewStatus(true);  
   
    const URI = `${uri.URI_PROTOCOL}${uri.URI_ENV}${uri.URI_BASE}${uri.URI_VERSION}${uri.URI_APPLICATION}fi/${uri.URI_GET_APPLICATION_STATUS}${props.application.ApplicationId || props.application.ProspectLoanApplicationId}`
    //console.log(URI)


    
  
    window.fetch(URI,{
        method: 'GET',
        headers: {
            'Access-Control-Request-Headers': 'application/json',           
            'Content-Type': 'application/json', 
            'DealerId' : props.application.DealerId,
            'Authorization' : token,  
            'x-api-key' : URI_X_API_KEY_I,       
          }
    })
    .then(response => {

        if (response.ok) {
            return response.json();
          }
        return Promise.reject(response);
    })
    .then(datos => {
        setLoadingNewStatus(false);
        // console.log(datos)

        if(datos.hasOwnProperty('LoanApplicationStatus')){
            setNewApplicationStatus(datos.LoanApplicationStatus)
        }

        if(datos.hasOwnProperty('Details')){
            setNewFinancialInstitutionNumber(datos.Details.Customer)
        }else if(datos.hasOwnProperty('FIApplicationId') && datos.hasOwnProperty('FIApplicationNumber')){
            
            setNewFinancialInstitutionNumber(datos.FIApplicationNumber);
            setNewFinancialInstitutionId(datos.FIApplicationId)
        }

        setErrorFlagFromContext(false)
        
       
    }).catch(error => {
        console.log(error)
        setRefresh(true)
        setLoadingNewStatus(false);
        if(errorFlagFromContext === false && error.status === 401){
            changeToken().then(response => console.log(response)).catch(error => console.log(error))
        } 
        
    })
  
    
       
    }




    //REFRESH STATUS WHEN OPEN AND CLOSE DETAILS
    useEffect(() => {

        const localTime = new Date().getTime()
        const today = moment(localTime).tz('America/New_York').format('YYYY-MM-DD')   
        const ny = moment(localTime).tz('America/New_York');
        const time8AM = moment.tz(today + " 08:00", "America/New_York");
        const timeMidNight = moment.tz(today + " 23:59", "America/New_York");

    

        if(showSheet || refresh){
                if( props.application.FinancialInstitutionId === 'FI-0002'){

                    if(ny > time8AM && ny < timeMidNight){
                        if( props.application.LoanApplicationStatus !== 'SignReady' && props.application.LoanApplicationStatus !== 'Error' && newApplicationStatus !== 'SignReady' && newApplicationStatus !== 'Error'){
                            // console.log('x')
                            refreshStatus()
                        } 
                    }        
                }else if(props.application.FinancialInstitutionId === 'FI-0001'){
                   
                    if( props.application.LoanApplicationStatus !== 'Error'  && newApplicationStatus !== 'Error'){
                        refreshStatus()
                    }
                   
                } 
        }
        
    }, [showSheet])




// usePolling(() => {
       
//     if( props.application.FinancialInstitutionId === 'FI-0002'){
//         if( props.application.LoanApplicationStatus !== 'SignReady' && newApplicationStatus !== 'SignReady'){
//             console.log('x')
//             refreshStatus(false)
//             setPollingCounter(pollingCounter + 1)
//         } 
//     }else if(props.application.FinancialInstitutionId === 'FI-0001'){
//         if( props.application.LoanApplicationStatus !== 'Approved' && newApplicationStatus !== 'Approved'){
//             console.log('x')
//             refreshStatus(false)
//             setPollingCounter(pollingCounter + 1)
//         }
//     } 
       
// }, showSheet ? null : (pollingCounter >= 6 ? (1000 * 60) : 10000) );




return(
    <Provider value ={{
        showSheet,
        setShowSheet
    }}>

    {showSheet ?
    <Sheet handler={showSheetHandler}>
        {   
            props.application.FinancialInstitutionId === 'FI-0001' ?

            <>
                {   
                     props.application.IsStubApplication === true ?

                     <PinnacleStubForm entity="aquaFinance" application_id={props.application.ApplicationId} type="edit" handler={showSheetHandler}/>
                     :

                        props.application.Applicant.FirstName === 'Applicant' ? 
                            <ApplicationFormAquaFinance type="create" 
                                                        updateId={props.application.ApplicationId} 
                                                        application_id={props.application.ApplicationId} 
                                                        applicant_id={props.applicant_Id}
                                                        applicantName={`${props.application.Applicant.FirstName} ${props.application.Applicant.LastName}`}
                                                        /> 
                                                        


                        : props.application.LoanApplicationStatus === 'Error' && isDeallerAllyAdmin != 'true' ? 
                            <ApplicationFormAquaFinance type="create" 
                                                        application_id={props.application.ApplicationId} 
                                                        updateId={props.application.ApplicationId}
                                                        applicant_id={props.applicant_Id}
                                                        showErrorMessages
                                                        errorStatus
                                                        applicantName={`${props.application.Applicant.FirstName} ${props.application.Applicant.LastName}`}
                                                        /> 

                                                        
                        :<ApplicationFormAquaFinance application_id={props.application.ApplicationId} 
                                                    type="edit" 
                                                    handler={showSheetHandler}
                                                    applicantName={`${props.application.Applicant.FirstName} ${props.application.Applicant.LastName}`}
                                                    />
                }
                
            </>

            : props.application.FinancialInstitutionId === 'FI-0002' ?

            <>
                {
                    props.application.IsStubApplication === true ?
                    <PinnacleStubForm application_id={props.application.ApplicationId} type="edit" handler={showSheetHandler}/>
                    :

                         props.application.LoanApplicationStatus === 'Error' && isDeallerAllyAdmin != 'true' ? 
                            <ApplicationFormPinnacle  type="create" 
                                                    application_id={props.application.ApplicationId} 
                                                    updateId={props.application.ApplicationId}   
                                                    applicant_id={props.applicant_Id}  
                                                    showErrorMessages  
                                                    errorStatus
                                                    applicantName={`${props.application.Applicant.FirstName} ${props.application.Applicant.LastName}`}
                                                    />                                
                                                    // application_status={newApplicationStatus || props.application.LoanApplicationStatus} 
                                                    //refresh_status={refreshStatus}/>


                            : <ApplicationFormPinnacle 
                                application_id={props.application.ApplicationId} 
                                type="edit" handler={showSheetHandler} 
                                application_status={newApplicationStatus || props.application.LoanApplicationStatus} 
                                refresh_status={refreshStatus}
                                applicantName={`${props.application.Applicant.FirstName} ${props.application.Applicant.LastName}`}
                                />
                        
                }
                
            </>

            :

            <>
                <ConvertProspect detailsId={props.application.ProspectLoanApplicationId}/>
            </>
     
        }
       
        
        
    </Sheet> : null}





    <div  className="applicationCard_card">

        <div className="applicationCard_card-header">
            <div className="applicationCard_header-titleContainer">
                <h2 className="applicationCard_header-numer">{props.application.ApplicationId || props.application.ProspectLoanApplicationId} - {props.application.Applicant.FirstName} {props.application.Applicant.LastName}</h2> 
                {
                    props.application.FinancialInstitutionId &&
                    <>
                        <span className="applicationCard_header-financialentity">{props.application.FinancialInstitution} </span>
                        <span className="applicationCard_header-financialentity">
                            - {props.application.FinancialInstitutionId === 'FI-0001' ?

                                props.application.IsStubApplication ? 'Only documents' :

                                    (props.application.FIApplicationId && props.application.FIApplicationNumber) ? <span  >{props.application.FIApplicationNumber}</span>

                                        : ((newFinancialInstitutionId && newFinancialInstitutionNumber) ?
                                            <span  >{newFinancialInstitutionNumber}</span>
                                            : 'Processing')

                                :

                                props.application.FinancialInstitutionId === 'FI-0002' ?
                                    props.application.IsStubApplication ? 'Only documents' : (props.application.FIApplicationId || newFinancialInstitutionNumber || 'Processing')
                                
                                : 'None'
                                
                                }
                        </span>
                    </>
                }
                
            </div>

            <div className="applicationCard_header-buttonsContainer">
                    <button className="applicationCard_header-DetailsButton" onClick={showSheetHandler}>
                        <img src={iconApplicationDetail} className="applicationCard_header-DetailsButton-img"/>
                        <p className="applicationCard_header-DetailsButton-text" >Details</p>
                    </button>

                {
                    newApplicationStatus ? 

                    <p className={newApplicationStatus === 'Approved' ? "applicationCard_header-status-approved" 
                                :newApplicationStatus === 'SignReady' ? "applicationCard_header-status-approved" 
                                :newApplicationStatus === 'Denied' ? "applicationCard_header-status-denied" 
                                :newApplicationStatus === 'Pending' ? "applicationCard_header-status-pending" 
                                :newApplicationStatus === 'Error' ? "applicationCard_header-status-denied" 
                                :newApplicationStatus === 'FilledOut' ? "applicationCard_header-status-FilledOut"
                                : "applicationCard_header-status-FilledOut" } >{newApplicationStatus}
                    </p>

                    :

                    <p className={props.application.LoanApplicationStatus === 'Approved' ? "applicationCard_header-status-approved" 
                                    :props.application.LoanApplicationStatus === 'SignReady' ? "applicationCard_header-status-approved" 
                                    :props.application.LoanApplicationStatus === 'Denied' ? "applicationCard_header-status-denied" 
                                    :props.application.LoanApplicationStatus === 'Pending' ? "applicationCard_header-status-pending" 
                                    :props.application.LoanApplicationStatus === 'Error' ? "applicationCard_header-status-denied" 
                                    :props.application.LoanApplicationStatus === 'FilledOut' ? "applicationCard_header-status-FilledOut"
                                    : "applicationCard_header-status-FilledOut" } >{props.application.LoanApplicationStatus}
                    </p>
                }
                
                {
                    loadingNewStatus ?

                    <Spinner
                    radius={22}
                    color={"grey"}
                    stroke={3}
                    visible={true}
                    />

                    :
                    
                    
                    (props.application.DealerId && !props.application.IsProspectApplication) ?
                    
                    <img src={iconRefresh} className="applicationCard_header-refreshIcon" onClick={refreshStatus}/>

                    : null

                }



            </div>
        </div>



        <div className="applicationCard_card-body">

            <div className="applicationCard_body-section">
                <div className="applicationCard_body-section-table applicationCard_body-section-table-first">
                    <p className="applicationCard_label">Creation Date:</p>
                    <p className="applicationCard_value">{props.application.CreationDate}</p>
                    <p className="applicationCard_label">Created By:</p>
                    <p className="applicationCard_value">{props.application.UserFullName || 'Pending to be assigned'}</p>
                    <p className="applicationCard_label">Dealer:</p>
                    <p className="applicationCard_value">{props.application.DealerName}</p>
                </div>
                
            </div>

            {   
                
                !props.application.IsProspectApplication && 
                <>
                <div className="applicationCard_body-section-line"></div>
                <div className="applicationCard_body-section">
                
                <p className="applicationCard_body-subtitleContainer"><span></span><span className="applicationCard_body-subtitle">Loan Parameters</span></p>
                <div className="applicationCard_body-section-table applicationCard_body-section-table-second">
                    <p className="applicationCard_label">Amount financed:</p>
                    <p className="applicationCard_value">{props.application.LoanParameters ? parseFloat(props.application.LoanParameters.AmountFinanced).toFixed(2): ''}</p>
                    <p className="applicationCard_label">Down Payment:</p>
                    <p className="applicationCard_value">{props.application.LoanParameters ? parseFloat(props.application.LoanParameters.DownPayment).toFixed(2) : ''}</p>
                    <p className="applicationCard_label">RTC Date:</p>
                    <p className="applicationCard_value">{props.application.RTCDate ? props.application.RTCDate : ''}</p>
                </div>
                
                </div>
                </> 
            }    
           
           
            

            <div className="applicationCard_body-section-line applicationCard_coApplicant-line"></div>

            <div className="applicationCard_body-section applicationCard_coApplicant">
                
            <p className="applicationCard_body-subtitleContainer"><span></span><span className="applicationCard_body-subtitle">CoApplicant</span></p>

                {
                    props.application.CoApplicant ? 
                    <div className="applicationCard_body-section-table">
                        <p className="applicationCard_label">Name:</p>
                        <p className="applicationCard_value">{props.application.CoApplicant ? props.application.CoApplicant.FullName : ''}</p>
                        <p className="applicationCard_label">Email:</p>
                        <p className="applicationCard_value">{props.application.CoApplicant ? props.application.CoApplicant.EmailAddress : ''}</p>
                        <p className="applicationCard_label">SSN:</p>
                        <p className="applicationCard_value">{(props.application.CoApplicant && props.application.CoApplicant.SocialSecurityNumber) ? ('*****' + props.application.CoApplicant.SocialSecurityNumber.toString().substr(5,9)) : ''}</p>
                    </div>
                    :
                    <div className="applicationCard_body-section-table">
                        <p className="applicationCard_value">This application does not have CoApplicant</p>
                    </div>
                }
                
                
            </div>

        </div>


        {
            props.application.UserFullName ? null :
            <div className="appliationCard_selectUserContainer">
                {
                    (userRole === 'Manager' || isDeallerAllyAdmin === 'true' || userRole === 'Finance Specialist/Admin') ?
                    <AssignUserToApplicationByIframe dealerId={props.application.DealerId} applicationId={props.application.ProspectLoanApplicationId} setReloadGetApplications={props.setReloadGetApplications}/>    
                    :
                    <p>This application needs first to be assigned to a user.</p>
                    
                }
                
            </div>
        }        
        



       
    </div>
    
    </Provider>
)};

export default ApplicationsCard;